import { gql } from '@apollo/client';

export const GET_FLOOD_ZONE_DATA = gql`
  query FloodZoneData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      floodZone
    }
  }
`;
