import React from 'react';
import './YesNoMultiForm.scss';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';

const YesNoMultiForm = ({
  yesNoLabel,
  yesNoField,
  yesNoTooltipContent,
  yesNoFieldProps = {},
  multiFormField,
  addAnotherLabel,
  addAnotherField,
  renderChild,
  isDisabled,
  useFormHooks,
  useYesNoMultiFormHooks: { childFormMetas, deleteChildForm, addChildForm },
}) => {
  const { formData, handleFieldChange, registerField } = useFormHooks;

  if (childFormMetas[multiFormField] === undefined) {
    throw Error(
      `${multiFormField} not found in childFormMetas. Did you forget to register it to the the multiFormField in the useYesNoMultiForm hook?`,
    );
  }
  const notDeletedCount = childFormMetas[multiFormField].filter(({ isDeleted }) => !isDeleted).length;
  return (
    <div>
      <SelectionYesNoSet
        label={yesNoLabel}
        tooltipContent={yesNoTooltipContent}
        {...registerField(yesNoField)}
        {...yesNoFieldProps}
      />
      {formData[yesNoField] === 'true' &&
        childFormMetas[multiFormField].map(({ isDeleted }, index) => {
          const nextIndex = index + 1;
          return isDeleted ? undefined : (
            <div key={index} className="FormFieldsGrid">
              {renderChild({
                index,
                isDisabled,
                getChildField: field => formData[`${multiFormField}--${index}--${field}`],
                registerChildField: field => registerField(`${multiFormField}--${index}--${field}`),
                useFormHooks,
              })}
              <SelectionYesNoSet
                className="AddAnotherField"
                label={addAnotherLabel}
                disabled={isDisabled || index + 2 < notDeletedCount}
                isWithMarginBottom={false}
                {...registerField(`${multiFormField}--${index}--${addAnotherField}`)}
                onChange={(value, name, err) => {
                  if (value === 'true') {
                    addChildForm(multiFormField, nextIndex);
                  } else {
                    deleteChildForm(multiFormField, nextIndex);
                  }
                  handleFieldChange(value, name, err);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default YesNoMultiForm;
