import React from 'react';
import { Loader } from '@hometap/htco-components';

import HometapError from 'components/HometapError';
import { getPortalsUrl } from 'utils/links';

export const defaultErrorProps = {
  buttonLink: getPortalsUrl('auth/login/reviewer'),
  errorHeading: "We're having trouble fetching this homeowner's track data. Please try refreshing",
  errorText: 'If the problem persists please contact engineering',
  buttonLabel: 'Head on home',
};

const SectionLoadingWrapper = ({ loading, children, error, errorProps }) => {
  errorProps = { ...defaultErrorProps, ...(errorProps || {}) };
  if (error) {
    return (
      <HometapError
        error={`${error.networkError?.statusCode || 404} error`}
        buttonLink={errorProps.buttonLink}
        title={errorProps.errorHeading}
        buttonLabel={errorProps.buttonLabel}
      >
        {errorProps.errorText}
      </HometapError>
    );
  }

  return loading ? (
    <div className="TrackDetailsContainer">
      <div className="TrackDetails">
        <Loader type="dot-pulse" overlay />
      </div>
    </div>
  ) : (
    children
  );
};

export default SectionLoadingWrapper;
