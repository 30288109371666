import { useState } from 'react';

/** Use a timeout error
 * @param {number?} timeout
 * @returns {[string, React.Dispatch<string>]}
 */
export default function useTimeoutError(timeout = 3000) {
  const [timeoutError, _setTimeoutError] = useState('');
  const setTimeoutError = error => {
    const { response, message } = error || {};
    _setTimeoutError(response?.data?.detail || message || '');
    setTimeout(_setTimeoutError, timeout, '');
  };
  return [timeoutError, setTimeoutError];
}
