import React, { useCallback } from 'react';
import { gql } from '@apollo/client';
import { Accordion, Button, Tooltip } from '@hometap/htco-components';
import { resolvePath, useLocation, useNavigate } from 'react-router-dom';

import TodosDocumentListBottomMenu from './TodosSelectionListBottomMenu';
import { isTodoActive, groupTodosChoices } from '../todoUtils';
import TodosRemoveTodoButton from './TodosRemoveTodoButton';
import {
  getApplicant,
  getValueByProperty,
  getVariableValue,
} from 'apps/track-details/tasks/components/TaskList/TaskListUtils';
import TrackDetailsPadding from 'apps/track-details/TrackDetailsPadding';
import useConfigurations from 'hooks/useConfigurations';

/**
 * @typedef TodosSelectionListParams
 * @type {import('../hooks/useHomeownerTodoSelection').UseHomeownerTodoSelectionReturn}
 * @property {Track} [track]
 * @property {string} homeownerFullName
 */

/** Homeowner To-do Selection List
 * @param {TodosSelectionListParams} params
 * @returns {JSX.Element}
 */
const TodosSelectionList = ({
  track,
  selectedTodos,
  setSelectedTodo,
  isSelected,
  selectTodo,
  deselectTodo,
  homeownerFullName,
}) => {
  const { homeownerTasks = [], applicants = [] } = track || {};
  const navigate = useNavigate();
  const location = useLocation();
  const todosPath = resolvePath('..', location.pathname);
  const { taskKinds, taskDocumentKinds } = useConfigurations();

  const groupedTodosChoices = groupTodosChoices(track);
  const groupedKeys = Object.keys(groupedTodosChoices);

  const accordionShouldRemainOpen = useCallback(todosInGroup => {
    // Allows for only opening the accordions with selected values on initial mount.
    // Otherwise the accordion would close anytime the count of selected todos in a
    // group falls to 0.
    return !!selectedTodos.find(({ kind }) => todosInGroup.findIndex(groupedTodo => groupedTodo.kind === kind) > -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    navigate(todosPath);
    setSelectedTodo([]);
  };

  /** Is the given homeowner todo choice already assigned & open?
   *
   *    Other Kind:                   There's no limitation of the number of other todos.
   *    Non-Applicant Related Kinds:  Only one active todo can exist for every kind.
   *    Applicant Related Kinds:      Only one active todo can exist for every kind x optional applicant.
   *
   * @param {HomeownerTodoChoice} todoChoice
   * @returns {boolean}
   */
  const isAlreadyAssignedAndOpen = todoChoice => {
    if (todoChoice.kind === taskKinds.OTHER) {
      return false;
    }
    return homeownerTasks.some(existingTodo => {
      const docKind = getVariableValue(existingTodo, 'doc_kind');
      const todoKind = getValueByProperty(taskDocumentKinds, docKind, 'kind');
      return (
        isTodoActive(existingTodo) &&
        todoChoice.kind === todoKind &&
        todoChoice.applicant?.identifier === getApplicant(existingTodo, applicants)?.identifier
      );
    });
  };

  return (
    <TrackDetailsPadding>
      <div>
        {groupedKeys.length > 0 ? (
          groupedKeys.map(key => {
            /** @type {HomeownerTodoChoice[]} */
            const todosChoicesInGroup = groupedTodosChoices[key];
            return (
              <Accordion
                header={<h4 className="TodosSelectionListGroupHeading">{key}</h4>}
                className="TodosSelectionListGroup"
                isOpen={accordionShouldRemainOpen(todosChoicesInGroup)}
                key={key}
              >
                {todosChoicesInGroup.map((todoChoice, index) => {
                  const { displayLabel } = todoChoice;
                  const isAssigned = isAlreadyAssignedAndOpen(todoChoice);
                  return (
                    <div className="TodosSelectionListGroupItemContainer" key={index}>
                      <div className="TodosSelectionListGroupItem">
                        <div className="TodosSelectionListGroupItemTextContent">
                          <p className="TodosSelectionListGroupItemHeading">{displayLabel}</p>
                        </div>
                        {isSelected(todoChoice) ? (
                          <TodosRemoveTodoButton removeTodo={() => deselectTodo(todoChoice)} />
                        ) : (
                          <Tooltip
                            action={isAssigned ? 'hover' : 'none'}
                            content={<b className="TodosSelectionListGroupAlreadyAssigned">To-do already assigned</b>}
                          >
                            <Button
                              className="TodosSelectionListTodoButton isAddButton"
                              theme="secondary"
                              label="Add"
                              size="small"
                              disabled={isAssigned}
                              onClick={() => selectTodo(todoChoice)}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Accordion>
            );
          })
        ) : (
          <h4 className="TodosSelectionListNoTodos">There are no to-dos that are currently assignable to this track</h4>
        )}
      </div>
      <TodosDocumentListBottomMenu
        selectedTodos={selectedTodos}
        homeownerFullName={homeownerFullName}
        onCancel={onCancel}
        shouldRenderGoBackButton={groupedKeys.length === 0}
        todosPath={todosPath}
      />
    </TrackDetailsPadding>
  );
};

TodosSelectionList.fragments = {
  AvailableTrackTodoChoices: gql`
    fragment AvailableTrackTodoChoices on TrackNode {
      assignableHomeownerTaskChoices {
        description
        displayLabel
        displayGroup
        kind
      }
    }
  `,
};

export default TodosSelectionList;
