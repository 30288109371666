import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { PreviewDocumentSidebar } from '../PreviewDocumentSidebar/PreviewDocumentSidebar';
import PreviewDocumentFile, { usePreviewDocumentFile } from '../PreviewDocumentFile/PreviewDocumentFile';

import { PreviewDocumentTopbar, usePreviewDocumentTopbar } from '../PreviewDocumentTopbar/PreviewDocumentTopbar';
import PreviewDocumentBottombar, {
  usePreviewDocumentBottombar,
} from '../PreviewDocumentBottombar/PreviewDocumentBottombar';
import PreviewDocumentLeftbar from '../PreviewDocumentLeftbar/PreviewDocumentLeftbar';

import './PreviewDocument.scss';

/**
 * @typedef PreviewDocumentParams
 * @type {object}
 * @property {bool?} isEmbedded Is this viewer embedded inside another Component. Left & Right Sidebar are not visible. Viewer cannot be closed & has slightly different styling.
 * @property {import('../../documentRequests').Document[]} documents Documents the Viewer can paginate through
 * @property {boolean?} documentLoading Is the fetch document request still going?
 * @property {string?} documentError Did the fetch document request raise an error?
 * @property {number} viewDocumentIndex The index of the document being viewed. Default to 0 index.
 * @property {React.Dispatch<number>} setViewDocumentIndex Select a new document to be viewed by providing an index
 * @property {string?} versionId If viewing a specific version of a document, provide the initial view version ID
 * @property {import('../../documentRequests').DocumentVersion?} viewVersion If viewing a specific version of a document, provide initial view version
 * @property {(() => void)?} onClose If defined, makes viewer is closable via close button or ESC key. The callback for closing the viewer
 * @property {string?} trackId If viewing a document in the context of a track, provide the track ID
 * @property {object?} scrollPosition The current scroll position in the documents list - TODO The viewer should never be aware of this
 * @property {boolean} [isFullScreenEmbedded] Is this viewer embedded inside another Component and is full screen. Left & Right Sidebar are not visible. Viewer cannot be closed & has slightly different styling. Action bar is moved to the top.
 * @property {boolean} [isArrows] Variable indicating whether document switch buttons will be shown
 * @property {boolean} [isSmall] Is the viewer has small size?
 * @property {boolean} [isBack] Is the viewer has a back button?
 * @property {() => void} [handleBack] The on-close callback to reset the Viewer
 * @property {'top'|'bottom'} [positionActionBar] Position of the action bar
 */

/** Document Viewer with all action bars
 * @param {PreviewDocumentParams} params
 * @returns {JSX.Element}
 *    -   Document(s) Fetching or Downloading   ->    Loader
 *    -   Document(s) Fetched & Downloaded      ->    Viewer
 */
export const PreviewDocument = ({
  isEmbedded,
  documents,
  documentLoading,
  documentError,
  viewDocumentIndex,
  setViewDocumentIndex,
  // page-realted params
  versionId,
  viewVersion,
  // nav-related params
  trackId,
  onClose,
  scrollPosition,
  isFullScreenEmbedded,
  isArrows = true,
  isSmall,
  isBack,
  handleBack,
  positionActionBar = 'bottom',
}) => {
  const viewDocument = documents[viewDocumentIndex];
  const thumbnailPluginInstance = thumbnailPlugin();
  const usePreviewDocumentFileReturn = usePreviewDocumentFile();
  const { download, resetDownload } = usePreviewDocumentFileReturn;
  const usePreviewDocumentBottombarReturn = usePreviewDocumentBottombar();
  const usePreviewDocumentTopbarReturn = usePreviewDocumentTopbar();
  const { resetBottombar } = usePreviewDocumentBottombarReturn;

  // The viewDocumentOrVersion is the currently viewed document or version.
  // Invariants:
  //    -   viewDocumentOrVersion is a Document   ->    viewDocumentOrVersion === documents[viewDocumentIndex]
  //    -   viewDocumentOrVersion is a Version    ->    viewDocumentOrVersion is another version of documents[viewDocumentIndex]
  const [viewDocumentOrVersion, _setViewDocumentOrVersion] = useState();
  // Download an image & reset the bottom bar on view document/version update
  const setViewDocumentOrVersion = useCallback(
    docOrVersion => {
      if (docOrVersion) {
        download(docOrVersion);
      } else {
        resetDownload();
      }
      resetBottombar(docOrVersion);
      _setViewDocumentOrVersion(docOrVersion);
    },
    [download, resetDownload, resetBottombar],
  );

  const isCloseable = !!onClose;
  const handleClose = useCallback(() => {
    setViewDocumentIndex(0);
    setViewDocumentOrVersion();
    if (onClose) {
      onClose();
    }
  }, [setViewDocumentIndex, setViewDocumentOrVersion, onClose]);

  // Hooks - useEffect

  // Update the Viewer when the parameters change
  useEffect(() => {
    const docOrVersion = versionId ? viewVersion : documents[viewDocumentIndex];
    if (docOrVersion) {
      setViewDocumentOrVersion(docOrVersion);
    }
  }, [versionId, viewVersion, documents, viewDocumentIndex, setViewDocumentOrVersion]);

  // If closeable, then, closes viewer when escape key is pressed
  useEffect(() => {
    const closeOnEscape = ({ key }) => {
      key === 'Escape' && handleClose();
    };
    if (isCloseable) {
      document.addEventListener('keydown', closeOnEscape);
    }
    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [isCloseable, handleClose]);

  return (
    <div className={cx('PreviewDocument', { isFullWindow: !isEmbedded, isFullScreenEmbedded })}>
      <PreviewDocumentTopbar
        {...{
          isEmbedded,
          isCloseable,
          handleClose,
          documents,
          viewDocumentIndex,
          setViewDocumentIndex,
          viewDocumentOrVersion,
          trackId,
          scrollPosition,
          isBack,
          handleBack,
          isFullScreenEmbedded,
          isSmall,
          isArrows,
          actionBar: positionActionBar === 'top' && (
            <PreviewDocumentBottombar
              {...{
                viewDocument,
                viewDocumentOrVersion,
                isEmbedded,
                isFullScreenEmbedded,
                position: positionActionBar,
                isSmall,
              }}
              {...usePreviewDocumentBottombarReturn}
            />
          ),
        }}
        {...usePreviewDocumentTopbarReturn}
      />
      <div className={cx('PreviewDocumentContentContainer', { isFullScreenEmbedded })}>
        {/* Embedded viewers don't display a sidebar */}
        {!isEmbedded && (
          <PreviewDocumentLeftbar
            {...{
              viewDocumentOrVersion,
              thumbnailPluginInstance,
            }}
          />
        )}
        <PreviewDocumentFile
          {...{
            viewDocumentOrVersion,
            documentLoading,
            documentError,
            isEmbedded,
            isFullScreenEmbedded,
            thumbnailPluginInstance,
          }}
          {...usePreviewDocumentFileReturn}
          {...usePreviewDocumentBottombarReturn}
          {...usePreviewDocumentTopbarReturn}
        />
        {/* Embedded viewers don't display a sidebar */}
        {!isEmbedded && (
          <PreviewDocumentSidebar
            {...{
              documents,
              viewDocumentIndex,
              viewDocumentOrVersion,
              documentLoading,
              trackId,
              scrollPosition,
            }}
          />
        )}
      </div>
      {positionActionBar === 'bottom' && (
        <PreviewDocumentBottombar
          {...{ viewDocument, viewDocumentOrVersion, isEmbedded, isFullScreenEmbedded, isSmall, isArrows }}
          {...usePreviewDocumentBottombarReturn}
        />
      )}
    </div>
  );
};

// actionBar top bottom
