import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './SidebarDetail.scss';

const exampleTitle = 'Sidebar Detail Title';

/**
 * @typedef SidebarDetailParams
 * @type {object}
 * @property {string} sidebarDetailTitle text that should be the header
 * @property {JSX.Element} children
 * @property {boolean} [isWithSections] the content contains sections
 */
/**
 * Wrapper component for ARP sidebar details to make formatting and layout consistent
 * @param {SidebarDetailParams} params
 * @returns {JSX.Element}
 */
export const SidebarDetail = ({ sidebarDetailTitle = exampleTitle, isWithSections = false, children }) => {
  return (
    <div className="SidebarDetail">
      <h3 className="SidebarDetailTitle">{sidebarDetailTitle}</h3>
      <div className={cx('SidebarDetailContent', { isWithSections })}>{children}</div>
    </div>
  );
};

SidebarDetail.propTypes = {
  sidebarDetailTitle: PropTypes.string.isRequired,
  isWithSections: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
