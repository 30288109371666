import React from 'react';
import cx from 'classnames';
import env from 'utils/env';
import _ from 'lodash';
import { REVIEWER_ROLES } from 'userRoles';
import { Header, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import { NavLink } from 'react-router-dom';
import { getLogoutUrl, getPortalsUrl, getTracksTableUrl, getFundingUrl } from 'utils/links';
import useCurrentUser from 'hooks/useCurrentUser';
import GuideCalculator from 'apps/investment-calculators/components/GuideCalculator/GuideCalculator';
import './Header.scss';

const USER_DROPDOWN_OPTIONS = {
  tracks: { name: 'Tracks', path: getTracksTableUrl() },
  closed: { name: 'Closed', path: getTracksTableUrl({ stage: 'ClosedLost' }) },
  invested: { name: 'Invested', path: getPortalsUrl('staff/invested-clients') },
  settled: { name: 'Settled', path: getPortalsUrl('staff/settled-clients') },
  funding: { name: 'Funding', path: getFundingUrl() },
  batchJobs: { name: 'Batch jobs', path: getPortalsUrl('staff/batch-jobs/home-valuations') },
  settings: { name: 'Settings', path: getPortalsUrl('staff/settings') },
  logout: { name: 'Log Out', path: getLogoutUrl() },
};

const getUserDropdownOptionsByUser = ({ user, isContractor }) => {
  let result = [];
  // External Contractors have a limited set of options (Even if they are also an underwriter or app specialist)
  if (user?.roles && user.roles.length > 0 && !isContractor) {
    user.roles.forEach(role => {
      switch (role) {
        case REVIEWER_ROLES.APPLICATION_SPECIALIST: {
          result.push(USER_DROPDOWN_OPTIONS.closed);
          break;
        }
        case REVIEWER_ROLES.UNDERWRITER: {
          const customTracks = { ...USER_DROPDOWN_OPTIONS.tracks };
          customTracks.path = customTracks.path.concat(`?underwriters=${user.reviewer.identifier}`);
          result.push(customTracks);
          result.push(USER_DROPDOWN_OPTIONS.closed);
          result.push(USER_DROPDOWN_OPTIONS.invested);
          result.push(USER_DROPDOWN_OPTIONS.settled);
          result.push(USER_DROPDOWN_OPTIONS.funding);
          result.push(USER_DROPDOWN_OPTIONS.batchJobs);
          break;
        }
        case REVIEWER_ROLES.SERVICER:
        case REVIEWER_ROLES.INVESTMENT_MANAGER:
        case REVIEWER_ROLES.SALES_MANAGER:
        case REVIEWER_ROLES.APPLICATION_SPECIALIST_SUPERVISOR: {
          result.push(USER_DROPDOWN_OPTIONS.tracks);
          result.push(USER_DROPDOWN_OPTIONS.closed);
          result.push(USER_DROPDOWN_OPTIONS.invested);
          result.push(USER_DROPDOWN_OPTIONS.settled);
          result.push(USER_DROPDOWN_OPTIONS.funding);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  result.push(USER_DROPDOWN_OPTIONS.settings);
  result.push(USER_DROPDOWN_OPTIONS.logout);
  result = _.uniqBy(result, 'name');
  return result;
};

const StaffHeader = () => {
  const {
    user,
    isInInvestmentGuideCalculatorRolloutGroup,
    hasBatchDocumentSetsPrivileges,
    isContractor,
    canReadWriteDataEntryTasks,
  } = useCurrentUser();
  const addClassIfActive = ({ isActive }) => (isActive ? 'isActive' : '');
  const userDropdownOptions = getUserDropdownOptionsByUser({ user, isContractor });

  const showDocSetLink = !isContractor && hasBatchDocumentSetsPrivileges;

  return (
    <Header logoLink={getPortalsUrl()} environment={env.getEnv()} className="Header">
      <div className="HeaderContentWrapper">
        <div className={cx('HeaderNav', env.isProd() && 'isProd')}>
          {!isContractor && (
            <NavLink to="/tracks" className={addClassIfActive}>
              Tracks
            </NavLink>
          )}
          {showDocSetLink && (
            <NavLink to={'/document-set-batch'} className={addClassIfActive}>
              Document Set Batches
            </NavLink>
          )}
          {canReadWriteDataEntryTasks && (
            <NavLink to={'/tasks'} className={addClassIfActive}>
              Data Entry Tasks
            </NavLink>
          )}
        </div>
      </div>
      <div className="HeaderRightContentWrapper">
        {isInInvestmentGuideCalculatorRolloutGroup && <GuideCalculator />}

        {user && (
          <div className="HeaderUser">
            <MenuDropdown
              className="HeaderUserDropdown"
              menuWidth={125}
              position="bottom-end"
              trigger={`${user.firstName} ${user.lastName}`}
            >
              {userDropdownOptions.map(item => {
                return (
                  <MenuDropdownItem key={item.name} href={item.path}>
                    {item.name}
                  </MenuDropdownItem>
                );
              })}
            </MenuDropdown>
          </div>
        )}
      </div>
    </Header>
  );
};
export default StaffHeader;
