import React, { useState, useEffect } from 'react';
import { omitBy, isNil } from 'lodash';
import { useMutation } from '@apollo/client';
import { NotFoundBlock, Paper } from '@hometap/htco-components';

import FilterableTable from 'components/FilterableTable/FilterableTable';
import TracksFilters from './components/TracksFilters';
import Toast from 'components/Toast/Toast';
import useFilterableQuery from 'hooks/useFilterableQuery';
import useCurrentUser from 'hooks/useCurrentUser';
import useQueryParams from 'hooks/useQueryParams';
import { getPortalsUrl } from 'utils/links';
import { ITEMS_PER_PAGE } from 'utils/tables';
import { segmentListType } from 'utils/segment/segment';

import { CLAIM_TRACK } from './mutations';
import { GET_MORE_TRACKS } from './queries';
import './Tracks.scss';
import { getTrackColumns, getTrackMobileColumns } from './utils';

const TracksController = () => {
  const { searchParamsObject, setSearchParams } = useQueryParams();
  const tracks = useFilterableQuery({
    query: GET_MORE_TRACKS,
    dataKey: 'tracks',
    pageLimit: ITEMS_PER_PAGE,
    defaultFilters: searchParamsObject,
    segmentType: segmentListType.tracks,
    queryOptions: { variables: { orderBy: searchParamsObject.orderBy } },
  });

  const { data, error, loading, total, filters, resetDefaultPage, handleFilterTable, handleSort, handleChangePage } =
    tracks;

  const [claimTrack, { data: claimTrackData, error: claimTrackError }] = useMutation(CLAIM_TRACK);
  const [friendlyId, setFriendlyId] = useState();
  const [fullNameShort, setFullNameShort] = useState();
  const { user } = useCurrentUser();

  const handleClaimTrack = trackId => {
    claimTrack({ variables: { trackId } }).catch(error => {
      console.error(error);
    });
  };

  const handleFilterChange = filters => {
    handleFilterTable(filters);
    setSearchParams(omitBy(filters, isNil));
  };

  useEffect(() => {
    if (claimTrackData) {
      setFriendlyId(claimTrackData.claimTrack.track.friendlyId);
      setFullNameShort(claimTrackData.claimTrack.track.reviewer.fullNameShort);
    }
  }, [claimTrackData]);

  let errorDataComponent;
  if (error) {
    errorDataComponent = (
      <NotFoundBlock
        error={`${error.networkError?.statusCode || 404} error`}
        buttonLink={getPortalsUrl('auth/login/reviewer')}
        title="We're having trouble fetching these tracks. Please try refreshing"
      >
        If the problem persists please contact engineering.
      </NotFoundBlock>
    );
  }

  return (
    <div className="TracksControllerContainer">
      {(claimTrackData && !claimTrackError && (
        <Toast message={`Track ${friendlyId} has been successfully claimed for ${fullNameShort}`} />
      )) ||
        (claimTrackError && (
          <Toast message={`${claimTrackError.message}, please try refreshing the page`} theme="warning" />
        ))}
      <Paper pad={0}>
        <TracksFilters appliedFilters={filters} onApplyFilter={handleFilterChange} />
        <div className="TracksTableContainer">
          <FilterableTable
            testId="tracks-table"
            data={data}
            loading={loading}
            resetDefaultPage={resetDefaultPage}
            onPageRequest={handleChangePage}
            totalItems={total}
            onSort={(column, sortDirection) => {
              handleSort(column, sortDirection);
              setSearchParams({
                ...searchParamsObject,
                orderBy: `${sortDirection === 'asc' ? '' : '-'}${column.sortKey}`,
              });
            }}
            errorDataComponent={errorDataComponent}
            columns={getTrackColumns(user, handleClaimTrack)}
            mobileColumns={getTrackMobileColumns(user, handleClaimTrack)}
          />
        </div>
      </Paper>
    </div>
  );
};

export default TracksController;
