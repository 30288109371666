import ActionsTooltip from '../../../components/Tooltip/ActionsTooltip';
import React from 'react';
import ACTION_TOOLTIP_OPTIONS from '../../../data/constants/actionTooltipOptions';
import { getPortalsUrl, getTrackTasks } from '../../../utils/links';

export const TrackCreationLinks = ({ trackId, friendlyId, email }) => {
  if (trackId && email) {
    const staffLoginLink = getTrackTasks(trackId);
    const homeownerLoginLink = getPortalsUrl(
      `configurator/autologin/?client_user_email=${email.replace('+', '%2B')}&track_id=${trackId}`,
    );

    const staffLoginActions = [
      { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: staffLoginLink },
      { actionType: ACTION_TOOLTIP_OPTIONS.link, toLink: staffLoginLink },
    ];

    const homeownerLoginActions = [
      { actionType: ACTION_TOOLTIP_OPTIONS.copy, value: homeownerLoginLink },
      { actionType: ACTION_TOOLTIP_OPTIONS.link, toLink: homeownerLoginLink },
    ];

    return (
      <div className="CreateTrackLinksContainer">
        <h2>{friendlyId}</h2>
        <div className="CreateTrackLinks">
          <ActionsTooltip className="CreateTrackLink" actions={staffLoginActions}>
            <h3>Track</h3>
          </ActionsTooltip>
          <ActionsTooltip className="CreateTrackLink" actions={homeownerLoginActions}>
            <h3>Homeowner Login</h3>
          </ActionsTooltip>
        </div>
      </div>
    );
  }

  return null;
};
