import { getVariableValue } from '../tasks/components/TaskList/TaskListUtils';

// Object of policy document types based on the provided document types.
export const POLICY_DOC_KINDS = {
  homeowners: 'ho_insurance',
  flood: 'flood_insurance',
  condo: 'condo_master_policy',
  other: 'other_insurance',
};

/**
 * Returns a boolean indicating whether there is an open task with the given policy document kind.
 *
 * @param {Array} tasks - An array of task objects.
 * @param {string} policyDocKind - The policy document kind to check for.
 * @returns {boolean} - True if there is an open task with the given policy document kind, false otherwise.
 */
export const getIsOpenSomeTask = (tasks = [], policyDocKind) =>
  tasks?.some(task => {
    const docKind = getVariableValue(task, 'doc_kind');
    return policyDocKind === docKind && task.isOpen;
  });
