import { useEffect, useState } from 'react';

/** Use local storage to store a particular state. Each key x track combination is unique!
 * @param {string} trackId Track ID
 * @param {string} key Unique Key for the local storage
 * @param {T} defaultValue The optional default value if value doesn't exist in the local storage
 * @returns {[T, React.Dispatch<T>]}
 */
const useLocalStateForTrack = ({ trackId = null, key, defaultValue = null }) => {
  const [value, setValue] = useState(() => {
    return getJSONFromLocalStorage({ trackId, key, defaultValue });
  });

  useEffect(() => {
    setJSONInLocalStorage({ trackId, key, value });
  }, [key, trackId, value]);

  return [value, setValue];
};

/** Get a Per-Track JSON value from local storage
 * @param {string} trackId Track ID
 * @param {string} key Unique Key for the local storage
 * @param {T} defaultValue The optional default value if value doesn't exist in the local storage
 * @returns JSON value in local storage for the trackId x key pair, Else the default value
 */
export function getJSONFromLocalStorage({ trackId = null, key, defaultValue = null }) {
  const keyWithPrefix = `htco${key}-${trackId}`;
  if (typeof window.localStorage === 'object') {
    const localStorageValue = localStorage.getItem(keyWithPrefix);
    return localStorageValue ? JSON.parse(localStorageValue) : defaultValue;
  }

  return defaultValue;
}

/** Get a Per-Track JSON value from local storage
 * @param {string} trackId Optional Track ID
 * @param {string} key Unique Key for the local storage
 * @param {T} value The value being stored
 */
export function setJSONInLocalStorage({ trackId = null, key, value }) {
  const keyWithPrefix = `htco${key}-${trackId}`;
  localStorage.setItem(keyWithPrefix, JSON.stringify(value));
}

export default useLocalStateForTrack;
