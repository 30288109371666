import useConfigurations from 'hooks/useConfigurations';
import { compareAndAlphabetize } from '../DocumentsUtils';

const useDocumentKinds = () => {
  const { documentKinds, documentKindsWithPerson } = useConfigurations();

  const documentKindsDropdownOptions = [];
  const documentKindToData = {};
  const documentTypeToDisplayName = {};
  for (const { value, displayName, visibility, autoPin } of documentKinds) {
    documentKindsDropdownOptions.push({ value, label: displayName });
    documentKindToData[value] = { label: displayName, visibility: visibility, autoPin: autoPin };
    documentTypeToDisplayName[value] = displayName;
  }
  const documentKindsDropdownOptionsSorted = Array.from(documentKindsDropdownOptions);
  documentKindsDropdownOptions.push({
    value: 'other',
    label: 'Other',
  });
  documentKindsDropdownOptionsSorted
    .sort((a, b) => compareAndAlphabetize(a, b, 'label'))
    .push({
      value: 'other',
      label: 'Other',
    });

  const getDocumentKindVisibility = documentType => {
    const visibility = documentKindToData[documentType]?.visibility;
    const visibilityLabel = visibility === 'external' || visibility === 'both' ? 'Both' : 'Internal';
    const isVisibilityEditable = visibility !== 'internal';
    return { visibility, visibilityLabel, isVisibilityEditable };
  };

  const getDocumentKindAutoPin = documentType => {
    return documentKindToData[documentType]?.autoPin;
  };

  const getDocumentTypeDisplayName = documentType => {
    return documentTypeToDisplayName[documentType] || 'Other';
  };

  const isDocumentType = documentType => {
    return documentType in documentTypeToDisplayName;
  };

  return {
    documentKindsDropdownOptions,
    documentKindsDropdownOptionsSorted,
    documentKindsWithPerson,
    getDocumentKindVisibility,
    getDocumentKindAutoPin,
    getDocumentTypeDisplayName,
    isDocumentType,
  };
};

export default useDocumentKinds;
