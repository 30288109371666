import { gql } from '@apollo/client';

export const GET_DOCUMENT_SET_BATCHES_QUERY = gql`
  query paginatedDocumentBatchRequests($limit: Int!, $offset: Int!, $sortDirection: String, $sortField: String) {
    paginatedDocumentBatchRequests(
      limit: $limit
      offset: $offset
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      hasMore
      documentSetBatches {
        batchRequestName
        documentSetTemplate {
          name
        }
        bpmProcessId
        requestedBy {
          identifier
          firstName
          lastName
        }
        documentSetsCount {
          successful
          failure
          totalRequested
        }
        batchStatus
        requestedAt
        documentSetBatchId
      }
    }
  }
`;

export const GET_DOCUMENT_SET_BATCH_BY_BPM_ID_QUERY = gql`
  query documentBatchRequest($bpmProcessId: String!) {
    documentBatchRequest(filter: { bpmProcessId: $bpmProcessId }) {
      batchRequestName
      documentSetBatchId
      bpmProcessId
      documentSets {
        friendlyId
        trackId
        documentSetId
        documentSetName
        documentSetStatus
        fileType
      }
    }
  }
`;

export const CANCEL_DOCUMENT_SET_BATCH_REQUEST = gql`
  mutation CancelDocumentSetBatch($bpmProcessId: IdentifierType!) {
    cancelDocumentSetBatch(bpmProcessId: $bpmProcessId) {
      ok
    }
  }
`;

export const REQUEST_DOCUMENT_SET_BATCH_QUERY = gql`
  mutation createDocumentSetBatch(
    $batchRequestName: String!
    $documentSetTemplateId: IdentifierType!
    $friendlyIds: [String!]!
    $includedDocumentVersions: DocumentSetIncludedDocumentVersionsEnum!
    $notifyAfterCompletion: Boolean!
    $outputFormat: DocumentSetOutputFormatEnum!
  ) {
    generateDocumentSetBatch(
      batchRequestName: $batchRequestName
      documentSetTemplateId: $documentSetTemplateId
      friendlyIds: $friendlyIds
      includedDocumentVersions: $includedDocumentVersions
      notifyAfterCompletion: $notifyAfterCompletion
      outputFormat: $outputFormat
    ) {
      bpmProcessId
    }
  }
`;
