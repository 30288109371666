import { gql } from '@apollo/client';

import TaskList from '../../components/TaskList/TaskList';
import { TASK_DETAILS, UPCOMING_TASK_DETAILS } from '../fragments';

export const UPDATE_REVIEW_STATUS = gql`
  mutation UpdateReviewStatus($reviewStatus: ReviewStatusEnum!, $trackId: IdentifierType!) {
    updateReviewStatus(reviewStatus: $reviewStatus, trackId: $trackId) {
      track {
        id
        internalTasks {
          ...TaskDetails
        }
        upcomingInternalTasks {
          ...UpcomingTaskDetails
        }
        ...TaskListData
      }
    }
  }
  ${TASK_DETAILS}
  ${UPCOMING_TASK_DETAILS}
  ${TaskList.fragments.TaskListData}
`;
