import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Outlet, useParams } from 'react-router-dom';
import { Badge, NotFoundBlock, Paper } from '@hometap/htco-components';
import cx from 'classnames';

import TrackDetailsBanner from 'components/TrackDetailsBanner/TrackDetailsBanner';

import { STATUS_DATA } from './utils/applicationUnlockConstants';
import SectionLoadingWrapper from './SectionLoadingWrapper';

import TrackNav from '../../components/TrackNav/TrackNav';
import { isTrackClosedLost } from 'apps/tracks/utils';

import './TrackDetails.scss';
import useCurrentUser from 'hooks/useCurrentUser';

export const MAIN_CONTENT_PADDING_X = 4;

const GET_TRACK = gql`
  query GetTrack($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      fullAddress
      fullNameShort
      currentStage {
        stageClassName
      }
      friendlyId
      hasAcceptedOffer
      identifier
      rolloutFlags

      applicants {
        fullNameShort
        id
      }

      homeowner {
        identifier
      }

      product {
        name
      }

      application {
        id
        unlockStatus
      }
      applicationReviewPermissions {
        canRead
      }
      activeOpportunityRecord {
        url
      }
    }
  }
`;

// TODO: Add tests, including for trackIsClosedLostAndNotServicer
const TrackDetailsController = ({ optOutOfDisabledState = false }) => {
  const { trackId } = useParams();
  const { loading, error, data } = useQuery(GET_TRACK, { variables: { trackId } });
  const { track } = data || {};
  const { isContractor, isInServicerGroup } = useCurrentUser();
  const trackIsClosedLost = isTrackClosedLost(track);
  const trackIsClosedLostAndNotServicer = trackIsClosedLost && !isInServicerGroup;

  if (!track && !loading) {
    return (
      <NotFoundBlock>
        You may be here because this page has moved, no longer exists, or you do not have permission to view it.
      </NotFoundBlock>
    );
  }
  const applicationUnlockStatus = STATUS_DATA[track?.application?.unlockStatus] || null;
  const closedLostBadge = isTrackClosedLost(track) && <Badge label="Closed Lost" theme="danger" />;

  return (
    <SectionLoadingWrapper loading={loading} error={error}>
      <div className="TrackDetailsContainer">
        <div className="TrackDetails">
          <div className="TrackDetailsContentContainer">
            <TrackDetailsBanner
              track={track}
              badge={closedLostBadge}
              isTrackDisabled={trackIsClosedLostAndNotServicer}
              applicationUnlockStatus={applicationUnlockStatus}
            />
            <Paper
              theme="plain"
              // aligns nav items to allow for left alignment while maintaining padding of first element
              pad={{ left: MAIN_CONTENT_PADDING_X - 1.6, right: MAIN_CONTENT_PADDING_X }}
              className="TrackDetailsTrackNav"
            >
              <TrackNav track={track} isContractor={isContractor} />
            </Paper>
            <Paper
              theme="plain"
              className={cx('TrackDetailsContent', {
                isTrackDisabled: trackIsClosedLostAndNotServicer && !optOutOfDisabledState,
              })}
            >
              <Outlet
                context={{
                  homeownerFullName: track?.fullNameShort,
                  homeowner: { identifier: track?.homeowner.identifier },
                  isTrackDisabled: trackIsClosedLostAndNotServicer,
                  applicationUnlockStatus,
                }}
              />
            </Paper>
          </div>
        </div>
      </div>
    </SectionLoadingWrapper>
  );
};

export default TrackDetailsController;
