import React from 'react';
import { MuiSelect, MuiTextarea } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { isEmpty } from 'lodash';
import './BackgroundCheckController.scss';

export const BackgroundCheckController = ({ track, editDisabled }) => {
  const { trackId } = useParams();
  const { editBackgroundChecksUrl, historyBackgroundChecksUrl } = getApplicationReviewPageUrls(trackId);
  const navToEditView = () => (window.location.href = editBackgroundChecksUrl);

  // In development of the read-only ARP, disable all inputs
  const readOnly = true;

  const { applicants } = track;
  const applicantsWithBackgroundChecks = applicants?.filter(applicant => !isEmpty(applicant.backgroundCheckReview));

  const doNothingOnChange = () => {};

  const BackgroundCheckForm = ({ applicant }) => (
    <>
      <h5 className="CustomBackgroundCheckHeader">{applicant.fullName}</h5>
      <div className={'TwoItemFormRow'}>
        <MuiSelect
          label="Status"
          name="statusSelector"
          value={applicant.backgroundCheckReview.status.toLowerCase()}
          options={applicant.backgroundCheckReview.statusOptions}
          disabled={readOnly}
          theme="outlined"
          width="100%"
          onChange={doNothingOnChange}
        />
        <MuiSelect
          label="Condition"
          name="conditionSelector"
          value={applicant.backgroundCheckReview.condition && applicant.backgroundCheckReview.condition.toLowerCase()}
          options={applicant.backgroundCheckReview.conditionOptions}
          disabled={readOnly}
          theme="outlined"
          width="100%"
          onChange={doNothingOnChange}
        />
      </div>
      <MuiTextarea
        value={applicant.backgroundCheckReview.notes}
        label="Notes"
        theme="outlined"
        width="100%"
        disabled={readOnly}
      />
    </>
  );

  return (
    <ApplicationReviewSection
      anchorId="background-checks-section"
      sectionTitle="Background checks"
      historyUrl={historyBackgroundChecksUrl}
      onEdit={editDisabled ? null : navToEditView}
    >
      {!applicantsWithBackgroundChecks ?? applicantsWithBackgroundChecks.length === 0 ? (
        <div>There are no background checks</div>
      ) : (
        applicantsWithBackgroundChecks.map((applicant, index) => (
          <BackgroundCheckForm key={index} applicant={applicant} />
        ))
      )}
    </ApplicationReviewSection>
  );
};
