import { RotateDirection } from '@react-pdf-viewer/core';
import { Icon } from '@hometap/htco-components';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import './PreviewDocumentLeftbar.scss';

/**
 * @typedef PreviewDocumentLeftbar
 * @type {object}
 * @property {import('../../documentRequests').DocumentOrVersion} viewDocumentOrVersion
 * @property {import('@react-pdf-viewer/core').Plugin} thumbnailPluginInstance
 */

/** Document Viewer's Leftbar used to display Thumbnails of PDF Pages
 * @param {PreviewDocumentLeftbarParams} params
 * @returns {JSX.Element?}
 *    -   Viewer is Embedded                      ->    Never Called
 *    -   Not a PDF                               ->    null
 *    -   PDF is Infected or Encrypted            ->    null
 *    -   Clean PDF or Anti-Virus Disabled        ->    PDF Thumbnails per Page
 */
export default function PreviewDocumentLeftbar({ viewDocumentOrVersion, thumbnailPluginInstance }) {
  const { isPDF, isAVCleanOrDisabled } = viewDocumentOrVersion ?? {};
  if (!isPDF || !isAVCleanOrDisabled) {
    return null;
  }
  const { Thumbnails } = thumbnailPluginInstance;
  return (
    <div className="PreviewDocumentLeftbar">
      <Thumbnails
        renderThumbnailItem={({ key, onJumpToPage, renderPageThumbnail, onRotatePage, renderPageLabel }) => (
          <div className="PreviewDocumentThumbnailItem" key={key}>
            <div className="PreviewDocumentThumbnailItemThumbnail" onClick={onJumpToPage}>
              {renderPageThumbnail}
            </div>
            <div className="PreviewDocumentThumbnailItemRotationButtonContainer">
              <button
                className="PreviewDocumentPageRotateButton"
                onClick={() => onRotatePage(RotateDirection.Backward)}
              >
                <Icon size="2x" className="PreviewDocumentButton" name="icon-rotate-left" />
              </button>
              <span className="PreviewDocumentThumbnailPageLabel">{renderPageLabel}</span>
              <button className="PreviewDocumentPageRotateButton" onClick={() => onRotatePage(RotateDirection.Forward)}>
                <Icon size="2x" className="PreviewDocumentButton" name="icon-rotate-right" />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
