import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { TASK_STATUSES } from '../../../../../../../data/constants/taskStatuses';
import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput, useForm } from '@hometap/htco-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import './HomeownersInsuranceReview.scss';
import SelectionYesNoSet from '../SelectionYesNoSet/SelectionYesNoSet';
import { emailValidator, phoneValidator } from 'utils/validators';
import { getHomeownerTodoReason } from '../../../../../todos/todoUtils';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { useMutation } from '@apollo/client';
import {
  CREATE_HOMEOWNERS_INSURANCE_POLICY,
  CREATE_HOMEOWNERS_COOP_INSURANCE_POLICY,
  UPDATE_HOMEOWNERS_INSURANCE_POLICY,
  UPDATE_HOMEOWNERS_COOP_INSURANCE_POLICY,
} from '../../../../data/mutations';
import PropTypes from 'prop-types';
import { TaskPropType } from '../../TaskDetail';
import { CONFIGURATIONS_PROPERTY_TYPE, CONFIGURATIONS_RESIDENCE_TYPE } from 'data/constants/configurations';
import { getExpirationDateData, getTheDateWithDaysAdded } from 'utils/dates';
import { getTodoNotesOptions } from '../../../../../utils/trackDetailsTodo';
import { useParams } from 'react-router';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useSpecificContentQuery,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { GET_HOMEOWNERS_INSURANCE_REVIEW } from './getHomeownersInsuranceReview';
import { FLORIDA_LOW_REPLACEMENT_COST } from 'apps/track-details/data/constants/messages';
import { INSURANCE_POLICY_KINDS } from 'apps/track-details/data/constants/policyKinds';
import { getVariableValue } from '../../../TaskList/TaskListUtils';

const OTHER = 'Other';

const AGENT_CONTACT_TYPE = {
  Email: 'email',
  Phone: 'phone',
};

const AGENT_CONTACT_TYPE_OPTIONS = [
  { value: AGENT_CONTACT_TYPE.Email, label: 'Email' },
  { value: AGENT_CONTACT_TYPE.Phone, label: 'Phone' },
];

const TOOLTIP_CONTENT = (
  <div>
    A valid policy means the:
    <br />
    1. Name on Application matches name on HOI policy
    <br />
    2. Address on Application matches address on HOI policy
    <br />
    3. HOI policy is current and paid for
  </div>
);

const INFORMATION_BLOCK = {
  invalidText:
    'Due to an invalid document, a request for an updated homeowners insurance policy will be sent when this task is marked as complete.',
  condoTownhouseOrApartmentText:
    'Due to property type rules, a request for a condo master policy will be sent when this task is marked as complete.',
  isNotReplacementCostEstimatorText:
    'A request for a Replacement Cost Calculator will be sent when this task is marked as complete.',
  isNotResidenceTypeAsVacationText:
    'A request for an updated Homeowners Insurance document, including proof of vacation residence type, will be sent when this task is marked as complete.',
  isExpiringPolicy:
    "Due to expiration rules, a request for an updated Homeowner's Insurance Policy will be sent when this task is marked as complete.",
  requestProofOfRental: 'A request for Proof of Rental will be sent when this task is marked as complete.',
  coOpPropType: 'The property type Co-op is a DQ property type. Please coordinate with your IM to discuss next steps.',
};

/**
 * @typedef HomeownersInsuranceFormData
 * @type {object}
 * @property {TrueFalseValue} [isValid]
 * @property {TrueFalseValue} [isVacation]
 * @property {TrueFalseValue} [replacementCost]
 * @property {'email'|'phone'} [contactType]
 * @property {string} [residenceType]
 * @property {string} [propertyType]
 * @property {string} [agentName]
 * @property {string} [agentEmail]
 * @property {string} [agentPhone]
 * @property {string} [provider]
 * @property {string} [providerName]
 * @property {string} [renewalDate]
 * @property {string} [policyNumber]
 * @property {string} [reason]
 * @property {string} [reasonNote]
 * @property {number} [dwellingAmount]
 */
/**
 * @typedef UseHomeownersInsuranceFormReturn
 * @type {object}
 * @property {HomeownersInsuranceFormData} formData
 * @property {(name: string, valueProp?: string) => RegisterFieldReturn} registerField
 */
/**
 * @typedef HomeownersInsuranceParams
 * @type {object}
 * @property {Task} task
 */
/**
 * @param {HomeownersInsuranceParams} params
 * @return JSX.Element
 */
const HomeownersInsuranceReview = ({ task }) => {
  const { trackId } = useParams();
  const { registerField, formData = {}, updateFormData, errors } = useForm({});
  const { taskKinds, allConfigurations } = useConfigurations();
  const { data: homeownersInsuranceData } = useSpecificContentQuery(GET_HOMEOWNERS_INSURANCE_REVIEW);
  const { user } = useCurrentUser();

  const taskId = task.identifier;
  const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);

  const extendedTaskData = useMemo(
    () => getHomeownersInsuranceReviewExtendedData(homeownersInsuranceData?.track, allConfigurations),
    [homeownersInsuranceData, allConfigurations],
  );
  const { createOrUpdateHomeownersInsuranceTask, isCreatingOrUpdatingHomeownersInsuranceTask, initialFormData } =
    useHomeownersInsuranceReviewTaskForm(trackId, uploadedDocIds, extendedTaskData);

  const { isValid, reason, residenceType, contactType, provider, propertyType, replacementCost, isVacation } = formData;
  const {
    residenceType: initialResidenceType,
    propertyType: initialPropertyType,
    replacementCost: initialReplacementCost,
  } = initialFormData;

  const { propertyTypes = [], residenceTypes = [], insuranceProviders = [] } = extendedTaskData;
  const providerOptions = insuranceProviders?.map(provider => ({ value: provider, label: provider })) || [];
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const isApplicationFloridaState = homeownersInsuranceData?.track?.home?.address?.state === 'FL';
  const canUpdateTaskData = !!homeownersInsuranceData;
  const isCoOpPropType = propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp;
  const isChangedResidenceOrPropertyTypes =
    residenceType !== initialResidenceType || propertyType !== initialPropertyType;

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.HOMEOWNER_INSURANCE_REVIEW_V1,
    taskKinds: taskKinds,
  });

  const isValidForm = useMemo(() => {
    return getIsValidHomeownerInsuranceReviewTaskForm(user, formData, errors, isChangedResidenceOrPropertyTypes);
  }, [user, formData, errors, isChangedResidenceOrPropertyTypes]);

  useEffect(() => {
    if (canUpdateTaskData) {
      updateSpecificTaskById(taskId, {
        saving: isCreatingOrUpdatingHomeownersInsuranceTask,
        complete: createOrUpdateHomeownersInsuranceTask,
        initialFormData,
        formData,
        updateFormData,
        isValidForm,
      });
    }
  }, [
    isCreatingOrUpdatingHomeownersInsuranceTask,
    createOrUpdateHomeownersInsuranceTask,
    updateSpecificTaskById,
    taskId,
    initialFormData,
    formData,
    updateFormData,
    isValidForm,
    canUpdateTaskData,
  ]);

  useEffect(() => {
    if (propertyType !== undefined) {
      if (isValid === 'false') {
        updateSpecificTaskById(taskId, {
          complete: undefined,
        });
      }
      if (isValid === 'true') {
        updateSpecificTaskById(taskId, {
          complete: createOrUpdateHomeownersInsuranceTask,
        });
      }
    }
  }, [isCoOpPropType, propertyType, taskId, updateSpecificTaskById, isValid, createOrUpdateHomeownersInsuranceTask]);

  const renewalDateData = getExpirationDateData(formData.renewalDate);

  return (
    <TaskDetailContentSectionReview
      header="Document validation"
      label="Is this a valid Homeowner’s Insurance Policy? "
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      isWithDivider={true}
      tooltipContent={TOOLTIP_CONTENT}
      invalidInformationText={INFORMATION_BLOCK.invalidText}
      reasonOptions={REASON_OPTIONS}
    >
      <div className="TaskDetailContentTitle">Policy details</div>
      <div>
        <MuiSelect
          label="Property type"
          classNamePrefix="HomeownersInsuranceReviewSelect"
          options={propertyTypes}
          theme="outlined"
          width="100%"
          helperText="From Application. Please verify"
          disabled={isCompleted}
          {...registerField('propertyType')}
        />

        {isChangedResidenceOrPropertyTypes && (
          <>
            {propertyType === CONFIGURATIONS_PROPERTY_TYPE.CondoTownhouseOrApartment && (
              <DetailInformationBlock
                className="HomeownersInsuranceReviewBlockText"
                isCompleted={isCompleted}
                text={INFORMATION_BLOCK.condoTownhouseOrApartmentText}
              />
            )}
          </>
        )}
        {isCoOpPropType && (
          <DetailInformationBlock
            type="alert"
            className="HomeownersInsuranceReviewBlockText"
            isCompleted={isCompleted}
            text={INFORMATION_BLOCK.coOpPropType}
          />
        )}
      </div>
      {!isCoOpPropType && (
        <>
          <div>
            <MuiSelect
              label="Residence type"
              classNamePrefix="HomeownersInsuranceReviewSelect"
              options={residenceTypes}
              theme="outlined"
              width="100%"
              helperText="From Application. Please verify"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('residenceType')}
            />
          </div>
          {isChangedResidenceOrPropertyTypes && (
            <>
              {residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Vacation && (
                <div>
                  <SelectionYesNoSet
                    isWithMarginBottom={false}
                    label="Does the policy confirm the residence type as vacation?"
                    disabled={isCompleted || isCoOpPropType}
                    {...registerField('isVacation')}
                  />
                  {isVacation === 'false' && !isCoOpPropType && (
                    <DetailInformationBlock
                      className="HomeownersInsuranceReviewBlockText"
                      isCompleted={isCompleted}
                      text={INFORMATION_BLOCK.isNotResidenceTypeAsVacationText}
                    />
                  )}
                </div>
              )}

              {residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental && !isCoOpPropType && (
                <DetailInformationBlock isCompleted={isCompleted} text={INFORMATION_BLOCK.requestProofOfRental} />
              )}
            </>
          )}
          <CurrencyInput
            label="Annual insurance cost"
            padFractionalZeros={true}
            disabled={isCompleted || isCoOpPropType}
            {...registerField('annualCost')}
          />
          <MuiSelect
            label="Provider"
            classNamePrefix="HomeownersInsuranceReviewSelect"
            options={providerOptions}
            theme="outlined"
            width="100%"
            disabled={isCompleted || isCoOpPropType}
            {...registerField('provider')}
          />
          {provider === OTHER && (
            <MuiTextInput
              label="Provider name"
              theme="outlined"
              width="100%"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('providerName')}
            />
          )}
          <div className="TwoItemFormRow">
            <MuiTextInput
              label="Agent name (optional)"
              theme="outlined"
              width="100%"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('agentName')}
            />
            <MuiSelect
              label="Agent contact type"
              options={AGENT_CONTACT_TYPE_OPTIONS}
              theme="outlined"
              width="100%"
              classNamePrefix="HomeownersInsuranceReviewSelect"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('contactType')}
            />
          </div>
          {contactType && (
            <div>
              {contactType === AGENT_CONTACT_TYPE.Email && (
                <MuiTextInput
                  label="Agent email (optional)"
                  theme="outlined"
                  validator={emailValidator}
                  width="100%"
                  disabled={isCompleted || isCoOpPropType}
                  {...registerField('agentEmail')}
                />
              )}
              {contactType === AGENT_CONTACT_TYPE.Phone && (
                <MuiTextInput
                  label="Agent phone (optional)"
                  validator={phoneValidator}
                  theme="outlined"
                  mask="000-000-0000"
                  width="100%"
                  disabled={isCompleted || isCoOpPropType}
                  {...registerField('agentPhone')}
                />
              )}
            </div>
          )}
          <div className="TwoItemFormRow">
            <DatePicker
              className="HomeownersInsuranceReviewDatePicker"
              label="Renewal date"
              theme="outlined"
              width="100%"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('renewalDate')}
            />
            <MuiTextInput
              label="Policy number"
              theme="outlined"
              width="100%"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('policyNumber')}
            />
          </div>
          {renewalDateData.isExpiring && !isCoOpPropType && (
            <DetailInformationBlock isCompleted={isCompleted} text={INFORMATION_BLOCK.isExpiringPolicy} />
          )}
          <div>
            <SelectionYesNoSet
              isWithMarginBottom={false}
              label="Is there proof of replacement cost?"
              disabled={isCompleted || isCoOpPropType}
              {...registerField('replacementCost')}
            />
            {replacementCost === 'false' &&
              !isCoOpPropType &&
              (initialReplacementCost === 'true' || initialReplacementCost === undefined) && (
                <DetailInformationBlock
                  className="HomeownersInsuranceReviewBlockText"
                  isCompleted={isCompleted}
                  text={INFORMATION_BLOCK.isNotReplacementCostEstimatorText}
                />
              )}
            {isApplicationFloridaState && (
              <DetailInformationBlock
                className="HomeownersInsuranceReviewBlockText"
                text={FLORIDA_LOW_REPLACEMENT_COST}
                type="info"
              />
            )}
          </div>
          <CurrencyInput
            label="Coverage amount"
            disabled={isCompleted || isCoOpPropType}
            extendedMaskProps={{ scale: 0 }}
            {...registerField('dwellingAmount')}
          />
        </>
      )}
    </TaskDetailContentSectionReview>
  );
};

export const HomeownersInsuranceFormData = PropTypes.shape({
  isValid: PropTypes.oneOf(['true', 'false']),
  isVacation: PropTypes.oneOf(['true', 'false']),
  residenceType: PropTypes.string,
  propertyType: PropTypes.string,
  agentName: PropTypes.string,
  agentEmail: PropTypes.string,
  agentPhone: PropTypes.string,
  provider: PropTypes.string,
  providerName: PropTypes.string,
  renewalDate: PropTypes.string,
  policyNumber: PropTypes.string,
  reason: PropTypes.string,
  reasonNote: PropTypes.string,
  dwellingAmount: PropTypes.number,
  replacementCost: PropTypes.oneOf(['true', 'false']),
  contactType: PropTypes.oneOf(['email', 'phone']),
});

const HomeownersInsuranceFormReturn = PropTypes.shape({
  registerField: PropTypes.func,
  formData: HomeownersInsuranceFormData,
});

const OptionsType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

const ExtendedHomeownersInsuranceDataType = PropTypes.shape({
  propertyTypes: PropTypes.arrayOf(OptionsType),
  residenceTypes: PropTypes.arrayOf(OptionsType),
  insuranceProviders: PropTypes.arrayOf(PropTypes.string),
});

HomeownersInsuranceReview.propTypes = {
  task: TaskPropType,
  useTaskSpecificFormReturn: HomeownersInsuranceFormReturn,
  extendedTaskData: ExtendedHomeownersInsuranceDataType,
};

export default HomeownersInsuranceReview;

/**
 * @param {ExtendedTaskData} [extendedTaskData]
 * @returns {HomeownersInsuranceFormData}
 */
export function getHomeownersInsuranceReviewInitialFormData(extendedTaskData) {
  const { residenceType, propertyType, insurancePolicyReviews, insuranceProviders } = extendedTaskData;
  const homeownersInsuranceReview =
    insurancePolicyReviews?.find(({ kind }) => kind === INSURANCE_POLICY_KINDS.HOMEOWNERS) || {};
  const {
    agent,
    agentEmail,
    agentPhone,
    dwellingAmount,
    policyNumber,
    annualCost,
    provider,
    renewalDate,
    replacementCost,
  } = homeownersInsuranceReview;
  const initialFormData = {
    isValid: undefined,
    residenceType,
    propertyType,
    agentName: agent,
    annualCost,
    provider,
    renewalDate,
    policyNumber,
    dwellingAmount,
    contactType: AGENT_CONTACT_TYPE.Phone,
    replacementCost: (typeof replacementCost === 'boolean' && replacementCost.toString()) || undefined,
  };
  if (provider && insuranceProviders?.length && !insuranceProviders.includes(provider)) {
    initialFormData.provider = OTHER;
    initialFormData.providerName = provider;
  }
  if (agentPhone) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Phone;
    initialFormData.agentPhone = agentPhone;
  }
  if (agentEmail) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Email;
    initialFormData.agentEmail = agentEmail;
  }
  return initialFormData;
}

/**
 * @param {Track} [track]
 * @param {Configurations} [configurations]
 * @returns {ExtendedTaskData}
 */
export function getHomeownersInsuranceReviewExtendedData(track = {}, configurations = {}) {
  const { insurancePolicyReviews, home = {} } = track;
  const { propertyType, residenceType } = home;
  const { residenceTypes, propertyTypes } = configurations?.home || {};
  const { insuranceProviders } = configurations?.insurancePolicy || {};
  return {
    propertyTypes,
    residenceTypes,
    insuranceProviders,
    insurancePolicyReviews,
    residenceType,
    propertyType,
  };
}

/**
 * @param {HomeownersInsuranceFormData} [formData]
 * @param {Record<string, {message: string, show: boolean}>} [errors]
 * @param {boolean} [isResidenceOrPropertyTypesChanged] Is the residence or property type changed
 * @returns {boolean}
 */
export function getIsValidHomeownerInsuranceReviewTaskForm(
  user = {},
  formData = {},
  errors,
  isResidenceOrPropertyTypesChanged,
) {
  // Townhouse or condo
  const {
    providerName,
    isValid,
    reason,
    reasonNote,
    contactType,
    annualCost,
    provider,
    propertyType,
    residenceType,
    policyNumber,
    renewalDate,
    replacementCost,
    isVacation,
    dwellingAmount,
  } = formData;
  if (propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp) {
    return true;
  }
  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });
  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }
  if (
    isValid === 'true' &&
    propertyType &&
    residenceType &&
    annualCost &&
    provider &&
    contactType &&
    renewalDate &&
    policyNumber &&
    dwellingAmount &&
    replacementCost !== undefined
  ) {
    const isProviderValid = !!(provider === OTHER ? providerName : provider);
    const isResidenceTypeValid =
      residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Vacation && isResidenceOrPropertyTypesChanged
        ? isVacation !== undefined
        : residenceType;
    const isContactEmailValid = contactType === AGENT_CONTACT_TYPE.Email && !errors?.agentEmail;
    const isContactPhoneValid = contactType === AGENT_CONTACT_TYPE.Phone && !errors?.agentPhone;
    const isContactValid = isContactEmailValid || isContactPhoneValid;
    return !(!isProviderValid || !isContactValid || !isResidenceTypeValid);
  }
  return false;
}

/**
 * @typedef {({formData: HomeownersInsuranceFormData, extendedTaskData?: ExtendedTaskData}) => void} CreateOrUpdateHomeownersInsuranceTask
 */
/**
 * @typedef UseHomeownersInsuranceReviewTaskForm
 * @type {object}
 * @property {boolean} [isCreatingOrUpdatingHomeownersInsuranceTask]
 * @property {CreateOrUpdateHomeownersInsuranceTask} [createOrUpdateHomeownersInsuranceTask]
 * @property {HomeownersInsuranceFormData} [initialFormData]
 */
/**
 * @param {string} [trackId]
 * @param {[string]} [uploadedDocIds]
 * @param {ExtendedTaskData} extendedTaskData
 * @returns {UseHomeownersInsuranceReviewTaskForm}
 */
export function useHomeownersInsuranceReviewTaskForm(trackId, uploadedDocIds, extendedTaskData) {
  const [createHomeownersInsurance, { loading: isCreateHomeownersInsuranceLoading }] = useMutation(
    CREATE_HOMEOWNERS_INSURANCE_POLICY,
  );
  const [updateHomeownersInsurance, { loading: isUpdateHomeownersInsuranceLoading }] = useMutation(
    UPDATE_HOMEOWNERS_INSURANCE_POLICY,
  );
  const [createHomeownersCoOpInsurance, { loading: isCreateHomeownersCoOpInsuranceLoading }] = useMutation(
    CREATE_HOMEOWNERS_COOP_INSURANCE_POLICY,
  );
  const [updateHomeownersCoOpInsurance, { loading: isUpdateHomeownersCoOpInsuranceLoading }] = useMutation(
    UPDATE_HOMEOWNERS_COOP_INSURANCE_POLICY,
  );
  const initialFormData = useMemo(
    () => getHomeownersInsuranceReviewInitialFormData(extendedTaskData),
    [extendedTaskData],
  );
  const isCreatingOrUpdatingHomeownersInsuranceTask =
    isCreateHomeownersInsuranceLoading ||
    isUpdateHomeownersInsuranceLoading ||
    isCreateHomeownersCoOpInsuranceLoading ||
    isUpdateHomeownersCoOpInsuranceLoading;

  const createOrUpdateHomeownersInsuranceTask = useCallback(
    async ({ formData = {} }) => {
      const homeownersInsuranceReview =
        extendedTaskData.insurancePolicyReviews?.find(({ kind }) => kind === INSURANCE_POLICY_KINDS.HOMEOWNERS) || {};
      if (formData?.isValid !== 'true') {
        return;
      }
      const {
        providerName,
        agentPhone,
        agentEmail,
        contactType,
        annualCost,
        provider,
        propertyType,
        residenceType,
        policyNumber,
        renewalDate,
        replacementCost,
        agentName,
        dwellingAmount,
      } = formData;
      const variables = {
        uploadedDocIds,
        agentName,
        kind: INSURANCE_POLICY_KINDS.HOMEOWNERS,
        policyNumber,
        propertyType,
        annualCost: parseFloat(annualCost).toFixed(2),
        provider,
        renewalDate,
        replacementCost: replacementCost === 'true',
        residenceType,
      };
      const isCoOpPropType = propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp;
      if (dwellingAmount) {
        variables.dwellingAmount = dwellingAmount;
      }
      if (contactType === AGENT_CONTACT_TYPE.Phone) {
        variables.agentPhone = agentPhone;
      }
      if (contactType === AGENT_CONTACT_TYPE.Email) {
        variables.agentEmail = agentEmail;
      }
      if (provider === OTHER) {
        variables.provider = providerName;
      }

      if (homeownersInsuranceReview && homeownersInsuranceReview.identifier) {
        const updateHomeownersInsuranceMutation = isCoOpPropType
          ? updateHomeownersCoOpInsurance
          : updateHomeownersInsurance;
        await updateHomeownersInsuranceMutation({
          variables: { ...variables, insurancePolicyId: homeownersInsuranceReview.identifier },
        });
      } else {
        const createHomeownersInsuranceMutation = isCoOpPropType
          ? createHomeownersCoOpInsurance
          : createHomeownersInsurance;
        await createHomeownersInsuranceMutation({
          variables: { ...variables, trackId },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      trackId,
      createHomeownersInsurance,
      updateHomeownersInsurance,
      createHomeownersCoOpInsurance,
      updateHomeownersCoOpInsurance,
      extendedTaskData,
    ],
  );

  return {
    isCreatingOrUpdatingHomeownersInsuranceTask,
    createOrUpdateHomeownersInsuranceTask,
    initialFormData,
  };
}

/**
 * @typedef HomeownersInsuranceTodosToCreate
 * @type {object}
 * @property {HomeownersInsuranceFormData} [formData]
 * @property {} [taskKinds]
 * @property {HomeownersInsuranceFormData} [initialFormData]
 */
/**
 * @param {HomeownersInsuranceTodosToCreate} [data]
 * @returns {HomeownersTodoToCreate[] | null}
 */
export const getHomeownersInsuranceAdditionalTodosToCreate = ({
  formData: { isValid, propertyType, residenceType, replacementCost, isVacation, renewalDate },
  taskKinds,
  initialFormData: {
    residenceType: initialResidenceType,
    propertyType: initialPropertyType,
    replacementCost: initialReplacementCost,
  },
}) => {
  if (isValid === 'false') {
    // Do not create an additional to-dos if the AS maked the document as invalid
    return null;
  }
  if (propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp) {
    // we don't continue processing an ineligible file
    return null;
  }
  const renewalDateData = getExpirationDateData(renewalDate);
  const isExpiringRenewalDate = renewalDateData.isExpiring;
  const isChangedResidenceOrPropertyTypes =
    residenceType !== initialResidenceType || propertyType !== initialPropertyType;
  const todosToCreate = [];
  if (replacementCost === 'false' && (initialReplacementCost === 'true' || initialReplacementCost === undefined)) {
    todosToCreate.push({
      kind: taskKinds.UPLOAD_REPLACEMENT_COST_ESTIMATOR_ON_HOI,
    });
  }
  if (!isChangedResidenceOrPropertyTypes) {
    return todosToCreate.length ? todosToCreate : null;
  }
  if (propertyType === CONFIGURATIONS_PROPERTY_TYPE.CondoTownhouseOrApartment) {
    todosToCreate.push({
      kind: taskKinds.UPLOAD_CONDO_MASTER_POLICY,
    });
  }
  // to avoid re-requesting todosToCreate with the same kind UPLOAD_HO_INSURANCE we check isExpiringRenewalDate
  if (residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Vacation && isVacation === 'false' && !isExpiringRenewalDate) {
    todosToCreate.push({
      kind: taskKinds.UPLOAD_HO_INSURANCE,
    });
  }
  if (residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental) {
    todosToCreate.push({
      kind: taskKinds.UPLOAD_PROOF_OF_RENTAL,
    });
  }
  if (todosToCreate.length) {
    return todosToCreate;
  }
  return null;
};

export const HO_INSURANCE_DEMO_DATA = {
  agentName: 'Bob',
  providerName: 'Whitney Smith',
  isValid: 'true',
  residenceType: CONFIGURATIONS_RESIDENCE_TYPE.PrimaryResidence,
  propertyType: CONFIGURATIONS_PROPERTY_TYPE.SingleFamilyHome,
  agentEmail: `test+${Math.random().toString(36).substring(5)}@hometap.com`,
  contactType: AGENT_CONTACT_TYPE.Email,
  annualCost: 999,
  provider: OTHER,
  policyNumber: '000123ABC',
  renewalDate: getTheDateWithDaysAdded(60),
  replacementCost: 'true',
  dwellingAmount: 400000,
};
