/**
 * getFullAddress - Create a method similar to `get_full_address_string` in `eng_portals`
 * @param {{ street: string, unit: string, city: string, state: string, zipCode: string }} address fields
 * @returns string
 */
export function getFullAddress({ street, unit, city, state, zipCode }) {
  if (!street || !city || !state || !zipCode) return '';
  const streetAndUnit = unit ? `${street}, ${unit}` : street;

  return `${streetAndUnit}, ${city}, ${state}, ${zipCode}`;
}
