import { useEffect, useState } from 'react';
import { checkOverflow } from 'utils/documents';

const useIsOverflow = (contaienrRef, trigger) => {
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (checkOverflow(contaienrRef)) {
      setOverflow(true);
      return;
    }
    setOverflow(false);
  }, [contaienrRef, trigger]);

  return overflow;
};

export default useIsOverflow;
