import { TaskPropType } from '../../TaskDetail';
import React, { useMemo } from 'react';
import { useAsync } from '@hometap/htco-components';

import { getSeniorLiensRequest } from 'apps/track-details/tasks/taskRequests';
import { useParams } from 'react-router-dom';
import SeniorLienDataEntry from '../SeniorLienDataEntry/SeniorLienDataEntry';

const SeniorLienReview = ({ task }) => {
  const { trackId } = useParams();
  const { loading: isLoading, results: seniorLiens = [] } = useAsync(getSeniorLiensRequest, {
    immediate: true,
    params: [{ track_id: trackId }],
    defaultLoading: true,
  });
  const lastDataEntry = useMemo(() => seniorLiens[0] || {}, [seniorLiens]);

  return <SeniorLienDataEntry task={task} isLoading={isLoading} lastDataEntry={lastDataEntry} isReassign={true} />;
};

SeniorLienReview.propTypes = {
  task: TaskPropType,
};

export default SeniorLienReview;
