import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { MuiSelect, MuiTextarea, Checkbox, DatePicker } from '@hometap/htco-components';
import {
  useUpdateSectionForm,
  useSectionFormById,
} from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import { useApolloClient } from '@apollo/client';
import { useInitialAdverseEventForm } from '../hooks/useInitialAdverseEventForm';
import { useAdverseEventForm } from '../hooks/useAdverseEventForm';
import { UPSERT_ADVERSE_EVENT } from '../../../data/mutations/upsertAdverseEvent';
import { DELETE_ADVERSE_EVENT } from '../../../data/mutations/deleteAdverseEvent';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { validateDateWithinYears } from 'utils/validators';
import { bankruptcy, shortSale, foreclosure } from '../utils/adverseEvents';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal';
import { helperText } from 'utils/dates';
import { HeapWrapper } from 'components/HeapWrapper/HeapWrapper';

const initialFormDataFromAdverseEventNode = adverseEvent => ({
  ...adverseEvent,
  kind: adverseEvent?.kind?.toLowerCase(),
  personIds: adverseEvent?.personIds ?? [],
  resolutionDate: adverseEvent?.resolutionDate,
  hasNotBeenResolved: adverseEvent?.hasNotBeenResolved,
  bankruptcyChapter: adverseEvent?.bankruptcyChapter?.toLowerCase(),
  bankruptcyOrder: adverseEvent?.bankruptcyOrder?.toLowerCase(),
  isForSubjectProperty: adverseEvent?.isForSubjectProperty?.toString(),
  notes: adverseEvent?.notes,
});

export const AdverseEventForm = ({
  disabled,
  adverseEvent,
  adverseEventKindOptions,
  applicantsOptions,
  adverseEventBankruptcyChapterOptions,
  adverseEventBankruptcyOrderOptions,
  trackId,
  onDelete,
  prevSectionId,
  refetch,
}) => {
  const { updateSectionFormById, saveFormOnChangeSection, deleteFormById } = useUpdateSectionForm();
  const {
    mutateAdverseEvent,
    deleteAdverseEvent,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    showSubmitErrors,
  } = useAdverseEventActions(adverseEvent, trackId);
  const { hasNotBeenResolved, newAdverseEvent, kind, resolutionDate, bankruptcyOrder } = formData;

  const adverseEventId = adverseEvent?.identifier;

  const { formData: prevFormDataToSave } = useSectionFormById(prevSectionId);

  useEffect(() => {
    if (prevSectionId && prevSectionId !== adverseEventId && prevSectionId === prevFormDataToSave?.identifier) {
      saveFormOnChangeSection(prevSectionId);
    }
  }, [prevSectionId, adverseEventId, prevFormDataToSave?.identifier, saveFormOnChangeSection]);

  // Calculate the years since resolution date if it exists for disqualification calculations
  const yearsSinceResolution = resolutionDate === undefined ? null : dayjs().diff(resolutionDate, 'year');

  useEffect(() => {
    updateSectionFormById(adverseEventId, {
      complete: mutateAdverseEvent,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      showSubmitErrors,
    });
  }, [
    mutateAdverseEvent,
    updateSectionFormById,
    adverseEventId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    showSubmitErrors,
  ]);

  useEffect(() => {
    if (hasNotBeenResolved) {
      updateFormData({ resolutionDate: null });
    }
  }, [hasNotBeenResolved, updateFormData]);

  const isBankruptcy = kind === bankruptcy;
  const isForeclosure = kind === foreclosure;
  const isShortSale = kind === shortSale;

  return (
    <FormWrapperWithDeleteConfirmModal
      title="Adverse event form"
      header="Delete adverse event"
      type="Adverse event"
      isDisabled={disabled}
      onDelete={async () => {
        if (!newAdverseEvent) {
          await deleteAdverseEvent();
          refetch();
        }
        onDelete(adverseEventId);
        deleteFormById(adverseEventId);
      }}
      canShowModal={!newAdverseEvent}
    >
      <div className={cx('TwoItemFormRow', { AdverseEventFormRowExtraPadding: isShortSale })}>
        <HeapWrapper dataId="adverse-event-type" dataValue={kind}>
          <MuiSelect
            required
            label="Adverse event type"
            options={adverseEventKindOptions}
            disabled={disabled}
            theme="outlined"
            width="100%"
            showRequiredAsterisk={false}
            {...registerField('kind')}
            helperText={isShortSale ? 'Enter only if Applicant was the Seller.' : ''}
          />
        </HeapWrapper>
        <MuiSelect
          data-id="selector-applicants"
          required
          label="Applicant(s)"
          multiple
          options={applicantsOptions}
          disabled={disabled}
          theme="outlined"
          width="100%"
          showRequiredAsterisk={false}
          {...registerField('personIds')}
        />
      </div>
      <DatePicker
        label="Resolution Date"
        theme="outlined"
        disabled={disabled || hasNotBeenResolved}
        className="FullWidthDatePicker"
        wrapperClassName="FullWidthDatePicker"
        validator={validateDateWithinYears}
        {...registerField('resolutionDate')}
        error={resolutionDate && validateDateWithinYears(resolutionDate)}
        showErrorIcon={true}
        helperText={helperText}
      />
      <Checkbox
        label="There is no resolution date for this adverse event."
        disabled={disabled}
        {...registerField('hasNotBeenResolved', 'checked')}
      />
      {hasNotBeenResolved && (
        <DetailInformationBlock
          type="alert"
          text="If there is no resolution date, please coordinate with your IM to Disqualify this track."
        />
      )}
      {isBankruptcy && (
        <div className={'TwoItemFormRow'}>
          <MuiSelect
            label="Chapter"
            required={isBankruptcy}
            options={adverseEventBankruptcyChapterOptions}
            disabled={disabled}
            theme="outlined"
            width="100%"
            {...registerField('bankruptcyChapter')}
          />
          <MuiSelect
            label="Order"
            required={isBankruptcy}
            options={adverseEventBankruptcyOrderOptions}
            disabled={disabled}
            theme="outlined"
            width="100%"
            {...registerField('bankruptcyOrder')}
          />
        </div>
      )}
      {isBankruptcy && bankruptcyOrder && yearsSinceResolution !== null && yearsSinceResolution < 1 && (
        <DetailInformationBlock
          type="alert"
          text={`Bankruptcy on this track was ${bankruptcyOrder.toLowerCase()} less than a year ago. Please coordinate with your IM to Disqualify this track`}
        />
      )}
      {isForeclosure && (
        <SelectionYesNoSet
          required={isForeclosure}
          label="Is this adverse event against the subject property?"
          disabled={disabled}
          theme="normal"
          isWithMarginBottom={false}
          {...registerField('isForSubjectProperty')}
        />
      )}
      {isForeclosure && yearsSinceResolution !== null && yearsSinceResolution < 5 && (
        <DetailInformationBlock
          type="alert"
          text="Foreclosure on this track were cured less than five years ago. Please coordinate with your IM to Disqualify this track."
        />
      )}
      {isShortSale && yearsSinceResolution !== null && yearsSinceResolution < 5 && (
        <DetailInformationBlock
          type="alert"
          text="One or more Short Sales on this track were cured less than five years ago. Please coordinate with your IM to Disqualify this track."
        />
      )}
      <MuiTextarea
        label="Notes (optional)"
        theme="outlined"
        width="100%"
        disabled={disabled}
        {...registerField('notes')}
      />
    </FormWrapperWithDeleteConfirmModal>
  );
};

export const useAdverseEventActions = (adverseEvent, trackId) => {
  const client = useApolloClient();
  const [adverseEventId, setAdverseEventId] = useState(adverseEvent?.identifier);

  useEffect(() => {
    setAdverseEventId(adverseEvent?.identifier);
  }, [adverseEvent?.identifier]);

  const { registerField, formData, updateFormData, isFormValid, initialFormData, showSubmitErrors } =
    useInitialAdverseEventForm(adverseEvent, initialFormDataFromAdverseEventNode);
  useAdverseEventForm(adverseEvent);

  const validateForm = () => {
    if (!isFormValid) return false;

    if (formData?.hasNotBeenResolved === undefined && formData?.resolutionDate == null) return false;

    const resolutionDateError = formData?.resolutionDate && validateDateWithinYears(formData.resolutionDate);
    if (resolutionDateError) {
      return false;
    }

    return true;
  };

  const mutateAdverseEvent = useCallback(
    async ({ formData = {} }) => {
      const {
        newAdverseEvent,
        kind,
        personIds,
        resolutionDate,
        hasNotBeenResolved,
        bankruptcyChapter,
        bankruptcyOrder,
        isForSubjectProperty,
        notes,
      } = formData;
      const variables = {
        kind: kind?.toUpperCase(),
        personIds,
        resolutionDate,
        hasNotBeenResolved,
        bankruptcyChapter,
        bankruptcyOrder,
        isForSubjectProperty: isForSubjectProperty === 'true',
        notes,
        trackId,
        adverseEventId: newAdverseEvent ? '' : adverseEventId,
      };

      if (kind === foreclosure || kind === shortSale) {
        variables.bankruptcyChapter = '';
        variables.bankruptcyOrder = '';
      }
      if (kind === bankruptcy || kind === shortSale) {
        variables.isForSubjectProperty = undefined;
      }

      try {
        const { data } = await client.mutate({
          mutation: UPSERT_ADVERSE_EVENT,
          variables,
        });
        const [mutationData] = Object.values(data);
        const newAdverseEventIdentifier = mutationData?.adverseEventId;
        setAdverseEventId(newAdverseEventIdentifier);
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: newAdverseEvent
            ? 'Adverse event successfully created.'
            : 'Changes to Adverse event successfully saved.',
        });

        return {
          newAdverseEventIdentifier:
            newAdverseEvent && newAdverseEventIdentifier ? newAdverseEventIdentifier : undefined,
        };
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.message || 'An error occurred while updating the adverse event.',
        });
      }
    },
    [trackId, adverseEventId, client],
  );

  const deleteAdverseEvent = useCallback(async () => {
    try {
      const { data } = await client.mutate({
        mutation: DELETE_ADVERSE_EVENT,
        variables: { trackId, adverseEventId },
      });
      const [mutationData] = Object.values(data);
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'Adverse event successfully deleted.',
      });

      return {
        result: mutationData,
      };
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to delete the adverse event',
        description: error.message || 'An error occurred while deleting the adverse event.',
      });
    }
  }, [trackId, adverseEventId, client]);

  return {
    mutateAdverseEvent,
    deleteAdverseEvent,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid: validateForm(),
    showSubmitErrors,
  };
};
