import React from 'react';
import { MuiSelect, MuiTextInput, MuiTextarea } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { booleanOptions, booleanOrNoneToValue } from 'utils/boolean';

import { ApplicationReviewSection, InvestmentIncreaseTable } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';

export const InvestmentIncreaseController = ({ track, editDisabled }) => {
  const { trackId } = useParams();
  const { editInvestmentIncreaseUrl, historyInvestmentIncreaseUrl } = getApplicationReviewPageUrls(trackId);

  // In development of the read-only ARP, disable all inputs
  const readOnly = true;
  const doNothingOnChange = () => {};

  //Extract values from backend data
  const { investmentIncrease, investmentIncreaseIdentifierOptions } = track || {};
  const { isInvestmentIncrease, investmentIncreaseIdentifiers, activeInvestmentMatches, notes } =
    investmentIncrease || {};
  const isInvestmentIncreaseValue = booleanOrNoneToValue(isInvestmentIncrease);

  const onClickEdit = function navigateToEditUrl() {
    window.location = editInvestmentIncreaseUrl;
  };

  const investmentIncreaseDetails =
    'It is crucial to identify if an opportunity is an investment increase and capture the corresponding data. Please\n' +
    ' review the information carefully. Note: This step is not applicable for Fund III investments.';

  return (
    <ApplicationReviewSection
      anchorId="investment-increase-section"
      sectionTitle="Investment increase"
      historyUrl={historyInvestmentIncreaseUrl}
      sectionDetails={investmentIncreaseDetails}
      onEdit={editDisabled ? null : onClickEdit}
    >
      <>
        <InvestmentIncreaseTable track={track} />
        <MuiSelect
          value={isInvestmentIncreaseValue}
          label="Investment Increase"
          headerText="Would this opportunity be an investment increase?"
          onChange={doNothingOnChange}
          options={booleanOptions}
          theme="outlined"
          width="100%"
          disabled={readOnly}
        />
        <MuiSelect
          value={investmentIncreaseIdentifiers || ''}
          label="Investment Increase Identifiers List"
          headerText="Which value(s) identify this as an investment increase?"
          multiple
          onChange={doNothingOnChange}
          options={investmentIncreaseIdentifierOptions}
          theme="outlined"
          width="100%"
          disabled={readOnly}
        />
        <MuiTextInput
          value={activeInvestmentMatches?.toString() || ''}
          label="Active Investment Matches"
          headerText="Which active Investment(s) matches this property?"
          theme="outlined"
          width="100%"
          disabled={readOnly}
        />
        <MuiTextarea value={notes} label="Notes" theme="outlined" width="100%" disabled={readOnly} />
      </>
    </ApplicationReviewSection>
  );
};

export default InvestmentIncreaseController;
