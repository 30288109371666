import heic2any from 'heic2any';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

/**
 * Returns file extension to determine between images and pdf files
 * @param {string} fileName
 * @returns {string} file extension. Example: "pdf"
 */
export const fileExtension = fileName => {
  return fileName.split('.').pop();
};

/**
 * Determines if file is a .heic file based on fileName
 * @param {string} fileName
 * @returns {bool} true = HEIC filetype
 */
export const isHEIC = fileName => {
  return fileExtension(fileName).toLowerCase() === 'heic';
};

/**
 *
 * @param {string} url Webpage url ending with a file name. ex: https://www.blah.com/example.jpeg
 * @returns
 */
export const stripFileName = url => {
  return url.substring(url.lastIndexOf('/') + 1);
};

/**
 * removes filename's extension
 * @param {string} fileName
 * @returns {string} fileName without extension
 */
export const stripFileExtension = ({ fileName, replaceWith = '' }) => {
  return fileName.replace(/\.[^/.]+$/, replaceWith);
};

/**
 * Given size in bytes, returns a string formatted based on decimal(default: 2)
 * @param {int/float} bytes
 * @param {int} decimals
 * @returns {string}
 */
export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  // sizes larger than GB not needed, included anyways to decrease likelihood out of bounds exception
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const DOCUMENT_STATUS = {
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  UPLOADED: 'uploaded',
};

// Generate a randomID for reference of the file since file.name is not necessarily unique.
export const generateFileId = file => {
  const randomKey = (Math.random() + 1).toString(36).substring(7);
  return `${file.name.replaceAll(/[^A-Z0-9]/gi, '_')}_${file.size}_${randomKey}`;
};

// Format a file for display to include id and status, ids on file param
// should take precedence so that they are not overridden.
export const formatFileObject = (file, status) => {
  if (file.file) {
    return {
      id: generateFileId(file.file),
      ...file,
      status,
    };
  }

  return {
    id: generateFileId(file),
    file,
    status,
  };
};

/**
 * returns bool if container is overflowing/ is scrollable
 * @param {ref.current} textContainer
 * @returns
 */
export const checkOverflow = textContainer => {
  return !!textContainer && textContainer.offsetHeight < textContainer.scrollHeight;
};

/**
 * Convert HEIC file into a png file
 * @param {blob} file
 * @returns {Promise} Once Promise has been resolved, a .jpeg file blob will be returned
 */
export const convertHeic = async file => {
  return await heic2any({ blob: file, toType: 'image/jpeg', quality: 1, multiple: false });
};

/**
 * Prepares file for upload. Defines logic to handle images, pdf's and document files such as .docx
 * @param {Object/blob} file
 * @returns {Promise/Object}
 */
export const prepareFileForUpload = async file => {
  // clone file Object to be modified
  const tempFile = { ...file };
  if (isHEIC(tempFile.file.path)) {
    try {
      const convertedFile = await convertHeic(tempFile.file);
      const fileName = stripFileExtension({ fileName: stripFileName(tempFile.file.path), replaceWith: '.jpeg' });
      const fileObject = new File([convertedFile], fileName);
      fileObject.visibility = tempFile.file.visibility;
      tempFile.file = fileObject;
      if (file.file.pinned) {
        tempFile.file.pinned = file.file.pinned;
      } else if (!file.file.hasOwnProperty('pinned')) {
        tempFile.file.pinned = true;
      }
      return formatFileObject(tempFile, 'accepted');
    } catch (error) {
      throw new Error(error);
    }
  }
  // NOTE: else if => handle docx, excel etc here...
  else {
    // if pinned is not defined, set it to true
    if (!file.file.hasOwnProperty('pinned')) {
      file.file.pinned = true;
    }
    return file;
  }
};

export const formatErrorMessage = message => {
  if (!message || typeof message !== 'string') return;

  // replace milliseconds to seconds
  const millisecondRegex = /(\d+)ms/;
  let match = message.match(millisecondRegex);
  if (match) {
    match = match[0].replace('ms', '');
    const seconds = match / 1000;
    message = message.replace(millisecondRegex, `${seconds} seconds`);
  }

  // Capitalize first letter of message
  message = message.charAt(0).toUpperCase() + message.slice(1);

  return message;
};

/**
 * @description Returns a boolean indicating whether the document is password protected. This function needs to be called within a pdfjs Worker.
 *
 * @param {blob} file - The file to check
 * @returns {boolean} - True if the document is password protected, false otherwise
 */
export const isDocumentPasswordProtected = async file => {
  try {
    const documentPromise = pdfjsLib.getDocument(URL.createObjectURL(file));
    const document = await documentPromise?.promise;
    if (document?._transport?._passwordCapability) {
      return true;
    }
  } catch (e) {
    if (e.name === 'PasswordException') {
      return true;
    }
  }
  return false;
};
