import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { debounce } from 'lodash';

const useInvestmentCalculator = (mutationQuery, trackId = null, defaultInputs = {}) => {
  const [inputData, setInputData] = useState(defaultInputs);

  const [maxInvestmentMutation, { loading, error: calcError, data }] = useMutation(mutationQuery);
  const calculatedData = data?.calculateUwInvestment || data?.calculateSalesInvestment;

  const updateCalcData = useCallback(
    newData => {
      maxInvestmentMutation({ variables: { ...newData, trackId } }).catch(() => {});
    },
    [trackId, maxInvestmentMutation],
  );

  useEffect(() => {
    updateCalcData(inputData);
    // We don't want to call this on inputData change because it should be debounced
    // Just want to fire it once when track is first loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JAKE-434: we can't use debounce in an inline function here to make eslint happy
  // causes the function to be recreated on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCalcData = useCallback(
    debounce(newData => updateCalcData(newData), 1500),
    [updateCalcData],
  );

  const handleUpdateInputObject = (newData = {}) => {
    const newObj = { ...inputData, ...newData };
    setInputData(newObj);
    debouncedUpdateCalcData(newObj);
  };

  const handleCalcInputChange = (key, newVal) => {
    const oldVal = inputData[key];
    if (oldVal !== newVal) {
      handleUpdateInputObject({ [key]: newVal });
    }
  };

  return {
    inputData,
    setInputData: handleUpdateInputObject,
    updateInputDataByKey: handleCalcInputChange,
    calculatedData,
    calculationsIsLoading: loading,
    calculationsError: calcError,
  };
};

export default useInvestmentCalculator;
