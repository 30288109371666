import React from 'react';
import cx from 'classnames';
import { Icon, Tooltip } from '@hometap/htco-components';

import './FormListView.scss';

const FormListView = ({ title, className = '', rows = [] }) => {
  return (
    <div className={cx('FormListViewData', className)}>
      {title && <h4>{title}</h4>}
      <div className="FormListViewDataRow">
        {rows.map((item, key) => {
          return [
            <div className="FormListViewDataWrapper" key={key}>
              <span className="FormListViewDataLabel" style={{ marginRight: 4 }}>
                {item.label}
              </span>

              {item.tooltipContent && (
                <Tooltip className="FormListViewDataLabelTooltip" width={600} content={item.tooltipContent}>
                  <Icon className="FormListViewDataLabelTooltipIcon" name="info" size="sm" />
                </Tooltip>
              )}

              <div className="FormListViewDataValueLinkWrapper">
                <span className="FormListViewDataValue">{item.value}</span>
                {item.link && (
                  <a href={item.link} target="_blank" rel="noreferrer" className="FormListViewDataLink">
                    Edit
                    <Icon name="icon-external" size="lg" />
                  </a>
                )}
              </div>
            </div>,
            item.additional && (
              <div className="FormListViewDataWrapper" key={`${key}-additional`}>
                {item.additional}
              </div>
            ),
          ];
        })}
      </div>
    </div>
  );
};
export default FormListView;
