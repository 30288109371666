import { gql } from '@apollo/client';

export const CREATE_AND_ASSIGN_TODOS = gql`
  mutation CreateAndAssignTodos(
    $todosInput: [CreateHomeownerTaskInput]!
    $trackId: IdentifierType!
    $assigneeId: IdentifierType!
  ) {
    createHomeownerTasks(tasksData: $todosInput, track: $trackId, assignee: $assigneeId) {
      tasks {
        id
      }
    }
  }
`;

export const SEND_HOMEOWNER_TASKS_REMINDER_EMAIL = gql`
  mutation SendHomeownerTasksReminderEmail($trackId: IdentifierType!) {
    sendHomeownerTasksReminderEmail(trackId: $trackId) {
      ok
    }
  }
`;
