import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@hometap/htco-components';

import EditDocumentForm from '../EditDocumentForm/EditDocumentForm';
import { SlideInFromRight } from '../SlideInFromRight/SlideInFromRight';
import { DOCUMENT_VISIBILITY } from '../../data/constants';
import { handleRelatedDocumentTasks, updateDocument } from '../../documentRequests';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { showNotification } from 'utils/toasts';

import './EditDocument.scss';

/**
 * This callback is displayed as a global member.
 * @callback open
 * @callback close
 * @callback open
 * @callback refresh
 * @callback getDocumentKindVisibility
 */
/**
 * @typedef EditDocument
 * @type {object}
 * @property {Document} document
 * @property {open} open
 * @property {close} close
 * @property {refresh} refresh
 * @property {getDocumentKindVisibility} getDocumentKindVisibility
 * @property {object} useDocumentKindsResults
 * @property {object} useTrackApplicantsResults
 */
/**
 * @param {EditDocument} params
 * @returns {JSX.Element}
 */
const EditDocument = ({
  open,
  close,
  document,
  useDocumentKindsResults,
  refresh,
  getDocumentKindVisibility,
  useTrackApplicantsResults,
}) => {
  const { trackId } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { applicantsOptions } = useTrackApplicantsResults;

  const { postMessage } = useBroadcastChannel({
    channelName: 'DOCS_UPDATED',
  });

  // complete any homeowner todos associated with this document and spawn review tasks
  const handleResolveTasks = async () => {
    const { todo_completed, todo_name } = await handleRelatedDocumentTasks(document.id);
    if (todo_completed) {
      showNotification({
        type: 'success',
        title: 'To-do marked complete',
        description: `"${todo_name}" has been marked complete.`,
      });
    }
  };

  const onSubmit = async data => {
    try {
      setLoading(true);
      const formData = {
        ...data,
        id: document.id,
        code: document.code,
        internal: data.internal === DOCUMENT_VISIBILITY.internal,
      };
      const isDocumentType = useDocumentKindsResults.isDocumentType(formData.kind);

      if (!useDocumentKindsResults.documentKindsWithPerson.includes(formData.kind)) {
        delete formData.person_id;
      }

      if (isDocumentType) {
        delete formData.kind_other;
      } else {
        delete formData.kind;
      }

      await updateDocument(formData);
      await handleResolveTasks();

      const newMessage = {
        trackId,
        updatedAt: new Date(),
      };
      postMessage(newMessage);
      close();
      refresh();
    } catch (error) {
      setError(error?.response?.data?.detail || error?.message);
      setTimeout(setError, 3000, null);
    }
    setLoading(false);
  };

  const handleCloseSlide = () => {
    if (!loading) {
      close();
    }
  };

  return (
    <SlideInFromRight open={open} close={handleCloseSlide}>
      {loading && <Loader className="EditDocumentLoaderOverlay" type="dot-pulse" overlay size="large" />}
      {document && (
        <EditDocumentForm
          document={document}
          onSubmit={onSubmit}
          handleCancel={handleCloseSlide}
          useDocumentKindsResults={useDocumentKindsResults}
          error={error}
          getDocumentKindVisibility={getDocumentKindVisibility}
          applicantsOptions={applicantsOptions}
        />
      )}
    </SlideInFromRight>
  );
};

export default EditDocument;
