import React, { useState } from 'react';
import { DataTable, IconButton } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import SSNShowHide from 'components/SSNShowHide/SSNShowHide';
import { booleanOrNoneToLabel } from 'utils/boolean';
import { getPortalsTrackDetailBaseUrl } from 'apps/track-details/utils/trackDetailsLinks';

import './InvestmentIncreaseTable.scss';

const FriendlyIdCell = ({ friendlyId, trackId }) => {
  const ICON_OPTIONS = {
    copy: 'icon-copy',
    check: 'icon-check-mdc',
  };

  const [currentIcon, setCurrentIcon] = useState(ICON_OPTIONS.copy);

  function handleDataCopy() {
    navigator.clipboard.writeText(friendlyId);
    setCurrentIcon(ICON_OPTIONS.check);
    setTimeout(() => {
      setCurrentIcon(ICON_OPTIONS.copy);
    }, 600);
  }

  const navigateUrl = getPortalsTrackDetailBaseUrl(trackId);

  return (
    <>
      <a href={navigateUrl}>{friendlyId}</a>
      <IconButton icon={currentIcon} onClick={() => handleDataCopy(friendlyId)} />
    </>
  );
};

const applicantsTableCell = applicants => {
  const applicantsColumns = [
    {
      name: 'Full Name',
      selector: row => row.fullName,
    },
    {
      name: 'SSN',
      selector: row => row.ssn,
      allowOverflow: true,
    },
    {
      name: 'On Title',
      selector: row => row.isOnTitle,
    },
  ];
  return <DataTable data={applicants} columns={applicantsColumns} />;
};

export const InvestmentIncreaseTable = ({ track }) => {
  const { trackId } = useParams();

  //Extract values from backend data
  const {
    friendlyId: currentTrackFriendlyId,
    htLienPosition: currentTrackHasHometapLien,
    propertyReportReview,
    home,
    applicants,
    potentiallyRelatedInvestments,
  } = track || {
    home: {},
    applicants: [],
    potentiallyRelatedInvestments: [],
  };
  const { apn: currentTrackAPN } = propertyReportReview || {};
  const { fullAddress: currentTrackHouseCanaryFullAddress } = home?.houseCanaryAddress || {};
  const { fullAddress: currentTrackDefaultFullAddress } = home?.address || {};

  const flattenApplicantsData = applicants => {
    return (
      applicants?.map(applicant => {
        const { isOnTitle } = applicant.titleMemberReview || {};
        return {
          fullName: applicant.fullName,
          ssn: <SSNShowHide value={applicant.ssn} />,
          isOnTitle: booleanOrNoneToLabel(isOnTitle),
        };
      }) ?? []
    );
  };

  const potentiallyRelatedInvestmentsFormattedData = potentiallyRelatedInvestments => {
    return (
      potentiallyRelatedInvestments?.map(relatedInvestment => {
        const { address } = relatedInvestment.home || {};
        const { fullAddress: defaultFullAddress } = address || {};
        const { houseCanaryAddress } = relatedInvestment.home || {};
        const { fullAddress: houseCanaryFullAddress } = houseCanaryAddress || {};
        return {
          friendlyId: FriendlyIdCell({
            friendlyId: relatedInvestment.friendlyId,
            trackId: relatedInvestment.identifier,
          }),
          current: 'Active Investment',
          address: defaultFullAddress || 'None',
          houseCanaryAddress: houseCanaryFullAddress || 'None',
          apn: relatedInvestment.apn,
          htLienPosition: booleanOrNoneToLabel(relatedInvestment.hometapLien),
          applicants: applicantsTableCell(flattenApplicantsData(applicants)),
        };
      }) ?? []
    );
  };

  const tableData = [
    {
      friendlyId: FriendlyIdCell({ friendlyId: currentTrackFriendlyId, trackId: trackId }),
      current: 'Current Opportunity',
      address: currentTrackDefaultFullAddress || 'None',
      houseCanaryAddress: currentTrackHouseCanaryFullAddress || 'None',
      apn: currentTrackAPN,
      htLienPosition: booleanOrNoneToLabel(currentTrackHasHometapLien),
      applicants: applicantsTableCell(flattenApplicantsData(applicants)),
    },
    ...potentiallyRelatedInvestmentsFormattedData(potentiallyRelatedInvestments),
  ];

  const excludedFromDropdownColumns = [
    {
      name: 'Friendly ID',
      selector: row => row.friendlyId,
    },
    {
      name: 'Track',
      selector: row => row.current,
    },
  ];
  const columns = [
    {
      name: 'Address',
      selector: row => row.address,
    },
    {
      name: 'HC Address',
      selector: row => row.houseCanaryAddress,
    },
    {
      name: 'APN',
      selector: row => row.apn,
    },
    {
      name: 'Has HT Lien',
      selector: row => row.htLienPosition,
    },
    {
      name: 'Applicants',
      selector: row => row.applicants,
    },
  ];

  return (
    <DataTable
      data={tableData}
      columns={columns}
      expandableRows
      expandableRowDisabled={false}
      primaryColumns={excludedFromDropdownColumns}
      // Add configuration for data to be rendered in all rows for MobileExpandedTable
      expandableRowsComponentProps={{ info: null }}
    />
  );
};

export default InvestmentIncreaseTable;
