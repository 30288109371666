import React from 'react';
import { Icon, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import { onClickItemCloseDropdownMenu } from '../../DocumentsUtils';
import { PinButton } from '../PinButton/PinButton';
import cx from 'classnames';
import './DocumentActions.scss';

const DocumentActions = ({
  row,
  onUploadNewVersion,
  onTogglePinDocument,
  onOpenEditDocument,
  onDeleteDocumentModal,
  onDownloadDocument,
}) => {
  const { id } = row;

  // disable upload new version for final investment Disclosure (IDD)
  const uploadNewVersionDisabled = row?.kind_display === 'Investment Disclosure';
  return (
    <div className="DocumentActions">
      <PinButton pinned={row.pinned} size="lg" onClick={() => onTogglePinDocument(row)} style={{ fontSize: '24px' }} />
      <MenuDropdown id={id} className="DocumentActionsMenuContainer" menuWidth="250px">
        <MenuDropdownItem
          onClick={() => !uploadNewVersionDisabled && onClickItemCloseDropdownMenu(id, () => onUploadNewVersion(row))}
          className={cx({ DocumentActionsUploadNewVersionDisabled: uploadNewVersionDisabled })}
        >
          <Icon name="icon-upload" />
          Upload new version
        </MenuDropdownItem>
        <MenuDropdownItem onClick={() => onClickItemCloseDropdownMenu(id, () => onOpenEditDocument(row))}>
          <Icon name="icon-edit" size="lg" />
          Edit
        </MenuDropdownItem>
        <MenuDropdownItem onClick={() => onClickItemCloseDropdownMenu(id, () => onDeleteDocumentModal(row))}>
          <Icon name="icon-delete" size="lg" />
          Delete
        </MenuDropdownItem>
        <MenuDropdownItem
          onClick={() => {
            onClickItemCloseDropdownMenu(id, () => onDownloadDocument(row));
          }}
        >
          <Icon name="icon-download" size="lg" />
          Download
        </MenuDropdownItem>
      </MenuDropdown>
    </div>
  );
};

export default DocumentActions;
