import { createContext, useContext, useEffect, useState } from 'react';
import apiWithAuth from 'utils/api';
import MaintenanceModeScreenOverlay from 'components/MaintenanceModeScreenOverlay/MaintenanceModeScreenOverlay';
import Pusher from 'pusher-js';

const Context = createContext({});

const useSystemStatus = () => useContext(Context);
export default useSystemStatus;

const initialSystemStatusObject = {
  maintenanceMode: false,
};

/**
 * @description Provider which fetches the current system status and passes that data through to context. Utilizes pusher to listen for system status changes along with REST to fetch current system status on mount.
 */
export const SystemStatusProvider = ({ children }) => {
  const [maintenanceMode, setMaintenanceMode] = useState(initialSystemStatusObject.maintenanceMode);

  // on mount fetch the initial system status
  useEffect(() => {
    apiWithAuth.v2
      .get('system-status/')
      .then(({ system_status }) => {
        setMaintenanceMode(system_status?.maintenance_mode);
      })
      .catch(() => {
        // do nothing if the request fails
        return;
      });
  }, []);

  // on mount subscribe to the system status channel and listen for changes
  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_KEY || !process.env.REACT_APP_PUSHER_CLUSTER) return;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const systemStatusChannel = pusher.subscribe('system-status');

    // listen to maintenance mode event changes within the system-status channel
    systemStatusChannel.bind('maintenance-mode', data => {
      setMaintenanceMode(data.maintenance_mode);
    });

    // unsubscribe from the system-status channel on unmount
    return () => {
      systemStatusChannel.unbind('maintenance-mode');
      systemStatusChannel.unsubscribe('system-status');
    };
  }, []);

  return (
    <Context.Provider value={{ maintenanceMode }}>
      <MaintenanceModeScreenOverlay visible={maintenanceMode} />

      {/* when maintenance mode is enabled, do not allow the background to be displayed while maintaining state */}
      <div style={{ display: maintenanceMode ? 'none' : 'block' }}>{children}</div>
    </Context.Provider>
  );
};
