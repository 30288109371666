import typewriter from '../analytics/segment';

const getSegment = () => {
  if (!window.analytics) {
    console.error('Segment Error: Cannot find window.analytics');
    return;
  }

  return window.analytics;
};

const debug = (prefix, event, props) => {
  if (process.env.REACT_APP_SEGMENT_LOGGING) {
    console.log(prefix, event, JSON.stringify(props, null, 2));
  }
};

// Initialize Segment with the write key from environment variables
export const init = () => {
  getSegment()?.load(process.env.REACT_APP_SEGMENT_KEY);
};

let DISABLED = false;
export const disable = () => {
  DISABLED = true;
};

export const browserTrackPage = props => {
  if (!DISABLED) {
    debug('SEGMENT PAGE |', 'Page Viewed', props);
    return getSegment()?.page('Page Viewed', props);
  }
};

export const browserIdentify = (userID, traits) => {
  if (!DISABLED) {
    debug('SEGMENT IDENTIFY |', userID, traits);
    if (userID) {
      return getSegment()?.identify(userID, traits);
    }
    return getSegment().identify(traits);
  }
};

export const segmentListType = {
  todos: 'todos',
  tasks: 'tasks',
  tracks: 'tracks',
};
// Setup typewriter with debug logging and error handling.
const analyticsWithDebug = { ...window.analytics };
analyticsWithDebug.track = (...args) => {
  if (!DISABLED) {
    debug('SEGMENT TRACK | ', args[0], args[1]);
    return getSegment()?.track(...args);
  }
};

typewriter.setTypewriterOptions({
  analytics: analyticsWithDebug,
  onViolation: (message, violations) => {
    console.error(`SEGMENT VIOLATION | For - ${message.event}`, JSON.stringify(violations, null, 2));
  },
});

export { default as browserTrack } from '../analytics/segment';

const methods = { init, disable };
export default methods;
