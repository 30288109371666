import { Icon, Tooltip } from '@hometap/htco-components';
import { DOCUMENT_SET_BATCH_STATUSES } from '../../data/constants';
import React from 'react';

/**
 *
 * @param {String} Status = COMPLETED|FAILED|IN_PROGRESS
 * @returns
 */
const DocumentBatchProgressBinary = ({ status, failedReason }) => {
  switch (status) {
    case DOCUMENT_SET_BATCH_STATUSES.FAILURE:
      return (
        <div className="DocumentBatchProgressFailureContainer">
          <span>Failed</span>
          {failedReason && (
            <Tooltip content={failedReason}>
              <Icon name="exclamation-circle" />
            </Tooltip>
          )}
        </div>
      );
    case DOCUMENT_SET_BATCH_STATUSES.IN_PROGRESS:
      return <>In Progress</>;
    default:
    case DOCUMENT_SET_BATCH_STATUSES.SUCCESS:
      return <>Successful</>;
  }
};

export default DocumentBatchProgressBinary;
