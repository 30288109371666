import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Loader } from '@hometap/htco-components';
import _ from 'lodash';

import { GET_TRACK_DATA } from 'apps/track-details/tasks/data/queries/getTrackData';
import {
  getTrackDataCoApplicant,
  getTrackDataGeneralInformation,
  getTrackDataPrimaryApplicant,
  getTrackDataPropertyInformation,
} from 'apps/track-details/utils/trackDataColumnConfig';

import DataList from 'components/DataList/DataList';
import DataListDetail from 'components/DataList/DataListDetail/DataListDetail';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';

import './TaskDataController.scss';
import useConfigurations from 'hooks/useConfigurations';
import { getDocKindElseTaskDefKey } from '../../trackTasksUtils';

/** this filter is not meant for review tasks */
const filterConfigItemsForCurrentTask = (items, currentTask) => {
  const { isMandatory } = currentTask;
  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(currentTask);
  const filteredItemsForCurrentTask = items.filter(item => {
    const { hideFieldWhen, taskKinds } = item;
    const shouldHideField = hideFieldWhen && hideFieldWhen({ data: currentTask });
    if (shouldHideField) return false;
    return !taskKinds || taskKinds.includes(docKindElseTaskDefKey) || !isMandatory;
  });

  return filteredItemsForCurrentTask;
};

const getFunctionOrPrimitiveValue = (maybeFunction, defaultValue, argObj) => {
  let value;
  if (typeof maybeFunction === 'function') {
    const func = maybeFunction;
    value = func(argObj);
  } else {
    value = defaultValue;
  }

  return value;
};

const mapData = ({ items, track, currentTask, extraContext }) => {
  const filteredDataForCurrentTask = filterConfigItemsForCurrentTask(items, currentTask);

  return filteredDataForCurrentTask.map(item => {
    const { label, actions, queryDataPathMessages, queryDataPath, display: displayFunc } = item;
    const allData = { ...track, ...extraContext };
    const value = _.get(allData, queryDataPath);
    const messages = _.get(allData, queryDataPathMessages);

    const renderedLabel = getFunctionOrPrimitiveValue(label, label, { track, extraContext });
    const displayValue = getFunctionOrPrimitiveValue(
      displayFunc,
      <DataListDetail value={value} actions={actions} messages={messages} />,
      { track, value, messages, extraContext },
    );

    return { value: displayValue, label: renderedLabel };
  });
};

const TaskDataController = ({ currentTask = {}, isCurrentTaskLoading }) => {
  const { trackId } = useParams();
  const taskQueryData = useQuery(GET_TRACK_DATA, { variables: { trackId } });
  const currentUserPermissions = useCurrentUserPermissions();
  const { track } = taskQueryData.data || {};

  const { taskKinds } = useConfigurations();

  // If the `currentTask` is not loaded by the time `taskQueryData` is done loading filters will
  // fire after content has already been painted on the screen which causes some jank.
  const loading = taskQueryData.loading || isCurrentTaskLoading;

  if (loading) {
    return (
      <div className="TaskDataControllerContainer TaskDataControllerContainerCenterAlign">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  if (!taskQueryData.loading && !track) {
    return (
      <div className="TaskDataControllerContainer TaskDataControllerContainerCenterAlign">
        We're having trouble fetching this homeowner's track data. Please try refreshing. If the problem persists please
        contact engineering
      </div>
    );
  }

  const generalInfoItems = mapData({
    track,
    currentTask,
    extraContext: { userPermissions: currentUserPermissions },
    items: getTrackDataGeneralInformation(taskKinds).items,
  });
  const propertyInfoItems = mapData({
    track,
    currentTask,
    extraContext: { userPermissions: currentUserPermissions },
    items: getTrackDataPropertyInformation(taskKinds).items,
  });

  const primaryApplicant = track.applicants?.find(applicant => applicant.isPrimaryApplicant) || {};
  const primaryApplicantsItems = mapData({
    track,
    currentTask,
    items: getTrackDataPrimaryApplicant(taskKinds).items,
    extraContext: { applicant: primaryApplicant, userPermissions: currentUserPermissions },
  });

  const coApplicantsData = track.applicants
    ?.filter(applicant => !applicant.isPrimaryApplicant)
    .map(coApplicant =>
      mapData({
        track,
        currentTask,
        items: getTrackDataCoApplicant(taskKinds).items,
        extraContext: { applicant: coApplicant, userPermissions: currentUserPermissions },
      }),
    );

  const applicants = (
    <>
      <DataList header={getTrackDataPrimaryApplicant(taskKinds).header} items={primaryApplicantsItems} />
      {!!coApplicantsData?.length &&
        coApplicantsData.map((items, index) => {
          return (
            <DataList key={`coApplicant-${index}`} header={getTrackDataCoApplicant(taskKinds).header} items={items} />
          );
        })}
    </>
  );

  return (
    <div className="TaskDataControllerContainer">
      <h3 className="TaskDataControllerTitle">Task information</h3>
      <DataList header={getTrackDataGeneralInformation(taskKinds).header} items={generalInfoItems} />
      {!!propertyInfoItems?.length && (
        <DataList header={getTrackDataPropertyInformation(taskKinds).header} items={propertyInfoItems} />
      )}
      {track.applicants && !!track.applicants.length && applicants}
    </div>
  );
};

TaskDataController.propTypes = {
  currentTask: PropTypes.object,
  isCurrentTaskLoading: PropTypes.bool.isRequired,
};

export default TaskDataController;
