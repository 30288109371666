export const TRACK_STAGES = {
  ApplicationReady: 'ApplicationReady',
  ApplicationInProgress: 'ApplicationInProgress',
  ApplicationInReview: 'ApplicationInReview',
  ApplicationApproved: 'ApplicationApproved',
  InitialOfferPrepared: 'InitialOfferPrepared',
  OfferReady: 'OfferReady',
  ConditionsInReview: 'ConditionsInReview',
  OfferDeclined: 'OfferDeclined',
  ClosingInProgress: 'ClosingInProgress',
  ClosedLost: 'ClosedLost',
  Invested: 'Invested',
  Settled: 'Settled',
};
