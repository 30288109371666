import PropTypes from 'prop-types';
import cx from 'classnames';
import './ApplicationReviewAlert.scss';

/**
 * @typedef props
 * @type {object}
 * @property {element} icon icon to show on the left
 * @property {element} description description to show in the middle
 * @property {element} action element to show on the right action area (button, link etc)
 * @property {boolean} isAssigned boolean to change styling when action has been completed
 */

/**
 * ApplicationReviewAlert
 * @param {props} props
 * @returns
 */
export const ApplicationReviewAlert = ({ icon, children, description, action, isAssigned }) => (
  <div className={cx('ApplicationReviewAlert', { isAssigned })}>
    {icon && <div className="ApplicationReviewAlert__Icon">{icon}</div>}
    <span className="ApplicationReviewAlert__Description">{children ?? description}</span>
    <div className="ApplicationReviewAlert__ActionArea">{action}</div>
  </div>
);

ApplicationReviewAlert.propTypes = {
  icon: PropTypes.element,
  description: PropTypes.element,
  action: PropTypes.element,
  isAssigned: PropTypes.bool,
};
