import { CONFIGURATIONS_PROPERTY_TYPE } from 'data/constants/configurations';
import { getExpirationDateData } from 'utils/dates';

/** Get the Invalid Reason from the HO, Flood, & Other Insurance Review Form
 * @property {*} [formData]
 * @returns {string | null}
 */
export const buildGetInvalidDocWithExpiryReason = expiryKey => {
  return formData => {
    const { isValid, reason, propertyType } = formData;
    if (isValid === 'false' && reason) {
      return reason;
    }
    if (propertyType && propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp) {
      // we don't continue processing an ineligible file
      return null;
    }
    const { isExpiring, expiresIn } = getExpirationDateData(formData[expiryKey]);
    if (isExpiring && isValid === 'true') {
      return expiresIn < 0 ? 'The document provided has expired.' : 'The document provided will expire within 30 days.';
    }
    return null;
  };
};
