import { getExpirationDateData } from 'utils/dates';
import { getHomeownerTodoReason } from '../../../../../todos/todoUtils';
import { useMutation } from '@apollo/client';
import { UPSERT_RENTAL_AGREEMENT } from '../../../../data/mutations';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { useForm, SelectionSet, DatePicker, CurrencyInput } from '@hometap/htco-components';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import React, { useCallback, useEffect, useMemo } from 'react';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { getTodoNotesOptions } from '../../../../../utils/trackDetailsTodo';
import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import './ProofOfRentalReview.scss';
import { PERIODICITY } from 'data/constants/periodicity';
import { useParams } from 'react-router-dom';
import { getVariableValue } from '../../../TaskList/TaskListUtils';

const TOOLTIP_CONTENT = (
  <div>
    A valid proof of rental document means the:
    <br />
    1. Signed, active lease agreement AND proof of current rental income (i.e. bank statement, copy of deposited check,
    electronic payment receipt, etc). <b>OR</b>
    <br />
    2. Rental transaction history from Airbnb or VRBO, or any other third-party report that details the gross rental
    income for the past 12 months. There must be income in 6 of the last 12 months.
  </div>
);

const INVALID_PROOF_OF_RENTAL_TEXT =
  'Due to an invalid document, a request for an updated proof of rental document will be sent when this task is marked as complete.';
const MONTHLY_OR_ANNUALLY_SELECTION = [
  {
    label: 'Monthly',
    value: PERIODICITY.MONTHLY,
  },
  {
    label: 'Annual',
    value: PERIODICITY.ANNUALLY,
  },
];

/**
 * @typedef ProofOfRentalFormData
 * @type {object}
 * @property {TrueFalseValue} [isValid]
 * @property {string} [reason]
 * @property {string} [reasonNote]
 */
/**
 * @typedef useProofOfRentalTaskForm
 * @type {object}
 * @property {ProofOfRentalFormData} formData
 * @property {(name: string, valueProp?: string) => RegisterFieldReturn} registerField
 */
/**
 * @typedef ProofOfRentalParams
 * @type {object}
 * @property {Task} task
 */
/**
 * @param {ProofOfRentalParams} params
 * @return JSX.Element
 */
const ProofOfRentalReview = ({ task }) => {
  const { registerField, updateFormData, formData = {} } = useForm({});
  const { taskKinds } = useConfigurations();
  const { isValid, reason, rentalAmountFrequency } = formData;
  const taskId = task.identifier;
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { trackId } = useParams();
  const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');
  const { updateProofOfRentalTask } = useProofOfRentalTaskForm(trackId, uploadedDocIds);
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const { user } = useCurrentUser();
  const isExpiringOrExpired = isAgreementExpiringOrExpired(formData);

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.HOMEOWNER_INSURANCE_REVIEW_V1,
    taskKinds: taskKinds,
  });

  const isValidForm = useMemo(() => {
    return getIsValidProofOfRentalReviewTaskForm(user, formData);
  }, [user, formData]);

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      complete: updateProofOfRentalTask,
      formData,
      updateFormData,
      isValidForm,
    });
  }, [updateProofOfRentalTask, updateSpecificTaskById, taskId, formData, updateFormData, isValidForm]);

  const doNothingOnChange = () => {};
  const rentalFreqText = () => {
    if (rentalAmountFrequency === PERIODICITY.MONTHLY) return 'Monthly rental income';
    else if (rentalAmountFrequency === PERIODICITY.ANNUALLY) return 'Annual rental income';
    return 'Rental income';
  };
  const rentalAmountMax = () => {
    return rentalAmountFrequency === PERIODICITY.MONTHLY ? 83333333.33 : 999999999.99;
  };

  return (
    <TaskDetailContentSectionReview
      header="Document validation"
      label="Is this a valid document for proof of rental?"
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      tooltipContent={TOOLTIP_CONTENT}
      invalidInformationText={INVALID_PROOF_OF_RENTAL_TEXT}
      reasonOptions={REASON_OPTIONS}
    >
      <div className="RentalDetails">
        <DatePicker
          label={'Rental agreement expiration date (optional)'}
          onChange={doNothingOnChange}
          theme="outlined"
          className="FullWidthDatePicker"
          wrapperClassName="FullWidthDatePicker"
          {...registerField('agreementExpirationDate')}
        />
        {isExpiringOrExpired && (
          <DetailInformationBlock
            isInvalid={false}
            isExpiring={true}
            isCompleted={isCompleted}
            expiringText={
              'The rental agreement is either expired, or within 30 days of expiring. The Homeowner will be sent a to-do requesting a more recent rental agreement when this task is marked as complete.'
            }
          />
        )}
      </div>
      {!isExpiringOrExpired && (
        <>
          <div className="TwoItemFormRow">
            <div className="FormItem">
              <CurrencyInput
                label={rentalFreqText()}
                padFractionalZeros={true}
                extendedMaskProps={{ max: rentalAmountMax() }}
                disabled={isCompleted || !rentalAmountFrequency}
                {...registerField('rentalAmount')}
              />
            </div>
            <div className="FormItem ProofOfRentalFrequencySelection">
              <SelectionSet
                onChange={doNothingOnChange}
                options={MONTHLY_OR_ANNUALLY_SELECTION}
                disabled={isCompleted}
                className="SelectorElement"
                {...registerField('rentalAmountFrequency')}
              />
            </div>
          </div>
        </>
      )}
    </TaskDetailContentSectionReview>
  );
};
export default ProofOfRentalReview;

/**
 * @param {ProofOfRentalFormData} [formData]
 * @returns {boolean}
 */
export function getIsValidProofOfRentalReviewTaskForm(user = {}, formData = {}) {
  const { isValid, reason, reasonNote, rentalAmountFrequency, rentalAmount } = formData;
  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });
  if (isValid === 'false' && !!homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }
  if (isValid === 'true') {
    // Allow form to be completed without rental amount if the agreement is expiring
    if (isAgreementExpiringOrExpired(formData)) {
      return true;
    }
    return !!(rentalAmountFrequency && rentalAmount);
  }
  return false;
}

const isDocValid = formData => {
  return formData?.isValid === 'true';
};

const isAgreementExpiringOrExpired = formData => {
  return formData?.agreementExpirationDate && getExpirationDateData(formData.agreementExpirationDate).isExpiring;
};

const extractRentalVariables = formData => {
  const { rentalAmountFrequency, rentalAmount, agreementExpirationDate } = formData;
  return { rentalAmountFrequency, rentalAmount, agreementExpirationDate };
};

export function useProofOfRentalTaskForm(trackId, uploadedDocIds) {
  const [upsertRentalAgreement, { loading: isUpsertingProofOfRentalTask }] = useMutation(UPSERT_RENTAL_AGREEMENT);

  const updateProofOfRentalTask = useCallback(
    async ({ formData = {} }) => {
      // Do not upsert rental agreement data even if it was input already if the doc is invalid or
      // expiring. If for some reason we wanted to allow rental income and expiration date to be
      // recorded for an agreement that expires within 30 days, they are still able to input that on
      // the ARP directly.
      if (!isDocValid(formData) || isAgreementExpiringOrExpired(formData)) {
        return;
      }

      const variables = {
        trackId,
        uploadedDocIds,
        ...extractRentalVariables(formData),
      };
      await upsertRentalAgreement({ variables });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [upsertRentalAgreement, trackId],
  );

  return {
    updateProofOfRentalTask,
    isUpsertingProofOfRentalTask,
  };
}

export const getProofOfRentalReviewAdditionalTodosToCreate = ({ formData, taskKinds }) => {
  // Do not create an additional to-dos if the AS marked the document as invalid or the agreement
  // is not expiring/expired. Note that a to-do WILL be created if the document is invalid (via
  // BPM), just not an "additional" to-do.
  if (formData?.isValid === 'false' || !isAgreementExpiringOrExpired(formData)) {
    return null;
  }

  return [
    {
      kind: taskKinds.UPLOAD_PROOF_OF_RENTAL,
    },
  ];
};

export const PROOF_OF_RENTAL_DEMO_DATA = {
  isValid: 'true',
  rentalAmountFrequency: PERIODICITY.MONTHLY,
  rentalAmount: '10000',
};
