import React, { useMemo } from 'react';
import { Badge, Checkbox, DataTable, Icon } from '@hometap/htco-components';
import { bytesToSize, fileExtension, stripFileExtension } from 'utils/documents';
import { FadeIn } from '../FadeIn/FadeIn';
import { VisibilityRadioRow } from '../VisibilityRadioRow/VisibilityRadioRow';
import { isEmpty } from 'lodash';
import { PinButton } from '../PinButton/PinButton';

import './DocumentsFileList.scss';

/**
 * Component used
 * @param {array} files
 * @param {callback} onremove: Callback called when 'x' is clicked for each file
 * @param {Object} newVersionDoc: Object that contains the document to be updated. Can be empty if not uploading a new version
 * @param {boolean} combine: Whether combine into single PDF file
 * @param {callback} setCombine: Callback called to update combine value
 * @param {ref} checkBoxRef: ref object for combine checkbox
 */
/**
 * @typedef DocumentsFileList
 * @type {object}
 * @property {array} files
 * @property {callback} onRemove: Callback called when 'x' is clicked for each file
 * @property {callback} setFiles
 * @property {Object} newVersionDoc: Object that contains the document to be updated. Can be empty if not uploading a new version
 * @property {boolean} combine: Whether combine into single PDF file
 * @property {callback} setCombine: Callback called to update combine value
 * @property {ref} checkBoxRef: ref object for combine checkbox
 * @property {string} defaultVisibilityLabel
 * @property {boolean} anyPasswordProtected: Whether merge is disabled
 * @property {boolean} isNewVersion: Whether this is a new version or a new document
 * @property {boolean} isDefaultVisibilityEditable -  Whether visibility is editable
 * @property {string} defaultVisibilityLabel
 * @property {Applicant} [applicant]
 * @property {string} documentKind
 */
/**
 * @param {DocumentsFileList} params
 * @returns {JSX.Element}
 */
export const DocumentsFileList = ({
  files = [],
  setFiles,
  onRemove,
  newVersionDoc,
  combine,
  setCombine,
  defaultVisibilityLabel,
  isDefaultVisibilityEditable,
  checkBoxRef,
  anyPasswordProtected = false,
  isNewVersion,
  documentKind,
}) => {
  const getNewFilename = fileName => {
    switch (fileExtension(fileName)) {
      case 'heic':
        return `${stripFileExtension({ fileName: fileName })}.jpeg`;
      default:
        return fileName;
    }
  };

  let fileNameWidth = '510px';
  if (combine) {
    fileNameWidth = '580px';
  }
  if (combine && files.length > 1) {
    fileNameWidth = '715px';
  }
  const columns = [
    {
      name: <strong>File</strong>,
      minWidth: '200px',
      maxWidth: fileNameWidth,
      selector: ({ file }) => file.name,
      cell: ({ file }) => {
        const fileName = getNewFilename(file.name);
        return (
          <div className="DocumentFileListFileInfoContainer" title={fileName}>
            <strong>{fileName}</strong>
            <div>{file.size && <span>{bytesToSize(file.size, 1)}</span>}</div>
            {file.passwordProtected && (
              <Badge
                theme="secondaryYellow15"
                label="password protected"
                className="DocumentFileListPasswordProtectedBadge"
              />
            )}
          </div>
        );
      },
    },
    {
      name: <strong>Visibility</strong>,
      left: true,
      width: '170px',
      selector: row => null,
      omit: combine,
      cell: row => {
        const isNewVersion = isEmpty(newVersionDoc);
        return (
          <VisibilityRadioRow
            defaultVisibilityLabel={defaultVisibilityLabel}
            isDefaultVisibilityEditable={isDefaultVisibilityEditable}
            setVisibility={val => (row.file.visibility = val)}
            disabled={!isNewVersion}
            docType={documentKind}
          />
        );
      },
    },
    {
      name: 'Pin',
      selector: row => null,
      width: '100px',
      omit: combine && files.length > 1,
      cell: row => {
        // determine if the file has a pinned property
        const hasPinned = row.file.hasOwnProperty('pinned');
        const handleClick = () => {
          const tempFiles = Object.assign([], files);
          const index = tempFiles.findIndex(file => file.id === row.id);
          tempFiles[index].file.pinned = hasPinned ? !tempFiles[index].file.pinned : false;
          setFiles(tempFiles);
        };

        return <PinButton pinned={hasPinned ? row.file.pinned : true} size={'lg'} onClick={handleClick} />;
      },
    },
    {
      name: null,
      right: true,
      selector: row => null,
      width: '20px',
      cell: row => (
        <div className="DocumentFileListRemoveFileContainer" onClick={() => onRemove(row)}>
          <Icon name="icon-cancel" size="sm" />
        </div>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#152033',
      },
    },
  };

  const renderCheckbox = useMemo(() => {
    if (isNewVersion && anyPasswordProtected) {
      return false;
    }

    if (files.length > 1) {
      return true;
    }

    return false;
  }, [anyPasswordProtected, files.length, isNewVersion]);

  return (
    <FadeIn open={files.length !== 0}>
      <div className="DocumentFileList">
        <div className="DocumentFileListTableContainer">
          <h4 className="DocumentFileListTableContainerHeader">Documents</h4>
          <hr className="DocumentFileListTableContainerDivider" />
          <DataTable
            height="300px"
            columns={columns}
            data={files}
            noDataComponent={'There are no Documents to display'}
            customStyles={customStyles}
          />
        </div>

        {anyPasswordProtected && (
          <div className="DocumentFileListMergeWarningBox">
            {isNewVersion
              ? 'Files that are password protected cannot be merged. Remove that file in order to continue.'
              : 'Files that are password protected cannot be merged.'}
          </div>
        )}

        <FadeIn open={renderCheckbox}>
          <div ref={checkBoxRef}>
            <Checkbox
              label="Merge into single PDF"
              className="DocumentFileListMergeCheckbox"
              name="combine"
              value="combine"
              checked={combine}
              disabled={anyPasswordProtected}
              onChange={(val, _) => {
                setCombine(val);
              }}
            />
          </div>
        </FadeIn>
      </div>
    </FadeIn>
  );
};
