import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getRateTypeOptions } from 'apps/track-details/utils/taskForms';
import useConfigurations from 'hooks/useConfigurations';
import SelectionYesNoUnknown from './SelectionYesNoUnknown';
import { getIsNewId } from '../utils/liens';
import useCurrentUser from 'hooks/useCurrentUser';
import { REQUIRED_ERROR_MESSAGE, validateDateNotBeforeDate } from 'utils/validators';
import { helperText } from 'utils/dates';
import { getLienPropertyReportFields } from '../utils/liens';

const LienPropertyReportFields = ({
  formData,
  initialFormData,
  lienPositionError,
  disabled,
  registerField,
  lienReportFieldsTouched,
  setLienReportFieldsTouched,
  uccOnlyFields = false,
  label,
  showTriggeredPublishErrors,
  judgementOrTaxFieldsOnly = false,
  homeEquityInvestmentFieldsOnly = false,
  partialClaimFieldsOnly = false,
  lienKind,
  holderFieldLabel = 'Servicer',
}) => {
  const { lienRateTypes } = useConfigurations();
  const { user } = useCurrentUser();

  const { onPropertyReport: initialOnPropertyReport } = initialFormData || {};
  const { onPropertyReport, kind, identifier: lienId, endOfTermDate, lienDate, newLien } = formData || {};
  const isOnPropertyReport = onPropertyReport === 'true';
  const rateTypeOptions = useMemo(() => getRateTypeOptions(lienRateTypes, kind.toUpperCase()), [lienRateTypes, kind]);
  const isLiteLienFormsFlag = user?.rolloutFlags.includes('lite_lien_forms_flag');

  const getRegisterProps = (fieldName, lienId) => {
    const { ref, onBlur: defaultOnBlur, error: defaultError, ...rest } = registerField(fieldName);

    // Enhanced onBlur that includes custom logic plus default behavior
    const onBlur = () => {
      handleTouch(fieldName, lienId);
      defaultOnBlur();
    };
    let error =
      lienReportFieldsTouched[lienId]?.[fieldName] ||
      (!newLien && initialOnPropertyReport === 'true') ||
      showTriggeredPublishErrors
        ? defaultError
        : '';
    if (fieldName === 'position' && lienPositionError[lienId]) {
      error = lienPositionError[lienId];
    }

    // We need to enforce 'required' to ensure that the form cannot be saved without filling out the mandatory fields.
    // 'required' is set to true when 'onPropertyReport' in formData is 'true'.
    return { ref, onBlur, error, required: isOnPropertyReport, ...rest };
  };

  const handleTouch = useCallback(
    (fieldName, lienId) => {
      setLienReportFieldsTouched(prevState => ({
        ...prevState,
        [lienId]: {
          ...prevState[lienId],
          [fieldName]: true,
        },
      }));
    },
    [setLienReportFieldsTouched],
  );

  useEffect(() => {
    if (!getIsNewId(lienId)) {
      getLienPropertyReportFields(lienKind, isLiteLienFormsFlag).forEach(field => {
        handleTouch(field, lienId);
      });
    }
  }, [lienId, handleTouch, lienKind, isLiteLienFormsFlag]);

  useEffect(() => {
    if (['true', 'false'].includes(onPropertyReport)) {
      setLienReportFieldsTouched(prevState => ({
        ...prevState,
        [lienId]: {
          ...prevState[lienId],
          active: isOnPropertyReport,
        },
      }));
    }
  }, [isOnPropertyReport, onPropertyReport, lienId, setLienReportFieldsTouched]);

  return (
    <>
      <div className="FormRow">
        <div className="FormItem">
          <SelectionYesNoUnknown
            label={label}
            showRequiredAsterisk={false}
            disabled={disabled}
            isWithMarginBottom={false}
            {...registerField('onPropertyReport')}
          />
        </div>
      </div>
      <>
        {isOnPropertyReport && (
          <div className="TwoItemFormRow">
            <div className="FormItem">
              {/* TODO(EH-741): Use MuiTextInput here after fixing trailing zeros issue */}
              <CurrencyInput
                label="Lien position"
                mask={[
                  {
                    mask: Number,
                    signed: false,
                    allowDecimal: false,
                    scale: 0,
                  },
                ]}
                startAdornment=""
                disabled={disabled}
                isZeroDefaultOnBlur={false}
                {...getRegisterProps('position', lienId)}
              />
            </div>
            <div className="FormItem">
              {!uccOnlyFields ? (
                <CurrencyInput
                  label="Original amount"
                  padFractionalZeros={true}
                  disabled={disabled}
                  {...getRegisterProps('originalAmount', lienId)}
                />
              ) : (
                <DatePicker
                  label="Date recorded"
                  theme="outlined"
                  disabled={disabled}
                  className="FullWidthDatePicker"
                  wrapperClassName="FullWidthDatePicker"
                  showRequiredAsterisk={false}
                  showErrorIcon={true}
                  {...getRegisterProps('lienDate', lienId)}
                  helperText={helperText}
                />
              )}
            </div>
          </div>
        )}
        {!uccOnlyFields && isOnPropertyReport && (
          <>
            <div className="TwoItemFormRow">
              <div className="FormItem">
                <DatePicker
                  label="Date recorded"
                  theme="outlined"
                  disabled={disabled}
                  className="FullWidthDatePicker"
                  wrapperClassName="FullWidthDatePicker"
                  showRequiredAsterisk={false}
                  showErrorIcon={true}
                  {...getRegisterProps('lienDate', lienId)}
                  helperText={helperText}
                />
              </div>
              <div className="FormItem">
                {judgementOrTaxFieldsOnly ? (
                  <div className="FormItem">
                    <MuiTextInput
                      label={holderFieldLabel}
                      theme="outlined"
                      width="100%"
                      disabled={disabled}
                      required={true}
                      showRequiredAsterisk={false}
                      {...registerField('holder')}
                    />
                  </div>
                ) : (
                  <DatePicker
                    label="Term date"
                    theme="outlined"
                    disabled={disabled}
                    className="FullWidthDatePicker"
                    wrapperClassName="FullWidthDatePicker"
                    showRequiredAsterisk={false}
                    {...getRegisterProps('endOfTermDate', lienId)}
                    showErrorIcon={true}
                    helperText={helperText}
                    error={
                      (endOfTermDate &&
                        validateDateNotBeforeDate(endOfTermDate, lienDate, 'Term date', 'Date recorded')) ||
                      ((lienReportFieldsTouched[lienId]?.['endOfTermDate'] || showTriggeredPublishErrors) &&
                        !endOfTermDate &&
                        REQUIRED_ERROR_MESSAGE) ||
                      ''
                    }
                  />
                )}
              </div>
            </div>
            {!judgementOrTaxFieldsOnly && !homeEquityInvestmentFieldsOnly && !partialClaimFieldsOnly && (
              <div className={'TwoItemFormRow'}>
                <div className="FormItem">
                  <MuiTextInput
                    label="Rate"
                    mask={[
                      {
                        mask: Number,
                        radix: '.',
                        scale: 3,
                      },
                    ]}
                    unmask="typed"
                    endAdornment="%"
                    theme="outlined"
                    width="100%"
                    disabled={disabled}
                    showRequiredAsterisk={false}
                    {...getRegisterProps('rate', lienId)}
                  />
                </div>
                <div className="FormItem">
                  <MuiSelect
                    label="Rate type"
                    options={rateTypeOptions}
                    disabled={disabled}
                    theme="outlined"
                    width="100%"
                    showRequiredAsterisk={false}
                    {...getRegisterProps('rateType', lienId)}
                  />
                </div>
              </div>
            )}
            {homeEquityInvestmentFieldsOnly && (
              <div className="FormItem">
                <MuiTextInput
                  label="HEI company/servicer"
                  theme="outlined"
                  width="100%"
                  disabled={disabled}
                  required={true}
                  showRequiredAsterisk={false}
                  {...registerField('holder')}
                />
              </div>
            )}
            {partialClaimFieldsOnly && (
              <div className="FormItem">
                <MuiTextInput
                  label="Lender"
                  theme="outlined"
                  width="100%"
                  disabled={disabled}
                  required={true}
                  showRequiredAsterisk={false}
                  {...registerField('lender')}
                />
              </div>
            )}
          </>
        )}
      </>
    </>
  );
};

export default LienPropertyReportFields;
