import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { getPortalsUrl } from 'utils/links';
import TracksController from 'apps/tracks/TracksController';
import TrackTasksController from 'apps/track-details/tasks/TrackTasksController';
import { browserTrackPage } from 'utils/segment/segment';
import TrackDetailsController from 'apps/track-details/TrackDetailsController';
import HometapError from 'components/HometapError';
import TrackTodosController from 'apps/track-details/todos/TrackTodosController';
import DocumentsController from 'apps/track-details/Documents/DocumentsController';
import StandAlonePreviewTrackDocument from 'apps/track-details/Documents/components/StandAlonePreviewTrackDocument/StandAlonePreviewTrackDocument';
import StandAlonePreviewDocument from 'apps/track-details/Documents/components/StandAlonePreviewDocument/StandAlonePreviewDocument';
import StandAloneUploadTrackDocument from 'apps/track-details/Documents/components/StandAloneUploadTrackDocument/StandAloneUploadTrackDocument';
import DocumentRequestLog from 'apps/track-details/Documents/DocumentSetRequestLog';
import TrackApplicationReviewController from 'apps/track-details/ApplicationReview/TrackApplicationReviewController';
import InvestmentCalculatorController, {
  TRACK_CALCULATORS,
} from 'apps/investment-calculators/InvestmentCalculatorController';
import DocumentSetBatchController from 'apps/document-set-batches/DocumentSetBatchController';
import DocumentSetBatchDetailsController from 'apps/document-set-batches/DocumentSetBatchDetailsController';
import FundingController from 'apps/funding/FundingController';
import AppraisalsController from 'apps/track-details/Appraisals/AppraisalsController';
import TasksController from 'apps/tasks/TasksController';

export const TRACK_DETAILS_PATH = {
  wildCard: '*',
  base: '/tracks',
  detailsSelector: ':trackId',
};

export const TRACK_DETAILS_FULL_PATH = `${TRACK_DETAILS_PATH.base}/${TRACK_DETAILS_PATH.detailsSelector}/${TRACK_DETAILS_PATH.wildCard}`;

const RedirectPortals = () => {
  window.location = getPortalsUrl();
  return null;
};

const AppRouter = () => {
  const location = useLocation();
  useEffect(() => {
    browserTrackPage();
  }, [location]);

  return (
    <Routes>
      <Route path={TRACK_DETAILS_PATH.base}>
        <Route index element={<TracksController />} />
        <Route
          path={`${TRACK_DETAILS_PATH.detailsSelector}/${TRACK_DETAILS_PATH.wildCard}`}
          element={<TrackDetailsController optOutOfDisabledState />}
        >
          {/* The routes that want to stay enabled when the track is closed lost */}
          <Route path="documents" element={<DocumentsController />} />
          <Route path="documents/:documentId" element={<StandAlonePreviewTrackDocument />}>
            <Route path="version/:versionId" element={<StandAlonePreviewTrackDocument />} />
          </Route>
          <Route path="documents/document-set-request-log" element={<DocumentRequestLog />} />
        </Route>
        <Route
          path={`${TRACK_DETAILS_PATH.detailsSelector}/${TRACK_DETAILS_PATH.wildCard}`}
          element={<TrackDetailsController />}
        >
          <Route path="application-review" element={<TrackApplicationReviewController />} />
          <Route path="todos/*" element={<TrackTodosController />} />
          <Route path="tasks" element={<TrackTasksController />} />
          <Route path="appraisals" element={<AppraisalsController />} />
          <Route path="tasks/:taskId" element={<TrackTasksController />} />
          <Route path="documents/upload" search="kind" element={<StandAloneUploadTrackDocument />} />
          <Route path="*" element={<HometapError />} />
        </Route>
        <Route path=":trackId/uw-calculator" element={<InvestmentCalculatorController type={TRACK_CALCULATORS.uw} />} />
        <Route
          path=":trackId/sales-calculator"
          element={<InvestmentCalculatorController type={TRACK_CALCULATORS.sales} />}
        />
      </Route>
      {/* redirect /tasks and /todos to /tracks to disable these pages due to performance issues and low use  */}
      <Route path="/tasks" element={<TasksController />} />
      <Route path="/todos" element={<Navigate to={TRACK_DETAILS_PATH.base} replace />} />
      <Route path="documents/:documentId" element={<StandAlonePreviewDocument />}>
        <Route path="version/:versionId" element={<StandAlonePreviewDocument />} />
      </Route>
      <Route path="/funding" element={<FundingController />} />
      <Route path="/document-set-batch">
        <Route index element={<DocumentSetBatchController />} />
        <Route path="batch-details/:documentBatchId" element={<DocumentSetBatchDetailsController />} />
      </Route>
      <Route path="/" element={<RedirectPortals />} />
      <Route path="*" element={<HometapError buttonLink="/" />} />
    </Routes>
  );
};

export default AppRouter;
