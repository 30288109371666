import React from 'react';
import cx from 'classnames';

import './ActionBlock.scss';
import PropTypes from 'prop-types';

/**
 * @typedef ActionBlockParams
 * @type {object}
 * @property {string} [description
 * @property {string} [className]
 * @property {'center'} [alignIcon]
 * @property {'yellow'} [theme]
 */
/**
 * @param {ActionBlockParams} params
 * @returns HTML Element
 */
export const ActionBlock = ({ className, alignIcon, action, icon, description, theme = 'yellow' }) => {
  return (
    <div
      className={cx('ActionBlock', className, {
        isCenter: alignIcon === 'center',
        isYellow: theme === 'yellow',
      })}
    >
      {icon && icon}
      <span className="ActionBlockDescription">{description}</span>
      {action && <div className="ActionBlockActionArea">{action}</div>}
    </div>
  );
};

ActionBlock.propTypes = {
  icon: PropTypes.element,
  description: PropTypes.string,
  action: PropTypes.element,
  alignIcon: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
};
