import React from 'react';
import { NotFoundBlock } from '@hometap/htco-components';

const HometapError = props => {
  return (
    <div style={{ paddingTop: 50 }}>
      <NotFoundBlock {...props} />
    </div>
  );
};

export default HometapError;
