import cx from 'classnames';

/**
 * SliderFormFooter component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional classes to apply to the component.
 * @param {ReactNode} props.children - The content of the slider form footer.
 * @returns {JSX.Element} The rendered SliderFormFooter component.
 */
const SliderFormFooter = ({ className, children }) => {
  return <div className={cx('flex h-[88px] w-full items-center p-[24px]', className)}>{children}</div>;
};

export default SliderFormFooter;
