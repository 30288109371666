import { Button } from '@hometap/htco-components';
import { PaydownController } from '../PaydownController/PaydownController';
import './PaydownWrapper.scss';
import { useUpdateSectionForm } from '../../../../hooks/useSpecificContentQuery';
import { getIsNewId } from '../../utils/liens';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';

export const PaydownWrapper = ({
  disabled,
  trackId,
  lienId,
  paydown,
  addNewPaydown,
  deletePaydown,
  isRequired = false,
  homeValue,
  lienKind,
  onPropertyReport,
  updateLienFormData,
}) => {
  const { deleteFormById, updateSectionFormById } = useUpdateSectionForm();

  const onDeleteClick = () => {
    deletePaydown({ lienId, paydownId: paydown.identifier });

    if (getIsNewId(paydown.identifier)) {
      deleteFormById(paydown.identifier);
      updateSectionFormById(lienId, {
        paydownId: undefined,
      });
    }
  };

  return (
    <div>
      {paydown ? (
        <div className="PaydownWrapper">
          <PaydownController
            onDelete={onDeleteClick}
            disabled={disabled}
            paydown={paydown}
            trackId={trackId}
            lienId={lienId}
            homeValue={homeValue}
            lienKind={lienKind}
            onPropertyReport={onPropertyReport}
            updateLienFormData={updateLienFormData}
          />
        </div>
      ) : (
        <>
          {isRequired && (
            <DetailInformationBlock
              type={'warning'}
              className="MissingLienPayoffWarning"
              text={'This lien will require a payoff unless you obtain a satisfaction document.'}
            />
          )}
          <Button onClick={addNewPaydown} theme="link" disabled={disabled}>
            Add a paydown
          </Button>
        </>
      )}
    </div>
  );
};
