import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Paper, DataTable, ModalConfirm, Button, Loader } from '@hometap/htco-components';
import { GET_FUND_NAMES, SAVE_FUNDS } from './fundingRequests';
import useTracksFunding from './hooks/useTracksFunding';
import dayjs from 'dayjs';
import { useQuery, useMutation } from '@apollo/client';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useCurrentUser from 'hooks/useCurrentUser';
import useFundNames from './hooks/useFundNames';
import useFundingData from './hooks/useFundingData';
import FundingCell from './components/FundingCell/FundingCell';
import FundingAllocationHeader from './components/FundAllocationHeaders/FundAllocationHeaders';
import DemoButton from 'components/DemoButton/DemoButton';
import './FundingController.scss';

const FRIENDLY_ID = 'Friendly ID';
const SIGNING_STATUS = 'Signing status';
const MAX_APPROVED_OR_ACCEPTED_OFFER_AMOUNT = 'Max approved or accepted offer amount';
const SELECTED_FUND = 'Selected fund';
const DAYS_SINCE_FINAL_APPROVED = 'Days since Final Approved';
const SIGNING_DATETIME = 'Signing Date / Time';

const MOBILE_ROWS = [FRIENDLY_ID, SIGNING_STATUS, MAX_APPROVED_OR_ACCEPTED_OFFER_AMOUNT, SELECTED_FUND];

const customStyles = {
  head: {
    style: {
      fontSize: 14,
    },
  },
  cells: {
    style: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: 18,
    },
  },
};

export const FundingController = props => {
  const {
    tracks: tracksFundingData,
    handleFetchTracks: refetchTracksFundingData,
    isTrackFundingLoading,
  } = useTracksFunding();

  // official names of the funds associated with their keyword
  const { data: fundNames } = useQuery(GET_FUND_NAMES);
  const { fundNameDictionary } = useFundNames(fundNames?.funds);

  //state management for page
  const [potentialTrackChange, setPotentialTrackChange] = useState({});

  // information about tracks' funding status
  const {
    combinedFormattedTrackFundData,
    setCombinedFormattedTrackFundData,
    changedTracksList,
    doTracksHaveSelectedRejectedFunds,
    handleReRunBuyBox,
    rerunBuyBoxLoading,
  } = useFundingData(tracksFundingData);

  // whether the confirmation modal is open after selecting a fund
  const [modelTrackOpen, setModelTrackOpen] = useState(false);

  // once a fund has been selected in a dropdown, this should trigger a change in the trackFundingDictionary,
  // we should set hasChanged to true to allow saving new changes
  const [hasChanged, setHasChanged] = useState(false);

  const [saveFundsMutation, { loading: saveFundsLoading, error }] = useMutation(SAVE_FUNDS, {
    onCompleted: result => readResults(result.updateTracksFunding?.ok, result.updateTracksFunding?.message),
    onError: () => propagateError(error),
  });
  const { isInServicerGroup } = useCurrentUser();
  const isDisabledSubmitButton = !isInServicerGroup || !hasChanged || doTracksHaveSelectedRejectedFunds();

  /**
   * Used to save the new fund selections on the page
   */
  const saveFunds = () => {
    // get only the tracks that have changes
    const changedTracks = changedTracksList(combinedFormattedTrackFundData);
    const trackFundList = changedTracks.map(track => {
      return { trackId: track.id, selectedFund: track.fundData.selected };
    });

    saveFundsMutation({
      variables: {
        trackFundAssignments: trackFundList,
      },
    });
  };

  /**
   * Used to read the results of the mutation of saving the fund changes
   * @param {Boolean} resultOK
   * @param {Object} resultMessage
   */
  const readResults = (resultOK, resultMessage) => {
    if (resultOK) {
      setHasChanged(false);
      const changedTracks = changedTracksList(combinedFormattedTrackFundData);
      const lengthOfFunds = Object.keys(changedTracks).length;
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Successfully saved fund allocation',
        description: `${lengthOfFunds} track${lengthOfFunds > 1 ? 's' : ''} successfully saved fund allocation`,
      });
      // update the data by re-fetching the data
      refetchTracksFundingData();
    } else {
      propagateError(resultMessage);
      // changed to true to allow user to change again
      setHasChanged(true);
    }
  };

  /**
   * Used to display the error found when saving a fund
   * @param {Object} resultMessage
   */
  const propagateError = (resultMessage = undefined) => {
    if (resultMessage !== undefined) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to assign funds',
        description: resultMessage?.message ?? 'Unknown failure to assign funds.',
      });
    }
    setHasChanged(true);
  };

  /**
   * Handles state management for fund changes
   * @param {String} changedTrack track whose fund is changing
   * @param {Object} newFund new fund for that track
   */
  const handleChangeFund = (changedTrack, newFund) => {
    const newState = combinedFormattedTrackFundData.map(track => {
      if (track.id === changedTrack.id) {
        return { ...track, fundData: { ...track.fundData, selected: newFund } };
      }
      return track;
    });
    setCombinedFormattedTrackFundData(newState);
    if (changedTracksList(newState).length) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  /**
   * If the user confirms in the modal that they want to change the fund selected even though it's not preferred
   */
  const handleNonPreferredModalConfirm = () => {
    if (!isEmpty(potentialTrackChange)) {
      handleChangeFund(potentialTrackChange.changedTrack, potentialTrackChange.newFund);
      setPotentialTrackChange({});
    }
    setModelTrackOpen(false);
  };

  const trackSelectors = [
    {
      label: FRIENDLY_ID,
      name: <span>Friendly ID</span>,
      selector: row => row.friendlyId,
      cell: row => <a href={`/tracks/${row.identifier}/application-review`}>{row.friendlyId}</a>,
    },
    {
      label: SIGNING_STATUS,
      name: <span>Signing status</span>,
      sortable: true,
      selector: row => row?.signingStatus,
    },
    {
      label: DAYS_SINCE_FINAL_APPROVED,
      name: <span>Days since Final Approved</span>,
      sortable: true,
      selector: row => row?.dateOfFinalApproval,
      cell: row => {
        const todaysDate = new Date();
        // To calculate the time difference of two dates in milliseconds
        const Difference_In_Time = todaysDate.getTime() - new Date(row?.dateOfFinalApproval).getTime();
        // number of millseconds in a day
        const millisecondsPerDay = 1000 * 3600 * 24;
        // To calculate the no. of days between two dates
        const Difference_In_Days = Math.floor(Difference_In_Time / millisecondsPerDay);
        return <span>{row?.dateOfFinalApproval ? `${Difference_In_Days} Days` : ''}</span>;
      },
    },
    {
      label: SIGNING_DATETIME,
      name: <span>Signing Date / Time</span>,
      sortable: true,
      selector: row => row?.signingDatetime,
      cell: row => (
        <span>
          {row?.signingDatetime ? dayjs(new Date(row?.signingDatetime)).format('MMM, DD YYYY [at] h:mm A') : ''}
        </span>
      ),
    },
    {
      label: MAX_APPROVED_OR_ACCEPTED_OFFER_AMOUNT,
      name: <span>Max approved or accepted offer amount</span>,
      selector: row => (row?.hasAcceptedOffer ? row?.acceptedOfferAmount : row?.maxInvestmentAmount),
      cell: row => {
        if (row?.hasAcceptedOffer) {
          return (
            <span>
              <span className="FundingControllerOfferAmountTag">(Offer)</span>
              {<strong>${row?.acceptedOfferAmount?.toLocaleString('en-US')}</strong>}
            </span>
          );
        } else if (row?.maxInvestmentAmount) {
          return (
            <span>
              <span className="FundingControllerOfferAmountTag">(Max)</span>
              {row?.maxInvestmentAmount ? <strong>${row?.maxInvestmentAmount.toLocaleString('en-US')}</strong> : ''}
            </span>
          );
        }
        return null;
      },
    },
    {
      label: SELECTED_FUND,
      name: <span>Selected Fund</span>,
      selector: row => row.friendlyId,
      cell: row => {
        const trackID = row.friendlyId;
        if (!trackID) return <div />;
        return (
          <FundingCell
            track={row}
            fundNameDict={fundNameDictionary}
            isDisabled={!isInServicerGroup}
            setPotentialTrackChange={setPotentialTrackChange}
            setModelTrackOpen={setModelTrackOpen}
            handleChangeFund={handleChangeFund}
          />
        );
      },
      grow: 2,
    },
  ];

  const mobileSelectors = trackSelectors.filter(selector => MOBILE_ROWS.includes(selector.label));

  /**
   * Make sure every track has a selected fund that is not a rejected fund.
   * if no fund can be found to assign to a track, leave empty.
   *
   * Checks if preferred fund is rejected and does not assign if it is rejected.
   */
  const handleDemoButtonPress = () => {
    combinedFormattedTrackFundData.forEach(track => {
      const { fundData } = track;
      const preferredFund = fundData?.preferred || null;
      // change selected fund to preferred fund even if it already is selected
      if (preferredFund && preferredFund !== fundData?.selected) {
        // only change to preferred if not rejected
        if (!fundData?.rejected?.includes(preferredFund)) {
          handleChangeFund({ changedTrack: track, newFund: preferredFund });
        }
        return;
      }
      if (fundData?.selected) return;
      // if track does not have a selected fund, assign it a fund
      // randomFund from trackFundingData.suitable;
      const suitableFunds = fundData?.suitable ?? [];
      const randomSuitableFund =
        suitableFunds.length > 0 ? suitableFunds[Math.floor(Math.random() * suitableFunds.length)] : null;
      handleChangeFund({ changedTrack: track, newFund: preferredFund ?? randomSuitableFund });
    });
  };

  return (
    <div className="FundingControllerPage">
      <FundingAllocationHeader
        fundNameDictionary={fundNameDictionary}
        combinedTracks={combinedFormattedTrackFundData}
      />
      <Paper pad={0} className="FundingControllerPaper">
        {modelTrackOpen && (
          <ModalConfirm
            className="activateOpportunityModal"
            open={modelTrackOpen}
            width={626}
            header="Allocate to non-preferred fund?"
            confirmText="Yes"
            onConfirm={handleNonPreferredModalConfirm}
            onClose={() => setModelTrackOpen(false)}
          >
            You're about to allocate to a non-preferred fund. Do you want to continue?
          </ModalConfirm>
        )}
        <div className="FundingTableContainer">
          <DataTable
            customStyles={customStyles}
            columns={trackSelectors}
            data={combinedFormattedTrackFundData ?? []}
            mobileColumns={mobileSelectors}
            loading={isTrackFundingLoading}
            respondAt={'md'}
            title="Tracks ready for Fund Allocation"
            className="FundingTable"
          />
        </div>
      </Paper>
      <div className="FundingControllerActionButtonContainer">
        <DemoButton className="FundingControllerDemoButton" onClickAction={handleDemoButtonPress} />
        <span>
          <Button
            className="FundingControllerFillAllButton"
            onClick={handleReRunBuyBox}
            disabled={!isInServicerGroup || rerunBuyBoxLoading}
          >
            {rerunBuyBoxLoading ? (
              <Loader theme="inverse" className="FundingControllerSaveLoader" />
            ) : (
              <div>Run BuyBox</div>
            )}
          </Button>
        </span>
        <span>
          <Button
            className="FundingControllerSaveFundsSubmitButton"
            type="submit"
            disabled={saveFundsLoading || isDisabledSubmitButton}
            onClick={saveFunds}
          >
            {saveFundsLoading ? (
              <Loader theme="inverse" className="FundingControllerSaveLoader" />
            ) : (
              <div>Save Changes</div>
            )}
          </Button>
        </span>
      </div>
    </div>
  );
};

export default FundingController;
