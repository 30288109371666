import cx from 'classnames';
import { Container, Icon, Paper, Button } from '@hometap/htco-components';
import FormListView from 'components/FormListView/FormListView';
import useCurrentUser from 'hooks/useCurrentUser';
import { currency } from 'utils/numbers';
import { getOutputFields } from '../../utils/getOutputFields';
import InvestmentCalculatorHeader from '../InvestmentCalculatorHeader/InvestmentCalculatorHeader';
import InvestmentCalculatorOutput from '../InvestmentCalculatorOutput/InvestmentCalculatorOutput';
import './InvestmentCalculator.scss';

const InvestmentCalculator = ({
  track,
  loading,
  headerError,
  calculatedData,
  calculationsError,
  inputGroups,
  hasSaved,
  actionLabel,
  onFetchTrack,
  onAction,
  isOverlay,
}) => {
  const { user } = useCurrentUser();

  return (
    <div className={cx('InvestmentCalculator', { isOverlay: isOverlay })}>
      <Paper theme="plain" pad={{ top: isOverlay ? 0 : 2.2, bottom: 6, left: 6, right: 6 }}>
        <InvestmentCalculatorHeader
          track={track}
          isLoading={loading}
          status={calculatedData?.eligibilityStatus}
          maxInvestment={currency(calculatedData?.investmentAmount)}
          calculatedData={calculatedData}
          calculationsError={calculationsError}
          errorMessage={headerError}
        />
        <Container row>
          <Container grid="7" respondAt="md" className="InvestmentCalcInputsContainer">
            {inputGroups.map(({ group, rows }) => {
              return <FormListView key={group} title={group} className="InvestmentCalcInputsSection" rows={rows} />;
            })}
          </Container>

          <Container grid="5" respondAt="md" className="InvestmentCalcMaxPotentialInvestment">
            <InvestmentCalculatorOutput loading={loading} data={getOutputFields(user, calculatedData)} />

            {track?.activeOpportunityRecord?.url && (
              <div className="InvestmentCalcSalesforceLink">
                <a href={track?.activeOpportunityRecord?.url} target="_blank" rel="noreferrer">
                  Open in Salesforce
                  <Icon name="icon-external" size="lg" />
                </a>
              </div>
            )}
          </Container>
        </Container>
      </Paper>

      {(!!track || onAction) && (
        <Paper className="InvestmentCalcFooter">
          <Container row>
            {hasSaved && (
              <Container row className="InvestmentCalcFooterSaveIconCheck">
                <Icon name="icon-check" />
                <span className="IconCheckText">Saved</span>
              </Container>
            )}
            <Container row className="InvestmentCalcFooterButtons">
              {onFetchTrack && (
                <Button theme="secondary" size="small" onClick={() => onFetchTrack()}>
                  Fetch Track Data
                </Button>
              )}
              {onAction && (
                <Button size="small" disabled={!calculatedData?.eligibilityStatus} onClick={onAction}>
                  {actionLabel}
                </Button>
              )}
            </Container>
          </Container>
        </Paper>
      )}
    </div>
  );
};

export default InvestmentCalculator;
