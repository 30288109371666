import React, { useEffect, useLayoutEffect, useState } from 'react';
import { TextInput } from '@hometap/htco-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './TaskNote.scss';

const TEXT_NOTE_IDS_ENUMS = {
  taskNoteTextInput: 'taskNoteTextInput',
};

const TEXT_NOTE_IDS = [TEXT_NOTE_IDS_ENUMS.taskNoteTextInput];

const TEXT_INPUT_ACTIVE_PADDING_TOP = 16;
const TEXT_INPUT_START_SCROLL_TOP = 0;
const TEXT_INPUT_MIN_HEIGHT = 206;
const TEXT_INPUT_MAX_HEIGHT = 310;

/**
 * To render the task note with TaskNote components
 * include it into the Parent Component and pass the props
 * @property taskId - the id of the task
 * @property initialTextValue - the text of the note
 * @property onChange - will be called if the text note value changes and pass the string value
 * @property isDisabled - when it is not editable
 * @property isLoading - when it is loading
 * @property className - additional CSS class that will be appended to parent container
 * @property minHeight - the min height for an inactive field, by default 206px
 * @property maxHeight - the min height for an active field, by default 310px
 */

const TaskNote = ({
  taskId,
  initialTextValue,
  className,
  onChange,
  isDisabled: isDisabledField,
  isLoading,
  minHeight,
  maxHeight,
}) => {
  const [textValue, setTextValue] = useState(initialTextValue);
  const [isActive, setIsActive] = useState(false);

  const textInputRef = React.useRef(null);

  const isChanged = textValue !== initialTextValue;
  const isDisabled = isDisabledField || isLoading;

  /**
   * Observes changes in initialTextValue and:
   * 1. Updates local states
   * 2. Scrolls the text area up
   */
  useEffect(() => {
    setTextValue(initialTextValue);
    setIsActive(false);
    textInputRef.current.scrollTop = TEXT_INPUT_START_SCROLL_TOP;
  }, [initialTextValue, taskId]);

  /**
   * useLayoutEffect is used to increase the height of the text input and check the scroll state
   * reset height - important to reduce when removed with "inherit"
   */
  useLayoutEffect(() => {
    textInputRef.current.style.height = 'inherit';

    if (isActive) {
      textInputRef.current.style.height = `${Math.max(
        textInputRef.current.scrollHeight + TEXT_INPUT_ACTIVE_PADDING_TOP,
        minHeight,
      )}px`;
    }
  }, [textValue, isActive, minHeight, maxHeight]);

  const onBlurField = e => {
    if (isActive && !isChanged && TEXT_NOTE_IDS.every(id => e.relatedTarget?.id !== id)) {
      setIsActive(false);
      textInputRef.current.scrollTop = TEXT_INPUT_START_SCROLL_TOP;
    }
  };

  const onFocusField = () => {
    if (!isActive) {
      setIsActive(true);
    }
  };

  const onChangeTaskNote = value => {
    setTextValue(value);
    onChange?.(value);
  };

  return (
    <div className={className}>
      <p className="TaskNoteTitle">Notes</p>
      <div
        className={cx('TaskNote', { isActive: isActive && !isDisabled, isDisabled })}
        onBlur={onBlurField}
        onFocus={onFocusField}
      >
        <TextInput
          ref={textInputRef}
          id={TEXT_NOTE_IDS_ENUMS.taskNoteTextInput}
          type="textarea"
          name="taskNote"
          value={textValue}
          onChange={onChangeTaskNote}
          disabled={isDisabled}
          className={cx('TaskNoteText', { isActive: isActive })}
          style={{
            minHeight: `${minHeight}px`,
            maxHeight: `${maxHeight}px`,
          }}
        />
      </div>
    </div>
  );
};

TaskNote.propTypes = {
  taskId: PropTypes.string,
  initialTextValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
};

TaskNote.defaultProps = {
  initialTextValue: '',
  minHeight: TEXT_INPUT_MIN_HEIGHT,
  maxHeight: TEXT_INPUT_MAX_HEIGHT,
};

export default TaskNote;
