import { NotFoundBlock } from '@hometap/htco-components';
import { getPortalsUrl } from 'utils/links';

const StatusCodeErrorBlock = ({ errorCode, codeConfig, ...props }) => {
  const loginUrl = getPortalsUrl('auth/login/reviewer');

  const config = {
    403: {
      title: "Looks like you don't have permission to view this page.",
      children: 'If you think this problem is wrong please contact engineering.',
      buttonLink: loginUrl,
    },
    404: {
      title: 'We could not find this page.',
      children: 'If you think this problem is wrong please contact engineering.',
      buttonLink: loginUrl,
    },
    500: {
      title: 'We are having trouble fetching this data',
      children: 'Please try again. If the problem persists please contact engineering.',
      buttonLink: null,
    },
    ...codeConfig,
  };

  if (!errorCode) {
    return null;
  }

  const codeDefaults = config[errorCode] || {};
  return <NotFoundBlock {...codeDefaults} error={`${errorCode} Error`} {...props} />;
};

export default StatusCodeErrorBlock;
