import React from 'react';
import { MuiSelect, SelectionSet } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { booleanOptions, booleanOrNoneToValue } from 'utils/boolean';
import cx from 'classnames';
import { SUMMARY_DIVIDER } from '../../components/Summary/Summary';

export const FloodReportController = ({ track, editDisabled }) => {
  const { trackId } = useParams();
  const { editFloodReportUrl, historyFloodReportUrl } = getApplicationReviewPageUrls(trackId);
  const doNothingOnChange = () => {};
  // In development of the read-only ARP, disable all inputs
  const readOnly = true;
  const { floodReportReview } = track;

  const onClickEdit = function navigateToEditUrl() {
    window.location = editFloodReportUrl;
  };

  return (
    <ApplicationReviewSection
      anchorId="flood-zone-section"
      sectionTitle="Flood report"
      historyUrl={historyFloodReportUrl}
      onEdit={!editDisabled ? onClickEdit : null}
      visibleSummary={true}
      summaryParams={[
        SUMMARY_DIVIDER,
        {
          label: 'Flood Zone',
          value: track.floodZone,
        },
        SUMMARY_DIVIDER,
      ]}
    >
      {!floodReportReview ? (
        <div>There is no flood report data</div>
      ) : (
        <>
          <SelectionSet
            value={booleanOrNoneToValue(floodReportReview.inFloodZone)}
            label="Is the property in a flood zone?"
            onChange={doNothingOnChange}
            name="inFloodZone"
            options={booleanOptions}
            disabled={readOnly}
            className={cx('SelectorElement', { disabledSelectorElement: readOnly })}
          />
          <MuiSelect
            label="Ops Status"
            value={floodReportReview.status.toLowerCase()}
            onChange={doNothingOnChange}
            options={floodReportReview.statusOptions}
            theme="outlined"
            width="100%"
            disabled
          />
        </>
      )}
    </ApplicationReviewSection>
  );
};

export default FloodReportController;
