import { gql } from '@apollo/client';

export const GET_TRACK_TRADELINES = gql`
  query GetTrackTradelines($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      applicants {
        id
        isPrimaryApplicant
        warnings
        riskBand {
          identifier

          tradelineData {
            riskMortgageTradelines {
              accountType
              amountPastDue
              amountSectionValueOriginal
              balanceAmount
              delinquenciesOver30Days
              delinquenciesOver60Days
              delinquenciesOver90Days
              enhancedPaymentDataStatus
              monthlyPaymentAmount
              statusCode
              statusDate
              subscriberDisplayName
              termsDuration
            }
            creditInquiries {
              inquiryDate
              subscriberDisplayName
              type
            }
            riskBankruptcies {
              ecoa
              filingDate
              status
              statusDate
            }
          }
        }
      }
    }
  }
`;
