import React, { useState } from 'react';
import { Button, ModalConfirm } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import SavedDisplay from '../SavedDisplay';
import './TaskActionBar.scss';
import DemoButton from '../../../../../../components/DemoButton/DemoButton';
import { parseCreatedAtDateParts } from '../../../../../../utils/dates';
import { useSpecificContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { useCallbackPrompt } from 'apps/track-details/tasks/hooks/useCallbackPrompt';

const TASK_RESOLUTION_ENUM = {
  NA: 'na',
  COMPLETED: 'completed',
  TRACK_CLOSED: 'track_closed',
  ESCALATED: 'escalated',
};

/**
 * @param task  current task
 * @param isPrimaryButtonEnabled  a flag for enabling / disabling the primary button
 * @param isSecondaryButtonEnabled a flag for enabling / disabling the secondary button
 * @param isDemoButtonEnabled a flag for enabling / disabling the Demo button
 * @param primaryButtonName a of tne primary button
 * @param onPrimaryButtonClick a function which is called after primary button click
 * @param onSecondaryButtonClick a function which is called after secondary button click
 * @param onDemoButtonClick a function which is called after Demo button click
 * @param isLoading a flag indicating that a loading process is happening
 * @param isSaving a flag indication that a saving process is happening
 * @param isHiddenTaskActionBar a flag indication that all the buttons is hidden
 * @param isSimpleReviewTask a flag indication that this task is legacy
 * @param onModalConfirm a function which is called when user click confirm modal button
 * @param onModalCancel a function which is called when user click cancel modal button
 */
const TaskActionBar = ({
  task = {},
  isPrimaryButtonEnabled,
  isSecondaryButtonEnabled,
  isDemoButtonEnabled,
  primaryButtonName,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onDemoButtonClick,
  isLoading,
  isSaving,
  isHiddenTaskActionBar,
  isSimpleReviewTask,
  onModalConfirm,
  onModalCancel,
}) => {
  const [openEscalateModalConfirm, setOpenEscalateModalConfirm] = useState(false);
  const [openReassignModalConfirm, setOpenReassignModalConfirm] = useState(false);

  const { loading: isSpecificContentLoading, specificTaskData } = useSpecificContent(task.identifier);

  const {
    saving: isSpecificTaskSaving,
    formData,
    isValidTaskForm,
    closeTask,
    updateFormWithDemoData,
    isFormChanged,
    showEscalate,
    reassignToData,
  } = specificTaskData;
  const { showReassign, disabledReassign } = reassignToData || {};

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isSimpleReviewTask ? isSecondaryButtonEnabled : isFormChanged,
  );
  const { isCompleted, completedBy, completedOn, taskStatus } = task;
  const { firstName, lastName } = completedBy || {};

  const isLoadingTask = isLoading || isSpecificContentLoading;
  const isSavingTask = isSaving || isSpecificTaskSaving;
  const isPrimaryButtonDisabled =
    isSavingTask || isPrimaryButtonEnabled !== undefined ? !isPrimaryButtonEnabled : !isValidTaskForm;

  const handleDemoButtonClick = () => {
    if (updateFormWithDemoData) {
      return updateFormWithDemoData();
    }
    return onDemoButtonClick();
  };

  const [monthDay, year, hours] = parseCreatedAtDateParts(completedOn);

  const openTaskBar = (
    <>
      <DemoButton
        className="TaskActionBarDemoButton"
        disabled={!isDemoButtonEnabled}
        onClickAction={handleDemoButtonClick}
      />
      {isSimpleReviewTask && (
        <>
          <Button
            onClick={() => onPrimaryButtonClick(TASK_RESOLUTION_ENUM.NA, closeTask)}
            theme="text"
            disabled={isLoadingTask || isSavingTask}
          >
            Mark as N/A
          </Button>
          <Button
            theme="secondary"
            disabled={!isSecondaryButtonEnabled}
            className="TaskActionBarButton"
            onClick={onSecondaryButtonClick}
          >
            Save Progress
          </Button>
        </>
      )}
      {showEscalate && (
        <>
          <Button
            onClick={() => {
              setOpenEscalateModalConfirm(true);
            }}
            theme="text"
            disabled={isLoadingTask || isSavingTask}
          >
            Escalate
          </Button>
          {openEscalateModalConfirm && (
            <ModalConfirm
              open={openEscalateModalConfirm}
              confirmText="Escalate"
              onConfirm={() => {
                onPrimaryButtonClick(TASK_RESOLUTION_ENUM.ESCALATED, closeTask);
                setOpenEscalateModalConfirm(false);
              }}
              onClose={() => setOpenEscalateModalConfirm(false)}
            >
              Please make sure that you have entered escalation reasons in the notes
            </ModalConfirm>
          )}
        </>
      )}
      {showReassign && (
        <>
          <Button
            onClick={() => {
              setOpenReassignModalConfirm(true);
            }}
            theme="text"
            disabled={isLoadingTask || isSavingTask || disabledReassign}
          >
            Reassign
          </Button>
          {openReassignModalConfirm && (
            <ModalConfirm
              open={openReassignModalConfirm}
              confirmText="Reassign"
              onConfirm={() => {
                onPrimaryButtonClick(TASK_RESOLUTION_ENUM.COMPLETED, closeTask, { fromReassign: true });
                setOpenReassignModalConfirm(false);
              }}
              onClose={() => {
                setOpenReassignModalConfirm(false);
              }}
            >
              Do you really want to {formData.reassignTo}? Please make sure that you have entered escalation reasons in
              the notes
            </ModalConfirm>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className="TaskActionBar">
        {(!isCompleted || (isCompleted && isSavingTask)) && (
          <SavedDisplay
            saving={isSavingTask}
            requesting={isLoadingTask}
            defaultShow={isSimpleReviewTask || isSavingTask}
          />
        )}

        {isCompleted && !isSavingTask && (
          <span className="TaskActionBarInfo">
            <span>
              Task marked {taskStatus} by {firstName} {lastName} on {monthDay}, {year}, {hours}
            </span>
          </span>
        )}

        {!isHiddenTaskActionBar && (
          <div className="TaskActionBarButtons">
            {!isCompleted && openTaskBar}
            <Button
              disabled={isPrimaryButtonDisabled}
              className="TaskActionBarButton"
              onClick={() => onPrimaryButtonClick(TASK_RESOLUTION_ENUM.COMPLETED, closeTask)}
            >
              {primaryButtonName}
            </Button>
          </div>
        )}
      </div>
      {isSimpleReviewTask ? (
        <ModalConfirm
          open={showPrompt}
          header="Save changes before leaving?"
          cancelText="Don’t save"
          confirmText="Save and leave"
          onConfirm={async () => {
            await onModalConfirm?.();
            confirmNavigation();
          }}
          onClose={cancelNavigation}
          onCancel={() => {
            onModalCancel?.();
            confirmNavigation();
          }}
        >
          You have unsaved changes. Would you like to save the changes before leaving?
        </ModalConfirm>
      ) : (
        <ModalConfirm
          open={showPrompt}
          header="Unsaved changes"
          confirmText="Yes"
          onConfirm={confirmNavigation}
          onClose={cancelNavigation}
          onCancel={cancelNavigation}
        >
          You have unsaved changes. Are you sure you wish to leave?
        </ModalConfirm>
      )}
    </>
  );
};

TaskActionBar.propTypes = {
  task: PropTypes.object,
  isSecondaryButtonEnabled: PropTypes.bool.isRequired,
  primaryButtonName: PropTypes.string.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  onDemoButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isPrimaryButtonEnabled: PropTypes.bool,
  onModalConfirm: PropTypes.func,
  onModalCancel: PropTypes.func,
};

export default TaskActionBar;
