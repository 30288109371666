import { useCallback, useEffect, useMemo, useState } from 'react';

export const DELETE_MULTI_DOC_ACTION = 'delete';

const getInitialDocumentSelections = ({ itemsWithDocumentIds }) => {
  return itemsWithDocumentIds?.reduce((acc, item) => ({ ...acc, [item.identifier]: item.documentIds }), {}) ?? {};
};

/**
 * @typedef {object} MultiDocSelectorParams
 * @property {object[]} documents List of all (GraphQL) documents available to select
 * @property {string} itemsWithDocumentIds Existing items with a list of linked document ids or a single document id
 * @property {boolean} docsCanLinkToMultipleItems Whether or not a document can be linked to multiple items.
 *                        If false, then the available document options dynamically change based on the other selected documents.
 */

/**
 * @typedef {object} MultiDocSelectorReturn
 * @property {string[]} remainingDocIds List of unselected document ids
 * @property {(string) => Option[]} getAvailableDocOptions Get the aviailable document options for a given form (i.e. item)
 * @property {(string, string[]) => Option[]} changeDocSelections Change the select items for a given form (i.e. item)
 * @property {string[]} selectedDocIds Get the aviailable document options for a given form (i.e. item)
 */

/**
 * Hook-up a multi forms to the same list of documents. So that each form can select from the same list of documents.
 * @param {MultiDocSelectorParams} params
 * @return {MultiDocSelectorReturn}
 */
export const useMultiDocSelector = ({ documents, itemsWithDocumentIds, docsCanLinkToMultipleItems = true }) => {
  const [docSelections, setDocSelections] = useState(getInitialDocumentSelections({ itemsWithDocumentIds }));
  useEffect(() => {
    setDocSelections(getInitialDocumentSelections({ itemsWithDocumentIds }));
  }, [documents, itemsWithDocumentIds]);

  const selectedDocIds = useMemo(() => Object.entries(docSelections).flatMap(([_, docIds]) => docIds), [docSelections]);
  const getAvailableDocOptions = useCallback(
    formId => {
      const otherSelectedIds = docsCanLinkToMultipleItems
        ? []
        : Object.entries(docSelections)
            .filter(([identifier, _]) => String(identifier) !== String(formId))
            .flatMap(([_, docIds]) => docIds);
      return (
        documents
          ?.filter(({ id }) => !otherSelectedIds.includes(id))
          ?.map(({ id, filename }) => ({ value: id, label: filename })) ?? []
      );
    },
    [docSelections, documents, docsCanLinkToMultipleItems],
  );

  const changeDocSelections = useCallback(
    (formId, documentIds, action) => {
      if (action === DELETE_MULTI_DOC_ACTION && !docsCanLinkToMultipleItems) {
        if (!docSelections[formId]) return;
        const copy = { ...docSelections };
        delete copy[formId];
        return setDocSelections(copy);
      }
      setDocSelections({ ...docSelections, [formId]: documentIds });
    },
    [docSelections, docsCanLinkToMultipleItems],
  );

  const remainingDocIds = useMemo(
    () => documents?.filter(({ id }) => !selectedDocIds.includes(id)).map(({ id }) => id) ?? [],
    [documents, selectedDocIds],
  );

  return {
    remainingDocIds,
    getAvailableDocOptions,
    changeDocSelections,
    selectedDocIds,
  };
};
