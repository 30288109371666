import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_DOCUMENT_SET_BATCHES_QUERY } from '../documentSetBatchRequests';

export const DOCUMENT_SET_BATCHES_PER_PAGE = 20;

/**
 * @description custom hook to handle the pagination of the document set batches
 * @param {defaultSortDirection: 'asc' | 'desc'} defaultSortDirection - the default direction to sort the data by
 * @param {defaultSortField: String} defaultSortField - the default field to sort the data by. This is used within the graphQL query
 * @param {defaultSortId: String} defaultSortId - the default id of the selector that was clicked. Used to change the arrows in a sortable table/ keep track of what field is being sorted
 */
const useDocumentSetBatchPagination = ({ defaultSortDirection, defaultSortField, defaultSortId }) => {
  const [paginationData, setPaginationData] = useState({
    limit: DOCUMENT_SET_BATCHES_PER_PAGE,
    offset: 0,
    sortDirection: defaultSortDirection,
    sortField: defaultSortField,
    sortId: defaultSortId,
  });
  const [hasMore, setHasMore] = useState(true);
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: initialLoading,
    error,
    fetchMore,
  } = useQuery(GET_DOCUMENT_SET_BATCHES_QUERY, {
    fetchPolicy: 'cache-and-network',
    // allows fetching document sets even if there is an error in a backend resolver
    // Reference: https://www.apollographql.com/docs/react/data/error-handling/#setting-an-error-policy
    errorPolicy: 'all',
    variables: {
      limit: DOCUMENT_SET_BATCHES_PER_PAGE,
      offset: 0,
      sortDirection: defaultSortDirection,
      sortField: defaultSortField,
    },
  });

  /**
   * @description set the batches upon the initial load
   */
  useEffect(() => {
    if (!batches?.length && data?.paginatedDocumentBatchRequests?.documentSetBatches) {
      setBatches(data?.paginatedDocumentBatchRequests?.documentSetBatches);
      setHasMore(data?.paginatedDocumentBatchRequests?.hasMore);
    }
  }, [batches?.length, data?.paginatedDocumentBatchRequests]);

  /**
   * @description load more data from the server by increasing the offset and maintaining the limit
   */
  const handleLoadMore = () => {
    if (data?.paginatedDocumentBatchRequests?.hasMore) {
      setLoading(true);
      fetchMore({
        variables: {
          limit: DOCUMENT_SET_BATCHES_PER_PAGE,
          offset: batches?.length,
          sortDirection: paginationData.sortDirection,
          sortField: paginationData.sortField,
        },
      })
        .then(result => {
          setHasMore(result?.data?.paginatedDocumentBatchRequests?.hasMore);
          setBatches([...batches, ...result?.data?.paginatedDocumentBatchRequests?.documentSetBatches]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  /**
   * @description refetch the data from the server using the current sort and limit
   */
  const handleRefetch = (overrideSortDirection = '', overrideSortField = '', overrideSortId = '') => {
    setLoading(true);
    fetchMore({
      variables: {
        limit: DOCUMENT_SET_BATCHES_PER_PAGE,
        offset: 0,
        sortDirection: overrideSortDirection || paginationData.sortDirection,
        sortField: overrideSortField || paginationData.sortField,
      },
    })
      .then(result => {
        setHasMore(result?.data?.paginatedDocumentBatchRequests?.hasMore);
        setBatches(result?.data?.paginatedDocumentBatchRequests?.documentSetBatches);

        if (overrideSortDirection && overrideSortField && overrideSortId) {
          setPaginationData({
            ...paginationData,
            sortDirection: overrideSortDirection,
            sortField: overrideSortField,
            sortId: overrideSortId,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   *
   * @param {String} sortField - the field that will be used against the graphql Query to sort by
   * @param {"asc" | "desc"} sortDirection {'asc' | 'desc'} - the direction to sort the data by
   * @param {String} sortId - the id of the selector that was clicked, this is used to change the arrows in a sortable table
   */
  const handleSort = (sortField, sortDirection, sortId) => {
    setLoading(true);
    fetchMore({
      variables: {
        limit: DOCUMENT_SET_BATCHES_PER_PAGE,
        offset: 0,
        sortDirection: sortDirection,
        sortField,
      },
    })
      .then(result => {
        setBatches(result?.data?.paginatedDocumentBatchRequests?.documentSetBatches);
        setPaginationData({
          ...paginationData,
          sortDirection,
          sortField,
          sortId,
        });
        setHasMore(result?.data?.paginatedDocumentBatchRequests?.hasMore);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    paginationData,
    loading: loading || initialLoading,
    error,
    handleSort,
    handleLoadMore,
    handleRefetch,
    batches,
    hasMore,
  };
};

export default useDocumentSetBatchPagination;
