import { gql } from '@apollo/client';

export const GET_GOVERNMENT_ID_REVIEW = gql`
  query GovernmentIDReview($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      applicants {
        id
        identifier
        birthDate
        fullName
        firstName

        person {
          identifier
        }

        governmentId {
          identifier
          nameAsAppearsOnId
          expirationDate
          identifier
        }
      }
    }
  }
`;
