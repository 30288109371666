import React, { useCallback, useState } from 'react';
import { RotateDirection } from '@react-pdf-viewer/core';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Icon } from '@hometap/htco-components';
import { toggleDocumentPin, updateDocumentVersionRotation } from '../../documentRequests';
import cx from 'classnames';
import { useTaskQueue } from 'hooks/useTaskQueue';
import { PinButton } from '../PinButton/PinButton';
import { showPinDocumentErrorToast } from '../../DocumentToasts';

import './PreviewDocumentBottombar.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import useCurrentUser from 'hooks/useCurrentUser';

// Max zoom is 100, min is 10
export const IMAGE_SCALE_INITIAL = 60;
export const IMAGE_SCALE_MIN = 10;
export const IMAGE_SCALE_MAX = 300;
export const IMAGE_SCALE_DELTA = 20;

/**
 * @typedef UsePreviewDocumentBottombarReturn
 * @type {object}
 * @property {number} imageScale Image File Scaling. Used by Viewer.
 * @property {React.Dispatch<number>} setImageScale Set the Image File Scaling
 * @property {number} imageRotation Image File Rotation. Used by Viewer.
 * @property {React.Dispatch<number>} setImageRotation Set the Image File Rotation
 * @property {React.Dispatch<(task: () => void)>} addQueueTask Add a request to async request queue
 * @property {import('@react-pdf-viewer/zoom').ZoomPlugin} zoomPluginInstance PDF Viewer Zoom Plugin
 * @property {import('@react-pdf-viewer/zoom').ZoomPlugin} rotatePluginInstance PDF Viewer Rotate Plugin
 * @property {() => void} resetBottombar Reset the bottom bars state on new document/version
 * @property {boolean} isPinnedLoading Is there an ongoing pin request?
 * @property {React.Dispatch<number>} setIsPinnedLoading Set whether there is an ongoing pin request
 */

/** Use the hooks for the Document Viewer's Bottombar.
 * @returns {UsePreviewDocumentBottombarReturn}
 */
export function usePreviewDocumentBottombar() {
  const rotatePluginInstance = rotatePlugin();
  const zoomPluginInstance = zoomPlugin();
  const { addQueueTask } = useTaskQueue();
  const [imageScale, setImageScale] = useState(IMAGE_SCALE_INITIAL);
  const [imageRotation, setImageRotation] = useState(0);
  const [isPinnedLoading, setIsPinnedLoading] = useState(false);

  const resetBottombar = useCallback(docOrVersion => {
    setImageRotation(docOrVersion?.rotation ?? 0);
    setImageScale(IMAGE_SCALE_INITIAL);
  }, []);

  return {
    imageScale,
    setImageScale,
    imageRotation,
    setImageRotation,
    addQueueTask,
    zoomPluginInstance,
    rotatePluginInstance,
    resetBottombar,
    isPinnedLoading,
    setIsPinnedLoading,
  };
}

/**
 * @typedef PreviewDocumentBottombarParams
 * @type {object}
 * @property {import('../../documentRequests').Document?} viewDocument The primary version a document being viewed
 * @property {import('../../documentRequests').DocumentOrVersion?} viewDocumentOrVersion The document/version being viewed
 * @property {boolean} isEmbedded Is the document viewer embedded?
 * @property {'bottom'|'top'} [position] Position of the action bar
 * @property {boolean} isSmall Is the document viewer small?
 */

/** Document Viewer's Bottombar. Responsible for Zoom/Rotate of the Image/PDF.
 * @param {PreviewDocumentBottombarParams & UsePreviewDocumentBottombarReturn} params
 * @returns {JSX.Element}
 *    -   Fetching Document/Version   ->  null
 *    -   File type not supported     ->  null
 *    -   Document/Version Fetched    ->  Zoom & Rotate Buttons
 */
export default function PreviewDocumentBottombar({
  viewDocument,
  viewDocumentOrVersion,
  isEmbedded,
  // Hook return
  imageScale,
  setImageScale,
  imageRotation,
  setImageRotation,
  addQueueTask,
  zoomPluginInstance,
  rotatePluginInstance,
  isPinnedLoading,
  setIsPinnedLoading,
  position = 'bottom',
  isSmall,
}) {
  const { isContractor } = useCurrentUser();

  if (!viewDocument || !viewDocumentOrVersion?.isViewable) {
    return null;
  }

  const { id: documentId, pinned = false } = viewDocument;
  const { isPDF } = viewDocumentOrVersion;
  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const { Rotate } = rotatePluginInstance;

  const zoomIn = scalePDFFunc => {
    if (isPDF) {
      scalePDFFunc();
    } else {
      const newImageScale = imageScale + IMAGE_SCALE_DELTA;
      if (newImageScale <= IMAGE_SCALE_MAX) {
        setImageScale(newImageScale);
      }
    }
  };

  const zoomOut = scalePDFFunc => {
    if (isPDF) {
      scalePDFFunc();
    } else {
      const newImageScale = imageScale - IMAGE_SCALE_DELTA;
      if (newImageScale >= IMAGE_SCALE_MIN) {
        setImageScale(newImageScale);
      }
    }
  };

  /** Handles rotate button pressed
   *
   * Restriction: Cannot be called unless current document is defined
   *
   * @param {callback} rotatePDFFunc:  object of props that contain a .onClick Function
   * @param {int} rotationalDelta:  90/-90 to determine turn right or left
   */
  const handleRotate = (rotatePDFFunc, rotationalDelta) => {
    const rotation = rotationalDelta + imageRotation;
    const viewDocumentRotation = viewDocumentOrVersion.rotation ?? 0;
    // updateDocumentVersionRotation will only accept rotations of 0,90,180,270 so if we go below 0 or above 270 we must loop back to 270 or 0
    if (viewDocumentRotation + rotationalDelta > 270) {
      viewDocumentOrVersion.rotation = 0;
    } else if (viewDocumentRotation + rotationalDelta < 0) {
      viewDocumentOrVersion.rotation = 270;
    } else {
      viewDocumentOrVersion.rotation = viewDocumentRotation + rotationalDelta;
    }

    // if file is pdf, there is a built in rotate function
    if (isPDF) {
      rotatePDFFunc();
    }
    setImageRotation(rotation);
    // PATCH request to update the rotation of the document
    addQueueTask(() => updateDocumentVersionRotation(viewDocumentOrVersion.id, viewDocumentOrVersion.rotation));
  };

  const pinDocument = async isPinned => {
    if (isPinnedLoading) {
      return;
    }
    setIsPinnedLoading(true);
    try {
      // we should pin/unpin only the primary version
      await toggleDocumentPin(documentId, isPinned);
      // setDocuments doesn't change documents state
      viewDocument.pinned = isPinned;
    } catch (error) {
      showPinDocumentErrorToast(error);
    }
    setIsPinnedLoading(false);
  };

  return (
    <div className={cx('PreviewDocumentBottombar', { isEmbedded, isSmall, isTop: position === 'top' })}>
      {!isContractor && (
        <div>
          <PinButton
            size="2x"
            className={cx('PreviewDocumentButton', {
              isDisabled: isPinnedLoading,
              isSmall,
            })}
            pinned={pinned}
            onClick={() => pinDocument(!pinned)}
          />
        </div>
      )}
      {/* only render zoom buttons for PDF files */}
      {isPDF && (
        <>
          <div>
            <ZoomIn>
              {zoomProps => (
                <button onClick={() => zoomIn(zoomProps.onClick)}>
                  <Icon
                    className={cx('PreviewDocumentButton', {
                      isDisabled: imageScale >= 100,
                    })}
                    name="icon-zoom-in"
                    size="2x"
                  />
                </button>
              )}
            </ZoomIn>
          </div>
          <div>
            <ZoomOut>
              {zoomProps => (
                <button onClick={() => zoomOut(zoomProps.onClick)}>
                  <Icon
                    className={cx('PreviewDocumentButton', {
                      isDisabled: imageScale <= 10,
                    })}
                    name="icon-zoom-out"
                    size="2x"
                  />
                </button>
              )}
            </ZoomOut>
          </div>
        </>
      )}
      <div>
        <Rotate direction={RotateDirection.Backward}>
          {rotateProps => (
            <button onClick={() => handleRotate(rotateProps.onClick, -90)}>
              <Icon className="PreviewDocumentButton" name="icon-rotate-left" size="2x" />
            </button>
          )}
        </Rotate>
      </div>
      <div>
        <Rotate direction={RotateDirection.Forward}>
          {rotateProps => (
            <button onClick={() => handleRotate(rotateProps.onClick, 90)}>
              <Icon className="PreviewDocumentButton" name="icon-rotate-right" size="2x" />
            </button>
          )}
        </Rotate>
      </div>
    </div>
  );
}
