import { gql } from '@apollo/client';

export const GET_TODOS = gql`
  query GetTodos(
    $nextPageLimit: Int
    $prevPageLimit: Int
    $nextPageCursor: String
    $prevPageCursor: String
    $orderBy: String
    $keyword: String
    $kind: [String!]
    $appSpecialists: [String!]
    $investmentManagers: [IdentifierType!]
    $underwriters: [IdentifierType!]
    $trackIds: [String!]
  ) {
    unifiedTasks(
      first: $nextPageLimit
      after: $nextPageCursor
      last: $prevPageLimit
      before: $prevPageCursor
      orderBy: $orderBy
      keyword: $keyword
      kinds: $kind
      status: ["not_started"]
      coordinator: $appSpecialists
      reviewers: $investmentManagers
      underwriters: $underwriters
      groups: ["homeowner"]
      trackIds: $trackIds
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          taskStatus
          identifier
          isOpen
          createdAt
          name
          kind
          notes
          id
          variables {
            name
            value
          }
          track {
            friendlyId
            id
            identifier
            applicants {
              isPrimaryApplicant
              fullNameShort
              fullName

              person {
                identifier
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TODOS_FILTERS = gql`
  query GetTodosFilters {
    homeownerTaskChoices {
      kind
      displayLabel
    }
    appSpecialists: reviewers(role: APPLICATION_SPECIALIST) {
      edges {
        node {
          identifier
          fullNameShort
        }
      }
    }
    investmentManagers: reviewers(role: INVESTMENT_MANAGER) {
      edges {
        node {
          identifier
          fullNameShort
        }
      }
    }
  }
`;
