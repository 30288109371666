import React, { useLayoutEffect } from 'react';
import { MuiTextarea } from '@hometap/htco-components';

import cx from 'classnames';
import PropTypes from 'prop-types';

import './FlexibleTextarea.scss';

const DEFAULT_PADDING_HEIGHT = 24;

/**
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.label - The text that will be displayed as the label of the textarea.
 * @param {string} props.theme - The theme of the textarea.
 * @param {string} props.width - The width of the textarea.
 * @param {boolean} props.disabled - Determines whether the textarea is disabled or not.
 * @param {boolean} props.showRequiredAsterisk - When true, adds an asterisk to the label, indicating that the field is required.
 * @param {number} props.rows - The number of rows in the textarea, controlling its initial visible height.
 * @param {boolean} props.required - Indicates whether the textarea is a required field.
 * @param {string} props.helperText - Used to display some helper text or an error message below the textarea.
 * @param {string} props.maxHeight - The maximum height of the textarea.
 * @returns
 */
const FlexibleTextarea = ({
  label,
  theme,
  width,
  disabled,
  showRequiredAsterisk = false,
  rows = 1,
  required,
  helperText,
  maxHeight,
  ...props
}) => {
  const textInputRef = React.useRef(null);
  const textInputValue = textInputRef?.current?.value;

  // /**
  //  * useLayoutEffect is used to increase the height of the text input and check the scroll state
  //  * reset height - important to reduce when removed with "inherit"
  //  */
  useLayoutEffect(() => {
    const { current } = textInputRef;

    if (current) {
      current.style.height = 'inherit';

      const { nextElementSibling, scrollHeight = 0 } = current;

      const isOverMaxHeight = maxHeight && scrollHeight > maxHeight;

      current.style.height = `${isOverMaxHeight ? maxHeight : scrollHeight}px`;

      if (!isOverMaxHeight && nextElementSibling && nextElementSibling.classList.contains('htco-MuiTextAreaOutline')) {
        nextElementSibling.style.height = `${scrollHeight + DEFAULT_PADDING_HEIGHT}px`;
      }
    }
  }, [textInputValue, maxHeight]);

  return (
    <MuiTextarea
      className={cx('FlexibleTextarea', { FlexibleTextareaHelperText: helperText })}
      label={label}
      theme="outlined"
      width="100%"
      disabled={disabled}
      showRequiredAsterisk={showRequiredAsterisk}
      rows={rows}
      required={required}
      ref={textInputRef}
      helperText={helperText}
      {...props}
    />
  );
};

FlexibleTextarea.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.string,
  helperText: PropTypes.string,
  showRequiredAsterisk: PropTypes.bool,
  /** This is used with useForm hook to manage required fields */
  registerRequiredField: PropTypes.func,
  unregisterRequiredField: PropTypes.func,
  theme: PropTypes.oneOf(['filled', 'outlined']),
};

export default FlexibleTextarea;
