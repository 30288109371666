import React from 'react';
import cx from 'classnames';

export const TrackDetailsPadding = ({ children, className, ...props }) => (
  <div className={cx('TrackDetailsPadding', className)} {...props}>
    {children}
  </div>
);

export default TrackDetailsPadding;
