import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FormError, Button, useEscape } from '@hometap/htco-components';
import { FileUploader } from '../FileUploader/FileUploader';
import { DocumentsFileList } from '../DocumentsFileList/DocumentsFileList';
import {
  DOCUMENTS_TYPE,
  MAX_BULK_UPLOAD_FILE_COUNT,
  MAX_UPLOAD_SIZE_BYTES,
} from 'apps/track-details/Documents/data/constants';
import { SlideInFromRight } from '../SlideInFromRight/SlideInFromRight';
import { formatFileObject, DOCUMENT_STATUS, formatErrorMessage, isDocumentPasswordProtected } from 'utils/documents';
import { FadeIn } from '../FadeIn/FadeIn';
import cx from 'classnames';
import useIsOverflow from 'hooks/useIsOverflow';
import { StaticDocumentType } from '../StaticDocumentType/StaticDocumentType';
import { isEmpty } from 'lodash';
import { NewMergedDocument } from '../NewMergedDocument/NewMergedDocument';
import { UploadNewOrEditDocumentFields } from '../UploadNewOrEditDocumentFields/UploadNewOrEditDocumentFields';
import PDFWorker from 'components/PDFWorker/PDFWorker';

import './UploadDocument.scss';

export const UploadDocument = ({
  open,
  close,
  processDocuments,
  useDocumentKindsResults,
  setFiles,
  files,
  docType,
  setDocType,
  otherType,
  setOtherType,
  newVersionDoc,
  handleCancel,
  newDocumentFields,
  setNewDocumentFields,
  isCombine,
  setIsCombine,
  closable = true,
  defaultVisibilityLabel,
  isDefaultVisibilityEditable,
  applicantsOptions,
  personId,
  setPersonId,
  applicant,
}) => {
  const containerRef = useRef();
  const uploaderRef = useRef();
  const checkBoxRef = useRef();
  const mergeDocumentRef = useRef();
  const [error, setError] = useState();
  const isOverflow = useIsOverflow(containerRef.current, files);
  const { isDocumentType, documentKindsDropdownOptionsSorted, documentKindsWithPerson } = useDocumentKindsResults;

  const anyPasswordProtected = files.some(file => file.file.passwordProtected);
  const isNewVersion = !isEmpty(newVersionDoc);
  const handleScrollToRef = ref => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Scroll to merge document div when combined checkbox is checked
    if (!isEmpty(newVersionDoc) || isCombine) {
      handleScrollToRef(mergeDocumentRef);
    }
    // scroll to checkbox if there is more than one file but checkbox has not been checked yet
    else if (files.length > 1) {
      handleScrollToRef(checkBoxRef);
    }
  }, [isCombine, newVersionDoc, mergeDocumentRef, files.length]);

  useEffect(() => {
    if (files.length > 1 && isNewVersion) {
      setIsCombine(true);
    }
    if (files.length === 1) {
      setIsCombine(false);
    }
  }, [isNewVersion, files.length, setIsCombine]);

  const handleRemoveFile = removeFile => {
    const newFiles = files.filter(file => file.id !== removeFile.id);
    setFiles(newFiles);
  };

  const handleUploadDocument = () => {
    setError();
    close();
    processDocuments(files, docType, otherType, personId);
  };

  const handleDropdownSelect = dt => {
    setDocType(dt);
    // clear other type
    if (otherType && dt !== DOCUMENTS_TYPE.Other) {
      setOtherType();
    }
    if (personId && !documentKindsWithPerson.includes(dt)) {
      setPersonId();
    }
  };

  const handlePrepare = async ({ acceptedFiles, rejectedFiles }) => {
    // Format each file group as one list of files for display, create id for easy reference
    // [{id, file: File, status: "rejected", errors: [{message: ""}]}]

    // filters out files that exceed the max size (50MB)
    const newFiles = acceptedFiles
      .filter(file => file.size <= MAX_UPLOAD_SIZE_BYTES)
      .map(file => formatFileObject(file, DOCUMENT_STATUS.ACCEPTED));

    // Display first error instead of stacking potentially a long list of errors
    rejectedFiles.forEach(file => {
      setError(formatErrorMessage(file.errors[0].message));
    });

    const allFiles = files.concat(newFiles);
    if (allFiles.length > MAX_BULK_UPLOAD_FILE_COUNT) {
      setError(`You've reached the file limit of ${MAX_BULK_UPLOAD_FILE_COUNT} files, please remove some to upload.`);
    } else {
      // loop through all files and for each pdf, use pdf.js to check if they are password protected
      // if they are, set error but still add it to the files state
      const updatedFiles = await Promise.all(
        allFiles.map(async fileWrapper => {
          if (fileWrapper.file.type === 'application/pdf') {
            try {
              const result = await isDocumentPasswordProtected(fileWrapper?.file);
              fileWrapper.file.passwordProtected = result;
            } catch (error) {
              fileWrapper.file.passwordProtected = false;
            }
          } else {
            fileWrapper.file.passwordProtected = false;
          }
          return fileWrapper;
        }),
      );

      // if any of the files are password protected, uncheck the merge box
      // only applies for uploading a new document. not for new version
      const anyPasswordProtected = updatedFiles.some(file => file.file.passwordProtected);
      if (anyPasswordProtected && !isNewVersion) {
        setIsCombine(false);
      }

      setFiles(updatedFiles);
    }
  };

  const disableButton = useMemo(() => {
    if (isNewVersion && anyPasswordProtected) {
      // if there are multiple documents and any of them are password protected, disable the button
      if (files.length > 1) {
        return true;
      }
    }
    if (files.length > 0 && docType) {
      if (docType === DOCUMENTS_TYPE.Other) {
        return !otherType;
      }
      if (documentKindsWithPerson.includes(docType)) {
        return isEmpty(newVersionDoc) ? !personId : false;
      }
      return false;
    }
    return true;
  }, [
    anyPasswordProtected,
    docType,
    documentKindsWithPerson,
    files.length,
    isNewVersion,
    newVersionDoc,
    otherType,
    personId,
  ]);

  // called when slider is closing without processing documents
  const handleCloseSliderWithoutProcess = () => {
    if (closable) {
      setError();
      setFiles([]);
      handleCancel();
    }
  };

  useEscape(true, handleCloseSliderWithoutProcess);

  useEffect(() => {
    if (Object.keys(newVersionDoc).length !== 0) {
      if (isDocumentType(newVersionDoc.kind_old_code)) {
        setDocType(newVersionDoc.kind_old_code);
        if (newVersionDoc?.personId) {
          setPersonId(newVersionDoc.personId);
        }
        setOtherType();
      } else {
        setDocType('other');
        setOtherType(newVersionDoc.kind_display);
        setPersonId();
      }
    }
  }, [isDocumentType, newVersionDoc, setDocType, setOtherType, setPersonId]);

  useEffect(() => {
    if (isNewVersion) {
      setIsCombine(true);
    } else {
      setIsCombine(false);
    }
  }, [isNewVersion, setIsCombine]);

  const additionalFieldsProps = useMemo(() => {
    const additionalFields = {
      [DOCUMENTS_TYPE.Other]: {
        name: 'other',
        value: otherType,
        onChange: v => setOtherType(v),
      },
    };
    if (documentKindsWithPerson.includes(docType)) {
      additionalFields[docType] = {
        name: 'person_id',
        value: personId,
        onChange: setPersonId,
      };
    }
    return additionalFields;
  }, [personId, docType, documentKindsWithPerson, otherType, setOtherType, setPersonId]);

  return (
    <PDFWorker>
      <SlideInFromRight
        closable={closable}
        open={open}
        close={handleCloseSliderWithoutProcess}
        className="UploadDocument"
      >
        {/* Scrollable container */}
        <div className="UploadDocumentContainer" ref={containerRef}>
          <h2 className="UploadDocumentsTitle">Upload Documents</h2>
          {isEmpty(newVersionDoc) ? (
            <UploadNewOrEditDocumentFields
              docType={docType}
              applicantsOptions={applicantsOptions}
              additionalFieldsByDocumentTypeProps={additionalFieldsProps}
              documentKindsWithPerson={documentKindsWithPerson}
              documentKindsDropdownOptions={documentKindsDropdownOptionsSorted}
              documentTypeFieldProps={{
                name: 'docType',
                value: docType,
                onChange: handleDropdownSelect,
              }}
              className="UploadDocumentFields"
            />
          ) : (
            <StaticDocumentType
              useDocumentKindsResults={useDocumentKindsResults}
              documentType={docType}
              otherType={otherType}
              applicant={applicant}
            />
          )}

          <FileUploader
            onUpload={handlePrepare}
            uploadTrigger={uploaderRef}
            setError={error => setError(formatErrorMessage(error))}
            options={{ maxSize: MAX_UPLOAD_SIZE_BYTES, disabled: files.length > MAX_BULK_UPLOAD_FILE_COUNT }}
          />
          <FadeIn open={error}>
            <FormError standalone error={error} />
          </FadeIn>
          <DocumentsFileList
            files={files}
            onRemove={handleRemoveFile}
            onReplace={() => uploaderRef.current()}
            newVersionDoc={newVersionDoc}
            combine={isCombine}
            setCombine={setIsCombine}
            defaultVisibilityLabel={defaultVisibilityLabel}
            isDefaultVisibilityEditable={isDefaultVisibilityEditable}
            setFiles={setFiles}
            checkBoxRef={checkBoxRef}
            anyPasswordProtected={anyPasswordProtected}
            isNewVersion={isNewVersion}
            documentKind={docType}
          />
          <div ref={mergeDocumentRef}>
            {/* Only render for new Version Document or merging files into pdf */}
            {(!isEmpty(newVersionDoc) || isCombine) && !anyPasswordProtected && (
              <NewMergedDocument
                isOpen={files.length > 1}
                values={newDocumentFields}
                onValuesChange={setNewDocumentFields}
                defaultVisibilityLabel={defaultVisibilityLabel}
                isDefaultVisibilityEditable={isDefaultVisibilityEditable}
                documentKind={docType}
              />
            )}
          </div>
        </div>
        <div className={cx('UploadDocumentButtonsContainer', { isBoxShadowApplied: isOverflow })}>
          <Button theme="text" onClick={handleCloseSliderWithoutProcess}>
            Cancel
          </Button>
          <Button disabled={disableButton} onClick={handleUploadDocument}>
            Upload Documents
          </Button>
        </div>
      </SlideInFromRight>
    </PDFWorker>
  );
};
