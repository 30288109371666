import { faker } from '@faker-js/faker';
import DemoButton from 'components/DemoButton/DemoButton';
import React from 'react';
import { TODO_NOTES_OPTIONS } from 'apps/track-details/data/constants/todoForm';
import { getVariableValue, getValueByProperty } from 'apps/track-details/tasks/components/TaskList/TaskListUtils';
import useConfigurations from 'hooks/useConfigurations';

const TodosDemoButton = ({ handleCreateTodosSubmit, track }) => {
  const { taskDocumentKinds } = useConfigurations();

  // TODO: Demo should handle task kinds which expand by applicant
  const { assignableHomeownerTaskChoices, homeownerTasks } = track;

  const getRandomNonDupeAssignableTask = () => {
    const alreadyAssignedTodos = new Set(
      homeownerTasks.map(task => {
        const docKind = getVariableValue(task, 'doc_kind');
        const todoKind = getValueByProperty(taskDocumentKinds, docKind, 'kind');
        return todoKind;
      }),
    );
    const nonDupes = assignableHomeownerTaskChoices.filter(choice => !alreadyAssignedTodos.has(choice.kind));
    return faker.helpers.arrayElement(nonDupes);
  };

  const handleDemo = () => {
    const { kind } = getRandomNonDupeAssignableTask();
    // 50/50 chance to pick a random note, or leave empty
    const allNotes = [...TODO_NOTES_OPTIONS, ...Array(TODO_NOTES_OPTIONS.length).fill('')];
    const notes = faker.helpers.arrayElement(allNotes);
    handleCreateTodosSubmit(undefined, [{ kind, notes }]);
  };

  return <DemoButton onClickAction={handleDemo} />;
};

export default TodosDemoButton;
