import React from 'react';
import FundItem from '../FundItem/FundItem';
import './FundAllocationHeaders.scss';
import useFundAllocation from 'apps/funding/hooks/useFundAllocation';

const FundAllocationHeader = ({ combinedTracks, fundNameDictionary }) => {
  const { totalInPipeLine, allocationByFund } = useFundAllocation(combinedTracks, fundNameDictionary);

  //Control Fund Order
  //Unallocated and then Funds in same order as fundNameDict but only if there is money allocated
  const namesOfUsedFunds = Object.keys(fundNameDictionary).filter(key => allocationByFund[key]);

  return (
    <div className="FundAllocationHeaderContainer">
      <h2 className="FundAllocationHeaderLabel">Fund allocations</h2>
      <div className="FundAllocationHeaderFunds">
        <FundItem key="total" label="Total in Pipeline" fundAmount={totalInPipeLine} type="AVAILABLE" />
        <FundItem key="unallocated" {...allocationByFund['unallocated']} />
        {namesOfUsedFunds.map(key => (
          <FundItem key={key} {...allocationByFund[key]} />
        ))}
      </div>
    </div>
  );
};

export default FundAllocationHeader;
