import { REVIEWER_ROLES } from 'userRoles';
import useCurrentUser from './useCurrentUser';

const hasOpsPermissions = user =>
  [
    REVIEWER_ROLES.APPLICATION_SPECIALIST,
    REVIEWER_ROLES.APPLICATION_SPECIALIST_SUPERVISOR,
    REVIEWER_ROLES.UNDERWRITER,
    REVIEWER_ROLES.SERVICER,
  ].some(role => user?.roles?.includes(role));

const useCurrentUserPermissions = () => {
  const { user } = useCurrentUser();
  return {
    hasOpsPermissions: hasOpsPermissions(user),
  };
};

export default useCurrentUserPermissions;
