import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput, MuiTextarea, useForm } from '@hometap/htco-components';

import React, { useCallback, useEffect, useMemo } from 'react';
import { FLORIDA_LOW_REPLACEMENT_COST, COOP_PROPERTY_DQ_MESSAGE } from 'apps/track-details/data/constants/messages';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { useInsuranceReviewForm } from '../../hooks/useInsuranceReviewForm';
import useConfigurations from 'hooks/useConfigurations';
import {
  useSectionFormById,
  useUpdateSectionForm,
} from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { DELETE_INSURANCE_POLICY, POLICY_KIND_TO_MUTATION } from 'apps/track-details/tasks/data/mutations';
import { useApolloClient, useMutation } from '@apollo/client';
import { INSURANCE_POLICY_KINDS } from 'apps/track-details/data/constants/policyKinds';
import { CONFIGURATIONS_PROPERTY_TYPE } from 'data/constants/configurations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { emailValidator, phoneValidator } from 'utils/validators';
import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal';

const OTHER = 'Other';

const AGENT_CONTACT_TYPE = {
  Email: 'email',
  Phone: 'phone',
};

const isCoOpPropType = propertyType => {
  return propertyType === CONFIGURATIONS_PROPERTY_TYPE.CoOp;
};

const InsurancePolicyDetails = ({
  disabled,
  policy,
  prevPolicyId,
  isApplicationFloridaState,
  initialResidenceType,
  initialPropertyType,
  onDelete,
}) => {
  const [deletePolicyMutation] = useMutation(DELETE_INSURANCE_POLICY);

  useInsuranceReviewForm(policy);
  const { allConfigurations } = useConfigurations();
  const { formData: prevFormDataToSave, initialFormData: prevInitialFormData } = useSectionFormById(prevPolicyId);
  const { updateSectionFormById } = useUpdateSectionForm();
  const { residenceTypes, propertyTypes } = allConfigurations?.home || {};
  const { insuranceProviders } = allConfigurations?.insurancePolicy || {};
  const providerOptions = insuranceProviders?.map(provider => ({ value: provider, label: provider })) || [];
  const policyId = policy.identifier;

  const {
    updateInsurancePolicy,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    showSubmitErrors,
  } = useReviewInsuranceForm(policy, initialResidenceType, initialPropertyType, insuranceProviders);

  const { propertyType, contactType, provider } = formData;
  const isCoOp = isCoOpPropType(propertyType);
  const isFormValidOrCoOp = isFormValid || isCoOp;
  const showResidenceAndPropertyTypeFields = ![
    INSURANCE_POLICY_KINDS.OTHER,
    INSURANCE_POLICY_KINDS.FLOOD,
    INSURANCE_POLICY_KINDS.CONDO,
  ].includes(policy.kind);
  const showReplacementCostField = ![INSURANCE_POLICY_KINDS.OTHER, INSURANCE_POLICY_KINDS.FLOOD].includes(policy.kind);
  const showAnnualCost = [INSURANCE_POLICY_KINDS.HOMEOWNERS, INSURANCE_POLICY_KINDS.FLOOD].includes(policy.kind);
  const showFloodHelperText = policy.kind === INSURANCE_POLICY_KINDS.FLOOD;

  useEffect(() => {
    if (prevPolicyId && prevPolicyId !== policyId) {
      updateSectionFormById(prevPolicyId, {
        prevFormData: prevFormDataToSave,
      });
    }
  }, [prevPolicyId, policyId, prevFormDataToSave, updateSectionFormById, prevInitialFormData]);

  useEffect(() => {
    updateSectionFormById(policyId, {
      complete: updateInsurancePolicy,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValidOrCoOp,
      showSubmitErrors,
    });
  }, [
    updateInsurancePolicy,
    updateSectionFormById,
    policyId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    isFormValidOrCoOp,
    showSubmitErrors,
  ]);

  const onDeletePolicy = async () => {
    try {
      await deletePolicyMutation({ variables: { insurancePolicyId: policyId } });
      onDelete?.(policyId);
      updateSectionFormById(policyId, { isDeleted: true, isValidForm: true, isFormChanged: false });

      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'Insurance policy successfully deleted.',
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to delete the insurance policy',
        description: error.message || 'An error occurred while deleting the insurance policy.',
      });
    }
  };

  return (
    <FormWrapperWithDeleteConfirmModal
      title="Insurance policy form"
      header="Delete insurance policy?"
      type="Insurance policy"
      isDisabled={disabled}
      onDelete={onDeletePolicy}
    >
      {showResidenceAndPropertyTypeFields && (
        <div>
          <MuiSelect
            label="Property Type"
            options={propertyTypes}
            disabled={disabled}
            theme="outlined"
            width="100%"
            classNamePrefix="CustomInsurancePoliciesSelector"
            className="CustomInsurancePoliciesSelector"
            required={true}
            showRequiredAsterisk={false}
            {...registerField('propertyType')}
          />
        </div>
      )}
      {isCoOp && (
        <DetailInformationBlock
          type="alert"
          className="HomeownersInsuranceReviewBlockText"
          text={COOP_PROPERTY_DQ_MESSAGE}
        />
      )}
      {!isCoOp && (
        <>
          {showResidenceAndPropertyTypeFields && (
            <div>
              <MuiSelect
                label="Residence type"
                className="CustomInsurancePoliciesSelector"
                classNamePrefix="CustomInsurancePoliciesSelector"
                options={residenceTypes}
                disabled={disabled}
                theme="outlined"
                width="100%"
                required={true}
                showRequiredAsterisk={false}
                {...registerField('residenceType')}
              />
            </div>
          )}
          {showAnnualCost && (
            <div>
              <CurrencyInput
                label="Annual insurance cost"
                helperText={showFloodHelperText ? 'If in condo master policy, enter $0' : ''}
                padFractionalZeros={true}
                disabled={disabled}
                required={true}
                showRequiredAsterisk={false}
                {...registerField('annualCost')}
              />
            </div>
          )}
          <div>
            <MuiSelect
              className="CustomInsurancePoliciesSelector"
              classNamePrefix="CustomInsurancePoliciesSelector"
              options={providerOptions}
              disabled={disabled}
              label="Provider"
              theme="outlined"
              width="100%"
              required={true}
              showRequiredAsterisk={false}
              {...registerField('provider')}
            />
          </div>
          {provider === OTHER && (
            <div>
              <MuiTextInput
                label="Provider name"
                theme="outlined"
                width="100%"
                disabled={disabled}
                {...registerField('providerName')}
                required={provider === OTHER}
                showRequiredAsterisk={false}
              />
            </div>
          )}
          <div className="TwoItemFormRow">
            <MuiTextInput
              label="Agent Name (optional)"
              theme="outlined"
              width="100%"
              disabled={disabled}
              {...registerField('agentName')}
            />
            <MuiSelect
              label="Agent Contact Type (optional)"
              options={policy.agentContactTypeOptions}
              disabled={disabled}
              theme="outlined"
              width="100%"
              className="CustomInsurancePoliciesSelector"
              classNamePrefix="CustomInsurancePoliciesSelector"
              {...registerField('contactType')}
            />
          </div>
          {contactType === 'email' && (
            <MuiTextInput
              label="Agent Email (optional)"
              theme="outlined"
              width="100%"
              disabled={disabled}
              validator={emailValidator}
              {...registerField('agentEmail')}
            />
          )}
          {contactType === 'phone' && (
            <MuiTextInput
              label="Agent Phone (optional)"
              theme="outlined"
              width="100%"
              disabled={disabled}
              validate={phoneValidator}
              {...registerField('agentPhone')}
            />
          )}
          <div className="TwoItemFormRow">
            <DatePicker
              label={'Renewal Date'}
              theme="outlined"
              disabled={disabled}
              className="FullWidthDatePicker"
              wrapperClassName="FullWidthDatePicker"
              required={true}
              {...registerField('renewalDate')}
            />
            <MuiTextInput
              label="Policy Number"
              theme="outlined"
              width="100%"
              disabled={disabled}
              startAdornment="#"
              required={true}
              showRequiredAsterisk={false}
              {...registerField('policyNumber')}
            />
          </div>
          {showReplacementCostField && (
            <div>
              <SelectionYesNoSet
                isWithMarginBottom={false}
                disabled={disabled}
                label="Is there proof of replacement cost?"
                {...registerField('replacementCost')}
              />
              {isApplicationFloridaState && (
                <DetailInformationBlock
                  className="InsurancePoliciesInformationBlock"
                  text={FLORIDA_LOW_REPLACEMENT_COST}
                  type="info"
                />
              )}
            </div>
          )}
          <div>
            <CurrencyInput
              label="Coverage amount"
              validator={value => {
                if (value === '0' || !value) {
                  return 'Please enter a coverage amount.';
                }
              }}
              disabled={disabled}
              required={true}
              showRequiredAsterisk={false}
              extendedMaskProps={{ scale: 0 }}
              {...registerField('dwellingAmount')}
            />
          </div>
          <MuiTextarea
            label="Notes (optional)"
            theme="outlined"
            width="100%"
            disabled={disabled}
            {...registerField('notes')}
          />
        </>
      )}
    </FormWrapperWithDeleteConfirmModal>
  );
};

export default InsurancePolicyDetails;

export function getInitialFormData(policy, residenceType, propertyType, insuranceProviders) {
  const {
    agent,
    agentEmail,
    agentPhone,
    dwellingAmount,
    notes,
    policyNumber,
    annualCost,
    provider,
    renewalDate,
    replacementCost,
  } = policy;
  const initialFormData = {
    residenceType,
    propertyType,
    agentName: agent,
    annualCost,
    provider,
    renewalDate,
    policyNumber,
    dwellingAmount,
    notes,
    agentPhone,
    agentEmail,
    replacementCost: (typeof replacementCost === 'boolean' && replacementCost.toString()) || undefined,
  };
  if (provider && insuranceProviders?.length && !insuranceProviders.includes(provider)) {
    initialFormData.provider = OTHER;
    initialFormData.providerName = provider;
  }
  if (agentPhone) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Phone;
  }
  if (agentEmail) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Email;
  }
  return initialFormData;
}

function useReviewInsuranceForm(policy, residenceType, propertyType, insuranceProviders) {
  const client = useApolloClient();
  const { initialFormData: initialFormDataFromSectionMap } = useSectionFormById(policy.identifier);
  const initialFormData = useMemo(
    () => initialFormDataFromSectionMap ?? getInitialFormData(policy, residenceType, propertyType, insuranceProviders),
    [policy, insuranceProviders, residenceType, propertyType, initialFormDataFromSectionMap],
  );
  const { registerField, formData, updateFormData, isFormValid, showSubmitErrors } = useForm(initialFormData, {
    showInitialErrors: true,
  });

  const updateInsurancePolicy = useCallback(
    async ({ formData = {} }) => {
      const {
        providerName,
        agentPhone,
        agentEmail,
        contactType,
        annualCost,
        provider,
        propertyType,
        residenceType,
        policyNumber,
        renewalDate,
        replacementCost,
        agentName,
        dwellingAmount,
        notes,
      } = formData;
      const variables = {
        agentName,
        kind: INSURANCE_POLICY_KINDS.HOMEOWNERS,
        policyNumber,
        propertyType,
        annualCost: parseFloat(annualCost).toFixed(2),
        provider,
        renewalDate,
        replacementCost: replacementCost === 'true',
        residenceType,
        insurancePolicyId: policy.identifier,
        notes,
      };
      if (dwellingAmount) {
        variables.dwellingAmount = dwellingAmount;
      }
      if (contactType === AGENT_CONTACT_TYPE.Phone) {
        variables.agentPhone = agentPhone;
      }
      if (contactType === AGENT_CONTACT_TYPE.Email) {
        variables.agentEmail = agentEmail;
      }
      if (provider === OTHER) {
        variables.provider = providerName;
      }

      if (policy && policy.identifier) {
        try {
          const policyKind = isCoOpPropType(propertyType) ? INSURANCE_POLICY_KINDS.COOP : policy.kind;
          await client.mutate({
            mutation: POLICY_KIND_TO_MUTATION[policyKind],
            variables,
          });
          showNotification({
            type: TOAST_TYPE.success,
            title: 'Success',
            description: `Changes to "${policy.description}" successfully saved.`,
          });
        } catch (error) {
          showNotification({
            type: TOAST_TYPE.error,
            title: 'Failed to save changes',
            description: error.networkError?.result?.errors[0]?.message
              ? error.networkError.result.errors[0].message
              : 'Could not save changes. Please try again.',
          });
        }
      }
    },
    [policy, client],
  );

  return {
    updateInsurancePolicy,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid,
    showSubmitErrors,
  };
}
