import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { initialFormDataFromPaydownNode } from '../components/PaydownController/helpers';
import { useInitialLienForm } from './useInitialLienForm';
import { CREATE_PAYDOWN, DELETE_PAYDOWN, UPDATE_PAYDOWN } from 'apps/track-details/tasks/data/mutations';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { useLienForm } from './useLienForm';
import { PAYMENT_METHOD_TYPE } from '../components/PaydownController/constants';
import { getErrorNotificationDescription, getIsNewId, getSuccessNotificationDescription } from '../utils/liens';

export function usePaydownForm(paydown, trackId, lienId) {
  const [deletePaydownMutation] = useMutation(DELETE_PAYDOWN);
  const [createPaydownMutation] = useMutation(CREATE_PAYDOWN);
  const [updatePaydownMutation] = useMutation(UPDATE_PAYDOWN);

  const { registerField, formData, updateFormData, isFormValid, initialFormData, errors, setErrors, showSubmitErrors } =
    useInitialLienForm(paydown, initialFormDataFromPaydownNode);
  useLienForm(paydown);

  const paydownId = paydown?.identifier;

  const mutatePaydownForm = useCallback(
    async ({ formData = {}, newLienId }) => {
      const {
        isPayoff,
        principalPaydownAmount,
        feePaydownAmount,
        goodThroughDate,
        perDiem,
        paymentMethod,
        mailingInstructions,
        accountNumber,
        routingNumber,
        newLien: newPaydown,
      } = formData;

      if (!lienId || (getIsNewId(lienId) && !newLienId)) {
        return;
      }

      const variables = {
        isPayoff: isPayoff === 'true',
        principalPaydownAmount,
        feePaydownAmount,
        goodThroughDate,
        perDiem,
        paymentMethod: paymentMethod?.toUpperCase(),
        mailingInstructions,
        accountNumber,
        trackId,
      };

      variables.paydownId = paydownId;

      if (paymentMethod === PAYMENT_METHOD_TYPE.wire) {
        variables.routingNumber = routingNumber;
      }

      if (newPaydown) {
        variables.lienId = newLienId || lienId;
      }

      const createOrUpdatePaydown = newPaydown ? createPaydownMutation : updatePaydownMutation;

      try {
        const { data } = await createOrUpdatePaydown({
          variables,
        });
        const [mutationData] = Object.values(data);
        const paydownIdentifier = Object.values(mutationData)[0]['identifier'];
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: getSuccessNotificationDescription(newPaydown, 'Paydown'),
        });
        return { newPaydownIdentifier: newPaydown && paydownIdentifier ? paydownIdentifier : undefined };
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : getErrorNotificationDescription(newPaydown, 'Paydown'),
        });
      }
    },
    [lienId, trackId, paydownId, createPaydownMutation, updatePaydownMutation],
  );

  const deletePaydownForm = useCallback(
    async paydownId => {
      const variables = { paydownId };
      try {
        const { data } = await deletePaydownMutation({
          variables,
        });
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: 'Paydown successfully deleted.',
        });
        return data?.deletePaydown?.ok;
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : 'Could not delete Paydown. Please try again.',
        });
        return false;
      }
    },
    [deletePaydownMutation],
  );

  return {
    mutatePaydownForm,
    deletePaydownForm,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid,
    errors,
    setErrors,
    showSubmitErrors,
  };
}
