import React from 'react';
import cx from 'classnames';
import { Loader, Paper } from '@hometap/htco-components';
import './InvestmentCalculatorOutput.scss';
import AlertError from 'components/AlertError/AlertError';

const InvestmentCalculatorOutput = ({ data, loading }) => {
  return (
    <Paper theme="medium" pad={3} className="InvestmentCalcOutput">
      <h4>Maximum Potential Investment</h4>

      {loading && <Loader type="dot-pulse" overlay />}

      <div className={cx('InvestmentCalcOutputRow')}>
        {data.map((item, key) => {
          return (
            <span key={key}>
              {item.label && item.value && (
                <div className="InvestmentCalcOutputDataWrapper" key={key}>
                  <span>{item.label}</span>
                  <span className="InvestmentCalcOutputDataValue">{item.value}</span>
                </div>
              )}
              {item.error && (
                <div className="InvestmentCalcOutputDataWrapper" key={`${key}-error`}>
                  <AlertError error={item.error} />
                </div>
              )}
            </span>
          );
        })}
      </div>
    </Paper>
  );
};

export default InvestmentCalculatorOutput;
