import { useEffect, useMemo } from 'react';
import { getTotalAmount, validateBalance } from '../utils/liens';

const VALIDATION_LIEN_BALANCE = {
  currentBalance: ({ currentBalance, principalAmount, interestAmount, escrowAmount }) =>
    validateBalance({
      value: getTotalAmount([principalAmount, interestAmount, escrowAmount]),
      valueToCompare: currentBalance,
      errorText: 'Current principal balance is lower than total sum of monthly principal, escrow and interest amounts.',
    }),
  principalAmount: ({ principalAmount, currentBalance }) =>
    validateBalance({
      value: principalAmount,
      valueToCompare: currentBalance,
      fieldName: 'Principal amount',
    }),
  interestAmount: ({ interestAmount, currentBalance }) =>
    validateBalance({
      value: interestAmount,
      valueToCompare: currentBalance,
      fieldName: 'Monthly interest amount',
    }),
  escrowAmount: ({ escrowAmount, currentBalance }) =>
    validateBalance({
      value: escrowAmount,
      valueToCompare: currentBalance,
      fieldName: 'Escrow amount',
    }),
};

/**
 * Custom hook `useValidationLienBalance` performs validation on related fields.
 * It manages and displays errors for fields such as current principal balance, principal amount, interest amount, and escrow amount.
 * The hook uses a combination of `useMemo` to compute validation errors based on the provided field values, and `useEffect` to update errors in the state.
 *
 * @param {object} params - Parameters for the hook.
 * @param {Function} params.setErrors - Function to set validation errors.
 * @param {number} params.currentBalance - The current principal balance value to validate against.
 * @param {number} params.principalAmount - The principal amount value to validate.
 * @param {number} params.interestAmount - The interest amount value to validate.
 * @param {number} params.escrowAmount - The escrow amount value to validate.
 *
 * Even though errors are cleared when a field loses focus (on blur),
 * it's still necessary to validate the fields.
 * We need to use the returnable object to pass the validation functions to the form fields.
 * @returns {object} - Returns an object containing validation functions for each field.
 */
export const useValidationLienBalance = ({
  setErrors,
  currentBalance,
  principalAmount,
  interestAmount,
  escrowAmount,
}) => {
  const validationErrorsToShow = useMemo(
    () =>
      Object.entries(VALIDATION_LIEN_BALANCE).reduce((acc, [key, validate]) => {
        return { ...acc, [key]: validate({ currentBalance, principalAmount, interestAmount, escrowAmount }) };
      }, {}),
    [currentBalance, principalAmount, interestAmount, escrowAmount],
  );

  useEffect(() => {
    Object.entries(validationErrorsToShow).forEach(([key, error]) => {
      error ? setErrors(key, error, true) : setErrors(key, null, false);
    });
  }, [validationErrorsToShow, setErrors]);

  return VALIDATION_LIEN_BALANCE;
};
