import { Button, Icon, ModalConfirm } from '@hometap/htco-components';
import { useCallbackPrompt } from 'apps/track-details/tasks/hooks/useCallbackPrompt';
import './ApplicationReviewActionBar.scss';

/**
 * @typedef ApplicationReviewActionBarParams
 * @type {object}
 * @property {string} [primaryButtonName] a of tne primary button
 * @property {string} [secondaryButtonName] a of tne primary button
 * @property {boolean} [isPrimaryButtonEnabled]  a flag for enabling / disabling the primary button
 * @property {boolean} [isSecondaryButtonEnabled] a flag for enabling / disabling the secondary button
 * @property {boolean} [isShowPrompt] a flag for showing / hiding a prompt
 * @property {boolean} [isSaving] a flag for saving
 * @property {function} [onPrimaryButtonClick] a function which is called after primary button click
 * @property {function} [onSecondaryButtonClick] a function which is called after primary button click
 */
/**
 * @param {ApplicationReviewActionBarParams} params
 * @return JSX.Element
 */
const ApplicationReviewActionBar = ({
  primaryButtonName = 'Publish',
  secondaryButtonName = 'Cancel',
  isPrimaryButtonEnabled,
  isSecondaryButtonEnabled,
  isShowPrompt,
  isSaving,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  sectionAlert,
}) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isShowPrompt);
  const primaryButton = isSaving ? 'Saving...' : primaryButtonName;
  return (
    <>
      <div className="ApplicationReviewActionBar">
        <Button
          size="small"
          theme="secondary"
          disabled={!isSecondaryButtonEnabled}
          onClick={() => onSecondaryButtonClick?.()}
        >
          {secondaryButtonName}
        </Button>
        <Button size="small" disabled={!isPrimaryButtonEnabled} onClick={() => onPrimaryButtonClick?.()}>
          {primaryButton}
        </Button>
        <ModalConfirm
          open={showPrompt}
          header="Unsaved changes"
          confirmText="Yes"
          onConfirm={confirmNavigation}
          onClose={cancelNavigation}
          onCancel={cancelNavigation}
        >
          You have unsaved changes. Are you sure you wish to leave?
        </ModalConfirm>
      </div>
      <div className="ApplicationReviewActionBarBottomMargin">
        {sectionAlert && (
          <div className="SectionAlert" style={{ color: sectionAlert.color }}>
            <Icon name={sectionAlert.icon} className="SectionAlertIcon" />
            {sectionAlert.message}
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicationReviewActionBar;
