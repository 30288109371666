import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { getRandomId } from 'apps/track-details/utils/taskForms';
import { useState } from 'react';

export const DELETE_MULTIPLE_FORMS_ACTION = 'delete';

export const useMultipleForms = (initialData = [], taskId) => {
  const [multipleForms, setMultipleForms] = useState(initialData);
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent();

  const updateMultipleForm = ({ id, action, initialDocId }) => {
    if (id && action === DELETE_MULTIPLE_FORMS_ACTION) {
      const isNewForm = multipleForms.find(({ identifier }) => identifier === id)?.isNewForm;
      updateSpecificTaskById(id, { isDeleted: true, isFormChanged: true, isValidForm: true }, isNewForm);
      return setMultipleForms(multipleForms.filter(({ identifier }) => id !== identifier));
    }
    return setMultipleForms([...multipleForms, { identifier: getRandomId(), taskId, initialDocId, isNewForm: true }]);
  };

  return {
    multipleForms,
    updateMultipleForm,
  };
};

export const getInitialMultipleForms = (dataWithDocuments, taskId, availableDocIds) => {
  if (dataWithDocuments.length) {
    return [...dataWithDocuments.map(item => ({ ...item, isValid: true, taskId }))];
  }
  if (availableDocIds.length) {
    return [{ identifier: getRandomId(), taskId, initialDocId: getFormInitialDocId(availableDocIds), isNewForm: true }];
  }
  return [];
};

export const getFormInitialDocId = availableDocIds => (availableDocIds.length === 1 ? availableDocIds[0] : undefined);
