import React from 'react';
import cx from 'classnames';
import './TaskDetailContentSectionWrapper.scss';

const TaskDetailContentSectionWrapper = ({ heading, children, className }) => {
  return (
    <div className={cx('TaskDetailContentSection', className)}>
      {heading && <h5 className="TaskDetailHeading">{heading}</h5>}
      {children}
    </div>
  );
};

export default TaskDetailContentSectionWrapper;
