import { Button, Loader } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import listIcon from 'assets/icons/list.svg';
import { CREATE_AND_ASSIGN_TODOS } from 'apps/track-details/todos/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useConfigurations from 'hooks/useConfigurations';
import { TODO_NOTE_EXPIRED_DOCUMENT, TODO_NOTE_EXPIRING_DOCUMENT } from '../../data/constants/todoNotes';
import { ApplicationReviewAlert } from './ApplicationReviewAlert';
import { capitalize } from 'lodash';

export const POLICY_KIND_TO_HOMEOWNER_TODO = {
  homeowners: 'UPLOAD_HO_INSURANCE',
  flood: 'UPLOAD_FLOOD_INSURANCE',
  condo: 'UPLOAD_CONDO_MASTER_POLICY',
  other: 'UPLOAD_OTHER_INSURANCE',
};

export const InsuranceExpiringAlert = ({ trackId, policyReview, expiresIn }) => {
  const { id, kind } = policyReview;
  const { homeowner } = useOutletContext();
  const { taskKinds } = useConfigurations();
  const [createTodos, { called, loading, error }] = useMutation(CREATE_AND_ASSIGN_TODOS, {
    onCompleted: () =>
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Successfully assigned to-do',
        description: 'All is well',
      }),
    onError: () =>
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to assign to-do',
        description: 'Please try adding the to-do again',
      }),
  });
  const createTodosData = {
    kind: taskKinds[POLICY_KIND_TO_HOMEOWNER_TODO[kind] ?? POLICY_KIND_TO_HOMEOWNER_TODO.homeowners],
    notes: expiresIn < 0 ? TODO_NOTE_EXPIRED_DOCUMENT : TODO_NOTE_EXPIRING_DOCUMENT,
    policy: id,
  };
  const hasCreatedTodo = called && !error && !loading;
  const expirationMessage =
    expiresIn < 0
      ? `${capitalize(kind)} Insurance policy has expired. Please click “Add this to-do” to request an updated one.`
      : `The expiration date for ${capitalize(
          kind,
        )} Insurance policy falls within the next 30 days. Please click “Add this to-do” to request an updated policy.`;

  return (
    <ApplicationReviewAlert
      isAssigned={hasCreatedTodo}
      icon={<img src={listIcon} className="alertListIcon" alt="List icon" />}
      action={
        loading ? (
          <Loader />
        ) : (
          !hasCreatedTodo && (
            <Button
              icon="icon-plus"
              theme="link"
              onClick={() =>
                createTodos({
                  variables: {
                    trackId,
                    assigneeId: homeowner.identifier,
                    todosInput: [createTodosData],
                    variables: { policy: id },
                  },
                })
              }
            >
              Add this to-do
            </Button>
          )
        )
      }
    >
      {expirationMessage}
    </ApplicationReviewAlert>
  );
};

InsuranceExpiringAlert.propTypes = {
  trackId: PropTypes.string.isRequired,
  policyReview: PropTypes.object.isRequired,
  expiresIn: PropTypes.number.isRequired,
};
