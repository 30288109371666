import InvestmentCalculator from './components/InvestmentCalculator/InvestmentCalculator';
import useCurrentUser from 'hooks/useCurrentUser';
import { NumberInput } from '@hometap/htco-components';
import useInvestmentCalculator from './hooks/useInvestmentCalculator';
import { currency, percentage } from 'utils/numbers';
import PaydownError from './components/PaydownError/PaydownError';
import { INPUT_FIELDS, INPUT_GROUPS } from './utils/calculatorConstants';
import { Helmet } from 'react-helmet-async';
import { getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { GET_SALES_CALCULATOR_MAX_INVESTMENT_DATA } from './mutations';
import { isNil, omitBy } from 'lodash';
import StatusCodeErrorBlock from 'components/StatusCodeErrorBlock/StatusCodeErrorBlock';
import './InvestmentCalculator.scss';
import useTrack from 'hooks/useTrackQuery';

const SalesCalculatorController = () => {
  const { isInInvestmentManagerGroup } = useCurrentUser();
  const { track, refetchTrack } = useTrack();

  const { inputData, updateInputDataByKey, calculatedData, calculationsIsLoading, calculationsError } =
    useInvestmentCalculator(GET_SALES_CALCULATOR_MAX_INVESTMENT_DATA, track?.identifier, {
      appraisedHomeValue: track?.relevantHomeValuation?.value || 0,
      totalDebtOnHome: track?.inquiry?.mortgageBalance || 0,
      feePaydown: 0,
      principalPaydown: 0,
    });

  if (!isInInvestmentManagerGroup) {
    return <StatusCodeErrorBlock errorCode={403} />;
  }

  const renderInput = key => (
    <NumberInput
      type="currency"
      placeholder="$"
      className="InvestmentCalculatorControllerInput"
      key={key}
      value={inputData[key]}
      precision={0}
      onChange={newVal => {
        updateInputDataByKey(key, newVal, track);
      }}
    />
  );

  const handleSendToEstimate = () => {
    const { rundownCreate } = getTrackDetailPageUrls(track?.identifier);
    const paramData = {
      max_investment_size: calculatedData?.investmentAmount,
      home_value: inputData.appraisedHomeValue,
      current_mortgage_balance: inputData.totalDebtOnHome,
      total_paydown: calculatedData?.assumedTotalPaydown || null,
    };
    const params = new URLSearchParams(omitBy(paramData, isNil)).toString();
    window.location = `${rundownCreate}?${params}`;
  };

  return (
    <div>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      <InvestmentCalculator
        track={track}
        loading={calculationsIsLoading}
        calculatedData={calculatedData}
        calculationsError={calculationsError}
        actionLabel="Send to Estimate"
        onFetchTrack={refetchTrack}
        onAction={handleSendToEstimate}
        inputGroups={[
          {
            group: INPUT_GROUPS.PROPERTY,
            rows: [
              {
                label: INPUT_FIELDS.APPROX_HOME_VALUE,
                value: renderInput('appraisedHomeValue'),
                tooltipContent: (
                  <>
                    This value is being pulled from <b>Relevant Home Valuation.</b>
                  </>
                ),
              },
              {
                label: INPUT_FIELDS.DEBT,
                value: renderInput('totalDebtOnHome'),
                tooltipContent: (
                  <>
                    This value is being pulled from <b>Inquiry Mortgage Balance.</b>
                  </>
                ),
              },
              {
                label: INPUT_FIELDS.STARTING_EQUITY,
                value: percentage((1 - inputData.totalDebtOnHome / inputData.appraisedHomeValue) * 100),
              },
            ],
          },
          {
            group: INPUT_GROUPS.PAYOFFS,
            rows: [
              {
                label: INPUT_FIELDS.FEE_PAYDOWN,
                value: renderInput('feePaydown'),
              },
              {
                label: INPUT_FIELDS.PRINCIPAL_PAYDOWN,
                value: renderInput('principalPaydown'),
                additional: <PaydownError calculatedData={calculatedData} includeCents={false} />,
              },
              {
                label: INPUT_FIELDS.TOTAL_PAYDOWN,
                value: currency((inputData.feePaydown || 0) + (inputData.principalPaydown || 0)),
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default SalesCalculatorController;
