import { gql } from '@apollo/client';

export const GET_ADVERSE_EVENT_DATA = gql`
  query AdverseEventData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      adverseEvents {
        identifier
        kind
        resolutionDate
        hasNotBeenResolved
        bankruptcyChapter
        bankruptcyOrder
        isForSubjectProperty
        notes
        personIds
      }
      adverseEventKindOptions {
        label
        value
      }
      adverseEventBankruptcyChapterOptions {
        label
        value
      }
      adverseEventBankruptcyOrderOptions {
        label
        value
      }
      noAdverseEventsToReview
    }
  }
`;
