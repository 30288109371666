export const DSCR_STATUS = {
  AVAILABLE: 'AVAILABLE',
  MISSING: 'MISSING',
  PARTIALLY_CALCULATED: 'PARTIALLY_CALCULATED',
};

export const FLOOD_INSURANCE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  MISSING: 'MISSING',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  UNKNOWN_ZONE: 'UNKNOWN_ZONE',
};

export const HOA_FEE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  MISSING: 'MISSING',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};
