import { TrackQueryProvider } from 'hooks/useTrackQuery';
import { useParams } from 'react-router-dom';
import { GET_INVESTMENT_CALCULATOR_TRACK_DATA, GET_UW_CALCULATOR_TRACK_DATA } from './queries';
import UWCalculatorController from './UWCalculatorController';
import SalesCalculatorController from './SalesCalculatorController';
import { Helmet } from 'react-helmet-async';

export const TRACK_CALCULATORS = {
  sales: 'sales-calculator',
  uw: 'uw-calculator',
};

const InvestmentCalculatorController = ({ type }) => {
  const { trackId } = useParams();

  const query = type === TRACK_CALCULATORS.sales ? GET_INVESTMENT_CALCULATOR_TRACK_DATA : GET_UW_CALCULATOR_TRACK_DATA;
  const controller = type === TRACK_CALCULATORS.sales ? <SalesCalculatorController /> : <UWCalculatorController />;

  return (
    <div>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      <TrackQueryProvider trackId={trackId} query={query}>
        {controller}
      </TrackQueryProvider>
    </div>
  );
};

export default InvestmentCalculatorController;
