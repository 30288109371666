import { gql } from '@apollo/client';

export const GET_BACKGROUND_CHECK_DATA = gql`
  query BackgroundCheck($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      friendlyId
      home {
        residenceType
        identifier
        address {
          street
          unit
          identifier
          city
          state
          zipCode
        }
      }
      fraudGuardData {
        status
        createdAt
        identifier
      }
      documents(filters: { kind: BACKGROUND }) {
        ... on DocumentV1 {
          isPinned
          identifier
          filename
          id
          kind {
            label
            value
          }
        }
        ... on Document {
          identifier
          isPinned
          filename
          id
          kind {
            label
            value
          }
        }
      }
      relevantHomeValuation {
        value
        identifier
      }
      applicants {
        firstName
        middleName
        lastName
        ssn
        phoneNumber
        birthDate
        ficoScore
        isSelfEmployed
        totalAnnualIncome
        isSelfEmployed
        jobCompany
        jobTitle
        jobPhoneNumber
        id
      }
    }
  }
`;
