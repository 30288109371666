import { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { COMPLETE_SENIOR_LIEN_TASK } from 'apps/track-details/tasks/data/mutations';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { TASK_RESOLUTION_KEYS } from 'data/constants/taskStatuses';
import { setJSONInLocalStorage } from 'hooks/useLocalStateForTrack';
import TASK_SPECIFIC_CONTENT_DEFINITIONS from 'apps/track-details/tasks/data/constants/taskSpecificContentDefinitions';
import { useSpecificContent, useUpdateSpecificTaskContent } from '../../../hooks/useSpecificContentQuery';
import { getDocKindElseTaskDefKey } from 'apps/track-details/tasks/trackTasksUtils';
import { getIsTaskFormChanged } from 'apps/track-details/utils/taskForms';
import {
  SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION,
  SENIOR_LIEN_ESCALATE_TASK_DEFINITION,
} from 'data/constants/bpmConstants';
import { useNavigate } from 'react-router-dom';
import { SET_TASK_ASSIGNEE } from 'apps/track-details/tasks/data/mutations/setTaskAssignee';
import { SET_TASK_ASSIGNEE_BY_EMAIL } from 'apps/track-details/tasks/data/mutations/setTaskAssigneeByEmail';

export function useTaskSpecificContractorForms({ trackId, task }) {
  const taskId = task?.identifier;
  const navigate = useNavigate();
  const {
    specificTaskData: { complete: saveFormToDB, initialFormData, updateFormData, formData, isValidForm },
  } = useSpecificContent(taskId);
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { reassignTo } = formData || {};

  const [completeSeniorLienTask, { loading: completing }] = useMutation(COMPLETE_SENIOR_LIEN_TASK);

  const [setTaskAssignee, { loading: isSettingTaskAssignee }] = useMutation(SET_TASK_ASSIGNEE);
  const [setTaskAssigneeByEmail, { loading: isSettingTaskAssigneeByEmail }] = useMutation(SET_TASK_ASSIGNEE_BY_EMAIL);

  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);
  const isEntryTask = docKindElseTaskDefKey === SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION;
  const isEscalateTask = task?.taskDefinitionKey === SENIOR_LIEN_ESCALATE_TASK_DEFINITION;

  const isFormChanged = useMemo(() => {
    // this check to avoid unnecessary prompt for the multiple forms with initialFormData
    return isEntryTask ? getIsTaskFormChanged(initialFormData, formData) : false;
  }, [formData, initialFormData, isEntryTask]);

  const getIsValidTaskForm = useCallback(() => {
    if (!task) {
      return false;
    }

    if (isValidForm !== undefined) {
      return isValidForm;
    }
  }, [task, isValidForm]);

  const closeTask = useCallback(
    async (resolution, additionalOnClickData = {}) => {
      if (!task) {
        return;
      }
      const { identifier: taskId } = task;
      const { fromReassign } = additionalOnClickData;

      if (saveFormToDB) {
        await saveFormToDB({ formData });
      }

      if (fromReassign && formData.reassignTo) {
        const { reassignTo } = formData;
        if (reassignTo.includes('@')) {
          await setTaskAssigneeByEmail({
            variables: {
              taskId: taskId,
              userEmail: reassignTo,
            },
          });
        } else {
          await setTaskAssignee({
            variables: {
              taskId: taskId,
              userId: reassignTo,
            },
          });
        }
        // Early Return, don't complete the task if reassigning
        navigate('/tasks');
        return;
      }

      await completeSeniorLienTask({
        variables: {
          taskId: taskId,
          resolution: resolution,
        },
      });

      setJSONInLocalStorage({
        trackId,
        key: 'latestCompletedTaskId',
        value: taskId,
      });
      showNotification({
        type: TOAST_TYPE.success,
        title:
          resolution === TASK_RESOLUTION_KEYS.ESCALATED
            ? 'Task escalated for Hometap to review'
            : 'Task successfully marked as complete',
      });
      navigate('/tasks');
    },
    [task, navigate, saveFormToDB, completeSeniorLienTask, trackId, formData, setTaskAssignee, setTaskAssigneeByEmail],
  );

  const isValidTaskForm = getIsValidTaskForm();
  const demoData = TASK_SPECIFIC_CONTENT_DEFINITIONS[docKindElseTaskDefKey]?.demoFormData;
  const insertDemoData = useCallback(() => {
    if (demoData) {
      updateFormData(demoData);
    }
  }, [demoData, updateFormData]);
  const updateFormWithDemoData = demoData ? insertDemoData : undefined;

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      closeTask,
      isValidTaskForm,
      saving: completing || isSettingTaskAssignee || isSettingTaskAssigneeByEmail,
      updateFormWithDemoData,
      isFormChanged,
      showEscalate: !isEscalateTask,
      reassignToData: { disabledReassign: !reassignTo, showReassign: !isEntryTask },
    });
  }, [
    closeTask,
    updateSpecificTaskById,
    taskId,
    isValidTaskForm,
    completing,
    isSettingTaskAssignee,
    isSettingTaskAssigneeByEmail,
    updateFormWithDemoData,
    isFormChanged,
    isEscalateTask,
    isEntryTask,
    reassignTo,
  ]);
}
