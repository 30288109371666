import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@hometap/htco-components';
import PropTypes from 'prop-types';

import '../TaskDetails.scss';
import { useTaskSpecificContractorForms } from './useTaskSpecificContractorForms';
import TaskSpecificContentController from '../TaskSpecificContent/TaskSpecificContentController';

/**
 * @typedef {ExtendedTaskData} ExtendedTaskData
 */
/**
 * @typedef TaskDetailMultipleFormsParams
 * @type {object}
 * @property {Task} task The Task being displayed
 * @property {Applicant} applicant The applicant for this task
 * @property {boolean} isPageLoading Is the parent component page loading?
 * @property {object} reviewStatus
 */
/**
 * @param {TaskDetailParams} params
 */
const TaskDetailContractorForms = ({ task, isPageLoading }) => {
  const { trackId, taskId } = useParams();
  useTaskSpecificContractorForms({ trackId, task });

  const ref = useRef();
  useEffect(() => {
    if (ref?.current) {
      ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [taskId]);

  if (isPageLoading) {
    return (
      <div className="TaskDetailLoadingContainer">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <div className="TaskDetailContainer">
      {task !== undefined && (
        <>
          <div className="TaskDetailBody" ref={ref}>
            <h2>{task.name}</h2>
            <TaskSpecificContentController task={task} />
          </div>
        </>
      )}
    </div>
  );
};

export const TaskPropType = PropTypes.shape({
  description: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  taskDefinitionKey: PropTypes.string,
  notes: PropTypes.string,
  link: PropTypes.string,
  displayGroup: PropTypes.string,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      kind: PropTypes.string,
      description: PropTypes.string,
      isCompleted: PropTypes.bool,
    }),
  ),
  mostRecentChange: PropTypes.shape({
    historyUser: PropTypes.string,
    historyDate: PropTypes.string,
    isTaskCondition: PropTypes.bool,
    taskConditionKind: PropTypes.string,
  }),
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
});

TaskDetailContractorForms.propTypes = {
  task: TaskPropType,
  applicant: PropTypes.shape({
    fullName: PropTypes.string,
    birthDate: PropTypes.string,
    governmentId: PropTypes.shape({
      nameAsAppearsOnId: PropTypes.string,
      expirationDate: PropTypes.string,
    }),
  }),
  isPageLoading: PropTypes.bool,
  reviewStatus: PropTypes.object,
  assignee: PropTypes.string,
};

export default TaskDetailContractorForms;
