export const ITEMS_PER_PAGE = 10;
export const CUSTOM_TABLE_STYLES = {
  cells: {
    style: {
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: 14,
    },
  },
};
