import { isArray } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const mapToObject = params => {
  const result = {};
  for (const [key, value] of params.entries()) {
    if (result[key]) {
      if (!isArray(result[key])) {
        result[key] = [result[key]];
      }
      result[key].push(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = mapToObject(searchParams);

  return {
    searchParams,
    searchParamsObject,
    setSearchParams,
  };
};

export default useQueryParams;
