import React from 'react';

import ApplicantFullNameAndType from 'components/ApplicantFullNameAndType/ApplicantFullNameAndType';
import { formatMonthDayYear } from 'utils/dates';
import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import { GET_VALIDITY_CONFIRMATION_DATA } from './getValidityConfirmationData';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';

const ValidityConfirmation = () => {
  const { data = {} } = useSpecificContentQuery(GET_VALIDITY_CONFIRMATION_DATA);
  const { track = {} } = data;
  const { applicants = [], liens = [] } = track;

  return (
    <div className="TaskDetailContent">
      <TaskDetailContentSectionWrapper className="TaskDetailContentSection" heading="Government IDs">
        {applicants.map(applicant => {
          const { governmentId, id } = applicant;
          return (
            <ul className="TaskDetailList" key={id}>
              <ApplicantFullNameAndType applicant={applicant} />
              <li>
                {governmentId
                  ? `${governmentId.nameAsAppearsOnId} expires on ${formatMonthDayYear(governmentId.expirationDate)} `
                  : 'No Government ID data has been set'}
              </li>
            </ul>
          );
        })}
      </TaskDetailContentSectionWrapper>
      <TaskDetailContentSectionWrapper heading="Mortgages/Liens">
        {!!liens.length ? (
          <ul className="TaskDetailList">
            {liens.map((lien, i) => (
              <li key={`lien-${i}`}>
                {lien.description} Statement as of {formatMonthDayYear(lien.asOfDate)}
              </li>
            ))}
          </ul>
        ) : (
          <ul className="TaskDetailList">
            <li>No Mortgage/Lien Data</li>
          </ul>
        )}
      </TaskDetailContentSectionWrapper>
    </div>
  );
};

export default ValidityConfirmation;
