import { gql } from '@apollo/client';

import TaskList from '../../components/TaskList/TaskList';
import { TASK_DETAILS } from '../fragments';

export const GET_INTERNAL_TASK_DATA_FOR_TRACK = gql`
  query GetInternalTaskDataForTrack($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      currentStage {
        stageClassName
      }
      homeowner {
        identifier
      }
      internalTasks {
        ...TaskDetails
      }
      homeownerTasks {
        id
        identifier
        kind
        name
        isOpen

        variables {
          name
          value
        }
      }
      applicants {
        id
        identifier
        birthDate
        fullName
        firstName

        person {
          identifier
        }

        governmentId {
          identifier
        }
      }
      upcomingInternalTasks {
        identifier
        name
      }
      ...TaskListData
    }
  }
  ${TaskList.fragments.TaskListData}
  ${TASK_DETAILS}
`;
