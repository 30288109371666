import { gql } from '@apollo/client';

export const GET_MORE_TRACKS = gql`
  query GetMoreTracks(
    $nextPageLimit: Int
    $prevPageLimit: Int
    $nextPageCursor: String
    $prevPageCursor: String
    $orderBy: String
    $keyword: String
    $appSpecialists: [String]
    $investmentManagers: [String]
    $underwriters: [String]
    $stage: [String]
    $state: [String]
  ) {
    tracks(
      first: $nextPageLimit
      after: $nextPageCursor
      last: $prevPageLimit
      before: $prevPageCursor
      orderBy: $orderBy
      keyword: $keyword
      coordinators: $appSpecialists
      reviewers: $investmentManagers
      underwriters: $underwriters
      currentStage: $stage
      state: $state
    ) {
      totalCount

      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }

      edges {
        cursor

        node {
          openTasks {
            assignee {
              userType
            }
          }
          id
          identifier
          friendlyId
          fullAddress
          fullNameShort

          currentStage {
            label
          }

          reviewer {
            fullNameShort
          }

          homeowner {
            identifier
            lastLogin
          }

          product {
            name
          }
          rolloutFlags
        }
      }
    }
  }
`;

export const GET_FILTER_OPTIONS = gql`
  query GetFilterOptions {
    appSpecialists: reviewers(role: APPLICATION_SPECIALIST) {
      edges {
        node {
          identifier
          fullNameShort
        }
      }
    }
    investmentManagers: reviewers(role: INVESTMENT_MANAGER) {
      edges {
        node {
          identifier
          fullNameShort
        }
      }
    }
    underwriters: reviewers(role: UNDERWRITER) {
      edges {
        node {
          identifier
          fullNameShort
        }
      }
    }
    stages {
      label
      stageClassName
    }
    states(filters: { status: OPERATIONAL }) {
      acronym
    }
  }
`;
