import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import './TaskDrawer.scss';
import { SlideSide } from '@hometap/htco-components';
import { TaskDrawerButton } from './TaskDrawerButton';

const TRANSITION_DURATION = 250;

/**
 * @param {number} closedWidth the minimum width of this component when closed / collapsed
 * @param {boolean} isOpen a flag indicating opened or closed / expanded or collapsed state of the component
 * @param {boolean} isOverlay a flag indicating the stylistic behavior of the component when it is expanded
 * @param {function} onChange a function which will be called whenever the component expands or collapses
 * @param {JSX.Element} children the child nodes which will be rendered inside this component
 */
const TaskDrawer = ({ closedWidth = 32, isOpen = false, isOverlay = false, onChange, children }) => {
  const [shouldOpen, setShouldOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen !== null) {
      setShouldOpen(isOpen);
    }
  }, [isOpen]);

  const handleInternalButtonClick = () => {
    onChange && onChange(!shouldOpen);
    setShouldOpen(!shouldOpen);
  };

  const overlayButtonStyle = {
    transitionDuration: `${TRANSITION_DURATION}ms`,
  };
  if (!shouldOpen) overlayButtonStyle.left = closedWidth - 14;

  return (
    <div className={cx('TaskDrawerContainer', { isOpen: shouldOpen })}>
      {!isOverlay && <TaskDrawerButton className="TaskDrawerControlButton" onClick={handleInternalButtonClick} />}

      <div style={{ minWidth: closedWidth, transition: `padding ${TRANSITION_DURATION} ease` }} className="TaskDrawer">
        <SlideSide
          duration={TRANSITION_DURATION}
          visible={shouldOpen}
          isOverlay={isOverlay}
          isRelativeOverlay={isOverlay}
        >
          {children}
        </SlideSide>
        {isOverlay && (
          <TaskDrawerButton
            style={overlayButtonStyle}
            className={cx('TaskDrawerControlOverlayButton', { animateOpen: shouldOpen })}
            onClick={handleInternalButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default TaskDrawer;
