import { useEffect, useMemo } from 'react';

export const useBroadcastChannel = ({ channelName, onMessage }) => {
  const channel = useMemo(() => new BroadcastChannel(channelName), [channelName]);
  const postMessage = message => channel.postMessage(message);

  useEffect(() => {
    if (onMessage) channel.onmessage = evt => onMessage(evt.data);
  }, [onMessage, channel]);

  return { channel, postMessage };
};
