import React from 'react';
import cx from 'classnames';
import { MuiSelect, MuiTextarea } from '@hometap/htco-components';
import './TaskDetailContentSectionReview.scss';
import TaskDetailContentSectionWrapper from '../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import { TODO_NOTES_OTHER } from '../../../../data/constants/todoForm';
import useCurrentUser from 'hooks/useCurrentUser';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { getTodoNotesOptions } from '../../../../utils/trackDetailsTodo';
import SelectionYesNoSet from '../TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import PropTypes from 'prop-types';

/**
 * @typedef TaskDetailContentSectionReviewParams
 * @type {object}
 * @property {string} label
 * @property {JSX.Element} children
 * @property {TrueFalseValue} isValid
 * @property {boolean} isCompleted
 * @property {function} registerField
 * @property {string} [reason]
 * @property {string} [invalidInformationText]
 * @property {boolean} [isWithDivider]
 * @property {string} [header]
 * @property {string|JSX.Element} [tooltipContent]
 * @property {object} [fieldProps]
 * @property {Option[]} [reasonOptions]
 * @property {string} [contentClassName]
 * @property {string} [contentWrapperClassName]
 * @property {JSX.Element} [visibleContent]
 * @property {boolean} [hideMarginTop]
 */
/**
 * @param {TaskDetailContentSectionReviewParams} params
 * @return JSX.Element
 */
const TaskDetailContentSectionReview = ({
  label,
  children,
  isValid,
  isCompleted,
  reason,
  registerField,
  fieldProps,
  header,
  tooltipContent,
  invalidInformationText = '',
  isWithDivider = false,
  reasonOptions,
  contentWrapperClassName,
  contentClassName,
  visibleContent,
  hideMarginTop = false,
}) => {
  const DEFAULT_REASON_OPTIONS = getTodoNotesOptions({ kind: 'default' });
  const { user } = useCurrentUser();

  return (
    <div className={cx('TaskDetailContent', { contentWrapperClassName, hideMarginTop })}>
      <TaskDetailContentSectionWrapper className={contentClassName}>
        {header && <div className={cx('TaskDetailContentTitle', 'TaskDetailContentMargin')}>{header}</div>}
        {visibleContent}
        <SelectionYesNoSet
          label={label}
          tooltipContent={tooltipContent}
          {...registerField('isValid')}
          {...fieldProps}
        />
        {isValid === 'true' && isWithDivider && <div className="TaskDetailContentIsValidFormItemValid" />}
        <div
          className={cx('TaskDetailContentFormFields', {
            isInvalid: isValid === 'false',
          })}
        >
          {isValid === 'true' && children}
          {isValid === 'false' && (
            <>
              {user?.rolloutFlags?.includes('hide_todo_reasons_dropdown') ? (
                <>
                  <div>Please write a brief note to the Homeowner with specific reasoning for your request.</div>
                  <MuiTextarea
                    theme="outlined"
                    label="Note"
                    width="100%"
                    disabled={isCompleted}
                    {...registerField('reasonNote')}
                  />
                </>
              ) : (
                <>
                  <div>Why is this not a valid document?</div>
                  <div className="ReasonsDropdown">
                    <MuiSelect
                      theme="outlined"
                      label="Reason"
                      options={reasonOptions || DEFAULT_REASON_OPTIONS}
                      width="100%"
                      disabled={isCompleted}
                      {...registerField('reason')}
                    />
                  </div>
                  {reason === TODO_NOTES_OTHER && (
                    <MuiTextarea
                      theme="outlined"
                      label="Note"
                      width="100%"
                      disabled={isCompleted}
                      {...registerField('reasonNote')}
                    />
                  )}
                </>
              )}
              <DetailInformationBlock isInvalid={true} isCompleted={isCompleted} invalidText={invalidInformationText} />
            </>
          )}
        </div>
      </TaskDetailContentSectionWrapper>
    </div>
  );
};

TaskDetailContentSectionReview.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  registerField: PropTypes.func.isRequired,
  reason: PropTypes.string,
  fieldProps: PropTypes.object,
  isValid: PropTypes.oneOf(['true', 'false']),
  isCompleted: PropTypes.bool,
  header: PropTypes.string,
  invalidInformationText: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isWithDivider: PropTypes.bool,
  reasonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  visibleContent: PropTypes.node,
  hideMarginTop: PropTypes.bool,
};

export default TaskDetailContentSectionReview;
