import { SelectionSet, Tooltip } from '@hometap/htco-components';
import cx from 'classnames';
import React from 'react';
import 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet.scss';
import PropTypes from 'prop-types';
import infoIcon from 'assets/icons/info.svg';

export const getSelectionYesNoUnknownInitialValue = value => JSON.stringify(value);
export const getSelectionYesNoUnknownSelectedValue = value => (value ? JSON.parse(value) : undefined);

const InfoIconToolTip = ({ content }) => {
  return (
    <Tooltip className="SelectionYesNoSetInfoTooltip" content={content}>
      <div className="SelectionYesNoSetInfoTooltipImage">
        <img src={infoIcon} className="SelectionYesNoSetInfoIcon" alt="" />
      </div>
    </Tooltip>
  );
};

/**
 * @typedef SelectionYesNoUnknownParams
 * @type {object}
 * @property {string} label
 * @property {string|JSX.Element} [tooltipContent]
 * @property {boolean} [isWithMarginBottom]
 * @property {boolean} [disabled]
 */
/**
 * This component renders a selection field with three possible states: Yes, No, or Unknown.
 * It allows the user to clearly express their stance on a given question.
 *
 * To manage the component's data, two functions are provided:
 * - getSelectionYesNoUnknownInitialValue: Used to set the initial state of the component.
 *   This allows initializing the component with a given value by converting it into a JSON string.
 * - getSelectionYesNoUnknownSelectedValue: Used to extract the value from the component before sending it to the server.
 *   This function converts the JSON string back into a value, which can then be sent to the server.
 *   If the conversion result is undefined, it means the user has not made a selection.
 *
 * @param {SelectionYesNoUnknownParams} params
 * @return JSX.Element
 */
const SelectionYesNoUnknown = ({ label, tooltipContent, isWithMarginBottom = true, disabled, ...props }) => {
  return (
    <>
      <div className="SelectionYesNoSetLabel">
        {label} {tooltipContent && <InfoIconToolTip content={tooltipContent} />}
      </div>
      <SelectionSet
        className={cx('SelectionYesNoSetFormItem', { isWithMarginBottom })}
        options={[
          {
            label: 'Yes',
            value: 'true',
            disabled,
          },
          {
            label: 'No',
            value: 'false',
            disabled,
          },
          {
            label: 'Unknown',
            value: 'null',
            disabled,
          },
        ]}
        {...props}
      />
    </>
  );
};

SelectionYesNoUnknown.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isWithMarginBottom: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SelectionYesNoUnknown;
