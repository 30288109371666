import { formatMMDDYYYY } from 'utils/dates';

export const formatFraudGuardData = track => {
  const { applicants, home } = track;

  const formattedApplicants = applicants.map(applicant => {
    return {
      first_name: applicant.firstName,
      middle_name: applicant.middleName === 'N/A' ? '' : applicant.middleName,
      last_name: applicant.lastName,
      ssn: applicant.ssn,
      phone_number: applicant.phoneNumber,
      birth_date: applicant.birthDate && formatMMDDYYYY(applicant.birthDate),
      fico_score: applicant.ficoScore,
      job_self_employed: applicant.isSelfEmployed,
      job_company: applicant.jobCompany,
      job_title: applicant.jobTitle,
      job_phone_number: applicant.jobPhoneNumber,
      monthly_pay: applicant.totalAnnualIncome / 12,
    };
  });

  return {
    friendly_id: track.friendlyId,
    // loan_amount is an arbitrary value that does not that affect the returned results when
    // submitting the fraudguard form, but is required by the fraudguard form.
    // With the addition of  client.active_inquiry_qualification_data.max_amount
    // being nullable we have decided that a static value of 15000 would be acceptable to use for the
    // prefill data used in prefilling the fraudguard form
    loan_amount: 15000,
    home_address: {
      street: home.address.street,
      unit: home.address.unit,
      city: home.address.city,
      state: home.address.state,
      zip_code: home.address.zipCode,
    },
    residence_type: home.residenceType?.toUpperCase(),
    home_value: track.relevantHomeValuation?.value,
    applicants: formattedApplicants,
  };
};
