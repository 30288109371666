import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './DataList.scss';

const DataList = ({ items = [], header, isRow = true, className }) => {
  return (
    <div className="DataListContainer">
      {header && <h4 className="DataListTitle">{header}</h4>}
      {items.map((item, index) => {
        return (
          <div key={`${item.label}-${index}`} className={cx('DataListItem', className, { isRow })}>
            <span className="DataListItemKey">{item.label}: </span>
            {item.value}
          </div>
        );
      })}
    </div>
  );
};

DataList.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.string,
    }),
  ).isRequired,
  isRow: PropTypes.bool,
};

export default DataList;
