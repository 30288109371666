import { Worker } from '@react-pdf-viewer/core';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

import './PDFWorker.scss';

// Dynamic worker links to fetch the current version of pdfjsLib since the worker needs to be the same version as the library
const UNPKG_WORKER_LINK = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;
const CLOUD_FLARE_LINK = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

/**
 * @description Since we cannot rely on unpkg or cloudflare 100% of the time we need to test which worker link is available and use that one.
 */
const PDFWorker = ({ children }) => {
  const [fetching, setFetching] = useState(true);
  const [workerURL, setWorkerURL] = useState(null);
  const [unableToLoadWorker, setUnableToLoadWorker] = useState(false);

  /**
   * @description Test if a worker can be fetched from the given url utilizing axios
   * @param {String} workerURL
   * @returns {Boolean}
   */
  const canFetchWorker = async workerURL => {
    const response = await axios.get(workerURL);
    if (response.status === 200) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    /**
     * @description Async function to determine which worker to use and set state based on which workers are available or if both are down.
     * @returns {void}
     */
    const determineWorkingWorker = async () => {
      setFetching(true);

      // start by fetching from unpkg
      const canFetchUnpkgWorker = await canFetchWorker(UNPKG_WORKER_LINK);
      if (canFetchUnpkgWorker) {
        setWorkerURL(UNPKG_WORKER_LINK);
        setFetching(false);
        return;
      }

      // if unpkg is down, try to fetch from cloud flare
      const canFetchCloudFlare = await canFetchWorker(CLOUD_FLARE_LINK);
      if (canFetchCloudFlare) {
        setWorkerURL(CLOUD_FLARE_LINK);
        setFetching(false);
        return;
      }

      // if both are down, set unable to load worker
      setFetching(false);
      setUnableToLoadWorker(true);
    };

    determineWorkingWorker();
  }, []);

  // return if cloudflare and unpkg are down
  if (unableToLoadWorker) {
    return (
      <div className="PDFWorkerUnableToLoadContainer">
        <h2>Unable to load PDF worker</h2>
        <p className="PDFWorkerUnableToLoadSubtitle">Try refreshing the page to refetch the document</p>
      </div>
    );
  }

  // if fetching workers or no worker url, return null
  if (fetching || !workerURL) {
    return null;
  }

  return <Worker workerUrl={workerURL}>{children}</Worker>;
};

export default PDFWorker;
