export const INPUT_GROUPS = {
  PROPERTY: 'Property Information',
  RISK_BAND: 'Risk Band Information',
  PAYOFFS: 'Payoffs & Paydowns',
  WITHHOLDINGS: 'Withholdings',
  RENTAL_ELIGIBILITY: 'Rental Property Eligibility',
};

export const INPUT_FIELDS = {
  // Property
  HOME_VALUE: 'Appraised Home Value', // UW Calc
  APPROX_HOME_VALUE: 'Approximate Home Value', // Sales Calc
  DEBT: 'Total Debt on Home',
  STARTING_EQUITY: 'Starting Equity',

  // Risk Band
  IWRB: 'Income-weighted Risk Band',
  RB6: 'Risk Band 6 Criteria',

  // Payoffs/Paydowns
  FEE_PAYDOWN: ['Fee Paydown (', <i key="feePaydown">Does not impact equity</i>, ')'],
  PRINCIPAL_PAYDOWN: ['Principal Paydown (', <i key="principalPaydown">Impacts equity</i>, ')'],
  TOTAL_PAYDOWN: 'Payoffs/Paydowns Total',

  // Withholdings
  DELINQUENT_TAXES: ['Highest Single Year Delinquent Taxes (', <i key="delinquentTaxes">if eligible</i>, ')'],
  FORBEARANCE_PAYMENT: 'Forbearance: Monthly Mortgage Payment',
  FORBEARANCE_LENGTH: 'Forbearance Length',
  WITHHOLDING_TOTAL: 'Witholding Total',

  // Rental Eligibility
  COMPOSITE_FICO: 'Composite FICO',
  DEBT_SERVICE_COVERAGE_RATIO: 'Debt Service Coverage Ratio',
  LIEN_POSITION: 'Lien Position',
};
