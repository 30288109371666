import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Loader,
  MuiTextInput,
  MuiTextarea,
  useEscape,
  useForm,
  useOutsideClick,
} from '@hometap/htco-components';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import { showNotification } from 'utils/toasts';
import { REQUEST_REGGORA_ORDER_REVISION } from '../../../ApplicationReview/sections/HomeValuationsController/reggoraRequests';

import './common/SliderForm.scss';
import SliderFormContainer from './common/SliderFormContainer';
import SliderFormTitle from './common/SliderFormTitle';
import { Divider } from '@mui/material';
import SliderFormBody from './common/SliderFormBody';
import SliderFormSection from './common/SliderFormSection';
import SliderFormField from './common/SliderFormField';
import SliderFormFooter from './common/SliderFormFooter';

/**
 * Slider form for ordering a new appraisal or a follow-up appraisal.
 * @param {Object} props - Component properties.
 * @param {?string} props.orderId - The Reggora order ID.
 * @param {boolean} props.isOpen - Whether or not the slider is open.
 * @param {Function} props.setIsOpen - Function to set whether or not the slider is open.
 * @param {Function} props.refetch - Function to refetch the data.
 * @returns {JSX.Element} The slider form to order new appraisals or follow-up orders.
 *
 */
const RevisionRequestSlider = ({ orderId, trackId, isOpen, setIsOpen, refetch }) => {
  const [errorBlockMessage, setErrorBlockMessage] = useState();

  const [requestRevision, { loading: requestRevisionLoading }] = useMutation(REQUEST_REGGORA_ORDER_REVISION);

  const { registerField, updateFormData, isFormValid, formData } = useForm({
    title: '',
    revisionReason: '',
  });

  const handleCloseSlider = () => {
    updateFormData({
      title: '',
      revisionReason: '',
    });

    setIsOpen(false);
    setErrorBlockMessage(null);
  };

  useEscape(true, () => handleCloseSlider());

  const handleRevisionRequest = async () => {
    const queryVariables = {
      orderId: orderId,
      trackId: trackId,
      title: formData?.title,
      revisionReason: formData?.revisionReason,
    };

    try {
      await requestRevision({ variables: queryVariables });
      showNotification({
        type: 'success',
        title: 'Revision Request Successfully Submitted',
      });
      await refetch();
      handleCloseSlider();
    } catch (error) {
      setErrorBlockMessage(
        `The revision request could not be created due to "${error?.message || 'an unknown error'}".`,
      );
    }
  };

  /**
   * Allows the slider to be closed when clicking outside of the slider.
   * @param {MouseEvent} event - A click event.
   */
  const handleBackdropClick = event => {
    const cssClass = event.target.className;
    if (
      cssClass.includes('htco-MenuDropdownItem') ||
      cssClass.includes('AppraisalsNewAppraisalButton') ||
      cssClass.includes('SliderFormSubmit')
    ) {
      return;
    }

    handleCloseSlider();
  };
  const ref = useRef(null);
  useOutsideClick(ref.current, handleBackdropClick);

  return (
    <SliderFormContainer ref={ref} isVisible={isOpen}>
      <SliderFormTitle title="Revision request" onClose={handleCloseSlider} closeDisabled={requestRevisionLoading} />
      <Divider />
      <SliderFormBody>
        <SliderFormSection>
          <SliderFormField
            label={'Title'}
            field={
              <MuiTextInput
                required
                theme="outlined"
                {...registerField('title')}
                placeholder="Title"
                disabled={requestRevisionLoading}
              />
            }
            className="SliderFormTextInputField"
          />
          <SliderFormField
            label={'Request'}
            field={
              <MuiTextarea
                required
                theme="outlined"
                rows={5}
                {...registerField('revisionReason')}
                disabled={requestRevisionLoading}
              />
            }
            className="SliderFormTextareaField"
          />
        </SliderFormSection>
        {errorBlockMessage && (
          <ErrorBlock
            icon="list"
            error={
              <>
                {errorBlockMessage} Please try again or{' '}
                <a href="https://hometap.slack.com/archives/CBN5REDQ9">reach out</a> to report the issue.
              </>
            }
          />
        )}
      </SliderFormBody>
      <Divider />
      <SliderFormFooter className="justify-end">
        <div className="flex items-center">
          <Button onClick={handleCloseSlider} theme="secondary" size={'small'}>
            Cancel
          </Button>
          {requestRevisionLoading ? (
            <div className="ml-[16px] flex h-[40px] w-[148px] items-center justify-center rounded-lg bg-neutral-dark-30">
              <Loader type="dot-pulse" theme="inverse" />
            </div>
          ) : (
            <Button
              className="SliderFormSubmit"
              size={'small'}
              onClick={() => handleRevisionRequest()}
              disabled={!isFormValid || requestRevisionLoading || !formData?.title || !formData?.revisionReason}
            >
              Create request
            </Button>
          )}
        </div>
      </SliderFormFooter>
    </SliderFormContainer>
  );
};

export default RevisionRequestSlider;
