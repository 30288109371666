import React, { useMemo } from 'react';
import { CurrencyInput, useForm } from '@hometap/htco-components';
import { isEqual } from 'lodash';
import numeral from 'numeral';
import { useMutation } from '@apollo/client';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { PROPERTY_TAX_UPSERT_MUTATION } from 'apps/track-details/tasks/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { getNumberValue } from 'utils/validators';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { convertNumericStringsToNumbers } from 'apps/track-details/utils/taskForms';
import './PropertyTaxController.scss';

const AMOUNT_FORMAT = '00.00';

export const PropertyTaxController = ({ track, editDisabled, refetch }) => {
  const [upsertPropertyTax, { loading }] = useMutation(PROPERTY_TAX_UPSERT_MUTATION);
  const readOnly = loading || editDisabled;
  const { propertyTaxReview } = track;
  const initialFormData = useMemo(
    () => ({
      totalAnnualTaxAmount: propertyTaxReview
        ? numeral(propertyTaxReview.totalAnnualTaxAmount).format(AMOUNT_FORMAT)
        : '',
    }),
    [propertyTaxReview],
  );
  const { registerField, isFormValid, updateFormData, formData } = useForm(initialFormData);
  const isFormChanged = !isEqual(
    convertNumericStringsToNumbers(initialFormData),
    convertNumericStringsToNumbers(formData),
  );
  const { identifier: trackId } = track;
  const { historyPropertyTaxUrl } = getApplicationReviewPageUrls(trackId);

  return (
    <ApplicationReviewSection
      anchorId="property-tax-section"
      sectionTitle="Property tax"
      historyUrl={historyPropertyTaxUrl}
      isWithActionBar={true}
      actionBarProps={{
        isPrimaryButtonEnabled: isFormChanged && isFormValid && !loading,
        isSecondaryButtonEnabled: isFormChanged && !loading,
        isShowPrompt: isFormChanged,
        primaryButtonName: loading ? 'Saving...' : 'Publish',
        onPrimaryButtonClick: async () => {
          const { identifier: trackId } = track;
          const { totalAnnualTaxAmount } = formData;
          const variables = {
            trackId,
            totalAnnualTaxAmount: getNumberValue(totalAnnualTaxAmount),
          };

          try {
            await upsertPropertyTax({ variables });
            showNotification({
              type: TOAST_TYPE.success,
              title: 'Success',
              description: `Changes to "Property Tax" successfully saved.`,
            });
          } catch (error) {
            showNotification({
              type: TOAST_TYPE.error,
              title: 'Failed to save changes',
              description: error.networkError?.result?.errors[0]?.message
                ? error.networkError.result.errors[0].message
                : 'Could not save changes. Please try again.',
            });
          }
          refetch();
        },
        onSecondaryButtonClick: () => {
          updateFormData(initialFormData);
        },
      }}
      onEdit={null}
    >
      <CurrencyInput
        label="Annual property tax"
        padFractionalZeros={true}
        disabled={readOnly}
        required
        {...registerField('totalAnnualTaxAmount')}
      />
    </ApplicationReviewSection>
  );
};

export default PropertyTaxController;
