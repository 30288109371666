import { gql } from '@apollo/client';
import { TASK_DETAILS, UPCOMING_TASK_DETAILS } from 'apps/track-details/tasks/data/fragments';

export const GET_TITLE_SEARCH_DATA = gql`
  query TitleSearch($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      internalTasks {
        ...TaskDetails
      }
      upcomingInternalTasks {
        ...UpcomingTaskDetails
      }
      id
      state
      defaultTitlePartner
    }
  }
  ${TASK_DETAILS}
  ${UPCOMING_TASK_DETAILS}
`;
