import { useEffect, useState } from 'react';

export const useScrollToElement = elementId => {
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    // scroll to the appropriate elementId
    if (shouldScroll && elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setShouldScroll(false);
    }
  }, [shouldScroll, elementId]);

  return {
    setShouldScroll,
  };
};
