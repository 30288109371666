import React, { useEffect, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Route, Routes, useOutletContext, useParams } from 'react-router-dom';
import { useForm } from '@hometap/htco-components';

import HometapError from 'components/HometapError';
import Toast from 'components/Toast/Toast';

import TodosAssignment from './components/TodosAssignment';
import SectionLoadingWrapper from '../SectionLoadingWrapper';
import TodosSelectionList from './components/TodosSelectionList';
import TodosTable from './components/TodosTable';
import { CREATE_AND_ASSIGN_TODOS } from './data/mutations';
import { formatSingularOrPluralTodoCopy } from './todoUtils';

import './Todos.scss';
import { TODO_FORM_DATA_NOTES_DROPDOWN_PREFIX, TODO_FORM_DATA_NOTES_TEXTAREA_PREFIX } from './data/constants/todoForm';
import { TODO_NOTES_OTHER } from 'apps/track-details/data/constants/todoForm';
import { GET_TRACK_TODO_DATA } from './queries';
import useHomeownerTodoSelection from './hooks/useHomeownerTodoSelection';
import useConfigurations from 'hooks/useConfigurations';

const SUCCESS_TOAST_DURATION = 4;

const TrackTodosController = () => {
  const { trackId } = useParams();

  // All notes except notes input through a textarea on the assignment screen are optional. The
  // textarea is conditionally rendered based on if "Other" is selected in the dropdown on the
  // assignment screen. Any errors returned from the internal state of the useForm hook are not
  // cleaned up if a required field goes from "required" to optional (conditionally toggling the
  // "required" prop and the textarea being removed from the DOM) which causes an issue where hidden
  // errors occur do to the field being removed from the DOM without resolving its errors yet.
  //
  // Also, because of the potential for errors needing to be rendered on multiple fields we
  // attempted to use "setErrors()" (exposed by useForm) and discovered that it is not able to
  // update error state on many fields at once.
  //
  // We had to opt to use custom error handling and move away from the useForm hook to clean up any
  // errors set when the textarea field is removed from the DOM and have the ability to raise errors
  // on potentially many fields

  const [assignmentFormErrors, setAssignmentFormErrors] = useState({});
  const useHomeownerTodoSelectionReturn = useHomeownerTodoSelection(trackId);
  const { selectedTodos, updateTodo } = useHomeownerTodoSelectionReturn;
  const { homeownerFullName, homeowner } = useOutletContext();
  const { handleSubmit, registerField, formData, handleFieldChange, updateFormData } = useForm();
  const { taskKinds } = useConfigurations();

  const { loading, error, data, refetch } = useQuery(GET_TRACK_TODO_DATA, {
    variables: { trackId },
    // enable loading state when refetching for newly created todos
    notifyOnNetworkStatusChange: true,
  });
  /** @type {{ track?: Track }} */
  const { track } = data || {};

  const [createTodos, { reset, ...createTodosAsyncState }] = useMutation(CREATE_AND_ASSIGN_TODOS);
  const resetCreateTodosMutation = useCallback(() => reset(), [reset]);

  const hasCreatedNewTodos =
    createTodosAsyncState.called && !createTodosAsyncState.loading && !createTodosAsyncState.error;
  const newlyCreatedTodos = createTodosAsyncState.data?.createHomeownerTasks.tasks;

  useEffect(() => {
    // refetch homeowner todos and reset the create todos mutation only if todos have been
    // resolved as completed or new todos have been created
    if (hasCreatedNewTodos) {
      refetch();

      // defer resetting createTodosAsyncState data for the length of the
      // toast animation because rending the toast relies on the data from createTodosAsyncState
      if (hasCreatedNewTodos) {
        setTimeout(() => resetCreateTodosMutation(), SUCCESS_TOAST_DURATION * 1000);
      }
    }
  }, [newlyCreatedTodos, refetch, resetCreateTodosMutation, hasCreatedNewTodos]);

  const handleNoteChange = (value, name, error, todo) => {
    const { kind, applicant } = todo;
    handleFieldChange(value, name, error);
    // if the form changes and we have an error on the particular field we can assume that the user
    // is either changing the selection from "Other" to another option (which would make this this
    // field now optional) or we either written into the textarea and resolved the "required" error
    // so we cleanup the existing errors for the particular field.
    const personId = applicant?.person?.identifier;
    const todoError = assignmentFormErrors[kind + personId];
    if (todoError) {
      const newErrors = { ...assignmentFormErrors };
      delete newErrors[kind + personId];
      setAssignmentFormErrors(newErrors);
    }
    updateTodo(todo, { notes: value });
  };

  /**
   *
   * @param {*} _
   * @param {CreateHomeownerTaskInput[]} [overrideTodos]
   * @returns
   */
  const handleCreateTodosSubmit = (_, overrideTodos) => {
    /** @type {CreateHomeownerTaskInput[]} */
    const createTodosData = overrideTodos
      ? overrideTodos
      : selectedTodos.map(({ kind, notes, applicant }) => ({ kind, notes, person: applicant?.person?.identifier }));

    const todoOtherValidationError = createTodosData.filter(({ kind, person }) => {
      const dropdownFieldValue = formData[TODO_FORM_DATA_NOTES_DROPDOWN_PREFIX + kind + person];
      const textAreaFieldValue = formData[TODO_FORM_DATA_NOTES_TEXTAREA_PREFIX + kind + person];
      const otherIsSelectedAndEmpty =
        (dropdownFieldValue === TODO_NOTES_OTHER || kind === taskKinds.OTHER) && !textAreaFieldValue;

      return otherIsSelectedAndEmpty;
    });

    if (todoOtherValidationError.length) {
      const updatedErrors = { ...assignmentFormErrors };

      for (const { kind, person } of todoOtherValidationError) {
        updatedErrors[kind + person] = { message: 'This is a required field', show: true };
      }
      setAssignmentFormErrors(updatedErrors);
      return;
    }
    createTodos({ variables: { trackId, assigneeId: homeowner.identifier, todosInput: createTodosData } });
    updateFormData({ [TODO_FORM_DATA_NOTES_TEXTAREA_PREFIX + taskKinds.OTHER]: '' });
  };

  return (
    <SectionLoadingWrapper
      loading={loading}
      error={error}
      data={data}
      errorProps={{
        buttonLink: '/tracks',
        errorHeading: "We're having trouble fetching this homeowner's to-dos. Please try refreshing",
        buttonLabel: 'Back to tracks list',
      }}
    >
      {hasCreatedNewTodos && (
        <Toast
          message={`${formatSingularOrPluralTodoCopy(newlyCreatedTodos)} assigned to ${homeownerFullName}`}
          duration={SUCCESS_TOAST_DURATION}
        />
      )}
      <div className="Todos">
        <Routes>
          <Route
            index
            element={
              <TodosTable
                {...{
                  track,
                  handleCreateTodosSubmit,
                }}
                {...useHomeownerTodoSelectionReturn}
              />
            }
          />
          <Route
            path="select-todos"
            element={
              <TodosSelectionList
                {...{
                  track,
                  homeownerFullName,
                }}
                {...useHomeownerTodoSelectionReturn}
              />
            }
          />
          <Route
            path="create"
            element={
              <TodosAssignment
                {...{
                  homeownerFullName,
                  createTodosAsyncState,
                }}
                {...useHomeownerTodoSelectionReturn}
                formProps={{
                  formData,
                  errors: assignmentFormErrors,
                  registerField,
                  onNoteChange: handleNoteChange,
                  onSubmit: handleSubmit(handleCreateTodosSubmit),
                }}
              />
            }
          />
          <Route path="*" element={<HometapError />} />
        </Routes>
      </div>
    </SectionLoadingWrapper>
  );
};

export default TrackTodosController;
