import { Button } from '@hometap/htco-components';
import React from 'react';

const TodosRemoveTodoButton = ({ removeTodo }) => {
  return (
    <Button
      theme="secondary"
      label="Remove"
      size="small"
      onClick={removeTodo}
      className="TodosSelectionListTodoButton isRemovalButton"
    />
  );
};

export default TodosRemoveTodoButton;
