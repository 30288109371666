import { gql } from '@apollo/client';

export const GET_BIRCHWOOD_DATA = gql`
  query Birchwood($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      identifier
      friendlyId
      home {
        identifier
        address {
          identifier
          street
          unit
          city
          state
          zipCode
          fullAddress
        }
      }
      applicants {
        identifier
        firstName
        lastName
        ssn
        birthDate
        governmentId {
          identifier
          nameAsAppearsOnId
          birthDate
        }
      }
    }
  }
`;
