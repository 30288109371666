import { Icon } from '@hometap/htco-components';
import './ErrorBlock.scss';

const ErrorBlock = ({ error, icon }) => {
  return (
    <div className="ErrorBlockContainer">
      <Icon name={icon || 'alert'} className="ErrorBlockIcon" size={'lg'} />
      <div className="ErrorBlockContent">{error}</div>
    </div>
  );
};

export default ErrorBlock;
