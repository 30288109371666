import { TaskPropType } from '../../TaskDetail';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { CurrencyInput, MuiSelect, MuiTextInput, useForm, DatePicker, MuiTextarea } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  DISQUALIFY_NEW_PAST_DUE_LIEN,
  DISQUALIFY_EXISTING_PAST_DUE_LIEN,
  UPDATE_MORTGAGE_FROM_TASK,
  CREATE_MORTGAGE_FROM_TASK,
  DELETE_LIEN,
} from 'apps/track-details/tasks/data/mutations';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { getTodoNotesOptions } from 'apps/track-details/utils/trackDetailsTodo';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import SelectionYesNoSet from '../SelectionYesNoSet/SelectionYesNoSet';
import './MortgageLienReview.scss';
import { getHomeownerTodoReason } from 'apps/track-details/todos/todoUtils';
import {
  MORTGAGE_LIEN_TYPE,
  convertNumericStringsToNumbers,
  getSelectionYesNoInitialValue,
} from 'apps/track-details/utils/taskForms';
import { validateNonEmptyString, validateDateNotMoreThanNDaysInPast } from 'utils/validators';
import isEmpty from 'lodash/isEmpty';
import { capitalizeFirstLetter } from 'apps/track-details/tasks/trackTasksUtils';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import isEqual from 'lodash/isEqual';
import SelectionYesNoUnknown, {
  getSelectionYesNoUnknownInitialValue,
  getSelectionYesNoUnknownSelectedValue,
} from 'apps/track-details/ApplicationReview/sections/LiensAndPaydownsController/components/SelectionYesNoUnknown';
import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal';
import { useValidationLienBalance } from '../../../../../ApplicationReview/sections/LiensAndPaydownsController/hooks/useValidationLienBalance';

const getTooltipContent = lienType => (
  <div>
    A valid {capitalizeFirstLetter(lienType)} Statement means the:
    <br />
    1. Name on the statement matches at least one of the names of our applicants
    <br />
    2. Due date is dated within the current month in which you are reviewing the file
    <br />
    3. Address on the statement matches the address on the application
    <br />
    4. The statement is current
    <br />
  </div>
);

/**
 * @typedef {object} MortgageLienFormData
 * @type {object}
 * @property {Date} asOfDate
 * @property {number} currentBalance
 * @property {string[]} docIdsToLink
 * @property {number} escrowAmount
 * @property {TrueFalseValue} isForbearance
 * @property {string} holder
 * @property {number} interestAmount
 * @property {TrueFalseValue} isMoreThanOneMonthPastDue
 * @property {string} mortgageAccountNumber
 * @property {string} mortgageInvestorKind
 * @property {number} principalAmount
 * @property {TrueFalseValue} [isValid]
 * @property {string} [notes]
 * @property {number} [pastDueAmount]
 * @property {number} [pastDueFees]
 */
/**
 * @typedef MortgageLienReviewParams
 * @type {object}
 * @property {Task} task
 */
/**
 * @param {MortgageLienReviewParams} params
 * @return JSX.Element
 */
const MortgageLienReview = ({
  task,
  data: lien,
  availableDocOptions,
  updateAvailableDocOptions,
  canDelete,
  onDelete,
}) => {
  const { trackId } = useParams();
  const { taskKinds } = useConfigurations();
  const { user } = useCurrentUser();

  const { formData, registerField, isFormChanged, handleFieldChange, setErrors } = useLienForm(
    user,
    lien,
    task,
    trackId,
  );

  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  // editing lien form is enabled if task is not completed, or it's a new form, or documents are changed
  const isEditableForm = lien?.isNewForm || isFormChanged;

  const isDisabledForm = isCompleted || !isEditableForm;

  const {
    isValid,
    reason,
    currentBalance,
    isMoreThanOneMonthPastDue,
    hasAModification,
    isForbearance,
    escrowAmount,
    interestAmount,
    principalAmount,
  } = formData;

  const validationLienBalance = useValidationLienBalance({
    currentBalance,
    escrowAmount,
    interestAmount,
    principalAmount,
    setErrors,
  });

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.MORTGAGE_STATEMENT_REVIEW_V1,
    taskKinds,
  });

  const isForbearanceCheck = isForbearance === 'true';

  return (
    <FormWrapperWithDeleteConfirmModal
      header="Delete lien"
      title="Lien form"
      type={MORTGAGE_LIEN_TYPE}
      canDelete={canDelete}
      onDelete={() => {
        onDelete(formData?.docIdsToLink);
      }}
    >
      <div>
        <MuiSelect
          label="Document used to fill out this form"
          classNamePrefix="MortgageLienSelect"
          options={availableDocOptions}
          theme="outlined"
          width="100%"
          required={true}
          showRequiredAsterisk={false}
          helperText="Select statement document used to fill out this form"
          {...registerField('docIdsToLink')}
          onChange={(value, name, err) => {
            updateAvailableDocOptions(value);
            handleFieldChange(value, name, err);
          }}
        />
      </div>
      <TaskDetailContentSectionReview
        label="Is this a valid mortgage/lien statement? "
        isValid={isValid}
        isCompleted={isCompleted}
        reason={reason}
        registerField={registerField}
        tooltipContent={getTooltipContent(MORTGAGE_LIEN_TYPE)}
        invalidInformationText={`Due to an invalid document, a request for an updated ${MORTGAGE_LIEN_TYPE.toLowerCase()} statement will be sent when this task is marked as complete.`}
        reasonOptions={REASON_OPTIONS}
        contentClassName="MortgageLienReviewContent"
        contentWrapperClassName="MortgageLienReviewContent"
        fieldProps={{
          isWithMarginBottom: typeof isValid === 'string',
          disabled: isDisabledForm,
        }}
        hideMarginTop
      >
        <div>
          <MuiTextInput
            label="Servicer"
            theme="outlined"
            width="100%"
            disabled={isDisabledForm}
            required={true}
            showRequiredAsterisk={false}
            validator={validateNonEmptyString}
            {...registerField('holder')}
          />
        </div>

        <div>
          <MuiTextInput
            label="Mortgage account number"
            theme="outlined"
            width="100%"
            disabled={isDisabledForm}
            required={true}
            showRequiredAsterisk={false}
            validator={validateNonEmptyString}
            {...registerField('mortgageAccountNumber')}
          />
        </div>

        <div className="TwoItemFormRow">
          <div className="TwoItemFormRowItem">
            <CurrencyInput
              label="Current principal balance"
              padFractionalZeros={true}
              required={true}
              showRequiredAsterisk={false}
              disabled={isDisabledForm}
              validator={value =>
                validationLienBalance.currentBalance({
                  currentBalance: value,
                  principalAmount,
                  escrowAmount,
                  interestAmount,
                })
              }
              {...registerField('currentBalance')}
            />
          </div>
          <div className="TwoItemFormRowItem">
            <DatePicker
              label="As of date"
              theme="outlined"
              required={true}
              showRequiredAsterisk={false}
              disabled={isDisabledForm}
              className="MortgageLienDatePicker"
              showErrorIcon={true}
              {...registerField('asOfDate')}
              showWarningIcon={true}
              warning={validateDateNotMoreThanNDaysInPast(formData.asOfDate)}
            />
          </div>
        </div>

        <div>
          <CurrencyInput
            label="Monthly principal amt."
            padFractionalZeros={true}
            disabled={isDisabledForm}
            required={true}
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.principalAmount({ principalAmount: value, currentBalance })}
            {...registerField('principalAmount')}
          />
        </div>

        <div className="TwoItemFormRow">
          <div className="TwoItemFormRowItem">
            <CurrencyInput
              label="Monthly interest amt."
              padFractionalZeros={true}
              disabled={isDisabledForm}
              required={true}
              showRequiredAsterisk={false}
              validator={value => validationLienBalance.interestAmount({ interestAmount: value, currentBalance })}
              {...registerField('interestAmount')}
            />
          </div>

          <div className="TwoItemFormRowItem">
            <CurrencyInput
              label="Escrow amt."
              padFractionalZeros={true}
              disabled={isDisabledForm}
              required={true}
              showRequiredAsterisk={false}
              validator={value => validationLienBalance.escrowAmount({ escrowAmount: value, currentBalance })}
              {...registerField('escrowAmount')}
            />
          </div>
        </div>

        <div className="FormRow">
          <SelectionYesNoUnknown
            label="Is there any indication of a modification?"
            disabled={isDisabledForm}
            isWithMarginBottom={false}
            {...registerField('hasAModification')}
          />
        </div>
        {hasAModification === 'true' && (
          <div className="FormRow">
            <DetailInformationBlock
              type="warning"
              className="MortgageLienBlockText"
              isWithIcon={false}
              text="Please manually create a Homeowner to-do requesting Loan Modification documents."
            />
          </div>
        )}

        <div className="FormRow">
          <SelectionYesNoSet
            label="Is the account more than one month past due?"
            disabled={isDisabledForm}
            isWithMarginBottom={false}
            {...registerField('isMoreThanOneMonthPastDue')}
          />
        </div>

        <div className="TwoItemFormRow">
          <div className="TwoItemFormRowItem">
            <CurrencyInput
              label="Past due amount (optional)"
              disabled={isDisabledForm}
              padFractionalZeros={true}
              {...registerField('pastDueAmount')}
            />
          </div>
          <div className="TwoItemFormRowItem">
            <CurrencyInput
              label="Past due fees (optional)"
              padFractionalZeros={true}
              disabled={isDisabledForm}
              {...registerField('pastDueFees')}
            />
          </div>
        </div>
        <div className="FormRow">
          <SelectionYesNoSet
            label="Is there any active forbearance?"
            required={true}
            showRequiredAsterisk={false}
            disabled={isDisabledForm}
            isWithMarginBottom={isMoreThanOneMonthPastDue === 'true'}
            {...registerField('isForbearance')}
          />
          {isMoreThanOneMonthPastDue === 'true' && (
            <DetailInformationBlock
              type={isForbearanceCheck ? 'warning' : 'alert'}
              isWithIcon={!isForbearanceCheck}
              text={
                isForbearanceCheck
                  ? 'Please manually create a Homeowner to-do requesting forbearance documentation.'
                  : "Doesn't qualify based on being more than one month past due, and no active forbearance. Please coordinate with your IM."
              }
            />
          )}
        </div>
        <div className="FormRow">
          <MuiTextarea
            label="Notes (optional)"
            theme="outlined"
            width="100%"
            disabled={isDisabledForm}
            {...registerField('notes')}
          />
        </div>
      </TaskDetailContentSectionReview>
    </FormWrapperWithDeleteConfirmModal>
  );
};

MortgageLienReview.propTypes = {
  task: TaskPropType,
};

export default MortgageLienReview;

/**
 * @param {ExtendedTaskData} [extendedTaskData]
 * @returns {MortgageLienFormData}
 */
function getMortgageLienInitialFormData(lien = {}) {
  const {
    asOfDate,
    currentBalance,
    escrowAmount,
    holder,
    interestAmount,
    isForbearance,
    isMoreThanOneMonthPastDue,
    mortgageAccountNumber,
    pastDueAmount,
    pastDueFees,
    principalAmount,
    isValid,
    hasAModification,
    notes,
  } = lien;

  const getAmountInitialValue = value => (value === '0.00' ? undefined : value);

  const initialFormData = {
    isValid: getSelectionYesNoInitialValue(isValid),
    isMoreThanOneMonthPastDue: getSelectionYesNoInitialValue(isMoreThanOneMonthPastDue),
    holder,
    mortgageAccountNumber,
    asOfDate,
    currentBalance: getAmountInitialValue(currentBalance),
    principalAmount,
    interestAmount,
    escrowAmount,
    pastDueAmount: getAmountInitialValue(pastDueAmount),
    pastDueFees: getAmountInitialValue(pastDueFees),
    isForbearance: getSelectionYesNoInitialValue(isForbearance),
    docIdsToLink: lien?.document?.identifier || lien?.initialDocId,
    hasAModification: getSelectionYesNoUnknownInitialValue(hasAModification),
    notes,
  };

  return initialFormData;
}

export function useLienForm(user, lien, task, trackId) {
  const lienId = lien?.identifier;
  const taskId = task.identifier;
  const { registerField, updateFormData, formData = {}, errors, handleFieldChange, setErrors } = useForm({});

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent();

  const canUpdateTaskData = !!lien;

  const { createOrUpdateMortgageLienTask, isLoadingMortgageLienTask, initialFormData, deleteForm } =
    useMortgageLienTaskForm(trackId, lien);

  const isValidForm = useMemo(() => {
    return getIsValidMortgageLienTaskForm(user, formData, errors);
  }, [user, formData, errors]);

  const isFormChanged = useMemo(() => {
    return !isEqual(convertNumericStringsToNumbers(initialFormData), convertNumericStringsToNumbers(formData));
  }, [formData, initialFormData]);

  const { isValid, isMoreThanOneMonthPastDue } = formData;

  useEffect(() => {
    if (initialFormData) {
      updateFormData?.(initialFormData || {});
    }
  }, [initialFormData, updateFormData]);

  useEffect(() => {
    if (isMoreThanOneMonthPastDue !== undefined) {
      if (isValid === 'false') {
        updateSpecificTaskById(lienId, {
          complete: undefined,
        });
      }
      if (isValid === 'true') {
        updateSpecificTaskById(lienId, {
          complete: createOrUpdateMortgageLienTask,
        });
      }
    }
  }, [isMoreThanOneMonthPastDue, lienId, updateSpecificTaskById, isValid, createOrUpdateMortgageLienTask]);

  useEffect(() => {
    if (canUpdateTaskData) {
      updateSpecificTaskById(lienId, {
        saving: isLoadingMortgageLienTask,
        isValidForm,
        isFormChanged,
        taskId,
        updateFormData,
        formData,
        initialFormData,
        deleteForm,
      });
    }
  }, [
    canUpdateTaskData,
    isLoadingMortgageLienTask,
    isFormChanged,
    isValidForm,
    lienId,
    taskId,
    updateSpecificTaskById,
    lien?.document,
    updateFormData,
    formData,
    initialFormData,
    deleteForm,
  ]);

  return { formData, initialFormData, registerField, handleFieldChange, isFormChanged, setErrors };
}

/**
 * @typedef {({formData: MortgageLienFormData, extendedTaskData?: ExtendedTaskData}) => void} CreateOrUpdateMortgageLienTask
 */
/**
 * @typedef UseMortgageLienTaskForm
 * @type {object}
 * @property {boolean} [isCreatingOrUpdatingMortgageLienTask]
 * @property {CreateOrUpdateMortgageLienTask} [createOrUpdateMortgageLienTask]
 * @property {MortgageLienFormData} [initialFormData]
 */
/**
 * @param {string} [trackId]
 * @param {[string]} [docIdsToLink]
 * @param {ExtendedTaskData} extendedTaskData
 * @returns {UseMortgageLienTaskForm}
 */
export function useMortgageLienTaskForm(trackId, lien) {
  const [createMortgageLien, { loading: isCreateMortgageLienLoading }] = useMutation(CREATE_MORTGAGE_FROM_TASK);
  const [updateMortgageLien, { loading: isUpdateMortgageLienLoading }] = useMutation(UPDATE_MORTGAGE_FROM_TASK);
  const [deleteLien, { loading: isDeleteLienLoading }] = useMutation(DELETE_LIEN);
  const [disqualifyNewPastDueLien, { loading: isDisqualifyNewPastDueLienLoading }] =
    useMutation(DISQUALIFY_NEW_PAST_DUE_LIEN);
  const [disqualifyExistingPastDueLien, { loading: isDisqualifyExistingPastDueLienLoading }] = useMutation(
    DISQUALIFY_EXISTING_PAST_DUE_LIEN,
  );

  const initialFormData = useMemo(() => getMortgageLienInitialFormData(lien), [lien]);

  const isLoadingMortgageLienTask =
    isCreateMortgageLienLoading ||
    isUpdateMortgageLienLoading ||
    isDisqualifyNewPastDueLienLoading ||
    isDisqualifyExistingPastDueLienLoading ||
    isDeleteLienLoading;

  const createOrUpdateMortgageLienTask = useCallback(
    async ({ formData = {} }) => {
      if (formData?.isValid !== 'true') {
        return;
      }
      const {
        asOfDate,
        currentBalance,
        escrowAmount,
        isForbearance,
        holder,
        interestAmount,
        isMoreThanOneMonthPastDue,
        mortgageAccountNumber,
        pastDueAmount,
        pastDueFees,
        principalAmount,
        hasAModification,
        notes,
      } = formData;
      const variables = {
        asOfDate,
        currentBalance,
        escrowAmount,
        hasForbearance: isForbearance === 'true',
        holder,
        interestAmount,
        isMoreThanOneMonthPastDue: isMoreThanOneMonthPastDue === 'true',
        mortgageAccountNumber,
        pastDueAmount,
        pastDueFees,
        principalAmount,
        docIdsToLink: [formData?.docIdsToLink] || [],
        hasAModification: getSelectionYesNoUnknownSelectedValue(hasAModification),
        notes,
      };

      if (lien && lien.identifier && typeof lien.identifier === 'string') {
        await updateMortgageLien({
          variables: { ...variables, lienId: lien.identifier, lienKind: lien.kind },
        });
      } else {
        await createMortgageLien({
          variables: { ...variables, trackId, lienKind: MORTGAGE_LIEN_TYPE },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackId, createMortgageLien, updateMortgageLien, disqualifyNewPastDueLien, disqualifyExistingPastDueLien, lien],
  );

  const deleteForm = useCallback(async () => {
    const lienId = lien.identifier;
    if (lienId) {
      await deleteLien({
        variables: { lienId },
      });
    }
  }, [deleteLien, lien.identifier]);

  return {
    isLoadingMortgageLienTask,
    createOrUpdateMortgageLienTask,
    deleteForm: lien.isNewForm ? undefined : deleteForm,
    initialFormData,
  };
}

/**
 * @param {MortgageLienFormData} [formData]
 * @param {Record<string, {message: string, show: boolean}>} [errors]
 * @returns {boolean}
 */
function getIsValidMortgageLienTaskForm(user = {}, formData = {}, errors) {
  const { reason, reasonNote, isValid, docIdsToLink } = formData;

  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });

  if (!docIdsToLink) {
    return false;
  }

  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }

  return isValid === 'true' && isEmpty(errors);
}

export const MORTGAGE_LIEN_DEMO_DATA = {
  holder: 'BOA',
  asOfDate: dayjs().format('YYYY-MM-DD'),
  mortgageAccountNumber: '123456789',
  currentBalance: '123456.78',
  principalAmount: '8857.78',
  interestAmount: '444.44',
  escrowAmount: '222.22',
  hasAModification: 'false',
  isMoreThanOneMonthPastDue: 'false',
  pastDueAmount: '555.55',
  pastDueFees: '66.66',
  isForbearance: 'false',
  isValid: 'true',
};
