import { getHomeownerTodoReason } from '../../../../../todos/todoUtils';
import { useMutation } from '@apollo/client';
import { UPDATE_INSURANCE_POLICY_REPLACEMENT_COST } from '../../../../data/mutations';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { CurrencyInput, useForm } from '@hometap/htco-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { getTodoNotesOptions } from '../../../../../utils/trackDetailsTodo';
import { CompleteTaskError } from '../TaskSpecificContentController';
import { useSpecificContentQuery, useUpdateSpecificTaskContent } from '../../../../hooks/useSpecificContentQuery';
import { GET_REPLACEMENT_COST_ESTIMATOR_REVIEW } from './getReplacementCostEstimatorReview';
import { useParams } from 'react-router-dom';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { getVariableValue } from '../../../TaskList/TaskListUtils';
import { INSURANCE_POLICY_KINDS } from 'apps/track-details/data/constants/policyKinds';

const INVALID_REPLACEMENT_COST_ESTIMATOR_TEXT =
  'Due to an invalid document, a request for an updated Replacement Cost Estimator will be sent when this task is marked as complete.';

/**
 * @typedef ReplacementCostEstimatorFormData
 * @type {object}
 * @property {TrueFalseValue} [isValid]
 * @property {string} [reason]
 * @property {string} [reasonNote]
 * @property {number} [replacementCostAmount]
 */
/**
 * @typedef useReplacementCostEstimatorReviewTaskForm
 * @type {object}
 * @property {ReplacementCostEstimatorFormData} formData
 * @property {(name: string, valueProp?: string) => RegisterFieldReturn} registerField
 */
/**
 * @typedef ReplacementCostEstimatorParams
 * @type {object}
 * @property {Task} task
 */
/**
 * @param {ReplacementCostEstimatorParams} params
 * @return JSX.Element
 */
const ReplacementCostEstimatorReview = ({ task }) => {
  const { trackId } = useParams();
  const { registerField, updateFormData, formData = {} } = useForm({});
  const { data: homeownersInsuranceData } = useSpecificContentQuery(GET_REPLACEMENT_COST_ESTIMATOR_REVIEW);
  const extendedTaskData = useMemo(
    () => getReplacementCostEstimatorReviewExtendedData(homeownersInsuranceData?.track),
    [homeownersInsuranceData],
  );
  const { taskKinds } = useConfigurations();
  const { user } = useCurrentUser();

  const [isSufficient, setIsSufficient] = useState();

  const { insurancePolicyReviews } = extendedTaskData;
  const policyKind = getTaskPolicyKind(task);
  const { dwellingAmount } = insurancePolicyReviews?.find(({ kind }) => kind === policyKind) || {};
  const { isValid, reason, replacementCostAmount } = formData;
  const taskId = task.identifier;
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const canUpdateTaskData = !!homeownersInsuranceData;

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { initialFormData, updateReplacementCostEstimatorTask, isUpdatingReplacementCostEstimatorTask } =
    useReplacementCostEstimatorReviewTaskForm(trackId, extendedTaskData, policyKind);

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.HOMEOWNER_INSURANCE_REVIEW_V1,
    taskKinds: taskKinds,
  });

  const isValidForm = useMemo(() => {
    return getIsValidReplacementCostEstimatorReviewTaskForm(user, formData);
  }, [user, formData]);

  useEffect(() => {
    if (canUpdateTaskData) {
      updateSpecificTaskById(taskId, {
        saving: isUpdatingReplacementCostEstimatorTask,
        complete: updateReplacementCostEstimatorTask,
        initialFormData,
        formData,
        updateFormData,
        isValidForm,
      });
    }
  }, [
    isUpdatingReplacementCostEstimatorTask,
    updateReplacementCostEstimatorTask,
    updateSpecificTaskById,
    taskId,
    initialFormData,
    formData,
    updateFormData,
    isValidForm,
    canUpdateTaskData,
  ]);

  useEffect(() => {
    if (!dwellingAmount) {
      return;
    }

    if (!replacementCostAmount) {
      return setIsSufficient(undefined);
    }

    setIsSufficient(dwellingAmount >= replacementCostAmount);
  }, [dwellingAmount, replacementCostAmount]);

  return (
    <TaskDetailContentSectionReview
      header="Document validation"
      label="Is this a valid Replacement Cost Estimator?"
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      isWithDivider={false}
      tooltipContent={null}
      invalidInformationText={INVALID_REPLACEMENT_COST_ESTIMATOR_TEXT}
      reasonOptions={REASON_OPTIONS}
    >
      <CurrencyInput label="Coverage amount" disabled={true} extendedMaskProps={{ scale: 0 }} value={dwellingAmount} />
      <CurrencyInput
        label="Replacement cost"
        disabled={isCompleted}
        extendedMaskProps={{ scale: 0 }}
        {...registerField('replacementCostAmount')}
      />

      {isSufficient !== undefined && !isSufficient && (
        <DetailInformationBlock
          text="The coverage for this property is insufficient, please contact your IM to discuss next steps for increasing coverage with the Homeowner."
          type="alert"
          alignIcon="center"
        />
      )}

      {isSufficient !== undefined && isSufficient && (
        <DetailInformationBlock
          text="Homeowners’ insurance coverage is sufficient. App Review will be updated to “YES” for Replacement Cost when this task is marked as complete."
          type="info"
          isWithIcon={false}
        />
      )}
    </TaskDetailContentSectionReview>
  );
};
export default ReplacementCostEstimatorReview;

export function getReplacementCostEstimatorReviewInitialFormData() {
  return {
    isValid: undefined,
    replacementCostAmount: undefined,
  };
}

/**
 * @param {ReplacementCostEstimatorFormData} [formData]
 * @returns {boolean}
 */
export function getIsValidReplacementCostEstimatorReviewTaskForm(user, formData) {
  const { isValid, reason, reasonNote, replacementCostAmount } = formData;
  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }
  return (isValid === 'true' && !!replacementCostAmount) || (isValid === 'false' && homeownerTodoReason);
}

/**
 * @param {Track} [track]
 * @param {Configurations} [configurations]
 * @returns {ExtendedTaskData}
 */
export function getReplacementCostEstimatorReviewExtendedData(track = {}) {
  const { insurancePolicyReviews } = track;
  return { insurancePolicyReviews };
}

export function useReplacementCostEstimatorReviewTaskForm(trackId, extendedTaskData, policyKind) {
  const [updateHomeownersInsurance, { loading: isUpdatingReplacementCostEstimatorTask }] = useMutation(
    UPDATE_INSURANCE_POLICY_REPLACEMENT_COST,
  );

  const initialFormData = useMemo(() => getReplacementCostEstimatorReviewInitialFormData(), []);

  const updateReplacementCostEstimatorTask = useCallback(
    async ({ formData = {} }) => {
      const { insurancePolicyReviews } = extendedTaskData;
      const insuranceReview = insurancePolicyReviews?.find(({ kind }) => kind === policyKind) || {};

      if (formData?.isValid !== 'true') {
        return;
      }

      if (insuranceReview && insuranceReview.identifier) {
        await updateHomeownersInsurance({
          variables: {
            kind: policyKind,
            insurancePolicyId: insuranceReview.identifier,
            dwellingAmount: formData.replacementCostAmount,
          },
        });
      } else {
        throw new CompleteTaskError('No insurance policy found to update');
      }
    },
    [extendedTaskData, policyKind, updateHomeownersInsurance],
  );

  return {
    updateReplacementCostEstimatorTask,
    isUpdatingReplacementCostEstimatorTask,
    initialFormData,
  };
}

export const REPLACEMENT_COST_ESTIMATOR_DEMO_DATA = {
  isValid: 'true',
  replacementCostAmount: 1000,
};

const RCE_DOC_KIND_TO_POLICY_KIND = {
  replacement_cost_estimator_on_hoi: INSURANCE_POLICY_KINDS.HOMEOWNERS,
  replacement_cost_estimator_on_condo_master_policy: INSURANCE_POLICY_KINDS.CONDO,
};

export const getTaskPolicyKind = task => {
  const taskDocKind = getVariableValue(task, 'doc_kind');
  if (taskDocKind) {
    return RCE_DOC_KIND_TO_POLICY_KIND[taskDocKind];
  }
  return INSURANCE_POLICY_KINDS.HOMEOWNERS;
};
