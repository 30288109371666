export const LIEN_TYPE = {
  Mortgage: 'mortgage',
  Heloc: 'heloc',
  HomeEquityInvestment: 'home_equity_investment',
  HomeEquityLoan: 'home_equity_loan',
  PrivateMortgage: 'private_mortgage',
  Judgement: 'judgement',
  Tax: 'tax_lien',
  PartialClaim: 'partial_claim',
  Other: 'other',
};

export const ON_PROPERTY_REPORT_FIELD_TYPES = {
  Position: 'position',
  OriginalAmount: 'originalAmount',
  LienDate: 'lienDate',
  EndOfTermDate: 'endOfTermDate',
  Rate: 'rate',
  RateType: 'rateType',
  Holder: 'holder',
  Lender: 'lender',
};

export const LIEN_PROPERTY_REPORT_FIELDS = [
  ON_PROPERTY_REPORT_FIELD_TYPES.Position,
  ON_PROPERTY_REPORT_FIELD_TYPES.OriginalAmount,
  ON_PROPERTY_REPORT_FIELD_TYPES.LienDate,
  ON_PROPERTY_REPORT_FIELD_TYPES.EndOfTermDate,
  ON_PROPERTY_REPORT_FIELD_TYPES.Rate,
  ON_PROPERTY_REPORT_FIELD_TYPES.RateType,
];

export const KIND_LIEN_PROPERTY_REPORT_FIELDS = [
  {
    kinds: [LIEN_TYPE.Judgement, LIEN_TYPE.Tax],
    fields: [
      ON_PROPERTY_REPORT_FIELD_TYPES.Position,
      ON_PROPERTY_REPORT_FIELD_TYPES.OriginalAmount,
      ON_PROPERTY_REPORT_FIELD_TYPES.LienDate,
      ON_PROPERTY_REPORT_FIELD_TYPES.Holder,
    ],
  },
  {
    kinds: [LIEN_TYPE.HomeEquityInvestment],
    fields: [
      ON_PROPERTY_REPORT_FIELD_TYPES.Position,
      ON_PROPERTY_REPORT_FIELD_TYPES.OriginalAmount,
      ON_PROPERTY_REPORT_FIELD_TYPES.LienDate,
      ON_PROPERTY_REPORT_FIELD_TYPES.EndOfTermDate,
      ON_PROPERTY_REPORT_FIELD_TYPES.Holder,
    ],
  },
  {
    kinds: [LIEN_TYPE.PartialClaim],
    fields: [
      ON_PROPERTY_REPORT_FIELD_TYPES.Position,
      ON_PROPERTY_REPORT_FIELD_TYPES.LienDate,
      ON_PROPERTY_REPORT_FIELD_TYPES.OriginalAmount,
      ON_PROPERTY_REPORT_FIELD_TYPES.EndOfTermDate,
      ON_PROPERTY_REPORT_FIELD_TYPES.Lender,
    ],
  },
];
