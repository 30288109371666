import { TaskDrawerButton } from '../TaskDrawer/TaskDrawerButton';
import cx from 'classnames';
import { SlideSide } from '@hometap/htco-components';
import React from 'react';

import './TaskDocumentsDrawer.scss';

const TRANSITION_DURATION = 250;

const TaskDocumentsDrawer = ({ setShouldOpen, shouldOpen, children }) => {
  const handleInternalButtonClick = () => {
    setShouldOpen(!shouldOpen);
  };

  return (
    <>
      <TaskDrawerButton
        className={cx('TaskDrawerControlButton', 'TaskDocumentsDrawerButton', { isOpen: shouldOpen })}
        onClick={handleInternalButtonClick}
      />
      <SlideSide
        className="TaskDocumentsDrawerWrapper"
        direction="right"
        duration={TRANSITION_DURATION}
        visible={shouldOpen}
        isOverlay={false}
        isRelativeOverlay={false}
      >
        {children}
      </SlideSide>
    </>
  );
};

export default TaskDocumentsDrawer;
