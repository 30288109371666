import React from 'react';
import { Paper } from '@hometap/htco-components';
import { MultiPicker } from '../index.js';
import PropTypes from 'prop-types';

import './ApplicationReviewSection.scss';
import { SummaryProps } from '../Summary/Summary.js';
import FormWrapperWithDeleteConfirmModal from 'components/FormWrapperWithDeleteConfirmModal/FormWrapperWithDeleteConfirmModal.js';

const exampleTitle = 'Multipicker Test';
const exampleEditFunction = function unlockForm() {
  console.log('Per a discussion on figma with Bradley clicking the edit button should unlock section form fields.');
  console.log('This should be removed once SPA editing propery integrated');
};

/**
 * Wrapper component for ARP sections to make formatting and layout consistent
 * Quick note that all props except anchorId and children for this component are passed directly to the MultiPicker component
 * @param {Object} props - the properties passed to the component
 * @param {string} props.anchorId - text that identifies the section for use when navigating via anchors
 * @param {string} props.sectionTitle - text that should be the header
 * @param {object} props.sectionAlert - alert to display at the section level
 * @param {string} props.historyUrl - url to the history page of the section
 * @param {string} props.sectionDetails - text that should go under the header
 * @param {array} props.pickList - optional array if ARP form can have multiple entry instances (e.g. insurance policies or mortgages)
 * @param {any} props.selectedId - optional ID of current entry if ARP form can have multiple entry instances (e.g. insurance policies or mortgages)
 * @param {callback} props.onSelect - optional function to select current entry if ARP form can have multiple entry instances (e.g. insurance policies or mortgages)
 * @param {callback} props.onEdit - callback for when user clicks on the edit button
 * @param {boolean} props.visibleSummary - determines if a summary section values should be rendered
 * @param {SummaryParams} props.summaryParams - object containing summary properties
 * @param {any} props.bottomLink - optional render component with link
 * @param {boolean} props.isWithActionBar - determines if the component renders with an action bar
 * @param {ApplicationReviewActionBarParams} props.actionBarProps - props to be passed to the action bar component
 * @param {boolean} props.isSectionEverDeletable - indicates if the entire section should ever be able to be deleted
 * @param {object} props.deletionModalProps - props to be passed to the deletion modal component
 * @returns
 */

export const ApplicationReviewSection = ({
  anchorId = '',
  sectionTitle = exampleTitle,
  sectionDetails,
  historyUrl,
  pickList = [],
  selectedId = 0,
  onSelect,
  onEdit = exampleEditFunction,
  visibleSummary = false,
  summaryParams,
  sectionAlert,
  showFirstCombinedDivider,
  isWithActionBar,
  actionBarProps,
  isSectionEverDeletable,
  deletionModalProps,
  bottomLink,
  children,
}) => {
  return (
    <div className="ApplicationReviewSection" id={anchorId}>
      <Paper theme="plain" className="MultiPickerContainer">
        <MultiPicker
          sectionTitle={sectionTitle}
          historyUrl={historyUrl}
          sectionDetails={sectionDetails}
          pickList={pickList}
          selectedId={selectedId}
          onSelect={onSelect}
          onEdit={onEdit}
          visibleSummary={visibleSummary}
          summaryParams={summaryParams}
          sectionAlert={sectionAlert}
          isWithActionBar={isWithActionBar}
          actionBarProps={actionBarProps}
          bottomLink={bottomLink}
          showFirstCombinedDivider={showFirstCombinedDivider}
        />
      </Paper>
      <Paper theme="plain" className="FormDetailsContainer">
        {isSectionEverDeletable ? (
          <FormWrapperWithDeleteConfirmModal {...deletionModalProps}>{children}</FormWrapperWithDeleteConfirmModal>
        ) : (
          children
        )}
      </Paper>
    </div>
  );
};

ApplicationReviewSection.propTypes = {
  anchorId: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionDetails: PropTypes.string,
  // TODO: Add sectionAlert: SelectionLevelAlertDefinition
  historyUrl: PropTypes.string,
  pickList: PropTypes.arrayOf(
    PropTypes.shape({
      subSectionLabel: PropTypes.string.isRequired,
      valueLabel: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object]),
      selectorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  visibleSummary: PropTypes.bool,
  summaryParams: SummaryProps,
  isWithActionBar: PropTypes.bool,
  actionBarProps: PropTypes.shape({
    primaryButtonName: PropTypes.string,
    secondaryButtonName: PropTypes.string,
    isPrimaryButtonEnabled: PropTypes.bool,
    isSecondaryButtonEnabled: PropTypes.bool,
    isShowPrompt: PropTypes.bool,
    onPrimaryButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
    isValid: PropTypes.bool,
  }),
  isSectionEverDeletable: PropTypes.bool,
  deletionModalProps: PropTypes.shape({
    title: PropTypes.string,
    header: PropTypes.string,
    type: PropTypes.string,
    canDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    canShowModal: PropTypes.bool,
  }),
  showFirstCombinedDivider: PropTypes.bool,
  bottomLink: PropTypes.element,
};
