import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import docIcon from 'assets/icons/doc.svg';

import './MultiPicker.scss';
import cx from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { Badge, Icon } from '@hometap/htco-components';
import ApplicationReviewActionBar from '../ApplicationReviewActionBar/ApplicationReviewActionBar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Summary, SummaryProps } from '../Summary/Summary';

/**
 * Generic MultiPicker component for using in Application Review sections that have multiple sub-parts
 * @param {Object} props
 * @param {string} props.sectionTitle - text that should be the header
 * @param {object} props.sectionAlert - alert to display at the section level
 * @param {string} props.historyUrl - url to the history page of the section
 * @param {string} props.sectionDetails - text that should go under the header
 * @param {array} props.pickList - array containing the elements that need to be on the picklist and their details
 * @param {any} props.selectedId - identifier of the currently picked section so styling can be applied
 * @param {callback} props.onSelect - callback when user clicks on a picker section
 * @param {callback} props.onEdit - callback for when user clicks on the edit button
 * @param {boolean} props.visibleSummary - determines if a summary section values should be rendered
 * @param {import('../Summary/Summary').SummaryParams} props.summaryParams - Object containing summary properties.
 * @param {import('../ApplicationReviewActionBar/ApplicationReviewActionBar').ApplicationReviewActionBarParams} props.actionBarProps - props to be passed to the action bar component
 * @param {any} props.bottomLink - optional render component with link
 * @param {boolean} props.isWithActionBar - determines if the component renders with an action bar
 */
export const MultiPicker = ({
  historyUrl,
  onEdit,
  onSelect,
  pickList,
  visibleSummary,
  summaryParams,
  sectionDetails,
  sectionTitle,
  sectionAlert,
  selectedId,
  isWithActionBar,
  actionBarProps,
  bottomLink,
}) => {
  const { trackId } = useParams();

  return (
    <div>
      <div className="MultiPickerHeader">
        <h3 className="MultiPickerTitle">{sectionTitle}</h3>
        {onEdit && (
          <span className="MultiPickerEditButton" onClick={() => onEdit()}>
            Edit
          </span>
        )}
      </div>
      {historyUrl && (
        <div className={cx('MultiPickerHistoryLink', { sectionDetails: sectionDetails })}>
          <a href={historyUrl}>View History</a>
        </div>
      )}
      {sectionDetails && <div className="MultiPickerDetails">{sectionDetails}</div>}
      {isWithActionBar && <ApplicationReviewActionBar sectionAlert={sectionAlert} {...actionBarProps} />}
      <div className={cx('SelectorOptionsContainer', { isWithActionBar, bottomLink })}>
        <TransitionGroup>
          {pickList.map(
            (
              {
                subSectionLabel,
                valueLabel,
                value,
                additionalValue,
                additionalLabel,
                selectorId,
                document,
                isPending,
                isDisabled,
                isFormValid,
              },
              i,
            ) => {
              const itemRef = createRef(null);
              return (
                <CSSTransition
                  nodeRef={itemRef}
                  key={i}
                  timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 300,
                  }}
                  classNames="MultiPickerItem"
                >
                  <div
                    className={cx('SelectorOption', {
                      isDisabled: isDisabled && selectedId !== selectorId,
                      hasError: isFormValid === false,
                    })}
                    onClick={e => {
                      if (e.target.classList.contains('icon')) {
                        return;
                      }
                      !isDisabled && onSelect(selectorId);
                    }}
                    data-selected={selectedId === selectorId}
                  >
                    {sectionAlert?.triggeringIds?.includes(selectorId) && (
                      <div
                        className="SelectorOptionAlertIconWrapper"
                        onClick={e => {
                          !isDisabled && onSelect(selectorId);
                        }}
                        style={{ color: sectionAlert.color }}
                      >
                        <Icon name={sectionAlert.icon} className="SelectorOptionAlertIcon" />
                      </div>
                    )}

                    <div className={cx('SelectorOptionLabelWrapper', { isPending })}>
                      <div className={cx('SelectorOptionLabel', { isDisabled })}>{subSectionLabel}</div>
                      {isPending && (
                        <Badge
                          label="Pending task completion"
                          theme="primaryBlue15"
                          className="SelectorOptionLabelBadge"
                        />
                      )}
                      {!isPending && (
                        <div className="SelectorOptionDetails">
                          <div className="SelectorOptionDetailsWrapper">
                            {valueLabel}
                            <span className="SelectorOptionDetailsValue">{value}</span>
                          </div>
                          {additionalValue && additionalLabel && (
                            <>
                              <span className="SelectorOptionDetailsDivider">|</span>
                              <div className="SelectorOptionDetailsWrapper">
                                {additionalLabel}
                                <span className="SelectorOptionDetailsValue">
                                  <span className="SelectorOptionDetailsAdditionalValue">{additionalValue}</span>
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {!!document?.identifier && (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/tracks/${trackId}/documents/${document.identifier}`}
                      >
                        <div className="SelectorOptionDoc icon">
                          <img src={docIcon} className="icon" alt="Hourglass icon" />
                        </div>
                      </Link>
                    )}
                  </div>
                </CSSTransition>
              );
            },
          )}
        </TransitionGroup>
      </div>
      {bottomLink && <div className="MultiPickerBottomLink">{bottomLink}</div>}
      <Summary visible={visibleSummary} summaryParams={summaryParams} />
    </div>
  );
};

MultiPicker.propTypes = {
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  pickList: PropTypes.arrayOf(
    PropTypes.shape({
      selectorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      subSectionLabel: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.object]),
      valueLabel: PropTypes.string,
    }),
  ),
  visibleSummary: PropTypes.bool,
  summaryParams: SummaryProps,
  sectionDetails: PropTypes.string,
  historyUrl: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  // TODO: Add sectionAlert: SelectionLevelAlertDefinition
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isWithActionBar: PropTypes.bool,
  showFirstCombinedDivider: PropTypes.bool,
  actionBarProps: PropTypes.shape({
    primaryButtonName: PropTypes.string,
    secondaryButtonName: PropTypes.string,
    isPrimaryButtonEnabled: PropTypes.bool,
    isSecondaryButtonEnabled: PropTypes.bool,
    isShowPrompt: PropTypes.bool,
    onPrimaryButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
    isValid: PropTypes.bool,
  }),
  bottomLink: PropTypes.element,
};
