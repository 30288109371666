import axios from 'axios';
import { getPortalsUrl } from 'utils/links';

// # --------------------------------------------------------------------------------------
// # Creates a new Homeowner User, Track, assigns Track to the standard IM user, and copies
// # homeowner autologin link to clipboard
// # Warning: This assumes the server is running on localhost:8000 and won't work otherwise
// # --------------------------------------------------------------------------------------

const QA_CREATE_TRACK = `${getPortalsUrl()}api/qa/stages/actions/create-client-in-stage/`;

export const createTrack = formData => axios.post(`${QA_CREATE_TRACK}`, formData);
