import {
  MuiSelect,
  MuiTextInput,
  useForm,
  RadioButtonSet,
  Checkbox,
  Button,
  DataTable,
  Icon,
  Loader,
  Tooltip,
} from '@hometap/htco-components';
import React, { useState } from 'react';
import { SlideInFromRight } from '../SlideInFromRight/SlideInFromRight';
import {
  showDocumentSetRequestedToast,
  showDocumentSetRequestedFailedToast,
  showDocumentSetDocumentsError,
} from '../../DocumentToasts';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GET_DOCUMENT_SET_LIST_QUERY,
  GET_DOCUMENT_SET_REQUEST_LOG_QUERY,
  REQUEST_DOCUMENT_SET_QUERY,
  getDocumentSetDocuments,
} from '../../DocumentSetRequests';
import useCurrentUser from 'hooks/useCurrentUser';
import { useParams } from 'react-router-dom';
import {
  DOCUMENT_SET_VERSION_INCLUSION_OPTIONS,
  DOCUMENT_SET_FILE_TYPE_OPTIONS,
  DEFAULT_DOCUMENT_SET_FORM,
  DOCUMENT_SET_VERSION_INCLUSION_VALUES,
} from '../../data/constants';
import './CreateDocumentSet.scss';
import { unionBy } from 'lodash';

const GET_FRIENDLY_ID = gql`
  query GetTrack($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      friendlyId
    }
  }
`;

const CreateDocumentSet = ({ open, setOpen, documents }) => {
  const [notifyByEmail, setNotifyByEmail] = useState(true);
  const [showDocumentSetOptions, setShowDocumentSetOptions] = useState(false);
  const { data: documentSetData } = useQuery(GET_DOCUMENT_SET_LIST_QUERY);
  const [defaultDocSetValues, setDefaultDocSetValues] = useState({});
  const [includedDocuments, setIncludedDocuments] = useState([]);
  const [suggestedDocuments, setSuggestedDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const { trackId } = useParams();
  const [requestDocSet, { loading }] = useMutation(REQUEST_DOCUMENT_SET_QUERY, {
    refetchQueries: [
      {
        query: GET_DOCUMENT_SET_REQUEST_LOG_QUERY,
        variables: { trackId },
      },
    ],
  });
  const { data } = useQuery(GET_FRIENDLY_ID, { variables: { trackId } });
  const friendlyId = data?.track?.friendlyId;
  const { user } = useCurrentUser();

  // will not change other than setting all the matching documents for the doc set
  const [allMatchingDocuments, setAllMatchingDocuments] = useState([]);

  const { registerField, handleSubmit, updateFormData, isFormValid, formData } = useForm(DEFAULT_DOCUMENT_SET_FORM);
  const [includeVersion, setIncludeVersion] = useState();

  const handleClose = () => {
    setIncludedDocuments([]);
    setSuggestedDocuments([]);
    setOtherDocuments([]);
    updateFormData(DEFAULT_DOCUMENT_SET_FORM);
    setShowDocumentSetOptions(false);
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!isFormValid) {
      return;
    }

    try {
      const variables = {
        variables: {
          trackId,
          documentSetTemplateId: formData?.documentSetType,
          documentIds: includedDocuments.map(doc => doc.id),
          documentSetName: formData?.documentSetName,
          outputFormat: formData?.fileType,
          includedDocumentVersions: includeVersion,
          notifyAfterCompletion: notifyByEmail,
        },
      };
      await requestDocSet(variables);
      showDocumentSetRequestedToast();
      handleClose();
    } catch (error) {
      showDocumentSetRequestedFailedToast();
    }
  };

  const shouldRenderVersionIcon = file => {
    if (!file?.has_other_versions) {
      return false;
    }

    if (
      includeVersion === DOCUMENT_SET_VERSION_INCLUSION_VALUES.ALL ||
      includeVersion === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED
    ) {
      return true;
    }

    return false;
  };

  const shouldRenderPinIcon = file => {
    if (!file?.pinned) {
      return false;
    }

    if (
      includeVersion === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED ||
      includeVersion === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED_PRIMARY ||
      includeVersion === DOCUMENT_SET_VERSION_INCLUSION_VALUES.ALL
    ) {
      return true;
    }
    return false;
  };

  const formatDocumentSetType = documentSetData?.documentSetTemplates.map(documentSet => ({
    label: documentSet.name,
    value: documentSet.id,
  }));

  const includedDocumentsSelectors = [
    {
      name: <strong>File</strong>,
      width: '350px',
      selector: file => file.filename,
      cell: file => {
        return (
          <strong>
            {file?.filename} {shouldRenderVersionIcon(file) && <Icon name="icon-history" />}{' '}
            {shouldRenderPinIcon(file) && <Icon name="pin" />}
          </strong>
        );
      },
    },
    {
      name: <strong>Type</strong>,
      selector: file => file.kind,
      cell: file => <span>{file?.kind_display}</span>,
    },
    {
      name: <strong>Actions</strong>,
      right: true,
      width: '100px',
      cell: file => (
        <button className="CreateDocumentSetRemoveButton" onClick={() => handleRemoveDocument(file)}>
          <Icon name="cancel" color="black" />
        </button>
      ),
    },
  ];

  const suggestedDocumentsSelectors = [
    {
      name: <strong>File</strong>,
      selector: file => file.name,
      cell: file => (
        <strong>
          {file.name}
          {shouldRenderVersionIcon(file) && <Icon name="icon-history" />}
          {shouldRenderPinIcon(file) && <Icon name="pin" />}
        </strong>
      ),
    },
    {
      name: <strong>Type</strong>,
      selector: file => file.kind,
      cell: file => <span>{file?.kind_display}</span>,
    },
    {
      name: <strong>Actions</strong>,
      width: '100px',
      right: true,
      cell: file => (
        <Button theme="link" onClick={() => handleAddDocument(file, 'suggested')}>
          <Icon name="plus" size="sm" style={{ fontWeight: 900 }} />
        </Button>
      ),
    },
  ];

  const otherDocumentsSelectors = [
    {
      name: <strong>File</strong>,
      width: '350px',
      selector: file => file.filename,
      cell: file => (
        <strong>
          {file?.filename}
          {shouldRenderVersionIcon(file) && <Icon name="icon-history" />}{' '}
          {shouldRenderPinIcon(file) && <Icon name="pin" />}
        </strong>
      ),
    },
    {
      name: <strong>Type</strong>,
      selector: file => file.kind,
      cell: file => <span>{file?.kind_display}</span>,
    },
    {
      name: <strong>Actions</strong>,
      width: '100px',
      right: true,
      cell: file => (
        <Button theme="link" onClick={() => handleAddDocument(file, 'other')}>
          <Icon name="plus" size="sm" style={{ fontWeight: 900 }} />
        </Button>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#152033',
      },
    },
  };

  /**
   * Takes document and adds it to the included documents list
   * @param {Object} document
   * @param {String} tableName - table name where document is coming from
   */
  const handleAddDocument = (document, tableName) => {
    // add document to includedDocuments list avoiding duplicates
    const documentObject = { ...document, fromTable: tableName };
    const updatedIncludedDocuments = includedDocuments.filter(doc => doc.id !== documentObject.id);
    setIncludedDocuments([...updatedIncludedDocuments, { ...documentObject, fromTable: tableName }]);
    // remove document from other documents based on document id
    setOtherDocuments(otherDocuments?.filter(doc => doc.id !== document.id));
    setSuggestedDocuments(suggestedDocuments?.filter(doc => doc.id !== document.id));
  };

  /**
   * Removes document from included documents list
   * @param {Object} document
   */
  const handleRemoveDocument = document => {
    // removes document from included Documents
    setIncludedDocuments(includedDocuments.filter(doc => doc.id !== document.id));

    if (document?.fromTable === 'other') {
      // add document back to other documents
      setOtherDocuments([...otherDocuments, document]);
    } else {
      // add document back to suggested documents
      setSuggestedDocuments([...suggestedDocuments, document]);
    }
  };

  /**
   * Places documents in the Included and All Available Documents sections based on the DocSetType's default version and file types.
   * @param {String} docSetTypeID - id denoting the Document Set Type selected
   */
  const handleDocumentSetTypeSelect = async docSetTypeID => {
    if (docSetTypeID) {
      const selectedDocumentSet = documentSetData?.documentSetTemplates.find(docSet => docSet.id === docSetTypeID);
      setDefaultDocSetValues(selectedDocumentSet);
      try {
        const docSetIncludedDocuments = await getDocumentSetDocuments(docSetTypeID, trackId);
        if (selectedDocumentSet) {
          updateFormData({
            documentSetType: docSetTypeID,
            fileType: selectedDocumentSet?.outputFormat,
            documentSetName: `${selectedDocumentSet?.outputFilePrefix}-${friendlyId}-${selectedDocumentSet?.name}`,
          });
          setIncludeVersion(selectedDocumentSet?.includedDocumentVersions);
          setAllMatchingDocuments(docSetIncludedDocuments);
          // handle adding non-pinned documents to the other documents list
          if (
            selectedDocumentSet?.includedDocumentVersions === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED ||
            selectedDocumentSet?.includedDocumentVersions === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED_PRIMARY
          ) {
            const includedPinnedDocuments = docSetIncludedDocuments?.filter(doc => doc?.pinned);
            setIncludedDocuments(includedPinnedDocuments);

            // remove pinned matching documents from other documents
            const updatedOtherDocuments = unionBy(documents, includedPinnedDocuments, 'id').filter(
              doc => !includedPinnedDocuments.map(includedDoc => includedDoc.id).includes(doc.id),
            );
            setOtherDocuments(updatedOtherDocuments);
          } else {
            setIncludedDocuments(docSetIncludedDocuments);
            // remove allmatchingdocuments from other documents
            const updatedOtherDocuments = unionBy(documents, docSetIncludedDocuments, 'id').filter(
              doc => !docSetIncludedDocuments.map(includedDoc => includedDoc.id).includes(doc.id),
            );
            setOtherDocuments(updatedOtherDocuments);
          }

          setSuggestedDocuments([]);
        }
        setShowDocumentSetOptions(true);
      } catch (error) {
        showDocumentSetDocumentsError();
      }
    } else {
      setShowDocumentSetOptions(false);
    }
  };

  /**
   * Validates document set name by making sure it has no special characters
   * @param {String} value
   */
  const validateDocumentSetName = value => {
    const name_regex = /^[a-zA-Z0-9-_ ]+$/;
    if (!value.match(name_regex)) {
      return 'Please enter a valid document set name. No special characters allowed.';
    }
  };

  const handleDocumentVersionChange = newIncludeVersions => {
    setIncludeVersion(newIncludeVersions);
    // handle adding non-pinned documents to the other documents list
    if (
      newIncludeVersions === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED ||
      newIncludeVersions === DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED_PRIMARY
    ) {
      const includedPinnedDocuments = allMatchingDocuments?.filter(doc => doc?.pinned);
      setIncludedDocuments(includedPinnedDocuments);

      // remove pinned matching documents from other documents
      const updatedOtherDocuments = unionBy(documents, includedPinnedDocuments, 'id').filter(
        doc => !includedPinnedDocuments.map(includedDoc => includedDoc.id).includes(doc.id),
      );
      setOtherDocuments(updatedOtherDocuments);
    } else {
      setIncludedDocuments(allMatchingDocuments);
      // remove allmatchingdocuments from other documents
      const updatedOtherDocuments = unionBy(documents, allMatchingDocuments, 'id').filter(
        doc => !allMatchingDocuments.map(includedDoc => includedDoc.id).includes(doc.id),
      );
      setOtherDocuments(updatedOtherDocuments);
    }

    setSuggestedDocuments([]);
  };

  const disableCTA = includedDocuments?.length === 0 || !isFormValid;

  return (
    <SlideInFromRight className="CreateDocumentSet" closable={true} open={open} close={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="CreateDocumentSetContentContainer">
          <h2>Create Document Set</h2>
          <MuiSelect
            required
            label="Document Set Type"
            options={formatDocumentSetType}
            {...registerField('documentSetType')}
            isMuiSelect={true}
            width={'100%'}
            onChange={handleDocumentSetTypeSelect}
            theme="outlined"
          />

          {showDocumentSetOptions && (
            <>
              <MuiTextInput
                required
                validator={validateDocumentSetName}
                label="Document Set Name"
                theme="outlined"
                {...registerField('documentSetName')}
              />
              <div>
                <h6 className="CreateDocumentSetContentContainerHeader">File Type</h6>
                <RadioButtonSet
                  className="CreateDocumentSetRadioButtonSet"
                  name="documentSetFileType"
                  inline
                  required
                  options={DOCUMENT_SET_FILE_TYPE_OPTIONS(defaultDocSetValues?.outputFormat)}
                  {...registerField('fileType')}
                />
              </div>
              <div style={{ zIndex: 2 }}>
                <h6 className="CreateDocumentSetContentContainerHeader">Document Versions</h6>
                <RadioButtonSet
                  className="CreateDocumentSetRadioButtonSet"
                  required
                  name="documentSetFileType"
                  inline
                  options={DOCUMENT_SET_VERSION_INCLUSION_OPTIONS}
                  onChange={e => handleDocumentVersionChange(e)}
                  value={includeVersion}
                />
              </div>
              <div className="CreateDocumentSetContentFileList">
                <h4 className="CreateDocumentSetContentDataTableHeader">
                  Included Documents
                  <Tooltip
                    content="Based on the document type these documents have been automatically added."
                    action="hover"
                    className="CreateDocumentSetTooltip"
                  >
                    <Icon name="info" size="sm" />
                  </Tooltip>
                </h4>
                <hr />
                <DataTable
                  columns={includedDocumentsSelectors}
                  noDataComponent={
                    <div className="CreateDocumentSetNoDocuments">
                      There are no documents included in this document set.
                    </div>
                  }
                  customStyles={customStyles}
                  data={includedDocuments}
                />
              </div>

              {suggestedDocuments.length > 0 && (
                <div className="CreateDocumentSetContentFileList">
                  <h4 className="CreateDocumentSetContentDataTableHeader">
                    Suggested Documents
                    <Tooltip
                      content="These files have a document type that is expected in the document set. Please review the list and add any documents that belong."
                      action="hover"
                      className="CreateDocumentSetTooltip"
                    >
                      <Icon name="info" size="sm" />
                    </Tooltip>
                  </h4>
                  <hr />
                  <DataTable
                    columns={suggestedDocumentsSelectors}
                    noDataComponent={<div className="CreateDocumentSetNoDocuments">There are no documents yet.</div>}
                    customStyles={customStyles}
                    data={suggestedDocuments}
                  />
                </div>
              )}
              <div className="CreateDocumentSetContentFileList">
                <h4 className="CreateDocumentSetContentDataTableHeader">All Available Documents</h4>
                <hr />
                <DataTable
                  columns={otherDocumentsSelectors}
                  noDataComponent={
                    <div className="CreateDocumentSetNoDocuments">There are no additional documents to include.</div>
                  }
                  customStyles={customStyles}
                  data={otherDocuments}
                />
              </div>
            </>
          )}
        </div>
        <div className="CreateDocumentSetStaticButtonBar">
          <span className="CreateDocumentSetStaticButtonBarNotify">
            <Checkbox
              label="Notify me by email when complete"
              checked={notifyByEmail}
              onChange={() => setNotifyByEmail(!notifyByEmail)}
            />
            <p className="CreateDocumentSetStaticButtonBarLittleText">
              {user?.email} will be notified when this document set is generated.
            </p>
          </span>
          <span className="CreateDocumentSetStaticButtonBarContainer">
            <Button onClick={handleClose} theme="link">
              Cancel
            </Button>
            <Button
              className="CreateDocumentSetSubmitButton"
              width={'220px'}
              type="submit"
              disabled={disableCTA || loading}
            >
              {loading ? (
                <Loader theme="inverse" className="CreateDocumentSetSubmitLoader" />
              ) : (
                <>Create Document Set</>
              )}
            </Button>
          </span>
        </div>
      </form>
    </SlideInFromRight>
  );
};

export default CreateDocumentSet;
