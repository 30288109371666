import { DatePicker, MuiSelect } from '@hometap/htco-components';

const ADVERSE_EVENTS_OPTIONS = [
  { value: 'Foreclosure', label: 'Foreclosure' },
  { value: 'Bankruptcy', label: 'Bankruptcy' },
  { value: 'Shortsale', label: 'Shortsale' },
];

export const AdverseEventForm = ({ isDisabled, registerChildField }) => {
  return (
    <>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <MuiSelect
            label="Adverse event"
            options={ADVERSE_EVENTS_OPTIONS}
            disabled={isDisabled}
            theme="outlined"
            width="100%"
            {...registerChildField('event')}
          />
        </div>

        <div className="FormItem">
          <DatePicker
            label={'Event Date'}
            theme="outlined"
            disabled={isDisabled}
            showRequiredAsterisk={false}
            {...registerChildField('event_date')}
          />
        </div>
      </div>
    </>
  );
};
