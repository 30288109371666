import './CannotEditAlert.scss';

export const CannotEditAlert = ({ applicationIsApproved, trackInvestmentManager }) => {
  const cannotEditApprovedMessage =
    'Editing has been disabled because this application has been approved. If you need to edit this information, please reset the Application Review in the Stage tab.';
  const cannotEditClosedOrOtherMessage = `Editing has been disabled for one or more of the following reasons:
  1. The track is before app review stage 
  2. the track is closed/lost 
  3. you are not logged in as an Underwriter or an Application Specialist. Please reach out to ${
    trackInvestmentManager || "the track's Investment Manager"
  } with any questions.`;
  const alertMessage = applicationIsApproved ? cannotEditApprovedMessage : cannotEditClosedOrOtherMessage;

  return <div className="ApplicationReviewCannotEditAlert">{alertMessage}</div>;
};
