import React from 'react';
import { DataTable } from '@hometap/htco-components';

import { ITEMS_PER_PAGE, CUSTOM_TABLE_STYLES } from 'utils/tables';

import './FilterableTable.scss';

const FilterableTable = ({
  data,
  loading,
  resetDefaultPage,
  onPageRequest,
  totalItems,
  onSort,
  errorDataComponent,
  testId,
  columns,
  mobileColumns,
}) => {
  return (
    <div className="FilterableTableContainer" data-test={testId}>
      <DataTable
        data={data}
        columns={columns}
        loading={loading}
        onSort={onSort}
        paginationPerPage={ITEMS_PER_PAGE}
        paginationResetDefaultPage={resetDefaultPage}
        pagination
        paginationServer
        onChangePage={onPageRequest}
        sortServer
        respondAt="md"
        customStyles={CUSTOM_TABLE_STYLES}
        paginationTotalRows={totalItems}
        noDataComponent={errorDataComponent || 'There are no records to display'}
        mobileColumns={mobileColumns}
      />
    </div>
  );
};

export default FilterableTable;
