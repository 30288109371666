import { TRACK_DETAILS_PATH } from 'AppRouter';
import { getPortalsUrl } from 'utils/links';
import { APP_REVIEW_ANCHORS } from '../ApplicationReview/data/constants/appReviewAnchors';
export const getPortalsTrackDetailBaseUrl = trackId => getPortalsUrl(`staff/clientview/${trackId}`);

export const getRiskBandDetailsPortalsUrl = (trackId, riskBandRequestId) => {
  const baseUrl = getPortalsTrackDetailBaseUrl(trackId);
  return `${baseUrl}/risk-score/${riskBandRequestId}`;
};

const getTrackDetailsPathFor = trackId => {
  // Generates a react app specific path for a given track
  return path => {
    let formattedPath = path;
    if (path[0] !== '/') {
      formattedPath = `/${path}`;
    }
    return `${TRACK_DETAILS_PATH.base}/${trackId}${formattedPath}`;
  };
};

export const getTrackDetailPageUrls = (trackId, rolloutFlags = []) => {
  const getTrackDetailsPagePath = getTrackDetailsPathFor(trackId);
  const basePortalsTrackDetailUrl = getPortalsTrackDetailBaseUrl(trackId);
  return {
    getRiskBandDetailsPortalsUrl: riskBandRequestId => getRiskBandDetailsPortalsUrl(trackId, riskBandRequestId),
    getDocumentViewUrl: documentId => `${basePortalsTrackDetailUrl}/documents/${documentId}`,
    stageUrl: basePortalsTrackDetailUrl,
    homeownerInfoUrl: `${basePortalsTrackDetailUrl}/info/`,
    riskScoreSummaryUrl: `${basePortalsTrackDetailUrl}/risk-score/summary/`,
    homeValuationsUrl: `${basePortalsTrackDetailUrl}/home-valuation/`,
    titleSearchReportUrl: `${basePortalsTrackDetailUrl}/title-search-report/`,
    appraisalsUrl: getTrackDetailsPagePath(`/appraisals`),
    documentsCreateUrl: getTrackDetailsPagePath('/documents'),
    contractGenUrl: `${basePortalsTrackDetailUrl}/contract-generation-template-selector/`,
    createTasksUrl: `${basePortalsTrackDetailUrl}/tasks/create/`,
    editPropertyAddressUrl: `${basePortalsTrackDetailUrl}/address/edit/`,
    editHomeUrl: `${basePortalsTrackDetailUrl}/home/edit/`,
    editMailingAddressUrl: `${basePortalsTrackDetailUrl}/mailing-address/edit/`,
    trackHistoryUrl: `${basePortalsTrackDetailUrl}/history/client/`,
    rundownCreate: `${basePortalsTrackDetailUrl}/rundown/create/`,
    editExpirationDateUrl: `${basePortalsTrackDetailUrl}/expiration-date/edit/`,
  };
};

export const getApplicationReviewPageUrls = (trackId, rolloutFlags = []) => {
  const basePortalsTrackDetailUrl = getPortalsTrackDetailBaseUrl(trackId);
  const baseApplicationReviewPath = `${basePortalsTrackDetailUrl}/ops/application-review`;
  const applicationReviewUrl = `/tracks/${trackId}/application-review`;
  const { MORTGAGE_LIENS } = APP_REVIEW_ANCHORS;
  const applicationReviewPageUrls = {
    // The ARP homepage is SPA but the create, edit, and history pages are currently MPA
    applicationReviewUrl: applicationReviewUrl,
    createFloodReportUrl: `${baseApplicationReviewPath}/flood-report/`,
    liensUrl: applicationReviewUrl + MORTGAGE_LIENS,
    editARPSummaryUrl: `${baseApplicationReviewPath}/ard-summary/edit/`,
    editBackgroundChecksUrl: `${baseApplicationReviewPath}/background-checks/`,
    editHomeValuationStatusUrl: `${baseApplicationReviewPath}/home-valuation-statuses/`,
    editInsurancePolicyUrl: `${baseApplicationReviewPath}/insurance-policies/`,
    editInvestmentIncreaseUrl: `${baseApplicationReviewPath}/investment-increase-review/`,
    // editMortgageLiensUrl is deprecated. This link is only used by deprecated controllers.
    editMortgageLiensUrl: `${baseApplicationReviewPath}/liens/`,
    editPropertyReportUrl: `${baseApplicationReviewPath}/property-report/`,
    editTitleMembersUrl: `${baseApplicationReviewPath}/title-members/`,
    historyBackgroundChecksUrl: `${baseApplicationReviewPath}/background-checks/history/`,
    historyFloodReportUrl: `${baseApplicationReviewPath}/flood-report/history/`,
    historyGovernmentIdsUrl: `${baseApplicationReviewPath}/government-ids/history/`,
    historyHomeValuationStatusUrl: `${baseApplicationReviewPath}/home-valuation-statuses/history/`,
    historyInsurancePolicyUrl: `${baseApplicationReviewPath}/insurance-policies-history/`,
    editFloodReportUrl: `${baseApplicationReviewPath}/flood-report/`,
    historyInvestmentIncreaseUrl: `${baseApplicationReviewPath}/investment-increase-review/history/`,
    historyMortgageLiensUrl: `${baseApplicationReviewPath}/liens/history/`,
    historyPropertyReportUrl: `${baseApplicationReviewPath}/property-report/history/`,
    historyTitleMembersUrl: `${baseApplicationReviewPath}/title-members/history/`,
    historyRentalAgreementUrl: `${baseApplicationReviewPath}/rental-agreement/history/`,
    historyHoaUrl: `${baseApplicationReviewPath}/hoa/history/`,
    historyPropertyTaxUrl: `${baseApplicationReviewPath}/property-tax/history/`,
    historyAdverseEventUrl: `${baseApplicationReviewPath}/adverse-event/history/`,
  };
  return applicationReviewPageUrls;
};

export const getTrackDetailsTasksLinks = (trackId, taskId) => {
  return {
    tasksUrl: `/tracks/${trackId}/tasks`,
    taskUrl: `/tracks/${trackId}/tasks/${taskId}`,
  };
};

export const getSalesforceOpportunityUrls = (trackId, opportunityId) => {
  const baseSalesforceOpportunityUrl = `${getPortalsTrackDetailBaseUrl(trackId)}/salesforce/opportunity-record`;
  return {
    editOpportunityNameUrl: `${baseSalesforceOpportunityUrl}/${opportunityId}/edit`,
  };
};
