import { Button, Icon } from '@hometap/htco-components';
import './TaskDetailMultipleFormsList.scss';
import { ActionBlock } from 'components/ActionBlock/ActionBlock';

import { getDocKindElseTaskDefKey } from 'apps/track-details/tasks/trackTasksUtils';
import {
  DELETE_MULTIPLE_FORMS_ACTION,
  getFormInitialDocId,
  getInitialMultipleForms,
  useMultipleForms,
} from './hooks/useMultipleForms';
import { TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT } from 'apps/track-details/tasks/data/constants/taskSpecificMultipleFormsContent';
import { DELETE_MULTI_DOC_ACTION, useMultiDocSelector } from './hooks/useMultiDocSelector';

/**
 * Renders a list of multiple forms for a task detail.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.task - The task object.
 * @param {Array} [props.dataWithDocuments=[]] - The data with documents array.
 * @param {Array} [props.itemsWithDocumentIds=[]] - The document IDs to exclude array.
 * @param {Array} [props.documents=[]] - The documents array.
 * @returns {JSX.Element} The rendered component.
 */
export const TaskDetailMultipleFormsList = ({
  task,
  dataWithDocuments = [],
  itemsWithDocumentIds = [],
  documents = [],
}) => {
  const taskId = task.identifier;
  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);

  const { getAvailableDocOptions, changeDocSelections, remainingDocIds } = useMultiDocSelector({
    documents: documents,
    itemsWithDocumentIds,
    docsCanLinkToMultipleItems: false,
  });

  // the form is deleted - the document is deleted documents = multiple forms
  const { multipleForms, updateMultipleForm } = useMultipleForms(
    (() => getInitialMultipleForms(dataWithDocuments, taskId, remainingDocIds))(),
  );

  const {
    content: MultipleSpecificForm,
    actionDescription = '',
    actionName,
  } = TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT[docKindElseTaskDefKey] || {};

  const showAddFormButton =
    remainingDocIds?.length >= 1 &&
    multipleForms.filter(({ isNewForm }) => isNewForm).length <
      documents.length - multipleForms.filter(form => !form.isDeleted && !form.isNewForm).length;

  const multipleFormsCardList = multipleForms.map(item => {
    const formId = item.identifier;

    const onDelete = documentId => {
      if (documentId) {
        changeDocSelections(formId, documentId, DELETE_MULTI_DOC_ACTION);
      }
      updateMultipleForm({ id: formId, action: DELETE_MULTIPLE_FORMS_ACTION });
    };

    return (
      <MultipleSpecificForm
        key={formId}
        updateAvailableDocOptions={documentId => changeDocSelections(formId, documentId)}
        availableDocOptions={getAvailableDocOptions(formId)}
        task={task}
        data={item}
        onDelete={onDelete}
        canDelete={true}
      />
    );
  });

  return (
    <div>
      {!!multipleFormsCardList?.length && (
        <div className="TaskDetailMultipleFormsCardList">{multipleFormsCardList}</div>
      )}

      {showAddFormButton && (
        <ActionBlock
          icon={<Icon name="icon-alert" />}
          description={actionDescription}
          action={
            <Button
              theme="link"
              onClick={() =>
                updateMultipleForm({
                  initialDocId: getFormInitialDocId(remainingDocIds),
                })
              }
            >
              {actionName}
            </Button>
          }
          className="TaskDetailMultipleFormsCardActionBlock"
        />
      )}
    </div>
  );
};
