import { Button, Icon, IconButton, Loader, Tooltip } from '@hometap/htco-components';
import { useState } from 'react';
import { showNotification } from 'utils/toasts';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { DOCUMENT_SET_BATCH_STATUSES } from '../../data/constants';
import './DocumentBatchProgress.scss';

const DocumentBatchProgress = ({
  successful,
  failure,
  totalRequested,
  batchStatus,
  failedReason,
  onDownload,
  document,
  onCancel,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const doneDocSets = successful + failure;
  const progressPercent = (doneDocSets / totalRequested) * 100;
  const progressString = `${successful}/${totalRequested}`; // failed docs sets are not included in progress string

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await onDownload(document);
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'There was an error downloading this document set batch.',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  if (isDownloading) {
    return (
      <span className="flex items-center gap-[8px]">
        Downloading
        <Loader size={'small'} type={'dot-pulse'} className={'m-0'} />
      </span>
    );
  }

  return (
    <>
      {batchStatus === DOCUMENT_SET_BATCH_STATUSES.IN_PROGRESS && (
        <>
          <span
            className="DocumentBatchProgressCancelBatchButton"
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? (
              <Button
                theme="link"
                className="DocumentBatchProgressCancelBatchButton"
                icon="icon-cancel"
                size="small"
                onClick={() => onCancel(document?.bpmProcessId)}
              >
                Cancel batch
              </Button>
            ) : (
              <div className="DocumentBatchProgressInProgressNonHoverContainer">
                {failure > 0 && <span className="DocumentBatchProgressCancelFailedString">{failure} failed</span>}
                <span>{progressString}</span>
                <ProgressCircle percentage={progressPercent} text={progressString} />
              </div>
            )}
          </span>
        </>
      )}

      {batchStatus === DOCUMENT_SET_BATCH_STATUSES.SUCCESS && (
        <>
          {failure > 0 && <span className="DocumentBatchProgressCancelFailedString">{failure} failed</span>}
          <span>
            {successful}/{totalRequested}
          </span>
          <IconButton icon="download" onClick={handleDownload} />
        </>
      )}

      {batchStatus === DOCUMENT_SET_BATCH_STATUSES.FAILURE && (
        <div className="DocumentBatchProgressFailureContainer">
          <span>Failed</span>
          <Tooltip content={failedReason ?? ''}>
            <Icon name="exclamation-circle" />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DocumentBatchProgress;
