import apiWithAuth from 'utils/api';

export const createSeniorLienRequest = async ({ track_id, process_instance_id, input_data, data, notes }) => {
  return await apiWithAuth.v2.post(
    `/tracks/${track_id}/data-entries`,
    {
      kind: 'senior_lien',
      version: 1,
      track_id,
      process_instance_id,
      input_data,
      data,
      notes,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getSeniorLiensRequest = async ({ track_id }) => {
  return await apiWithAuth.v2.get(`/tracks/${track_id}/data-entries`);
};
