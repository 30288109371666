import React from 'react';
import cx from 'classnames';
import { Icon, Tooltip } from '@hometap/htco-components';

import { getRiskBandDetailsPortalsUrl } from 'apps/track-details/utils/trackDetailsLinks';

import './RiskBandDisplay.scss';

const ConditionalOpsPermissionsRiskDetailsTooltip = ({
  hasOpsPermissions,
  riskBand = {},
  children,
  riskBandDetailsLink,
}) =>
  hasOpsPermissions ? (
    <Tooltip content={riskBand?.isFake ? 'View FAKE request details' : 'View request details'} isClickableLink>
      <a href={riskBandDetailsLink} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </Tooltip>
  ) : (
    children
  );

const RiskBandDisplay = ({ riskBand, hasOpsPermissions, trackId }) => {
  let display;

  const riskBandDetailsLink = getRiskBandDetailsPortalsUrl(trackId, riskBand?.identifier);

  if (!riskBand) {
    display = 'None';
  } else if (riskBand.errorMessage) {
    display = (
      <ConditionalOpsPermissionsRiskDetailsTooltip
        riskBand={riskBand}
        hasOpsPermissions={hasOpsPermissions}
        riskBandDetailsLink={riskBandDetailsLink}
      >
        <span className="RiskBandDisplayContent">{riskBand.isNoDataResponse ? 'No data' : 'Error'}</span>
      </ConditionalOpsPermissionsRiskDetailsTooltip>
    );
  } else {
    display = (
      <ConditionalOpsPermissionsRiskDetailsTooltip
        riskBand={riskBand}
        hasOpsPermissions={hasOpsPermissions}
        riskBandDetailsLink={riskBandDetailsLink}
      >
        <span className="RiskBandDisplayContent">
          {riskBand.value} {riskBand.wasExperianNameExactMatch ? '- Name Match' : '- Name Mismatch'}
          {!riskBand.wasExperianNameExactMatch && <Icon className="RiskBandDisplayErrorIcon" name="error" />}
        </span>
      </ConditionalOpsPermissionsRiskDetailsTooltip>
    );
  }

  return <span className={cx('RiskBandDisplayContainer', { isFake: riskBand?.isFake })}>{display}</span>;
};

export default RiskBandDisplay;
