import React from 'react';
import './ReadOnlyFieldGroup.scss'; // Assuming SCSS is used

/**
 * @typedef ReadOnlyFieldGroupProps
 * @type {object}
 * @property {Array<{title: string, content: string}>} items - Array of objects containing title and content for each field
 */

/**
 * Displays a group of read-only fields in a flex container.
 *
 * @param {ReadOnlyFieldGroupProps} props - The props for the component.
 * @returns {JSX.Element} - The JSX element for the read-only field group.
 */
const ReadOnlyFieldGroup = ({ items }) => {
  return (
    <div className="ReadOnlyFieldGroupContainer">
      {items.map((item, index) => (
        <div className="ReadOnlyFieldGroupColumn" key={index}>
          <strong>{item.title}</strong>
          <div>{item.linkComponent || item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default ReadOnlyFieldGroup;
