import React from 'react';
import { useParams } from 'react-router';

import { booleanOptionsWithUnknown, booleanOrNoneToValue } from 'utils/boolean';
import { CurrencyInput, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';

export const TitleMembersController = ({ track, editDisabled }) => {
  const { trackId } = useParams();

  const readOnly = true;
  const { applicants } = track;

  const { historyTitleMembersUrl, editTitleMembersUrl } = getApplicationReviewPageUrls(trackId);
  const navToEditView = () => (window.location.href = editTitleMembersUrl);
  const doNothingOnChange = () => {};

  const applicantsHaveTitleMemberData = applicants => {
    return applicants?.some(applicant => applicant.titleMemberReview);
  };
  const convertOnTitleValue = isOnTitle => {
    return booleanOrNoneToValue(isOnTitle);
  };

  const createRiskBandDisplay = (riskBand, isNameMatch) => {
    return riskBand ? `${riskBand} - ${isNameMatch ? 'Name Match' : 'Name Mismatch'}` : 'None';
  };

  return (
    <ApplicationReviewSection
      anchorId="title-members-section"
      sectionTitle={'Title members'}
      historyUrl={historyTitleMembersUrl}
      visibleSummary={false}
      onEdit={editDisabled ? null : navToEditView}
    >
      {!applicantsHaveTitleMemberData(applicants) ? (
        <div>There is no title member data</div>
      ) : (
        <>
          {applicants.map(({ fullName, totalAnnualIncome, riskBand, titleMemberReview }, i) => {
            const { isOnTitle } = titleMemberReview || {};
            const { value, wasExperianNameExactMatch } = riskBand || {};
            const riskBandDisplay = createRiskBandDisplay(value, wasExperianNameExactMatch);
            return (
              <div className="detailItemContainer" key={i}>
                <h5>{fullName}</h5>
                <MuiSelect
                  label="On Title"
                  value={convertOnTitleValue(isOnTitle)}
                  options={booleanOptionsWithUnknown}
                  disabled={readOnly}
                  theme="outlined"
                  onChange={doNothingOnChange}
                  width="100%"
                />

                <div className={'TwoItemFormRow'}>
                  <CurrencyInput
                    label="Total Annual Income"
                    value={totalAnnualIncome || ''}
                    extendedMaskProps={{ scale: 0 }}
                    disabled
                  />
                  <MuiTextInput label="Risk Band" value={riskBandDisplay} disabled theme="outlined" width="100%" />
                </div>
              </div>
            );
          })}
        </>
      )}
    </ApplicationReviewSection>
  );
};

export default TitleMembersController;
