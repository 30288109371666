import React from 'react';
import { Button } from '@hometap/htco-components';
import FilterMultiSelect from 'components/FilterMultiSelect/FilterMultiSelect';
import { omitBy, isEmpty } from 'lodash';
import { mapOptions, sortField } from 'utils/data';
import useDocumentKinds from '../../hooks/useDocumentKinds';
import './DocumentFilters.scss';

/**
 * Filters documents in documents page by document type
 * @param appliedFilters
 * @param onApplyFilter - function that changes
 */
const DocumentFilters = ({ appliedFilters = {}, onApplyFilter }) => {
  const hasAppliedFilters = Object.keys(omitBy(appliedFilters, isEmpty)).length > 0;
  const { documentKindsDropdownOptionsSorted } = useDocumentKinds();

  const handleClear = () => {
    onApplyFilter();
  };

  const renderChangeProps = name => {
    return {
      name,
      value: appliedFilters[name],
      onChange: (value, name) => {
        onApplyFilter({
          ...appliedFilters,
          [name]: value,
        });
      },
    };
  };

  return (
    <div className="DocumentFilters">
      <div className="DocumentFiltersDropdowns">
        <FilterMultiSelect
          label="Document Type"
          options={mapOptions(documentKindsDropdownOptionsSorted, 'label', 'value', sortField('label'))}
          {...renderChangeProps('kinds')}
        />
        {hasAppliedFilters && (
          <Button
            className="DocumentFiltersButton"
            data-testid="documentFilter-clear"
            theme="text"
            size="small"
            onClick={handleClear}
          >
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default DocumentFilters;
