import { gql } from '@apollo/client';

export const UPSERT_ADVERSE_EVENT = gql`
  mutation UpsertAdverseEvent(
    $trackId: IdentifierType!
    $kind: AdverseEventKinds!
    $personIds: [IdentifierType!]!
    $adverseEventId: IdentifierType
    $hasNotBeenResolved: Boolean
    $resolutionDate: Date
    $isForSubjectProperty: Boolean
    $bankruptcyChapter: String
    $bankruptcyOrder: String
    $notes: String
  ) {
    upsertAdverseEvent(
      trackId: $trackId
      kind: $kind
      personIds: $personIds
      adverseEventId: $adverseEventId
      hasNotBeenResolved: $hasNotBeenResolved
      resolutionDate: $resolutionDate
      isForSubjectProperty: $isForSubjectProperty
      bankruptcyChapter: $bankruptcyChapter
      bankruptcyOrder: $bankruptcyOrder
      notes: $notes
    ) {
      adverseEventId
    }
  }
`;
