import '@hometap/htco-components/index.css';
import './styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';

export const IDLE_WARNING_MODAL_CONTAINER = 'IdleWarningModalContainer';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
      <div id={IDLE_WARNING_MODAL_CONTAINER} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
