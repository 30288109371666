import React from 'react';
import { TextInput, Button } from '@hometap/htco-components';
import './SearchBox.scss';

const SearchBox = ({ search, setSearch, appliedFilters, onApplyFilter, inputTestId, buttonTestId, placeholder }) => {
  const handleSearchEnter = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    onApplyFilter({
      ...appliedFilters,
      keyword: search,
    });
  };

  return (
    <>
      <TextInput
        className="SearchBoxInput"
        data-testid={inputTestId}
        placeholder={placeholder}
        maxLength={100}
        value={search}
        onChange={setSearch}
        onKeyUp={handleSearchEnter}
      />
      <Button className="SearchBoxButton" data-testid={buttonTestId} size="small" onClick={handleSearch}>
        Search
      </Button>
    </>
  );
};

export default SearchBox;
