import React from 'react';
import cx from 'classnames';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { useWindowSize, MenuDropdown, MenuDropdownItem } from '@hometap/htco-components';
import useCurrentUser from 'hooks/useCurrentUser';
import { createLinks } from './TrackNavUtils';
import { TRACK_DETAILS_FULL_PATH } from 'AppRouter';

import './TrackNav.scss';

const isSPALink = to => {
  const match = matchPath(TRACK_DETAILS_FULL_PATH, to);
  return !!match;
};

const TrackNav = ({ track, isContractor }) => {
  const { isScreenSize } = useWindowSize({ delay: 250 });
  const { pathname } = useLocation();
  const { isOnlyApplicationSpecialist } = useCurrentUser();
  const canReadApplicationReview = track?.applicationReviewPermissions?.canRead;

  const links = createLinks(track, isOnlyApplicationSpecialist, isContractor, canReadApplicationReview);
  const navLinks = links.filter(link => !isScreenSize(link.collapseAtBreakpoint));
  const moreLinks = links.filter(link => isScreenSize(link.collapseAtBreakpoint));

  const isActiveRouteInMoreLinks = () => moreLinks.map(link => link.to).includes(pathname);
  const createSpaOrMpaLink = ({ name, to, alsoActiveWhen, disabled }) => {
    if (isSPALink(to)) {
      return (
        <NavLink
          key={name}
          to={disabled ? '' : to}
          className={({ isActive }) => cx('TrackNavLink', { isActive, disabled })}
        >
          {name}
        </NavLink>
      );
    }
    if (disabled) {
      return (
        <span
          key={name}
          className={cx('TrackNavLink', { isActive: alsoActiveWhen && alsoActiveWhen(pathname), disabled })}
        >
          {name}
        </span>
      );
    }
    return (
      <a key={name} href={to} className={cx('TrackNavLink', { isActive: alsoActiveWhen && alsoActiveWhen(pathname) })}>
        {name}
      </a>
    );
  };

  const createSpaOrMpaDropdownLink = ({ name, to, disabled }) =>
    isSPALink(to) ? (
      <NavLink key={name} to={disabled ? null : to} className={cx('TrackNavLink htco-MenuDropdownItem', { disabled })}>
        {name}
      </NavLink>
    ) : (
      <MenuDropdownItem key={name} href={disabled ? null : to} className={cx('TrackNavLink', { disabled })}>
        {name}
      </MenuDropdownItem>
    );

  return (
    <nav
      className="TrackNav"
      style={{ justifyContent: isContractor ? 'flex-start' : 'space-between', ...(isContractor ? { gap: 24 } : {}) }}
    >
      {navLinks.map(createSpaOrMpaLink)}
      {moreLinks.length > 0 && (
        <MenuDropdown
          trigger={<div className={cx('TrackNavLink', { isActive: isActiveRouteInMoreLinks() })}>More</div>}
        >
          {moreLinks.map(createSpaOrMpaDropdownLink)}
        </MenuDropdown>
      )}
    </nav>
  );
};

export default TrackNav;
