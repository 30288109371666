import React, { useState } from 'react';
import { Button } from '@hometap/htco-components';
import FilterMultiSelect from 'components/FilterMultiSelect/FilterMultiSelect';
import SearchBox from 'components/SearchBox/SearchBox';
import { omitBy, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_FILTER_OPTIONS } from '../queries';
import env from 'utils/env';
import TrackCreation from './TrackCreation';
import { mapOptions, sortField } from 'utils/data';

const TrackFilters = ({ appliedFilters = {}, onApplyFilter }) => {
  const [search, setSearch] = useState(appliedFilters.keyword);
  const hasAppliedFilters = Object.keys(omitBy(appliedFilters, isEmpty)).length > 0;

  const {
    loading,
    error,
    data = {},
  } = useQuery(GET_FILTER_OPTIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const handleClear = () => {
    setSearch();
    onApplyFilter();
  };

  const renderChangeProps = name => {
    return {
      name,
      loading,
      noOptionsMessage: error ? () => <span>Error loading options</span> : undefined,
      value: appliedFilters[name],
      onChange: (value, name) => {
        onApplyFilter({
          ...appliedFilters,
          [name]: value,
        });
      },
    };
  };

  return (
    <div className="TrackFilters">
      <SearchBox
        search={search}
        setSearch={setSearch}
        appliedFilters={appliedFilters}
        onApplyFilter={onApplyFilter}
        inputTestId="trackfilter-search-input"
        buttonTestId="trackfilter-search-btn"
        placeholder="Search by name, email, address, or friendly ID"
      />
      {!env.isProd() && !env.isStage() && (
        <TrackCreation stages={data.stages} investmentManagers={data.investmentManagers} />
      )}
      <div className="TrackFiltersDropdowns">
        <FilterMultiSelect
          label="App Specialist"
          options={mapOptions(data.appSpecialists, 'fullNameShort', 'identifier', sortField('fullNameShort'))}
          {...renderChangeProps('appSpecialists')}
        />
        <FilterMultiSelect
          label="Investment Manager"
          options={mapOptions(data.investmentManagers, 'fullNameShort', 'identifier', sortField('fullNameShort'))}
          {...renderChangeProps('investmentManagers')}
        />
        <FilterMultiSelect
          label="Underwriter"
          options={mapOptions(data.underwriters, 'fullNameShort', 'identifier', sortField('fullNameShort'))}
          {...renderChangeProps('underwriters')}
        />
        <FilterMultiSelect
          label="Stage"
          options={mapOptions(data.stages, 'label', 'stageClassName')}
          {...renderChangeProps('stage')}
        />
        <FilterMultiSelect
          label="State"
          options={mapOptions(data.states, 'acronym', 'acronym')}
          {...renderChangeProps('state')}
        />

        {hasAppliedFilters && (
          <Button
            className="TrackFiltersButton"
            data-testid="trackfilter-clear"
            theme="text"
            size="small"
            onClick={handleClear}
          >
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default TrackFilters;
