import React from 'react';
import { LoadingPlaceholder } from '@hometap/htco-components';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';

import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import { GET_FLOOD_ZONE_DATA } from './getFloodZoneData';

const FLOOD_ZONE_PDF_LINK = 'https://snmapmod.snco.us/fmm/document/fema-flood-zone-definitions.pdf';
const FEMA_LINK = 'https://msc.fema.gov/portal/home';

const FloodZoneCheck = () => {
  const { data = {}, loading, trackId } = useSpecificContentQuery(GET_FLOOD_ZONE_DATA);
  const { track } = data;

  const { editFloodReportUrl } = getApplicationReviewPageUrls(trackId);

  return (
    <div className="TaskDetailContent">
      <TaskDetailContentSectionWrapper>
        <b>Flood Zone:</b>{' '}
        <LoadingPlaceholder isLoading={loading} isInline>
          {({ loadingClass }) => <span className={loadingClass}>{track?.floodZone}</span>}
        </LoadingPlaceholder>
        <a href={FLOOD_ZONE_PDF_LINK} rel="noopener noreferrer" target="_blank">
          &nbsp; (Flood Zone Guide)
        </a>
        {!track?.floodZone && !loading && (
          <p>
            View/Download Flood Zone Report:{' '}
            <a href={FEMA_LINK} target="_blank" rel="noopener noreferrer">
              FEMA Map
            </a>
          </p>
        )}
        <ul className="TaskDetailList">
          <li>
            Use the information above to <a href={editFloodReportUrl}>fill out the ARD for Flood Zone</a>
          </li>
          <li>If property is in a flood zone, request and review flood insurance policy</li>
          <li>If property type is 'manufactured home' in a flood zone, DQ</li>
        </ul>
      </TaskDetailContentSectionWrapper>
    </div>
  );
};

export default FloodZoneCheck;
