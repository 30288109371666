export const booleanOrNoneToValue = (bool, trueValue = 'yes', falseValue = 'no', noneValue = 'unknown') => {
  if (bool === false) {
    return falseValue;
  }
  return bool ? trueValue : noneValue;
};

export const booleanOrNoneToLabel = (bool, trueLabel = 'Yes', falseLabel = 'No', noneLabel = 'Unknown') => {
  return booleanOrNoneToValue(bool, trueLabel, falseLabel, noneLabel);
};

export const booleanOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
export const booleanOptionsWithUnknown = [...booleanOptions, { label: 'Unknown', value: 'unknown' }];
