import { getVariableValue } from 'apps/track-details/tasks/components/TaskList/TaskListUtils';
import { GovernmentIdExpiringAlert } from '../Alert/GovernmentIdExpiringAlert';
import { getExpirationDateData } from 'utils/dates';
import { InsuranceExpiringAlert } from '../Alert/InsuranceExpiringAlert';
import { getIsOpenSomeTask, POLICY_DOC_KINDS } from 'apps/track-details/utils/insuranceTasks';

export const ApplicationReviewExpiringAlerts = ({ track }) => {
  const { identifier, applicants, insurancePolicyReviews, homeownerTasks, internalTasks } = track;
  const allTasks = homeownerTasks && internalTasks ? homeownerTasks.concat(internalTasks) : [];

  return (
    <>
      {applicants &&
        applicants.map((applicant, index) => {
          const { governmentId } = applicant;
          const personId = applicant?.person?.identifier;
          const hasOpenGovernmentIdTask = allTasks?.some(task => {
            const personIdVariable = task.variables ? getVariableValue(task, 'person_id') : undefined;
            if (!personIdVariable) return false;
            return personId === personIdVariable && getVariableValue(task, 'doc_kind') === 'gov_id' && task.isOpen;
          });
          const expirationDateData = governmentId?.expirationDate && getExpirationDateData(governmentId.expirationDate);
          if (expirationDateData && expirationDateData.isExpiring && !hasOpenGovernmentIdTask)
            return (
              <GovernmentIdExpiringAlert
                key={`applicant-${index}`}
                trackId={identifier}
                applicant={applicant}
                expiresIn={expirationDateData.expiresIn}
              />
            );
          return undefined;
        })}
      {insurancePolicyReviews &&
        insurancePolicyReviews.map((policyReview, index) => {
          const { kind, renewalDate } = policyReview;
          const hasOpenTaskKind = getIsOpenSomeTask(allTasks, POLICY_DOC_KINDS[kind]);
          const renewalDateData = getExpirationDateData(renewalDate);
          if (renewalDateData.isExpiring && !hasOpenTaskKind) {
            return (
              <InsuranceExpiringAlert
                key={`policy-${index}`}
                trackId={identifier}
                policyReview={policyReview}
                expiresIn={renewalDateData.expiresIn}
              />
            );
          }
          return undefined;
        })}
    </>
  );
};
