import { gql } from '@apollo/client';

export const COMPLETE_DOC_REVIEW_TASK = gql`
  mutation CompleteDocReviewTask(
    $taskId: IdentifierType!
    $resolution: TaskResolutionEnum!
    $todosToCreate: [CreateHomeownerTaskInput]
    $isValidDoc: Boolean
    $todoNotes: String
  ) {
    completeDocReviewTask(
      taskId: $taskId
      resolution: $resolution
      todosToCreate: $todosToCreate
      isValidDoc: $isValidDoc
      todoNotes: $todoNotes
    ) {
      ok
    }
  }
`;
