import React from 'react';
import { Icon } from '@hometap/htco-components';
import cx from 'classnames';
import './PinButton.scss';

/**
 *
 * @param {string|undefined|null} className
 * @param {string(['sm', 'lg', '2x', '3x', '4x', '5x'])} size
 * @param {boolean} pinned
 * @param {function} onClick
 * @returns
 */
export const PinButton = ({ className, size = 'lg', pinned, onClick, ...props }) => {
  return (
    <button className={cx('PinButton', className)} onClick={onClick}>
      <Icon {...props} size={size} name={pinned ? 'icon-pin-fill' : 'icon-pin'} />
    </button>
  );
};
