import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { MuiTextInput, useForm } from '@hometap/htco-components';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useSpecificContentQuery,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { UPSERT_HOA } from 'apps/track-details/tasks/data/mutations';
import { getHomeownerTodoReason } from 'apps/track-details/todos/todoUtils';
import { GET_HOA_REVIEW } from './getHoaReview';
import { isEmpty } from 'lodash';

/**
 * @typedef HoaDocReviewParams
 * @type {object}
 */
const HoaDocReview = ({ task }) => {
  const { trackId } = useParams();
  const { registerField, updateFormData, formData = {}, errors } = useForm({});
  const { isValid, reason } = formData;
  const taskId = task.identifier;
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { data: dataFromQuery } = useSpecificContentQuery(GET_HOA_REVIEW);
  const { user } = useCurrentUser();

  const tooltipContent = (
    <div>
      A valid HOA document means that:
      <br />
      1. HOA name on Application matches name on HOA document
      <br />
      2. HOA contact on Application matches name on HOA document
      <br />
    </div>
  );

  const invalidInformationText =
    'Due to an invalid document, a request for an updated HOA Declaration of Trust or HOA Organizational Document will be sent when this task is marked as complete.';

  const { complete, saving, initialFormData } = useHoaDocReviewForm({ trackId, data: dataFromQuery });
  const isValidForm = useMemo(() => {
    return getIsValidHoaDocReviewTaskForm(user, formData, errors);
  }, [user, formData, errors]);

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      formData,
      updateFormData,
      complete,
      saving,
      initialFormData,
      isValidForm,
    });
  }, [
    formData,
    updateFormData,
    taskId,
    updateSpecificTaskById,
    trackId,
    complete,
    saving,
    initialFormData,
    isValidForm,
  ]);

  return (
    <TaskDetailContentSectionReview
      label="Is this a valid HOA Declaration of Trust or HOA Organizational Document?"
      isValid={isValid}
      registerField={registerField}
      tooltipContent={tooltipContent}
      invalidInformationText={invalidInformationText}
      taskKind={task.kind}
      isCompleted={isCompleted}
      reason={reason}
    >
      <MuiTextInput
        label="HOA name"
        theme="outlined"
        width="100%"
        maxLength={100}
        required
        isWithAsterisk={false}
        {...registerField('hoaName')}
      />
    </TaskDetailContentSectionReview>
  );
};

const useHoaDocReviewForm = ({ trackId, data }) => {
  const initialFormData = useMemo(
    () => ({
      hoaName: data?.track?.hoaReview?.hoaName ?? '',
    }),
    [data],
  );

  const [mutation, { loading: saving }] = useMutation(UPSERT_HOA);
  const complete = useCallback(
    async ({ formData }) => {
      const { isValid, hoaName } = formData;
      if (isValid === 'false') return;

      const variables = { trackId, hoaName };
      await mutation({ variables });
    },
    [trackId, mutation],
  );

  return {
    complete,
    saving,
    initialFormData,
  };
};

export const getIsValidHoaDocReviewTaskForm = (user = {}, formData = {}, errors) => {
  const { isValid, hoaName } = formData;
  const homeownerTodoReason = getHomeownerTodoReason(formData);
  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }

  return !!(isValid === 'true' && hoaName && isEmpty(errors));
};

export const HOA_DOC_DEMO_DATA = {
  isValid: 'true',
  hoaName: 'Homeowners Association',
};

export default HoaDocReview;
