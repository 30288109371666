import { Icon } from '@hometap/htco-components';
import cx from 'classnames';

import './TaskDrawer.scss';

export const TaskDrawerButton = ({ style, onClick, className }) => {
  return (
    <button style={style} className={className} onClick={onClick}>
      <Icon name="caret-up" className={cx('TaskDrawerControlButtonIcon')} />
    </button>
  );
};
