import React, { useEffect, useCallback, useRef } from 'react';
import cx from 'classnames';
import { Button, FormError, MuiSelect, Paper, TextInput } from '@hometap/htco-components';
import { resolvePath, useLocation, useNavigate } from 'react-router-dom';

import { formatSingularOrPluralTodoCopy, generateHomeownerSelectedTodoCount } from '../todoUtils';
import TodosRemoveTodoButton from './TodosRemoveTodoButton';
import useIsBodyOverflow from 'hooks/useIsBodyOverflow';

import { TODO_FORM_DATA_NOTES_DROPDOWN_PREFIX, TODO_FORM_DATA_NOTES_TEXTAREA_PREFIX } from '../data/constants/todoForm';
import { TODO_NOTES_OTHER } from 'apps/track-details/data/constants/todoForm';
import TrackDetailsPadding from 'apps/track-details/TrackDetailsPadding';

import { getTodoNotesOptions } from '../../utils/trackDetailsTodo';
import useConfigurations from 'hooks/useConfigurations';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import useCurrentUser from 'hooks/useCurrentUser';

/**
 * @typedef TodosAssignmentParams
 * @type {import('../hooks/useHomeownerTodoSelection').UseHomeownerTodoSelectionReturn}
 * @property {} createTodosAsyncState
 * @property {} formProps
 */

/**
 *
 * @param {TodosAssignmentParams} params
 * @returns
 */
const TodosAssignment = ({
  selectedTodos,
  setSelectedTodo,
  deselectTodo,
  homeownerFullName,
  createTodosAsyncState,
  formProps,
}) => {
  const { formData, errors, registerField, onNoteChange, onSubmit } = formProps;

  const todosListRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const todosPath = resolvePath('..', location.pathname);
  const isBodyOverflow = useIsBodyOverflow(todosListRef);
  const { taskKinds } = useConfigurations();
  const { user } = useCurrentUser();

  const { called: createTodosCalled, loading: createTodosLoading, error: createTodosError } = createTodosAsyncState;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    if (selectedTodos.length === 0) {
      return goBack();
    }
  }, [goBack, selectedTodos.length]);

  useEffect(() => {
    const todosHaveSuccessfullyBeenCreated = createTodosCalled && !createTodosError && !createTodosLoading;

    if (todosHaveSuccessfullyBeenCreated) {
      setSelectedTodo([]);
      navigate(todosPath, { replace: true });
    }
  }, [createTodosCalled, createTodosError, createTodosLoading, setSelectedTodo, navigate, todosPath]);

  return (
    <TrackDetailsPadding
      ref={todosListRef}
      className={cx('TodosAssignmentList', { isLastChildMarginApplied: isBodyOverflow })}
    >
      {createTodosError && (
        <FormError
          standalone
          error="Something went wrong while assigning to-dos to this homeowner. No to-dos have been assigned."
        />
      )}
      <Paper theme="plain" pad={{ top: 2, bottom: 2 }} className="TodosAssignmentHomeownerTodoCount">
        <h4 className="TodosAssignmentHomeownerTodoCountHeading">
          {generateHomeownerSelectedTodoCount(selectedTodos, homeownerFullName)}
        </h4>
      </Paper>
      <form onSubmit={onSubmit}>
        {selectedTodos.map((todo, index) => {
          const { displayLabel, kind, applicant } = todo;
          const { identifier: personId } = applicant?.person ?? {};
          const todoDropdownFormDataField = TODO_FORM_DATA_NOTES_DROPDOWN_PREFIX + kind + personId;
          const todoTextareaFormDataField = TODO_FORM_DATA_NOTES_TEXTAREA_PREFIX + kind + personId;
          const isOtherTodoOrOtherNotes =
            formData[todoDropdownFormDataField] === TODO_NOTES_OTHER || kind === taskKinds.OTHER;
          const todoNotesOptions = getTodoNotesOptions({
            kind,
            startOptions: [{ label: '-- None --', value: '' }],
            taskKinds: taskKinds,
          });
          return (
            <div className="TodosAssignmentItem" key={index}>
              <div className="TodosAssignmentItemRow">
                {displayLabel}
                <TodosRemoveTodoButton removeTodo={() => deselectTodo(todo)} />
              </div>
              {user?.rolloutFlags?.includes('hide_todo_reasons_dropdown') ? (
                <TextInput
                  type="textarea"
                  rows="2"
                  className="TodosAssignmentReasonTextarea"
                  value={formData[todoTextareaFormDataField]}
                  label="Please write a brief note to the Homeowner with specific reasoning for your request."
                  {...registerField(todoTextareaFormDataField)}
                  onChange={(value, name, error) => onNoteChange(value, name, error, todo)}
                />
              ) : (
                <>
                  {kind !== taskKinds.OTHER && (
                    <div className="TodosAssignmentReasonSelect" data-testid={`todo-notes-${index}`}>
                      <MuiSelect
                        options={todoNotesOptions}
                        value={formData[todoDropdownFormDataField]}
                        menuPortalTarget={document.body}
                        label="Reason for Request (optional)"
                        theme="outlined"
                        width="100%"
                        {...registerField(todoDropdownFormDataField)}
                        onChange={(value, name, error) => onNoteChange(value, name, error, todo)}
                      />
                    </div>
                  )}
                  {isOtherTodoOrOtherNotes && (
                    <div
                      className="TodosAssignmentReasonTextareaContainer"
                      data-testid={`todo-textarea-notes-${index}`}
                    >
                      <TextInput
                        type="textarea"
                        rows="2"
                        className="TodosAssignmentReasonTextarea"
                        value={formData[todoTextareaFormDataField]}
                        label="Notes (required)"
                        {...registerField(todoTextareaFormDataField)}
                        // This is a required field see comment in the TodosController to see how/why
                        // this doesn't use the "required" prop
                        error={errors[kind + personId]?.show && errors[kind + personId]?.message}
                        onChange={(value, name, error) => onNoteChange(value, name, error, todo)}
                      />
                    </div>
                  )}
                  {kind === taskKinds.UPLOAD_OTHER_INSURANCE && (
                    <div className="TodosAssignmentOtherInsuranceInformation">
                      <DetailInformationBlock text="Please select “Other” as your reason, and use the Note field to inform the Homeowner about which policy you are requesting an update to." />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
        <div className={cx('TodosAssignmentBottomButtonRow', { isBoxShadowApplied: isBodyOverflow })}>
          <Button theme="secondary" label="Go back" onClick={goBack} disabled={createTodosAsyncState.loading} />
          <Button
            label={`Assign ${formatSingularOrPluralTodoCopy(selectedTodos)}`}
            disabled={!selectedTodos.length}
            type="submit"
            loading={createTodosAsyncState.loading}
          />
        </div>
      </form>
    </TrackDetailsPadding>
  );
};

export default TodosAssignment;
