import cx from 'classnames';
import './FundItem.scss';

const FundItem = ({ label, fundAmount, percent, type }) => {
  if (fundAmount === 0 && type === 'FUND') return null;

  // if there's no tracks on the page, we want to make sure that the total in pipeline still renders $0
  const fundAmountString = fundAmount => {
    if (fundAmount >= 0) {
      return `$${fundAmount?.toLocaleString()}`;
    }
  };

  return (
    <div className="FundItemContainer">
      <div className="FundItemLabel">{label}</div>
      <div className="FundItemNumberContainer">
        <span
          className={cx(
            'FundItemAmount',
            { FundItemAmountBlue: type === 'AVAILABLE' },
            { FundItemAmountRed: type === 'ALLOCATION' && fundAmount !== 0 },
            { FundItemAmountGreen: type === 'ALLOCATION' && fundAmount === 0 },
            { FundItemAmountBlack: type === 'FUND' },
          )}
        >
          {fundAmountString(fundAmount)}
        </span>
        {percent && (
          <span className="FundItemPercent">
            {'('}
            {percent}
            {'%)'}
          </span>
        )}
      </div>
    </div>
  );
};
export default FundItem;
