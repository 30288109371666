import React, { forwardRef } from 'react';
import cx from 'classnames';
import hourglass from 'assets/icons/hourglass.svg';

import { Badge, Tooltip } from '@hometap/htco-components';

import './TaskGroupItem.scss';

import { TASK_STATUSES } from '../../../../../data/constants/taskStatuses';

const { COMPLETED, NOT_APPLICABLE, IN_PROCESS, TRACK_CLOSED, PENDING_TO_DO } = TASK_STATUSES;

const statusBadgeMap = {
  [IN_PROCESS]: <Badge label={IN_PROCESS} theme="primaryBlue100" className="TaskGroupItemBadge" />,
  [NOT_APPLICABLE]: <Badge label={NOT_APPLICABLE} theme="gray" className="TaskGroupItemBadge" />,
  [COMPLETED]: <Badge label={COMPLETED} theme="green" className="TaskGroupItemBadge" />,
  [TRACK_CLOSED]: <Badge label={TRACK_CLOSED} theme="gray" className="TaskGroupItemBadge" />,
  [PENDING_TO_DO]: (
    <Tooltip
      className="TaskGroupItemBadgeTooltipIcon"
      content="Pending Completion of Homeowner To-Do"
      position="bottom-end"
      target="body"
    >
      <img src={hourglass} alt="Hourglass icon" />
    </Tooltip>
  ),
};

/**
 * To render the task_list with TaskGroupItem components
 * wrap it with Accordion Component from https://htco.netlify.app/Elements/Accordion
 * and pass the props
 * @property {String} header - title of the task
 * @property {String} status - status of the task
 * @property {Boolean} isActive - task selected or not
 * @property {Function} onChange - handler to register selected task
 */

const TaskGroupItem = forwardRef(({ header, status, onChange, isActive, disabled }, ref) => {
  return (
    <div
      className={cx('TaskGroupItemWrapper', {
        isActive,
        disabled,
      })}
      onClick={onChange}
      ref={ref}
    >
      <div className="TaskGroupItem">
        <span className="TaskGroupItemHeader">{header}</span>
        {status && statusBadgeMap[status]}
      </div>
    </div>
  );
});

export default TaskGroupItem;
