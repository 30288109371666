import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Modal,
  MuiTextInput,
  useForm,
  MuiSelect,
  useAsync,
  Loader,
} from '@hometap/htco-components';
import { mapOptions } from 'utils/data';
import { CREATE_TRACK_FORM, getFakerUser } from '../utils';
import { createTrack } from 'data/trackRequest';
import useDocumentKinds from 'apps/track-details/Documents/hooks/useDocumentKinds';
import './TrackCreation.scss';
import { showNotification, TOAST_TYPE } from '../../../utils/toasts';
import cx from 'classnames';
import { TrackCreationLinks } from './TrackCreationLinks';
import { hometapEmailValidator } from '../../../utils/validators';

const TrackCreation = data => {
  const useDocumentKindsResults = useDocumentKinds();
  const { registerField, formData, updateFormData, isFormValid } = useForm(CREATE_TRACK_FORM);

  const [modelTrackOpen, setModelTrackOpen] = useState(false);

  const {
    results: createTrackResults,
    execute: executeCreateTrack,
    loading,
    setError,
    setResults,
  } = useAsync(createTrack, {
    onSuccess: () => {
      updateFormData(CREATE_TRACK_FORM);
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'New Track successfully created.',
      });
    },
    onError: error => {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to save changes',
        description: error.response?.data[0]
          ? error.response?.data[0]
          : 'Could not create New Track. Please try again.',
      });
      setError(null);
    },
  });

  const { stage_class_name } = formData;

  const isEarlierStageProcess = ['ApplicationReady', 'ReadyToApply', 'ApplicationInProgress'].includes(
    stage_class_name,
  );

  useEffect(() => {
    updateFormData({ reviewer_id: '', document_kinds: [], co_applicants: [] });
  }, [isEarlierStageProcess, updateFormData]);

  const autoFillCreateTrackInfo = () => {
    const user = getFakerUser();
    updateFormData({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
    });
  };

  const addCoApplicant = () => {
    const user = getFakerUser();
    updateFormData({
      co_applicants: [
        ...formData.co_applicants,
        { email: user.email, first_name: user.firstName, last_name: user.lastName },
      ],
    });
  };

  const onConfirm = () => {
    executeCreateTrack(formData);
  };

  const onCancelModal = () => {
    updateFormData(CREATE_TRACK_FORM);
    if (createTrackResults) {
      setResults(null);
    }
  };

  return (
    <>
      <IconButton
        className="TrackCreation CreateTrackButton"
        icon={'icon-plus'}
        onClick={() => {
          setModelTrackOpen(true);
        }}
        size="normal"
        theme="solid"
        width={null}
      />

      <Modal
        className={cx('CreateTrackModal', { loading })}
        open={modelTrackOpen}
        width={626}
        onClose={() => {
          setModelTrackOpen(false);
          onCancelModal();
        }}
      >
        {loading && <Loader typle="spinner" size="large" overlay />}

        <h2 className="MergeDocumentsModalTitle">Create New Track</h2>

        <div className="TwoItemFormRow">
          <div className="FormItem">
            <MuiTextInput
              label="First Name"
              theme="outlined"
              width="100%"
              showRequiredAsterisk={false}
              autoComplete="nope"
              required
              {...registerField('first_name')}
            />
          </div>
          <div className="FormItem">
            <MuiTextInput
              label="Last Name"
              theme="outlined"
              width="100%"
              showRequiredAsterisk={false}
              autoComplete="nope"
              required
              {...registerField('last_name')}
            />
          </div>
        </div>

        <div className="FormItem">
          <MuiTextInput
            label="Email"
            theme="outlined"
            width="100%"
            required
            showRequiredAsterisk={false}
            autoComplete="nope"
            validator={hometapEmailValidator}
            {...registerField('email')}
          />
        </div>

        <div className="TwoItemFormRow">
          <div className="FormItem">
            <Button onClick={autoFillCreateTrackInfo} theme="primary" width="100%" className="FormRowButton">
              Demo Person
            </Button>
          </div>
          <div className="FormItem">
            <Button
              className="FormRowButton"
              disabled={isEarlierStageProcess}
              theme="primary"
              width="100%"
              onClick={() => addCoApplicant()}
            >
              Add Co-Applicant (<span className="CoApplicantCount">{formData?.co_applicants?.length}</span>)
            </Button>
          </div>
        </div>

        <div className="FormItem">
          <MuiSelect
            label="Select a Stage"
            name="selectStage"
            theme="outlined"
            width="100%"
            required
            options={mapOptions(data.stages, 'label', 'stageClassName')}
            showRequiredAsterisk={false}
            {...registerField('stage_class_name')}
          />
        </div>

        <div className="FormItem">
          <MuiSelect
            label="Select an Investment Manager"
            name="selectInvestmentManager"
            theme="outlined"
            width="100%"
            required={!isEarlierStageProcess}
            disabled={isEarlierStageProcess}
            options={mapOptions(data.investmentManagers, 'fullNameShort', 'identifier')}
            showRequiredAsterisk={false}
            {...registerField('reviewer_id')}
          />
        </div>

        <div className="FormItem">
          <MuiSelect
            label="Document Kinds (optional)"
            name="selectDocumentKind"
            theme="outlined"
            width="100%"
            multiple={true}
            disabled={isEarlierStageProcess}
            options={useDocumentKindsResults.documentKindsDropdownOptionsSorted}
            showRequiredAsterisk={false}
            {...registerField('document_kinds')}
          />
        </div>

        <TrackCreationLinks
          email={createTrackResults?.data?.email}
          trackId={createTrackResults?.data?.track_id}
          friendlyId={createTrackResults?.data?.track_friendly_id}
        />

        <div className="CreateTrackModalButtons">
          <Button theme="text" onClick={onCancelModal}>
            Cancel
          </Button>
          <Button disabled={!isFormValid || loading} onClick={onConfirm}>
            Create Track
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TrackCreation;
