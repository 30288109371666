import { Icon, Tooltip } from '@hometap/htco-components';
import { COMPOSITE_FICO_STATUS } from 'apps/track-details/ApplicationReview/data/constants/compositeFICOStatus';

const SidebarDetailsCompositeFico = ({ compositeFicoData }) => {
  const { status, statusDisplay, value } = compositeFicoData;

  const getFicoDisplay = () => {
    const isFicoCurrentOrFinal = status === COMPOSITE_FICO_STATUS.CURRENT || status === COMPOSITE_FICO_STATUS.FINAL;
    const isFicoUnder500 = value < 500;

    if (!value || isFicoUnder500) {
      return (
        <>
          <strong>{value || 'None'}</strong>
          {' - '}
          <Icon name="cancel" className="FicoIconFail" />
        </>
      );
    } else if (isFicoCurrentOrFinal) {
      return (
        <>
          <strong>{value}</strong>
          {' - '}
          <Icon name="icon-check-mdc" className="FicoIconOK" />
        </>
      );
    }
    // Do not display the "pass/OK" icon if it is an "estimated" FICO because that means we have low
    // confidence in the value. This usually means the Title Members section hasn't been filled out
    // yet, and we must know who is on the title to compute an accurate composite FICO.
    return <strong>{value}</strong>;
  };

  return (
    <div className="SidebarDetailCompositeFico">
      <span className="SidebarDetailContentLabel">{statusDisplay}: </span>
      {compositeFicoData.errorMessage ? (
        <Tooltip content={compositeFicoData.errorMessage}>{getFicoDisplay()}</Tooltip>
      ) : (
        getFicoDisplay()
      )}
    </div>
  );
};

export default SidebarDetailsCompositeFico;
