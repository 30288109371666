import { LoadingPlaceholder } from '@hometap/htco-components';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import React from 'react';
import { GET_TITLE_SEARCH_DATA } from './getTitleSearchData';

import './TitleSearch.scss';

const TitleSearch = () => {
  const { data = {}, loading } = useSpecificContentQuery(GET_TITLE_SEARCH_DATA);

  const { defaultTitlePartner, state: continentalState } = data.track || {};

  return (
    <div className="TaskDetailContent">
      <p>
        <a href="https://app.getguru.com/card/TzpdkG5c/Requesting-Title-Documents">Reference this Guru card</a> to
        determine the title partner for this subject property address
      </p>

      <LoadingPlaceholder isLoading={loading} isInline>
        {({ withLoadingClass }) => (
          <p>
            Default title partner for
            <span className={withLoadingClass('TitleSearchPartnerData')}>
              &nbsp;
              <strong>{continentalState}</strong>: <strong>{defaultTitlePartner}</strong>
            </span>
          </p>
        )}
      </LoadingPlaceholder>
    </div>
  );
};

export default TitleSearch;
