import { useState, useEffect } from 'react';
import { useAsync } from '@hometap/htco-components';
import { getTrackDocuments } from '../documentRequests';
import { getApplicantByPersonId } from '../DocumentsUtils';

/**
 * @typedef UseTrackDocumentsReturn
 * @type {object}
 * @property {import('../documentRequests').Document[]} documents List of documents being fetched
 * @property {boolean} documentsLoading Are the documents being fetched?
 * @property {import('axios').AxiosError?} documentsError An error raise by fetching the documents
 * @property {(trackId: string) => Promise<void>} executeGetTrackDocuments Execute the fetch documents request
 * @property {function} handleFilterDocuments Function to apply filters
 * @property {object} filters Currently applied filters
 */

/** Use sorted track documents fetch request
 * @param {string} trackId Track ID
 * @param {Applicant[]} [applicants]
 * @param {object}  defaultFilters object of filter key and values
 * @returns {UseTrackDocumentsReturn}
 */
export default function useTrackDocuments(trackId, applicants = [], defaultFilters) {
  const [filters, setFilters] = useState(defaultFilters);
  const [documents, setDocuments] = useState([]);

  const {
    results = [],
    loading: documentsLoading,
    error: documentsError,
    execute: executeGetTrackDocuments,
  } = useAsync(getTrackDocuments, {
    immediate: true,
    params: [trackId, filters],
    defaultLoading: true,
  });

  // add additional properties: personId and  applicant to the Document
  useEffect(() => {
    // add additional properties: personId and  applicant to the Document
    if (applicants?.length && results.length) {
      setDocuments(
        results.map((document, index) => {
          const documentWithPerson = document.contexts?.find(({ context_kind }) => context_kind === 'Person');
          if (documentWithPerson) {
            return {
              ...document,
              personId: documentWithPerson.context_id,
              applicant: getApplicantByPersonId(documentWithPerson.context_id, applicants),
            };
          }
          return document;
        }),
      );
    } else if (results.length) {
      setDocuments(results);
    }
  }, [results, applicants]);

  const handleFilterDocuments = newFilters => {
    setFilters(newFilters);
  };

  // put the most recently modified pinned documents at the top
  documents.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at));
  documents.sort((a, b) => b.pinned - a.pinned);

  return {
    documents,
    documentsLoading,
    documentsError,
    executeGetTrackDocuments,
    handleFilterDocuments,
    filters,
  };
}
