import { Loader } from '@hometap/htco-components';

import './LoadingContainer.scss';

const LoadingContainer = ({ isLoading, children }) => {
  return (
    <div className="LoadingContainer">
      {isLoading && <Loader type="dot-pulse" overlay />}
      {children}
    </div>
  );
};

export default LoadingContainer;
