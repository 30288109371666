import React, { useMemo } from 'react';
import { MuiSelect, MuiTextInput } from '@hometap/htco-components';

import './UploadNewOrEditDocumentFields.scss';
import { DOCUMENTS_TYPE } from '../../data/constants';
import cx from 'classnames';
/**
 * @typedef UploadNewOrEditDocumentFieldsProps
 * @type {object}
 * @property {string} docType Document type
 * @property {object} documentTypeFieldProps Props for the select Document Type
 * @property {object} additionalFieldsByDocumentTypeProps Props for the additional fields mapped by Document Type
 * @property {Option[]} [applicantsOptions] The list of the applicants to show in the select
 * @property {Option[]} [documentKindsDropdownOptions] The list of the document types to show in the select
 * @property {string} [className]
 * @property {string} [additionalFieldClassName]
 * @property {string[]} [documentKindsWithPerson]
 */
/**
 * @param {UploadNewOrEditDocumentFieldsProps} params
 * @return {JSX.Element}
 */
export const UploadNewOrEditDocumentFields = ({
  docType,
  documentKindsDropdownOptions,
  applicantsOptions,
  documentTypeFieldProps,
  additionalFieldsByDocumentTypeProps,
  className,
  additionalFieldClassName,
  documentKindsWithPerson = [],
}) => {
  const additionalFields = useMemo(() => {
    const fields = {
      [DOCUMENTS_TYPE.Other]: (
        <div className={cx('AdditionalField', additionalFieldClassName)}>
          <MuiTextInput
            required
            label="Other Document Type"
            autoComplete="off"
            maxLength={100}
            theme="outlined"
            width="100%"
            {...additionalFieldsByDocumentTypeProps[docType]}
          />
        </div>
      ),
    };
    if (documentKindsWithPerson.includes(docType)) {
      fields[docType] = (
        <div className={cx('AdditionalField', additionalFieldClassName)}>
          <MuiSelect
            required
            label="Applicant"
            options={applicantsOptions}
            disabled={!applicantsOptions}
            closeMenuOnSelect={true}
            autoComplete="off"
            theme="outlined"
            width="100%"
            {...additionalFieldsByDocumentTypeProps[docType]}
          />
        </div>
      );
    }
    return fields;
  }, [
    additionalFieldClassName,
    additionalFieldsByDocumentTypeProps,
    applicantsOptions,
    docType,
    documentKindsWithPerson,
  ]);

  return (
    <div className={className}>
      <MuiSelect
        required
        label="Document Type"
        closeMenuOnSelect={true}
        theme="outlined"
        width="100%"
        options={documentKindsDropdownOptions}
        {...documentTypeFieldProps}
      />

      {additionalFields[docType] || null}
    </div>
  );
};
