import AlertError from 'components/AlertError/AlertError';
import { currency } from 'utils/numbers';
import { totalEndingEquityErrorFromCriteria } from '../../utils/calculatorErrors';
import './PaydownError.scss';

const PaydownError = ({ calculatedData, includeCents }) => {
  const totalEndingEquityError = totalEndingEquityErrorFromCriteria(calculatedData?.uwFailedEligibilityCriteria);
  const increaseInPaydownAmount = calculatedData?.increaseInPaydown;
  if (totalEndingEquityError && increaseInPaydownAmount) {
    return (
      <AlertError
        error={
          <div className="PaydownErrorContainer">
            <span>Additional Paydown needed for eligibility.</span>
            <span className="PaydownErrorDataValue">{currency(increaseInPaydownAmount, includeCents)}</span>
          </div>
        }
      />
    );
  }

  return null;
};

export default PaydownError;
