import { useMemo } from 'react';
import { useSectionFormById } from '../../../hooks/useSpecificContentQuery';
import { useForm } from '@hometap/htco-components';

export const useInitialAdverseEventForm = (adverseEvent, initialFormDataFromAdverseEventNode) => {
  const { initialFormData: initialFormDataFromSectionMap, prevFormData } = useSectionFormById(adverseEvent.identifier);
  const initialFormData = useMemo(
    () => initialFormDataFromSectionMap ?? initialFormDataFromAdverseEventNode(adverseEvent),
    [initialFormDataFromSectionMap, adverseEvent, initialFormDataFromAdverseEventNode],
  );
  const adverseEventFormData = prevFormData || initialFormData;

  const { registerField, formData, updateFormData, isFormValid, errors, showSubmitErrors } = useForm(
    adverseEventFormData,
    {
      showInitialErrors: !adverseEvent.newAdverseEvent,
      shouldUpdateDefaultValues: true,
      shouldUpdateInitialErrors: true,
    },
  );

  return { registerField, formData, updateFormData, isFormValid, errors, initialFormData, showSubmitErrors };
};
