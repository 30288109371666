import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Tooltip } from '@hometap/htco-components';
import PropTypes from 'prop-types';

import HtmlRenderer from '../HtmlRenderer/HtmlRenderer';
import TaskConditions from '../TaskConditions/TaskConditions';
import TaskNote from '../TaskNote/TaskNote';

import { getPortalsUrl } from 'utils/links';
import { getMostRecentChangeDescription, getIsSimpleReviewTask } from '../../trackTasksUtils';
import { FINAL_APPROVED } from '../TaskList/TaskListUtils';
import TaskSpecificContentController, {
  useTaskSpecificForm,
} from './TaskSpecificContent/TaskSpecificContentController';

import './TaskDetails.scss';

/**
 * @typedef {ExtendedTaskData} ExtendedTaskData
 */
/**
 * @typedef TaskDetailParams
 * @type {object}
 * @property {Task} task The Task being displayed
 * @property {Applicant} applicant The applicant for this task
 * @property {boolean} isPageLoading Is the parent component page loading?
 * @property {boolean} isSaveTaskNotesLoading
 * @property {boolean} shouldCheckAllConditions
 * @property {object} reviewStatus
 * @property {function} onConditionChange
 * @property {function} onTaskNoteChange
 * @property {string} assignee
 * @property {ConditionChange[]} allConditionChanges
 */
/**
 * @param {TaskDetailParams} params
 */
const TaskDetail = ({
  task,
  applicant,
  isPageLoading,
  isSaveTaskNotesLoading,
  reviewStatus,
  onConditionChange,
  onTaskNoteChange,
  shouldCheckAllConditions,
  assignee,
  allConditionChanges,
}) => {
  const { trackId, taskId } = useParams();
  const { conditions, description, notes, mostRecentChange, isCompleted } = task || {};
  useTaskSpecificForm({ trackId, assignee, task, applicant, allConditionChanges });

  const ref = useRef();

  const isFinalApproved = reviewStatus?.value === FINAL_APPROVED;
  const isReadOnly = isCompleted || isFinalApproved;
  const mostRecentChangeDescription = getMostRecentChangeDescription(mostRecentChange);
  const isSimpleReviewTask = getIsSimpleReviewTask(task?.taskDefinitionKey);

  useEffect(() => {
    if (ref?.current) {
      ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [taskId]);

  if (isPageLoading) {
    return (
      <div className="TaskDetailLoadingContainer">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <div className="TaskDetailContainer">
      {task !== undefined && (
        <>
          <div className="TaskDetailBody" ref={ref}>
            <h2>{task.name}</h2>
            {mostRecentChangeDescription && !isReadOnly && (
              <Tooltip className="TaskDetailTooltip" content="Open change history" isClickableLink>
                <a
                  href={getPortalsUrl(`/staff/clientview/${trackId}/tasks/${taskId}/history`)}
                  className="TaskDetailRecentChange"
                >
                  {mostRecentChangeDescription}
                </a>
              </Tooltip>
            )}
            {conditions?.length > 0 && (
              <TaskConditions
                conditions={conditions}
                isReadOnly={isReadOnly}
                onConditionChange={(id, value) => {
                  onConditionChange(id, value);
                }}
                shouldCheckAllConditions={shouldCheckAllConditions}
                className="TaskDetailMarginBlock"
              />
            )}
            {Boolean(description) && <HtmlRenderer description={description} className="TaskDetailMarginBlock" />}
            <TaskSpecificContentController task={task} />
            {/* TODO Hookup Notes to form */}
            {/* Smart tasks do not have notes, unless otherwise specified */}
            {isSimpleReviewTask && (
              <TaskNote
                taskId={taskId}
                initialTextValue={notes}
                onChange={value => onTaskNoteChange(value, task?.identifier)}
                isDisabled={isReadOnly}
                className="TaskDetailMarginBlock"
                isLoading={isSaveTaskNotesLoading}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const TaskPropType = PropTypes.shape({
  description: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  taskDefinitionKey: PropTypes.string,
  notes: PropTypes.string,
  link: PropTypes.string,
  displayGroup: PropTypes.string,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      kind: PropTypes.string,
      description: PropTypes.string,
      isCompleted: PropTypes.bool,
    }),
  ),
  mostRecentChange: PropTypes.shape({
    historyUser: PropTypes.string,
    historyDate: PropTypes.string,
    isTaskCondition: PropTypes.bool,
    taskConditionKind: PropTypes.string,
  }),
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
});

TaskDetail.propTypes = {
  task: TaskPropType,
  applicant: PropTypes.shape({
    fullName: PropTypes.string,
    birthDate: PropTypes.string,
    governmentId: PropTypes.shape({
      nameAsAppearsOnId: PropTypes.string,
      expirationDate: PropTypes.string,
    }),
  }),
  isPageLoading: PropTypes.bool,
  isSaveTaskNotesLoading: PropTypes.bool,
  shouldCheckAllConditions: PropTypes.bool,
  reviewStatus: PropTypes.object,
  onConditionChange: PropTypes.func,
  onTaskNoteChange: PropTypes.func,
  assignee: PropTypes.string,
  allConditionChanges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.bool,
    }),
  ),
};

export default TaskDetail;
