import { useState, useEffect } from 'react';
import { useWindowSize } from '@hometap/htco-components';

const useIsBodyOverflow = ref => {
  /**
   * Checks if the page has overflow and rerenders when screen height has changed and/or if
   * and optional ref's childNodes count has changed (helpful for dynamic lists).
   *
   * @param {Object} ref - optional element to observe for changes to trigger update.
   * If ref is not provided this hook will only update when the screen height changes.
   *
   * @returns {Boolean} does the page have overflow?
   */
  const [hasOverflow, setHasOverflow] = useState(false);
  const { screenHeight } = useWindowSize();

  useEffect(() => {
    const isBodyOverflow = document.body.clientHeight > document.documentElement.clientHeight;

    setHasOverflow(isBodyOverflow);
  }, [screenHeight, ref, ref?.current?.childNodes.length]);

  return hasOverflow;
};

export default useIsBodyOverflow;
