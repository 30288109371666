import { useEffect } from 'react';
import { getDateTimeFormat } from 'utils/dates';
import { Icon, Tooltip } from '@hometap/htco-components';
import PreviewDocumentFile, { usePreviewDocumentFile } from '../PreviewDocumentFile/PreviewDocumentFile';

import './PreviewDocumentTile.scss';
import AVStatusSmall from '../AVStatusSmall/AVStatusSmall';

/**
 * @typedef PreviewDocumentTileParams
 * @type {object}
 * @property {import('../../documentRequests').DocumentOrVersion?} viewDocumentOrVersion document/version being viewed
 */

/** Document Viewer Tile which has neither sidebars nor action buttons
 * @param {PreviewDocumentTileParams} params
 * @returns {JSX.Element} Document File Viewer + Description Overlay
 */
export function PreviewDocumentTile({ viewDocumentOrVersion, ...divParams }) {
  const usePreviewDocumentFileReturn = usePreviewDocumentFile();
  const { download } = usePreviewDocumentFileReturn;

  // Updating the viewDocumentOrVersion state triggers either a download or a reset
  useEffect(() => {
    download(viewDocumentOrVersion);
  }, [viewDocumentOrVersion, download]);

  const { filename, created_at, rotation } = viewDocumentOrVersion;
  return (
    <div className="PreviewDocumentTile" {...divParams}>
      <div className="PreviewDocumentFileWrapper">
        <PreviewDocumentFile
          viewDocumentOrVersion={viewDocumentOrVersion}
          {...usePreviewDocumentFileReturn}
          imageRotation={rotation}
          isTile
        />
      </div>
      <ul className="PreviewDocumentTileDescription">
        <li className="PreviewDocumentTileFilename">
          {filename}
          <Tooltip
            className="PreviewDocumentTileDescriptionTooltip"
            content={
              <span>
                {viewDocumentOrVersion?.kind_display}
                <br />
                {viewDocumentOrVersion?.applicant?.fullNameShort}
              </span>
            }
            action="hover"
          >
            <Icon name="icon-info" size="lg" />
          </Tooltip>
        </li>
        <AVStatusSmall
          viewDocumentOrVersion={viewDocumentOrVersion}
          extraClassName="PreviewDocumentTileAntiVirusStatus"
        />
        <li className="PreviewDocumentTileCreatedAt">{getDateTimeFormat(created_at, 'DD MMM. YYYY')}</li>
      </ul>
    </div>
  );
}
