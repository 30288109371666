import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const CONTRACTOR_PATHNAME_REGEX_WHITE_LIST = [
  /^\/tasks$/,
  /^\/tracks\/[\w-]+\/tasks$/,
  /^\/tracks\/[\w-]+\/tasks\/[\w-]+$/,
  /^\/tracks\/[\w-]+\/documents$/,
  /^\/tracks\/[\w-]+\/documents\/[\w-]+$/,
  /^\/tracks\/[\w-]+\/documents\/[\w-]\/version\/[\w-]+$/,
];

export function ContractorRedirect({ children }) {
  const { user, isContractor } = useCurrentUser();
  const { pathname } = useLocation();

  if (!user) {
    return null;
  } else if (isContractor && !CONTRACTOR_PATHNAME_REGEX_WHITE_LIST.includes(pathname)) {
    const pathAllowedForContractor = CONTRACTOR_PATHNAME_REGEX_WHITE_LIST.reduce(
      (allowed, nextPathnameRegex) => allowed || nextPathnameRegex.test(pathname),
      false,
    );
    if (!pathAllowedForContractor) {
      return <Navigate to={{ pathname: '/tasks' }} />;
    }
  }
  return children;
}
