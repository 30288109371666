import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import { ICON_SCRIPT } from '@hometap/htco-components';
import useCurrentUser, { CurrentUserProvider } from 'hooks/useCurrentUser';
import Header from './components/Header/Header';
import IdleWarningModal from 'components/IdleWarning/IdleWarningModal';
import { getVersion } from 'utils/env';
import segment from 'utils/segment/segment';
import sentry from 'utils/sentry';

import AppRouter from 'AppRouter';
import { ErrorBoundary } from '@sentry/react';
import Configurator from './configurator/Configurator';
import { ConfigurationsProvider } from './hooks/useConfigurations';
import { SystemStatusProvider } from 'hooks/useSystemStatus';
import { ContractorRedirect } from 'components/ContractorRedirect/ContractorRedirect';

const IdleWarningContainer = () => {
  // @ts-expect-error Property 'user' does not exist on type '{}
  const { user } = useCurrentUser();
  return user ? <IdleWarningModal /> : null;
};

const App = () => {
  console.log(`StaffPortal v${getVersion() || '0.0.0'}`);

  useEffect(() => {
    segment.init();
    sentry.init();
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          {/* @ts-expect-error Property 'SameSite' does not exist on type 'DetailedHTMLProps' */}
          <script src={ICON_SCRIPT} SameSite="None"></script>
        </Helmet>
        {/* @ts-expect-error ErrorBoundary component is not yet TypeScript aware. */}
        <ErrorBoundary>
          <CurrentUserProvider>
            <SystemStatusProvider>
              <ConfigurationsProvider>
                <IdleWarningContainer />
                <Router>
                  <ContractorRedirect>
                    <Header />
                    <main id="Main">
                      <AppRouter />
                      { /* @ts-expect-error Configurator component is not yet TypeScript aware */}
                      <Configurator />
                    </main>
                  </ContractorRedirect>
                </Router>
              </ConfigurationsProvider>
            </SystemStatusProvider>
          </CurrentUserProvider>
        </ErrorBoundary>
      </HelmetProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
