import React from 'react';
import { Badge } from '@hometap/htco-components';
import './ReggoraStatus.scss';

import { REGGORA_STATUS_LABELS } from '../../../utils/reggora';

const ReggoraStatus = ({ label }) => {
  const ReggoraLabels = {
    [REGGORA_STATUS_LABELS.PENDING_APPROVAL]: (
      <Badge className="reggoraStatusLabel" label={label} theme="primaryBlue15" />
    ),
    [REGGORA_STATUS_LABELS.WAITING_FOR_PAYMENT]: (
      <Badge className="reggoraStatusLabel" label={label} theme="primaryBlue15" />
    ),
    [REGGORA_STATUS_LABELS.FINDING_APPRAISERS]: (
      <Badge className="reggoraStatusLabel" label={label} theme="primaryBlue15" />
    ),
    [REGGORA_STATUS_LABELS.ACCEPTED]: <Badge className="reggoraStatusLabel" label={label} theme="primaryBlue15" />,
    [REGGORA_STATUS_LABELS.INSPECTION_SCHEDULED]: (
      <Badge className="reggoraStatusLabel" label={label} theme="secondaryYellow15" />
    ),
    [REGGORA_STATUS_LABELS.INSPECTION_COMPLETED]: (
      <Badge className="reggoraStatusLabel" label={label} theme="secondaryYellow15" />
    ),
    [REGGORA_STATUS_LABELS.UNDER_REVIEW]: <Badge className="reggoraStatusLabel" label={label} theme="primaryGreen15" />,
    [REGGORA_STATUS_LABELS.SUBMITTED]: <Badge className="reggoraStatusLabel" label={label} theme="primaryGreen15" />,
    [REGGORA_STATUS_LABELS.REVISION_SUBMITTED]: (
      <Badge className="reggoraStatusLabel" label={label} theme="primaryGreen15" />
    ),
    [REGGORA_STATUS_LABELS.REVISION_REQUESTED]: (
      <Badge className="reggoraStatusLabel" label={label} theme="secondaryRed15" />
    ),
    [REGGORA_STATUS_LABELS.ON_HOLD]: <Badge className="reggoraStatusLabel" label={label} theme="secondaryRed15" />,
    [REGGORA_STATUS_LABELS.ATTENTION_REQUIRED]: (
      <Badge className="reggoraStatusLabel" label={label} theme="secondaryRed15" />
    ),
    [REGGORA_STATUS_LABELS.TYPO_CANCELLED]: (
      <Badge className="reggoraStatusLabel" label={'Cancelled'} theme="secondaryRed15" />
    ),
    [REGGORA_STATUS_LABELS.CANCELLED]: <Badge className="reggoraStatusLabel" label={label} theme="secondaryRed15" />,
  };

  let renderedLabel;

  if (ReggoraLabels[label] === undefined) {
    renderedLabel = <div>{label}</div>;
  } else {
    renderedLabel = <div>{ReggoraLabels[label]}</div>;
  }

  return <>{renderedLabel}</>;
};
export default ReggoraStatus;
