const TODO_REASON = {
  MissingPages: 'The document previously provided is missing pages.',
  IsNotClear: 'The document previously provided is not clear.',
  Incomplete: 'The document previously provided is incomplete. Please ensure all four corners of document are visible.',
  NotForTheProperty: 'The document previously provided was not for the property indicated on your Application.',
  IncorrectPropertyType: 'The document previously provided does not reflect the correct property type.',
  IncorrectApplicant: 'The document previously provided does not reflect the correct applicant name(s).',
  IsExpired: 'The document previously provided is expired.',
  MostRecentStatement: 'Please provide the most recent statement.',
  PayoffStatement: 'Please provide new payoff statement as document previously provided is expired.',
};

export const TODO_NOTES_OPTIONS = [
  TODO_REASON.IsNotClear,
  TODO_REASON.Incomplete,
  TODO_REASON.IncorrectApplicant,
  TODO_REASON.MissingPages,
  TODO_REASON.MostRecentStatement,
  TODO_REASON.NotForTheProperty,
  TODO_REASON.IncorrectPropertyType,
  TODO_REASON.PayoffStatement,
];

const TODO_NOTES_GOVT_ID = [
  TODO_REASON.IsNotClear,
  TODO_REASON.Incomplete,
  TODO_REASON.IncorrectApplicant,
  TODO_REASON.IsExpired,
];

const TODO_NOTES_HOI = [
  TODO_REASON.MissingPages,
  TODO_REASON.IsNotClear,
  TODO_REASON.Incomplete,
  TODO_REASON.NotForTheProperty,
  TODO_REASON.IncorrectPropertyType,
  TODO_REASON.IncorrectApplicant,
  TODO_REASON.IsExpired,
];

const TODO_NOTES_MORTGAGE_LIEN = [
  TODO_REASON.MissingPages,
  TODO_REASON.IsNotClear,
  TODO_REASON.Incomplete,
  TODO_REASON.MostRecentStatement,
  TODO_REASON.NotForTheProperty,
  TODO_REASON.IncorrectPropertyType,
  TODO_REASON.IncorrectApplicant,
  TODO_REASON.IsExpired,
];

export const getTodoNotes = taskKinds => ({
  default: TODO_NOTES_OPTIONS,
  [taskKinds.GOVT_ID_REVIEW_V1]: TODO_NOTES_GOVT_ID,
  [taskKinds.UPLOAD_GOV_ID]: TODO_NOTES_GOVT_ID,
  [taskKinds.HOMEOWNER_INSURANCE_REVIEW_V1]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_HO_INSURANCE]: TODO_NOTES_HOI,
  [taskKinds.FLOOD_INSURANCE_REVIEW_V1]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_FLOOD_INSURANCE]: TODO_NOTES_HOI,
  [taskKinds.OTHER_INSURANCE_REVIEW_V1]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_OTHER_INSURANCE]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_CONDO_MASTER_POLICY]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_REPLACEMENT_COST_ESTIMATOR_ON_HOI]: TODO_NOTES_HOI,
  [taskKinds.UPLOAD_REPLACEMENT_COST_ESTIMATOR_ON_CONDO_MASTER_POLICY]: TODO_NOTES_HOI,
  [taskKinds.MORTGAGE_STATEMENT_REVIEW_V1]: TODO_NOTES_MORTGAGE_LIEN,
});

export const TODO_NOTES_OTHER = 'other';

export const TODO_NOTES_OTHER_OPTION = { label: 'Other', value: TODO_NOTES_OTHER };
