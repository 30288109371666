import { useState, useEffect, useCallback } from 'react';

/**
 * @typedef {Object} TaskQueueParams
 * @property {function[]} tasks - The queue of the tasks
 * @property {boolean} isProcessing - Returns true if a task in process
 * @property {boolean} isLoading - Returns true if the queue of the tasks is loading
 * @property {function} addQueueTask - The callback function, which will be added to the queue
 */

/** Hook for queueing and processing async tasks sequentially.
 * @param {boolean} shouldProcessSequentially - The condition when we should process async tasks sequentially.
 * @returns {TaskQueueParams}
 */

export const useTaskQueue = (shouldProcessSequentially = true) => {
  const [queue, setQueue] = useState({ isProcessing: false, tasks: [] });

  useEffect(() => {
    if (!shouldProcessSequentially) return;
    if (queue.tasks.length === 0) return;
    if (queue.isProcessing) return;

    const [task] = queue.tasks;
    setQueue(prev => ({
      isProcessing: true,
      tasks: prev.tasks.slice(1),
    }));

    Promise.resolve(task()).finally(() => {
      setQueue(prev => ({
        isProcessing: false,
        tasks: prev.tasks,
      }));
    });
  }, [queue, shouldProcessSequentially]);

  return {
    tasks: queue.tasks,
    isProcessing: queue.isProcessing,
    isLoading: queue.tasks.length !== 0 || queue.isProcessing,
    addQueueTask: useCallback(task => {
      setQueue(prev => ({
        isProcessing: prev.isProcessing,
        tasks: [...prev.tasks, task],
      }));
    }, []),
  };
};
