import { gql } from '@apollo/client';
import TodosSelectionList from './components/TodosSelectionList';
import TodosTable from './components/TodosTable';

export const GET_TRACK_TODO_DATA = gql`
  query GetTrackTodos($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      applicants {
        id
        fullName
        fullNameShort
        identifier
        person {
          identifier
        }
      }
      ...HomeownerTodos
      ...AvailableTrackTodoChoices
    }
  }

  ${TodosTable.fragments.HomeownerTodos}
  ${TodosSelectionList.fragments.AvailableTrackTodoChoices}
`;
