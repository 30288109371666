import InvestmentCalculator from './components/InvestmentCalculator/InvestmentCalculator';
import useCurrentUser from 'hooks/useCurrentUser';
import { NumberInput } from '@hometap/htco-components';
import useInvestmentCalculator from './hooks/useInvestmentCalculator';
import { currency, percentage } from 'utils/numbers';
import PaydownError from './components/PaydownError/PaydownError';
import { INPUT_FIELDS, INPUT_GROUPS } from './utils/calculatorConstants';
import { Helmet } from 'react-helmet-async';
import { GET_GUIDE_CALCULATOR_MAX_INVESTMENT_DATA } from './mutations';
import StatusCodeErrorBlock from 'components/StatusCodeErrorBlock/StatusCodeErrorBlock';
import './InvestmentCalculator.scss';

const GuideCalculatorController = () => {
  const { isInInvestmentGuideCalculatorRolloutGroup } = useCurrentUser();

  const { inputData, updateInputDataByKey, calculatedData, calculationsIsLoading, calculationsError } =
    useInvestmentCalculator(GET_GUIDE_CALCULATOR_MAX_INVESTMENT_DATA, null, {
      appraisedHomeValue: 0,
      totalDebtOnHome: 0,
      feePaydown: 0,
      principalPaydown: 0,
    });

  if (!isInInvestmentGuideCalculatorRolloutGroup) {
    return <StatusCodeErrorBlock errorCode={403} />;
  }

  const renderInput = key => (
    <NumberInput
      type="currency"
      placeholder="$"
      className="InvestmentCalculatorControllerInput"
      key={key}
      value={inputData[key]}
      precision={0}
      onChange={newVal => {
        updateInputDataByKey(key, newVal);
      }}
    />
  );

  return (
    <div>
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} />
      <InvestmentCalculator
        loading={calculationsIsLoading}
        calculatedData={calculatedData}
        calculationsError={calculationsError}
        isOverlay={true}
        inputGroups={[
          {
            group: INPUT_GROUPS.PROPERTY,
            rows: [
              {
                label: INPUT_FIELDS.APPROX_HOME_VALUE,
                value: renderInput('appraisedHomeValue'),
              },
              {
                label: INPUT_FIELDS.DEBT,
                value: renderInput('totalDebtOnHome'),
              },
              {
                label: INPUT_FIELDS.STARTING_EQUITY,
                value: percentage((1 - inputData.totalDebtOnHome / inputData.appraisedHomeValue) * 100),
              },
            ],
          },
          {
            group: INPUT_GROUPS.PAYOFFS,
            rows: [
              {
                label: INPUT_FIELDS.FEE_PAYDOWN,
                value: renderInput('feePaydown'),
              },
              {
                label: INPUT_FIELDS.PRINCIPAL_PAYDOWN,
                value: renderInput('principalPaydown'),
                additional: <PaydownError calculatedData={calculatedData} includeCents={false} />,
              },
              {
                label: INPUT_FIELDS.TOTAL_PAYDOWN,
                value: currency((inputData.feePaydown || 0) + (inputData.principalPaydown || 0)),
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default GuideCalculatorController;
