import React from 'react';
import { Icon, Loader } from '@hometap/htco-components';

import './SavedDisplay.scss';

/**
 * @typedef SavedDisplayParams
 * @type {object}
 * @property {boolean} saving a flag indication that a saving process is happening
 * @property {boolean} requesting a flag indication that a requesting process is happening
 * @property {boolean} [defaultShow] a flag indication that saved display is showing
 * @property {UseTaskSpecificFormReturn} useTaskSpecificFormReturn The hook return for task specific content
 */
/**
 * @param {SavedDisplayParams} params
 * @returns {HTMLElement}
 */
const SavedDisplay = ({ saving, requesting, defaultShow = true }) => {
  const isLoading = saving || requesting;
  const loadingText = saving ? 'Saving' : 'Loading';

  return (
    <div className="SavedDisplay">
      {defaultShow && (
        <>
          <div className="SavedDisplayIconWrapper">
            {isLoading ? <Loader size="small" /> : <Icon name="icon-check" />}
          </div>
          {isLoading ? loadingText : 'Saved'}
        </>
      )}
    </div>
  );
};

export default SavedDisplay;
