import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput, MuiTextarea } from '@hometap/htco-components';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  MORTGAGE_LIEN_TYPE,
  getSelectionYesNoInitialValue,
  getDocIdsToLinkInitialValue,
  getRateTypeInitialValue,
  RATE_TYPE_UNKNOWN,
} from 'apps/track-details/utils/taskForms';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useConfigurations from 'hooks/useConfigurations';
import { validateDateNotBeforeDate, validateDateNotMoreThanNDaysInPast } from 'utils/validators';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import SelectionYesNoUnknown, {
  getSelectionYesNoUnknownInitialValue,
  getSelectionYesNoUnknownSelectedValue,
} from './SelectionYesNoUnknown';
import { CREATE_MORTGAGE } from 'apps/track-details/ApplicationReview/data/mutations/createMortgage';
import { UPDATE_MORTGAGE } from 'apps/track-details/ApplicationReview/data/mutations/updateMortgage';
import { useLienForm } from '../hooks/useLienForm';
import { useInitialLienForm } from '../hooks/useInitialLienForm';
import { PaydownWrapper } from './PaydownWrapper/PaydownWrapper';
import { helperText } from 'utils/dates';
import LienPropertyReportFields from './LienPropertyReportFields';
import { useValidationLienBalance } from '../hooks/useValidationLienBalance';
import { getErrorNotificationDescription, getSuccessNotificationDescription } from '../utils/liens';

const initialFormDataFromLienNode = lien => ({
  ...lien,
  kind: lien?.kind?.toLowerCase(),
  mortgageInvestorKind: lien?.mortgageInvestorKind?.toLowerCase(),
  rateType: getRateTypeInitialValue(lien?.rateType, MORTGAGE_LIEN_TYPE),
  onPropertyReport: getSelectionYesNoUnknownInitialValue(lien?.onPropertyReport),
  isForbearance: getSelectionYesNoInitialValue(lien?.isForbearance),
  hasAModification: getSelectionYesNoUnknownInitialValue(lien?.hasAModification),
  isMoreThanOneMonthPastDue: getSelectionYesNoInitialValue(lien?.isMoreThanOneMonthPastDue),
  docIdsToLink: getDocIdsToLinkInitialValue(lien),
});

export const MortgageReviewForm = ({
  availableDocOptions,
  disabled,
  lien,
  trackId,
  lienPositionError,
  addNewPaydown,
  deletePaydown,
  setLienReportFieldsTouched,
  lienReportFieldsTouched,
  homeValue,
}) => {
  const { lienMortgageInvestorTypes } = useConfigurations();
  const { updateSectionFormById } = useUpdateSectionForm();
  const {
    mutateMortgageLien,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    paydownId,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useMortgageForm(lien, trackId, lienReportFieldsTouched, lienPositionError);

  const {
    currentBalance,
    isMoreThanOneMonthPastDue,
    isForbearance,
    hasAModification,
    escrowAmount,
    interestAmount,
    principalAmount,
    onPropertyReport,
  } = formData;

  const validationLienBalance = useValidationLienBalance({
    currentBalance,
    escrowAmount,
    interestAmount,
    principalAmount,
    setErrors,
  });

  const lienId = lien?.identifier;

  useEffect(() => {
    updateSectionFormById(lienId, {
      complete: mutateMortgageLien,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      paydownId,
      showSubmitErrors,
    });
  }, [
    mutateMortgageLien,
    updateSectionFormById,
    lienId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
  ]);

  const isForbearanceCheck = isForbearance === 'true';

  return (
    <>
      <div className="FormRow">
        <div>
          <MuiSelect
            label="Document used to fill out this form"
            classNamePrefix="MortgageLienSelect"
            options={availableDocOptions}
            disabled={disabled}
            theme="outlined"
            width="100%"
            showRequiredAsterisk={false}
            required
            {...registerField('docIdsToLink')}
          />
        </div>
      </div>
      <LienPropertyReportFields
        label="Is this mortgage on the property report?"
        initialFormData={initialFormData}
        formData={formData}
        lienPositionError={lienPositionError}
        disabled={disabled}
        setLienReportFieldsTouched={setLienReportFieldsTouched}
        lienReportFieldsTouched={lienReportFieldsTouched}
        registerField={registerField}
        showTriggeredPublishErrors={showTriggeredPublishErrors}
      />
      <div className="FormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Servicer"
            theme="outlined"
            width="100%"
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            {...registerField('holder')}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <MuiSelect
            label="Mortgage investor type"
            options={lienMortgageInvestorTypes}
            disabled={disabled}
            theme="outlined"
            width="100%"
            required
            showRequiredAsterisk={false}
            {...registerField('mortgageInvestorKind')}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Mortgage account number"
            theme="outlined"
            width="100%"
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            {...registerField('mortgageAccountNumber')}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Current principal balance"
            padFractionalZeros={true}
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            validator={value =>
              validationLienBalance.currentBalance({
                currentBalance: value,
                principalAmount,
                escrowAmount,
                interestAmount,
              })
            }
            {...registerField('currentBalance')}
          />
        </div>
        <div className="FormItem">
          <DatePicker
            label="As of date"
            theme="outlined"
            disabled={disabled}
            className="FullWidthDatePicker"
            wrapperClassName="FullWidthDatePicker"
            required
            showRequiredAsterisk={false}
            {...registerField('asOfDate')}
            showErrorIcon={true}
            helperText={helperText}
            showWarningIcon={true}
            warning={validateDateNotMoreThanNDaysInPast(formData.asOfDate)}
          />
        </div>
      </div>
      <div className="FormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Monthly principal amt."
            padFractionalZeros={true}
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.principalAmount({ principalAmount: value, currentBalance })}
            {...registerField('principalAmount')}
          />
        </div>
      </div>
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Monthly interest amt."
            padFractionalZeros={true}
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.interestAmount({ interestAmount: value, currentBalance })}
            {...registerField('interestAmount')}
          />
        </div>
        <div className="FormItem">
          <CurrencyInput
            label="Escrow amt."
            padFractionalZeros={true}
            disabled={disabled}
            required
            showRequiredAsterisk={false}
            validator={value => validationLienBalance.escrowAmount({ escrowAmount: value, currentBalance })}
            {...registerField('escrowAmount')}
          />
        </div>
      </div>
      <div className="FormRow">
        <SelectionYesNoUnknown
          label="Is there any indication of a modification?"
          disabled={disabled}
          isWithMarginBottom={false}
          {...registerField('hasAModification')}
        />
      </div>
      {hasAModification === 'true' && (
        <div className="FormRow">
          <DetailInformationBlock
            type="warning"
            className="MortgageLienBlockText"
            isWithIcon={false}
            text="Please manually create a Homeowner to-do requesting Loan Modification documents."
          />
        </div>
      )}
      <div className="FormRow">
        <SelectionYesNoSet
          label="Is the account more than one month past due?"
          isWithMarginBottom={false}
          disabled={disabled}
          {...registerField('isMoreThanOneMonthPastDue')}
        />
      </div>
      <div className={'TwoItemFormRow'}>
        <div className="FormItem">
          <CurrencyInput
            label="Past due amount (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            {...registerField('pastDueAmount')}
          />
        </div>
        <div className="FormItem">
          <CurrencyInput
            label="Past due fees (optional)"
            padFractionalZeros={true}
            disabled={disabled}
            {...registerField('pastDueFees')}
          />
        </div>
      </div>
      <div className="FormRow">
        <SelectionYesNoSet
          label="Is there any active forbearance?"
          required={true}
          showRequiredAsterisk={false}
          isWithMarginBottom={false}
          disabled={disabled}
          {...registerField('isForbearance')}
        />
        {isMoreThanOneMonthPastDue === 'true' && (
          <DetailInformationBlock
            type={isForbearanceCheck ? 'warning' : 'error'}
            isWithIcon={!isForbearanceCheck}
            text={
              isForbearanceCheck
                ? 'Please manually create a Homeowner to-do requesting forbearance documentation.'
                : "Doesn't qualify based on being more than one month past due, and no active forbearance. Please coordinate with your IM."
            }
            className="ForbearanceDetailsBlock"
          />
        )}
      </div>
      <div className="FormRow">
        <MuiTextarea
          label="Notes (optional)"
          theme="outlined"
          width="100%"
          disabled={disabled}
          {...registerField('notes')}
        />
      </div>
      <PaydownWrapper
        paydown={lien?.paydown}
        trackId={trackId}
        disabled={disabled}
        addNewPaydown={addNewPaydown}
        deletePaydown={deletePaydown}
        lienId={lien?.identifier}
        homeValue={homeValue}
        onPropertyReport={onPropertyReport}
        updateLienFormData={updateFormData}
      />
    </>
  );
};

function useMortgageForm(lien, trackId, lienReportFieldsTouched, lienPositionError) {
  const client = useApolloClient();

  const {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    errors,
    setErrors,
    initialFormData,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useInitialLienForm(lien, initialFormDataFromLienNode);
  useLienForm(lien);

  const validateForm = lienReportFieldsTouched => {
    const { onPropertyReport } = formData;
    if (onPropertyReport === 'true') {
      const PROPERTY_REPORT_DEPENDENT_FIELDS = [
        'position',
        'endOfTermDate',
        'lienDate',
        'originalAmount',
        'rate',
        'rateType',
      ];
      if (
        PROPERTY_REPORT_DEPENDENT_FIELDS.every(
          field =>
            (!formData[field] || (field === 'rateType' && formData[field] === RATE_TYPE_UNKNOWN)) &&
            !showTriggeredPublishErrors &&
            !lienReportFieldsTouched[lien.identifier]?.[field],
        )
      ) {
        return true;
      }
      if (lienPositionError[lien.identifier]) {
        return false;
      }
      if (validateDateNotBeforeDate(formData.endOfTermDate, formData.lienDate, 'Term date', 'Date recorded')) {
        return false;
      }
    }
    return isFormValid;
  };

  const mutateMortgageLien = useCallback(
    async ({ formData = {} }) => {
      const {
        newLien,
        asOfDate,
        currentBalance,
        endOfTermDate,
        escrowAmount,
        isForbearance,
        holder,
        interestAmount,
        isMoreThanOneMonthPastDue,
        lienDate,
        mortgageAccountNumber,
        mortgageInvestorKind,
        notes,
        onPropertyReport,
        originalAmount,
        pastDueAmount,
        pastDueFees,
        position,
        principalAmount,
        rate,
        rateType,
        hasAModification,
        docIdsToLink,
      } = formData;
      const variables = {
        asOfDate,
        currentBalance: parseFloat(currentBalance).toFixed(2),
        escrowAmount: parseFloat(escrowAmount).toFixed(2),
        mortgageInvestorKind: mortgageInvestorKind ? mortgageInvestorKind.toUpperCase() : undefined,
        onPropertyReport: getSelectionYesNoUnknownSelectedValue(onPropertyReport),
        hasForbearance: isForbearance === 'true',
        isMoreThanOneMonthPastDue: isMoreThanOneMonthPastDue === 'true',
        holder,
        interestAmount: parseFloat(interestAmount).toFixed(2),
        mortgageAccountNumber,
        notes,
        pastDueAmount: pastDueAmount ? parseFloat(pastDueAmount).toFixed(2) : undefined,
        pastDueFees: pastDueFees ? parseFloat(pastDueFees).toFixed(2) : undefined,
        principalAmount: parseFloat(principalAmount).toFixed(2),
        docIdsToLink,
        hasAModification: getSelectionYesNoUnknownSelectedValue(hasAModification),
        trackId,
      };
      variables.lienKind = MORTGAGE_LIEN_TYPE;
      variables.lienId = lien.identifier;

      if (onPropertyReport === 'true') {
        variables.position = position;
        variables.originalAmount = parseFloat(originalAmount).toFixed(2);
        variables.lienDate = lienDate;
        variables.endOfTermDate = endOfTermDate;
        variables.rate = parseFloat(rate).toFixed(3);
        variables.rateType = rateType?.toUpperCase();
      }

      const MUTATION = newLien ? CREATE_MORTGAGE : UPDATE_MORTGAGE;

      try {
        const { data } = await client.mutate({
          mutation: MUTATION,
          variables,
        });
        const [mutationData] = Object.values(data);
        const lienIdentifier = Object.values(mutationData)[0]['identifier'];
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: getSuccessNotificationDescription(newLien, 'Mortgage'),
        });
        return { newLienIdentifier: newLien && lienIdentifier ? lienIdentifier : undefined };
      } catch (error) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : getErrorNotificationDescription(newLien, 'Mortgage'),
        });
      }
    },
    [client, lien, trackId],
  );

  return {
    mutateMortgageLien,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid: validateForm(lienReportFieldsTouched),
    showSubmitErrors,
    paydownId: lien?.paydown?.identifier,
    errors,
    setErrors,
    showTriggeredPublishErrors,
  };
}
