import { gql } from '@apollo/client';

export const GET_TRACK_MORTGAGES_LIENS = gql`
  query Track($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      liens {
        identifier
        holder
        lender
        notes
        maxLineAmount
        currentBalance
        principalAmount
        interestAmount
        escrowAmount
        pastDueAmount
        pastDueFees
        asOfDate
        isForbearance
        onPropertyReport
        kind
        mortgageInvestorKind
        position
        originalAmount
        rate
        lienDate
        endOfTermDate
        rateType
        mortgageAccountNumber
        isMoreThanOneMonthPastDue
      }
      liensKindOptions {
        label
        value
      }
    }
  }
`;
