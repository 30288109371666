import React from 'react';
import { MuiSelect, Grid, Badge, Icon, Tooltip } from '@hometap/htco-components';
import cx from 'classnames';
import './FundingCell.scss';

const FundingCell = ({
  track,
  setPotentialTrackChange,
  setModelTrackOpen,
  handleChangeFund,
  fundNameDict,
  isDisabled,
}) => {
  if (!track) {
    return null;
  }
  function fundDropdownLabel() {
    // if the option is selected to run buybox, we do not want a label under it
    if (track.fundData?.selected === null || track.fundData?.selected === '') {
      return null;
    }
    if (track.fundData?.rejected?.includes(track.fundData?.selected)) {
      return (
        <div className={cx('fundingCellWarningDropDownLabel', 'ineligible')}>
          <Icon color={null} name="alert" rotate={null} />
          <span className="FundingCellWarningText">
            Looks like the fund you selected is ineligible. Please select an eligible fund.
          </span>
        </div>
      );
    }
    if (track.fundData?.initial !== track.fundData?.selected) {
      return (
        <div className={cx('fundingCellWarningDropDownLabel', 'newChanges')}>
          <Icon color={null} name="alert" rotate={null} />
          <span className="FundingCellWarningText">
            Looks like you made changes to this fund. Click "Save Changes" to update this track's fund.
          </span>
        </div>
      );
    }
    return null;
  }

  const formatDropDownOptions = () => {
    const newDropdownOptions = [];
    // first include the picked fund (which may not be on the suitable list)
    if (track.fundData?.selected) {
      newDropdownOptions.push({
        label: (
          <div className="FundingCellDropdownOptions" id={track.friendlyId}>
            {track.fundData?.preferred === track.fundData?.selected && (
              <span className="FundingCellDropDownOptionsBadge">
                <Badge label="Preferred" theme="primaryBlue15" />
              </span>
            )}
            {fundNameDict[track.fundData?.selected]}
          </div>
        ),
        value: track.fundData?.selected,
      });
    }

    // make suitable funds available to choose from
    track.fundData?.suitable?.forEach(fund => {
      if (fund !== track.fundData?.selected) {
        newDropdownOptions.push({
          label: (
            <div className="FundingCellDropdownOptions">
              {track.fundData?.preferred === fund && (
                <span className="FundingCellDropDownOptionsBadge">
                  <Badge label="Preferred" theme="primaryBlue15" />
                </span>
              )}
              {fundNameDict[fund]}
            </div>
          ),
          value: fund,
        });
      }
    });

    // check if there is an empty option, if there is not add one
    if (newDropdownOptions.map(option => option?.type === 'empty').includes(false) || newDropdownOptions.length === 0) {
      // place empty option at the top of the list
      newDropdownOptions.unshift({
        label: (
          <div className="FundingCellDropdownOptions" id={track.friendlyId}>
            Select a fund
          </div>
        ),
        value: null,
        type: 'empty',
      });
    }

    return newDropdownOptions;
  };

  const createToolTipFunds = () => {
    return (
      <Tooltip
        action="hover"
        target="body"
        position="bottom-start"
        width={460}
        content={
          <div className="TooltipContent">
            <strong>Eligible:</strong>
            {track?.fundData?.suitable?.length > 0 && (
              <ol className="TooltipList">
                {track?.fundData?.suitable?.map((fund, index) => (
                  <li key={index}>{fundNameDict[fund]}</li>
                ))}
              </ol>
            )}
            <br />
            <strong>Ineligible:</strong>
            {track?.fundData?.rejected?.length > 0 && (
              <ol className="TooltipList">
                {track?.fundData?.rejected?.map((fund, index) => (
                  <>
                    <li>{fundNameDict[fund]}</li>
                    <ul className="IneligibleReasonsList">
                      {track?.fundData?.rejectedDetails[fund]?.map((reason, index) => (
                        <li key={index}>{reason}</li>
                      ))}
                    </ul>
                  </>
                ))}
              </ol>
            )}
          </div>
        }
      >
        <Icon name="info" iconSize="lg" color={'#467cfd'} className="FundingCellTooltip" />
      </Tooltip>
    );
  };

  /**
   * Handles modal and side effect management for fund changes
   * @param {String} changedTrack track whose fund is changing
   * @param {Object} newFund new fund for that track
   */
  const handleChange = fundChoice => {
    if (track.fundData?.selected !== fundChoice) {
      if (fundChoice !== track.fundData?.preferred) {
        setPotentialTrackChange({ changedTrack: track, newFund: fundChoice });
        setModelTrackOpen(true);
      } else {
        handleChangeFund(track, fundChoice);
      }
    }
  };

  const singleFundDropdown = (
    <Grid className="FundingCellGrid">
      <div className="FundingCellContainer">
        <div className="FundingCellDropdown">
          <MuiSelect
            className="FundingCellMuiSelect"
            id={track.friendlyId}
            label={track.fundData?.selected ? 'Selected fund' : 'Select a fund'}
            name="selectFund"
            theme="outlined"
            width="100%"
            disabled={isDisabled}
            value={track.fundData?.selected}
            options={formatDropDownOptions()}
            onChange={handleChange}
            isMuiSelect={true}
            closeMenuOnSelect={true}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        </div>
        {createToolTipFunds()}
      </div>
      {fundDropdownLabel()}
    </Grid>
  );

  return singleFundDropdown;
};

export default FundingCell;
