import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import useConfigurations from 'hooks/useConfigurations';
import { capitalize } from 'lodash';
import { booleanOrNoneToValue, booleanOptionsWithUnknown } from 'utils/boolean';
import './TaskMortgageLien.scss';

export const TaskMortgageLien = ({ lien, readOnly = true }) => {
  const displayMortgageAccountNumber = ['MORTGAGE', 'HOME_EQUITY_LOAN', 'HELOC', 'PRIVATE_MORTGAGE'].includes(
    lien.kind,
  );
  const displayMaxLineAmount = lien.kind === 'HELOC';
  const { allConfigurations } = useConfigurations();
  const { mortgageInvestorTypes, rateTypes } = allConfigurations?.lien || {};

  const doNothingOnChange = () => {};
  return (
    <>
      <h5>{capitalize(lien.kind)}</h5>
      <div className={'TwoItemFormRow'}>
        <MuiTextInput value={lien.holder} label="Servicer" theme="outlined" width="100%" disabled={readOnly} />
        {displayMortgageAccountNumber && (
          <MuiTextInput
            value={lien.mortgageAccountNumber}
            label="Mortgage Account Number"
            theme="outlined"
            width="100%"
            disabled={readOnly}
          />
        )}
      </div>
      <MuiSelect
        label="Mortgage Investor"
        value={lien.mortgageInvestorKind?.toLowerCase()}
        options={mortgageInvestorTypes}
        disabled={readOnly}
        theme="outlined"
        onChange={doNothingOnChange}
        width="100%"
      />
      <div className={'TwoItemFormRow'}>
        <MuiTextInput value={lien.lender} label="Lender" theme="outlined" width="100%" disabled={readOnly} />
      </div>
      {displayMaxLineAmount && (
        <CurrencyInput
          value={lien.maxLineAmount}
          label="Max Line Amount"
          padFractionalZeros={true}
          disabled={readOnly}
        />
      )}
      <div className={'TwoItemFormRow'}>
        <CurrencyInput value={lien.currentBalance} label="Balance" padFractionalZeros={true} disabled={readOnly} />
        <DatePicker
          value={lien.asOfDate}
          label={'As of date'}
          onChange={doNothingOnChange}
          theme="outlined"
          disabled={readOnly}
          className="FullWidthDatePicker"
          wrapperClassName="FullWidthDatePicker"
        />
      </div>
      <CurrencyInput
        value={lien.principalAmount}
        label="Monthly principal amt."
        padFractionalZeros={true}
        disabled={readOnly}
      />
      <div className={'TwoItemFormRow'}>
        <CurrencyInput
          value={lien.interestAmount}
          label="Monthly interest amt."
          padFractionalZeros={true}
          disabled={readOnly}
        />
        <CurrencyInput value={lien.escrowAmount} label="Escrow amt." padFractionalZeros={true} disabled={readOnly} />
      </div>
      <CurrencyInput
        value={lien.pastDueAmount}
        label="Past due amount (optional)"
        padFractionalZeros={true}
        disabled={readOnly}
      />
      <CurrencyInput
        value={lien.pastDueFees}
        label="Past due fees (optional)"
        padFractionalZeros={true}
        disabled={readOnly}
      />
      <MuiSelect
        label="Has forbearance"
        value={booleanOrNoneToValue(lien.isForbearance)}
        options={booleanOptionsWithUnknown}
        disabled={readOnly}
        theme="outlined"
        onChange={doNothingOnChange}
        width="100%"
      />
      <MuiSelect
        label="On property report"
        value={booleanOrNoneToValue(lien.onPropertyReport)}
        options={booleanOptionsWithUnknown}
        disabled={readOnly}
        theme="outlined"
        onChange={doNothingOnChange}
        width="100%"
      />
      {lien.onPropertyReport && (
        <>
          <MuiTextInput
            value={lien.position}
            label="Position"
            mask={[
              {
                mask: Number,
                signed: false,
                allowDecimal: false,
              },
            ]}
            unmask="typed"
            theme="outlined"
            width="100%"
            disabled={readOnly}
          />
          <CurrencyInput
            padFractionalZeros={true}
            value={lien.originalAmount}
            label="Original Amount"
            disabled={readOnly}
          />
          <div className={'TwoItemFormRow'}>
            <DatePicker
              value={lien.lienDate}
              label={'Date recorded'}
              onChange={doNothingOnChange}
              theme="outlined"
              disabled={readOnly}
              className="FullWidthDatePicker"
              wrapperClassName="FullWidthDatePicker"
            />
            <DatePicker
              value={lien.endOfTermDate}
              label={'Term date'}
              onChange={doNothingOnChange}
              theme="outlined"
              disabled={readOnly}
              className="FullWidthDatePicker"
              wrapperClassName="FullWidthDatePicker"
            />
          </div>
          <div className={'TwoItemFormRow'}>
            <MuiTextInput
              value={lien.rate}
              label="Rate"
              mask={[
                {
                  mask: Number,
                  radix: '.',
                  scale: 3,
                },
              ]}
              unmask="typed"
              endAdornment="%"
              theme="outlined"
              width="100%"
              disabled={readOnly}
            />
            <MuiSelect
              label="Rate type"
              value={lien.rateType?.toLowerCase()}
              options={rateTypes}
              disabled={readOnly}
              theme="outlined"
              onChange={doNothingOnChange}
              width="100%"
            />
          </div>
        </>
      )}
      <MuiTextInput value={lien.notes} label="Notes" theme="outlined" width="100%" disabled={readOnly} />
      <br />
    </>
  );
};
