import { useSectionFormById, useUpdateSectionForm } from '../../../hooks/useSpecificContentQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { getIsTaskFormChanged } from 'apps/track-details/utils/taskForms';

export const useAdverseEventForm = adverseEvent => {
  const adverseEventId = adverseEvent?.identifier;

  const { updateSectionFormById } = useUpdateSectionForm();
  const { initialFormData, updateFormData, formData } = useSectionFormById(adverseEventId);

  const isFormChanged = useMemo(() => {
    return getIsTaskFormChanged(initialFormData, formData);
  }, [formData, initialFormData]);

  const resetData = useCallback(() => {
    if (initialFormData) {
      updateFormData(initialFormData);
    }
  }, [initialFormData, updateFormData]);

  useEffect(() => {
    updateSectionFormById(adverseEventId, {
      isFormChanged,
      resetData,
    });
  }, [adverseEventId, isFormChanged, resetData, updateSectionFormById]);
};
