import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_TRACK_DETAILS_FOR_REGGORA_ORDER } from '../../ApplicationReview/sections/HomeValuationsController/reggoraRequests';

/**
 * Returns all information about the Reggora orders
 * @param {String} trackId id of the track we need the appraisals for
 * @returns paramaters needed to create a new Reggora order, preformatted for form and non-form use
 */
const useNewAppraisalOrder = trackId => {
  const [prefilledOrderValues, setPrefilledOrderValues] = useState(null);

  const { data: trackDetailsForReggoraOrderData, loading: trackDetailsForReggoraOrderDataLoading } = useQuery(
    GET_TRACK_DETAILS_FOR_REGGORA_ORDER,
    {
      variables: { trackId: trackId },
    },
  );

  useEffect(() => {
    if (trackDetailsForReggoraOrderData && !trackDetailsForReggoraOrderDataLoading) {
      //Format the data gotten from the track request
      const { track } = trackDetailsForReggoraOrderData;
      const [addressLine1, addressLine2] = formatAddress(track?.home?.address);
      const primaryApplicant = track.applicants?.find(applicant => applicant.isPrimaryApplicant) || null;

      const flattenedTrackData = {
        friendlyId: track?.friendlyId,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        propertyType: track?.home?.propertyTypeLabel,
        primaryApplicant: primaryApplicant,
        appraisalType: track?.appraisalType?.label,
      };

      // Set the values used by the component according to the formatted data
      setPrefilledOrderValues(flattenedTrackData);
    }
  }, [trackDetailsForReggoraOrderData, trackDetailsForReggoraOrderDataLoading]);

  /**
   * Used to turn the address into a two-line element
   */
  const formatAddress = addressObj => {
    const addressLine1 = addressObj?.unit ? `${addressObj?.street},  ${addressObj?.unit}` : `${addressObj?.street}`;
    const addressLine2 = `${addressObj?.city} ${addressObj?.state}, ${addressObj?.zipCode}`;
    return [addressLine1, addressLine2];
  };

  return {
    prefilledOrderValues,
    trackDetailsForReggoraOrderDataLoading,
  };
};
export default useNewAppraisalOrder;
