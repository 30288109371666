import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FILL_ALL_FUNDS } from '../fundingRequests';
import { TOAST_TYPE, showNotification } from 'utils/toasts';

/**
 * Returns all pertinent info about the tracks and their funding statuses
 * @param {Array} tracks tracks that should be shown on the funding page
 * @param {Dict} fundNameDict dictionary of the formal names for funds
 * @returns
 */
const useFundingData = tracksFundingData => {
  const [combinedFormattedTrackFundData, setCombinedFormattedTrackFundData] = useState([]);
  const [fillAllFundsMutation, { loading: rerunBuyBoxLoading }] = useMutation(FILL_ALL_FUNDS);

  /**
   * Keeps track of whether tracks have selected ineligible funds
   * @returns whether the tracks have selected funds that are on their rejected list
   */
  const doTracksHaveSelectedRejectedFunds = () => {
    const hasRejected = combinedFormattedTrackFundData.map(track => {
      if (track.fundData.rejected.includes(track.fundData.selected)) {
        return true;
      }
      return false;
    });
    return hasRejected.includes(true);
  };

  /**
   *
   */
  const changedTracksList = combinedFormattedTrackFundData => {
    // determine which tracks have been changed by checking selected compared to initial
    return combinedFormattedTrackFundData
      .map(track => {
        if (
          track.fundData.selected !== track.fundData.initial &&
          track.fundData.selected !== null &&
          track.fundData.selected !== ''
        ) {
          return track;
        }
        return null;
      })
      .filter(track => track !== null);
  };

  const formatRejectedDetails = rejectedDetails => {
    const rejectionReasonsByFund = {};
    if (rejectedDetails) {
      rejectedDetails.forEach(({ fund, reasons }) => {
        rejectionReasonsByFund[fund] = reasons;
      });
    }
    return rejectionReasonsByFund;
  };

  useEffect(() => {
    const formattedCombinedTracks = [];
    if (tracksFundingData !== undefined) {
      tracksFundingData.forEach(track => {
        formattedCombinedTracks.push({
          ...track.node,
          id: track.node.identifier,
          fundData: {
            trackFundDataId: track?.node?.fundData?.identifier,
            selected: track?.node?.fund,
            rejected: track?.node?.fundData?.rejectedFunds || [],
            suitable: track?.node?.fundData?.suitableFunds || [],
            // this should never change as it is the selected fund for this track
            preferred: track?.node?.fundData?.selectedFund,
            initial: track?.node?.fund,
            rejectedDetails: formatRejectedDetails(track?.node?.fundData?.rejectedFundDetails),
          },
        });

        setCombinedFormattedTrackFundData(formattedCombinedTracks);
      });
    }
  }, [tracksFundingData]);

  const handleReRunBuyBox = async () => {
    // get tracks that have a blank selected fund
    const tracksWithoutEligibleFund = combinedFormattedTrackFundData
      ?.filter(track => track?.fundData?.selected === null)
      .map(track => track?.friendlyId);

    try {
      const { data } = await fillAllFundsMutation({
        variables: {
          friendlyIds: tracksWithoutEligibleFund,
        },
      });
      const tracksFundData = data?.getAllocatedFundForTracks?.trackFundData;
      // clone combinedFormattedTrackFundData to avoid mutating state
      const combinedFormattedTrackFundDataClone = Object.assign([], combinedFormattedTrackFundData);

      tracksFundData?.forEach(trackFundData => {
        const friendlyId = trackFundData?.friendlyId;
        const fundData = trackFundData?.fundData;

        // find the track in combinedFormattedTrackFundData based on friendlyId
        const trackIndex = combinedFormattedTrackFundDataClone?.findIndex(track => track?.friendlyId === friendlyId);
        const track = combinedFormattedTrackFundDataClone[trackIndex];

        track.fundData.selected = fundData?.selectedFund;

        // if there is a selected_fund and it is not rejected, set it as preferred
        if (fundData?.selectedFund && !fundData?.rejectedFunds?.includes(fundData?.selectedFund)) {
          track.fundData.preferred = fundData?.selectedFund;
        }

        track.fundData.suitable = fundData?.suitableFunds;
        track.fundData.rejected = fundData?.rejectedFunds.map(rejectedFundDetails => rejectedFundDetails?.fund);
        // format to [fund]: [reasons]
        track.fundData.rejectedDetails = {};
        for (const rejectedFundDetails of fundData?.rejectedFunds) {
          track.fundData.rejectedDetails[rejectedFundDetails?.fund] = rejectedFundDetails?.reasons;
        }
      });

      // set combinedFormattedTrackFundData to the clone
      setCombinedFormattedTrackFundData(combinedFormattedTrackFundDataClone);

      showNotification({
        type: TOAST_TYPE.success,
        title: 'success',
        description: 'Successfully filled blank funds',
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'error',
        description: 'Error filling blank funds',
      });
    }
  };

  return {
    combinedFormattedTrackFundData,
    setCombinedFormattedTrackFundData,
    changedTracksList,
    doTracksHaveSelectedRejectedFunds,
    handleReRunBuyBox,
    rerunBuyBoxLoading,
  };
};
export default useFundingData;
