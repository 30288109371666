import React, { useState } from 'react';
import { SlideSide, IconButton } from '@hometap/htco-components';
import GuideCalculatorController from 'apps/investment-calculators/GuideCalculatorController';
import './GuideCalculator.scss';

const GuideCalculator = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <IconButton
        icon="calculator"
        className="GuideCalculatorButton"
        onClick={() => {
          setVisible(!visible);
        }}
      />
      <SlideSide
        onBackdropClick={() => setVisible(false)}
        maxWidth={724}
        visible={visible}
        isOverlay={true}
        displayBackdrop={true}
        direction="right"
      >
        <div className="GuideCalculatorCloseButtonWrapper">
          <IconButton
            icon="close"
            size="2x"
            className="GuideCalculatorCloseButton"
            onClick={() => {
              setVisible(!visible);
            }}
          />
        </div>
        <GuideCalculatorController />
      </SlideSide>
    </>
  );
};

export default GuideCalculator;
