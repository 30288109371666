import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Loader } from '@hometap/htco-components';
import HometapError from 'components/HometapError';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CannotEditAlert } from './components/Alert/CannotEditAlert';
import {
  ApplicantDetailsSidebarController,
  OpportunityDetailsSidebarController,
  PropertyDetailsSidebarController,
  RentalEligibilitySidebarController,
} from './sidebarDetails';
import {
  BackgroundCheckController,
  FloodReportController,
  RentalAgreementController,
  HOAController,
  PropertyTaxController,
  HomeValuationsController,
  InsurancePoliciesController,
  InvestmentIncreaseController,
  PropertyReportController,
  TitleMembersController,
  UnderwritingDetailsController,
  LiensAndPaydownsController,
  AdverseEventController,
} from './sections';
import { GET_TRACK_DATA } from 'apps/track-details/ApplicationReview/data/queries/getTrackData';

import './TrackApplicationReviewController.scss';
import { ApplicationReviewExpiringAlerts } from './components/ApplicationReviewExpiringAlerts/ApplicationReviewExpiringAlerts';
import { SectionFormsProvider } from './hooks/useSpecificContentQuery';
import { CONFIGURATIONS_RESIDENCE_TYPE } from 'data/constants/configurations';

const TrackApplicationReviewController = () => {
  const { trackId } = useParams();
  const { hash } = useLocation();
  const { loading = true, error, data, refetch } = useQuery(GET_TRACK_DATA, { variables: { trackId } });
  const { track } = data || {};
  const { canRead, canWrite } = track?.applicationReviewPermissions || {};
  const homeIsRental = track?.home?.residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental;
  const hasUWDSCR = track?.uwDscr !== null && track?.uwDscr !== undefined;
  const hasRentalAgreement = track?.rentalAgreementReview;
  const homeHasHOA = track?.application.hasHoa;
  const hasHOAReview = track?.hoaReview;
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to tasks page if user does not have read access
    if (!loading && !canRead) {
      return navigate(`/tracks/${trackId}/tasks/`);
    }

    // scroll to the appropriate section post-render if navigating from the related edit/history view
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  if (error) {
    return (
      <HometapError title="We are having trouble fetching the data for this track." error={`500 error`}>
        Please try refreshing. If the problem persists please contact engineering.
      </HometapError>
    );
  }

  if (!loading && !track) {
    return (
      <div>
        We're having trouble fetching this homeowner's track data. Please try refreshing. If the problem persists please
        contact engineering
      </div>
    );
  }

  const editDisabled = track && (Boolean(track.applicationIsApprovedOrClosedLost) || !canWrite);
  const applicationIsApproved = track && track.applicationIsApprovedOrClosedLost === 'APPROVED';
  const trackInvestmentManager = track && track.investmentCoordinator;

  return (
    <Container className="TrackApplicationReviewController" row>
      <Container className="TrackApplicationReviewColumn" grid={9}>
        {loading ? (
          <div className="CenterAlign">
            <Loader type="dot-pulse" />
          </div>
        ) : (
          <>
            <div className="TrackApplicationReviewAlertsContainer">
              {editDisabled && (
                <CannotEditAlert
                  applicationIsApproved={applicationIsApproved}
                  trackInvestmentManager={trackInvestmentManager}
                />
              )}
              {track && <ApplicationReviewExpiringAlerts track={track} />}
            </div>
            <UnderwritingDetailsController track={track} editDisabled={editDisabled} />
            <SectionFormsProvider>
              <LiensAndPaydownsController track={track} editDisabled={editDisabled} refetch={refetch} />
            </SectionFormsProvider>
            <SectionFormsProvider>
              <InsurancePoliciesController track={track} editDisabled={editDisabled} refetch={refetch} />
            </SectionFormsProvider>
            <PropertyReportController track={track} editDisabled={editDisabled} />
            <TitleMembersController track={track} editDisabled={editDisabled} />
            <BackgroundCheckController track={track} editDisabled={editDisabled} />
            <SectionFormsProvider>
              <AdverseEventController editDisabled={editDisabled} />
            </SectionFormsProvider>
            <FloodReportController track={track} editDisabled={editDisabled} />
            <HomeValuationsController track={track} editDisabled={editDisabled} />
            <PropertyTaxController track={track} editDisabled={editDisabled} refetch={refetch} />
            <InvestmentIncreaseController track={track} editDisabled={editDisabled} />
            {/* Even if Home is no longer marked as being in HOA, if there is HOAReview, display it
              so that it can be deleted  */}
            {(homeHasHOA || hasHOAReview) && (
              <HOAController
                track={track}
                canDelete={!homeHasHOA && hasHOAReview}
                editDisabled={editDisabled}
                refetch={refetch}
              />
            )}
            {/* Even if Home is no longer marked as being a Rental, if there is
             RentalAgreementReview, display it so that it can be deleted  */}
            {(homeIsRental || hasRentalAgreement) && (
              <RentalAgreementController
                track={track}
                canDelete={!homeIsRental && hasRentalAgreement}
                editDisabled={editDisabled}
                refetch={refetch}
              />
            )}
          </>
        )}
      </Container>
      <Container className="TrackApplicationReviewSidebarDetailsContainer" grid={3}>
        {loading ? (
          <div className="CenterAlign">
            <Loader type="dot-pulse" />
          </div>
        ) : (
          <>
            <OpportunityDetailsSidebarController track={track} />
            <PropertyDetailsSidebarController track={track} />
            {/* Display DSCR if home is currently a rental OR it has Track.uw_dscr value indicating
            it was underwritten and presumably final approved while a rental. This is important for
            rentals we invest in that become non-rentals. As implemented, this will also display for
            non-rentals we invest in that become rentals, but that is okay because DSCR will appear
            as missing. If we don't want this to happen, we could instead take Track stage into
            account when displaying. However, long term we probably want to simplify this display
            and instead only display Track.uw_dscr once final approved, and otherwise compute and
            display what is essentially an estimated DSCR.*/}
            {(homeIsRental || hasUWDSCR) && <RentalEligibilitySidebarController track={track} />}
            <ApplicantDetailsSidebarController track={track} />
          </>
        )}
      </Container>
    </Container>
  );
};

export default TrackApplicationReviewController;
