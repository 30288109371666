export const failureReasonByCode = codeToFind => uwFailedEligibilityCriteria => {
  if (uwFailedEligibilityCriteria) {
    return uwFailedEligibilityCriteria.find(failureReason => {
      return failureReason.code === codeToFind;
    })?.details;
  }
};

// Known errors
export const riskBand6ErrorFromCriteria = failureReasonByCode('RB6_CRITERIA_FAILED');
export const upshareErrorFromCriteria = failureReasonByCode('MAX_HOMETAP_UPSHARE_FAILED');
export const homeownerEndingEquityErrorFromCriteria = failureReasonByCode('NOT_ENOUGH_HOMEOWNER_ENDING_EQUITY');
export const totalEndingEquityErrorFromCriteria = failureReasonByCode('NOT_ENOUGH_TOTAL_ENDING_EQUITY');
export const investmentAmountErrorFromCriteria = failureReasonByCode('INVESTMENT_AMOUNT_IS_TOO_LOW');
export const totalPaydownExceedsErrorFromCriteria = failureReasonByCode('TOTAL_PAYDOWN_EXCEEDS_MAX_AMOUNT');

export const rentalCltvTooHighErrorFromCriteria = failureReasonByCode('RENTAL_CLTV_TOO_HIGH');
export const rentalDebtServiceCoverageRatioErrorFromCriteria = failureReasonByCode('RENTAL_DSCR_TOO_LOW');
export const rentalLienPositionErrorFromCriteria = failureReasonByCode('RENTAL_LIEN_POSITION_TOO_HIGH');
export const rentalCompositeFicoErrorFromCriteria = failureReasonByCode('RENTAL_COMPOSITE_FICO_TOO_LOW');

// The following are Known error cases that are intentionally unhandled because they practically should not happen
// export const downshareErrorFromCriteria = failureReasonByCode('MAX_HOMETAP_DOWNSHARE_FAILED');
