import { gql } from '@apollo/client';

export const GET_MINIMUM_REQUIRED_DOCS_DATA = gql`
  query MinimumRequiredDocs($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      rolloutFlags
      liens {
        kind
        asOfDate
        pastDueAmount
        identifier
        kindLabel
        isForbearance
      }

      home {
        identifier
        propertyType
        propertyTypeLabel
      }

      applicants {
        isPrimaryApplicant
        fullNameShort
        id

        governmentId {
          nameAsAppearsOnId
          expirationDate
          identifier
        }
      }
    }
  }
`;
