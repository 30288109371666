import React from 'react';
import cx from 'classnames';
import './AVStatus.scss';

/**
 * @typedef AVStatusParams
 * @type {object}
 * @property {import('../../documentRequests').DocumentOrVersion} viewDocumentOrVersion
 */

/** Document Anti-virus status component
 * @param {AVStatusParams} params
 * @returns {JSX.Element?}
 *    -   Fetching Document/Version                         ->  null
 *    -   File is Clean or Anti-Virus Disabled              ->  null
 *    -   File is Infected, Encrypted, Hasn't been scanned  ->  Anti-Virus Status component
 */
export default function AVStatus({ viewDocumentOrVersion }) {
  const { isEncrypted, isInfected, isUnscanned } = viewDocumentOrVersion ?? {};

  let message;
  if (isEncrypted) {
    message = 'Document is password protected and has not been scanned for virus protection.';
  } else if (isInfected) {
    message = 'Document contains a virus. No download or preview available.';
  } else if (isUnscanned) {
    message = 'Document has not yet been scanned for protection. No download or preview available.';
  } else {
    // short-circuit
    return null;
  }

  return (
    <div className="AVStatusContainer">
      <div className={cx('AVStatus', { isEncrypted })}>{message}</div>
    </div>
  );
}
