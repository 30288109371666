import isEqual from 'lodash/isEqual';
import { getDocKindElseTaskDefKey } from '../tasks/trackTasksUtils';
import { TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT } from '../tasks/data/constants/taskSpecificMultipleFormsContent';

/* isFormChanged is used to check if the data in a form has been changed
 * we delete all the unnecessary properties and properties with undefined values in the objects
 * we compare the formData with the initialFormData to see if there are any differences.
 */
export const getIsTaskFormChanged = (initialFormData, formData) => {
  if (formData && initialFormData && Object.keys(formData)?.length && Object.keys(initialFormData)?.length) {
    const formDataDuplicate = { ...formData };
    Object.keys(formDataDuplicate).forEach(
      key => (!formDataDuplicate[key] || !initialFormData.hasOwnProperty(key)) && delete formDataDuplicate[key],
    );
    delete formDataDuplicate.isValid;

    const initialFormDataDuplicate = { ...initialFormData };
    Object.keys(initialFormDataDuplicate).forEach(
      key => !initialFormDataDuplicate[key] && delete initialFormDataDuplicate[key],
    );
    delete initialFormDataDuplicate.isValid;

    return !isEqual(formDataDuplicate, initialFormDataDuplicate);
  }
  return false;
};

export const getSelectionYesNoInitialValue = value => (typeof value === 'boolean' && value.toString()) || undefined;

export const RATE_TYPE_UNKNOWN = 'a_';
export const MORTGAGE_LIEN_TYPE = 'MORTGAGE';
export const HELOC_LIEN_TYPE = 'HELOC';
export const HOME_EQUITY_LOAN_LIEN_TYPE = 'HOME_EQUITY_LOAN';
export const PRIVATE_MORTGAGE_LIEN_TYPE = 'PRIVATE_MORTGAGE';
export const JUDGEMENT_LIEN_TYPE = 'JUDGEMENT';
export const TAX_LIEN_TYPE = 'TAX_LIEN';
export const PARTIAL_CLAIM_LIEN_TYPE = 'PARTIAL_CLAIM';
export const OTHER_LIEN_TYPE = 'OTHER';
export const LIEN_KINDS_WITH_REQUIRED_RATE_TYPE = [
  MORTGAGE_LIEN_TYPE,
  HELOC_LIEN_TYPE,
  HOME_EQUITY_LOAN_LIEN_TYPE,
  PRIVATE_MORTGAGE_LIEN_TYPE,
];
export const OTHER_LIEN_KINDS = [JUDGEMENT_LIEN_TYPE, TAX_LIEN_TYPE, PARTIAL_CLAIM_LIEN_TYPE, OTHER_LIEN_TYPE];

export const PROPERTY_REPORT_FIELDS = ['position', 'originalAmount', 'lienDate', 'rate', 'rateType', 'endOfTermDate'];

export const getRateTypeOptions = (options = [], lienKind) => {
  if (LIEN_KINDS_WITH_REQUIRED_RATE_TYPE.includes(lienKind)) {
    return options.filter(({ value }) => value !== '');
  }
  return options.map(option => (option.value === '' ? { ...option, value: RATE_TYPE_UNKNOWN } : option));
};

export const getIsMultipleFormsTask = task => {
  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);
  return !!TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT[docKindElseTaskDefKey];
};

export const getRandomId = () => Math.floor(Math.random() * 10000000);

export const getRateTypeInitialValue = (value, lienKind) => {
  if (value === null) {
    // it is important to verify the type of lien that we are dealing with at the moment
    return LIEN_KINDS_WITH_REQUIRED_RATE_TYPE.includes(lienKind) ? undefined : RATE_TYPE_UNKNOWN;
  }
  return value?.toLowerCase();
};

//  to compare two objects, but some of the properties might have values represented as strings in one object
//  and numbers in the other
export const convertNumericStringsToNumbers = obj => {
  const copyObj = { ...obj };
  for (const key in copyObj) {
    if (copyObj.hasOwnProperty(key)) {
      const value = copyObj[key];

      if (typeof value === 'string' && !isNaN(Number(value))) {
        copyObj[key] = Number(value);
      } else if (typeof value === 'object' && value !== null) {
        convertNumericStringsToNumbers(value);
      }
    }
  }
  return copyObj;
};

export const getDocIdsToLinkInitialValue = lien => {
  const linkedDoc = lien?.document?.identifier;
  if (linkedDoc) {
    return [linkedDoc];
  }
  return [];
};
