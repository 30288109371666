import React, { useState, useEffect, useMemo } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useParams } from 'react-router';
import env from 'utils/env';
import { Loader, TextInput, Checkbox, Button, useForm, DropdownSelector } from '@hometap/htco-components';
import useConfigurations from 'hooks/useConfigurations';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { currency } from 'utils/numbers';
import './HomeValuationsController.scss';
import ReadOnlyFieldGroup from '../../components/ReadOnlyFieldGroup/ReadOnlyFieldGroup';
import useAppraisalsData from '../../../../track-details/Appraisals/hooks/useAppraisalsData';
import { extractFormNumber, getReggoraLink, PRODUCT_NUMBERS_TRADITIONAL } from '../../../utils/reggora';
import { formatFullMonthDayYear } from '../../../../../utils/dates';
import HistoricalHomeValuationDisplay from './HistoricalHomeValuationDisplay';
import { SUMMARY_DIVIDER } from '../../components/Summary/Summary';

export const HomeValuationsController = ({ track, editDisabled }) => {
  const { propertyConditions } = useConfigurations();

  const { trackId } = useParams();
  const historicalHomeValuationsLabel = 'Historical Home Valuations';
  const historicalHomeValuationsId = 'historical-home-valuations';

  const { isInUnderwriterGroup } = useCurrentUser();
  const { homeValuationReviews, homeValuationReviewKindOptions, dateOfFinalApproval } = track;
  // information about track's appraisals
  const {
    formattedAppraisalData,
    isDataTableLoading: isReggoraOrdersLoading,
    changeOrderApprovalStatus,
    changePropertyCondition,
    addOrderNotes,
  } = useAppraisalsData(track.identifier);

  // for the notes and 'verify' checkbox for each appraisal
  const { registerField, formData, updateFormData } = useForm({});

  const handleOrderApprovalChecked = orderId => {
    const currentCheckedValue = formData?.[`${orderId}:underwriterApproved`];
    changeOrderApprovalStatus(orderId, !currentCheckedValue);
  };

  const handleChangePropertyCondition = (orderId, value) => {
    updateFormData({
      [`${orderId}:propertyCondition`]: value,
    });
    changePropertyCondition(orderId, value);
  };

  const handleAddOrderNotes = orderId => {
    const notes = formData?.[`${orderId}:notes`];
    addOrderNotes(orderId, notes ?? '');
  };

  /**
   * @description determine whether or not the given appraisal was created before a track's date of final approval
   * @param appraisal {object} a Reggora order
   * @param dateOfFinalApproval {String} the current Track's date of final approval
   */
  const isCreatedBeforeFinalApproval = (appraisal, dateOfFinalApproval) => {
    if (dateOfFinalApproval) {
      const finalApproval = new Date(dateOfFinalApproval);
      const createdDatetime = new Date(appraisal.orderCreatedDatetime);
      return createdDatetime < finalApproval;
    }
    return true;
  };
  /**
   * @description Filter by appraisal of record. If an item has it set to true, only show this item, otherwise show all items
   * @param item {object} a Reggora order. The current item.
   * @param index {Number} the current index.
   * @param array {Array} the entire array of Reggora orders.
   */
  const filterByAppraisalOfRecord = (item, index, array) => {
    // Check if there's any element with appraisalOfRecord set to true
    const appraisalOfRecord = array?.find(item => item.appraisalOfRecord === true);

    // If such an element exists, only include this element
    if (appraisalOfRecord) {
      return item?.appraisalOfRecord === true;
    }

    // Otherwise, include all elements
    return true;
  };

  const { historyHomeValuationStatusUrl, editHomeValuationStatusUrl } = getApplicationReviewPageUrls(trackId);
  const navToEditView = () => (window.location.href = editHomeValuationStatusUrl);

  /**
   * Returns the "Ordered on" content string given when the Reggora order was created and who created it (there may not be a specific createdBy if these are historical Reggora orders created by the cron backfill script).
   *
   * @param {string} orderCreatedDatetime - The date and time when the order was created.
   * @param {Object | null} createdBy - The object containing information about the user who created the order.
   * @returns {string} The ordered on content string.
   */
  const getOrderedOnContent = (orderCreatedDatetime, createdBy) => {
    if (createdBy?.user?.firstName && createdBy?.user?.lastName) {
      return `${formatFullMonthDayYear(orderCreatedDatetime)} by ${createdBy?.user?.firstName} ${
        createdBy?.user?.lastName
      }`;
    }
    return `${formatFullMonthDayYear(orderCreatedDatetime)}`;
  };

  const [activeSelectorId, setActiveSelectorId] = useState(null);
  const [pickList, setPickList] = useState([]);

  useEffect(() => {
    const updatedPickList = [];
    if (formattedAppraisalData?.length) {
      const reggoraOrderPickListItems = formattedAppraisalData
        ?.filter(appraisal => isCreatedBeforeFinalApproval(appraisal, dateOfFinalApproval))
        ?.filter(filterByAppraisalOfRecord)
        .map(appraisal => ({
          ...appraisal,
          subSectionLabel: appraisal.product,
          valueLabel: 'Status: ',
          value: <b>{appraisal.orderStatus}</b>,
          selectorId: appraisal.orderId,
        }));
      updatedPickList.push(...reggoraOrderPickListItems);
    }

    // create the Historical Home Valuations sub section if applicable
    if (homeValuationReviews?.length) {
      updatedPickList.push({
        subSectionLabel: historicalHomeValuationsLabel,
        selectorId: historicalHomeValuationsId,
      });
    }

    // runs when the formattedAppraisalData is initially fetched from GraphQL query
    // (when activeSelectorId is still null from starting value)
    //
    // Since isReggoraOrdersLoading is unreliable for determining when the data is fully loaded and state is set we determine if
    // data has been fetched/ parsed by if the formattedAppraisalData is not undefined
    if (updatedPickList.length && activeSelectorId === null && formattedAppraisalData?.length >= 0) {
      // if there are Reggora orders, they are prioritized over historical home valuations to be selected
      if (formattedAppraisalData?.length) {
        setActiveSelectorId(updatedPickList[0].selectorId);
      }
      // if there are no Reggora orders, but there are historical home valuations, select the historical section
      else if (homeValuationReviews?.length) {
        setActiveSelectorId(historicalHomeValuationsId);
      }
    }

    setPickList(updatedPickList);

    // sync appraisal data to formData
    (formattedAppraisalData || []).forEach(appraisal => {
      updateFormData({
        [`${appraisal.orderId}:propertyCondition`]: appraisal.propertyCondition,
        [`${appraisal.orderId}:notes`]: appraisal.notes,
        [`${appraisal.orderId}:underwriterApproved`]: appraisal.underwriterApproved,
      });
    });
  }, [activeSelectorId, dateOfFinalApproval, formattedAppraisalData, homeValuationReviews, updateFormData]);

  /**
   * @description change the activeSelectorId when different sections are clicked
   * @param selectorId {String} the selectorId for the chosen pick list section
   */
  const changeSelectedSection = selectorId => {
    if (selectorId !== activeSelectorId) {
      const selectedSectionId = pickList.find(section => section.selectorId === selectorId).selectorId;
      setActiveSelectorId(selectedSectionId);
    }
  };

  const valuationCombinedValue = useMemo(() => {
    if (track.beginningHomeValuation) {
      return currency(track.beginningHomeValuation.value);
    } else if (track.relevantHomeValuation) {
      return currency(track.relevantHomeValuation.value);
    }
    return '';
  }, [track]);

  const valuationCombinedLabel =
    !track.beginningHomeValuation && track.relevantHomeValuation
      ? 'Relevant Home Valuation'
      : 'Beginning Home Valuation';

  const getActiveSectionContent = () => {
    if (isReggoraOrdersLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      );
    }

    if (activeSelectorId === null) {
      return <div>There is no home valuations status data.</div>;
    }

    if (activeSelectorId === historicalHomeValuationsId) {
      return homeValuationReviews.map((homeValReview, index) => {
        return (
          <HistoricalHomeValuationDisplay
            key={`homeValReview-${homeValReview.orderIndex}`}
            homeValReview={homeValReview}
            index={index}
            homeValuationReviewKindOptions={homeValuationReviewKindOptions}
          />
        );
      });
    }

    // else we have a Reggora Order actively selected in the pick list
    const selectedReggoraOrder = (formattedAppraisalData || []).find(order => order?.orderId === activeSelectorId);
    const {
      orderId,
      loanNumber,
      product,
      createdBy,
      orderCreatedDatetime,
      inspectionDatetime,
      acceptedVendorName,
      appraisalSubmissionDatetime,
      document,
      totalFee,
    } = selectedReggoraOrder;
    const isTraditionalProduct = PRODUCT_NUMBERS_TRADITIONAL.includes(extractFormNumber(product || ''));
    return (
      <div key={`reggoraOrderReview-${orderId}`} className="flex flex-col gap-[16px]">
        <ReadOnlyFieldGroup
          items={[
            {
              title: 'Order ID',
              content: loanNumber,
              linkComponent: (
                <Button
                  theme="link"
                  icon="arrow-square-up-right"
                  label={loanNumber}
                  className="ReggoraOrderLink"
                  onClick={() => {
                    window.open(getReggoraLink(orderId, env.isProd()), '_blank');
                  }}
                />
              ),
            },
            ...(document
              ? [
                  {
                    title: 'Document',
                    content: document.filename,
                    linkComponent: (
                      <span
                        className="ReggoraDocumentsLink flex w-full text-ellipsis whitespace-nowrap font-semibold"
                        onClick={() => {
                          window.open(`/tracks/${track.identifier}/documents/${document.identifier}`, '_blank');
                        }}
                      >
                        {document.filename}
                      </span>
                    ),
                  },
                ]
              : []),
          ]}
        />
        {(orderCreatedDatetime || inspectionDatetime) && (
          <ReadOnlyFieldGroup
            items={[
              ...(orderCreatedDatetime
                ? [
                    {
                      title: 'Ordered on',
                      content: getOrderedOnContent(orderCreatedDatetime, createdBy),
                    },
                  ]
                : []),
              ...(inspectionDatetime
                ? [{ title: 'Scheduled for', content: formatFullMonthDayYear(inspectionDatetime) }]
                : []),
            ]}
          />
        )}
        {(acceptedVendorName || appraisalSubmissionDatetime) && (
          <ReadOnlyFieldGroup
            items={[
              ...(acceptedVendorName ? [{ title: 'Provider', content: acceptedVendorName }] : []),
              ...(appraisalSubmissionDatetime
                ? [
                    {
                      title: 'Appraisal Submitted on',
                      content: formatFullMonthDayYear(appraisalSubmissionDatetime),
                    },
                  ]
                : []),
            ]}
          />
        )}
        {isTraditionalProduct && (
          <ReadOnlyFieldGroup
            items={[
              {
                title: 'Appraisal Cost',
                content: totalFee ? `$${totalFee}` : '--',
              },
            ]}
          />
        )}
        <div className="PropertyConditionContainer mt-[8px] flex flex-col gap-[4px]">
          <DropdownSelector
            isMuiSelect={true}
            theme="outlined"
            width={'100%'}
            label={'Property condition'}
            options={propertyConditions}
            {...registerField(`${orderId}:propertyCondition`)}
            onChange={value => {
              handleChangePropertyCondition(orderId, value);
            }}
          />
        </div>
        <div className="NotesAreaContainer">
          <TextInput
            label="Notes"
            type="textarea"
            {...registerField(`${orderId}:notes`)}
            onBlur={() => {
              handleAddOrderNotes(orderId);
            }}
          />
        </div>
        <Checkbox
          label={
            <div>
              <b>Verify appraisal </b>
              <div>
                I confirm that the appraisal document has been reviewed, all information is valid and meets our
                guidelines.
              </div>
            </div>
          }
          name="underwriterApproved"
          disabled={!isInUnderwriterGroup || !formData?.[`${orderId}:propertyCondition`]}
          checked={formData?.[`${orderId}:underwriterApproved`]}
          {...registerField(`${orderId}:underwriterApproved`)}
          // since checkbox is expecting a string, we need to pass a string value
          value={formData?.[`${orderId}:underwriterApproved`] ? 'true' : 'false'}
          onChange={() => {
            handleOrderApprovalChecked(orderId);
          }}
        />
      </div>
    );
  };

  return (
    <ApplicationReviewSection
      anchorId="home-valuation-statuses-section"
      sectionTitle={'Home valuation statuses'}
      historyUrl={historyHomeValuationStatusUrl}
      pickList={pickList}
      selectedId={activeSelectorId || ''}
      onSelect={changeSelectedSection}
      onEdit={editDisabled ? null : navToEditView}
      visibleSummary={true}
      summaryParams={[
        SUMMARY_DIVIDER,
        {
          label: valuationCombinedLabel,
          value: valuationCombinedValue,
        },
        SUMMARY_DIVIDER,
      ]}
    >
      {getActiveSectionContent()}
    </ApplicationReviewSection>
  );
};

export default HomeValuationsController;
