import { useAsync } from '@hometap/htco-components';
import { getTaskDocuments, getTrackDocuments } from '../../Documents/documentRequests';
import { getApplicantByPersonId } from 'apps/track-details/Documents/DocumentsUtils';
import { useEffect, useState } from 'react';

/**
 * @typedef UseTaskDocumentsReturn
 * @type {object}
 * @property {import('../../Documents/documentRequests').Document[]} documents List of documents being fetched
 * @property {boolean} documentsLoading Are the documents being fetched?
 * @property {import('axios').AxiosError?} documentsError An error raise by fetching the documents
 * @property {(taskId: string) => Promise<void>} executeGetTaskDocuments Execute the fetch documents request
 */

/** Use sorted track or task documents
 * @param {string} trackId Track ID
 * @param {string} taskId Task ID
 * @property {"allTrackDocs"|"allDocsThatMatchTaskKind"|"allDocsLinkedToTask"} taskDocVariant
 * @returns {UseTaskDocumentsReturn}
 */
export default function useTaskDocuments(trackId, taskId, taskDocVariant, applicants = []) {
  const [documents, setDocuments] = useState([]);

  const allTrackDocs = taskDocVariant === 'allTrackDocs';
  const allDocsThatMatchTaskKind = taskDocVariant === 'allDocsThatMatchTaskKind';

  const {
    results = [],
    loading: documentsLoading,
    error: documentsError,
    execute: executeGetTaskDocuments,
  } = useAsync(allTrackDocs ? getTrackDocuments : getTaskDocuments, {
    immediate: true,
    params: allTrackDocs ? [trackId] : [taskId, allDocsThatMatchTaskKind],
    defaultLoading: true,
  });

  useEffect(() => {
    // add additional properties: personId and  applicant to the Document
    if (applicants?.length && results.length) {
      setDocuments(
        results.map((document, index) => {
          const documentWithPerson = document.contexts?.find(({ context_kind }) => context_kind === 'Person');
          if (documentWithPerson) {
            return {
              ...document,
              personId: documentWithPerson.context_id,
              applicant: getApplicantByPersonId(documentWithPerson.context_id, applicants),
            };
          }
          return document;
        }),
      );
    } else if (results.length) {
      setDocuments(results);
    }
  }, [results, applicants]);

  // put the most recently modified pinned documents at the top
  documents.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at));
  documents.sort((a, b) => b.pinned - a.pinned);
  return {
    documents,
    documentsLoading,
    documentsError,
    executeGetTaskDocuments,
  };
}
