import React, { useEffect } from 'react';
import { bytesToSize } from 'utils/documents';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@hometap/htco-components';
import cx from 'classnames';
import {
  MAX_UPLOAD_SIZE_BYTES,
  MAX_BULK_UPLOAD_FILE_COUNT,
  ACCEPTED_FILE_TYPE_AND_EXTENSIONS,
} from '../../data/constants';
import './FileUploader.scss';

const ERROR_CODE_MESSAGE_MAP = {
  'file-invalid-type': "File extension is not allowed. Allowed extensions are: 'pdf', 'jpg', 'heic', & 'png'",
  'file-too-large': `File size exceeds limit of ${bytesToSize(MAX_UPLOAD_SIZE_BYTES)}.`,
};

export const FileUploader = ({ options, uploadTrigger, onUpload, setError }) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone({
    accept: ACCEPTED_FILE_TYPE_AND_EXTENSIONS,
    noClick: true,
    ...options,
  });

  useEffect(() => {
    if (uploadTrigger) {
      uploadTrigger.current = open;
    }
  }, [uploadTrigger, open]);

  useEffect(() => {
    if (acceptedFiles?.length || fileRejections?.length) {
      // set error to nothing if there is only accepted files
      if (acceptedFiles?.length && !fileRejections?.length) {
        setError();
      }
      onUpload({
        acceptedFiles,
        rejectedFiles: fileRejections.map(file => {
          return {
            file: file.file,
            errors: file.errors?.map(err => {
              return {
                ...err,
                message: ERROR_CODE_MESSAGE_MAP[err.code] || err.message,
              };
            }),
          };
        }),
      });
    }
    // Adding onUpload to the dependency array causes loops that are hard to work around if they need to
    // set state for the parent based on this data - leaving it out works as long as the onUpload method doesn't need to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, fileRejections]);

  return (
    <div {...getRootProps({ className: cx('FileUploader', { isDisabled: options?.disabled }) })}>
      <Icon size="3x" name="file-upload" className="FileUploaderIcon" />
      <span className="FileUploaderCTA">
        Drag and drop here, or{' '}
        <span onClick={open} className="FileUploaderCTAHighlight">
          pick from your files
        </span>
      </span>
      <span className="FileUploaderAdditionalInfo">
        Please note that you can upload up to {MAX_BULK_UPLOAD_FILE_COUNT} documents at once.
      </span>
      <span className="FileUploaderAdditionalInfo">Max. file size: 50MB</span>
      <input {...getInputProps()} />
    </div>
  );
};
