import { gql } from '@apollo/client';

export const CREATE_MORTGAGE = gql`
  mutation CreateMortgage(
    $asOfDate: Date!
    $currentBalance: Decimal!
    $docIdsToLink: [IdentifierType!]!
    $endOfTermDate: Date
    $escrowAmount: Decimal!
    $hasForbearance: Boolean!
    $holder: String!
    $interestAmount: Decimal!
    $isMoreThanOneMonthPastDue: Boolean!
    $lienDate: Date
    $mortgageAccountNumber: String!
    $mortgageInvestorKind: LienReviewMortgageInvestorKind!
    $notes: String
    $onPropertyReport: Boolean
    $originalAmount: Decimal
    $pastDueAmount: Decimal
    $pastDueFees: Decimal
    $position: Int
    $principalAmount: Decimal!
    $rate: Decimal
    $rateType: LienReviewRateType
    $trackId: IdentifierType!
    $hasAModification: Boolean
  ) {
    createMortgage(
      asOfDate: $asOfDate
      currentBalance: $currentBalance
      endOfTermDate: $endOfTermDate
      escrowAmount: $escrowAmount
      hasForbearance: $hasForbearance
      holder: $holder
      interestAmount: $interestAmount
      isMoreThanOneMonthPastDue: $isMoreThanOneMonthPastDue
      lienDate: $lienDate
      mortgageAccountNumber: $mortgageAccountNumber
      mortgageInvestorKind: $mortgageInvestorKind
      notes: $notes
      onPropertyReport: $onPropertyReport
      originalAmount: $originalAmount
      pastDueAmount: $pastDueAmount
      pastDueFees: $pastDueFees
      position: $position
      principalAmount: $principalAmount
      rate: $rate
      rateType: $rateType
      trackId: $trackId
      docIdsToLink: $docIdsToLink
      hasAModification: $hasAModification
    ) {
      mortgageLien {
        identifier
      }
    }
  }
`;
