import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MARK_DOCS_AS_REVIEWED } from 'apps/track-details/tasks/data/mutations';
import { showNotification, TOAST_TYPE } from 'utils/toasts';
import { TASK_RESOLUTION_KEYS } from 'data/constants/taskStatuses';
import { setJSONInLocalStorage } from 'hooks/useLocalStateForTrack';
import TASK_SPECIFIC_CONTENT_DEFINITIONS from 'apps/track-details/tasks/data/constants/taskSpecificContentDefinitions';
import { useSpecificContent, useUpdateSpecificTaskContent } from '../../../hooks/useSpecificContentQuery';
import useConfigurations from 'hooks/useConfigurations';
import { getDocKindElseTaskDefKey, getIsSimpleReviewTask } from 'apps/track-details/tasks/trackTasksUtils';
import { getIsTaskFormChanged } from 'apps/track-details/utils/taskForms';
import { getVariableValue } from '../../TaskList/TaskListUtils';
import isEqual from 'lodash/isEqual';
import { COMPLETE_DOC_REVIEW_TASK } from 'apps/track-details/tasks/data/mutations/completeDocReviewTask';
import { COMPLETE_SIMPLE_REVIEW_TASK } from 'apps/track-details/tasks/data/mutations/completeSimpleReviewTask';
import { REVIEW_PROPERTY_TAX_TASK_DEFINITION } from '../../../../../../data/constants/bpmConstants';

/**
 * @typedef TaskSpecificContentParams
 * @type {object}
 * @property {Task} task The task being displayed
 */

/**
 * @param {TaskSpecificContentParams} params
 * @returns HTML Element or null
 */
const TaskSpecificContentController = ({ task }) => {
  const SpecificContentComponent = TASK_SPECIFIC_CONTENT_DEFINITIONS[getDocKindElseTaskDefKey(task)]?.content;
  return SpecificContentComponent ? <SpecificContentComponent task={task} /> : null;
};

export default TaskSpecificContentController;

/**
 * @typedef UseTaskSpecificFormReturn
 * @type {UseFormReturn}
 * @property {(allConditionChanges: ConditionChange[]) => boolean} getIsValidTaskForm Is the task form valid?
 * @property {boolean} isClosing Is the current task in the process of closing?
 * @property {(resolution: string, complete: [function]) => void} closeTask Close the Task
 */
/**
 * @typedef UseTaskSpecificFormParams
 * @type {object}
 * @property {string} trackId Track ID
 * @property {string} [assignee]
 * @property {Applicant} [applicant] The applicant for this task
 * @property {Task} [task] GraphQL Selected Task once loaded
 * @property {ConditionChange[]} allConditionChanges required for the getIsValidTaskForm function
 */
/** Use a Task Specific Form
 * @param {UseTaskSpecificFormParams}
 * @returns {UseTaskSpecificFormReturn}
 */
export function useTaskSpecificForm({ trackId, task, allConditionChanges, applicant }) {
  const taskId = task?.identifier;
  const {
    specificTaskData: { complete, initialFormData, updateFormData, formData, isValidForm },
  } = useSpecificContent(taskId);
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { taskKinds } = useConfigurations();

  // TODO: https://hometap.atlassian.net/browse/EH-68 Seperate Doc Review Tasks & Checkbox Review Tasks behavior
  const [completeDocReviewTask, { loading: isCompletingDocReviewTask }] = useMutation(COMPLETE_DOC_REVIEW_TASK);
  const [completeSimpleReviewTask, { loading: isCompletingSimpleReviewTask }] =
    useMutation(COMPLETE_SIMPLE_REVIEW_TASK);
  const [markDocumentsAsReviewed] = useMutation(MARK_DOCS_AS_REVIEWED);

  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);

  useEffect(() => {
    if (initialFormData) {
      updateFormData?.(initialFormData || {});
    }
  }, [initialFormData, updateFormData]);

  const isFormChanged = useMemo(() => {
    return getIsTaskFormChanged(initialFormData, formData);
  }, [formData, initialFormData]);

  const getIsValidTaskForm = useCallback(() => {
    if (!task) {
      return false;
    }
    const { conditions } = task;

    if (isValidForm !== undefined) {
      return isValidForm;
    }
    // If the task doesn't have a custom validator, use the legacy conditions validation
    return conditions.every(({ identifier, isCompleted }) => {
      const changedCondition = allConditionChanges?.find(cond => cond.id === identifier);
      if (changedCondition) {
        return changedCondition.value !== false;
      }
      return isCompleted !== false;
    });
  }, [task, isValidForm, allConditionChanges]);

  const closeDocReviewTask = useCallback(
    async (resolution, currentTodosCount) => {
      await complete?.({ formData, applicant });
      const contentDefinition = TASK_SPECIFIC_CONTENT_DEFINITIONS[docKindElseTaskDefKey];
      const docInvalidationReason = contentDefinition?.getDocInvalidationReason?.(formData);
      const docInvalidationNotes = contentDefinition?.getDocInvalidationNotes?.(formData);
      let todoNotes = formData.reasonNote;
      const todosToCreate = contentDefinition?.getAdditionalTodosToCreate?.({ formData, taskKinds, initialFormData });
      const additionalTodosCount =
        (todosToCreate?.length ?? 0) + (!!docInvalidationReason || formData.isValid === 'false' ? 1 : 0);

      if (docInvalidationReason && !docInvalidationNotes) {
        todoNotes = docInvalidationReason;
      }

      if (!todoNotes && docInvalidationNotes) {
        todoNotes = docInvalidationNotes;
      }

      const isValidDoc = formData.isValid === 'true' && !todoNotes;

      const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');
      // TODO Can roll back a partial failure?
      if (uploadedDocIds) {
        markDocumentsAsReviewed({
          variables: {
            documentIds: uploadedDocIds,
            invalidationNotes: todoNotes,
          },
        });
      }
      await completeDocReviewTask({
        variables: {
          taskId,
          resolution,
          todosToCreate,
          isValidDoc: isValidDoc,
          todoNotes: todoNotes,
        },
      });
      return currentTodosCount + additionalTodosCount;
    },
    [
      applicant,
      complete,
      completeDocReviewTask,
      docKindElseTaskDefKey,
      formData,
      initialFormData,
      markDocumentsAsReviewed,
      task,
      taskId,
      taskKinds,
    ],
  );

  const closeReviewPropertyTaxTask = useCallback(
    async resolution => {
      const response = await complete?.({ formData, applicant });
      if (response?.data?.upsertPropertyTax) {
        await completeSimpleReviewTask({ variables: { taskId, resolution } });
      }
    },
    [applicant, complete, completeSimpleReviewTask, formData, taskId],
  );

  const closeTask = useCallback(
    async resolution => {
      if (!task) {
        return;
      }
      const { identifier: taskId, taskDefinitionKey } = task;
      let todosCount = 0;

      const isSimpleReviewTask = getIsSimpleReviewTask(taskDefinitionKey);
      const isReviewPropertyTaxTask = taskDefinitionKey === REVIEW_PROPERTY_TAX_TASK_DEFINITION;

      if (isReviewPropertyTaxTask) {
        await closeReviewPropertyTaxTask(resolution);
      } else if (isSimpleReviewTask) {
        await completeSimpleReviewTask({ variables: { taskId, resolution } });
      } else {
        todosCount = await closeDocReviewTask(resolution, todosCount);
      }

      setJSONInLocalStorage({
        trackId,
        key: 'latestCompletedTaskId',
        value: taskId,
      });
      showNotification({
        type: TOAST_TYPE.success,
        title: `Task successfully marked as ${resolution === TASK_RESOLUTION_KEYS.COMPLETED ? 'complete' : 'N/A'}`,
        description:
          todosCount > 0 ? (
            <div className="ToastContentDescription">{`Task saved and ${
              todosCount > 1 ? 'to-dos' : 'to-do'
            } assigned`}</div>
          ) : (
            ''
          ),
      });
    },
    [task, completeSimpleReviewTask, trackId, closeDocReviewTask, closeReviewPropertyTaxTask],
  );
  const isValidTaskForm = getIsValidTaskForm();
  const demoData = TASK_SPECIFIC_CONTENT_DEFINITIONS[docKindElseTaskDefKey]?.demoFormData;
  const insertDemoData = useCallback(() => {
    if (demoData) {
      updateFormData(demoData);
    }
  }, [demoData, updateFormData]);
  const updateFormWithDemoData = demoData ? insertDemoData : undefined;

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      closeTask,
      isValidTaskForm,
      saving: isCompletingDocReviewTask || isCompletingSimpleReviewTask,
      updateFormWithDemoData,
      isFormChanged,
    });
  }, [
    closeTask,
    updateSpecificTaskById,
    taskId,
    isValidTaskForm,
    isCompletingDocReviewTask,
    isCompletingSimpleReviewTask,
    updateFormWithDemoData,
    isFormChanged,
  ]);
}

function findKeysWithTypeAndTaskId(obj, taskId) {
  const resultObjects = [];
  if (!obj) {
    return resultObjects;
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value.taskId === taskId && key !== taskId && value.isFormChanged) {
      resultObjects.push(value);
    }
  }

  return resultObjects;
}

// Doc Review Task MultiForm
export const useTaskSpecificMultipleForms = ({ trackId, task }) => {
  const taskId = task?.identifier;

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent();
  const { taskKinds } = useConfigurations();
  const { specificTasksMap } = useSpecificContent();

  const [completeDocReviewTask, { loading: isCompletingDocUploadTask }] = useMutation(COMPLETE_DOC_REVIEW_TASK);
  const [markDocumentsAsReviewed] = useMutation(MARK_DOCS_AS_REVIEWED);

  const [taskForms, setTaskForms] = useState([]);

  const forms = useMemo(() => findKeysWithTypeAndTaskId(specificTasksMap, taskId), [specificTasksMap, taskId]);

  useEffect(() => {
    if (!isEqual(forms, taskForms)) {
      setTaskForms(forms);
    }
  }, [forms, taskForms]);

  const isValidTaskForm = taskForms?.every(form => form.isValidForm);
  const isFormChanged = taskForms?.some(form => form.isFormChanged);

  const docKindElseTaskDefKey = getDocKindElseTaskDefKey(task);

  const closeTask = useCallback(
    async resolution => {
      if (!task) {
        return;
      }
      const { identifier: taskId } = task;
      const contentDefinition = TASK_SPECIFIC_CONTENT_DEFINITIONS[docKindElseTaskDefKey];
      let todosCount = 0;
      let taskTodosToCreate = [];
      let isValidDoc = false;
      let todoNotes = '';

      for (const form of taskForms) {
        // eslint-disable-next-line no-await-in-loop
        if (form.isDeleted && form.deleteForm) await form.deleteForm();

        if (!form.isDeleted) {
          if (form.complete) {
            // eslint-disable-next-line no-await-in-loop
            await form.complete({ formData: form.formData });
          }

          const { formData, initialFormData } = form;

          const uploadedDocIds = formData.uploadedDocIds ? [formData.uploadedDocIds] : [];
          const docInvalidationReason = contentDefinition?.getDocInvalidationReason?.(formData);
          const docInvalidationNotes = contentDefinition?.getDocInvalidationNotes?.(formData);
          todoNotes = formData.reasonNote;
          const todosToCreate = contentDefinition?.getAdditionalTodosToCreate?.({
            formData,
            taskKinds,
            initialFormData,
          });

          todosCount =
            todosCount +
            (todosToCreate?.length ?? 0) +
            (!!docInvalidationReason || formData.isValid === 'false' ? 1 : 0);
          if (todosToCreate?.length) {
            taskTodosToCreate = [...taskTodosToCreate, ...todosToCreate];
          }
          if (docInvalidationReason && !docInvalidationNotes) {
            todoNotes = docInvalidationReason;
          }
          if (!todoNotes && docInvalidationNotes) {
            todoNotes = docInvalidationNotes;
          }

          isValidDoc = formData.isValid === 'true' && !todoNotes;

          // TODO Can roll back a partial failure?
          if (uploadedDocIds) {
            // eslint-disable-next-line no-await-in-loop
            await markDocumentsAsReviewed({
              variables: {
                documentIds: uploadedDocIds,
                invalidationNotes: todoNotes,
              },
            });
          }
        }
      }
      await completeDocReviewTask({
        variables: {
          taskId: taskId,
          resolution: resolution,
          // TODO(https://hometap.atlassian.net/browse/EH-269): Split todo creation into separate service call
          //   or redesign our Camunda process
          todosToCreate: taskTodosToCreate,
          isValidDoc: isValidDoc,
          todoNotes: todoNotes,
        },
      });

      setJSONInLocalStorage({
        trackId,
        key: 'latestCompletedTaskId',
        value: taskId,
      });
      showNotification({
        type: TOAST_TYPE.success,
        title: `Task successfully marked as ${resolution === TASK_RESOLUTION_KEYS.COMPLETED ? 'complete' : 'N/A'}`,
        description:
          todosCount > 0 ? (
            <div className="ToastContentDescription">{`Task saved and ${
              todosCount > 1 ? 'to-dos' : 'to-do'
            } assigned`}</div>
          ) : (
            ''
          ),
      });
    },
    [task, docKindElseTaskDefKey, completeDocReviewTask, trackId, taskForms, taskKinds, markDocumentsAsReviewed],
  );

  const demoData = TASK_SPECIFIC_CONTENT_DEFINITIONS[docKindElseTaskDefKey]?.demoFormData;
  const insertDemoData = useCallback(() => {
    if (demoData) {
      taskForms.forEach(form => form.updateFormData(demoData));
    }
  }, [demoData, taskForms]);

  const updateFormWithDemoData = demoData ? insertDemoData : undefined;

  // isFormChanged, isValidForm
  useEffect(() => {
    updateSpecificTaskById(taskId, {
      closeTask,
      isValidTaskForm,
      saving: isCompletingDocUploadTask,
      updateFormWithDemoData,
      isFormChanged,
    });
  }, [
    updateSpecificTaskById,
    taskId,
    isCompletingDocUploadTask,
    isValidTaskForm,
    closeTask,
    isFormChanged,
    updateFormWithDemoData,
  ]);
};

export function CompleteTaskError(message = '') {
  this.name = 'CompleteTaskError';
  this.message = message;
}
CompleteTaskError.prototype = Error.prototype;
