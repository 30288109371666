import { Icon, Tooltip } from '@hometap/htco-components';
import React from 'react';

import './SummaryLine.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

/**
 * @typedef SummaryLineProps
 * @type {object}
 * @property {string|number|JSX.Element} [value] - The value of the item in the lines.
 * @property {string} [valueTag] - The HTML tag to be used for the value.
 * @property {string} [valueClassName] - The CSS class to be applied to the value.
 * @property {string} [className] - The CSS class to be applied to the entire item.
 * @property {string|JSX.Element} [tooltip] - The tooltip of the item in the lines.
 * @property {string|JSX.Element} [label] - The label of the item in the lines.
 * @property {string} [labelTag] - The HTML tag to be used for the label.
 * @property {string} [labelClassName] - The CSS class to be applied to the label.
 * @property {boolean} [isIndented] - Flag indicating if the item is indented.
 * @property {boolean} [isWithExtraBottom] - Flag indicating if the item has extra bottom padding.
 * @property {boolean} [isWithExtraTop] - Flag indicating if the item has extra top padding.
 */

/**
 * @param {SummaryLineProps} props
 * @returns {JSX.Element|null}
 */
export const SummaryLine = ({
  value,
  valueTag: ComponentValue = 'h5',
  valueClassName,
  className,
  tooltip,
  label,
  labelTag: ComponentLabel = 'h5',
  labelClassName,
  isIndented,
  isWithExtraBottom,
  isWithExtraTop,
}) => (
  <div className={cx('SummaryLine', className, { isIndented, isWithExtraBottom, isWithExtraTop })}>
    {typeof label === 'string' ? (
      <ComponentLabel className={cx('SummaryLineItemLabel', labelClassName)}>
        {label}
        {<span className="SummaryLineItemColon">:</span>}
        {tooltip && (
          <Tooltip className="SummaryLineItemTooltip" content={tooltip}>
            <Icon name="info" />
          </Tooltip>
        )}
      </ComponentLabel>
    ) : (
      label
    )}

    {typeof (value === 'string' || value === 'number') ? (
      <ComponentValue className={cx('SummaryLineItemLabel', valueClassName)}>{value}</ComponentValue>
    ) : (
      value
    )}
  </div>
);

export const SummaryLineProps = {
  labelTag: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  valueTag: PropTypes.string,
  valueClassName: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isIndented: PropTypes.bool,
  isWithExtraBottom: PropTypes.bool,
  isWithExtraTop: PropTypes.bool,
};

SummaryLine.propTypes = SummaryLineProps;
