import { getRandomId, getSelectionYesNoInitialValue } from 'apps/track-details/utils/taskForms';
import { format } from 'date-fns';

export const getNewPaydown = () => {
  const newLienIdentifier = `new-${getRandomId()}`;
  const formattedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return {
    newLien: true,
    identifier: newLienIdentifier,
    selectorId: newLienIdentifier,
    createdAt: formattedDate,
    isPayoff: '',
    paymentMethod: '',
    mailingInstructions: '',
    accountNumber: '',
    routingNumber: '',
  };
};

export const initialFormDataFromPaydownNode = paydown => ({
  ...paydown,
  isPayoff: getSelectionYesNoInitialValue(paydown?.isPayoff),
  paymentMethod: paydown?.paymentMethod?.value,
});
