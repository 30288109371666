import { useState, useEffect } from 'react';
import { isEmpty, omitBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { getLoginUrl } from 'utils/links';
import { browserTrack } from 'utils/segment/segment';

const useFilterableQuery = ({
  query,
  dataKey,
  pageLimit,
  defaultFilters = {},
  queryOptions = {},
  segmentType = undefined,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(defaultFilters);
  const [sortKey, setSort] = useState(defaultFilters.orderBy);
  const [paginationVars, setPaginationVars] = useState({ nextPageLimit: pageLimit });

  // This tracks a toggle for whether or not to reset to the default page in the table component.
  const [resetDefaultPage, setResetDefaultPage] = useState(false);

  // This will re-run when the variables change
  const { loading, error, data, refetch } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'no-cache',
    ...queryOptions,
    variables: {
      orderBy: sortKey,
      ...filters,
      ...paginationVars,
      ...queryOptions.variables,
    },
  });

  const innerData = data ? data[dataKey] : {};
  const { edges = [], pageInfo, totalCount } = innerData;
  const nodes = edges?.map(({ node }) => node);

  useEffect(() => {
    // Logout if session has ended resulting in a 403
    if (error && error?.networkError?.statusCode === 403) {
      window.api.redirect(getLoginUrl());
    }
  }, [error]);

  const handleSort = (column, sortDirection) => {
    setSort(`${sortDirection === 'asc' ? '' : '-'}${column.sortKey}`);
    browserTrack.listFilterApplied({
      filters,
      list_type: segmentType,
      sort: sortKey,
    });
  };

  const handleChangePage = nextPage => {
    const isNext = nextPage >= currentPage || nextPage === 1;
    setPaginationVars({
      nextPageLimit: isNext ? pageLimit : null,
      nextPageCursor: isNext && nextPage !== 1 ? pageInfo?.endCursor : null,
      prevPageLimit: !isNext ? pageLimit : null,
      prevPageCursor: !isNext ? pageInfo?.startCursor : null,
    });
    setCurrentPage(nextPage);
  };

  const handleFilterTable = newFilters => {
    setFilters(newFilters);
    // This works with the DataTable component changes to manage the current page logic
    // which oddly configured using a toggle for paginationResetDefaultPage.
    // When on page 1, we want to call refetch, but on any other page, we just toggle the
    // resetDefaultPage flag which calls handleChangePage which will refetch.
    if (currentPage !== 1) {
      setCurrentPage(1);
      setResetDefaultPage(!resetDefaultPage);
    }
    const cleanFilters = omitBy(newFilters, isEmpty);
    const filterKeys = Object.keys(cleanFilters);
    browserTrack.listFilterApplied({
      filters: cleanFilters,
      filter_keys: filterKeys,
      list_type: segmentType,
      sort: sortKey,
    });
  };

  return {
    data: nodes,
    filters,
    loading,
    error,
    currentPage,
    setCurrentPage,
    resetDefaultPage,
    total: totalCount,
    sortKey,
    handleSort,
    handleChangePage,
    handleFilterTable,
    handleFetch: refetch,
  };
};

export default useFilterableQuery;
