import axios from 'axios';
import apiWithAuth from 'utils/api';
import { convertHeic } from 'utils/documents';
import {
  ACCEPTED_FILE_EXTENSION_ICONS,
  ACCEPTED_VIEWABLE_FILE_EXTENSIONS,
  AV_MESSAGE_PASSWORD_PROTECTED,
  AV_STATUS,
} from './data/constants';
import { getFormDataForUploadFiles } from './DocumentsUtils';
import { faker } from '@faker-js/faker';

/**
 * @typedef AVStatus
 * @type {object}
 * @property {string?} message Anti-virus message
 * @property {string?} timestamp datetime stamp of last status update
 * @property {string?} status Anti-virus status
 */

/**
 * @typedef UserStub
 * @type {object}
 * @property {string} id User ID
 * @property {string} full_name Full name of the user
 */

/**
 * @typedef APIDocumentVersion
 * @type {object}
 * @property {string} id Document Version ID
 * @property {number} version Version Number
 * @property {boolean} is_current_version Is this the current version?
 * @property {UserStub?} created_by Uploader of the document. Missing created_by implies created by system
 * @property {string} created_at Creation datetime stamp
 * @property {string} filename File name
 * @property {string} file_url File location either in S3 Bucket or Django Private Media
 * @property {string} file_extension File extension
 * @property {number} file_size Byte size of the file as an int
 * @property {AVStatus?} av_status Missing av_status implies that the file hasn't been scanned
 * @property {number?} rotation
 */

/**
 * @typedef Context
 * @type {object}
 * @property {string} context_id Context ID
 * @property {string} context_type Context Type
 * @property {string} context_key Context Key i.e. a secondary identifier
 */

/**
 * @typedef APIDocument
 * @type {APIDocumentVersion}
 * @property {boolean} pinned Is this document pinned?
 * @property {boolean} has_other_versions Does this document have other versions?
 * @property {Context[]} contexts Contexts linked to the document
 * @property {string?} external_kind External document type
 * @property {string?} external_id External document ID
 * @property {string?} modified_at Last modification datetime stamp
 */

/**
 * @typedef DocumenntCalculatedFields
 * @type {object}
 * @property {boolean} isViewable Is the file viewable i.e. supported by the Documents v2 Viewer?
 * @property {boolean} isPDF Is the file a PDF?
 * @property {boolean} isAVCleanOrDisabled Is the file Clean or Anti-Virus Disabled?
 * @property {boolean} isUnscanned Has the file been scanned?
 * @property {boolean} isEncrypted Is the file encrypted with a password? Encrypted files are always unscanned.
 * @property {boolean} isInfected Was a virus detected within the file?
 * @property {boolean} isDownloadAllowed Is the file allowed to be downloaded?
 * @property {string} fileIcon
 */

/**
 * @typedef Document
 * @type {APIDocument & DocumenntCalculatedFields}
 */

/**
 * @typedef DocumentVersion
 * @type {APIDocumentVersion & DocumenntCalculatedFields}
 */

/**
 * @typedef DocumentOrVersion
 * @type {Document | DocumentVersion}
 */

/** Injected Front-end calculated fields into a document or version
 * @param {APIDocument | APIDocumentVersion} docOrVersion
 */
function injectCalculatedFieldsIntoDocOrVersion(docOrVersion) {
  const { av_status, file_extension } = docOrVersion;
  const isViewable = ACCEPTED_VIEWABLE_FILE_EXTENSIONS.includes(file_extension);
  const isPDF = file_extension === 'pdf';
  const isAVCleanOrDisabled = [AV_STATUS.Clean, AV_STATUS.Disabled].includes(av_status?.status);
  const isUnscanned = (av_status?.status ?? AV_STATUS.Unscannable) === AV_STATUS.Unscannable;
  const isEncrypted =
    av_status?.status === AV_STATUS.Unscannable &&
    // encrypted pdfs have an av_status with a specific message
    av_status.message === AV_MESSAGE_PASSWORD_PROTECTED;
  const isInfected = av_status?.status === AV_STATUS.Infected;
  const isDownloadAllowed = isEncrypted || isAVCleanOrDisabled;
  const fileIconSuffix = ACCEPTED_FILE_EXTENSION_ICONS.includes(file_extension) ? file_extension : 'primary';
  const fileIcon = `icon-file-${fileIconSuffix}`;

  Object.assign(docOrVersion, {
    isViewable,
    isPDF,
    isAVCleanOrDisabled,
    isEncrypted,
    isInfected,
    isDownloadAllowed,
    isUnscanned,
    fileIcon,
  });
}

/**
 * @typedef GetTrackDocumentsQueryParams
 * @type {object}
 * @property {string[]?} kinds
 * @property {boolean?} pinned
 */

/** Get all track-base documents
 * @param {uuid4} trackId Track ID
 * @param {GetTrackDocumentsQueryParams?} params
 * @returns {Promise<DocumentOrVersionExt[]>} Track documents
 * @raises Request error
 */
export const getTrackDocuments = async (trackId, params) => {
  const documents = await apiWithAuth.v2.get(`/tracks/${trackId}/documents`, { params });
  documents.forEach(injectCalculatedFieldsIntoDocOrVersion);
  return documents;
};

/** Get all task-base documents
 * @param {string} taskId Task ID
 * @param {boolean} [allDocsThatMatchTaskKind] Show the all documents that match the task kind
 * @returns {Promise<DocumentOrVersionExt[]>} Task documents
 * @raises Request error
 */
export const getTaskDocuments = async (taskId, allDocsThatMatchTaskKind = true) => {
  const documents = await apiWithAuth.v2.get(`/tasks/${taskId}/documents/`, {
    params: { include_all_docs: allDocsThatMatchTaskKind },
  });
  documents.forEach(injectCalculatedFieldsIntoDocOrVersion);
  return documents;
};

/** Uploads file(s) for new document version
 * @param {File|File[]} files: file object that contains the file(s) that will be uploaded
 * @param {string} newVersionDocId: document object that is being updated
 * @param {string} fileName: document name
 * @param {boolean} internal: document visibility
 * @param {string} kind: document type
 * @returns {Promise<Document>} Updated document
 */
/**
 * @typedef UploadNewVersion
 * @type {object}
 * @property {File[]} files
 * @property {string} newVersionDocId
 * @property {string} fileName: document name
 * @property {boolean} internal: document visibility
 * @property {boolean} pinned: document pinned status
 * @property {string} kind: document type
 * @property {string} kindOther: other document type
 * @property {string} [personId]: document person id
 */
/** Uploads file(s) and combine into pdf
 * @param {UploadNewVersion}
 * @returns {Promise<Document>} Updated document
 */
export const uploadNewVersion = async ({
  files,
  newVersionDocId,
  fileName,
  internal,
  pinned,
  kind,
  kindOther,
  personId,
}) => {
  const formData = await getFormDataForUploadFiles({ files, fileName, internal, kind, kindOther, personId });
  if (pinned) {
    await apiWithAuth.v2.post(`/documents/${newVersionDocId}/versions`, formData);
    return await toggleDocumentPin(newVersionDocId, pinned);
  }
  return await apiWithAuth.v2.post(`/documents/${newVersionDocId}/versions`, formData);
};

/**
 * @typedef UploadMultipleFilesIntoPdf
 * @type {object}
 * @property {File|File[]} files: file object that contains the file(s) that will be uploaded
 * @property {string} trackId: track id
 * @property {string} fileName: document name
 * @property {boolean} internal: document visibility
 * @property {boolean} pinned: document pinned status
 * @property {string} [kind]: document type
 * @property {string} [kind_other]: document other type
 * @property {string} [personId]: document person id
 */
/** Uploads file(s) and combine into pdf
 * @param {UploadMultipleFilesIntoPdf}
 * @returns {Promise<Document>} Updated document
 */
export const uploadMultipleFilesIntoPdf = async ({
  files,
  trackId,
  fileName,
  internal,
  kind,
  kind_other,
  personId,
}) => {
  const formData = await getFormDataForUploadFiles({
    files,
    fileName,
    internal,
    kind,
    kindOther: kind_other,
    personId,
  });
  return await apiWithAuth.v2.post(`/tracks/${trackId}/documents`, formData);
};

/**
 * @typedef UploadDocument
 * @type {object}
 * @property {string} trackId
 * @property {File} file
 * @property {string} [kind]
 * @property {string} [kind_other]
 * @property {string} [personId]: document person id
 */
/**
 * @param {UploadDocument}
 * @returns {Promise<Document>}
 */
export const uploadDocument = async ({ trackId, file, kind, kind_other, personId }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('internal', file.visibility === 'internal');
  formData.append('name', file.name);
  if (kind_other) {
    formData.append('kind_other', kind_other);
  } else {
    formData.append('kind', kind);
    if (personId) {
      formData.append('person_id', personId);
    }
  }
  return await apiWithAuth.v2.post(`/tracks/${trackId}/documents`, formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    timeout: 30000, // 30 seconds timeout
  });
};

/** Pin/Unpin a document
 * @param {string} documentRef Document ID/Code
 * @param {bool} pinStatus true to pin, false to unpin
 * @returns
 */
export const toggleDocumentPin = async (documentRef, pinStatus) => {
  return await apiWithAuth.v2.post(`/documents/${documentRef}/actions/toggle-pin`, { pin: pinStatus });
};

/** Complete homeowner todos associated with this document's kind and spawn review tasks
 * @param {string} documentRef Document ID/Code
 * @returns
 */
export const handleRelatedDocumentTasks = async documentRef => {
  return await apiWithAuth.v2.post(`/documents/${documentRef}/actions/handle-review-tasks`);
};

/** Get document versions
 * @param {string} documentRef Document ID/Code
 * @returns {Promise<DocumentOrVersion[]>} Document versions
 * @raises Request error
 */
export const getDocumentVersions = async documentRef => {
  const versions = await apiWithAuth.v2.get(`/documents/${documentRef}/versions`, { method: 'get' });
  versions.forEach(injectCalculatedFieldsIntoDocOrVersion);
  return versions;
};

/** Make a version current
 * @param {string} versionId Document ID/Code
 * @returns {Promise<DocumentOrVersion>} Updated document
 * @raises Request error
 */
export const makeVersionPrimary = async versionId => {
  const updatedDoc = await apiWithAuth.v2.post(`/documents/${versionId}/actions/make-current`);
  injectCalculatedFieldsIntoDocOrVersion(updatedDoc);
  return updatedDoc;
};

/** Get document
 * @param {string} documentRef Document ID/Code
 * @returns {Promise<Document>} Document
 * @raises Request error
 */
export const getDocumentById = async documentRef => {
  const doc = await apiWithAuth.v2.get(`/documents/${documentRef}`, { method: 'get' });
  injectCalculatedFieldsIntoDocOrVersion(doc);
  return doc;
};

/**
 * @param {string} documentId id or code of the document
 * @returns
 */
export const deleteDocument = async documentId => {
  return await apiWithAuth.v2.delete(`/documents/${documentId}`);
};

/**
 * @param {array} documentIds ids or codes of the documents
 * @returns
 */
export const deleteMultipleDocuments = async documentIds => {
  const deleteDocumentRequests = documentIds.map(documentId => deleteDocument(documentId));
  return await Promise.all(deleteDocumentRequests);
};

/**
 * @typedef DocumentUpdate
 * @type {object}
 * @param {string} id
 * @param {string} code
 * @param {string} name
 * @param {string?} kind
 * @param {string?} kind_other
 * @param {string?} description
 * @param {boolean?} internal
 * @param {object?} additional_data
 */

/**
 * @param {DocumentUpdate} document
 * @returns
 */
export const updateDocument = async document => {
  return await apiWithAuth.v2.patch(`/documents/${document.id || document.code}`, document);
};

/**
 *
 * @param {string} versionId
 * @param {number} rotation
 * @returns
 */
export const updateDocumentVersionRotation = async (versionId, rotation) => {
  return await apiWithAuth.v2.patch(`/documents/${versionId}/versions/rotate`, { rotation });
};

/**
 * @typedef DownloadThenMaybeConvertDocumentParams
 * @type {object}
 * @property {string} file_url document/version file_url that directs to s3 or media_private
 * @property {string} file_extension Document/Version file extension
 */

/** Download document/version blob by ID
 * @param {DownloadThenMaybeConvertDocumentParams} document
 * @param {boolean} isReturnBlobURL when we need to return a string containing an object URL
 * @returns {Promise<Blob | string>} Document image blob or blob url
 * @raises Rejection error from download or conversion error/failure
 */
export const downloadThenMaybeConvertDocument = async ({ id, file_extension }, isReturnBlobURL = false) => {
  try {
    const documentObject = await apiWithAuth.v2.get(`/documents/${id}`);

    if (!documentObject.file_url) {
      return null;
    }

    let imageBlob = await axios.get(documentObject.file_url, { responseType: 'blob', method: 'get' });
    imageBlob = imageBlob.data;

    // handle heic file conversion on client-side
    if (file_extension === 'heic') {
      const convertedBlob = await convertHeic(imageBlob);
      imageBlob = convertedBlob;
    }
    if (isReturnBlobURL) {
      return URL.createObjectURL(imageBlob);
    }
    return imageBlob;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * @typedef DataToMerge
 * @type {object}
 * @property {array} documentIds UUID4s at least two of the documents
 * @property {string} name final name of the merged documents
 * @property {boolean} internal
 * @property {boolean} delete_source_documents delete/archive source documents upon merge
 * @property {string?} kind document's kind
 * @property {string?} kind_other document's kind
 */

/**
 * @param {DataToMerge} dataToMerge
 */
export const mergeMultipleDocuments = async dataToMerge => {
  return await apiWithAuth.v2.post('/documents/actions/merge', dataToMerge, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const downloadMultipleFiles = documentsToDownload => {
  // handle multiple files download => zip file
  return documentsToDownload.map(async ({ id, filename, file_extension }) => {
    return {
      file: await downloadThenMaybeConvertDocument({
        id,
        file_extension,
      }),
      filename: filename,
    };
  });
};

export const uploadDemoDocument = (trackId, docTypes, onUploadComplete, docTypesExceptions = []) => {
  const filteredDocTypes = docTypes.filter(({ value }) => !docTypesExceptions.includes(value));
  const demoDocType = faker.helpers.arrayElement(filteredDocTypes)?.value;
  if (!demoDocType) {
    return;
  }
  const image = `${window.location.origin}/document-demo-puppy.jpeg`;
  // Fetch a cute demo file and convert it into blob, then file which can be uploaded to the backend.
  fetch(image)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], `${faker.word.adjective()}-puppy.jpg`);
      uploadDocument({ trackId, file, kind: demoDocType }).then(() => {
        onUploadComplete(trackId); // Refetch the documents list
      });
    });
};
