import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@hometap/htco-components';

import { generateHomeownerSelectedTodoCount } from '../todoUtils';

const TOTAL_DISPLAYED_TODO_LABELS = 2;

const formatSelectedDisplayLabels = todos => {
  const todosToDisplay = todos.slice(0, TOTAL_DISPLAYED_TODO_LABELS).map(({ displayLabel }) => displayLabel);
  const remainderOfTodosAfterSlice = todos.length - TOTAL_DISPLAYED_TODO_LABELS;
  return `${todosToDisplay.join(', ')} ${remainderOfTodosAfterSlice > 0 ? `+ ${remainderOfTodosAfterSlice}` : ''}`;
};

const TodosSelectionListBottomMenu = ({
  onCancel,
  todosPath,
  selectedTodos = [],
  homeownerFullName,
  shouldRenderGoBackButton,
}) => {
  return (
    <div className="TodosSelectionListBottomMenu">
      {selectedTodos.length > 0 && (
        <div className="TodosSelectionListBottomMenuSelectedTodos">
          <h4 className="TodosSelectionListBottomMenuSelectedTodosHeading">
            {generateHomeownerSelectedTodoCount(selectedTodos, homeownerFullName)}
          </h4>
          <p className="TodosSelectionListBottomMenuSelectedTodosLabels">
            {formatSelectedDisplayLabels(selectedTodos)}
          </p>
        </div>
      )}
      <div className="TodosSelectionListBottomMenuButtons">
        {shouldRenderGoBackButton ? (
          <Button label="Go back" href="/tracks" />
        ) : (
          <>
            <Link
              to={`${todosPath.pathname}/create`}
              className="htco-Button TodosSelectionListBottomMenuReviewButton"
              disabled={selectedTodos.length === 0}
            >
              Review To-dos
            </Link>
            <Button onClick={onCancel} label="Cancel" theme="secondary" />
          </>
        )}
      </div>
    </div>
  );
};

export default TodosSelectionListBottomMenu;
