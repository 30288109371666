import { gql, useQuery } from '@apollo/client';

/** Use the App Unlock Changes GraphQL Query
 * @param {string} trackId Track ID
 * @returns {import('@apollo/client').QueryResult<SnapshotEventChanges>}
 */
export default function useAppUnlockChangesQuery(trackId) {
  return useQuery(GET_APPLICATION_CHANGES, { variables: { trackId } });
}

const GET_APPLICATION_CHANGES = gql`
  query GetTrack($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      changesDuringAppUnlock {
        leftEvent {
          createdAt
        }
        rightEvent {
          createdAt
        }
        snapshotsChanges {
          objectChangeType
          leftSnapshot {
            modelName
            fields {
              fieldName
              fieldValue
            }
          }
          rightSnapshot {
            modelName
            fields {
              fieldName
              fieldValue
            }
          }
          fieldChanges {
            leftValue
            rightValue
            fieldName
            changeType
          }
        }
      }
    }
  }
`;

// * * * * * * * * * Types * * * * * * * * *

/**
 * @typedef SnapshotEventChanges
 * @type {object}
 * @property {SnapshotEvent} leftEvent
 * @property {SnapshotEvent} rightEvent
 * @property {SnapshotChanges[]} snapshotsChanges
 */

/**
 * @typedef SnapshotEvent
 * @type {object}
 * @property {string} createdAt - Datetime stamp
 */

/**
 * @typedef SnapshotChanges
 * @type {object}
 * @property {string} objectChangeType
 * @property {Snapshot?} leftSnapshot
 * @property {Snapshot?} rightSnapshot
 * @property {SnapshotFieldChange[]} fieldChanges
 */

/**
 * @typedef Snapshot
 * @type {object}
 * @property {string} modelName
 * @property {SnapshotField[]} fields
 */

/**
 * @typedef SnapshotFieldChange
 * @type {object}
 * @property {string} fieldName
 * @property {string} leftValue
 * @property {string} rightValue
 * @property {string} changeType
 */

/**
 * @typedef SnapshotField
 * @type {object}
 * @property {string} fieldName
 * @property {string} fieldValue
 */
