import React, { useEffect, useState } from 'react';
import { UploadDocument } from '../UploadDocument/UploadDocument';
import { UploadDocumentProcessing } from '../UploadDocumentProcessing/UploadDocumentProcessing';
import { ACCEPTED_IMAGES_FILE_TYPE } from '../../data/constants';
import { getApplicantByPersonId } from '../../DocumentsUtils';

const initialNewDocumentValue = { name: '', internal: true, pinned: true };

export const SliderController = ({
  openUpload,
  setOpenUpload,
  trackId,
  refresh,
  useDocumentKindsResults,
  versionToBeUpdated,
  setVersionToBeUpdated,
  kindId,
  closable = true,
  getDocumentKindVisibility,
  useTrackApplicantsResults,
}) => {
  const [files, setFiles] = useState([]);
  const [docType, setDocType] = useState();
  const [otherType, setOtherType] = useState();
  const [openProcessing, setOpenProcessing] = useState(false);
  const [newDocumentFields, setNewDocumentFields] = useState(initialNewDocumentValue);
  const [isCombine, setIsCombine] = useState(false);
  const [personId, setPersonId] = useState();
  const { visibilityLabel, isVisibilityEditable } = getDocumentKindVisibility(docType);
  const { isDocumentType, documentKindsWithPerson } = useDocumentKindsResults;
  const { applicantsOptions, applicants } = useTrackApplicantsResults;

  const applicant = getApplicantByPersonId(personId, applicants);

  // set docType if kindId is supplied and is a documentType
  useEffect(() => {
    if (isDocumentType(kindId)) setDocType(kindId);
  }, [kindId, isDocumentType]);

  // default to applicant when there's only one and gov_id
  useEffect(() => {
    if (
      applicantsOptions &&
      applicantsOptions.length === 1 &&
      personId === undefined &&
      documentKindsWithPerson.includes(docType)
    )
      setPersonId(applicantsOptions[0].value);
  }, [applicantsOptions, personId, docType, documentKindsWithPerson]);

  // close without intention of proceeding with processing documents or clearing state
  const handleCancel = () => {
    if (closable) {
      handleTransition();
      setVersionToBeUpdated({});
      setIsCombine(false);
      setNewDocumentFields(initialNewDocumentValue);
    }
  };

  // intention of processing documents
  const handleTransition = () => {
    setOpenProcessing(false);
    setOpenUpload(false);
    setFiles([]);
    setDocType();
    setOtherType();
    setPersonId();
  };

  // Called when uploadDocument is transitioning to UploadDocumentProcessing
  const handleProcessDocuments = (toUploadDocuments, dt, otherType, personId) => {
    setOpenUpload(false);
    setOpenProcessing(true);
    setOtherType(otherType);
    setDocType(dt);
    setPersonId(personId);

    if (isCombine) {
      const images = [];
      const othersDocuments = [];
      toUploadDocuments.forEach(document => {
        if (ACCEPTED_IMAGES_FILE_TYPE.includes(document.file.type)) {
          images.push(document);
        } else {
          othersDocuments.push(document);
        }
      });
      const sortedImages = images.sort((a, b) => b.file['size'] - a.file['size']);
      setFiles([...othersDocuments, ...sortedImages]);
    } else {
      setFiles(toUploadDocuments);
    }
  };

  return (
    <>
      <UploadDocument
        open={openUpload}
        close={handleTransition}
        processDocuments={handleProcessDocuments}
        useDocumentKindsResults={useDocumentKindsResults}
        setFiles={setFiles}
        files={files}
        setDocType={setDocType}
        docType={docType}
        setOtherType={setOtherType}
        otherType={otherType}
        newVersionDoc={versionToBeUpdated}
        handleCancel={handleCancel}
        newDocumentFields={newDocumentFields}
        setNewDocumentFields={setNewDocumentFields}
        isCombine={isCombine}
        setIsCombine={setIsCombine}
        closable={closable}
        defaultVisibilityLabel={visibilityLabel}
        isDefaultVisibilityEditable={isVisibilityEditable}
        applicantsOptions={applicantsOptions}
        personId={personId}
        setPersonId={setPersonId}
        applicant={applicant}
      />
      <UploadDocumentProcessing
        open={openProcessing}
        files={files}
        close={handleCancel}
        documentType={docType}
        useDocumentKindsResults={useDocumentKindsResults}
        otherType={otherType}
        trackId={trackId}
        refresh={refresh}
        versionToBeUpdated={versionToBeUpdated}
        newDocumentFields={newDocumentFields}
        isCombine={isCombine}
        closable={closable}
        defaultVisibilityLabel={visibilityLabel}
        isDefaultVisibilityEditable={isVisibilityEditable}
        applicant={applicant}
      />
    </>
  );
};
