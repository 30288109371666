import { useMemo } from 'react';

/**
 * Hook to resolve fund names into a an object
 * @param {Array[Object]} fundNameInfo
 * @returns {Object}
 */
const useFundNames = fundNameInfo => {
  const fundNameDictionary = useMemo(() => {
    if (fundNameInfo) {
      return fundNameInfo.reduce((acc, fundLabel) => {
        acc[fundLabel.key] = fundLabel.value;
        return acc;
      }, {});
    }
    return {};
  }, [fundNameInfo]);

  // dictionary holding all pertinent information about the funding selection data
  return { fundNameDictionary };
};

// return an object with associated as a track
export default useFundNames;
