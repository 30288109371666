import { gql } from '@apollo/client';

export const LIEN_DETAILS = gql`
  fragment LienDetails on LienNode {
    identifier
    asOfDate
    currentBalance
    endOfTermDate
    escrowAmount
    holder
    interestAmount
    isForbearance
    isMoreThanOneMonthPastDue
    kind
    lienDate
    mortgageAccountNumber
    mortgageInvestorKind
    notes
    onPropertyReport
    originalAmount
    pastDueAmount
    pastDueFees
    position
    principalAmount
    rate
    rateType
    document {
      ... on Document {
        identifier
        isPinned
        filename
        id
        kind {
          label
          value
        }
      }
    }
  }
`;

export const GET_LIENS = gql`
  query Liens($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      liens {
        ...LienDetails
      }
    }
  }
  ${LIEN_DETAILS}
`;
