import React from 'react';
import PropTypes from 'prop-types';

import './Summary.scss';
import { SummaryLine, SummaryLineProps } from '../SummaryLine/SummaryLine';

export const SUMMARY_DIVIDER = 'divider';

/**
 * Summary component used in Application Review sections.
 * It represents a summary view of multiple sub-parts of an application.
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.visible - Determines if the component should be visible.
 * @param {(string|SummaryLineProps)[]} props.summaryParams - Array containing summary properties. Each item can be either a string or a SummaryLineProps object.
 */
export const Summary = ({ visible, summaryParams }) => {
  return visible && summaryParams?.length ? (
    <>
      {summaryParams.map((line, i) => {
        if (line === SUMMARY_DIVIDER) {
          return <div className="SummaryDivider" key={i} />;
        }
        return <SummaryLine key={i} {...line} />;
      })}
    </>
  ) : null;
};

export const SummaryProps = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.shape(SummaryLineProps)]),
);

Summary.propTypes = {
  visible: PropTypes.bool,
  summaryParams: SummaryProps,
};
