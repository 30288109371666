import { formatMMDDYYYY } from 'utils/dates';

export const formatBirchwoodData = (track, applicant) => {
  return {
    friendly_id: track.friendlyId,
    address: {
      street: track.home.address.street,
      unit: track.home.address.unit,
      city: track.home.address.city,
      state: track.home.address.state,
      zip_code: track.home.address.zipCode,
      full_address: track.home.address.fullAddress,
    },
    // It is important we use the name and birth_date from the Gov ID when ordering credit, rather
    // than the self reported data on applicant directly.
    name_as_appears_on_id: applicant.governmentId?.nameAsAppearsOnId || '',
    birth_date: formatMMDDYYYY(applicant.governmentId?.birthDate) || '',
    ssn: applicant.ssn,
  };
};
