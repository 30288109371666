import { useEffect, useState } from 'react';
import { formatMMDDYYYY } from '../../../../../../utils/dates';
import { useSectionFormById } from '../../../hooks/useSpecificContentQuery';
import { getAdverseEventSectionsData } from '../utils/adverseEvents';

export const useAdverseEventSections = (sections, adverseEventId, adverseEventKindOptions) => {
  const [adverseEventSectionsData, setAdverseEventSectionsData] = useState();
  const { formData } = useSectionFormById(adverseEventId);

  useEffect(() => {
    if (sections) {
      setAdverseEventSectionsData(getAdverseEventSectionsData(sections));
    }
  }, [sections]);

  const type = adverseEventKindOptions?.find(option => option.value === formData?.kind.toLowerCase());

  // Update section data based on form data changes
  useEffect(() => {
    if (formData?.identifier && formData.identifier === adverseEventId) {
      setAdverseEventSectionsData(sectionsData => {
        const updatedSections = sectionsData.sections.map(section => {
          if (section.identifier === adverseEventId) {
            return {
              ...section,
              resolutionDate: formData.resolutionDate,
              hasNotBeenResolved: formData.hasNotBeenResolved,
              subSectionLabel: type ? type.label : 'New adverse event',
              value:
                formData.hasNotBeenResolved || formData.resolutionDate == null
                  ? 'Unresolved'
                  : formatMMDDYYYY(formData.resolutionDate),
            };
          }
          return section;
        });

        return { ...sectionsData, sections: updatedSections };
      });
    }
  }, [adverseEventId, formData, setAdverseEventSectionsData, type]);

  return {
    adverseEventSections: adverseEventSectionsData?.sections || [],
  };
};
