import React from 'react';
import { CurrencyInput, Helptip, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';

import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls, getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import './UnderwritingDetailsController.scss';
import { COMPOSITE_FICO_STATUS } from 'apps/track-details/ApplicationReview/data/constants/compositeFICOStatus';

export const UnderwritingDetailsController = ({ track, editDisabled }) => {
  const { trackId } = useParams();
  const { trackHistoryUrl } = getTrackDetailPageUrls(trackId);
  const { editARPSummaryUrl } = getApplicationReviewPageUrls(trackId);

  // In development of the read-only ARP, disable all inputs
  const readOnly = true;
  const applicationIsApproved = track.applicationIsApprovedOrClosedLost === 'APPROVED';
  const iwrErrorMessage = track.currentIncomeWeightedRiskData?.errorMessage;
  const iwrHelptip = iwrErrorMessage && <Helptip text={iwrErrorMessage} tooltipContentClassName="iwr-Tooltip" />;
  const compositeFicoIsCurrentOrFinal =
    track.compositeFicoData.status === COMPOSITE_FICO_STATUS.CURRENT ||
    track.compositeFicoData.status === COMPOSITE_FICO_STATUS.FINAL;

  const handleSelectPlaceholder = function changeReviewer() {
    // The DropdownSelector component which is used in the MuiSelect component requires an onChange handler
    // but we are not doing anything with it yet for this readonly page.
  };

  const onClickEdit = function navigateToEditUrl() {
    window.location = editARPSummaryUrl;
  };

  const coordinatorDisplay = track.coordinator ? `${track.coordinator.fullNameShort} ${track.coordinator.email}` : null;
  const underwriterDisplay = track.underwriter ? `${track.underwriter.fullNameShort} ${track.underwriter.email}` : null;
  const numberOrEmpty = num => {
    return num || num === 0 ? num : '';
  };

  return (
    <ApplicationReviewSection
      anchorId="underwriting-section"
      sectionTitle="Underwriting details"
      historyUrl={trackHistoryUrl}
      visibleSummary={false}
      onEdit={!editDisabled ? onClickEdit : null}
    >
      <>
        <MuiTextInput
          label="Underwriting Rule Version"
          value={track.uwRuleVersion}
          disabled={readOnly}
          theme="outlined"
          width="100%"
        />

        <div className={'TwoItemFormRow'}>
          <MuiSelect
            label="Investment Coordinator"
            name="investmentCoordinatorSelector"
            value={coordinatorDisplay}
            options={[{ label: coordinatorDisplay, value: coordinatorDisplay }]}
            disabled={readOnly}
            theme="outlined"
            onChange={handleSelectPlaceholder}
            width="100%"
          />
          <MuiSelect
            label="Underwriter"
            name="underwriterSelector"
            value={underwriterDisplay}
            options={[{ label: underwriterDisplay, value: underwriterDisplay }]}
            disabled={readOnly}
            theme="outlined"
            onChange={handleSelectPlaceholder}
            width="100%"
          />
        </div>

        <div className={'TwoItemFormRow'}>
          <CurrencyInput
            label="Maximum Investment Amount"
            disabled={readOnly}
            extendedMaskProps={{ scale: 0 }}
            value={track.maxInvestmentAmount}
          />
          <CurrencyInput
            label="Beginning Home Valuation"
            disabled={readOnly}
            extendedMaskProps={{ scale: 0 }}
            value={track.beginningHomeValuation?.value}
          />
        </div>

        <MuiTextInput
          label="HT Lien Position"
          mask={[
            {
              mask: Number,
              signed: false,
            },
          ]}
          unmask="typed"
          disabled={readOnly}
          value={track.htLienPosition}
          theme="outlined"
          width="100%"
        />

        <div className={'TwoItemFormRow'}>
          <CurrencyInput
            label="Principal Paydown"
            padFractionalZeros={true}
            disabled={readOnly}
            value={numberOrEmpty(track.principalPaydown)}
          />
          <CurrencyInput
            label="Total Paydown"
            disabled={readOnly}
            padFractionalZeros={true}
            value={numberOrEmpty(track.totalPaydown)}
          />
        </div>
        <div className={'TwoItemFormRow'}>
          <MuiTextInput
            label="Owner's Ending Equity"
            mask={[
              {
                mask: Number,
                radix: '.',
              },
            ]}
            unmask="typed"
            endAdornment="%"
            disabled={readOnly}
            value={track.ownerEndingEquityPercent}
            theme="outlined"
            width="100%"
          />
          <MuiTextInput
            label="Total Ending Equity"
            mask={[
              {
                mask: Number,
                radix: '.',
              },
            ]}
            unmask="typed"
            endAdornment="%"
            disabled={readOnly}
            value={track.totalEndingEquityPercent}
            theme="outlined"
            width="100%"
          />
        </div>

        {applicationIsApproved ? (
          <>
            <MuiTextInput
              label={track.compositeFicoData.statusDisplay}
              value={track.compositeFicoData.value || 'None'}
              disabled
              theme="outlined"
              width="100%"
            />
            <div className={'TwoItemFormRow'}>
              <MuiTextInput
                label="Final Income Weighted Risk Score"
                value={track.finalIncomeWeightedRiskScore}
                disabled
                theme="outlined"
                endAdornment={!track.finalIncomeWeightedRiskScore && iwrHelptip}
                width="100%"
              />
              <MuiTextInput
                label="Final Income Weighted Risk Band"
                value={track.finalIncomeWeightedRiskBand}
                disabled
                theme="outlined"
                endAdornment={!track.finalIncomeWeightedRiskBand && iwrHelptip}
                width="100%"
              />
            </div>
          </>
        ) : (
          <>
            {compositeFicoIsCurrentOrFinal && (
              <MuiTextInput
                label={track.compositeFicoData.statusDisplay}
                value={track.compositeFicoData.value || 'None'}
                disabled
                theme="outlined"
                width="100%"
              />
            )}
            <div className={'TwoItemFormRow'}>
              <MuiTextInput
                label="Current Income Weighted Risk Score"
                value={track.currentIncomeWeightedRiskData?.score}
                disabled
                theme="outlined"
                endAdornment={track.currentIncomeWeightedRiskData?.score === 'None' && iwrHelptip}
                width="100%"
              />
              <MuiTextInput
                label="Current Income Weighted Risk Band"
                value={track.currentIncomeWeightedRiskData?.band}
                disabled
                theme="outlined"
                endAdornment={track.currentIncomeWeightedRiskData?.band === 'None' && iwrHelptip}
                width="100%"
              />
            </div>
          </>
        )}
        <div className={'TwoItemFormRow'}>
          <CurrencyInput
            label="Lien Balance Total"
            padFractionalZeros={true}
            value={track.currentLienBalanceTotal || ''}
            disabled
          />
          <CurrencyInput
            label="Past Due Lien Balance Total"
            padFractionalZeros={true}
            value={track.currentPastDueLienBalanceTotal || ''}
            disabled
          />
        </div>
      </>
    </ApplicationReviewSection>
  );
};

export default UnderwritingDetailsController;
