import { gql } from '@apollo/client';

export const GET_FLOOD_INSURANCE_REVIEW = gql`
  query FloodInsuranceReview($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      insurancePolicyReviews {
        identifier
        agent
        agentEmail
        agentPhone
        dwellingAmount
        kind
        policyNumber
        annualCost
        provider
        renewalDate
      }
    }
  }
`;
