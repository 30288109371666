import * as Sentry from '@sentry/react';
import env from './env';

const init = () => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY,
      release: process.env.REACT_APP_VERSION,
      environment: env.getEnv(),
      debug: process.env.NODE_ENV === 'development',
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    });
  }
};

const setUser = data => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.setUser(data);
  }
};

const log = (message, data) => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.captureMessage(message, data);
  }
};

const logError = (message, metadata) => {
  log(message, { level: 'error', ...metadata });
};

const logWarning = (message, metadata) => {
  log(message, { level: 'warning', ...metadata });
};

const logDebug = (message, metadata) => {
  log(message, { level: 'debug', ...metadata });
};

const sentryMethods = {
  init,
  setUser,
  logError,
  logWarning,
  logDebug,
};

export default sentryMethods;
