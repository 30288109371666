import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon, Label } from '@hometap/htco-components';
import usePrevious from 'hooks/usePrevious';

import './FriendlyIdInput.scss';

const FriendlyIdInput = ({
  disabled = false,
  error,
  handleRejectedPasteValues = () => {},
  helperText,
  label,
  name,
  onBlur = () => {},
  onChange = () => {},
  registerRequiredField,
  required = false,
  theme = 'filled',
  unregisterRequiredField,
  validator,
  value = [],
  width = 'auto',
  resetErrorMessage,
}) => {
  const [currentError, setCurrentError] = useState(error);

  // used to determine if the div should scroll to the bottom
  const previousValue = usePrevious(value);
  const caretRef = useRef();

  // Providing behavior supported by the useForm hook
  // Utility Hook for components to use required fields with useForm
  const useRequiredValidation = ({ name, required, registerRequiredField, unregisterRequiredField }) => {
    const nameRef = useRef();

    useEffect(() => {
      if (required) {
        registerRequiredField && registerRequiredField(name);
      } else {
        unregisterRequiredField && unregisterRequiredField(name);
      }

      if (nameRef.current !== name) {
        unregisterRequiredField && unregisterRequiredField(nameRef.current);
        nameRef.current = name;
      }

      return () => unregisterRequiredField && unregisterRequiredField(name);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, required]);
  };

  useRequiredValidation({ name, required, registerRequiredField, unregisterRequiredField });

  /**
   * Validates list of strings
   * @param {array[string]} val
   * @returns
   */
  const handleValidation = val => {
    if (val || required) {
      if (validator) {
        return validator(val);
      }
    }
  };

  /**
   * Called when user pastes within input field
   * @param {Event} event
   */
  const handlePaste = event => {
    event.preventDefault();
    const data = event.clipboardData.getData('text/plain');
    const modified_data = [...data.matchAll(/[A-Z]{2}\d{6}\b/g)].map(a => a[0]);
    const remainder = data
      .replace(/[A-Z]{2}\d{6}\b/g, '')
      .replace(/\s+/g, ',')
      .split(',')
      .filter(Boolean);
    const uniqueFriendlyIdList = [...new Set([...value, ...modified_data])]; //use Set to prevent duplicates
    onChange(uniqueFriendlyIdList, name, handleValidation(uniqueFriendlyIdList));
    handleRejectedPasteValues(remainder);
    setCurrentError(error);
  };

  /**
   * called onBeforeInput
   * @param {Event} event
   */
  const handleInput = event => {
    event.preventDefault();
    setCurrentError(helperText);
  };

  /**
   * called onBlur, resets error text and then calls the props func
   */
  const handleOnBlur = () => {
    setCurrentError(error);
    resetErrorMessage();
    onBlur();
  };

  /**
   * removes friendlyId from friendlyId list
   * @param {String} friendlyId
   */
  const handleRemoveFriendlyId = friendlyId => {
    const newFriendlyIdList = value.filter(id => id !== friendlyId);
    onChange(newFriendlyIdList, name, handleValidation(newFriendlyIdList));
  };

  /**
   * determines if there is an error message
   */
  const doShowError = () => {
    return currentError?.length >= 1;
  };

  const inputStyle = { width };
  const isOutlinedTheme = theme === 'outlined';
  const isFilledTheme = theme === 'filled';

  const contentEditableClass = cx('ContentEditable', {
    isFilled: isFilledTheme,
    isOutlined: isOutlinedTheme,
    isContent: value.length,
    isDisabled: disabled,
    isError: doShowError(),
  });

  useEffect(() => {
    // scroll to end only if input is growing not removed
    if (value?.length > previousValue?.length) {
      caretRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [value, previousValue]);

  return (
    <div>
      <div className="FriendlyIdInputContainer" style={inputStyle}>
        <div
          contentEditable
          onPaste={handlePaste}
          onBeforeInput={handleInput}
          onBlur={handleOnBlur}
          name={name}
          className={contentEditableClass}
          suppressContentEditableWarning="True"
          // disable grammarly which will break this component
          data-gramm="false"
        >
          {value.map(friendlyId => (
            <span
              key={friendlyId}
              className="FriendlyIdTag"
              contentEditable="False"
              suppressContentEditableWarning="True"
            >
              {friendlyId}
              <button className="RemoveFriendlyIdButton" onClick={() => handleRemoveFriendlyId(friendlyId)}>
                <Icon name="cancel" color="#152033" />
              </button>
            </span>
          ))}
          <div className="CaretRenderer" ref={caretRef} />
          {/* Workaround to force cursor/caret render at end of friendlyIds */}
          {doShowError() && <Icon classname="iconError" color="#b41a1a" name="icon-error" size="lg" />}
        </div>
        <label htmlFor={name}>
          <Label className={cx('LabelText', { isError: doShowError() })}>
            {label} {required ? '*' : ''}
          </Label>
        </label>
      </div>
      <p className={cx('HelperText', { isError: doShowError() })}>{doShowError() ? currentError : helperText}</p>
    </div>
  );
};

FriendlyIdInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.node, //Provided by useForm regsiter field
  handleRejectedPasteValues: PropTypes.func,
  headerText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onBlur: PropTypes.func, //Provided by useForm regsiter field
  onChange: PropTypes.func, //Provided by useForm regsiter field
  registerRequiredField: PropTypes.func, //Provided by useForm regsiter field
  required: PropTypes.bool,
  theme: PropTypes.oneOf(['filled', 'outlined']),
  unregisterRequiredField: PropTypes.func, //Provided by useForm regsiter field
  validator: PropTypes.func,
  value: PropTypes.array,
  width: PropTypes.string,
  resetErrorMessage: PropTypes.func,
};

export default FriendlyIdInput;
