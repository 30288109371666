import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Container, Paper } from '@hometap/htco-components';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import AlertError from 'components/AlertError/AlertError';
import './InvestmentCalculatorHeader.scss';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import { totalEndingEquityErrorFromCriteria } from 'apps/investment-calculators/utils/calculatorErrors';

const InvestmentCalculatorHeader = ({
  track,
  status,
  maxInvestment,
  isLoading,
  calculatedData,
  calculationsError,
  errorMessage,
}) => {
  const [cachedStatus, setCachedStatus] = useState();
  const hasTrack = !!track;

  useEffect(() => {
    if (!isLoading && status !== undefined) {
      setCachedStatus(status);
    }
  }, [status, isLoading]);

  let displayStatus = cachedStatus ? 'Eligible' : 'Ineligible';

  const totalEndingEquityError = totalEndingEquityErrorFromCriteria(calculatedData?.uwFailedEligibilityCriteria);
  const increaseInPaydownAmount = calculatedData?.increaseInPaydown;
  if (totalEndingEquityError && increaseInPaydownAmount) {
    displayStatus = 'Paydown Required';
  }

  let error = errorMessage;
  if (calculationsError && !error) {
    displayStatus = 'Error';
    const fullErrorMsg = calculationsError.toString();
    if (fullErrorMsg.match('DMN call failed')) {
      error = (
        <span>
          We are having trouble fetching the calculated data for this Track.
          <ActionsTooltip className="InvestmentCalculatorErrorCopy" value={fullErrorMsg}>
            Copy details
          </ActionsTooltip>
        </span>
      );
    } else {
      error = <span>{fullErrorMsg.replace(/^Error:/, '').substring(0, 150)}</span>;
    }
  }

  return (
    <Container row className="InvestmentCalculatorHeader">
      {hasTrack && (
        <Container grid="7">
          <span className="InvestmentCalculatorHeaderPreText">
            FRIENDLY ID: {track.friendlyId}
            <span className="InvestmentCalculatorHeaderBullet">•</span>
            {track.product.termYears} YR, {track.product.name}
          </span>
          <Paper theme="plain" className="InvestmentCalculatorHeaderAddress">
            <h1>{track.fullAddress}</h1>
          </Paper>
        </Container>
      )}
      <Container grid={hasTrack ? '3' : '7'} className="InvestmentCalculatorStatusContainer" justify={'flex-end'}>
        <span className="InvestmentCalculatorHeaderPreText">Status</span>
        <LoadingContainer isLoading={isLoading}>
          <h1 className={cx('InvestmentCalculatorStatus', { isIneligible: !cachedStatus || error })}>
            {displayStatus}
          </h1>
        </LoadingContainer>
      </Container>
      <Container
        grid={hasTrack ? '2' : '5'}
        className="InvestmentCalculatorMaximumInvestmentContainer"
        justify={'flex-end'}
      >
        <span className="InvestmentCalculatorHeaderPreText">Maximum Investment</span>
        <LoadingContainer isLoading={isLoading}>
          <h1>{maxInvestment}</h1>
        </LoadingContainer>
      </Container>
      {error && <AlertError error={error} />}
    </Container>
  );
};

export default InvestmentCalculatorHeader;
