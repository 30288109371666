import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getGraphQlUrl } from 'utils/api';

const clientOptions = {
  uri: getGraphQlUrl(),
  credentials: 'include',
  cache: new InMemoryCache({
    /**
    /* by default, an object's cache ID is the concatenation of the object's __typename and id (or _id)
    /* to accomplish this, you define a TypePolicy for each type you want to customize.
     **/
    typePolicies: {
      TaskConditionNode: {
        keyFields: ['identifier'],
      },
      UserNode: {
        keyFields: ['identifier'],
      },
      RiskBandNode: {
        keyFields: ['identifier'],
      },
      DocumentInterface: {
        keyFields: ['identifier'],
      },

      AddressNode: {
        keyFields: ['identifier'],
      },

      HomeNode: {
        keyFields: ['identifier'],
      },
      HomeValuationNode: {
        keyFields: ['identifier'],
      },
      LabelValueType: {
        keyFields: ['value'],
      },

      LienNode: {
        keyFields: ['identifier'],
      },

      FraudGuardDataNode: {
        keyFields: ['identifier'],
      },

      BpmTaskNode: {
        keyFields: ['identifier'],
      },

      GovernmentIDNode: {
        keyFields: ['identifier'],
      },

      ConfigurationsNode: {
        keyFields: [],
      },
    },
  }),
};

if (window.Cypress) {
  clientOptions.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
  };
}

export const client = new ApolloClient(clientOptions);
