import React from 'react';
import { NotFoundBlock, Paper } from '@hometap/htco-components';

import FilterableTable from 'components/FilterableTable/FilterableTable';
import useQueryParams from 'hooks/useQueryParams';
import useFilterableQuery from 'hooks/useFilterableQuery';
import { getPortalsUrl } from 'utils/links';
import { ITEMS_PER_PAGE } from 'utils/tables';

import { getTrackDetailsTasksLinks } from '../track-details/utils/trackDetailsLinks';
import { GET_MORE_TASKS } from './queries';
import './Tasks.scss';
import { segmentListType } from 'utils/segment/segment';
import useCurrentUser from 'hooks/useCurrentUser';

const columns = [
  {
    name: 'Task',
    sortable: false,
    sortKey: 'kind',
    selector: row => (
      <a data-test={row.name} href={getTrackDetailsTasksLinks(row.track.identifier, row.identifier).taskUrl}>
        {row.name}
      </a>
    ),
    grow: 3,
  },
  {
    name: 'Friendly ID',
    sortable: false,
    sortKey: 'friendlyId',
    selector: row => row.track.friendlyId,
    cell: row => (
      <a data-test={row.track.friendlyId} href={getTrackDetailsTasksLinks(row.track.identifier).tasksUrl}>
        {row.track.friendlyId}
      </a>
    ),
  },
];

const TasksController = () => {
  const { searchParamsObject } = useQueryParams();
  const { user } = useCurrentUser();
  const requiredFilters = {
    assignee: user?.identifier,
  };
  const {
    data: unsortedTasks,
    error,
    loading,
    total,
    resetDefaultPage,
    handleSort,
    handleChangePage,
  } = useFilterableQuery({
    query: GET_MORE_TASKS,
    dataKey: 'unifiedTasks',
    pageLimit: ITEMS_PER_PAGE,
    defaultFilters: {
      ...searchParamsObject,
      ...requiredFilters,
    },
    segmentType: segmentListType.tasks,
  });

  // Sort s.t. Escalated tasks appear first
  const sortedTasks = unsortedTasks.sort((a, b) => (a.taskDefinitionKey < b.taskDefinitionKey ? -1 : 1));

  let errorDataComponent;
  if (error) {
    errorDataComponent = (
      <NotFoundBlock
        error={`${error.networkError?.statusCode || 404} error`}
        buttonLink={getPortalsUrl('auth/login/reviewer')}
        title="We're having trouble fetching these tasks. Please try refreshing"
      >
        If the problem persists please contact engineering.
      </NotFoundBlock>
    );
  }

  return (
    <div className="TasksControllerContainer">
      <Paper pad={0}>
        <FilterableTable
          testId="tasks-table"
          data={sortedTasks}
          loading={loading}
          resetDefaultPage={resetDefaultPage}
          onPageRequest={handleChangePage}
          onSort={handleSort}
          totalItems={total}
          errorDataComponent={errorDataComponent}
          columns={columns}
        />
      </Paper>
    </div>
  );
};

export default TasksController;
