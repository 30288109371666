import { gql } from '@apollo/client';

export const GET_HOA_REVIEW = gql`
  query HoaReview($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      hoaReview {
        hoaName
        totalAnnualHoaFee
      }
    }
  }
`;
