import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import { useState } from 'react';

export const useAppReviewFormDeletionV1 = ({
  sections,
  setSections,
  currentSection,
  setCurrentSection,
  memoNodes,
  deleteExistingDBRecord,
  setReportFieldsTouched,
  draftKey,
}) => {
  const [nodesWithoutDeleted, setNodesWithoutDeleted] = useState(memoNodes);
  const { deleteFormById } = useUpdateSectionForm();

  const deleteSection = deletedId => {
    const updatedSections = sections.filter(section => section.identifier !== deletedId);
    setSections(updatedSections);

    if (currentSection.identifier === deletedId) {
      setCurrentSection(updatedSections[0] ?? {});
      setReportFieldsTouched(prevState => {
        const reportFieldsTouched = { ...prevState };
        delete reportFieldsTouched[deletedId];
        return reportFieldsTouched;
      });
    }
  };

  const deleteItem = async () => {
    const itemId = currentSection.identifier;
    if (!currentSection[draftKey]) {
      await deleteExistingDBRecord(itemId);
      setNodesWithoutDeleted(nodesWithoutDeleted.filter(node => node.identifier !== itemId));
    }
    deleteSection(itemId);
    deleteFormById(itemId);
  };

  return {
    nodesWithoutDeleted,
    setNodesWithoutDeleted,
    deleteItem,
  };
};
