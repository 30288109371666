import React, { useContext, useEffect } from 'react';
import { getLoginUrl, getPortalsUrl } from 'utils/links';
import segment, { browserIdentify } from 'utils/segment/segment';
import sentry from '../utils/sentry';
import {
  isInApplicationSpecialistGroup,
  isInInvestmentManagerGroup,
  isInUnderwriterGroup,
  isInServicerGroup,
  isOnlyInvestmentManager,
  isOnlyApplicationSpecialist,
  hasBatchDocumentSetsPrivileges,
  isInExternalContractorGroup,
  canReadWriteDataEntryTasks,
} from 'userRoles';
import { gql, useQuery } from '@apollo/client';
import { isInInvestmentGuideCalculatorRolloutGroup } from 'userRolloutGroups';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      identifier
      firstName
      lastName
      roles
      rolloutFlags
      userType
      email
      reviewer {
        identifier
      }
    }
  }
`;

const Context = React.createContext({});

// Hook to get current user data, returns "contextData" below
const useCurrentUser = () => useContext(Context);
export default useCurrentUser;

// Provider which fetches the current logged in user
// and passes that data through to context.
export const CurrentUserProvider = ({ children }) => {
  const { loading, error, data, refetch } = useQuery(GET_CURRENT_USER);
  const user = data?.currentUser;

  useEffect(() => {
    // Logout if session has ended resulting in a 403
    if (error && error?.networkError?.statusCode === 403) {
      window.api.redirect(getLoginUrl());
    }
  }, [error]);

  useEffect(() => {
    // If a client user is trying to reach this app, redirect to home
    if (user?.userType === 'client') {
      window.location = getPortalsUrl();
      segment.disable();
    } else if (user?.userType === 'reviewer') {
      browserIdentify(user.email, {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
      });
    }
    if (user) {
      sentry.setUser(user);
    }
  }, [user]);

  const contextData = {
    loading,
    user,
    error,
    fetch: refetch,
    isOnlyInvestmentManager: isOnlyInvestmentManager(user),
    isInInvestmentManagerGroup: isInInvestmentManagerGroup(user),
    isInApplicationSpecialistGroup: isInApplicationSpecialistGroup(user),
    isInUnderwriterGroup: isInUnderwriterGroup(user),
    isInServicerGroup: isInServicerGroup(user),
    isOnlyApplicationSpecialist: isOnlyApplicationSpecialist(user),
    isInInvestmentGuideCalculatorRolloutGroup: isInInvestmentGuideCalculatorRolloutGroup(user),
    hasBatchDocumentSetsPrivileges: hasBatchDocumentSetsPrivileges(user),
    isContractor: isInExternalContractorGroup(user),
    canReadWriteDataEntryTasks: canReadWriteDataEntryTasks(user),
  };

  // This doesn't return children until user has loaded so that nested components don't need
  // to handled loading/error handling UI for the user.
  return (
    <Context.Provider value={contextData}>
      {(loading && !user) || user?.userType === 'client' ? null : children}
    </Context.Provider>
  );
};
