import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './HtmlRenderer.scss';

const DEFAULT_HTML_RENDERER_TAG = 'p';

/**
 * HtmlRenderer Component is to render a task description
 * that may contain html or could be a plain text
 * @property description - description of the task
 * @property tag - any type of tag we want to wrap a description, 'p' is a default tag
 * @property className - additional className
 */

const HtmlRenderer = ({ description, tag: Tag, className }) => {
  const htmlRenderClassName = cx('HtmlRenderer', className);
  let htmlRenderBody;

  if (typeof description !== 'string' || !description) {
    return null;
  }

  if (description.trim().startsWith('<')) {
    htmlRenderBody = <div className={htmlRenderClassName} dangerouslySetInnerHTML={{ __html: description }} />;
  } else {
    htmlRenderBody = <Tag className={htmlRenderClassName}>{description}</Tag>;
  }

  return htmlRenderBody;
};

HtmlRenderer.propTypes = {
  description: PropTypes.string.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
};

HtmlRenderer.defaultProps = {
  tag: DEFAULT_HTML_RENDERER_TAG,
};

export default HtmlRenderer;
