import React from 'react';
import { DataTable, LoadingPlaceholder } from '@hometap/htco-components';

import ApplicantFullNameAndType from 'components/ApplicantFullNameAndType/ApplicantFullNameAndType';
import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import { currency } from 'utils/numbers';
import { formatMMDDYYYY } from 'utils/dates';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { GET_TRACK_TRADELINES } from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/RedFlagCheck/getTrackTradelines';

import './RedFlagCheck.scss';

const renderFalseyInt = value => value || '0';

const DelinquenciesTableDisplay = ({ totalDelinquencies, currentDelinquencyAmount, delinquencyDays }) => (
  <p className="RedFlagCheckTableDropdownDisplay">
    This mortgage has {currentDelinquencyAmount} delinquencies over {delinquencyDays} days with a total of{' '}
    {totalDelinquencies} delinquencies over {delinquencyDays} days across all mortgages
  </p>
);

const DataTableWrapper = ({ children, loadingClass, heading }) => (
  <TaskDetailContentSectionWrapper className="RedFlagCheckDataTableContainer" heading={heading}>
    <div className={loadingClass}>{children}</div>
  </TaskDetailContentSectionWrapper>
);

const RedFlagDataTable = ({ data, columns, primaryColumns }) => (
  <DataTable data={data} columns={columns} primaryColumns={primaryColumns} />
);

const MortgageTradelineTable = ({ tradelines = [] }) => {
  if (!tradelines.length) return <p>There is no mortgage tradeline data at this moment.</p>;

  const sumTradelineFieldTotals = field =>
    tradelines.reduce((runningSum, tradeline) => {
      runningSum += tradeline[field];
      return runningSum;
    }, 0);

  const allColumns = [
    {
      name: '',
      key: 'name',
      selector: row => row.name,
    },
    { name: 'Status Date', key: 'statusDate', selector: row => row.statusDate },
    { name: 'Amount Past Due', key: 'amountPastDue', selector: row => row.amountPastDue },
    { name: 'Balance Amount', key: 'balanceAmount', selector: row => row.balanceAmount },
    { name: 'Lender/Servicer', key: 'lender', selector: row => row.lender },
    { name: 'Account Type', key: 'accountType', selector: row => row.accountType },
    {
      name: 'Value',
      key: 'value',
      selector: row => row.value,
    },
    {
      name: 'Total Value for All Mortgage Tradelines',
      key: 'total',
      selector: row => row.total,
    },
    {
      name: 'Term Duration',
      key: 'term',
      selector: row => row.termsDuration,
    },
    {
      name: 'Balance Amount Total For All Mortgages',
      key: 'balanceAmountTotal',
      selector: row => row.balanceAmountTotal,
    },
    {
      name: 'Monthly Payment Amount',
      key: 'monthlyPayment',
      selector: row => row.monthlyPayment,
    },
    {
      name: 'Total Monthly Payment for All Mortgages',
      key: 'totalMonthlyPayment',
      selector: row => row.totalMonthlyPayment,
    },
    { name: 'Payment Status', key: 'paymentStatus', selector: row => row.paymentStatus },
    {
      name: 'Delinquencies Over 30 Days',
      key: 'delinquenciesOver30Days',
      selector: row => (
        <DelinquenciesTableDisplay
          totalDelinquencies={row.delinquenciesOver30Days?.total}
          currentDelinquencyAmount={row.delinquenciesOver30Days?.rowValue}
          delinquencyDays={30}
        />
      ),
    },
    {
      name: 'Delinquencies Over 60 Days',
      key: 'delinquenciesOver60Days',
      selector: row => (
        <DelinquenciesTableDisplay
          totalDelinquencies={row.delinquenciesOver60Days?.total}
          currentDelinquencyAmount={row.delinquenciesOver60Days?.rowValue}
          delinquencyDays={60}
        />
      ),
    },
    {
      name: 'Delinquencies Over 90 Days',
      key: 'delinquenciesOver90Days',
      selector: row => (
        <DelinquenciesTableDisplay
          totalDelinquencies={row.delinquenciesOver90Days?.total}
          currentDelinquencyAmount={row.delinquenciesOver90Days?.rowValue}
          delinquencyDays={90}
        />
      ),
    },
    {
      name: 'Status Code',
      key: 'statusCode',
      selector: row => row.statusCode,
    },
  ];

  const mainColumns = allColumns.slice(0, 4);

  const tableData = tradelines.map((tradeline, i) => {
    const {
      accountType,
      amountPastDue,
      amountSectionValueOriginal,
      balanceAmount,
      delinquenciesOver30Days,
      delinquenciesOver60Days,
      delinquenciesOver90Days,
      enhancedPaymentDataStatus,
      monthlyPaymentAmount,
      statusCode,
      statusDate,
      subscriberDisplayName,
      termsDuration,
    } = tradeline;
    return {
      name: <span style={{ width: 33.33 }}>Tradeline {i + 1}</span>,
      statusDate: formatMMDDYYYY(statusDate),
      amountPastDue: currency(amountPastDue),
      balanceAmount: currency(balanceAmount),
      accountType,
      value: currency(currency(amountSectionValueOriginal)),
      total: currency(sumTradelineFieldTotals('amountSectionValueOriginal')),
      statusCode,
      termsDuration,
      lender: subscriberDisplayName,
      balanceAmountTotal: currency(sumTradelineFieldTotals('balanceAmount')),
      monthlyPayment: currency(renderFalseyInt(monthlyPaymentAmount)),
      totalMonthlyPayment: currency(sumTradelineFieldTotals('monthlyPaymentAmount')),
      paymentStatus: renderFalseyInt(enhancedPaymentDataStatus),
      delinquenciesOver30Days: {
        rowValue: renderFalseyInt(delinquenciesOver30Days),
        total: renderFalseyInt(sumTradelineFieldTotals('delinquenciesOver30Days')),
      },
      delinquenciesOver60Days: {
        rowValue: renderFalseyInt(delinquenciesOver60Days),
        total: renderFalseyInt(sumTradelineFieldTotals('delinquenciesOver60Days')),
      },
      delinquenciesOver90Days: {
        rowValue: renderFalseyInt(delinquenciesOver90Days),
        total: renderFalseyInt(sumTradelineFieldTotals('delinquenciesOver90Days')),
      },
    };
  });

  return (
    <RedFlagDataTable
      data={tableData}
      columns={allColumns}
      heading="Mortgage Tradelines"
      primaryColumns={mainColumns}
    />
  );
};

const CreditInquiresTable = ({ creditInquires = [] }) => {
  if (!creditInquires.length) return <p>There is no credit inquiry information at this moment.</p>;

  const allColumns = [
    { name: '', key: 'name', selector: row => row.name },
    { name: 'Date of Inquiry', key: 'creditInquiry', selector: row => row.creditInquiry },
    { name: 'Account Type', key: 'accountType', selector: row => row.accountType },
    { name: 'Lender/Servicer', key: 'lender', selector: row => row.lender },
  ];

  const mainColumns = allColumns.slice(0, 3);

  const data = creditInquires.map((inquiry, i) => {
    return {
      name: <span style={{ width: 33.33 }}>Credit Inquiry {i + 1}</span>,
      creditInquiry: formatMMDDYYYY(inquiry.inquiryDate),
      lender: inquiry.type,
      accountType: inquiry.subscriberDisplayName,
    };
  });

  return <RedFlagDataTable data={data} columns={allColumns} heading="Credit Inquires" primaryColumns={mainColumns} />;
};

const BankruptciesTable = ({ bankruptcies = [] }) => {
  if (!bankruptcies.length) return <p>There is no credit bankruptcy information at this moment.</p>;
  const allColumns = [
    { name: '', key: 'name', selector: row => row.name },
    { name: 'Status Date', key: 'statusDate', selector: row => row.statusDate },
    { name: 'Filing Date', key: 'filingDate', selector: row => row.filingDate },
    { name: 'ECOA', key: 'ecoa', selector: row => row.ecoa },
    { name: 'Status', key: 'status', selector: row => row.status },
  ];

  const mainColumns = allColumns.slice(0, 4);

  const data = bankruptcies.map((bankruptcy, i) => {
    return {
      name: <span style={{ width: 33.33 }}>Bankruptcy {i + 1}</span>,
      status: bankruptcy.status,
      ecoa: bankruptcy.ecoa,
      statusDate: formatMMDDYYYY(bankruptcy.statusDate),
      filingDate: formatMMDDYYYY(bankruptcy.filingDate),
    };
  });

  return <RedFlagDataTable data={data} columns={allColumns} heading="Bankruptcies" primaryColumns={mainColumns} />;
};

const RedFlagCheck = () => {
  const { data = {}, loading } = useSpecificContentQuery(GET_TRACK_TRADELINES);
  const { applicants = [] } = data.track || {};
  const primaryApplicant = applicants?.find(({ isPrimaryApplicant }) => isPrimaryApplicant);

  return (
    <LoadingPlaceholder isLoading={loading}>
      {({ loadingClass }) => {
        return (
          <div className="TaskDetailContent">
            <div className="TaskDetailMarginBlock">
              {/* This is a one off list item that we want nested like list items coming from the backend "description" field  */}
              <ul>
                <li>If disclosed, create a task for 'Hard Credit Pull'</li>
              </ul>
            </div>
            <div className="TaskDetailContent">
              <TaskDetailContentSectionWrapper heading="Applicant Information">
                <div className={loadingClass}>
                  {applicants?.map(applicant => {
                    return (
                      <ul className="TaskDetailList" key={applicant.id}>
                        <ApplicantFullNameAndType applicant={applicant} />
                        <div>
                          <span>Warnings: </span>
                          {!!applicant.warnings.length ? (
                            applicant.warnings.map((warning, i) => (
                              <li key={`applicant-${applicant.id}-waring-${i}`}>{warning}</li>
                            ))
                          ) : (
                            <span>None</span>
                          )}
                        </div>
                      </ul>
                    );
                  })}
                </div>
              </TaskDetailContentSectionWrapper>
              <DataTableWrapper loadingClass={loadingClass} heading="Mortgage Tradelines">
                <MortgageTradelineTable
                  tradelines={primaryApplicant?.riskBand?.tradelineData?.riskMortgageTradelines}
                />
              </DataTableWrapper>

              <DataTableWrapper loadingClass={loadingClass} heading="Credit Inquires">
                <CreditInquiresTable creditInquires={primaryApplicant?.riskBand?.tradelineData?.creditInquiries} />
              </DataTableWrapper>

              <DataTableWrapper loadingClass={loadingClass} heading="Bankruptcies">
                <BankruptciesTable bankruptcies={primaryApplicant?.riskBand?.tradelineData?.riskBankruptcies} />
              </DataTableWrapper>
            </div>
          </div>
        );
      }}
    </LoadingPlaceholder>
  );
};

export default RedFlagCheck;
