import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Icon } from '@hometap/htco-components';
import { DOCUMENT_VISIBILITY } from '../../data/constants';

import './VisibilityRadioRow.scss';

// TODO: https://hometap.atlassian.net/browse/TOT-130 will handle default document visibility for each document kind in the future
export const OVERRIDE_DOCUMENT_KIND_VISIBILITY = {
  executed_contract_set: DOCUMENT_VISIBILITY.both,
};

/**
 * @typedef VisibilityRadioRow
 * @type {object}
 * @param {string} defaultVisibilityLabel: The default visibility label
 * @param {boolean} isDefaultVisibilityEditable: Whether the default visibility is editable. if false, the default visibility label will be displayed and not an input
 * @param {callback} setVisibility: Callback to set the visibility value of the parent component
 * @param {boolean} disabled: Whether the input is disabled
 * @param {string} staticValue: The static value of the visibility
 * @param {boolean} isUpdateVisibilityValue: Whether the component is able to set the parent's state. This does not impact the functionality of manually interacting with the radio buttons.
 * @param {string} extraClassName: Extra class name for the component
 * @param {string} docType: The document kind string. Ex: 'executed_contract_set'
 */
/**
 * @description Radio button component for setting the visibility of a document. This component contains an override object that sets the default visibility for some document kinds that we want to be defaulted to a value that is not normally the default.
 * @param {VisibilityRadioRow} params
 * @returns {JSX.Element}
 */
export const VisibilityRadioRow = ({
  defaultVisibilityLabel = '',
  isDefaultVisibilityEditable = true,
  setVisibility,
  disabled = false,
  staticValue = '',
  isUpdateVisibilityValue = true,
  extraClassName,
  docType,
}) => {
  const defaultVisibility = defaultVisibilityLabel.toLowerCase();
  const [value, setValue] = useState(defaultVisibility);
  const [isDefaultVisibilityChanged, setIsDefaultVisibilityChanged] = useState(false);

  /**
   * @description if the component is able to set the parent's state, update the parent's state when this component's state changes
   */
  useEffect(() => {
    if (isUpdateVisibilityValue) {
      setVisibility(value);
    }
  }, [setVisibility, value, isUpdateVisibilityValue]);

  /**
   * @description handle the default visibility for when the document kind changes or when staticValue changes
   */
  useEffect(() => {
    if (staticValue) {
      setValue(staticValue);
    } else if (OVERRIDE_DOCUMENT_KIND_VISIBILITY[docType]) {
      setValue(OVERRIDE_DOCUMENT_KIND_VISIBILITY[docType]);
    } else {
      setValue(DOCUMENT_VISIBILITY.internal);
    }

    if (!isDefaultVisibilityEditable) {
      setIsDefaultVisibilityChanged(true);
    }
  }, [staticValue, docType, isDefaultVisibilityEditable, defaultVisibility]);

  /**
   * @description if the default visibility changes, update the component's state
   */
  useEffect(() => {
    if (isDefaultVisibilityChanged) {
      if (OVERRIDE_DOCUMENT_KIND_VISIBILITY[docType]) {
        setVisibility(OVERRIDE_DOCUMENT_KIND_VISIBILITY[docType]);
        setValue(OVERRIDE_DOCUMENT_KIND_VISIBILITY[docType]);
        setIsDefaultVisibilityChanged(false);
      } else {
        setVisibility(defaultVisibility);
        setValue(defaultVisibility);
        setIsDefaultVisibilityChanged(false);
      }
    }
  }, [isDefaultVisibilityChanged, defaultVisibility, setVisibility, docType]);

  /**
   * @description Set the visibility value and update the component's state
   * @param {String} value
   */
  const handleClick = value => {
    if (!disabled) {
      setValue(value);
      setVisibility(value);
    }
  };

  return (
    <>
      {isDefaultVisibilityEditable ? (
        <div className={cx('VisibilityRadioRow', extraClassName)}>
          <div className={cx('VisibilityRadioRowRadio', { disabled })} onClick={() => handleClick('internal')}>
            <Icon name={`icon-radio-${value === 'internal' ? 'selected' : 'unselected'}`} />
            Internal
          </div>
          <div className={cx('VisibilityRadioRowRadio', { disabled })} onClick={() => handleClick('both')}>
            <Icon name={`icon-radio-${value === 'both' ? 'selected' : 'unselected'}`} />
            Both
          </div>
        </div>
      ) : (
        defaultVisibilityLabel
      )}
    </>
  );
};
