import React from 'react';
import { Link } from 'react-router-dom';

import { getTrackDetailPageUrls, getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import useCurrentUser from 'hooks/useCurrentUser';

const TrackLink = ({ friendlyId, trackId }) => {
  const { isInApplicationSpecialistGroup, isInUnderwriterGroup } = useCurrentUser();
  const { stageUrl } = getTrackDetailPageUrls(trackId);
  const { applicationReviewUrl } = getApplicationReviewPageUrls(trackId);

  if (isInApplicationSpecialistGroup) {
    return (
      <Link data-test={friendlyId} to={`${trackId}/tasks`}>
        {friendlyId}
      </Link>
    );
  }

  if (isInUnderwriterGroup) {
    return (
      <a data-test={friendlyId} href={applicationReviewUrl}>
        {friendlyId}
      </a>
    );
  }

  return (
    <a data-test={friendlyId} href={stageUrl}>
      {friendlyId}
    </a>
  );
};

export default TrackLink;
