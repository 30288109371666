import { useEffect, useState } from 'react';
import { GET_TRACKS_WITH_FUNDING_DATA } from '../fundingRequests';
import { useQuery } from '@apollo/client';

const useTracksFunding = () => {
  const [tracks, setTracks] = useState([]);
  const { refetch, loading: isTrackFundingLoading } = useQuery(GET_TRACKS_WITH_FUNDING_DATA);

  const handleFetchTracks = async () => {
    const newTracks = [];
    // whether there's a next page for the tracks in the correct stages
    let hasNextPageTracksInCorrectStage = true;
    // the start cursor for the tracks in the correct stages
    let tracksInCorrectStageStartCursor = null;
    // the end cursor for the tracks in the correct stages
    let tracksInCorrectStageEndCursor = null;
    // the page limit for the tracks in the correct stages
    let tracksInCorrectStagePageLimit = null;

    while (hasNextPageTracksInCorrectStage) {
      // get the next set of data for tracks in the correct stages
      // eslint-disable-next-line no-await-in-loop
      const { data } = await refetch({
        nextPageLimit: hasNextPageTracksInCorrectStage ? tracksInCorrectStagePageLimit : null,
        nextPageCursor:
          hasNextPageTracksInCorrectStage && tracksInCorrectStagePageLimit !== 1 ? tracksInCorrectStageEndCursor : null,
        prevPageLimit: !hasNextPageTracksInCorrectStage ? tracksInCorrectStagePageLimit : null,
        prevPageCursor: !hasNextPageTracksInCorrectStage ? tracksInCorrectStageStartCursor : null,
      });

      // get the tracks in the correct stages
      const { tracksInCorrectStages } = data;
      const tracksInCorrectStagesEdges = tracksInCorrectStages?.edges;
      // add all of their edges to the new tracks array
      tracksInCorrectStagesEdges?.forEach(track => {
        if (newTracks.indexOf(track) === -1) {
          newTracks.push(track);
        }
      });
      // get the next page info for the tracks in the correct stages
      hasNextPageTracksInCorrectStage = data?.tracksInCorrectStages?.pageInfo?.hasNextPage;
      hasNextPageTracksInCorrectStage = data?.tracksInCorrectStages?.pageInfo?.hasNextPage;
      tracksInCorrectStageStartCursor = data?.tracksInCorrectStages?.pageInfo?.startCursor;
      tracksInCorrectStageEndCursor = data?.tracksInCorrectStages?.pageInfo?.endCursor;
      tracksInCorrectStagePageLimit = data?.nextPageLimit;
    }

    // whether there's a next page for the tracks in UW Approved
    let hasNextPageTracksInUWApproved = true;
    // the start cursor for the tracks in UW Approved
    let tracksInUWApprovedStartCursor = null;
    // the end cursor for the tracks in UW Approved
    let tracksInUWApprovedEndCursor = null;
    // the page limit for the tracks in UW Approved
    let tracksInUWApprovedPageLimit = null;

    while (hasNextPageTracksInUWApproved) {
      // get the next set of data for tracks in UW Approved
      // eslint-disable-next-line no-await-in-loop
      const { data } = await refetch({
        nextPageLimit: hasNextPageTracksInUWApproved ? tracksInUWApprovedPageLimit : null,
        nextPageCursor:
          hasNextPageTracksInUWApproved && tracksInUWApprovedPageLimit !== 1 ? tracksInUWApprovedEndCursor : null,
        prevPageLimit: !hasNextPageTracksInUWApproved ? tracksInUWApprovedPageLimit : null,
        prevPageCursor: !hasNextPageTracksInUWApproved ? tracksInUWApprovedStartCursor : null,
      });

      // get the tracks in UW Approved
      const { tracksWithUWApproved } = data;
      const tracksInUWApprovedEdges = tracksWithUWApproved?.edges;
      // add all of their edges to the new tracks array (unique protects any repeats with the cursor-based pagination)
      tracksInUWApprovedEdges?.forEach(track => {
        if (newTracks.indexOf(track) === -1) {
          newTracks.push(track);
        }
      });
      // get the next page info for the tracks in UW Approved
      hasNextPageTracksInUWApproved = data?.tracksInUWApproved?.pageInfo?.hasNextPage;
      tracksInUWApprovedStartCursor = data?.tracksInUWApproved?.pageInfo?.startCursor;
      tracksInUWApprovedEndCursor = data?.tracksInUWApproved?.pageInfo?.endCursor;
      tracksInUWApprovedPageLimit = data?.nextPageLimit;
    }

    // set the list of tracks we received once we've received all of them
    setTracks(newTracks);
  };

  useEffect(() => {
    handleFetchTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tracks, handleFetchTracks, isTrackFundingLoading };
};

export default useTracksFunding;
