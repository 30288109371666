import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@hometap/htco-components';
import { VisibilityRadioRow } from '../VisibilityRadioRow/VisibilityRadioRow';
import { FadeIn } from '../FadeIn/FadeIn';

import './NewMergedDocument.scss';
import { PinButton } from '../PinButton/PinButton';

export const NewMergedDocument = ({
  isOpen,
  values,
  onValuesChange,
  defaultVisibilityLabel,
  isDefaultVisibilityEditable,
  documentKind,
}) => {
  const { name, pinned } = values;

  const onValuesChangeHandler = updatedValue => {
    onValuesChange({ ...values, ...updatedValue });
  };

  return (
    <FadeIn open={isOpen}>
      <div className="NewMergedDocumentContainer">
        <h4 className="NewMergedDocumentTitle">New Document</h4>
        <div className="NewMergedDocumentFields">
          <table className="NewMergedDocumentFieldsTable">
            <thead>
              <tr className="NewMergedDocumentHeaders">
                <th style={{ width: '50%' }}>Document Name</th>
                <th style={{ width: '205px' }}>Visibility</th>
                <th>Pin</th>
              </tr>
            </thead>
            <tbody>
              <tr className="NewMergedDocumentInputs">
                <td>
                  <TextInput
                    className="NewMergedDocumentField"
                    placeholder="Document Name"
                    value={name}
                    onChange={name => onValuesChangeHandler({ name })}
                  />
                </td>
                <td>
                  <VisibilityRadioRow
                    setVisibility={value => onValuesChangeHandler({ internal: value === 'internal' })}
                    isUpdateVisibilityValue={false}
                    extraClassName="NewMergedDocumentFieldRadio"
                    defaultVisibilityLabel={defaultVisibilityLabel}
                    isDefaultVisibilityEditable={isDefaultVisibilityEditable}
                    docType={documentKind}
                  />
                </td>
                <td>
                  <PinButton pinned={pinned} size={'2x'} onClick={() => onValuesChangeHandler({ pinned: !pinned })} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </FadeIn>
  );
};

NewMergedDocument.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    internal: PropTypes.bool.isRequired,
  }),
  onValuesChange: PropTypes.func.isRequired,
};
