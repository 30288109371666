import React from 'react';
import { MenuDropdown as MenuDropdownComponent } from '@hometap/htco-components';
import cx from 'classnames';

import './MenuDropdown.scss';

const MenuDropdown = ({ className, trigger, modifiers = [], children, isMenuOpen, onMenuChange, isDisabled }) => {
  const noOffsetModifier = {
    name: 'offset',
    options: {
      offset: [-1, -5],
    },
  };
  return (
    <MenuDropdownComponent
      modifiers={[noOffsetModifier, ...modifiers]}
      disabled={isDisabled}
      className={cx('MenuDropdown', className)}
      openClassName="isOpen"
      trigger={trigger}
      isMenuOpen={isMenuOpen}
      onMenuChange={onMenuChange}
    >
      {children}
    </MenuDropdownComponent>
  );
};

export default MenuDropdown;
