export const sortField = field => (a, b) => {
  if (a[field] === b[field]) {
    return 0;
  }
  return a[field] < b[field] ? -1 : 1;
};

export const mapOptions = (data = [], labelProp, valueProp, sortFn = () => 0) => {
  const items = data.edges ? data.edges.map(item => item.node) : data;
  return [...items].sort(sortFn).map(item => ({ label: item[labelProp], value: item[valueProp] }));
};
