import { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Loader,
  MuiSelect,
  MuiTextInput,
  RadioButtonSet,
  useEscape,
  useForm,
} from '@hometap/htco-components';
import { GET_DOCUMENT_SET_LIST_QUERY } from 'apps/track-details/Documents/DocumentSetRequests';
import { useMutation, useQuery } from '@apollo/client';
import {
  DEFAULT_DOCUMENT_SET_BATCH_FORM,
  DOCUMENT_SET_BATCH_FILE_TYPE_OPTIONS,
  DOCUMENT_SET_BATCH_VERSION_INCLUSION_OPTIONS,
} from 'apps/document-set-batches/data/constants';
import { REQUEST_DOCUMENT_SET_BATCH_QUERY } from 'apps/document-set-batches/documentSetBatchRequests';
import { showNotification } from 'utils/toasts';
import FriendlyIdInput from 'components/FriendlyIdInput/FriendlyIdInput';

import './CreateDocumentSetBatchSlider.scss';
import { SlideInFromRight } from 'apps/track-details/Documents/components/SlideInFromRight/SlideInFromRight';

const CreateDocumentSetBatchSlider = ({ sliderStatus, setSliderStatus, getDocumentSetBatches }) => {
  const { data: documentSetBatchData } = useQuery(GET_DOCUMENT_SET_LIST_QUERY);
  const [selectedDocumentSetBatch, setSelectedDocumentSetBatch] = useState({});
  const [badFriendlyIds, setBadFriendlyIds] = useState([]);

  const formattedDocumentSetBatchOptions = documentSetBatchData?.documentSetTemplates.map(documentSet => ({
    label: documentSet.name,
    value: documentSet.id,
  }));
  const [notify, setNotify] = useState(true);
  const { registerField, updateFormData, isFormValid, formData } = useForm(DEFAULT_DOCUMENT_SET_BATCH_FORM);
  const [requestDocSetBatch, { loading: documentSetBatchLoading }] = useMutation(REQUEST_DOCUMENT_SET_BATCH_QUERY);

  useEscape(true, () => handleCloseSlider());

  const handleSubmit = async () => {
    const queryVariables = {
      documentSetTemplateId: formData?.documentSetType,
      batchRequestName: formData?.documentSetName,
      outputFormat: formData?.fileType,
      includedDocumentVersions: formData?.includeVersion,
      notifyAfterCompletion: notify,
      friendlyIds: formData?.friendlyIds,
    };

    try {
      await requestDocSetBatch({ variables: queryVariables });
      showNotification({
        type: 'success',
        title: 'Document set batch requested.',
        description: `${formData?.documentSetName} has been requested.`,
      });
    } catch (error) {
      const errorDescription =
        error?.message ?? `${formData?.documentSetName} could not be requested. Please try again.`;
      showNotification({
        type: 'error',
        title: 'Document set batch failed.',
        description: errorDescription,
      });
    } finally {
      await getDocumentSetBatches();
    }
    handleCloseSlider();
  };

  /**
   * Closes the slider and resets the form data.
   */
  const handleCloseSlider = () => {
    updateFormData(DEFAULT_DOCUMENT_SET_BATCH_FORM);
    resetErrorMessage();
    setSliderStatus(false);
  };

  const formattedBadFriendlyIds = badFriendlyIds.join(', ');

  /**
   * Resets the list of bad friendly ID's once the user stops interacting.
   */
  const resetErrorMessage = () => {
    setBadFriendlyIds([]);
  };

  return (
    <SlideInFromRight className={'SliderContainer'} closable={true} open={sliderStatus} close={handleCloseSlider}>
      <Container className={'CreateDocumentSetBatchSlider'}>
        <div onClick={handleCloseSlider} className="CreateDocumentSetBatchSliderCloseButton">
          <Icon name="close" size="lg" />
        </div>
        <h2>New Document Set Batch</h2>
        <MuiTextInput label="Batch Request Name" theme="outlined" required {...registerField('documentSetName')} />
        <FriendlyIdInput
          helperText="Must use copy/paste to input Friendly IDs."
          label="Friendly ID(s)"
          required
          theme="outlined"
          handleRejectedPasteValues={setBadFriendlyIds}
          resetErrorMessage={resetErrorMessage}
          {...registerField('friendlyIds')}
        />
        <div>
          <MuiSelect
            required
            label={'Document Set Type'}
            options={formattedDocumentSetBatchOptions}
            {...registerField('documentSetType')}
            onChange={docSetID => {
              const selectedDocumentSet = documentSetBatchData?.documentSetTemplates.find(
                documentSet => documentSet.id === docSetID,
              );
              if (selectedDocumentSet) {
                updateFormData({
                  fileType: selectedDocumentSet?.outputFormat,
                  includeVersion: selectedDocumentSet?.includedDocumentVersions,
                  documentSetType: docSetID,
                });
                setSelectedDocumentSetBatch(selectedDocumentSet);
              }
            }}
            isMuiSelect={true}
            width={'100%'}
            theme="outlined"
          />
        </div>
        {formData?.documentSetType && (
          <>
            <div>
              <strong>File Types</strong>
              <RadioButtonSet
                className="CreateDocumentSetRadioButtonSet"
                name="documentSetFileType"
                inline
                required
                options={DOCUMENT_SET_BATCH_FILE_TYPE_OPTIONS(selectedDocumentSetBatch?.outputFormat)}
                {...registerField('fileType')}
              />
            </div>
            <div>
              <strong>Document Versions</strong>
              <RadioButtonSet
                className="CreateDocumentSetRadioButtonSet"
                required
                name="documentSetFileType"
                inline
                options={DOCUMENT_SET_BATCH_VERSION_INCLUSION_OPTIONS(
                  selectedDocumentSetBatch?.includedDocumentVersions,
                )}
                {...registerField('includeVersion')}
              />
            </div>
          </>
        )}
        {formattedBadFriendlyIds.length > 0 && (
          <div className="BadFriendlyIdWarningContainer">
            <Icon name="icon-alert" color="#B41A1A" className="BadFriendlyIdWarningIcon" />
            <span>{formattedBadFriendlyIds} did not paste. Please check to make sure the values are correct.</span>
          </div>
        )}
      </Container>
      <div className={'CreateDocumentSetBatchSliderFooter'}>
        <Checkbox label="Notify me by email when complete" checked={notify} onChange={setNotify} />
        <span className="CreateDocumentSetBatchSliderFooterButton">
          <Button onClick={handleCloseSlider} theme="link">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!isFormValid || documentSetBatchLoading}
            className="w-[150px] flex-row items-center justify-items-center"
          >
            {documentSetBatchLoading ? <Loader theme="inverse" /> : <>Create batch</>}
          </Button>
        </span>
      </div>
    </SlideInFromRight>
  );
};

export default CreateDocumentSetBatchSlider;
