"use strict";
// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFilterApplied = exports.internalRundownInteractionOccurred = exports.cTAClicked = exports.setTypewriterOptions = exports.defaultValidationErrorHandler = exports.ListType = void 0;
var ListType;
(function (ListType) {
    ListType["Tasks"] = "tasks";
    ListType["Todos"] = "todos";
    ListType["Tracks"] = "tracks";
})(ListType = exports.ListType || (exports.ListType = {}));
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
var defaultValidationErrorHandler = function (message, violations) {
    var msg = JSON.stringify({
        type: 'Typewriter JSON Schema Validation Error',
        description: "You made an analytics call (".concat(message.event, ") using Typewriter that doesn't match the ") +
            'Tracking Plan spec.',
        errors: violations,
    }, undefined, 2);
    console.warn(msg);
};
exports.defaultValidationErrorHandler = defaultValidationErrorHandler;
var analytics = function () {
    return window.analytics;
};
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
function setTypewriterOptions(options) {
    analytics = options.analytics ? function () { return options.analytics || window.analytics; } : analytics;
}
exports.setTypewriterOptions = setTypewriterOptions;
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message) {
    if (message === void 0) { message = {}; }
    return __assign(__assign({}, message), { context: __assign(__assign({}, (message.context || {})), { typewriter: {
                language: 'typescript',
                version: '8.1.0',
            } }) });
}
/**
 * Fires a 'CTAClicked' track call.
 *
 * @param CTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function cTAClicked(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('CTA Clicked', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.cTAClicked = cTAClicked;
/**
 * Fires a 'InternalRundownInteractionOccurred' track call.
 *
 * @param InternalRundownInteractionOccurred props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function internalRundownInteractionOccurred(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('Internal Rundown Interaction Occurred', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.internalRundownInteractionOccurred = internalRundownInteractionOccurred;
/**
 * Fires a 'ListFilterApplied' track call.
 *
 * @param ListFilterApplied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
function listFilterApplied(props, options, callback) {
    var a = analytics();
    if (a) {
        a.track('List Filter Applied', props || {}, __assign(__assign({}, options), { context: __assign(__assign({}, ((options === null || options === void 0 ? void 0 : options.context) || {})), { typewriter: {
                    language: 'typescript',
                    version: '',
                } }) }), callback);
    }
}
exports.listFilterApplied = listFilterApplied;
var clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions: setTypewriterOptions,
    /**
     * Fires a 'CTAClicked' track call.
     *
     * @param CTAClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cTAClicked: cTAClicked,
    /**
     * Fires a 'InternalRundownInteractionOccurred' track call.
     *
     * @param InternalRundownInteractionOccurred props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    internalRundownInteractionOccurred: internalRundownInteractionOccurred,
    /**
     * Fires a 'ListFilterApplied' track call.
     *
     * @param ListFilterApplied props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    listFilterApplied: listFilterApplied,
};
exports.default = new Proxy(clientAPI, {
    get: function (target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method];
        }
        return function () {
            console.warn("\u26A0\uFE0F  You made an analytics call (".concat(String(method), ") that can't be found. Either:\n         a) Re-generate your typewriter client: `npx typewriter`\n         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE"));
            var a = analytics();
            if (a) {
                a.track('Unknown Analytics Call Fired', {
                    method: method,
                }, withTypewriterContext());
            }
        };
    },
});
