import { gql, useQuery } from '@apollo/client';
import { compareAndAlphabetize } from '../DocumentsUtils';

export const GET_TRACK_APPLICANTS = gql`
  query GetTrackApplicants($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      applicants {
        identifier
        fullName
        fullNameShort

        person {
          identifier
        }
      }
    }
  }
`;

/**
 * @typedef Option
 * @type {object}
 * @property {string} value applicant id
 * @property {string} label applicant name or name short
 * /

/**
 * @typedef UseDocumentApplicantsReturn
 * @type {object}
 * @property {Applicant[]} [applicants] The list of the applicants
 * @property {Option[]} [applicantsOptions] The list of the applicants to show in the select
 * @property {boolean} applicantsLoading Are the applicants loading?
 */
/** Use the hooks to get the applicants
 * @param {string} trackId
 * @returns {UseDocumentApplicantsReturn}
 */
export const useTrackApplicants = trackId => {
  const { loading: applicantsLoading, data } = useQuery(GET_TRACK_APPLICANTS, {
    variables: {
      trackId,
    },
  });

  const applicantsOptions = data?.track?.applicants
    ?.map(applicant => ({ value: applicant.person.identifier, label: applicant.fullName || applicant.fullNameShort }))
    .sort((a, b) => compareAndAlphabetize(a, b, 'label'));

  return {
    applicants: data?.track?.applicants,
    applicantsOptions,
    applicantsLoading,
  };
};
