import React from 'react';
import { DataTable, LoadingPlaceholder } from '@hometap/htco-components';

import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';
import { currency } from 'utils/numbers';
import { formatMonthDayYear } from 'utils/dates';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { GET_MINIMUM_REQUIRED_DOCS_DATA } from './getMinimumRequiredDocsData';

import './MinimumRequiredDocs.scss';

const LienTable = ({ liens = [] }) => {
  const toNullishNoneString = value => value ?? 'None';

  const data = liens.map(({ description, kind, asOfDate, isForbearance, pastDueAmount }, i) => ({
    description,
    kind,
    asOfDate: formatMonthDayYear(asOfDate),
    forbearance: isForbearance ? 'Yes' : 'No',
    pastDueAmount: toNullishNoneString(currency(pastDueAmount)),
    id: `${kind}-${i}`,
  }));

  const allColumns = [
    { name: 'Description', key: 'description', selector: row => row.description },
    { name: 'Kind', key: 'kind', selector: row => row.kind },
    { name: 'Forbearance?', key: 'forbearance', selector: row => row.forbearance },
    { name: 'Past Due Amount', key: 'pastDueAmount', selector: row => row.pastDueAmount },
    { name: 'As of Date', key: 'asOfDate', selector: row => row.asOfDate },
  ];

  const primaryColumns = allColumns.slice(0, 3);

  return (
    <div className="MinimumRequiredDocsTable">
      <DataTable columns={allColumns} data={data} primaryColumns={primaryColumns} />
    </div>
  );
};
const MinimumRequiredDocs = () => {
  const { data = {}, loading } = useSpecificContentQuery(GET_MINIMUM_REQUIRED_DOCS_DATA);
  const { track } = data;
  const { liens = [], home = {} } = track || {};
  const hasLiens = !!liens.length;
  return (
    <div className="TaskDetailContent">
      <TaskDetailContentSectionWrapper heading="Mortgage/Lien Statement">
        <ul className="TaskDetailList">
          <li>Valid mortgage/lien statement(s) on all disclosed liens</li>
          <li>
            Statement is dated within the past month, all pages are present, and at least one applicant is listed on the
            statement
          </li>
          <li>If past due payments exist, they do not total more than one month</li>
          <li>
            If there is indication of an active forbearance or a deferred balance present, create a new task to confirm
            collected and validated
          </li>
        </ul>
      </TaskDetailContentSectionWrapper>

      <TaskDetailContentSectionWrapper heading="Property Lien Information">
        <LoadingPlaceholder isLoading={loading}>
          {({ loadingClass }) => (
            <div className={loadingClass}>
              {hasLiens ? <LienTable liens={liens} /> : <div>There is no property lien information at this time.</div>}
            </div>
          )}
        </LoadingPlaceholder>
      </TaskDetailContentSectionWrapper>

      <TaskDetailContentSectionWrapper heading="Property">
        <LoadingPlaceholder isLoading={loading}>
          {({ loadingClass }) => (
            <p>
              <strong>Property Type: </strong>
              <span className={loadingClass}>{home.propertyTypeLabel}</span>
            </p>
          )}
        </LoadingPlaceholder>
      </TaskDetailContentSectionWrapper>
    </div>
  );
};

export default MinimumRequiredDocs;
