import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Modal, Button, useAsync, Loader, Checkbox, MuiTextInput } from '@hometap/htco-components';
import { mergeMultipleDocuments, toggleDocumentPin } from '../../documentRequests';

import './MergeDocumentsModal.scss';
import { VisibilityRadioRow } from '../VisibilityRadioRow/VisibilityRadioRow';
import { PinButton } from '../PinButton/PinButton';
import { getMergeErrorTypeAndText } from '../../DocumentsUtils';
import { showMergeDocumentsToastByType } from '../../DocumentToasts';

import { DOCUMENTS_TYPE, MERGE_DOCUMENTS_TOAST } from '../../data/constants';
import { UploadNewOrEditDocumentFields } from '../UploadNewOrEditDocumentFields/UploadNewOrEditDocumentFields';

const MergeDocumentsModal = ({
  open,
  options,
  close,
  documentKindsDropdownOptions,
  documentKindsWithPerson = [],
  getDocumentKindVisibility,
  useTrackApplicantsResults,
}) => {
  const { applicantsOptions } = useTrackApplicantsResults;

  const [name, setName] = useState();
  const [kind, setKind] = useState();
  const [pinned, setPinned] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [kindOther, setKindOther] = useState();
  const [visibility, setVisibility] = useState('internal');
  const [isDeleteSource, setIsDeleteSource] = useState(true);
  const [toastOptions, setToastOptions] = useState(null);
  const [personId, setPersonId] = useState();

  const { documentIds } = options || {};
  const {
    loading: isLoading,
    execute,
    setError,
    results,
    setResults,
  } = useAsync(mergeMultipleDocuments, {
    onSuccess: data => setToastOptions({ type: MERGE_DOCUMENTS_TOAST.Success, data }),
    onError: error => {
      const { errorText, type } = getMergeErrorTypeAndText(error);
      setToastOptions({
        type,
        error: errorText,
      });
    },
  });

  useEffect(() => {
    if (toastOptions) {
      const { type, data, error } = toastOptions;
      switch (type) {
        case MERGE_DOCUMENTS_TOAST.Success:
          if (data) {
            showMergeDocumentsToastByType(
              data.filename,
              MERGE_DOCUMENTS_TOAST.Success,
              data.additional_data?.source_document_ids?.length,
            );
          }
          break;
        case MERGE_DOCUMENTS_TOAST.Error:
        case MERGE_DOCUMENTS_TOAST.NetworkError:
          showMergeDocumentsToastByType(`${name}.pdf`, type, 0, error);
          break;
        default:
          showMergeDocumentsToastByType(`${name}.pdf`, MERGE_DOCUMENTS_TOAST.Error);
          break;
      }
      setToastOptions(null);
    }
  }, [toastOptions, name]);

  // default to applicant when there's only one and gov_id
  useEffect(() => {
    if (
      applicantsOptions &&
      applicantsOptions.length === 1 &&
      personId === undefined &&
      documentKindsWithPerson.includes(kind)
    )
      setPersonId(applicantsOptions[0].value);
  }, [applicantsOptions, personId, kind, documentKindsWithPerson]);

  const isOtherDocumentType = kind === 'other';

  const { visibilityLabel, isVisibilityEditable } = getDocumentKindVisibility(kind);

  const handleDropdownSelectorChange = kind => {
    if (kindOther && kind !== DOCUMENTS_TYPE.Other) {
      setKindOther();
    }
    if (personId && !documentKindsWithPerson.includes(kind)) {
      setPersonId();
    }
    setKind(kind);
  };

  const disableButton = () => {
    if (!name || !kind) {
      return true;
    }
    if (kind === DOCUMENTS_TYPE.Other) {
      return !kindOther;
    }
    if (documentKindsWithPerson.includes(kind)) {
      return !personId;
    }
    return false;
  };

  const handleButtonClick = async () => {
    setSubmitted(false);
    const dataToMerge = {
      name,
      document_ids: documentIds,
      internal: visibility === 'internal',
      delete_source_documents: isDeleteSource,
    };
    if (isOtherDocumentType) {
      dataToMerge['kind_other'] = kindOther;
    } else {
      dataToMerge['kind'] = kind;
    }
    if (personId) {
      dataToMerge['person_id'] = personId;
    }

    let mergedDocumentInfo;
    try {
      mergedDocumentInfo = await execute(dataToMerge);
    } catch (error) {
      setError(error);
    }

    if (pinned && mergedDocumentInfo?.id) {
      await toggleDocumentPin(mergedDocumentInfo.id, pinned);
    }
    setSubmitted(true);
  };

  useEffect(() => {
    if (results && submitted) {
      setResults(null);
      setSubmitted(false);
      close(true);
    }
  }, [close, results, setResults, submitted]);

  const handleClose = () => {
    if (!isLoading) {
      close();
    }
  };

  const additionalFieldsProps = useMemo(() => {
    const additionalFields = {
      [DOCUMENTS_TYPE.Other]: {
        name: 'other',
        value: kindOther,
        onChange: v => setKindOther(v),
      },
    };
    if (documentKindsWithPerson.includes(kind)) {
      additionalFields[kind] = {
        name: 'person_id',
        value: personId,
        onChange: setPersonId,
      };
    }
    return additionalFields;
  }, [personId, kind, documentKindsWithPerson, kindOther]);

  return (
    <Modal open={open} onClose={handleClose} className={cx('MergeDocumentsModal', { isLoading })} width={1000}>
      {isLoading && <Loader typle="spinner" size="large" overlay />}
      <h2 className="MergeDocumentsModalTitle">Merge Documents</h2>
      <MuiTextInput
        value={name}
        onChange={setName}
        label="Document Name"
        required
        autoComplete="off"
        theme="outlined"
        width="100%"
        className="MergeDocumentsModalField"
      />
      <UploadNewOrEditDocumentFields
        docType={kind}
        documentKindsDropdownOptions={documentKindsDropdownOptions}
        applicantsOptions={applicantsOptions}
        additionalFieldsByDocumentTypeProps={additionalFieldsProps}
        documentKindsWithPerson={documentKindsWithPerson}
        documentTypeFieldProps={{
          name: 'kind',
          value: kind,
          onChange: handleDropdownSelectorChange,
        }}
        additionalFieldClassName="MergeDocumentsModalField"
        className="MergeDocumentsModalField"
      />
      <div className="MergeDocumentsModalColumnContainer">
        <label>Pin Document</label>
        <PinButton pinned={pinned} onClick={() => setPinned(!pinned)} size="2x" />
      </div>
      <div className="MergeDocumentsModalColumnContainer">
        <label>Document Visibility</label>
        <VisibilityRadioRow
          setVisibility={setVisibility}
          isUpdateVisibilityValue={false}
          extraClassName="MergeDocumentsModalVisibility"
          defaultVisibilityLabel={visibilityLabel}
          isDefaultVisibilityEditable={isVisibilityEditable}
          docType={kind}
        />
      </div>
      <Checkbox
        label="Delete/Archive source documents"
        value={isDeleteSource ? 'checked' : 'unchecked'}
        checked={isDeleteSource}
        name="deleteArchiveSource"
        onChange={setIsDeleteSource}
      />
      <div className="MergeDocumentsModalButtons">
        <Button theme="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={disableButton()} onClick={handleButtonClick}>
          Create
        </Button>
      </div>
    </Modal>
  );
};
export default MergeDocumentsModal;
