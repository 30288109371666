import image from 'assets/images/maintenance_mode.webp';
import './MaintenanceModeScreenOverlay.scss';

const MaintenanceModeScreenOverlay = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="MaintenanceModeScreenOverlayContainer">
      <div className="MaintenanceModeScreenOverlayContentContainer">
        {/* image */}
        <img className="MaintenanceModeScreenOverlayContainerImage" src={image} alt="Maintenance Mode graphic" />
        {/* text */}
        <div className="MaintenanceModeScreenOverlayBody">
          <h1 className="MaintenanceModeScreenOverlayContentHeader">Website under maintenance.</h1>
          <p className="MaintenanceModeScreenOverlayContentDescription">
            We apologize for the inconvenience while HUB undergoes planned maintenance. We’ll refresh this page
            automatically when finished.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceModeScreenOverlay;
