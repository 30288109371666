import { forwardRef } from 'react';
import cx from 'classnames';

import { SlideSide } from '@hometap/htco-components';

/**
 * SliderContainer component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isVisible - Determines if the slider container is visible.
 * @param {string} [props.className] - Additional classes to add to the component.
 * @param {ReactNode} props.children - The content of the slider container.
 * @param {React.MutableRefObject} [props.ref] - The ref object.
 * @returns {JSX.Element} The rendered SliderContainer component.
 */
const SliderFormContainer = forwardRef(({ isVisible, className, children }, ref) => {
  return (
    <SlideSide
      className={cx('flex flex-col flex-nowrap justify-between', className)}
      maxWidth={596}
      visible={isVisible}
      isOverlay
      displayBackdrop
      direction="right"
    >
      <div ref={ref} className="flex h-full flex-col justify-center">
        {children}
      </div>
    </SlideSide>
  );
});

export default SliderFormContainer;
