import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { startCase, isEmpty } from 'lodash';

import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';

import './InsurancePoliciesController.scss';
import InsurancePolicyDetails from './components/InsurancePolicyDetails/InsurancePolicyDetails';
import useConfigurations from 'hooks/useConfigurations';
import { useFormsForAppReviewSection } from '../../hooks/useSpecificContentQuery';
import { getIsOpenSomeTask, POLICY_DOC_KINDS } from 'apps/track-details/utils/insuranceTasks';
import { getVariableValue } from 'apps/track-details/tasks/components/TaskList/TaskListUtils';
import { useScrollToElement } from 'hooks/useScrollToElement';

const INSURANCE_POLICIES_ANCHOR_ID = 'insurance-policies-section';

export const InsurancePoliciesController = ({ track, editDisabled, refetch }) => {
  const { trackId } = useParams();
  const { taskKinds, documentKinds } = useConfigurations();
  const { setShouldScroll } = useScrollToElement(INSURANCE_POLICIES_ANCHOR_ID);
  const { insurancePolicyReviews, home, homeownerTasks, internalTasks } = track;

  const [nodesWithoutDeleted, setNodesWithoutDeleted] = useState(insurancePolicyReviews);

  const INSURANCE_POLICIES_REVIEW_TASK_KINDS = useMemo(
    () => [
      taskKinds.HOMEOWNER_INSURANCE_REVIEW_V1,
      taskKinds.FLOOD_INSURANCE_REVIEW_V1,
      taskKinds.CONDO_MASTER_INSURANCE_REVIEW_V1,
      taskKinds.OTHER_INSURANCE_REVIEW_V1,
    ],
    [taskKinds],
  );
  const { isValid, isSaving, isChanged, confirmSection, cancelSectionChanges } = useFormsForAppReviewSection({
    // TODO Revisit section alerts for Insurance https://hometap.atlassian.net/browse/EH-543
    sectionAlertDefinitionsInPriorityOrder: [],
    afterPublishSection: () => {
      setPrevPolicyId(undefined);
    },
  });

  const { residenceType, propertyType } = home || {};
  const isApplicationFloridaState = home?.address?.state === 'FL';

  const insurancePolicyPickList = useMemo(() => {
    const insurancePolicyReviewsList = nodesWithoutDeleted
      ? nodesWithoutDeleted
          .map((policy, i) => {
            const { kind } = policy;
            const policyDocKind = POLICY_DOC_KINDS[kind];
            const isPending =
              getIsOpenSomeTask(homeownerTasks, policyDocKind) || getIsOpenSomeTask(internalTasks, policyDocKind);

            return {
              ...policy,
              ...{
                subSectionLabel: `${startCase(policy.description)}`,
                selectorId: i,
                isPending,
                isDisabled: false,
              },
            };
          })
          .sort((a, b) => a.isPending - b.isPending)
      : [];
    const pendingInsurancePolicyTasks = internalTasks
      ? internalTasks.reduce((accum, internalTask) => {
          if (internalTask.isOpen && INSURANCE_POLICIES_REVIEW_TASK_KINDS.includes(internalTask.kind)) {
            const taskDocumentKind = getVariableValue(internalTask, 'doc_kind');
            // return early if we already have an insurance policy in the list to avoid duplicates
            if (
              insurancePolicyReviewsList.find(
                insurancePolicy => POLICY_DOC_KINDS[insurancePolicy.kind] === taskDocumentKind,
              )
            )
              return accum;

            const displayName =
              documentKinds.find(documentKind => documentKind.value === taskDocumentKind)?.displayName ??
              internalTask.kindLabel;
            return [
              ...accum,
              {
                subSectionLabel: `${startCase(displayName)}`,
                selectorId: internalTask.identifier,
                isPending: true,
                isDisabled: true,
              },
            ];
          }
          return accum;
        }, [])
      : [];
    return [...insurancePolicyReviewsList, ...pendingInsurancePolicyTasks];
  }, [internalTasks, nodesWithoutDeleted, INSURANCE_POLICIES_REVIEW_TASK_KINDS, documentKinds, homeownerTasks]);

  const onlyHasDisabledPolicies = insurancePolicyPickList.every(insurancePolicy => insurancePolicy.isDisabled);

  // TODO https://hometap.atlassian.net/browse/EH-569 Consolidate App Review Section Tab related code into a single hook with settings
  const [currentSection, setCurrentSection] = useState(
    onlyHasDisabledPolicies ? undefined : insurancePolicyPickList[0],
  ); // i.e. tab
  const [prevPolicyId, setPrevPolicyId] = useState();
  const selectedId = currentSection && currentSection.selectorId;
  const { historyInsurancePolicyUrl } = getApplicationReviewPageUrls(trackId);

  const changeCurrentSection = function togglePicker(selectorId) {
    // i.e. change current tab/item
    if (selectorId !== currentSection.selectorId) {
      const selectedSection = insurancePolicyPickList.find(policy => policy.selectorId === selectorId);
      setCurrentSection(selectedSection);
      setPrevPolicyId(currentSection.identifier);
      setShouldScroll(true);
    }
  };

  const onDeletePolicy = id => {
    const filteredNodes = nodesWithoutDeleted.filter(({ identifier }) => identifier !== id);
    const section = filteredNodes.filter(node => !node.isDisabled)[0] || undefined;

    setNodesWithoutDeleted(filteredNodes);
    setCurrentSection(section);
    setShouldScroll(true);
    refetch();
  };

  return (
    <ApplicationReviewSection
      anchorId={INSURANCE_POLICIES_ANCHOR_ID}
      sectionTitle="Insurance policies"
      historyUrl={historyInsurancePolicyUrl}
      pickList={insurancePolicyPickList}
      selectedId={selectedId}
      onSelect={changeCurrentSection}
      onEdit={null}
      isWithActionBar={!onlyHasDisabledPolicies}
      actionBarProps={{
        isPrimaryButtonEnabled: isValid && isChanged && !isSaving,
        isSecondaryButtonEnabled: isChanged && !isSaving,
        isShowPrompt: isChanged,
        isSaving,
        onPrimaryButtonClick: async () => {
          await confirmSection();
          refetch();
        },
        onSecondaryButtonClick: () => cancelSectionChanges(currentSection.identifier),
        isValid,
      }}
    >
      {isEmpty(currentSection) ? (
        <div>There is no insurance policy data</div>
      ) : (
        <InsurancePolicyDetails
          disabled={editDisabled}
          initialResidenceType={residenceType}
          initialPropertyType={propertyType}
          policy={currentSection}
          isApplicationFloridaState={isApplicationFloridaState}
          prevPolicyId={prevPolicyId}
          onDelete={onDeletePolicy}
        />
      )}
    </ApplicationReviewSection>
  );
};
