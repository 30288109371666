import { gql } from '@apollo/client';
import apiWithAuth from 'utils/api';

export const getDocumentSetDocuments = async (docSetId, trackId) => {
  return await apiWithAuth.v2.get(`/tracks/${trackId}/documents/`, {
    method: 'get',
    params: {
      document_set_template_id: docSetId,
    },
  });
};

export const GET_DOCUMENT_SET_LIST_QUERY = gql`
  query GetDocumentSetList {
    documentSetTemplates {
      name
      id
      description
      documentKinds
      includedDocumentVersions
      outputFormat
      outputFilePrefix
    }
  }
`;

export const GET_DOCUMENT_SET_REQUEST_LOG_QUERY = gql`
  query document_set_requests($trackId: String!) {
    documentSetRequests(trackId: $trackId) {
      startTime
      rootProcessInstanceId
      state
      documentSetName
      requestedBy
      trackId
      failed
      failedReason
      documentSetId
    }
  }
`;

export const REQUEST_DOCUMENT_SET_QUERY = gql`
  mutation GenerateDocumentSet(
    $trackId: IdentifierType!
    $documentSetTemplateId: IdentifierType!
    $documentIds: [IdentifierType!]!
    $documentSetName: String!
    $outputFormat: DocumentSetOutputFormatEnum!
    $includedDocumentVersions: DocumentSetIncludedDocumentVersionsEnum!
    $notifyAfterCompletion: Boolean!
  ) {
    generateDocumentSet(
      trackId: $trackId
      documentSetTemplateId: $documentSetTemplateId
      documentIds: $documentIds
      documentSetName: $documentSetName
      outputFormat: $outputFormat
      includedDocumentVersions: $includedDocumentVersions
      notifyAfterCompletion: $notifyAfterCompletion
    ) {
      bpmProcessId
    }
  }
`;

export const GET_ROLLOUT_FLAGS = gql`
  query GetRolloutFlags($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      rolloutFlags
    }
  }
`;
