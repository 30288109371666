import { gql } from '@apollo/client';

export const GET_TRACK_DATA = gql`
  query Track($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      friendlyId
      applicationIsApprovedOrClosedLost
      canExpire
      currentLienBalanceTotal
      currentPastDueLienBalanceTotal
      expirationDate
      finalIncomeWeightedRiskBand
      finalIncomeWeightedRiskScore
      floodZone
      fullAddress
      fullNameShort
      feePaydown
      hasActiveSettlement
      hasExpired
      htLienPosition
      inInvested
      maxInvestmentAmount
      ownerEndingEquityPercent
      phoneNumber
      principalPaydown
      reviewStatusLastUpdatedDays
      totalEndingEquityPercent
      totalPaydown
      uwRuleVersion
      rolloutFlags
      dateOfFinalApproval
      application {
        id
        submitted
        hasHoa
        hoaContactName
        hoaContactPhone
        hoaStreet
        hoaUnit
        hoaCity
        hoaState
        hoaZipCode
      }
      applicationReviewPermissions {
        canRead
        canWrite
      }
      appraisalType {
        label
        value
      }
      compositeFicoData {
        value
        status
        statusDisplay
        errorMessage
      }
      incomeWeightedRiskBand {
        label
        value
      }
      estimatedIncomeWeightedRiskData {
        score
        band
        errorMessage
      }
      home {
        identifier
        residenceType
        propertyType
        houseCanaryAddress {
          identifier
          fullAddress
        }
        address {
          identifier
          fullAddress
          state
        }
      }
      currentStage {
        label
        stageClassName
      }
      reviewStatus {
        label
        value
      }
      regionDefaults {
        spouseSignatureRequiredInfo
      }
      activeInvestment {
        postCloseStatus
      }
      beginningHomeValuation {
        identifier
        value
      }
      coordinator {
        fullNameShort
        email
      }
      currentIncomeWeightedRiskData {
        score
        band
        errorMessage
      }
      home {
        identifier
        propertyTypeLabel
        residenceTypeLabel
      }
      insuranceKindOptions {
        label
        value
      }
      insurancePolicyReviews {
        identifier
        agent
        agentContact
        agentContactTypeOptions {
          label
          value
        }
        agentEmail
        agentPhone
        description
        dwellingAmount
        kind
        notes
        orderIndex
        policyNumber
        annualCost
        provider
        renewalDate
        replacementCost
        document {
          ... on Document {
            identifier
            isPinned
            filename
            id
            kind {
              label
              value
            }
          }
          ... on DocumentV1 {
            identifier
            isPinned
            filename
            id
            kind {
              label
              value
            }
          }
        }
      }
      mailingAddress {
        fullAddress
      }
      relevantHomeValuation {
        identifier
        messages
        value
        valueLower
        valueUpper
        type
        expirationDate
        daysUntilExpires
        avmEligibility
        avmEligibilityDetail
      }
      reviewer {
        fullNameShort
        email
      }
      underwriter {
        fullNameShort
        email
      }
      applicants {
        identifier
        fullName
        totalAnnualIncome
        ssn
        maritalStatus
        isPrimaryApplicant
        ficoScore
        email
        phoneNumber
        relationshipToPrimary
        birthDate
        membership {
          identifier
          status
          email
          passwordInitializationAttemptsRemaining
          emailConfirmed
          verified
          verificationAttemptsRemaining
          userInitialized
        }
        backgroundCheckReview {
          status
          condition
          notes
          statusOptions {
            label
            value
          }
          conditionOptions {
            label
            value
          }
        }
        governmentId {
          nameAsAppearsOnId
          expirationDate
          notes
        }
        riskBand {
          identifier
          errorMessage
          expirationDate
          isFake
          isNoDataResponse
          isRiskScorePassing
          value
          wasExperianNameExactMatch
        }
        titleMemberReview {
          identifier
          isOnTitle
          vestingName
        }
        governmentId {
          identifier
          nameAsAppearsOnId
          expirationDate
          notes
        }
        person {
          identifier
        }
      }
      floodReportReview {
        inFloodZone
        status
        statusOptions {
          label
          value
        }
      }
      rentalAgreementReview {
        identifier
        totalAnnualRentalAmount
        agreementExpirationDate
      }
      hoaReview {
        identifier
        hoaName
        totalAnnualHoaFee
      }
      propertyTaxReview {
        totalAnnualTaxAmount
      }
      opportunityRecords {
        identifier
        url
        name
        isActive
      }
      propertyReportReview {
        apn
        effectiveDate
        fullAddress
        grantee
        isTrust
        legalDescription
        notes
        status
        titlePartner
        titlePartnerFileNumber
        statusOptions {
          value
          label
        }
        titlePartnerOptions {
          value
          label
        }
      }
      homeValuationReviewKindOptions {
        label
        value
      }
      homeValuationReviewProviderOptions {
        label
        value
      }
      homeValuationReviewStatusOptions {
        label
        value
      }
      homeValuationReviewKindNumberOptions {
        label
        value
      }
      homeValuationReviews {
        kind
        kindOther
        kindNumber
        vendorCompleted
        homeownerCompleted
        scheduledDate
        orderIndex
        notes
        provider {
          label
          value
        }
        providerOther
        status {
          label
          value
        }
      }
      investmentIncreaseIdentifierOptions {
        label
        value
      }
      investmentIncrease {
        identifier
        isInvestmentIncrease
        investmentIncreaseIdentifiers
        activeInvestmentMatches
        notes
      }
      liens {
        identifier
        holder
        lender
        notes
        maxLineAmount
        currentBalance
        principalAmount
        interestAmount
        escrowAmount
        pastDueAmount
        pastDueFees
        asOfDate
        isForbearance
        isFixture
        onPropertyReport
        kind
        mortgageInvestorKind
        position
        originalAmount
        rate
        lienDate
        endOfTermDate
        rateType
        mortgageAccountNumber
        isMoreThanOneMonthPastDue
        wasProfessionallyServiced
        endOfDrawPeriodDate
        hasAModification
        createdAt
        document {
          ... on Document {
            identifier
            isPinned
            filename
            id
            kind {
              label
              value
            }
          }
        }
        paydown {
          identifier
          principalPaydownAmount
          feePaydownAmount
          goodThroughDate
          paymentMethod {
            value
          }
          accountNumber
          routingNumber
          mailingInstructions
          isPayoff
          totalPaydownAmount
          perDiem
        }
      }
      liensKindOptions {
        label
        value
      }
      potentiallyRelatedInvestments {
        identifier
        friendlyId
        apn
        hometapLien
        home {
          identifier
          houseCanaryAddress {
            identifier
            fullAddress
          }
          address {
            identifier
            fullAddress
          }
        }
        applicants {
          fullName
          ssn
          titleMemberReview {
            isOnTitle
          }
        }
      }
      additionalTracks {
        identifier
        fullAddress
        friendlyId
        currentStage {
          label
        }
      }
      internalTasks {
        id
        identifier
        isOpen
        kind
        kindLabel
        variables {
          name
          value
        }
      }
      homeownerTasks {
        name
        kind
        taskStatus
        createdAt
        identifier
        isOpen
        id

        variables {
          name
          value
        }
      }
      internalTasks {
        identifier
        isOpen
        id

        variables {
          name
          value
        }
      }
      uwDscr
      debtServiceCoverageRatioInfo {
        dscrValue
        dscrValueStatus
        monthlyRentalIncome
        relevantLiens {
          identifier
          kindLabel
          principalAmount
          interestAmount
        }
        monthlyFloodInsuranceCost
        monthlyFloodInsuranceCostStatus
        monthlyHomeownersInsuranceCost
        monthlyPropertyTaxCost
        monthlyHoaFee
        monthlyHoaFeeStatus
      }
    }
  }
`;
