export const APP_UNLOCK_CHANGES_TOOLBAR_TARGET_ID = 'application-unlock-changes-toolbar-target-id';

/**
 * @typedef ApplicationUnlockStatusData
 * @type {object}
 * @property {string} iconName
 * @property {string} displanName
 * @property {string} tooltipStatusTitle
 * @property {boolean} doShowChanges
 */

/** @type {Record<string, ApplicationUnlockStatusData>} */
export const STATUS_DATA = {
  LOCKED: {
    iconName: 'icon-lock',
    displayName: 'Locked',
    tooltipStatusTitle: 'Review Changes',
    doShowChanges: true,
  },
  UNLOCKED: {
    iconName: 'icon-unlocked',
    displayName: 'Unlocked',
    tooltipStatusTitle: 'Application Unlocked',
    doShowChanges: false,
  },
};
