import React, { useMemo } from 'react';
import { CurrencyInput, DatePicker, useForm } from '@hometap/htco-components';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { isEqual } from 'lodash';
import numeral from 'numeral';
import { useMutation } from '@apollo/client';
import { DELETE_RENTAL_AGREEMENT, UPSERT_RENTAL_AGREEMENT } from 'apps/track-details/tasks/data/mutations';
import { PERIODICITY } from 'data/constants/periodicity';
import useConfigurations from 'hooks/useConfigurations';
import { CONFIGURATIONS_RESIDENCE_TYPE } from 'data/constants/configurations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { getExpirationDateData } from 'utils/dates';
import { getNumberValue } from 'utils/validators';
import { currencyWithCents } from 'utils/numbers';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { ApplicationReviewAlert } from 'apps/track-details/ApplicationReview/components/Alert/ApplicationReviewAlert';
import { SUMMARY_DIVIDER } from 'apps/track-details/ApplicationReview/components/Summary/Summary';
import { convertNumericStringsToNumbers } from 'apps/track-details/utils/taskForms';
import './RentalAgreementController.scss';
import { SECTION_ALERT_TYPES, createSelectionLevelAlert } from '../../hooks/useSpecificContentQuery';

export const RentalAgreementController = ({ track, canDelete, editDisabled, refetch }) => {
  const [upsertRentalAgreement, { loading }] = useMutation(UPSERT_RENTAL_AGREEMENT);
  const [deleteRentalAgreementMutation] = useMutation(DELETE_RENTAL_AGREEMENT);
  const readOnly = loading || editDisabled;
  const { residenceTypes } = useConfigurations();
  const { rentalAgreementReview } = track;
  const homeIsRental = track?.home?.residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental;
  const initialFormData = useMemo(() => getInitialFormData(rentalAgreementReview), [rentalAgreementReview]);
  const { registerField, isFormValid, updateFormData, formData } = useForm(initialFormData);
  const { agreementExpirationDate } = formData;
  const isFormChanged = !isEqual(
    convertNumericStringsToNumbers(initialFormData),
    convertNumericStringsToNumbers(formData),
  );
  const expirationDateData = !!agreementExpirationDate && getExpirationDateData(agreementExpirationDate);
  const { isExpiring } = expirationDateData;
  const { identifier: trackId } = track;
  const { historyRentalAgreementUrl } = getApplicationReviewPageUrls(trackId);

  function getSectionAlert() {
    // Add an error instructing user to delete Rental Agreement data if residence type is currently
    // not rental and there is rental agreement data (and ARP is in an editable state)
    if (!homeIsRental && rentalAgreementReview && !readOnly) {
      const residenceTypeLabelLower =
        (residenceTypes &&
          Object.keys(residenceTypes)
            .find(key => residenceTypes[key] === track?.home.residenceType)
            ?.toLowerCase()) ||
        'unknown';

      return createSelectionLevelAlert({
        type: SECTION_ALERT_TYPES.ERROR,
        message: `Residency type changed to ${residenceTypeLabelLower}. Please delete this section.`,
      });
    }
    return null;
  }

  const deleteRentalAgreement = async () => {
    try {
      await deleteRentalAgreementMutation({ variables: { rentalAgreementId: rentalAgreementReview.identifier } });
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'Rental agreement successfully deleted.',
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to delete rental agreement',
        description: error.networkError?.result?.errors[0]?.message
          ? error.networkError.result.errors[0].message
          : 'Could not delete rental agreement. Please try again.',
      });
    } finally {
      refetch();
    }
  };

  return (
    <ApplicationReviewSection
      anchorId="rental-agreement-section"
      sectionTitle="Rental agreement"
      historyUrl={historyRentalAgreementUrl}
      summaryParams={[
        {
          label: <b>Rental amount</b>,
          value: <b>{currencyWithCents(formData.rentalAmount)}</b>,
        },
        SUMMARY_DIVIDER,
      ]}
      sectionAlert={getSectionAlert()}
      visibleSummary={true}
      isWithActionBar={true}
      isSectionEverDeletable={true}
      deletionModalProps={{
        title: 'Rental form',
        header: 'Delete rental agreement data',
        type: 'Rental agreement data',
        canDelete: !readOnly && canDelete,
        onDelete: deleteRentalAgreement,
        canShowModal: true,
      }}
      actionBarProps={{
        isPrimaryButtonEnabled: isFormChanged && isFormValid && !loading,
        isSecondaryButtonEnabled: isFormChanged && !loading,
        isShowPrompt: isFormChanged,
        primaryButtonName: loading ? 'Saving...' : 'Publish',
        onPrimaryButtonClick: async () => {
          const { identifier: trackId } = track;
          const { rentalAmount, agreementExpirationDate } = formData;
          const variables = {
            trackId,
            rentalAmount: getNumberValue(rentalAmount),
            rentalAmountFrequency: PERIODICITY.ANNUALLY,
            agreementExpirationDate,
          };
          if (!agreementExpirationDate) variables.agreementExpirationDate = null;

          try {
            await upsertRentalAgreement({ variables });
            showNotification({
              type: TOAST_TYPE.success,
              title: 'Success',
              description: `Changes to "Rental Agreement" successfully saved.`,
            });
          } catch (error) {
            showNotification({
              type: TOAST_TYPE.error,
              title: 'Failed to save changes',
              description: error.networkError?.result?.errors[0]?.message
                ? error.networkError.result.errors[0].message
                : 'Could not save changes. Please try again.',
            });
          }
          refetch();
        },
        onSecondaryButtonClick: () => {
          updateFormData(initialFormData);
        },
      }}
      onEdit={null}
    >
      <CurrencyInput
        label="Annual rental income"
        padFractionalZeros={true}
        disabled={readOnly}
        required
        {...registerField('rentalAmount')}
      />
      <DatePicker
        label="Rental agreement expiration date (optional)"
        theme="outlined"
        disabled={readOnly}
        className="FullWidthDatePicker agreementExpirationDatePicker"
        wrapperClassName="FullWidthDatePicker"
        {...registerField('agreementExpirationDate')}
      />
      {isExpiring && (
        <ApplicationReviewAlert>
          Expiration is within 30 days. Please create a to-do requesting a more recent rental agreement.
        </ApplicationReviewAlert>
      )}
    </ApplicationReviewSection>
  );
};

export default RentalAgreementController;

const getInitialFormData = rentalAgreementReview => {
  const AMOUNT_FORMAT = '00.00';
  if (rentalAgreementReview) {
    const { totalAnnualRentalAmount, agreementExpirationDate } = rentalAgreementReview;
    const initialFormData = {
      rentalAmount: numeral(totalAnnualRentalAmount).format(AMOUNT_FORMAT),
      agreementExpirationDate: agreementExpirationDate ? agreementExpirationDate : '',
    };

    return initialFormData;
  }
  // Using empty string instead of null/undefined to prevent wrong isFormChanged true
  return { rentalAmount: '', agreementExpirationDate: '' };
};
