import { useEffect, useMemo, useState, useCallback } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import './PanAndZoomImage.scss';

const PanAndZoomImage = ({ downloadedBlobURL, filename, imageRotation }) => {
  const zoomFactor = 8;
  const imageSideSpacing = 100;

  const [container, setContainer] = useState(null);

  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const [imageNaturalDimensions, setImageNaturalDimensions] = useState({ width: 0, height: 0 });

  const imageScale = useMemo(() => {
    if (
      containerDimensions.width === 0 ||
      containerDimensions.height === 0 ||
      imageNaturalDimensions.width === 0 ||
      imageNaturalDimensions.height === 0
    )
      return 0;
    return Math.min(
      containerDimensions.width / imageNaturalDimensions.width,
      containerDimensions.height / imageNaturalDimensions.height,
    );
  }, [containerDimensions, imageNaturalDimensions]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerDimensions({ width: rect.width, height: rect.height });
    } else {
      setContainerDimensions({ width: 0, height: 0 });
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      // if image is rotated 90 or 270 degrees, then swap width and height for natural dimensions
      if (imageRotation % 180 === 90) {
        setImageNaturalDimensions({
          width: image.naturalHeight + imageSideSpacing,
          height: image.naturalWidth + imageSideSpacing,
        });
      } else {
        setImageNaturalDimensions({
          width: image.naturalWidth + imageSideSpacing,
          height: image.naturalHeight + imageSideSpacing,
        });
      }
    };
    image.src = downloadedBlobURL;
  }, [downloadedBlobURL, imageRotation]);

  return (
    <div className="PanAndZoomImageContainer" ref={e => setContainer(e)}>
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerDimensions.width}x${containerDimensions.height}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * zoomFactor}
          centerOnInit
          smooth={true}
          centerZoomedOut={true}
        >
          <TransformComponent wrapperClass="PanAndZoomImageTransformComponentWrapper">
            <img
              alt={filename}
              src={downloadedBlobURL}
              style={{
                transform: `rotate(${imageRotation}deg)`,
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      )}
    </div>
  );
};

export default PanAndZoomImage;
