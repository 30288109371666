import { gql } from '@apollo/client';

export const GET_TRACKS_WITH_FUNDING_DATA = gql`
  query TrackFunds(
    $nextPageLimit: Int
    $prevPageLimit: Int
    $nextPageCursor: String
    $prevPageCursor: String
    $keyword: String
  ) {
    tracksInCorrectStages: tracks(
      currentStage: [
        "ApplicationApproved"
        "InitialOfferPrepared"
        "OfferReady"
        "ConditionsInReview"
        "ClosingInProgress"
      ]
      orderBy: "signing_datetime"
      first: $nextPageLimit
      after: $nextPageCursor
      last: $prevPageLimit
      before: $prevPageCursor
      keyword: $keyword
    ) {
      totalCount

      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          identifier
          friendlyId
          dateOfFinalApproval
          signingDatetime
          signingStatus
          hasAcceptedOffer
          acceptedOfferAmount
          currentStage {
            label
          }
          fund
          fundData {
            identifier
            suitableFunds
            rejectedFunds
            selectedFund
            rejectedFundDetails {
              fund
              reasons
            }
          }
          maxInvestmentAmount
        }
      }
    }
    tracksWithUWApproved: tracks(
      reviewStatus: ["uw_approved"]
      currentStage: ["ApplicationInReview"]
      orderBy: "signing_datetime"
      first: $nextPageLimit
      after: $nextPageCursor
      last: $prevPageLimit
      before: $prevPageCursor
      keyword: $keyword
    ) {
      totalCount

      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          identifier
          friendlyId
          dateOfFinalApproval
          signingDatetime
          signingStatus
          hasAcceptedOffer
          acceptedOfferAmount
          currentStage {
            label
          }
          fund
          fundData {
            identifier
            suitableFunds
            rejectedFunds
            selectedFund
            rejectedFundDetails {
              fund
              reasons
            }
          }
          maxInvestmentAmount
        }
      }
    }
  }
`;

export const GET_TRACK_FUND_DATA = gql`
  query TrackFundData($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      fundData {
        suitableFunds
        rejectedFunds
      }
    }
  }
`;

export const GET_FUND_NAMES = gql`
  query Fund {
    funds {
      key
      value
    }
  }
`;

export const SAVE_FUNDS = gql`
  mutation UpdateTracksFunding($trackFundAssignments: [TrackFundInput]!) {
    updateTracksFunding(trackFundAssignments: $trackFundAssignments) {
      tracks {
        id
        fund
      }
      ok
      message
    }
  }
`;

export const FILL_ALL_FUNDS = gql`
  mutation GetAllocatedFundForTracks($friendlyIds: [IdentifierType]!) {
    getAllocatedFundForTracks(friendlyIds: $friendlyIds) {
      trackFundData {
        friendlyId
        fundData {
          selectedFund
          rejectedFunds {
            fund
            reasons
          }
          suitableFunds
        }
      }
    }
  }
`;
