export const REVIEWER_ROLLOUT_GROUPS = {
  INVESTMENT_GUIDE_CALCULATOR: 'investment_guide_calculator',
};

const isInRolloutGroup = rolloutGroup => {
  return user => user?.rolloutFlags.some(rolloutFlag => rolloutGroup === rolloutFlag);
};

export const isInInvestmentGuideCalculatorRolloutGroup = user =>
  isInRolloutGroup(REVIEWER_ROLLOUT_GROUPS.INVESTMENT_GUIDE_CALCULATOR)(user);
