/**
 * @file HeapWrapper.js
 *
 * @description Wrapper component for enabling the addition of data id tags and associated metadata.
 */

export const HeapWrapper = ({ children, dataId, dataValue }) => {
  return (
    <span className={'m-0 w-full p-0'} data-id={dataId} data-value={dataValue}>
      {children}
    </span>
  );
};
