import React from 'react';

/**
 * cleanPercentage helper function
 * @description Cleans and generates a usable percentage
 * @param {number} percentage
 * @returns {number}
 */
const cleanPercentage = percentage => {
  if (!Number.isFinite(+percentage) || percentage < 0) {
    return 0;
  }
  if (percentage > 100) {
    return 100;
  }
  return +percentage;
};

/**
 * Circle component
 * @description returns a circle that can be used to create a complete circle or progress circle given a percentage
 * @param {string} color
 * @returns {JSX.Element}
 */
const Circle = ({ color, percent }) => {
  const radius = 10;
  const circ = 2 * Math.PI * radius;
  const strokePercent = ((100 - percent) * circ) / 100;
  return (
    <circle
      r={radius}
      cx={12}
      cy={12}
      fill="transparent"
      stroke={strokePercent !== circ ? color : ''}
      strokeWidth={'2px'}
      strokeDasharray={circ}
      strokeDashoffset={percent ? strokePercent : 0}
    />
  );
};

const ProgressCircle = ({ percentage, color = '#366ced' }) => {
  const progressPercent = cleanPercentage(percentage);
  return (
    <>
      <svg width={24} height={24} style={{ transform: 'rotate(-90deg)' }}>
        <g>
          <Circle color="#F6F6F6" />
          <Circle color={color} percent={progressPercent} />
        </g>
      </svg>
    </>
  );
};

export default ProgressCircle;
