import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * Hook to calculate the main headers for the funding page
 * @param {Array[Object]} tracks: tracks from graphqlQL query. Example usage: tracks?.tracks?.edges
 * @param {Dict} fundNameDict: dict of fund names provided by useFundNames hook
 */
const useFundAllocation = (combinedTracks, fundNameDictionary) => {
  const [totalInPipeLine, setTotalInPipeLine] = useState(0);
  const [allocationByFund, setAllocationByFund] = useState({});
  /**
   * Logic to calculate the track's offer amount. this can be either max amount or active investment amount
   * @param {Object} track
   * @returns {Number}
   */
  const getTrackFundAmount = track => {
    // return 0 if max investment and active investment are null/ undefined
    if (!track.acceptedOfferAmount && !track?.maxInvestmentAmount) return 0;

    // return active investment amount if it exists
    if (track?.hasAcceptedOffer) {
      return parseFloat(track.acceptedOfferAmount);
    }

    // return max investment amount if active investment does not exist
    if (track?.maxInvestmentAmount) {
      return parseFloat(track.maxInvestmentAmount);
    }
    // if no investment amount or max investment amount, return 0
    return 0;
  };

  // calculates main headers: total in pipeline and unallocated funds, percent and all funds in pipeline including funds with $0 allocation
  useEffect(() => {
    if (!isEmpty(combinedTracks)) {
      // calculate total in pipeline, then set in state
      const totalInPipelineAmount = combinedTracks
        ?.map(track => {
          return getTrackFundAmount(track);
        })
        .reduce((acc, curr) => acc + curr, 0);

      setTotalInPipeLine(totalInPipelineAmount);

      //constructAlloocation by Fund then set in state
      const newFundObj = {
        unallocated: {
          label: 'Unallocated funds',
          fundAmount: 0,
          type: 'ALLOCATION',
          percent: ((0 / totalInPipelineAmount) * 100).toFixed(0),
        },
      };
      combinedTracks.forEach(track => {
        const fund = track.fundData.selected ? track.fundData.selected : 'unallocated';
        const newFundAmount = getTrackFundAmount(track) + (newFundObj[fund]?.fundAmount || 0);
        const type = fund === 'unallocated' ? 'ALLOCATION' : 'FUND';
        const label = fund === 'unallocated' ? 'Unallocated funds' : fundNameDictionary[fund];

        newFundObj[fund] = {
          label: label,
          fundAmount: newFundAmount,
          type: type,
          percent: Math.round((newFundAmount / totalInPipelineAmount) * 100),
        };
      });

      setAllocationByFund(newFundObj);
    }
  }, [combinedTracks, fundNameDictionary, setAllocationByFund, setTotalInPipeLine]);

  return {
    totalInPipeLine,
    allocationByFund,
  };
};

export default useFundAllocation;
