import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader, NotFoundBlock, Tooltip, useWindowSize } from '@hometap/htco-components';
import cx from 'classnames';
import { APP_UNLOCK_CHANGES_TOOLBAR_TARGET_ID } from 'apps/track-details/utils/applicationUnlockConstants';

import './ApplicationChanges.scss';
import useAppUnlockChangesQuery from './useAppUnlockChangesQuery';
import processSnapshotEventsChanges from './processSnapshotEventsChanges';
import appUnlockObservedModels from './appUnlockObservedModels';

// * * * * * * * * * Render Functions * * * * * * * * *

/** Render Field Changes
 * @param {import('./processSnapshotEventsChanges').FieldChanges[]} fieldChanges
 * @returns HTML Element
 */
const renderFieldChanges = fieldChanges => {
  if (fieldChanges.length === 0) {
    return null;
  }
  return (
    <ul className="ApplicationChangesEdited">
      <li>Changed</li>
      {fieldChanges.map(({ fieldDisplayName, isSecret, oldDisplayValue, newDisplayValue }, fieldChangeIndex) => {
        return (
          <ul key={fieldChangeIndex} className="ApplicationChangesChangedListItem">
            <li>{fieldDisplayName}</li>
            {!isSecret && (
              <ul>
                <li>Was: {oldDisplayValue}</li>
                <li>Is now: {newDisplayValue}</li>
              </ul>
            )}
          </ul>
        );
      })}
    </ul>
  );
};

/** Render Added/Removed Fields
 * @param {import('./processSnapshotEventsChanges').AddedOrRemovedField[]} addOrRemoveFields
 * @param {"Added"|"Removed"} action
 * @returns HTML ELement
 */
const renderAddedOrRemovedFields = (addOrRemoveFields, action) => {
  if (addOrRemoveFields.length === 0) {
    return null;
  }
  return (
    <ul className="ApplicationChangesAddedOrRemovedObjectField">
      <li>{action}</li>
      {addOrRemoveFields.map(({ fieldDisplayName }, index) => {
        return (
          <ul key={index}>
            <li>{fieldDisplayName}</li>
          </ul>
        );
      })}
    </ul>
  );
};

/** Render Model Added or Removed
 * @param {import('./processSnapshotEventsChanges').AddedOrRemovedModel} modelsAddedOrRemoved
 * @param {"added"|"removed"} action
 * @returns HTML Element
 */
const renderModelsAddedOrRemoved = (modelsAddedOrRemoved, action) => {
  if (modelsAddedOrRemoved.length === 0) {
    return null;
  }
  return (
    <div key={`${action}Models`} className="ApplicationChangesObjectContainer">
      {modelsAddedOrRemoved.map(({ instanceName }, index) => {
        return (
          <p key={index} className="ApplicationChangesChangedInstanceName">
            {`${instanceName} was ${action}`}
          </p>
        );
      })}
    </div>
  );
};

const renderModelsChanged = modelsChanges => {
  if (modelsChanges.length === 0) {
    return null;
  }
  return modelsChanges.map((modelChanges, modelChangesIndex) => {
    const { instanceName, fieldsAdded, fieldsRemoved, fieldsChanges } = modelChanges;
    if (fieldsAdded.length + fieldsRemoved.length + fieldsChanges.length === 0) {
      return <p className="ApplicationChangesNoNotableChanges">No notable changes made</p>;
    }
    return (
      <div key={`changed-${modelChangesIndex}`}>
        <p className="ApplicationChangesChangedInstanceName">{`${instanceName} had changes`}</p>
        {renderAddedOrRemovedFields(fieldsAdded, 'Added')}
        {renderAddedOrRemovedFields(fieldsRemoved, 'Removed')}
        {renderFieldChanges(fieldsChanges)}
      </div>
    );
  });
};

/** Render the Group By Model Changes
 * @param {import('./processSnapshotEventsChanges').GroupByModelChanges} groupByModelChange
 * @param {number} groupIndex
 * @returns HTML Element
 */
const renderGroupByModelChanges = ({ modelDisplayName, modelsAdded, modelsRemoved, modelsChanges }, groupIndex) => {
  return (
    <div key={groupIndex}>
      <h5 className="ApplicationChangesTextUppercase">{modelDisplayName}</h5>
      {renderModelsAddedOrRemoved(modelsAdded, 'added')}
      {renderModelsAddedOrRemoved(modelsRemoved, 'removed')}
      {renderModelsChanged(modelsChanges)}
    </div>
  );
};

/** Render app unlock status & changes
 * @param {import('apps/track-details/utils/applicationUnlockConstants').ApplicationUnlockStatusData} status
 * @param {import('./processSnapshotEventsChanges').ProcessedEventChanges[]} changes
 * @returns HTML Element
 */
const renderAppUnlockStatusAndChanges = (
  { tooltipStatusTitle, iconName, displayName, doShowChanges },
  changes,
  screenHeight,
) => {
  if (!changes) {
    return null;
  }

  const renderStatusAndChanges = () => {
    if (!doShowChanges) {
      return <p>This application is pending changes from the Homeowner</p>;
    } else if (changes.length === 0) {
      return <h5 className="ApplicationChangesTextUppercase">No changes made</h5>;
    }

    return (
      <>
        <h5>Application changes</h5>
        <div>
          {changes.map(({ submitDateDisplay, groupByModelChanges }, eventIndex) => {
            return (
              <div key={eventIndex} className="ApplicationChangesChangeSetContainer">
                <p>Submitted {submitDateDisplay}</p>
                {groupByModelChanges.map(renderGroupByModelChanges)}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const classFlags = {
    heightL: screenHeight >= 880,
    heightM: screenHeight < 880 && screenHeight >= 700,
    heightS: screenHeight < 700,
  };
  return (
    <div className="ApplicationChangesTooltipBody">
      <h5 className="ApplicationChangesTooltipTitle">{tooltipStatusTitle}</h5>
      <div className={cx('ApplicationChangesTooltipContent', classFlags)}>
        <p>
          This application is <Icon name={iconName} /> {displayName} to the Homeowner
        </p>
        {renderStatusAndChanges()}
      </div>
    </div>
  );
};

/**
 * @typedef ApplicationChangesParams
 * @type {object}
 * @property {string} trackId Track ID
 * @property {import('apps/track-details/utils/applicationUnlockConstants').ApplicationUnlockStatusData} status Application Unlock Status
 */

/** Render Application Unlock Changes ToolTip
 * @param {ApplicationChangesParams} params
 * @returns HTML Element
 */
const ApplicationChanges = ({ trackId, status }) => {
  const { loading, error, data } = useAppUnlockChangesQuery(trackId);
  const { screenHeight } = useWindowSize();
  const [changes, setChanges] = useState();

  useEffect(() => {
    if (!data) return;
    const { changesDuringAppUnlock } = data.track;
    const changes = processSnapshotEventsChanges(changesDuringAppUnlock, appUnlockObservedModels);
    setChanges(changes);
  }, [data]);

  return (
    <Tooltip
      target={`#${APP_UNLOCK_CHANGES_TOOLBAR_TARGET_ID}`}
      position="right"
      action="hover-in-click-out"
      width={364}
      tooltipContentClassName="ApplicationChangesTooltipCustom"
      content={
        <div>
          {loading && (
            <div className="ApplicationChangesTooltipBodyLoading">
              <Loader type="dot-pulse" />
            </div>
          )}
          {error && (
            <NotFoundBlock
              error={`${error.networkError?.statusCode || 404} error`}
              title="Error"
              className="ApplicationChangesTooltipError"
            >
              <p>We're having trouble fetching app resubmission changes.</p>
              <p>Please try refreshing.</p>
              <p>If the problem persists please contact engineering.</p>
            </NotFoundBlock>
          )}
          {renderAppUnlockStatusAndChanges(status, changes, screenHeight)}
        </div>
      }
    >
      <Icon name={status.iconName} size="2x" />
    </Tooltip>
  );
};

ApplicationChanges.propTypes = {
  trackId: PropTypes.string.isRequired,
  status: PropTypes.shape({
    iconName: PropTypes.string,
    displayName: PropTypes.string,
    tooltipStatusTitle: PropTypes.string,
    doShowChanges: PropTypes.bool,
  }).isRequired,
};

export default ApplicationChanges;
