import { Icon, Tooltip } from '@hometap/htco-components';

export const DOCUMENT_SET_BATCH_STATUSES = {
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'successful',
  FAILURE: 'failed',
};

export const GENERIC_BATCH_ERROR_MESSAGE =
  'Document Sets Batch has encountered an error please retry the operation or contact engineering support if error persists';

/**
 *
 * @param {string} option
 * @returns
 */
export const DOCUMENT_SET_BATCH_FILE_TYPE_OPTIONS = option => [
  { label: `PDF ${option === 'PDF' ? '(default)' : ''}`, value: 'PDF' },
  { label: `ZIP ${option === 'ZIP' ? '(default)' : ''}`, value: 'ZIP' },
];

export const DOCUMENT_SET_BATCH_VERSION_INCLUSION_VALUES = {
  PINNED: 'PINNED',
  PRIMARY: 'PRIMARY',
  PINNED_PRIMARY: 'PINNED_PRIMARY',
  ALL: 'ALL',
};

export const DOCUMENT_SET_BATCH_VERSION_INCLUSION_OPTIONS = option => [
  {
    label: (
      <>
        Pinned only {option === 'PINNED' && ' (default)'}
        <Tooltip content="Document set would include all versions of pinned documents for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_BATCH_VERSION_INCLUSION_VALUES.PINNED,
  },
  {
    label: (
      <>
        Primary Version Only {option === 'PRIMARY' && ' (default)'}
        <Tooltip content="Document set would include only primary versions of included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_BATCH_VERSION_INCLUSION_VALUES.PRIMARY,
  },
  {
    label: (
      <>
        Pinned, primary {option === 'PINNED_PRIMARY' && ' (default)'}
        <Tooltip content="Document set would include only the primary versions of pinned documents for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_BATCH_VERSION_INCLUSION_VALUES.PINNED_PRIMARY,
  },
  {
    label: (
      <>
        All {option === 'ALL' && ' (default)'}
        <Tooltip content="Document set would include all documents and versions for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_BATCH_VERSION_INCLUSION_VALUES.ALL,
  },
];

export const DEFAULT_DOCUMENT_SET_BATCH_FORM = {
  documentSetType: null,
  documentSetName: '',
  fileType: DOCUMENT_SET_BATCH_FILE_TYPE_OPTIONS('PDF').value,
  includeVersion: DOCUMENT_SET_BATCH_VERSION_INCLUSION_OPTIONS('ALL')[3].value, // ALL
  friendlyIds: [],
};
