import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useYesNoMultiForm = ({
  fieldsMeta,
  initialRequestData,
  useFormHooks: { handleFieldChange, updateFormData },
}) => {
  const fields = useMemo(() => fieldsMeta.map(({ multiFormField }) => multiFormField), [fieldsMeta]);
  const [childFormMetas, setChildFormMetas] = useState(getInitialChildFormMetas(fields, initialRequestData));
  useEffect(() => {
    if (initialRequestData) {
      setChildFormMetas(getInitialChildFormMetas(fields, initialRequestData));
      updateFormData(getInitialFormData(fields, initialRequestData));
    }
  }, [fields, initialRequestData, updateFormData]);

  const deleteChildForm = useCallback(
    (field, index) => {
      childFormMetas[field][index] = { isDeleted: true };
      setChildFormMetas(childFormMetas);
    },
    [childFormMetas],
  );

  const addChildForm = useCallback(
    (field, index) => {
      childFormMetas[field][index] = { isDeleted: false };
      setChildFormMetas(childFormMetas);
    },
    [childFormMetas],
  );

  const getRequestData = useCallback(
    formData => {
      const requestData = Object.entries(formData).reduce((data, [key, value]) => {
        const [field, indexStr, childField] = key.split('--');
        const index = parseInt(indexStr);
        if (key === 'reassignTo') {
          return data;
        }
        if (!isNaN(index) && childField !== undefined) {
          data[field] = data[field] ?? [];
          data[field][index] = data[field][index] ?? {};
          data[field][index][childField] = value;
        } else {
          data[key] = value;
        }
        return data;
      }, {});

      fieldsMeta.forEach(({ yesNoField, multiFormField }) => {
        if (requestData[yesNoField] === 'false') {
          delete requestData[multiFormField];
        }
        requestData[multiFormField] = requestData[multiFormField]?.filter(
          (_, index) => childFormMetas[multiFormField][index]?.isDeleted !== true,
        );
      });

      return requestData;
    },
    [fieldsMeta, childFormMetas],
  );

  return {
    childFormMetas,
    addChildForm,
    deleteChildForm,
    handleFieldChange,
    getRequestData,
  };
};

function getInitialFormData(fields, initialRequestData = {}) {
  return Object.entries(initialRequestData).reduce((acc, [field, value]) => {
    if (fields.includes(field)) {
      value.forEach((child, index) => {
        Object.entries(child).forEach(([childField, childValue]) => {
          acc[`${field}--${index}--${childField}`] = childValue;
        });
      });
    } else {
      acc[field] = value;
    }
    return acc;
  }, {});
}

function getInitialChildFormMetas(fields, initialData = {}) {
  return fields.reduce((acc, field) => {
    let meta = initialData[field]?.map(() => {
      return { isDeleted: false };
    });
    if (meta === undefined || isEmpty(meta)) {
      meta = [{ isDeleted: false }];
    }
    acc[field] = meta;
    return acc;
  }, {});
}

export default useYesNoMultiForm;
