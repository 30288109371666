import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import numeral from 'numeral';
import { useMutation } from '@apollo/client';
import { CurrencyInput, MuiTextInput, SelectionSet, useForm } from '@hometap/htco-components';
import { PERIODICITY } from 'data/constants/periodicity';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import useTrackDocuments from 'apps/track-details/Documents/hooks/useTrackDocuments';
import { DELETE_HOA, UPSERT_HOA } from 'apps/track-details/tasks/data/mutations';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { getNumberValue } from 'utils/validators';
import { getFullAddress } from 'utils/address';
import { SECTION_ALERT_TYPES, createSelectionLevelAlert } from '../../hooks/useSpecificContentQuery';
import { convertNumericStringsToNumbers } from '../../../utils/taskForms';
import './HOAController.scss';

const MONTHLY_OR_ANNUALLY_SELECTION = [
  {
    label: 'Monthly',
    value: PERIODICITY.MONTHLY,
  },
  {
    label: 'Annual',
    value: PERIODICITY.ANNUALLY,
  },
];

const MONTHLY_OR_ANNUALLY_LABEL = {
  [PERIODICITY.MONTHLY]: 'Monthly',
  [PERIODICITY.ANNUALLY]: 'Annual',
};

const HOA_RELATED_DOCUMENT_KINDS = ['proof_of_hoa_fees', 'hoa_organization_documents'];

export const HOAController = ({ track, canDelete, editDisabled, refetch }) => {
  const [upsertHoa, { loading }] = useMutation(UPSERT_HOA);
  const [deleteHoaMutation] = useMutation(DELETE_HOA);
  const readOnly = loading || editDisabled;
  const { identifier: trackId } = track;
  const doNothingOnChange = () => {};
  const { hoaReview, application } = track;
  const { hoaContactName, hoaContactPhone, hoaUnit, hoaZipCode, hoaStreet, hoaCity, hoaState } = application;
  const { documents } = useTrackDocuments(trackId);
  const initialFormData = useMemo(() => getInitialFormData(hoaReview), [hoaReview]);
  const [lastSavedFormData, setLastSavedFormData] = useState(); // Store the last successful published data. Will help checking if there are any new changes not published

  const { registerField, updateFormData, formData } = useForm(initialFormData);
  const { hoaFeeFrequency, errors } = formData;
  const convertedLastSavedFormData = convertNumericStringsToNumbers(lastSavedFormData);
  const convertedInitialFormData = convertNumericStringsToNumbers(initialFormData);
  const convertedFormData = convertNumericStringsToNumbers(formData);
  const isFormChanged = lastSavedFormData
    ? !isEqual(convertedLastSavedFormData, convertedFormData)
    : !isEqual(convertedInitialFormData, convertedFormData);
  const isFormValid = getIsFormValid(formData, errors);
  const { historyHoaUrl } = getApplicationReviewPageUrls(trackId);

  function getSectionAlert() {
    // Add an error instructing user to delete HOA data if Track is not marked as being in an HOA
    // and there is HOA data (and ARP is in an editable state)
    if (!application.hasHoa && hoaReview && !readOnly) {
      return createSelectionLevelAlert({
        type: SECTION_ALERT_TYPES.ERROR,
        message: `Confirm the HOA status with the homeowner, and unlock and correct the application as needed. If the property has no HOA, delete this section.`,
      });
    }
    return null;
  }

  const deleteHoa = async () => {
    try {
      await deleteHoaMutation({ variables: { hoaId: hoaReview.identifier } });
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Success',
        description: 'HOA details successfully deleted.',
      });
    } catch (error) {
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to delete HOA details',
        description: error.networkError?.result?.errors[0]?.message
          ? error.networkError.result.errors[0].message
          : 'Could not delete HOA details. Please try again.',
      });
    } finally {
      refetch();
    }
  };

  return (
    <ApplicationReviewSection
      anchorId="hoa-section"
      sectionTitle="HOA"
      historyUrl={historyHoaUrl}
      sectionAlert={getSectionAlert()}
      isSectionEverDeletable={true}
      deletionModalProps={{
        title: 'HOA details',
        header: 'Delete HOA details',
        type: 'homeowners association details',
        canDelete: !readOnly && canDelete,
        onDelete: deleteHoa,
        canShowModal: true,
      }}
      isWithActionBar={true}
      actionBarProps={{
        isPrimaryButtonEnabled: isFormChanged && isFormValid && !loading,
        isSecondaryButtonEnabled: isFormChanged && !loading,
        isShowPrompt: isFormChanged,
        primaryButtonName: loading ? 'Saving...' : 'Publish',
        onPrimaryButtonClick: async () => {
          const { identifier: trackId } = track;
          const { hoaName, hoaFeeAmount, hoaFeeFrequency } = formData;
          const variables = {
            trackId,
            hoaName,
            hoaFeeAmount: getNumberValue(hoaFeeAmount),
            hoaFeeFrequency,
          };

          try {
            await upsertHoa({ variables });
            setLastSavedFormData(formData);
            showNotification({
              type: TOAST_TYPE.success,
              title: 'Success',
              description: `Changes to "HOA" successfully saved.`,
            });
          } catch (error) {
            showNotification({
              type: TOAST_TYPE.error,
              title: 'Failed to save changes',
              description: error.networkError?.result?.errors[0]?.message
                ? error.networkError.result.errors[0].message
                : 'Could not save changes. Please try again.',
            });
          }
          refetch();
        },
        onSecondaryButtonClick: () => {
          updateFormData(initialFormData);
        },
      }}
      onEdit={null}
    >
      <div className="HOADetailsTable">
        <div className="HOADetailsTableColumn">
          <div className="HOADetailsTableField">
            <b>HOA contact name</b>
            <span>{hoaContactName}</span>
          </div>
          <div className="HOADetailsTableField">
            <b>Contact number</b>
            <span>{hoaContactPhone}</span>
          </div>
          <div className="HOADetailsTableField">
            <b>Address</b>
            <span>
              {getFullAddress({
                street: hoaStreet,
                unit: hoaUnit,
                city: hoaCity,
                state: hoaState,
                zipCode: hoaZipCode,
              })}
            </span>
          </div>
        </div>
        <div className="HOADetailsTableColumn">
          <b>Documents</b>
          {documents.map(document => {
            const { id, kind, kind_display, filename } = document;
            if (HOA_RELATED_DOCUMENT_KINDS.includes(kind)) {
              return (
                <div className="HOADetailsTableField" key={id}>
                  <span>{kind_display}</span>
                  <Link to={`/tracks/${trackId}/documents/${id}`} target="_blank">
                    {filename}
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      <MuiTextInput label="HOA name" disabled={readOnly} theme="outlined" width="100%" {...registerField('hoaName')} />
      <CurrencyInput
        label={`${MONTHLY_OR_ANNUALLY_LABEL[hoaFeeFrequency] ?? ''} HOA fee`}
        padFractionalZeros={true}
        disabled={readOnly}
        {...registerField('hoaFeeAmount')}
      />
      <SelectionSet
        onChange={doNothingOnChange}
        options={MONTHLY_OR_ANNUALLY_SELECTION}
        disabled={readOnly}
        className="SelectorElement"
        {...registerField('hoaFeeFrequency')}
      />
    </ApplicationReviewSection>
  );
};

const getInitialFormData = hoaReview => {
  const AMOUNT_FORMAT = '00.00';
  const initialFormData = {
    hoaName: '',
    hoaFeeAmount: '',
    hoaFeeFrequency: PERIODICITY.MONTHLY,
  };
  if (hoaReview?.hoaName) {
    initialFormData.hoaName = hoaReview.hoaName;
  }
  if (hoaReview?.totalAnnualHoaFee) {
    const hoaFeeAmountNumberValue = getNumberValue(hoaReview.totalAnnualHoaFee);
    initialFormData.hoaFeeAmount = numeral(hoaFeeAmountNumberValue).format(AMOUNT_FORMAT);
    initialFormData.hoaFeeFrequency = PERIODICITY.ANNUALLY;
  }

  return initialFormData;
};

const getIsFormValid = (formData, errors) => {
  const { hoaName, hoaFeeAmount } = formData;
  const eitherFieldIsFilled = !!hoaName || !!hoaFeeAmount;

  return eitherFieldIsFilled && isEmpty(errors);
};

export default HOAController;
