import env from './env';

// TODO: Add a redirect for deploy previews, when we have support for them.
export const getPortalsUrl = (path = '') => {
  // Remove slash if "path" starts with a slash because, the REACT_APP_BACKEND_BASE var should already end in a slash.
  if (path[0] && path[0] === '/') {
    path = path.slice(1, path.length);
  }
  if (process.env.REACT_APP_BACKEND_BASE) {
    return process.env.REACT_APP_BACKEND_BASE + path;
  }

  return env.getBackendUrlForEnv() + path;
};

export const getLogoutUrl = () => {
  return getPortalsUrl('auth/logout');
};

export const getLoginUrl = () => {
  return getPortalsUrl('auth/login');
};

export const getGraphQlUrl = () => {
  return getPortalsUrl('graphql/');
};

export const getFundingUrl = () => {
  const { origin } = window.location;
  return `${origin}/funding`;
};

export const getTracksTableUrl = filters => {
  const { origin } = window.location;
  if (!filters) {
    return `${origin}/tracks`;
  }
  const filter_query = new URLSearchParams(filters).toString();
  return `${origin}/tracks?${filter_query}`;
};

export const getTrackTasks = trackId => {
  const { origin } = window.location;
  return `${origin}/tracks/${trackId}/tasks`;
};
