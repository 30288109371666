import { TASK_STATUSES } from 'data/constants/taskStatuses';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput, useForm } from '@hometap/htco-components';
import { getTodoNotesOptions } from 'apps/track-details/utils/trackDetailsTodo';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import { TaskPropType } from '../../TaskDetail';
import { getHomeownerTodoReason } from 'apps/track-details/todos/todoUtils';
import { getExpirationDateData, getTheDateWithDaysAdded } from 'utils/dates';
import {
  useSpecificContentQuery,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { GET_CONDO_MASTER_POLICY } from './getCondoMasterPolicyReview';
import { emailValidator, phoneValidator } from 'utils/validators';
import { CREATE_CONDO_MASTER_POLICY, UPDATE_CONDO_MASTER_POLICY } from 'apps/track-details/tasks/data/mutations';
import { useMutation } from '@apollo/client';
import './CondoMasterPolicyReview.scss';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import SelectionYesNoSet from '../SelectionYesNoSet/SelectionYesNoSet';
import { FLORIDA_LOW_REPLACEMENT_COST } from 'apps/track-details/data/constants/messages';
import { INSURANCE_POLICY_KINDS } from 'apps/track-details/data/constants/policyKinds';
import { getVariableValue } from '../../../TaskList/TaskListUtils';
import { getSelectionYesNoInitialValue } from 'apps/track-details/utils/taskForms';

const OTHER = 'Other';

const AGENT_CONTACT_TYPE = {
  Email: 'email',
  Phone: 'phone',
};

const AGENT_CONTACT_TYPE_OPTIONS = [
  { value: AGENT_CONTACT_TYPE.Email, label: 'Email' },
  { value: AGENT_CONTACT_TYPE.Phone, label: 'Phone' },
];

const TOOLTIP_CONTENT = (
  <div>
    A valid Condo Master Policy means the:
    <br />
    1. Address on the policy matches the address on the application
    <br />
    2. The policy is current and paid for
  </div>
);

/**
 * @typedef CondoMasterPolicyReviewFormData
 * @type {object}
 * @property {TrueFalseValue} [isValid]
 * @property {'email'|'phone'} [contactType]
 * @property {string} [agentName]
 * @property {string} [agentEmail]
 * @property {string} [agentPhone]
 * @property {string} [provider]
 * @property {string} [providerName]
 * @property {string} [renewalDate]
 * @property {string} [policyNumber]
 * @property {string} [reason]
 * @property {string} [reasonNote]
 * @property {number} [dwellingAmount]
 * @property {TrueFalseValue} [replacementCost]
 */
/**
 * @typedef CondoMasterPolicyReviewParams
 * @type {object}
 * @property {Task} task
 */
/**
 * @param {CondoMasterPolicyReviewParams} params
 * @return JSX.Element
 */
const CondoMasterPolicyReview = ({ task }) => {
  const { trackId } = useParams();
  const { taskKinds, allConfigurations } = useConfigurations();
  const { registerField, updateFormData, formData = {}, errors } = useForm({});
  const { data: condoMasterPolicyReviewData } = useSpecificContentQuery(GET_CONDO_MASTER_POLICY);
  const { user } = useCurrentUser();

  const taskId = task.identifier;
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');

  const extendedTaskData = useMemo(
    () => getCondoMasterPolicyReviewExtendedData(condoMasterPolicyReviewData?.track, allConfigurations),
    [condoMasterPolicyReviewData, allConfigurations],
  );
  const {
    createOrUpdateCondoMasterPolicyReviewTask,
    isCreatingOrUpdatingCondoMasterPolicyReviewTask,
    initialFormData,
  } = useCondoMasterPolicyReviewTaskForm(trackId, uploadedDocIds, extendedTaskData);
  const { replacementCost: initialReplacementCost } = initialFormData;

  const { insuranceProviders = [] } = extendedTaskData;
  const providerOptions = insuranceProviders?.map(provider => ({ value: provider, label: provider })) || [];
  const { isValid, reason, contactType, provider, renewalDate, replacementCost } = formData;
  const { isExpiring } = getExpirationDateData(renewalDate);
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const canUpdateTaskData = !!condoMasterPolicyReviewData;
  const isApplicationFloridaState = condoMasterPolicyReviewData?.track?.home?.address?.state === 'FL';

  const isValidForm = useMemo(() => {
    return getIsValidCondoMasterPolicyReviewTaskForm(user, formData, errors);
  }, [user, formData, errors]);

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.UPLOAD_CONDO_MASTER_POLICY,
    taskKinds,
  });

  useEffect(() => {
    if (canUpdateTaskData) {
      updateSpecificTaskById(taskId, {
        saving: isCreatingOrUpdatingCondoMasterPolicyReviewTask,
        complete: createOrUpdateCondoMasterPolicyReviewTask,
        initialFormData,
        formData,
        updateFormData,
        isValidForm,
      });
    }
  }, [
    isCreatingOrUpdatingCondoMasterPolicyReviewTask,
    createOrUpdateCondoMasterPolicyReviewTask,
    updateSpecificTaskById,
    taskId,
    initialFormData,
    formData,
    updateFormData,
    isValidForm,
    canUpdateTaskData,
  ]);

  return (
    <TaskDetailContentSectionReview
      header="Document validation"
      label="Is this a valid Condo Master Policy? "
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      tooltipContent={TOOLTIP_CONTENT}
      invalidInformationText={
        'Due to an invalid document, a request for an updated condo master policy will be sent when this task is marked as complete.'
      }
      reasonOptions={REASON_OPTIONS}
      isWithDivider={true}
    >
      <div className="TaskDetailContentTitle">Policy details</div>

      <MuiSelect
        label="Provider"
        classNamePrefix="CondoMasterPolicyReviewSelect"
        options={providerOptions}
        theme="outlined"
        width="100%"
        disabled={isCompleted}
        {...registerField('provider')}
      />

      {provider === OTHER && (
        <MuiTextInput
          label="Provider name"
          theme="outlined"
          width="100%"
          disabled={isCompleted}
          {...registerField('providerName')}
        />
      )}

      <div className="TwoItemFormRow">
        <MuiTextInput
          label="Agent name (optional)"
          theme="outlined"
          width="100%"
          disabled={isCompleted}
          {...registerField('agentName')}
        />
        <MuiSelect
          label="Agent contact type"
          options={AGENT_CONTACT_TYPE_OPTIONS}
          theme="outlined"
          width="100%"
          classNamePrefix="CondoMasterPolicyReviewSelect"
          disabled={isCompleted}
          {...registerField('contactType')}
        />
      </div>

      {contactType && (
        <div>
          {contactType === AGENT_CONTACT_TYPE.Email && (
            <MuiTextInput
              label="Agent email (optional)"
              theme="outlined"
              validator={emailValidator}
              width="100%"
              disabled={isCompleted}
              {...registerField('agentEmail')}
            />
          )}
          {contactType === AGENT_CONTACT_TYPE.Phone && (
            <MuiTextInput
              label="Agent phone (optional)"
              validator={phoneValidator}
              theme="outlined"
              mask="000-000-0000"
              width="100%"
              disabled={isCompleted}
              {...registerField('agentPhone')}
            />
          )}
        </div>
      )}

      <div className="TwoItemFormRow">
        <DatePicker
          className="CondoMasterPolicyReviewDatePicker"
          label="Renewal date"
          theme="outlined"
          width="100%"
          disabled={isCompleted}
          {...registerField('renewalDate')}
        />
        <MuiTextInput
          label="Policy number"
          theme="outlined"
          width="100%"
          disabled={isCompleted}
          {...registerField('policyNumber')}
        />
      </div>
      {isExpiring && (
        <DetailInformationBlock
          isInvalid={false}
          isExpiring={isExpiring}
          isCompleted={isCompleted}
          expiringText={
            'Due to expiration rules, a request for an updated Condo Master Insurance Policy will be sent when this task is marked as complete.'
          }
        />
      )}

      <div>
        <SelectionYesNoSet
          isWithMarginBottom={false}
          label="Is there proof of replacement cost?"
          disabled={isCompleted}
          {...registerField('replacementCost')}
        />
        {replacementCost === 'false' && (initialReplacementCost === 'true' || initialReplacementCost === undefined) && (
          <DetailInformationBlock
            className="CondoMasterPolicyReviewBlockText"
            isCompleted={isCompleted}
            text="A request for a Replacement Cost Calculator will be sent when this task is marked as complete."
          />
        )}
        {isApplicationFloridaState && (
          <DetailInformationBlock
            className="CondoMasterPolicyReviewBlockText"
            text={FLORIDA_LOW_REPLACEMENT_COST}
            type="info"
          />
        )}
      </div>

      <CurrencyInput
        label="Coverage amount"
        disabled={isCompleted}
        extendedMaskProps={{ scale: 0 }}
        {...registerField('dwellingAmount')}
      />
    </TaskDetailContentSectionReview>
  );
};

CondoMasterPolicyReview.propTypes = {
  task: TaskPropType,
};

export default CondoMasterPolicyReview;

/**
 * @param {ExtendedTaskData} [extendedTaskData]
 * @returns {CondoMasterPolicyReviewFormData}
 */
function getCondoMasterPolicyReviewInitialFormData(extendedTaskData) {
  const { insurancePolicyReviews, insuranceProviders } = extendedTaskData;
  const condoMasterPolicyReview =
    insurancePolicyReviews?.find(({ kind }) => kind === INSURANCE_POLICY_KINDS.CONDO) || {};

  const { agent, agentEmail, agentPhone, dwellingAmount, policyNumber, provider, renewalDate, replacementCost } =
    condoMasterPolicyReview;
  const initialFormData = {
    isValid: undefined,
    agentName: agent,
    provider,
    renewalDate,
    policyNumber,
    dwellingAmount,
    contactType: AGENT_CONTACT_TYPE.Phone,
    replacementCost: getSelectionYesNoInitialValue(replacementCost),
  };
  if (provider && insuranceProviders?.length && !insuranceProviders.includes(provider)) {
    initialFormData.provider = OTHER;
    initialFormData.providerName = provider;
  }
  if (agentPhone) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Phone;
    initialFormData.agentPhone = agentPhone;
  }
  if (agentEmail) {
    initialFormData.contactType = AGENT_CONTACT_TYPE.Email;
    initialFormData.agentEmail = agentEmail;
  }
  return initialFormData;
}

/**
 * @typedef {({formData: CondoMasterPolicyReviewFormData, extendedTaskData?: ExtendedTaskData}) => void} CreateOrUpdateCondoMasterPolicyReviewTask
 */
/**
 * @typedef UseCondoMasterPolicyReviewTaskForm
 * @type {object}
 * @property {boolean} [isCreatingOrUpdatingCondoMasterPolicyReviewTask]
 * @property {CreateOrUpdateCondoMasterPolicyReviewTask} [createOrUpdateCondoMasterPolicyReviewTask]
 * @property {CondoMasterPolicyReviewFormData} [initialFormData]
 */
/**
 * @param {string} [trackId]
 * @param {[string]} [uploadedDocIds]
 * @param {ExtendedTaskData} extendedTaskData
 * @returns {UseCondoMasterPolicyReviewTaskForm}
 */
export function useCondoMasterPolicyReviewTaskForm(trackId, uploadedDocIds, extendedTaskData) {
  const [createCondoMasterPolicyReview, { loading: isCreateCondoMasterPolicyReviewLoading }] =
    useMutation(CREATE_CONDO_MASTER_POLICY);
  const [updateCondoMasterPolicyReview, { loading: isUpdateCondoMasterPolicyReviewLoading }] =
    useMutation(UPDATE_CONDO_MASTER_POLICY);
  const initialFormData = useMemo(
    () => getCondoMasterPolicyReviewInitialFormData(extendedTaskData),
    [extendedTaskData],
  );
  const isCreatingOrUpdatingCondoMasterPolicyReviewTask =
    isCreateCondoMasterPolicyReviewLoading || isUpdateCondoMasterPolicyReviewLoading;

  const createOrUpdateCondoMasterPolicyReviewTask = useCallback(
    async ({ formData = {} }) => {
      const condoMasterPolicyReviewReview =
        extendedTaskData.insurancePolicyReviews?.find(({ kind }) => kind === INSURANCE_POLICY_KINDS.CONDO) || {};
      if (formData?.isValid !== 'true') {
        return;
      }
      const {
        providerName,
        agentPhone,
        agentEmail,
        contactType,
        provider,
        policyNumber,
        renewalDate,
        agentName,
        dwellingAmount,
        replacementCost,
      } = formData;
      const variables = {
        uploadedDocIds,
        agentName,
        kind: INSURANCE_POLICY_KINDS.CONDO,
        policyNumber,
        provider,
        renewalDate,
        dwellingAmount,
        replacementCost: replacementCost === 'true',
      };
      if (contactType === AGENT_CONTACT_TYPE.Phone) {
        variables.agentPhone = agentPhone;
      }
      if (contactType === AGENT_CONTACT_TYPE.Email) {
        variables.agentEmail = agentEmail;
      }
      if (provider === OTHER) {
        variables.provider = providerName;
      }

      if (condoMasterPolicyReviewReview && condoMasterPolicyReviewReview.identifier) {
        await updateCondoMasterPolicyReview({
          variables: { ...variables, insurancePolicyId: condoMasterPolicyReviewReview.identifier },
        });
      } else {
        await createCondoMasterPolicyReview({
          variables: { ...variables, trackId },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackId, createCondoMasterPolicyReview, updateCondoMasterPolicyReview, extendedTaskData],
  );

  return {
    isCreatingOrUpdatingCondoMasterPolicyReviewTask,
    createOrUpdateCondoMasterPolicyReviewTask,
    initialFormData,
  };
}

/**
 * @typedef GetCondoMasterPolicyReviewTodosToCreateParams
 * @type {object}
 * @property {CondoMasterPolicyReviewFormData} [formData]
 * @property {CondoMasterPolicyReviewFormData} [initialFormData]
 * @property {Object<string, string>} [taskKinds]
 */
/**
 * @param {GetCondoMasterPolicyReviewTodosToCreateParams} [params]
 * @returns {HomeownersTodoToCreate[] | null}
 */
export const getCondoMasterPolicyReviewAdditionalTodosToCreate = ({
  formData: { isValid, replacementCost },
  taskKinds,
  initialFormData: { replacementCost: initialReplacementCost },
}) => {
  if (isValid === 'false') {
    // Do not create an additional to-dos if the AS marked the document as invalid
    return null;
  }
  if (replacementCost === 'false' && (initialReplacementCost === 'true' || initialReplacementCost === undefined)) {
    return [
      {
        kind: taskKinds.UPLOAD_REPLACEMENT_COST_ESTIMATOR_ON_CONDO_MASTER_POLICY,
      },
    ];
  }
  return null;
};

/**
 * @param {Track} [track]
 * @param {Configurations} [configurations]
 * @returns {ExtendedTaskData}
 */
export function getCondoMasterPolicyReviewExtendedData(track = {}, configurations = {}) {
  const { insurancePolicyReviews } = track;
  const { insuranceProviders } = configurations?.insurancePolicy || {};
  return {
    insuranceProviders,
    insurancePolicyReviews,
  };
}

/**
 * @param {CondoMasterPolicyReviewFormData} [formData]
 * @param {Record<string, {message: string, show: boolean}>} [errors]
 * @returns {boolean}
 */
function getIsValidCondoMasterPolicyReviewTaskForm(user = {}, formData = {}, errors) {
  const {
    providerName,
    isValid,
    reason,
    reasonNote,
    contactType,
    provider,
    policyNumber,
    renewalDate,
    dwellingAmount,
    replacementCost,
  } = formData;
  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });
  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }
  if (
    isValid === 'true' &&
    provider &&
    contactType &&
    renewalDate &&
    policyNumber &&
    dwellingAmount &&
    replacementCost !== undefined
  ) {
    const isProviderValid = !!(provider === OTHER ? providerName : provider);
    const isContactEmailValid = contactType === AGENT_CONTACT_TYPE.Email && !errors?.agentEmail;
    const isContactPhoneValid = contactType === AGENT_CONTACT_TYPE.Phone && !errors?.agentPhone;
    const isContactValid = isContactEmailValid || isContactPhoneValid;
    return !(!isProviderValid || !isContactValid);
  }
  return false;
}

export const CONDO_MASTER_POLICY_REVIEW_DEMO_DATA = {
  agentName: 'Bob',
  providerName: 'Whitney Smith',
  isValid: 'true',
  agentEmail: `test+${Math.random().toString(36).substring(5)}@hometap.com`,
  contactType: AGENT_CONTACT_TYPE.Email,
  provider: OTHER,
  policyNumber: '000123ABC',
  renewalDate: getTheDateWithDaysAdded(60),
  replacementCost: 'true',
  dwellingAmount: 400000,
};
