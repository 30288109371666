import { CurrencyInput, DatePicker, MuiSelect, MuiTextInput } from '@hometap/htco-components';
import SelectionYesNoSet from '../SelectionYesNoSet/SelectionYesNoSet';
import './SeniorLienDataEntry.scss';
import FilterMultiSelect from 'components/FilterMultiSelect/FilterMultiSelect';
import useConfigurations from 'hooks/useConfigurations';

const SENIOR_LIEN_DATE_ENTRY_RATE_TYPE_OPTIONS = [
  {
    value: 'fixed',
    label: 'Fixed',
  },
  {
    value: 'adjustable',
    label: 'Adjustable',
  },
  {
    value: 'negative_amortization',
    label: 'Negative Amortization',
  },
  {
    value: 'interest_only',
    label: 'Interest Only',
  },
  {
    value: 'hei_equity_share',
    label: 'HEI / Equity Share',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const SENIOR_LIEN_DATE_ENTRY_ADDITONAL_LIEN_TYPE_OPTION = {
  value: 'hei',
  label: 'HEI',
};

export const SeniorLienDataEntryForm = ({
  isDisabled,
  getChildField,
  registerChildField,
  useFormHooks: { handleFieldChange },
  availableDocOptions,
  changeDocSelections,
}) => {
  const { lienKinds } = useConfigurations();
  return (
    <>
      <div>
        <FilterMultiSelect
          label="Documents"
          placeholder=""
          className="FilterMultiSelect"
          options={availableDocOptions}
          classNamePrefix="SeniorLienSelect"
          {...registerChildField('documentIds')}
          onChange={(value, name, err) => {
            changeDocSelections(value);
            handleFieldChange(value, name, err);
          }}
          disabled={isDisabled}
        />
      </div>

      <div className="FormItem">
        <MuiSelect
          label="Lien type"
          options={[...lienKinds, SENIOR_LIEN_DATE_ENTRY_ADDITONAL_LIEN_TYPE_OPTION]}
          disabled={isDisabled}
          theme="outlined"
          width="100%"
          classNamePrefix="SeniorLienSelect"
          {...registerChildField('kind')}
        />
      </div>

      <div>
        <DatePicker
          label="Date recorded"
          theme="outlined"
          disabled={isDisabled}
          showRequiredAsterisk={false}
          className="SeniorLienDatePicker"
          {...registerChildField('lienDate')}
        />
      </div>

      <div className="TwoItemFormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Servicer"
            theme="outlined"
            width="100%"
            disabled={isDisabled}
            {...registerChildField('holder')}
          />
        </div>

        <div className="FormItem">
          <MuiTextInput
            label="Mortgage account number"
            theme="outlined"
            width="100%"
            disabled={isDisabled}
            {...registerChildField('mortgageAccountNumber')}
          />
        </div>
      </div>

      <div className="TwoItemFormRow">
        <div className="FormItem">
          <CurrencyInput
            label="Balance"
            disabled={isDisabled}
            padFractionalZeros={true}
            {...registerChildField('currentBalance')}
          />
        </div>

        <div className="FormItem">
          <MuiTextInput
            label="Position"
            mask={[
              {
                mask: Number,
                signed: false,
                allowDecimal: false,
              },
            ]}
            unmask="typed"
            theme="outlined"
            width="100%"
            required={true}
            showRequiredAsterisk={false}
            disabled={isDisabled}
            {...registerChildField('position')}
          />
        </div>
      </div>

      <div className="TwoItemFormRow">
        <div className="FormItem">
          <MuiTextInput
            label="Rate"
            mask={[
              {
                mask: Number,
                radix: '.',
                scale: 5,
              },
            ]}
            unmask="typed"
            endAdornment="%"
            theme="outlined"
            width="100%"
            disabled={isDisabled}
            required={true}
            showRequiredAsterisk={false}
            {...registerChildField('rate')}
          />
        </div>
        <div className="FormItem">
          <MuiSelect
            label="Rate type"
            options={SENIOR_LIEN_DATE_ENTRY_RATE_TYPE_OPTIONS}
            disabled={isDisabled}
            theme="outlined"
            width="100%"
            required={true}
            showRequiredAsterisk={false}
            classNamePrefix="SeniorLienSelect"
            {...registerChildField('rateType')}
          />
        </div>
      </div>

      <div>
        <SelectionYesNoSet
          label="Was this lien paid off or paid down?"
          required={true}
          showRequiredAsterisk={false}
          {...registerChildField('wasPaidDown')}
        />
      </div>

      {getChildField('wasPaidDown') === 'true' && (
        <div>
          <CurrencyInput
            label="Paydown amount"
            padFractionalZeros={true}
            disabled={isDisabled}
            {...registerChildField('paydownAmount')}
          />
        </div>
      )}
    </>
  );
};
