import React from 'react';
import cx from 'classnames';
import { Icon } from '@hometap/htco-components';

import './Toast.scss';

const Toast = ({ message, theme = 'success', duration = 4 }) => {
  // This component is purposely a basic and inflexible simple Toast message that only renders a success or warning message in the
  // top-center position to keep in scope for TUX-613 plans to move this to the component library with more flexibility are in place.

  return (
    <div className="Toast" style={{ animationDuration: `${duration}s` }}>
      <div className={cx('ToastContent', { isSuccess: theme === 'success', isWarning: theme === 'warning' })}>
        <Icon name={cx({ 'check-mdc': theme === 'success', 'icon-alert': theme === 'warning' })} />
        <p className="ToastMessage">{message}</p>
      </div>
    </div>
  );
};

export default Toast;
