import React, { useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { GET_DOCUMENT_SET_BATCH_BY_BPM_ID_QUERY } from './documentSetBatchRequests';
import { useQuery } from '@apollo/client';
import { Button, Container, DataTable, NotFoundBlock } from '@hometap/htco-components';
import TrackDetailsPadding from '../track-details/TrackDetailsPadding';
import DocumentBatchProgressBinary from './components/DocumentBatchProgress/DocumentBatchProgressBinary';
import { GENERIC_BATCH_ERROR_MESSAGE } from './data/constants';
import { downloadThenMaybeConvertDocument } from 'apps/track-details/Documents/documentRequests';
import { downloadBlobFile } from 'apps/track-details/Documents/DocumentsUtils';
import { showNotification } from 'utils/toasts';
import './DocumentSetBatch.scss';

const DocumentSetBatchDetailsController = () => {
  const { documentBatchId } = useParams();
  const {
    data: documentSetTrackData,
    error: documentSetBatchTrackError,
    loading: documentSetBatchTrackLoading,
  } = useQuery(GET_DOCUMENT_SET_BATCH_BY_BPM_ID_QUERY, {
    variables: { bpmProcessId: documentBatchId },
  });
  const navigate = useNavigate();

  const documentSetBatchDetails = documentSetTrackData?.documentBatchRequest ?? {};
  const documentSetTracks = documentSetTrackData?.documentBatchRequest?.documentSets ?? [];

  /**
   * Handles downloading the document set batch
   */
  const handleDownload = async () => {
    try {
      const response = await downloadThenMaybeConvertDocument({
        id: documentSetBatchDetails?.documentSetBatchId,
        file_extension: 'zip',
      });
      await downloadBlobFile(response, `${documentSetBatchDetails?.batchRequestName}.zip`);
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'There was an error downloading the document set batch.',
      });
    }
  };

  const documentSetBatchDetailsSelectors = [
    {
      id: 'FriendlyID',
      name: 'Friendly ID',
      sortable: true,
      selector: row => row.friendlyId,
      cell: row => <a href={`/tracks/${row?.trackId}/tasks`}>{row?.friendlyId}</a>,
    },
    {
      id: 'documentSetBatchName',
      name: 'Document Set Name',
      sortable: true,
      center: true,
      selector: row => row.documentSetName,
      cell: row => (
        <>
          {row?.documentSetId ? (
            <Link
              to=""
              onClick={e => {
                e.preventDefault();
                navigate(`/tracks/${row?.trackId}/documents/${row?.documentSetId}`, {
                  state: { documentSetBatchId: documentBatchId },
                });
              }}
            >
              {row?.documentSetName}
            </Link>
          ) : (
            <span>{row?.documentSetName || 'N/A'}</span>
          )}
        </>
      ),
    },
    {
      id: 'documentSetBatchFileType',
      name: 'File Type',
      sortable: true,
      center: true,
      selector: row => row.fileType,
      cell: row => <>{row.fileType.toUpperCase()}</>,
    },
    {
      id: 'documentSetBatchStatus',
      name: 'Status',
      left: true,
      sortable: true,
      maxWidth: '200px',
      selector: row => row.documentSetStatus,
      cell: row => (
        <DocumentBatchProgressBinary status={row.documentSetStatus} failedReason={GENERIC_BATCH_ERROR_MESSAGE} />
      ),
    },
  ];

  const customStyles = {
    cells: {
      style: {
        paddingTop: 24,
        paddingBottom: 24,
        fontSize: 16,
      },
    },
  };

  const errorDataComponent = useMemo(() => {
    if (documentSetBatchTrackError) {
      return (
        <NotFoundBlock
          error={`${documentSetBatchTrackError?.networkError?.statusCode || 404} Error`}
          title={"We're having trouble fetching documents for this track. Please try refreshing."}
        />
      );
    }
  }, [documentSetBatchTrackError]);

  return (
    <TrackDetailsPadding className={'DocumentSetBatchControllerPadding'}>
      <Container className="DocumentSetBatchContainer">
        <div className={'DocumentSetBatchControllerRow'}>
          <h3>
            <a href="/document-set-batch">Document Set Batches </a> / {documentSetBatchDetails?.batchRequestName}
          </h3>
          <Button onClick={handleDownload}>Download</Button>
        </div>
        <DataTable
          data={documentSetTracks ?? []}
          loading={documentSetBatchTrackLoading}
          columns={documentSetBatchDetailsSelectors}
          customStyles={customStyles}
          noDataComponent={'There are no batch document sets to display'}
          errorDataComponent={errorDataComponent}
        />
      </Container>
    </TrackDetailsPadding>
  );
};

export default DocumentSetBatchDetailsController;
