import React from 'react';
import cx from 'classnames';
import JSZip from 'jszip';
import { getToday } from 'utils/dates';
import { prepareFileForUpload, stripFileExtension } from '../../../utils/documents';
import { downloadThenMaybeConvertDocument, downloadMultipleFiles } from './documentRequests';
import { showNotification } from '../../../utils/toasts';
import { getDocumentToastSettings, getErrorInformation } from './DocumentToasts';
import {
  AV_MESSAGE_PASSWORD_PROTECTED,
  AV_STATUS,
  MERGE_DOCUMENTS_DESCRIPTION_BY_AV_STATUS,
  MERGE_DOCUMENTS_TOAST,
  OTHERS_AV_STATUS,
  NOT_YET_SCANNED_AV_STATUS,
  DEFAULT_DELETE_MODAL_CONFIRM_OPTIONS,
  DEFAULT_DOWNLOAD_MODAL_CONFIRM_OPTIONS,
} from './data/constants';

export const getDeleteDocumentModalConfirmOptions = (document, deleteDocuments) => ({
  ...DEFAULT_DELETE_MODAL_CONFIRM_OPTIONS,
  header: 'Delete Document',
  onConfirm: () => deleteDocuments([document.id]),
  children: (
    <div className="ModalConfirmDocumentWrapper">
      You are about to delete all versions of document{' '}
      <span className="ModalConfirmDocumentName">{document.filename}</span>. This cannot be undone.
      <p className="ModalConfirmDocumentBodyDescription">Are you sure you want to proceed?</p>
    </div>
  ),
});

export const getEncryptedDocumentDownloadModalConfirmOptions = (document, downloadDocument) => ({
  ...DEFAULT_DOWNLOAD_MODAL_CONFIRM_OPTIONS,
  header: 'Download Document',
  onConfirm: () => {
    downloadDocument(document);
  },
  children: (
    <>
      Document is password protected and has not been scanned for virus protection.
      <p className="ModalConfirmDocumentBodyDescription">Are you sure you want to proceed?</p>
    </>
  ),
});

export const getDeleteDocumentsModalConfirmOptions = (documentIds, deleteDocuments) => ({
  ...DEFAULT_DELETE_MODAL_CONFIRM_OPTIONS,
  header: 'Delete Documents',
  onConfirm: () => deleteDocuments(documentIds),
  children: (
    <>
      You are about to delete all versions of <span className="ModalConfirmDocumentName">{documentIds.length}</span>{' '}
      documents. This cannot be undone.
      <p className="ModalConfirmDocumentBodyDescription">Are you sure you want to proceed?</p>
    </>
  ),
});

export const getDownloadDocumentsModalConfirmOptions = (documents, downloadDocuments, onCancel) => ({
  ...DEFAULT_DOWNLOAD_MODAL_CONFIRM_OPTIONS,
  header: 'Download Documents',
  onConfirm: () => downloadDocuments(documents),
  onCancel,
  onClose: onCancel,
  children: (
    <>
      <span className="ModalConfirmDocumentName">{documents.length}</span> of the selected documents are password
      protected and have not been scanned for virus protection.
      <div className="ModalConfirmDocumentBodyDescription">
        {documents.map(({ filename, id }) => (
          <div className="ModalConfirmDocumentNameWithEllipsis">{filename}</div>
        ))}
      </div>
      <p className="ModalConfirmDocumentBodyDescription">Are you sure you want to proceed?</p>
    </>
  ),
});

export const getDownloadDocumentModalConfirmOptions = (document, downloadDocument) => ({
  ...DEFAULT_DOWNLOAD_MODAL_CONFIRM_OPTIONS,
  header: 'Download Document',
  onConfirm: () => downloadDocument(document),
  children: (
    <div className="ModalConfirmDocumentWrapper">
      <span className="ModalConfirmDocumentName">{document.filename}</span> is password protected and has not been
      scanned for virus protection.
      <p className="ModalConfirmDocumentBodyDescription">Are you sure you want to proceed?</p>
    </div>
  ),
});

/**
 * @param {Object[]} documents - documents list
 * @param {string[]} documentIds - document Ids to be merged
 */
export const getDocumentUUID4IdsWithMappedFilesByAvStatus = (documents, documentIds) => {
  const mergeDocuments = documents.reduce((acc, document) => {
    if (documentIds.includes(document.code || document.id)) {
      return [...acc, document];
    }
    return acc;
  }, []);
  const documentIdsToMerge = mergeDocuments.map(({ id }) => id);
  const mappedFilesByAVStatus = getMappedFilesByAVStatus(mergeDocuments);
  return { documentIdsToMerge, mappedFilesByAVStatus };
};

export const downloadBlobFile = (fileBlob, filename) => {
  const url = window.URL.createObjectURL(fileBlob);
  const tempElement = document.createElement('a');

  tempElement.href = url;
  tempElement.download = filename;
  tempElement.click();
};

// TODO: remove this function and logic in the components
//  when https://hometap.atlassian.net/browse/ROCK-518 will be merged
/**
 * @param {string} id - dropdown menu id
 * @param {callback} onClick - callback function will be invoked on click
 */
export const onClickItemCloseDropdownMenu = (id, onClick) => {
  onClick();
  document.getElementById(id)?.firstChild?.click();
};

/**
 * @typedef GetFormDataForUploadFiles
 * @type {object}
 * @property {File[]} files
 * @property {string} [fileName]
 * @property {boolean} [internal]
 * @property {string} [kind]
 * @property {string} [kind_other]
 * @property {string} [personId]: document person id
 */

/**
 * @param {GetFormDataForUploadFiles}
 * @returns {FormData} formData
 */
export const getFormDataForUploadFiles = async ({ files, fileName, internal, kind, kindOther, personId }) => {
  const formData = new FormData();

  // converts files if unsupported file type
  const preparedFiles = files.map(file => prepareFileForUpload(file));

  await Promise.all(preparedFiles).then(values => {
    values.forEach(({ file }) => formData.append('file', file));
  });
  let formattedFileName = files[0].file.name;
  // if more than multiple files, add pdf extension
  if (files.length > 1) {
    // if fileName is not provided, default to the first file's name
    if (fileName) {
      formattedFileName = `${fileName}.pdf`;
    } else {
      formattedFileName = stripFileExtension({ fileName: formattedFileName, replaceWith: '.pdf' });
    }
  }
  formData.append('name', formattedFileName);
  if (typeof internal === 'boolean') {
    formData.append('internal', internal);
  }
  if (kindOther) {
    formData.append('kind_other', kindOther);
  } else {
    formData.append('kind', kind);
  }
  if (personId) {
    formData.append('person_id', personId);
  }
  return formData;
};

/**
 * Adds files to empty zip object and returns array of zip objects that will be generated into a zipObject to be downloaded
 * @param {array} array of file objects that contain:
 * @param {JSZip} zip JSZip Object that files will be added to
 * @returns {Promise}
 */
export const addMultipleFilesToZip = (zip, files) => {
  return files.map(({ value }) => {
    return zip.file(value.filename, value.file);
  });
};

/**
 * Downloads a single file or zips multiple files.  ZipFilename defaults to `compressed-{todays date and time}.zip`
 * @param {Object[]} documentsToDownload the documents to download
 * @param {string} documentsToDownload.id ids or codes of the documents
 * @param {string} documentsToDownload.filename
 * @param {string} documentsToDownload.file_extension
 * @param {boolean|undefined} documentsToDownload.checked ids or codes of the documents
 * @param {boolean|undefined} documentsToDownload.pinned ids or codes of the documents
 * @param {string} zipFilePrefix prefix for the zip file
 * @returns
 */
export const downloadAndCompressFiles = async (documentsToDownload, zipFilePrefix = 'compressed') => {
  // guard statement to return if no files to download
  if (!documentsToDownload || documentsToDownload.length === 0) {
    return;
  }

  // if downloading single file
  if (documentsToDownload.length === 1) {
    const blobFile = await downloadThenMaybeConvertDocument(documentsToDownload[0]);
    downloadBlobFile(blobFile, documentsToDownload[0].filename);
    return;
  }

  // Download multiple files
  const downloadedFiles = await Promise.allSettled(await downloadMultipleFiles(documentsToDownload));

  // Add files to zip object
  const zip = JSZip();
  await Promise.allSettled(await addMultipleFilesToZip(zip, downloadedFiles));

  return zip.generateAsync({ type: 'blob' }).then(content => {
    return downloadBlobFile(content, `${zipFilePrefix}-${getToday()}.zip`);
  });
};

/**
 * @typedef DocumentAVStatus
 * @type {object}
 * @property {?string} status - av status
 * @property {?string} message - message of the status
 * @property {?string} timestamp
 */

/**
 * @typedef DownloadDocument
 * @type {object}
 * @property {string} id - document ID
 * @property {string} filename - document file name
 * @property {string} file_extension - document extension
 * @property {boolean} checked - selected or not the document
 * @property {boolean} pinned - pinned or not the document
 * @property {DocumentAVStatus} av_status
 */

/** When the user selects multiple documents and attempts to download them we should check every file
 * by av_status and show notifications for infected and not yet scanned files, but user can download
 * only clean and files with password protected
 * @param {DownloadDocument[]} documents list of documents with av_status
 * @return {{cleanFiles: DownloadDocument[], passwordProtectedFiles: DownloadDocument[]}} an object with only
 * clean files and files with password protected
 */
export const getAVBatchFileToDownloadAndMaybeShowToasts = documents => {
  if (!documents?.length) {
    return { cleanFiles: [], passwordProtectedFiles: [] };
  }

  const { cleanFiles, passwordProtectedFiles, infectedFiles, notYetScannedFiles } = getMappedFilesByAVStatus(documents);

  // files with virus
  if (infectedFiles.length) {
    infectedFiles.forEach(({ filename }) => {
      showNotification(getDocumentToastSettings(filename, AV_STATUS.Infected));
    });
  }

  // files not scanned yet
  if (notYetScannedFiles.length) {
    notYetScannedFiles.forEach(({ filename }) => {
      showNotification(getDocumentToastSettings(filename, NOT_YET_SCANNED_AV_STATUS));
    });
  }
  return { cleanFiles, passwordProtectedFiles };
};

/**
 * @typedef InitialDocumentsByAVStatuses
 * @type {object}
 * @property {DownloadDocument[]} cleanFiles - files that have all been scanned and cleared of virus
 * @property {DownloadDocument[]} infectedFiles - files has a virus
 * @property {DownloadDocument[]} passwordProtectedFiles - files is password protected
 * @property {DownloadDocument[]} notYetScannedFiles - files without av_status.status or has 'Unscannable' status but not password protected
 */

/**
 *
 * @param {DownloadDocument[]} documents list of documents with av_status
 * @return {InitialDocumentsByAVStatuses} an object with only clean and files with password protected
 */
const getMappedFilesByAVStatus = documents => {
  const initialDocumentsByAVStatuses = {
    cleanFiles: [],
    infectedFiles: [],
    passwordProtectedFiles: [],
    notYetScannedFiles: [],
  };

  return documents.reduce((acc, document) => {
    const { status, message } = document.av_status;

    if (!status) {
      return { ...acc, notYetScannedFiles: [...acc.notYetScannedFiles, document] };
    }

    if (status === AV_STATUS.Unscannable) {
      // unscannable file and password protected
      if (message === AV_MESSAGE_PASSWORD_PROTECTED) {
        return { ...acc, passwordProtectedFiles: [...acc.passwordProtectedFiles, document] };
      }
      // unscannable file
      return { ...acc, notYetScannedFiles: [...acc.notYetScannedFiles, document] };
    }

    // file scanned and cleared of viruses
    if (status === AV_STATUS.Disabled || status === AV_STATUS.Clean) {
      return { ...acc, cleanFiles: [...acc.cleanFiles, document] };
    }

    // file has a virus
    if (status === AV_STATUS.Infected) {
      return { ...acc, infectedFiles: [...acc.infectedFiles, document] };
    }

    return acc;
  }, initialDocumentsByAVStatuses);
};

/**
 * Used in the sort method Array.prototype.sort() to compare
 * and alphabetize a list of objects by property name
 * @param {object} a the first element for comparison
 * @param {object} b the second element for comparison
 * @param {string} propertyToCompare property of the object by which we compare
 * @returns {number}
 */
export const compareAndAlphabetize = (a, b, propertyToCompare) => {
  if (a[propertyToCompare] < b[propertyToCompare]) {
    return -1;
  }
  if (a[propertyToCompare] > b[propertyToCompare]) {
    return 1;
  }
  return 0;
};

/**
 * @typedef MergeErrorTypeAndTextData
 * @type {object}
 * @property {string} type
 * @property {string} errorText
 */

/**
 * Use it to get the additional data to show the error toast
 * @param {object|string} error
 * @return {MergeErrorTypeAndTextData}
 */
export const getMergeErrorTypeAndText = error => {
  const { errorText, isNetworkError } = getErrorInformation(error);
  return {
    type: isNetworkError ? MERGE_DOCUMENTS_TOAST.NetworkError : MERGE_DOCUMENTS_TOAST.Error,
    errorText,
  };
};

/**
 * Show the element with styles for confirmation modal description
 * @param {Object[]} documents - documents list
 * @param {boolean} isContinueAvailable  when the length of the documents is more than one file
 * @param {DocumentAVStatus} avStatusType  av_status of the document
 * @param {boolean} hideCount  when we should hide the count of tht documents in the description
 * @return JSX.Element|null
 */
const getMergeDocumentModalConfirmDescription = (documents, isContinueAvailable, avStatusType, hideCount) => {
  if (!documents.length) {
    return null;
  }

  const documentsNameList = (
    <>
      {MERGE_DOCUMENTS_DESCRIPTION_BY_AV_STATUS[avStatusType]}
      <div
        className={cx('ModalConfirmDocumentBodyDescription', 'ModalConfirmDocumentBodyConfirmTextBottom', {
          ModalConfirmDocumentBodyConfirmDescriptionHideCount: hideCount,
        })}
      >
        {documents.map(({ filename, id }) => (
          <div className="ModalConfirmDocumentNameWithEllipsis" key={id}>
            {filename}
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      {!hideCount && getMergeDocumentModalConfirmCount(documents.length, isContinueAvailable)}
      {hideCount ? <div>{documentsNameList}</div> : documentsNameList}
    </>
  );
};

/**
 * Show the element with styles for confirmation modal description
 * @param {number} count the count of the documents
 * @param {boolean} isContinueAvailable  when the length of the documents is more than one file
 * @param {boolean} showSum  when we want to show sum of the files with different av_statuses
 * @return JSX.Element
 */
const getMergeDocumentModalConfirmCount = (count, isContinueAvailable, showSum) => {
  const confirmModalCount = (
    <>
      <span className="ModalConfirmDocumentName">{count}</span> of the selected documents{' '}
      {isContinueAvailable ? 'will not' : 'cannot'} be merged.{' '}
    </>
  );
  return showSum ? (
    <div className="ModalConfirmDocumentBodyConfirmTextBottom">{confirmModalCount}</div>
  ) : (
    confirmModalCount
  );
};

export const getMergeDocumentModalConfirmOptions = (documents, onConfirmMerge, close) => {
  const { cleanFiles, passwordProtectedFiles, infectedFiles, notYetScannedFiles } = documents;
  // we can merge two and more files
  const isContinueAvailable = cleanFiles.length > 1;
  const filesWithDifferentAvStatus = [
    passwordProtectedFiles.length,
    infectedFiles.length,
    notYetScannedFiles.length,
  ].filter(el => el);
  // we check if our files has different av_status to show different text
  const isFilesWithDifferentAvStatus = filesWithDifferentAvStatus.length > 1;

  return {
    confirmText: isContinueAvailable ? 'Yes, continue' : 'Okay',
    cancelText: isContinueAvailable ? 'No, go back' : '',
    theme: 'primary',
    width: 611,
    header: 'Merge Documents',
    onConfirm: () => {
      isContinueAvailable && onConfirmMerge(cleanFiles.map(({ id }) => id));
      close();
    },
    children: (
      <>
        {isFilesWithDifferentAvStatus &&
          getMergeDocumentModalConfirmCount(
            filesWithDifferentAvStatus.reduce((a, b) => a + b, 0),
            isContinueAvailable,
            true,
          )}
        {getMergeDocumentModalConfirmDescription(
          infectedFiles,
          isContinueAvailable,
          AV_STATUS.Infected,
          isFilesWithDifferentAvStatus,
        )}
        {getMergeDocumentModalConfirmDescription(
          passwordProtectedFiles,
          isContinueAvailable,
          OTHERS_AV_STATUS.PasswordProtected,
          isFilesWithDifferentAvStatus,
        )}
        {getMergeDocumentModalConfirmDescription(
          notYetScannedFiles,
          isContinueAvailable,
          OTHERS_AV_STATUS.NotYetScannedFiles,
          isFilesWithDifferentAvStatus,
        )}
        <p className={cx('ModalConfirmDocumentBodyDescription', 'ModalConfirmDocumentBodyConfirmText')}>
          {isContinueAvailable
            ? 'Are you sure you want to proceed?'
            : 'Please try to merge again without this document.'}
        </p>
      </>
    ),
  };
};

/** Get the applicant from the list of the applicants by person id
 * @param {Applicant[]} [applicants] List of Applicants
 * @param {string} personId  Applicant's person identifier
 * @returns {Applicant|null} */
export const getApplicantByPersonId = (personId, applicants) =>
  applicants?.find(({ person }) => person.identifier === personId) || null;

/** Download the file
 * @param {Document|DocumentVersion} docOrVersion the file to download
 * @param {function} setTimeoutError the amount of time to wait to download the file
 */
export const downloadFile = async (docOrVersion, setTimeoutError) => {
  const { isDownloadAllowed, filename, isInfected } = docOrVersion;
  if (!isDownloadAllowed) {
    showNotification(getDocumentToastSettings(filename, isInfected ? AV_STATUS.Infected : 'NotYetScanned'));
    return;
  }

  try {
    const fileBlob = await downloadThenMaybeConvertDocument(docOrVersion);
    downloadBlobFile(fileBlob, filename);
    showNotification(getDocumentToastSettings(filename, AV_STATUS.Clean));
  } catch (error) {
    setTimeoutError(error);
  }
};
