import React, { useMemo } from 'react';
import { Modal, Button } from '@hometap/htco-components';
import { useMutation } from '@apollo/client';
import { SEND_HOMEOWNER_TASKS_REMINDER_EMAIL } from '../data/mutations';
import { formatSingularOrPluralTodoCopy } from '../todoUtils';

const SuccessModalBody = ({ onClose, openTodos }) => (
  <>
    <h3 className="TodosConfirmSendReminderModalHeading">Success!</h3>
    <p>Email reminder sent for {formatSingularOrPluralTodoCopy(openTodos, 'open')}.</p>
    <div className="TodosConfirmSendReminderModalFooter">
      <Button onClick={onClose}>Done</Button>
    </div>
  </>
);

const ErrorModalBody = ({ onClose }) => (
  <>
    <h3 className="TodosConfirmSendReminderModalHeading">Oops!</h3>
    <p>Failed to send the reminder email to the homeowner.</p>
    <div className="TodosConfirmSendReminderModalFooter">
      <Button theme="danger" onClick={onClose}>
        Done
      </Button>
    </div>
  </>
);

const TodosConfirmSendReminderModal = ({ on, onClose, openTodos, trackId }) => {
  const [sendHomeownerTasksReminderEmail, { reset, error, loading, data, called }] = useMutation(
    SEND_HOMEOWNER_TASKS_REMINDER_EMAIL,
  );
  const isSuccess = useMemo(() => !!data?.sendHomeownerTasksReminderEmail?.ok, [data]);
  const isError = !!error;
  const handleClose = () => {
    onClose();
    reset();
  };
  return (
    <Modal open={on} width={600} onClose={handleClose} allowClose={!called || isSuccess || isError}>
      {() => {
        if (isError) {
          return <ErrorModalBody onClose={handleClose} />;
        }
        if (isSuccess) {
          return <SuccessModalBody onClose={handleClose} openTodos={openTodos} />;
        }
        return (
          <>
            <h3 className="TodosConfirmSendReminderModalHeading">Send Reminder</h3>
            <p>
              This will send the homeowner an email for {formatSingularOrPluralTodoCopy(openTodos, 'open')}. Are you
              sure you want to do this?
            </p>
            <div className="TodosConfirmSendReminderModalFooter">
              {!called && (
                <Button className="TodosConfirmSendReminderModalCancel" onClick={handleClose} theme="link">
                  Cancel
                </Button>
              )}
              <Button
                loading={loading}
                onClick={() => {
                  sendHomeownerTasksReminderEmail({ variables: { trackId } });
                }}
              >
                Send Reminder
              </Button>
            </div>
          </>
        );
      }}
    </Modal>
  );
};

export default TodosConfirmSendReminderModal;
