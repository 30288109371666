import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBlocker } from './useBlocker';

/**
 *
 * @param {boolean} shouldBlockNavigation
 * @returns
 */
export function useCallbackPrompt(shouldBlockNavigation) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    const preventUnload = event => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    };

    window.addEventListener('beforeunload', preventUnload);
    return () => window.removeEventListener('beforeunload', preventUnload);
  }, [shouldBlockNavigation]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation && lastLocation.location?.pathname) {
      navigate(lastLocation.location.pathname);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
  }, [navigate, confirmedNavigation, lastLocation]);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    nextLocation => {
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation?.location?.pathname !== location?.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  useBlocker(handleBlockedNavigation, shouldBlockNavigation);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
