import { getTodoNotes, TODO_NOTES_OTHER_OPTION } from '../data/constants/todoForm';

/**
 * @typedef Option
 * @type {object}
 * @property {string} value
 * @property {string} label
 */
/** Get the todoNotes list extended by kind
 * @typedef GetTodoNotesOptions
 * @type {object}
 * @property {string} [kind]
 * @property {Option[]} [startOptions]
 * @property {Option[]} [endOptions]
 * @property {boolean} [isWithOtherOption]
 * @return {Option[]} The list of the homeownerTodo reasons options
 */
export const getTodoNotesOptions = ({
  kind = 'default',
  startOptions,
  endOptions,
  isWithOtherOption = true,
  taskKinds = {},
}) => {
  const homeownerTodoReasonsOptions = (getTodoNotes(taskKinds)[kind] || getTodoNotes(taskKinds)['default']).map(
    option => ({
      label: option,
      value: option,
    }),
  );
  if (startOptions?.length) {
    homeownerTodoReasonsOptions.unshift(...startOptions);
  }
  if (endOptions?.length) {
    homeownerTodoReasonsOptions.push(...endOptions);
  }
  if (isWithOtherOption) {
    homeownerTodoReasonsOptions.push(TODO_NOTES_OTHER_OPTION);
  }
  return homeownerTodoReasonsOptions;
};
