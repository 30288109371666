// Reggora order status labels
export const REGGORA_STATUS_LABELS = {
  PENDING_APPROVAL: 'Pending Approval',
  WAITING_FOR_PAYMENT: 'Waiting for Payment',
  FINDING_APPRAISERS: 'Finding Appraisers',
  ACCEPTED: 'Accepted',
  INSPECTION_SCHEDULED: 'Inspection Scheduled',
  INSPECTION_COMPLETED: 'Inspection Completed',
  UNDER_REVIEW: 'Under Review',
  SUBMITTED: 'Submitted',
  REVISION_SUBMITTED: 'Revision Submitted',
  REVISION_REQUESTED: 'Revision Requested',
  ON_HOLD: 'On Hold',
  ATTENTION_REQUIRED: 'Attention Required',
  TYPO_CANCELLED: 'Canceled',
  CANCELLED: 'Cancelled',
};

// Home appraisal types
export const APPRAISAL_TYPES = {
  TRADITIONAL: 'physical_appraisal',
  VIRTUAL: 'virtual_appraisal',
  CURBSIDE: 'curbside_appraisal',
  HOMEOWNER_PROVIDED: 'ho_provided_appraisal',
  ASSISTED: 'assisted_appraisal',
  OTHER: 'other',
};

// Reggora product numbers
export const PRODUCT_NUMBERS_TRADITIONAL = ['1004', '1073', '1025', '1004C'];

export const getReggoraLink = (orderId, isProd) => {
  if (isProd) {
    return `https://lender.reggora.com/orders/${orderId}/`;
  }
  return `https://lender-staging.reggora.com/orders/${orderId}/`;
};

export const extractFormNumber = product => {
  // Match the number inside parentheses using regular expression
  // ex: "Uniform Residential Appraisal (1004)" -> "1004"
  // ex: "Manufactured Home (1004C)" -> "1004C"
  const match = product.match(/\((\d+[A-Z]?)\)/);

  // If match found, return the number, otherwise return null
  return match ? match[1] : null;
};
