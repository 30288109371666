import React from 'react';
import PropTypes from 'prop-types';

import './SidebarDetailSection.scss';

/**
 * @typedef SidebarDetailSectionParams
 * @type {object}
 * @property {string} title text that should be the header
 * @property {JSX.Element} children
 */
/**
 * Wrapper component for ARP sidebar details section to make formatting and layout consistent
 * @param {SidebarDetailSectionParams} params
 * @returns {JSX.Element}
 */
export const SidebarDetailSection = ({ title, children }) => (
  <div>
    <h5 className="SidebarDetailSectionTitle">{title}</h5>
    <div className="SidebarDetailSectionContent">{children}</div>
  </div>
);

SidebarDetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
