import React from 'react';
import { Icon } from '@hometap/htco-components';

import './AlertError.scss';

// TODO: Move to Component Library and support other states
const AlertError = ({ error }) => {
  return (
    <span className="AlertError">
      <Icon name="error" />
      {error}
    </span>
  );
};

export default AlertError;
