export const APP_REVIEW_ANCHORS = {
  ADVERSE_EVENTS: '#adverse-event-section',
  BACKGROUND_CHECKS: '#background-checks-section',
  FLOOD_ZONE: '#flood-zone-section',
  HOA: '#hoa-section',
  HOME_VALUATION_STATUSES: '#home-valuation-statuses-section',
  INSURANCE_POLICIES: '#insurance-policies-section',
  INVESTMENT_INCREASE: '#investment-increase-section',
  LIENS_AND_PAYDOWNS: '#liens-and-paydowns-section',
  MORTGAGE_LIENS: '#mortgage-liens-section',
  PROPERTY_REPORT: '#property-report-section',
  PROPERTY_TAX: '#property-tax-section',
  RENTAL_HISTORY: '#rental-history-section',
  TITLE_MEMBERS: '#title-members-section',
  UNDERWRITING: '#underwriting-section',
};
