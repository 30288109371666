import React from 'react';
import PropTypes from 'prop-types';

import ActionsTooltip from 'components/Tooltip/ActionsTooltip';

import cx from 'classnames';
import '../ApplicantDetailsSidebarController/ApplicantDetailsSidebarController.scss';

const SidebarDetailsDisplayValueOrNone = ({ value, label, actions = [] }) => (
  <div className={cx({ InlineLabelValue: !!value })}>
    <span className="SidebarDetailContentLabel">{`${label}:`} </span>
    {value ? (
      <ActionsTooltip actions={actions}>
        <strong>{value}</strong>
      </ActionsTooltip>
    ) : (
      <strong>None</strong>
    )}
  </div>
);

SidebarDetailsDisplayValueOrNone.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType, PropTypes.object]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      onEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      value: PropTypes.node,
    }),
  ),
};

export default SidebarDetailsDisplayValueOrNone;
