import cx from 'classnames';

/**
 * SliderFormSection component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.label - The label for the slider form field.
 * @param {ReactNode} props.field - The content of the slider form field, either static information or an interactive input.
 * @param {string} props.className - Additional classes to apply to the component.
 * @returns {JSX.Element} The rendered SliderFormSection component.
 
 */
const SliderFormField = ({ label, field, className }) => {
  return (
    <div className={cx('flex flex-col gap-[4px]', className)}>
      <strong className="text-neutral-dark-100">{label}</strong>
      {field}
    </div>
  );
};

export default SliderFormField;
