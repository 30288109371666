/**
 * SliderForm component for fields that just display text to the user (i.e., not interactable form inputs, just informative text)
 *
 * @param {string} text - The text to display.
 * @returns {JSX.Element} The rendered SliderFormText component.
 */
const SliderFormText = ({ text }) => {
  return <div className="text-neutral-dark-75">{text}</div>;
};

export default SliderFormText;
