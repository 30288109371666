import React from 'react';
import cx from 'classnames';
import './AVStatusSmall.scss';

/**
 * @typedef AVStatusSmallParams
 * @type {object}
 * @property {import('../../documentRequests').DocumentOrVersion} viewDocumentOrVersion
 */

/** Document Anti-virus status component
 * @param {AVStatusSmallParams} viewDocumentOrVersion
 * @param {string} [extraClassName]
 * @returns {JSX.Element}
 *    -   Fetching Document/Version               ->  null
 *    -   File is Clean or Anti-Virus Disabled    ->  null
 *    -   File is Infected, Encrypted, Unscanned  ->  Anti-Virus Status Small component
 */
export default function AVStatusSmall({ viewDocumentOrVersion, extraClassName }) {
  const { isEncrypted, isInfected, isUnscanned } = viewDocumentOrVersion || {};

  let message;
  if (isEncrypted) {
    message = 'Password Protected';
  } else if (isInfected) {
    message = 'Virus Detected';
  } else if (isUnscanned) {
    message = 'Unscanned';
  } else {
    // short-circuit
    return null;
  }

  return <span className={cx('AVStatusSmall', extraClassName, { isEncrypted })}>{message}</span>;
}
