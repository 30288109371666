import { Icon, Tooltip } from '@hometap/htco-components';

// NOTE: may need to expose via API
export const ACCEPTED_FILE_TYPE_AND_EXTENSIONS = {
  'image/jpeg': [],
  'image/png': [],
  'image/jpg': [],
  'image/heif': [],
  'image/heic': [],
  'image/heic-sequence': [],
  'image/heif-sequence': [],
  'application/pdf': [],
  'application/zip': [],
};

export const ACCEPTED_IMAGES_FILE_EXTENSIONS = ['jpg', 'png', 'jpeg', 'heic', 'heif'];
export const ACCEPTED_IMAGES_FILE_TYPE = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/heif',
  'image/heic',
  'image/heic-sequence',
  'image/heif-sequence',
];
export const ACCEPTED_VIEWABLE_FILE_EXTENSIONS = ['pdf', 'jpg', 'png', 'jpeg', 'heic', 'heif'];
export const ACCEPTED_DOWNLOADABLE_FILE_EXTENSIONS = ['pdf', 'jpg', 'png', 'jpeg', 'heic', 'heif', 'zip'];

// This array corresponds to available specific file extensions in fortawesome
export const ACCEPTED_FILE_EXTENSION_ICONS = [
  'pps',
  'doc',
  'zip',
  'xls',
  'txt',
  'rtf',
  'ppt',
  'key',
  'pdf',
  'png',
  'jpg',
];

export const MAX_UPLOAD_SIZE_BYTES = 5000 * 10000; // 50MB
export const MAX_BULK_UPLOAD_FILE_COUNT = 20;

export const OTHER_DOCUMENT_TYPE = 'other';
export const DOCUMENT_VISIBILITY = {
  internal: 'internal',
  both: 'both',
};

export const AV_STATUS = {
  Disabled: 'Disabled',
  Unscannable: 'Unscannable',
  Clean: 'Clean',
  Infected: 'Infected',
};

export const OTHERS_AV_STATUS = {
  PasswordProtected: 'passwordProtected',
  NotYetScannedFiles: 'notYetScannedFiles',
};

export const MERGE_DOCUMENTS_DESCRIPTION_BY_AV_STATUS = {
  [AV_STATUS.Infected]: 'These files contain a virus.',
  [OTHERS_AV_STATUS.NotYetScannedFiles]: 'These files have not been scanned for virus protection yet.',
  [OTHERS_AV_STATUS.PasswordProtected]:
    'These files are password protected and have not been scanned for virus protection.',
};

export const MERGE_DOCUMENTS_TOAST = {
  Success: 'merge-success',
  Error: 'merge-error',
  NetworkError: 'merge-network-error',
};

export const AV_MESSAGE_PASSWORD_PROTECTED = 'File is Encrypted.';

/**
 *
 * @param {string} option
 * @returns
 */
export const DOCUMENT_SET_FILE_TYPE_OPTIONS = option => {
  return [
    { label: `PDF ${option === 'PDF' ? '(Default)' : ''}`, value: 'PDF' },
    { label: `ZIP ${option === 'ZIP' ? '(Default)' : ''}`, value: 'ZIP' },
  ];
};

export const DOCUMENT_SET_VERSION_INCLUSION_VALUES = {
  PINNED: 'PINNED',
  PRIMARY: 'PRIMARY',
  PINNED_PRIMARY: 'PINNED_PRIMARY',
  ALL: 'ALL',
};

export const DOCUMENT_SET_VERSION_INCLUSION_OPTIONS = [
  {
    label: (
      <>
        Pinned only{' '}
        <Tooltip content="Document set would include all versions of pinned documents for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED,
  },
  {
    label: (
      <>
        Primary Version Only{' '}
        <Tooltip content="Document set would include only primary versions of included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_VERSION_INCLUSION_VALUES.PRIMARY,
  },
  {
    label: (
      <>
        Pinned, primary{' '}
        <Tooltip content="Document set would include only the primary versions of pinned documents for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_VERSION_INCLUSION_VALUES.PINNED_PRIMARY,
  },
  {
    label: (
      <>
        All{' '}
        <Tooltip content="Document set would include all documents and versions for included document types.">
          <Icon name="info" />
        </Tooltip>
      </>
    ),
    value: DOCUMENT_SET_VERSION_INCLUSION_VALUES.ALL,
  },
];

export const DEFAULT_DOCUMENT_SET_FORM = {
  documentSetType: null,
  documentSetName: '',
  fileType: DOCUMENT_SET_FILE_TYPE_OPTIONS('PDF').value,
  includeVersion: DOCUMENT_SET_VERSION_INCLUSION_OPTIONS[3].value, // ALL
};

export const DOCUMENTS_TYPE = {
  Other: 'other',
};

export const NOT_YET_SCANNED_AV_STATUS = 'NotYetScanned';

export const DEFAULT_DELETE_MODAL_CONFIRM_OPTIONS = {
  confirmText: 'Yes, delete',
  cancelText: 'No, go back',
  theme: 'danger',
  width: 611,
};

export const DEFAULT_DOWNLOAD_MODAL_CONFIRM_OPTIONS = {
  confirmText: 'Yes, download',
  cancelText: 'No, go back',
  theme: 'primary',
  width: 611,
};
