import { MuiTextarea } from '@hometap/htco-components';
import { useUpdateSectionForm } from 'apps/track-details/ApplicationReview/hooks/useSpecificContentQuery';
import { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { CREATE_TAX_LIEN, UPDATE_TAX_LIEN } from 'apps/track-details/tasks/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import { useLienForm } from '../hooks/useLienForm';
import { useInitialLienForm } from '../hooks/useInitialLienForm';
import { PaydownWrapper } from './PaydownWrapper/PaydownWrapper';
import { getIsRequiredPayoffMissing } from '../LiensAndPaydownsController';
import useConfigurations from 'hooks/useConfigurations';
import { getSelectionYesNoUnknownInitialValue, getSelectionYesNoUnknownSelectedValue } from './SelectionYesNoUnknown';
import {
  LienFormData,
  LienNode,
  LienReportFieldsTouched,
  Configurations,
  MutationResponse,
  TaxLienFormData,
  TaxLienMutationData,
  TaxLienMutationVariables,
  UseTaxLienFormParams,
  UseTaxLienInitialForm,
  LienPositionError,
} from '../types/types';
import {
  getErrorNotificationDescription,
  getLienPropertyReportFields,
  getSuccessNotificationDescription,
} from '../utils/liens';
import LienPropertyReportFields from './LienPropertyReportFields';
import { LIEN_TYPE } from './data/constants';

const initialFormDataFromLienNode = (lien: any) => ({
  ...lien,
  kind: lien?.kind?.toLowerCase(),
  onPropertyReport: getSelectionYesNoUnknownInitialValue(lien?.onPropertyReport),
});

export const TaxLienReviewForm = ({
  disabled,
  lien,
  liensKindOptions,
  lienKind,
  trackId,
  lienPositionError,
  addNewPaydown,
  deletePaydown,
  setLienReportFieldsTouched,
  lienReportFieldsTouched,
  homeValue,
}: LienFormData) => {
  const lienType = liensKindOptions.find(valLabelPair => valLabelPair.value === lienKind.toLowerCase())!;
  const { lienKindsRequiringPayoff } = useConfigurations() as Configurations;
  const { updateSectionFormById } = useUpdateSectionForm();
  const {
    mutateTaxLien,
    initialFormData,
    registerField,
    formData = {},
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
    showTriggeredPublishErrors,
  } = useTaxLienForm({ lien, trackId, lienReportFieldsTouched, lienPositionError });

  const { onPropertyReport } = formData;

  const lienId = lien?.identifier;

  const isPayoffRequired = getIsRequiredPayoffMissing(
    lienKindsRequiringPayoff,
    { ...lien, formData: { kind: lienType ? lienType.value : 'tax_lien' } },
    false,
  );

  useEffect(() => {
    updateSectionFormById(lienId, {
      complete: mutateTaxLien,
      initialFormData,
      formData,
      updateFormData,
      isValidForm: isFormValid,
      paydownId,
      showSubmitErrors,
    });
  }, [
    mutateTaxLien,
    updateSectionFormById,
    lienId,
    initialFormData,
    formData,
    updateFormData,
    isFormValid,
    paydownId,
    showSubmitErrors,
  ]);

  return (
    <>
      <LienPropertyReportFields
        label="Is this tax lien on the property report?"
        initialFormData={initialFormData}
        formData={formData}
        lienPositionError={lienPositionError}
        disabled={disabled}
        setLienReportFieldsTouched={setLienReportFieldsTouched}
        lienReportFieldsTouched={lienReportFieldsTouched}
        registerField={registerField}
        showTriggeredPublishErrors={showTriggeredPublishErrors}
        lienKind={lienKind}
        judgementOrTaxFieldsOnly={true}
        holderFieldLabel="Taxing authority"
      />
      <div className="FormRow">
        <MuiTextarea
          label="Notes (optional)"
          theme="outlined"
          width="100%"
          disabled={disabled}
          {...registerField('notes')}
        />
      </div>
      <PaydownWrapper
        paydown={lien?.paydown}
        trackId={trackId}
        lienKind={lienKind}
        disabled={disabled}
        addNewPaydown={addNewPaydown}
        deletePaydown={deletePaydown}
        lienId={lien?.identifier}
        isRequired={isPayoffRequired}
        homeValue={homeValue}
        onPropertyReport={onPropertyReport}
        updateLienFormData={updateFormData}
      />
    </>
  );
};

function useTaxLienForm({ lien, trackId, lienReportFieldsTouched, lienPositionError }: UseTaxLienFormParams) {
  const client = useApolloClient();

  const {
    registerField,
    formData,
    updateFormData,
    isFormValid,
    initialFormData,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  }: UseTaxLienInitialForm = useInitialLienForm(lien, initialFormDataFromLienNode);
  useLienForm(lien);

  const validateForm = (lienReportFieldsTouched: LienReportFieldsTouched) => {
    const { onPropertyReport } = formData;
    if (onPropertyReport === 'true') {
      const PROPERTY_REPORT_DEPENDENT_FIELDS = getLienPropertyReportFields(LIEN_TYPE.Tax, true);
      if (
        PROPERTY_REPORT_DEPENDENT_FIELDS.every(
          field =>
            !formData[field as keyof TaxLienFormData] &&
            !showTriggeredPublishErrors &&
            !lienReportFieldsTouched[lien.identifier]?.[field],
        )
      ) {
        return true;
      }
      if (lienPositionError[lien.identifier as keyof LienPositionError]) {
        return false;
      }
    }

    return isFormValid;
  };

  const mutateTaxLien = useCallback(
    async ({ formData = {} }: { formData: TaxLienFormData }) => {
      const { newLien, holder, lienDate, notes, onPropertyReport, originalAmount, position } = formData;
      const variables: TaxLienMutationVariables = {
        onPropertyReport: getSelectionYesNoUnknownSelectedValue(onPropertyReport),
        notes,
        trackId,
      };
      variables.lienId = lien.identifier;

      if (onPropertyReport === 'true') {
        variables.position = position;
        variables.originalAmount = parseFloat(originalAmount || '0').toFixed(2);
        variables.lienDate = lienDate;
        variables.holder = holder;
      }

      const MUTATION = newLien ? CREATE_TAX_LIEN : UPDATE_TAX_LIEN;

      try {
        const { data } = (await client.mutate({
          mutation: MUTATION,
          variables,
        })) as MutationResponse<TaxLienMutationData>;
        const [mutationData] = Object.values(data);
        const lienIdentifier = (Object.values(mutationData)[0] as Partial<LienNode>)['identifier'];
        showNotification({
          type: TOAST_TYPE.success,
          title: 'Success',
          description: getSuccessNotificationDescription(newLien, 'Tax Lien'),
        });
        return { newLienIdentifier: newLien && lienIdentifier ? lienIdentifier : undefined };
      } catch (error: any) {
        showNotification({
          type: TOAST_TYPE.error,
          title: 'Failed to save changes',
          description: error.networkError?.result?.errors[0]?.message
            ? error.networkError.result.errors[0].message
            : getErrorNotificationDescription(newLien, 'Tax Lien'),
        });
      }
    },
    [client, lien, trackId],
  );

  return {
    mutateTaxLien,
    initialFormData,
    registerField,
    formData,
    updateFormData,
    isFormValid: validateForm(lienReportFieldsTouched),
    paydownId: lien?.paydown?.identifier,
    setErrors,
    showSubmitErrors,
    showTriggeredPublishErrors,
  };
}
