import { useSectionFormById, useUpdateSectionForm } from '../../../hooks/useSpecificContentQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { convertNumericStringsToNumbers, getIsTaskFormChanged } from 'apps/track-details/utils/taskForms';

/**
 * Object representing an insurance policy.
 * @typedef Policy
 * @type {object}
 * @property {string} identifier - The unique identifier of the policy.
 * @property {string} agent - The name of the agent associated with the policy.
 * @property {string} agentContact - The contact information for the agent.
 * @property {Array} agentContactTypeOptions - The available contact types for the agent.
 * @property {string} agentEmail - The email address of the agent.
 * @property {string} agentPhone - The phone number of the agent.
 * @property {string} description - A description of the policy.
 * @property {number} dwellingAmount - The amount of coverage for the dwelling.
 * @property {string} kind - The type of policy.
 * @property {string} notes - Additional notes about the policy.
 * @property {number} orderIndex - The order index of the policy.
 * @property {string} policyNumber - The policy number.
 * @property {string} provider - The name of the insurance provider.
 * @property {string} renewalDate - The date the policy is set to renew.
 * @property {boolean} replacementCost - Whether the policy covers replacement cost.
 * @property {Object} document - The document associated with the policy.
 * @property {string} subSectionLabel - The label of the subsection the policy belongs to.
 * @property {number} selectorId - The ID of the selector associated with the policy.
 * @property {boolean} isPending - Whether the policy is pending.
 * @property {boolean} isDisabled - Whether the policy is disabled.
 */
/** Use a Policy Specific Form
 * @param {Policy} policy
 */
export function useInsuranceReviewForm(policy) {
  const policyId = policy?.identifier;

  const { updateSectionFormById } = useUpdateSectionForm();
  const { initialFormData, updateFormData, formData, isValidForm, prevFormData } = useSectionFormById(policyId);

  const policyFormData = prevFormData || initialFormData;

  useEffect(() => {
    if (policyFormData) {
      updateFormData?.(policyFormData || {});
    }
  }, [policyFormData, updateFormData]);

  const isFormChanged = useMemo(() => {
    return getIsTaskFormChanged(
      convertNumericStringsToNumbers(initialFormData),
      convertNumericStringsToNumbers(formData),
    );
  }, [formData, initialFormData]);

  // the function to rest current opened form data to initial state
  const resetData = useCallback(() => {
    if (initialFormData) {
      updateFormData(initialFormData);
    }
  }, [initialFormData, updateFormData]);

  useEffect(() => {
    updateSectionFormById(policyId, {
      isValidForm,
      isFormChanged,
      resetData,
    });
  }, [isValidForm, policyId, isFormChanged, resetData, updateSectionFormById]);
}
