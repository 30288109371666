const ACTION_TOOL_TIP_OPTIONS = {
  email: 'EMAIL',
  phone: 'PHONE',
  copy: 'COPY',
  edit: 'EDIT',
  link: 'LINK',
  magicLink: 'MAGIC_LINK',
};

export default ACTION_TOOL_TIP_OPTIONS;
