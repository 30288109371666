import React, { useEffect, useState } from 'react';
import { FormError, Loader } from '@hometap/htco-components';
import { PreviewDocument } from 'apps/track-details/Documents/components/PreviewDocument/PreviewDocument';

import './TaskDocuments.scss';
import { PreviewDocumentTile } from 'apps/track-details/Documents/components/PreviewDocumentTile/PreviewDocumentTile';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import cx from 'classnames';
import { useUpdateSpecificTaskContent } from '../../hooks/useSpecificContentQuery';
import useTaskDocuments from '../../hooks/useTaskDocuments';
import { useTrackApplicants } from 'apps/track-details/Documents/hooks/useTrackApplicants';
import TaskDocumentsDrawer from '../TaskDocumentsDrawer/TaskDocumentsDrawer';

const TASK_DOCS_TIMEOUT_FOR_RESIZING_HACK = 180;

/**
 * @typedef TaskDocumentsParams
 * @type {object}
 * @property {string} trackId Track ID
 * @property {string} taskId Task ID
 * @property {boolean} isPreviewDocumentOpen Is the document viewer open?
 * @property {(boolean) => void} setIsPreviewDocumentOpen Set the document viewer open state
 * @property {boolean} isTaskCompleted  Is the task completed?
 * @property {boolean} shouldOpen Should the drawer open?
 * @property {(boolean)=> void} setShouldOpen Set the drawer open state
 * @property {boolean} isWithDrawer Should the documents be displayed in a drawer?
 * @property {"allTrackDocs"|"allDocsThatMatchTaskKind"|"allDocsLinkedToTask"} taskDocVariant
 */

/** Task Documents List View
 * @param {TaskDocumentsParams} params
 * @returns {JSX.Element}
 *   -  Fetching Documents        -> Loader
 *   -  Documents Fetch Error     -> Error Message
 *   -  No Documents Found        -> Empty Message
 *   -  Documented Fetched        -> Document Tiles which open the viewer when clicked
 *   -  Document Tile Selected    -> Document Viewer
 */
export default function TaskDocuments({
  trackId,
  taskId,
  isPreviewDocumentOpen,
  setIsPreviewDocumentOpen,
  isTaskCompleted,
  shouldOpen,
  setShouldOpen,
  isWithDrawer,
  taskDocVariant,
}) {
  // Applicants
  const useTrackApplicantsResults = useTrackApplicants(trackId);

  // Documents
  const { documents, documentsLoading, documentsError, executeGetTaskDocuments } = useTaskDocuments(
    trackId,
    taskId,
    taskDocVariant,
    useTrackApplicantsResults.applicants,
  );
  const [viewDocumentIndex, setViewDocumentIndex] = useState(0);

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);

  useEffect(() => {
    updateSpecificTaskById(taskId, { documents, loading: documentsLoading });
  }, [documents, taskId, updateSpecificTaskById, documentsLoading]);

  useBroadcastChannel({
    channelName: 'DOCS_UPDATED',
    onMessage: async () => {
      await executeGetTaskDocuments(taskId);
    },
  });

  useEffect(() => {
    let timeoutId;
    // The size of the document is determined incorrectly if we collapse it and open the document for viewing.
    // This is because the document is being rendered before the animation has finished. We are adding a timeout to fix this
    if (isWithDrawer && shouldOpen) {
      if (isPreviewDocumentOpen) {
        timeoutId = setTimeout(() => {
          setIsDocumentsOpen(true);
        }, TASK_DOCS_TIMEOUT_FOR_RESIZING_HACK);
      } else {
        setIsDocumentsOpen(true);
      }
    }
    if (!shouldOpen && isWithDrawer) {
      clearTimeout(timeoutId);
      setIsDocumentsOpen(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isWithDrawer, isPreviewDocumentOpen, shouldOpen, setIsDocumentsOpen]);

  let child;
  if (documentsLoading) {
    child = <Loader type="dot-pulse" size="large" />;
  } else if (isTaskCompleted) {
    child = <p>Task completed</p>;
  } else if (documentsError) {
    child = <FormError error="Failed to retrieve task documents." />;
  } else if (documents.length === 0) {
    child = <p>No documents yet!</p>;
  } else if (isPreviewDocumentOpen) {
    child = (
      <PreviewDocument
        isEmbedded
        isFullScreenEmbedded
        documentLoading={documentsLoading}
        documentError={documentsError}
        isArrows={false}
        isSmall={true}
        isBack={true}
        handleBack={() => {
          setViewDocumentIndex(0);
          setIsPreviewDocumentOpen(false);
        }}
        positionActionBar="top"
        {...{
          documents,
          viewDocumentIndex,
          setViewDocumentIndex,
        }}
      />
    );
  } else {
    child = (
      <div className="PreviewDocumentInner">
        {documents.map((doc, index) => {
          return (
            <PreviewDocumentTile
              key={index}
              viewDocumentOrVersion={doc}
              onClick={() => {
                setViewDocumentIndex(index);
                setIsPreviewDocumentOpen(true);
              }}
            />
          );
        })}
      </div>
    );
  }

  const documentsContent = (
    <div
      className={cx('TaskDocuments', {
        isPreviewDocumentOpen,
        isWithDrawer,
      })}
    >
      {!documentsLoading && <h2 className="TaskDocumentsHeader">Documents</h2>}
      {child}
    </div>
  );

  return isWithDrawer ? (
    <TaskDocumentsDrawer shouldOpen={shouldOpen} setShouldOpen={setShouldOpen}>
      {isDocumentsOpen && documentsContent}
    </TaskDocumentsDrawer>
  ) : (
    documentsContent
  );
}
