import { Icon, ModalConfirm } from '@hometap/htco-components';
import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './FormWrapperWithDeleteConfirmModal.scss';

/**
 * @component
 * @param {boolean} isDisabled - Indicates whether deletion is disabled.
 * @param {boolean} canDelete - Indicates whether the card can be deleted.
 * @param {boolean} canShowModal - Indicates whether we can show confirmation modal.
 * @param {string} type - The type of object.
 * @param {string} header - The header.
 * @param {string} [identifier] - The identifier of the card.
 * @param {function} onDelete - The function to be called when the card is deleted.
 * @param {string} title - The title of the card.
 * @param {ReactNode} children - The content of the card.
 * @returns {JSX.Element} The FormWrapperWithDeleteConfirmModal component.
 */
const FormWrapperWithDeleteConfirmModal = ({
  isDisabled = false,
  canDelete = true,
  canShowModal = true,
  type,
  identifier,
  onDelete,
  title,
  header,
  children,
}) => {
  const [showPrompt, setShowPrompt] = useState(false);

  const onIconDeleteHandler = () => {
    if (isDisabled) return;
    if (canShowModal) {
      setShowPrompt(true);
    } else {
      onDelete(identifier);
    }
  };

  const handleDeleteConfirmed = () => {
    setShowPrompt(false);
    onDelete(identifier, 'delete');
  };

  return (
    <div className="FormWrapper">
      <div className="FormHeader">
        <h3 className="FormTitle">{title}</h3>
        {canDelete && (
          <div className={cx('DeleteIcon', { isDisabled })} onClick={onIconDeleteHandler}>
            <Icon name="icon-bin" />
          </div>
        )}
      </div>
      {children}
      {canShowModal && (
        <ModalConfirm
          open={showPrompt}
          header={header}
          confirmText="Yes, delete"
          cancelText="No, go back"
          onConfirm={handleDeleteConfirmed}
          onClose={() => setShowPrompt(false)}
          onCancel={() => setShowPrompt(false)}
          theme="danger"
          width={610}
        >
          You are about to delete {type.toLowerCase()}. This cannot be undone.
          <div className="AreYouSureText">Are you sure you want to proceed?</div>
        </ModalConfirm>
      )}
    </div>
  );
};

FormWrapperWithDeleteConfirmModal.propTypes = {
  isDisabled: PropTypes.bool,
  canDelete: PropTypes.bool,
  canShowModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  identifier: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormWrapperWithDeleteConfirmModal;
