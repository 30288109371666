import { gql } from '@apollo/client';

export const GET_TRACK_DATA = gql`
  query Track($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      identifier
      friendlyId
      floodZone
      appraisalType {
        label
        value
      }
      reviewStatus {
        value
      }
      compositeFicoData {
        value
        statusDisplay
      }
      mailingAddress {
        fullAddress
      }
      incomeWeightedRiskBand {
        label
        value
      }
      rolloutFlags
      relevantHomeValuation {
        identifier
        value
        valueLower
        valueUpper
        avmEligibility
        expirationDate
        messages
      }
      home {
        identifier
        propertyTypeLabel
        residenceTypeLabel
        address {
          fullAddress
          identifier
        }
      }
      regionDefaults {
        spouseSignatureRequiredInfo
      }
      applicants {
        id
        fullName
        isPrimaryApplicant
        phoneNumber
        birthDate
        ssn
        email
        maritalStatus
        ficoScore
        identifier
        governmentId {
          nameAsAppearsOnId
          identifier
        }
        riskBand {
          value
          expirationDate
          isNoDataResponse
          wasExperianNameExactMatch
          identifier
          errorMessage
          isFake
        }
      }
    }
  }
`;
