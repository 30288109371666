import { Accordion } from '@hometap/htco-components';
import { currencyWithCents, formatDecimal } from 'utils/numbers';
import { SidebarDetail } from '../../components';
import { DSCR_STATUS, FLOOD_INSURANCE_STATUS, HOA_FEE_STATUS } from '../../data/constants/rentalEligibility';
import './RentalEligibilitySidebarController.scss';

const RentalEligibilitySidebarController = ({ track }) => {
  const { debtServiceCoverageRatioInfo } = track;

  const renderDscr = () => {
    const { dscrValueStatus, dscrValue } = debtServiceCoverageRatioInfo;
    const { AVAILABLE, MISSING, PARTIALLY_CALCULATED } = DSCR_STATUS;
    const DscrValue = ({ children }) => (
      <strong className="RentalEligibilityDSCR" data-test-id="dscrValue">
        {children}
      </strong>
    );

    if (dscrValueStatus === MISSING) {
      return <DscrValue>(missing information)</DscrValue>;
    } else if (dscrValueStatus === PARTIALLY_CALCULATED) {
      return (
        <>
          <DscrValue>{formatDecimal(dscrValue, '0.00')}</DscrValue>
          &nbsp;(partially calculated)
        </>
      );
    } else if (dscrValueStatus === AVAILABLE) {
      return <DscrValue>{formatDecimal(dscrValue, '0.00')}</DscrValue>;
    }

    return null;
  };

  const renderFloodInsurance = () => {
    const { AVAILABLE, MISSING, NOT_APPLICABLE, UNKNOWN_ZONE } = FLOOD_INSURANCE_STATUS;
    const FloodInsuranceCostValue = ({ children }) => (
      <strong data-test-id="floodInsuranceCostValue">{children}</strong>
    );

    if (debtServiceCoverageRatioInfo.monthlyFloodInsuranceCostStatus === AVAILABLE) {
      return (
        <FloodInsuranceCostValue>
          {currencyWithCents(debtServiceCoverageRatioInfo.monthlyFloodInsuranceCost)}
        </FloodInsuranceCostValue>
      );
    } else if (debtServiceCoverageRatioInfo.monthlyFloodInsuranceCostStatus === MISSING) {
      return <FloodInsuranceCostValue>Missing insurance cost</FloodInsuranceCostValue>;
    } else if (debtServiceCoverageRatioInfo.monthlyFloodInsuranceCostStatus === UNKNOWN_ZONE) {
      return <FloodInsuranceCostValue>Missing flood zone info</FloodInsuranceCostValue>;
    } else if (debtServiceCoverageRatioInfo.monthlyFloodInsuranceCostStatus === NOT_APPLICABLE) {
      return <FloodInsuranceCostValue>Not in a flood zone</FloodInsuranceCostValue>;
    }
    return null;
  };

  const renderHomeownersInsurance = () => {
    const HomeownersInsuranceValue = ({ children }) => (
      <strong data-test-id="homeownersInsuranceCostValue">{children}</strong>
    );

    if (debtServiceCoverageRatioInfo.monthlyHomeownersInsuranceCost !== null) {
      return (
        <HomeownersInsuranceValue>
          {currencyWithCents(debtServiceCoverageRatioInfo.monthlyHomeownersInsuranceCost)}
        </HomeownersInsuranceValue>
      );
    }
    return <HomeownersInsuranceValue>Missing insurance cost</HomeownersInsuranceValue>;
  };

  const renderPropertyTax = () => {
    const PropertyTaxValue = ({ children }) => <strong data-test-id="propertyTaxValue">{children}</strong>;

    if (debtServiceCoverageRatioInfo.monthlyPropertyTaxCost !== null) {
      return (
        <PropertyTaxValue>{currencyWithCents(debtServiceCoverageRatioInfo.monthlyPropertyTaxCost)}</PropertyTaxValue>
      );
    }
    return <PropertyTaxValue>Missing tax cost</PropertyTaxValue>;
  };

  const renderHoaFee = () => {
    const { monthlyHoaFee, monthlyHoaFeeStatus } = debtServiceCoverageRatioInfo;
    const { AVAILABLE, MISSING, NOT_APPLICABLE } = HOA_FEE_STATUS;
    const HoaFeeValue = ({ children }) => <strong data-test-id="hoaFeeValue">{children}</strong>;

    if (monthlyHoaFeeStatus === AVAILABLE) {
      return <HoaFeeValue>{currencyWithCents(monthlyHoaFee)}</HoaFeeValue>;
    } else if (monthlyHoaFeeStatus === MISSING) {
      return <HoaFeeValue>Missing HOA fees</HoaFeeValue>;
    } else if (monthlyHoaFeeStatus === NOT_APPLICABLE) {
      return <HoaFeeValue>Not an HOA</HoaFeeValue>;
    }
    return null;
  };

  const renderLiens = () => {
    return debtServiceCoverageRatioInfo.relevantLiens?.length > 0
      ? debtServiceCoverageRatioInfo.relevantLiens.map((lien, index) => {
          const { kindLabel, principalAmount, interestAmount } = lien;
          return (
            <div key={`${lien.kindLabel}_${index}`}>
              <div>{`(${index + 1}) ${kindLabel}:`}</div>
              <div className="RentalEligibilityRow LienKind">
                <span className="RentalEligibilityLabel">Principal</span>
                <strong>{currencyWithCents(principalAmount)}</strong>
              </div>
              <div className="RentalEligibilityRow LienKind">
                <span className="RentalEligibilityLabel">Interest</span>
                <strong>{currencyWithCents(interestAmount)}</strong>
              </div>
            </div>
          );
        })
      : 'No relevant liens';
  };

  const renderRentalIncome = () => {
    const RentalIncomeValue = ({ children }) => <strong data-test-id="rentalIncomeValue">{children}</strong>;

    if (debtServiceCoverageRatioInfo.monthlyRentalIncome !== null) {
      return (
        <RentalIncomeValue>{currencyWithCents(debtServiceCoverageRatioInfo.monthlyRentalIncome)}</RentalIncomeValue>
      );
    }
    return <RentalIncomeValue>Missing rental income</RentalIncomeValue>;
  };

  const AccordionHeader = () => {
    return <span className="RentalEligibilityRow RentalEligibilityAccordionTitle">DSCR:&nbsp;{renderDscr()}</span>;
  };

  return (
    <SidebarDetail sidebarDetailTitle={'Rental eligibility'}>
      <div className="RentalEligibilitySidebarContainer">
        <Accordion header={<AccordionHeader />}>
          <div>
            <div className="RentalEligibilityRow">
              <span className="RentalEligibilityLabel">Rental income:</span>
              {renderRentalIncome()}
            </div>
          </div>

          <div>
            <div className="RentalEligibilitySectionTitle">Lien Costs</div>
            {renderLiens()}
          </div>

          <div>
            <div className="RentalEligibilitySectionTitle">Insurance Costs</div>
            <div className="RentalEligibilityRow">
              <span className="RentalEligibilityLabel">Flood insurance:</span>
              {renderFloodInsurance()}
            </div>
            <div className="RentalEligibilityRow">
              <span className="RentalEligibilityLabel">Homeowners Insurance:</span>
              {renderHomeownersInsurance()}
            </div>
          </div>

          <div className="RentalEligibilityRow">
            <span className="RentalEligibilityLabel">Property tax:</span>
            {renderPropertyTax()}
          </div>

          <div className="RentalEligibilityRow">
            <span className="RentalEligibilityLabel">HOA fee:</span>
            {renderHoaFee()}
          </div>

          <div className="RentalEligibilityFooter">Note: All dollar amounts are monthly</div>
        </Accordion>
      </div>
    </SidebarDetail>
  );
};

export default RentalEligibilitySidebarController;
