import axios from 'axios';
import Qs from 'qs';
import omit from 'lodash/omit';
import endsWith from 'lodash/endsWith';
import env from './env';
import { getPortalsUrl } from './links';

const handleTrailingSlash = config => {
  if (!endsWith(config.url, '/')) {
    config.url += '/';
  }
  return config;
};

// Return response data to make it quicker to access, but add full
// request object at __request in case it need to be accessed.
const handleResponseFormatting = response => {
  const data = response.data || {};
  if (typeof data === 'object' && data !== null) {
    data.__request = omit(response, 'data');
  }
  return data;
};

/** Get API Base depending on current url, amplify root domains do not support auth & cors
 * @param {string|undefined} version API version
 * @returns {string} API base URL
 */
const getApiBase = version => {
  if (process.env.REACT_APP_BACKEND_BASE) {
    return `${process.env.REACT_APP_BACKEND_BASE}api/${version || 'v1'}`;
  }
  return `${env.getBackendUrlForEnv()}api/${version || 'v1'}`;
};

// Cypress makes it difficult to test javascript redirects
// https://docs.cypress.io/guides/guides/web-security.html#JavaScript-Redirects
// So this lets use stub the replace and test the redirect
window.api = {
  redirect: url => {
    window.location.replace(url);
  },
};

// Get the global defaults for an axios instance
const getPortalsApiDefaults = version => {
  return {
    baseURL: getApiBase(version),
    withCredentials: true,
    xsrfCookieName: 'portalscsrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 20000, //20 second timeout for all requests
    paramsSerializer: params => {
      // repeat params format: { a: ['b', 'c'] }
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    },
  };
};

// Create API with authentication
const createApiWithAuth = version => {
  const api = axios.create(getPortalsApiDefaults(version));
  api.interceptors.request.use(handleTrailingSlash);
  api.interceptors.response.use(handleResponseFormatting, error => {
    // Globally handle all unauthenticated errors - redirect to portals login
    // This may need a more specific "authentication" issue
    if (error?.response?.status === 403) {
      window.api.redirect(getPortalsUrl());
    }
    return Promise.reject(error);
  });
  return api;
};

export const apiV1NoRedirect = axios.create(getPortalsApiDefaults());
apiV1NoRedirect.interceptors.request.use(handleTrailingSlash);
apiV1NoRedirect.interceptors.response.use(handleResponseFormatting);

export const getGraphQlUrl = () => {
  return getPortalsUrl('graphql/');
};

export const apiV1WithAuth = createApiWithAuth('v1');
export const apiV2WithAuth = createApiWithAuth('v2');

export const apiWithAuth = {
  v1: apiV1WithAuth,
  v2: apiV2WithAuth,
};

export default apiWithAuth;
