import { useSpecificContent, useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { TaskDetailMultipleFormsList } from '../../TaskDetailMultipleFormsList/TaskDetailMultipleFormsList';
import { GET_LIENS } from 'apps/track-details/tasks/data/queries/getLiens';
import { useMemo } from 'react';

export const MortgageReviewController = ({ task }) => {
  const { data: responseData, loading: responseDataLoading } = useSpecificContentQuery(GET_LIENS);
  const {
    specificTaskData: { documents = [], loading: documentsLoading },
  } = useSpecificContent(task.identifier);

  const dataWithDocuments = useMemo(
    () => responseData?.track?.liens?.filter(({ kind }) => kind.toLowerCase() === 'mortgage'),
    [responseData?.track?.liens],
  );

  const itemsWithDocumentIds = useMemo(() => {
    return dataWithDocuments
      ?.filter(({ document }) => document?.identifier)
      .map(({ document, identifier }) => ({ identifier, documentIds: document.identifier }));
  }, [dataWithDocuments]);

  if (responseDataLoading || documentsLoading) {
    return null;
  }

  return (
    <TaskDetailMultipleFormsList
      dataWithDocuments={dataWithDocuments}
      documents={documents}
      task={task}
      itemsWithDocumentIds={itemsWithDocumentIds}
    />
  );
};
