import React, { useEffect } from 'react';
import { Icon } from '@hometap/htco-components';
import cx from 'classnames';
import './SlideInFromRight.scss';

export const SlideInFromRight = ({ children, open, close, className = '', closable = true }) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
  }, [open]);

  return (
    <div className={cx('SlideInFromRight', className)}>
      <div className={cx('SlideInFromRightSlideIn', { isOpen: open, isClosed: !open })}>
        {closable && (
          <div onClick={close} className="SlideInFromRightCloseIcon">
            <Icon name="icon-close" size="lg" />
          </div>
        )}
        {children}
      </div>
      {/* Animated */}
      <div className={cx('SlideInFromRightCloseIconBackdrop', { isOpen: open, isClosed: !open })} onClick={close}>
        {/* clickable container to close slider */}
        {open && <div onClick={close} className={cx('SlideInFromRightClickable', { isOpen: open, isClosed: !open })} />}
      </div>
    </div>
  );
};
