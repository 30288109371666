import dayjs from 'dayjs';
import { isNil } from 'lodash';

const EMAIL_ERROR_MESSAGE = 'Please enter a valid email.';
export const emailValidator = (value, message = EMAIL_ERROR_MESSAGE) => {
  const regex =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  if (!regex.test(String(value).toLowerCase())) {
    return message;
  }
};

const HOMETAP_EMAIL_ERROR_MESSAGE = 'Please enter a valid email. The email must end with @hometap.com.';
export const hometapEmailValidator = (value, message = HOMETAP_EMAIL_ERROR_MESSAGE) => {
  const regex =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@hometap.com$/;
  if (!regex.test(String(value).toLowerCase())) {
    return message;
  }
};

const PHONE_ERROR_MESSAGE = 'Please enter a valid phone number.';
export const phoneValidator = (value, message = PHONE_ERROR_MESSAGE) => {
  // Length of 000-000-0000 format
  if (value.length !== 12) {
    return message;
  }
};

const NUMERIC_VALUE_ERROR_MESSAGE = 'Please enter a numeric value.';
export const validateNumericValue = (value, fieldName, message = NUMERIC_VALUE_ERROR_MESSAGE) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\+?(\d*[\.,]?)*\d+$/;
  if (value === null || value === undefined || !regex.test(String(value).toLowerCase())) {
    return fieldName ? `Please enter a positive numeric value for '${fieldName}'.` : message;
  }
};

export const REQUIRED_ERROR_MESSAGE = 'This is a required field.';
export const validateUsd = (value, required = true, fieldName, message = REQUIRED_ERROR_MESSAGE) => {
  const invalidValueMessage = validateNumericValue(value, fieldName);
  if (invalidValueMessage) {
    return invalidValueMessage;
  }
  if (required && !isNil(value) && value < 0) {
    return fieldName ? `Please enter a value greater than 0 for '${fieldName}'.` : message;
  }
};

const PERCENTAGE_ERROR_MESSAGE = 'Please enter a valid value for a percentage.';
export const validatePercentage = (value, fieldName, message = PERCENTAGE_ERROR_MESSAGE) => {
  const invalidValueMessage = validateNumericValue(value, fieldName);
  if (invalidValueMessage) {
    return invalidValueMessage;
  }
  if (!isNil(value) && (value <= 0 || value >= 100)) {
    return fieldName ? `Please enter a percentage between 0 and 100 for '${fieldName}'` : message;
  }
};

const INVALID_DATE_ERROR_MESSAGE = 'Please enter a valid date.';

export const validateDateWithinYears = (
  dateToValidate,
  years = 10, // Default to 10 years if no value is provided
  fieldName = '',
  message = INVALID_DATE_ERROR_MESSAGE,
) => {
  const currentDate = dayjs();
  const dateToValidateParsed = dayjs(dateToValidate);

  if (!dateToValidateParsed.isValid()) {
    return fieldName ? `Please enter a valid date for '${fieldName}'.` : message;
  }

  if (dateToValidateParsed.isAfter(currentDate)) {
    return 'Please enter a date that is not in the future.';
  }

  const yearsAgo = currentDate.subtract(years, 'year');
  if (dateToValidateParsed.isBefore(yearsAgo)) {
    return `Please enter a date within the last ${years} years.`;
  }
};

export const validateDateNotInThePast = date => {
  if (!date) {
    return;
  }

  const currentDate = dayjs();
  const dateToValidateParsed = dayjs(date);

  if (!dateToValidateParsed.isValid()) {
    return INVALID_DATE_ERROR_MESSAGE;
  }

  if (dateToValidateParsed.isBefore(currentDate, 'date')) {
    return 'Please enter a date that is not in the past.';
  }
};

export const validateDateNotBeforeDate = (
  dateToValidate,
  dateToCompare,
  fieldToValidateName = '',
  fieldToCompareName = '',
) => {
  const dateToValidateParsed = dayjs(dateToValidate).startOf('day');
  const dateToCompareParsed = dayjs(dateToCompare).startOf('day');

  if (!dateToValidateParsed.isValid()) {
    return fieldToValidateName ? `Please enter a valid date for '${fieldToValidateName}'.` : INVALID_DATE_ERROR_MESSAGE;
  }

  // Check whether the date field to validate is before the target date field
  if (dateToValidateParsed.isBefore(dateToCompareParsed)) {
    return `${fieldToValidateName ? fieldToValidateName : 'Date'} cannot be before ${
      fieldToCompareName ? fieldToCompareName : dateToCompareParsed.format('MM/DD/YYYY')
    }.`;
  }
};

export const validateDateNotMoreThanNDaysInPast = (
  date,
  daysCount = 30,
  message = 'Please request an updated statement.',
) => {
  if (!date) {
    return undefined;
  }

  const currentDate = dayjs();
  const dateToValidateParsed = dayjs(date);

  if (!dateToValidateParsed.isValid()) {
    return INVALID_DATE_ERROR_MESSAGE;
  }

  const daysDifference = currentDate.diff(dateToValidateParsed, 'day');

  if (daysDifference > daysCount) {
    return message;
  }
  return undefined;
};

const RANGE_ERROR_MESSAGE = 'Please enter an integer value.';
export const validateInRange = (value, minValue = 0, maxValue = 99, message = RANGE_ERROR_MESSAGE) => {
  const regex = /^\d+$/;
  if (isNil(value) || !regex.test(String(value).toLowerCase())) {
    return message || RANGE_ERROR_MESSAGE;
  }
  if (value < minValue || value > maxValue) {
    return `Please enter a value between ${minValue} and ${maxValue}`;
  }
};

export const validateNonEmptyString = (value, fieldName, message = REQUIRED_ERROR_MESSAGE) => {
  if (!value || !value.trim()) {
    return fieldName ? `Please enter a value for '${fieldName}'.` : message;
  }
};

export const validateDate = (value, fieldName, message = INVALID_DATE_ERROR_MESSAGE) => {
  if (!value || !dayjs(value).isValid()) {
    return fieldName ? `Please enter a valid date for '${fieldName}'.` : message;
  }
};

export const getNumberValue = value => {
  return typeof value === 'string' ? Number(value.replaceAll(',', '')) : value;
};

const NUMERIC_NULL_ERROR_MESSAGE = 'Please enter a value greater than 0.';
export const validateNumberValue = value => {
  if (isNil(value) || getNumberValue(value) <= 0) {
    return NUMERIC_NULL_ERROR_MESSAGE;
  }
};

export const validateMinStringLength = (value, minValue, maxValue) => {
  if (value?.trim()?.length < minValue) {
    return `Ensure this value has at least ${minValue} digits.`;
  }
  if (value?.trim()?.length > maxValue) {
    return `Ensure this value has at most ${maxValue} digits.`;
  }
};
