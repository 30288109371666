import React from 'react';
import PropTypes from 'prop-types';

import ActionsTooltip from 'components/Tooltip/ActionsTooltip';

import './DataListDetail.scss';

const DataListDetail = ({ value, actions = [], messages, children }) => {
  return (
    <>
      <div>
        <div>
          <ActionsTooltip value={value} actions={actions} className="DataListItemValue">
            {children || value}
          </ActionsTooltip>
        </div>
      </div>
      {messages && (
        <ul>
          {messages.map((message, messageIndex) => {
            return (
              <li key={`message-${messageIndex}`} className="DataListItemMessage">
                {message}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

DataListDetail.propTypes = {
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType, PropTypes.object]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      onEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      value: PropTypes.node,
    }),
  ),
};

export default DataListDetail;
