import React from 'react';
import { Icon } from '@hometap/htco-components';

import './DocumentFileCannotBeViewed.scss';
import { bytesToSize } from 'utils/documents';
import AVStatus from '../AVStatus/AVStatus';

/**
 * @typedef DocumentFileCannotBeViewed
 * @property {import('../../documentRequests').DocumentOrVersion} viewDocumentOrVersion Document or Version being viewed
 * @property {boolean} isTile Is the Document Viewer a Tile?
 * @property {boolean} isEmbedded Is the Document Viewer embedded?
 * @type {object}
 */

/** Document File Cannot Be Viewed component
 * @param {DocumentFileCannotBeViewed} params
 * @returns {JSX.Element}
 *    -   Tile or Embedded Viewer   ->    Anti-Virus Status + File Icon
 *    -   Full Window Viewer        ->    File Icon + File Name + File Size
 */
export default function DocumentFileCannotBeViewed({ viewDocumentOrVersion, isTile, isEmbedded }) {
  const { filename, fileIcon, file_size, isAVCleanOrDisabled } = viewDocumentOrVersion;
  const isViewerFullWindow = !isTile && !isEmbedded;

  const documentFileCannotBeViewed = (
    <div className="DocumentFileContent">
      <Icon size={isTile ? '3x' : '5x'} color="#152033" name={fileIcon} />
      {/* Tiles don't display a filename nor a file size */}
      {isViewerFullWindow && <strong className="PreviewFileName">{filename}</strong>}
      {isViewerFullWindow && <div className="PreviewFileSize">{bytesToSize(file_size)}</div>}
    </div>
  );

  return !isViewerFullWindow && !isAVCleanOrDisabled && isEmbedded ? (
    <div className="DocumentFileCannotBeViewed">
      <div className="AVStatusWrapper">
        <AVStatus {...{ viewDocumentOrVersion }} />
      </div>
      {documentFileCannotBeViewed}
    </div>
  ) : (
    documentFileCannotBeViewed
  );
}
