import React from 'react';
import cx from 'classnames';
import { Icon } from '@hometap/htco-components';

import './DetailInformationBlock.scss';

const ICON_TYPE = {
  default: 'icon-list',
  alert: 'icon-alert',
  warning: 'icon-alert',
  info: 'icon-info',
  error: 'icon-error',
};

/**
 * @typedef DetailInformationBlockParams
 * @type {object}
 * @property {boolean} isInvalid
 * @property {boolean} isCompleted
 * @property {boolean} isExpiring can be useful to show some kind of notification
 * @property {string} [text]
 * @property {string} [className]
 * @property {boolean} [isWithIcon]
 * @property {'default'|'info'|'alert'|'warning'} [type]
 * @property {'top'|'center'} [alignIcon]
 */
/**
 * @param {DetailInformationBlockParams} params
 * @returns HTML Element
 */
export default function DetailInformationBlock({
  isInvalid,
  isCompleted,
  isExpiring,
  className,
  text = '',
  invalidText = '',
  expiringText = '',
  isWithIcon = true,
  type = 'default',
  alignIcon = 'top',
}) {
  let message;
  if (isCompleted) {
    message = 'Homeowner to-do has been assigned.';
  } else if (isInvalid && invalidText) {
    message = invalidText;
  } else if (isExpiring && expiringText) {
    message = expiringText;
  } else {
    message = text;
  }

  return (
    <div
      className={cx('DetailInformationBlock', className, {
        isExpiringOrInvalid: isExpiring || isInvalid || (!isCompleted && text),
        isCompleted,
        isWithIcon,
        [type]: type,
      })}
    >
      {isWithIcon && (
        <Icon
          name={ICON_TYPE[type]}
          className={cx('DetailInformationBlockIcon', {
            isCompleted,
            isTop: alignIcon === 'top',
            isCenter: alignIcon === 'center',
          })}
        />
      )}
      <span>{message}</span>
    </div>
  );
}
