import React from 'react';
import PropTypes from 'prop-types';
import { currency } from 'utils/numbers';

import './MinMaxCurrencyDisplay.scss';

const MinMaxCurrencyDisplay = ({ amount, rangeLower, rangeUpper }) => {
  return (
    <div className="MinMaxCurrencyContainer">
      <h5 className="MinMaxCurrencyAmount">{currency(amount)}</h5>
      {rangeLower && rangeUpper && (
        <h6 className="MinMaxCurrencyRange">{`(${currency(rangeLower)} - ${currency(rangeUpper)})`}</h6>
      )}
    </div>
  );
};

MinMaxCurrencyDisplay.propTypes = {
  amount: PropTypes.number,
  rangeLower: PropTypes.number,
  rangeUpper: PropTypes.number,
};

export default MinMaxCurrencyDisplay;
