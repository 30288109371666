import { apiV1NoRedirect } from 'utils/api';

const sendTrackReviewerServerSegmentEvent = (reviewerUserId, { eventName, properties, trackId }) => {
  return apiV1NoRedirect.post(
    `/segment/internal/${reviewerUserId}/actions/track-reviewer/`,
    {
      event_name: eventName,
      properties,
      // conditionally add so we don't always send track_id in the request
      ...(trackId && { track_id: trackId }),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const sendTrackClientServerSegmentEvent = (trackId, { eventName, properties }) => {
  return apiV1NoRedirect.post(
    `/segment/homeowner/tracks/${trackId}/actions/track-client/`,
    {
      event_name: eventName,
      properties,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const sendTaskViewedSegmentEvent = async ({ trackId, reviewerUserId, task }) => {
  const properties = {
    task_name: task.kind,
    review_status: task.review_status,
    task_id: task.identifier,
    task_notes: task.notes,
    mandatory_task: task.isMandatory,
    update_type: 'view',
    resolution_type: task.resolutionLabel?.toLowerCase(),
    manually_created: task.isManual,
    current_stage: task.createdAtStage,
    ...(task.createdBy && { created_by: task.createdBy.email }),
  };

  return await Promise.all([
    sendTrackReviewerServerSegmentEvent(reviewerUserId, { properties, eventName: 'Task Updated' }),
    sendTrackClientServerSegmentEvent(trackId, { properties, eventName: 'Homeowner Task Updated' }),
  ]);
};
