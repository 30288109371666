import React from 'react';
import cx from 'classnames';
import { Icon, Tooltip } from '@hometap/htco-components';
import { Link } from 'react-router-dom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import './PreviewDocumentTopbar.scss';

/** Use the hooks for the Document Viewer's Topbar.
 * @returns {UsePreviewDocumentTopbarReturn}
 */
export function usePreviewDocumentTopbar() {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  return { pageNavigationPluginInstance };
}

/**
 * @typedef PreviewDocumentTopbarParams
 * @type {object}
 * @property {boolean} isCloseable Is the Document Viewer closeable?
 * @property {boolean} isEmbedded Is the Document Viewer embedded inside another component?
 * @property {() => void} handleClose The on-close callback to reset the Viewer
 * @property {import('../../documentRequests').Document[]} documents The list of Document's being viewed
 * @property {number} viewDocumentIndex The index of the current viewed document
 * @property {React.Dispatch<number>} setViewDocumentIndex Set the index of the current viewed document
 * @property {import('../../documentRequests').DocumentOrVersion[]} viewDocumentOrVersion The current viewed document/version
 * @property {string?} trackId Track ID used for navigation
 * @property {object?} scrollPosition The scroll position of the documents list view
 * @property {JSX.Element} [actionBar] Action bar to be shown on the top bar
 * @property {boolean} [isSmall] Is the viewer has small size?
 * @property {boolean} [isArrows] Variable indicating whether document switch buttons will be shown
 * @property {boolean} [isBack] Is the viewer has a back button?
 * @property {() => void} [handleBack] The on-close callback to reset the Viewer
 * @property {import('@react-pdf-viewer/page-navigation').PageNavigationPlugin} pageNavigationPluginInstance Page Navigation Plugin Instance
 */

/** Document Viewer's top bar which contains the filename & arrow/pagination buttons
 * @param {PreviewDocumentTopbarParams} params
 * @returns {JSX.Element}
 *    -   Fetching Documents(s)   ->    Empty
 *    -   Single Document         ->    Filename Only
 *    -   Multiple Documents      ->    Filename + Pagination/Navigation Arrows. Embedded Viewer doesn't use navigation to paginate.
 */
export function PreviewDocumentTopbar({
  isCloseable,
  isEmbedded,
  handleClose,
  documents,
  viewDocumentIndex,
  setViewDocumentIndex,
  viewDocumentOrVersion,
  trackId,
  scrollPosition,
  actionBar,
  isSmall,
  isArrows = true,
  isFullScreenEmbedded,
  isBack,
  handleBack,
  pageNavigationPluginInstance,
}) {
  /**
   * @param {"right"|"left"} direction right/left direction
   * @returns {number} Next index in specified direction. Note: Wraps around the list of documents.
   */
  const getNextIndex = direction => {
    const delta = direction === 'right' ? 1 : -1;
    if (viewDocumentIndex + delta > documents.length - 1) {
      return 0;
    } else if (viewDocumentIndex + delta < 0) {
      return documents.length - 1;
    }
    return viewDocumentIndex + delta;
  };

  const Arrow = ({ direction }) => {
    const nextIndex = getNextIndex(direction);
    if (isEmbedded) {
      return (
        <div className="PreviewDocumentArrow" onClick={() => setViewDocumentIndex(nextIndex)}>
          <Icon name={`icon-arrow-circle-${direction}`} size="2x" />
        </div>
      );
    }
    const linkPrefix = trackId ? `/tracks/${trackId}` : '';
    return (
      <Link
        className="PreviewDocumentArrow"
        to={`${linkPrefix}/documents/${documents[nextIndex].id}`}
        replace={false}
        state={{ scrollPosition, documents, viewDocumentIndex: nextIndex }}
      >
        <Icon name={`icon-arrow-circle-${direction}`} size="2x" />
      </Link>
    );
  };

  const Arrows = () => {
    if (!documents || documents.length <= 1) {
      return null;
    }
    return (
      <span className={cx('PreviewDocumentArrows', { isEmbedded, isFullWindow: !isEmbedded })}>
        <Arrow direction="left" />
        <Arrow direction="right" />
      </span>
    );
  };

  const { CurrentPageLabel } = pageNavigationPluginInstance;

  return (
    <div
      className={cx('PreviewDocumentTopbar', {
        isEmbedded,
        isSmall,
        isFullScreenEmbedded,
      })}
    >
      <span className="PreviewDocumentTopbarLeft">
        {isCloseable && (
          <span className="closeButton" onClick={handleClose}>
            <Icon name="icon-close" size="2x" />
          </span>
        )}
        {isBack && (
          <span className="backButton" onClick={handleBack}>
            <Icon name="icon-chevron2-left" />
            All docs
          </span>
        )}
        {/* all docs! add  */}
        <div className="PreviewDocumentFileName">
          {viewDocumentOrVersion?.filename}
          <div className="PreviewDocumentPageNumbers">
            <CurrentPageLabel>
              {currentPageLabelProps => (
                <>{`Page ${currentPageLabelProps.currentPage + 1} of ${currentPageLabelProps.numberOfPages}`}</>
              )}
            </CurrentPageLabel>
          </div>
        </div>
        <Tooltip
          className="PreviewDocumentKindTooltip"
          content={
            <span>
              {viewDocumentOrVersion?.kind_display}
              <br />
              {viewDocumentOrVersion?.applicant?.fullNameShort}
            </span>
          }
          action="hover"
        >
          <Icon name="icon-info" size="lg" />
        </Tooltip>
      </span>
      <div className={cx('PreviewDocumentTopbarActions', { isSmall })}>
        {/* no arrors only if not all docs function */}
        {!!actionBar && actionBar}
        {isArrows && <Arrows />}
      </div>
    </div>
  );
}
