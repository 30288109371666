import React from 'react';
import ReadOnlyFieldGroup from '../../components/ReadOnlyFieldGroup/ReadOnlyFieldGroup';
import { booleanOrNoneToValue, booleanOptionsWithUnknown } from 'utils/boolean';
import { APPRAISAL_TYPES } from '../../../utils/reggora';

const HistoricalHomeValuationDisplay = ({ homeValReview, index, homeValuationReviewKindOptions }) => {
  const shouldShowOtherFieldVariant = fieldValue => fieldValue === 'other';
  const kind = homeValReview.kind && homeValReview.kind.toLowerCase();

  return (
    <>
      {index > 0 && <div className="Divider"></div>}
      <>
        <ReadOnlyFieldGroup
          items={[
            {
              title: 'Provider',
              content: shouldShowOtherFieldVariant(homeValReview.provider?.value)
                ? homeValReview.providerOther
                : homeValReview.provider?.label,
            },
            {
              title: 'Type',
              content: shouldShowOtherFieldVariant(kind)
                ? homeValReview.kindOther
                : homeValuationReviewKindOptions.find(opt => opt.value === kind).label,
            },
          ]}
        />
        {(homeValReview.status || homeValReview.kindNumber) && (
          <ReadOnlyFieldGroup
            items={[
              ...(homeValReview.status ? [{ title: 'Status', content: homeValReview.status?.label }] : []),
              ...(homeValReview.kindNumber ? [{ title: 'Form Number', content: homeValReview.kindNumber }] : []),
            ]}
          />
        )}
        {kind !== APPRAISAL_TYPES.TRADITIONAL && (
          <ReadOnlyFieldGroup
            items={[
              {
                title: 'Vendor Completed',
                content: booleanOptionsWithUnknown.find(
                  opt => opt.value === booleanOrNoneToValue(homeValReview.vendorCompleted),
                ).label,
              },
              {
                title: 'Homeowner Completed',
                content: booleanOptionsWithUnknown.find(
                  opt => opt.value === booleanOrNoneToValue(homeValReview.homeownerCompleted),
                ).label,
              },
            ]}
          />
        )}
        {homeValReview.scheduledDate && (
          <ReadOnlyFieldGroup items={[{ title: 'Scheduled Date For', content: homeValReview.scheduledDate }]} />
        )}
        {homeValReview.notes && <ReadOnlyFieldGroup items={[{ title: 'Notes', content: homeValReview.notes }]} />}
      </>
    </>
  );
};

export default HistoricalHomeValuationDisplay;
