import cx from 'classnames';

/**
 * SliderFormBody component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional classes to add to the component.
 * @param {ReactNode} props.children - The content of the slider form body.
 * @returns {JSX.Element} The rendered SliderFormBody component.
 */
const SliderFormBody = ({ className, children }) => {
  return (
    <div
      className={cx(
        'relative flex h-full w-full flex-col flex-nowrap justify-start gap-[24px] overflow-y-scroll p-[24px]',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default SliderFormBody;
