import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@hometap/htco-components';
import PropTypes from 'prop-types';

import TaskSpecificContentController, {
  useTaskSpecificMultipleForms,
} from '../TaskSpecificContent/TaskSpecificContentController';

import '../TaskDetails.scss';
import { getDocKindElseTaskDefKey } from 'apps/track-details/tasks/trackTasksUtils';
import { TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT } from 'apps/track-details/tasks/data/constants/taskSpecificMultipleFormsContent';

/**
 * @typedef {ExtendedTaskData} ExtendedTaskData
 */
/**
 * @typedef TaskDetailMultipleFormsParams
 * @type {object}
 * @property {Task} task The Task being displayed
 * @property {boolean} isPageLoading Is the parent component page loading?
 */
/**
 * @param {TaskDetailParams} params
 */
const TaskDetailMultipleForms = ({ task, isPageLoading }) => {
  const { trackId, taskId } = useParams();
  useTaskSpecificMultipleForms({ trackId, task });

  const ref = useRef();
  const multipleFormsContent = TASK_SPECIFIC_MULTIPLE_FORMS_CONTENT[getDocKindElseTaskDefKey(task)];

  useEffect(() => {
    if (ref?.current) {
      ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [taskId]);

  if (isPageLoading) {
    return (
      <div className="TaskDetailLoadingContainer">
        <Loader type="dot-pulse" />
      </div>
    );
  }

  return (
    <div className="TaskDetailContainer">
      {task !== undefined && multipleFormsContent && (
        <>
          <div className="TaskDetailBody" ref={ref}>
            <h2>{task.name}</h2>
            <TaskSpecificContentController task={task} />
          </div>
        </>
      )}
    </div>
  );
};

export const TaskPropType = PropTypes.shape({
  description: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  taskDefinitionKey: PropTypes.string,
  notes: PropTypes.string,
  link: PropTypes.string,
  displayGroup: PropTypes.string,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      kind: PropTypes.string,
      description: PropTypes.string,
      isCompleted: PropTypes.bool,
    }),
  ),
  mostRecentChange: PropTypes.shape({
    historyUser: PropTypes.string,
    historyDate: PropTypes.string,
    isTaskCondition: PropTypes.bool,
    taskConditionKind: PropTypes.string,
  }),
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
});

TaskDetailMultipleForms.propTypes = {
  task: TaskPropType,
  isPageLoading: PropTypes.bool,
};

export default TaskDetailMultipleForms;
