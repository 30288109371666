import { useEffect, useState } from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(null);

  useEffect(() => {
    const updatePosition = () => {
      const { scrollY, scrollX } = window;
      if (scrollY !== 0 || scrollX !== 0) {
        setScrollPosition({ top: scrollY, left: scrollX });
      } else {
        setScrollPosition(null);
      }
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};
