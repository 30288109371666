import { useCallback, useEffect, useMemo } from 'react';
import { MuiTextInput, SelectionSet, useForm } from '@hometap/htco-components';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { getTodoNotesOptions } from 'apps/track-details/utils/trackDetailsTodo';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useSpecificContentQuery,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { getVariableValue } from '../../../TaskList/TaskListUtils';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { PERIODICITY } from 'data/constants/periodicity';
import { UPSERT_HOA } from 'apps/track-details/tasks/data/mutations';
import { GET_HOA_REVIEW } from '../HoaDocReview/getHoaReview';
import { getHomeownerTodoReason } from 'apps/track-details/todos/todoUtils';
import './HOAFeeReview.scss';

const TOOLTIP_CONTENT = <div>Examples of accepted documents: HOA bill, Assessment statement, HOA meeting minutes</div>;
const PERIODICITY_LABELS = {
  [PERIODICITY.MONTHLY]: 'Monthly',
  [PERIODICITY.ANNUALLY]: 'Annual',
};

export const HOAFeeReview = ({ task }) => {
  const { trackId } = useParams();
  const { taskKinds, allConfigurations } = useConfigurations();
  const { registerField, updateFormData, formData = {}, errors } = useForm();
  const { data: hoaFeeReviewData } = useSpecificContentQuery(GET_HOA_REVIEW);
  const { user } = useCurrentUser();

  const taskId = task.identifier;
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');

  const extendedTaskData = useMemo(
    () => getHOAFeeReviewExtendedData(hoaFeeReviewData?.track, allConfigurations),
    [hoaFeeReviewData, allConfigurations],
  );
  const { isLoading, mutate, initialFormData } = useHOAFeeReviewTaskForm(trackId, uploadedDocIds, extendedTaskData);

  const { isValid, reason, hoaFeeFrequency } = formData;
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const canUpdateTaskData = !!hoaFeeReviewData;

  const isValidForm = useMemo(() => {
    return getIsValidHOAFeeReviewTaskForm(user, formData, errors);
  }, [user, formData, errors]);

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.UPLOAD_PROOF_OF_HOA_FEES,
    taskKinds,
  });

  useEffect(() => {
    if (canUpdateTaskData) {
      updateSpecificTaskById(taskId, {
        saving: isLoading,
        complete: mutate,
        initialFormData,
        formData,
        updateFormData,
        isValidForm,
      });
    }
  }, [
    isLoading,
    mutate,
    updateSpecificTaskById,
    taskId,
    initialFormData,
    formData,
    updateFormData,
    isValidForm,
    canUpdateTaskData,
  ]);

  return (
    <TaskDetailContentSectionReview
      header="HOA fee details"
      label="Can you verify the monthly or annual recurring HOA fee with this document?"
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      tooltipContent={TOOLTIP_CONTENT}
      invalidInformationText={
        'Due to an invalid document, a request for a valid proof HOA fees will be sent when this task is marked as complete.'
      }
      reasonOptions={REASON_OPTIONS}
      isWithDivider={true}
    >
      <div className="TwoItemFormRow">
        <div className="FormItem">
          <MuiTextInput
            label={`${hoaFeeFrequency ? `${PERIODICITY_LABELS[hoaFeeFrequency]} ` : ''}HOA fee`}
            disabled={isCompleted}
            required={true}
            showRequiredAsterisk={false}
            padFractionalZeros={true}
            {...registerField('hoaFeeAmount')}
          />
        </div>
        <div className="FormItem HOAFeeFrequencySelection">
          <SelectionSet
            options={[
              {
                label: PERIODICITY_LABELS[PERIODICITY.MONTHLY],
                value: PERIODICITY.MONTHLY,
              },
              {
                label: PERIODICITY_LABELS[PERIODICITY.ANNUALLY],
                value: PERIODICITY.ANNUALLY,
              },
            ]}
            disabled={isCompleted}
            {...registerField('hoaFeeFrequency')}
          />
        </div>
      </div>
    </TaskDetailContentSectionReview>
  );
};

export function getHOAFeeReviewExtendedData(track = {}) {
  const { hoaReview } = track;
  return {
    hoaReview,
  };
}

export function useHOAFeeReviewTaskForm(trackId, uploadedDocIds, extendedTaskData) {
  const [hoaUpsertMutation, { loading: isHOAUpsertLoading }] = useMutation(UPSERT_HOA);
  const initialFormData = useMemo(() => getHOAFeeReviewInitialFormData(extendedTaskData), [extendedTaskData]);

  const upsertHOA = useCallback(
    async ({ formData = {} }) => {
      if (formData?.isValid !== 'true') {
        return;
      }
      const { hoaFeeAmount, hoaFeeFrequency } = formData;
      const variables = {
        hoaFeeAmount,
        hoaFeeFrequency,
        trackId,
        uploadedDocIds,
      };

      await hoaUpsertMutation({ variables });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackId, hoaUpsertMutation],
  );

  return {
    isLoading: isHOAUpsertLoading,
    mutate: upsertHOA,
    initialFormData,
  };
}

export const getHOAFeeReviewInitialFormData = extendedTaskData => {
  const { hoaReview } = extendedTaskData;
  const hoaFeeReview = hoaReview || {};

  const { totalAnnualHoaFee } = hoaFeeReview;
  const initialFormData = {
    isValid: undefined,
  };
  if (hoaReview?.totalAnnualHoaFee) {
    initialFormData.hoaFeeFrequency = PERIODICITY.ANNUALLY;
    initialFormData.hoaFeeAmount = totalAnnualHoaFee;
  } else {
    initialFormData.hoaFeeFrequency = PERIODICITY.MONTHLY;
  }
  return initialFormData;
};

export function getIsValidHOAFeeReviewTaskForm(user = {}, formData = {}, errors) {
  const { isValid, reason, reasonNote, hoaFeeAmount, hoaFeeFrequency } = formData;
  const homeownerTodoReason = getHomeownerTodoReason({ reason, reasonNote });
  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }

  return !!(isValid === 'true' && hoaFeeAmount && hoaFeeFrequency && isEmpty(errors));
}

export const HOA_FEE_DEMO = {
  isValid: 'true',
  hoaFeeAmount: 123,
  hoaFeeFrequency: PERIODICITY.MONTHLY,
};
