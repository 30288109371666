export const CONFIGURATIONS_PROPERTY_TYPE = {
  SingleFamilyHome: 'sf',
  MultiFamilyHome: 'mf',
  CondoTownhouseOrApartment: 'co',
  CoOp: 'cp',
  ManufacturedHome: 'md',
  MobileHome: 'mh',
};

export const CONFIGURATIONS_RESIDENCE_TYPE = {
  PrimaryResidence: 'pr',
  Vacation: 'va',
  Rental: 're',
};
