import { gql } from '@apollo/client';

export const GET_REPLACEMENT_COST_ESTIMATOR_REVIEW = gql`
  query GetProofOfReplacementCostReview($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      insurancePolicyReviews {
        identifier
        kind
        dwellingAmount
        replacementCost
      }
    }
  }
`;
