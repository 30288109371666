import { gql } from '@apollo/client';

export const TASK_DETAILS = gql`
  fragment TaskDetails on UnifiedTaskNode {
    id
    description
    taskDefinitionKey
    name
    kind
    notes
    link
    displayGroup
    taskStatus
    isOpen
    isCompleted
    createdAt
    processInstanceId

    mostRecentChange {
      historyUser
      historyDate
      isTaskCondition
      taskConditionKind
    }

    completedOn
    completedBy {
      firstName
      identifier
      lastName
    }

    conditions {
      kind
      description
      identifier
      isCompleted
    }

    variables {
      name
      value
    }
  }
`;

export const UPCOMING_TASK_DETAILS = gql`
  fragment UpcomingTaskDetails on UnifiedTaskNode {
    id
    identifier
    name
    taskDefinitionKey
    kind
    variables {
      name
      value
    }
  }
`;

export const UPDATED_TASK_CONDITION = gql`
  fragment UpdatedTaskCondition on TaskConditionNode {
    identifier
    isCompleted
    task {
      ...TaskDetails
    }
  }
  ${TASK_DETAILS}
`;
