import { useQuery } from '@apollo/client';
import { DataTable, Icon, Tooltip } from '@hometap/htco-components';
import { Link, useParams } from 'react-router-dom';
import { GET_DOCUMENT_SET_REQUEST_LOG_QUERY } from './DocumentSetRequests';
import { getDateTimeFormat } from 'utils/dates';

const DocumentRequestLog = () => {
  const { trackId } = useParams();
  const { data, loading } = useQuery(GET_DOCUMENT_SET_REQUEST_LOG_QUERY, {
    variables: {
      trackId: trackId ?? '',
    },
  });

  const isCompletedWithoutFailure = (state, failed) => {
    if (state === 'COMPLETED' && failed === null) {
      return true;
    }
    return false;
  };

  const documentSetRequestLogSelector = [
    {
      id: 'name',
      name: <strong>Output File Name</strong>,
      sortable: true,
      minWidth: '300px',
      selector: docSetRequest => docSetRequest?.documentSetName,
      cell: docSetRequest => (
        <>
          {isCompletedWithoutFailure(docSetRequest?.state, docSetRequest?.failed) ? (
            <Link to={`/tracks/${trackId}/documents/${docSetRequest.documentSetId}`}>
              {docSetRequest?.documentSetName}
            </Link>
          ) : (
            <span>{docSetRequest?.documentSetName}</span>
          )}
        </>
      ),
    },
    {
      id: 'requestedBy',
      name: <strong>Requested By</strong>,
      selector: docSetRequest => docSetRequest?.requestedBy,
    },
    {
      id: 'startTime',
      name: <strong>Requested</strong>,
      sortable: true,
      selector: docSetRequest => docSetRequest?.startTime,
      cell: docSetRequest => <>{docSetRequest?.startTime && getDateTimeFormat(docSetRequest?.startTime)}</>,
    },
    {
      id: 'status',
      name: <strong>Status</strong>,
      selector: docSetRequest => docSetRequest?.state,
      sortable: true,
      cell: docSetRequest => {
        switch (docSetRequest?.state) {
          case 'ACTIVE':
            return <span>In Progress</span>;
          case 'SUSPENDED':
            return <span>Suspended</span>;

          // NOTE: This case only happens if someone manually terminates a process in the BPM system
          case 'EXTERNALLY_TERMINATED':
            return (
              <Tooltip content="This process was terminated within the BPM System">
                <span className="DocumentSetRequestLogFailedIcon">
                  Terminated
                  <Icon name="info" size="lg" style={{ marginLeft: 8 }} />
                </span>
              </Tooltip>
            );

          // COMPLETED can be a failed process since state only supports SUSPENDED, ACTIVE AND COMPLETED
          default:
          case 'COMPLETED':
            if (docSetRequest?.failed) {
              if (docSetRequest?.failedReason) {
                return (
                  <Tooltip content={docSetRequest.failedReason}>
                    <span className="DocumentSetRequestLogFailedIcon">
                      Failed
                      <Icon name="info" size="lg" style={{ marginLeft: 8 }} />
                    </span>
                  </Tooltip>
                );
              }
              return <span className="DocumentSetRequestLogFailedIcon">Failed</span>;
            }
            return <span>Complete</span>;
        }
      },
    },
    {
      id: 'processId',
      name: <strong>Process ID</strong>,
      minWidth: '315px',
      selector: docSetRequest => docSetRequest?.rootProcessInstanceId,
      cell: docSetRequest => <span>{docSetRequest?.rootProcessInstanceId}</span>,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#152033',
      },
    },
  };

  return (
    <div className="DocumentSetRequestLogContainer">
      <Link to={`/tracks/${trackId}/documents`}>Back to Documents</Link>
      <h1>Document Set Request Log</h1>
      <DataTable
        customStyles={customStyles}
        columns={documentSetRequestLogSelector}
        loading={loading}
        defaultSortFieldId={'startTime'}
        noDataComponent="No Document Set Requests"
        data={data?.documentSetRequests ?? []}
      />
    </div>
  );
};

export default DocumentRequestLog;
