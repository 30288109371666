import React, { useMemo } from 'react';
import './StaticDocumentType.scss';
import { DOCUMENTS_TYPE } from '../../data/constants';

export const StaticDocumentType = ({ otherType = null, documentType, useDocumentKindsResults, applicant }) => {
  const { getDocumentTypeDisplayName, documentKindsWithPerson } = useDocumentKindsResults;

  const getAdditionalField = (label, value) => {
    if (!value) {
      return null;
    }
    return (
      <div className="UploadDocumentProcessingAdditionalField">
        <div className="UploadDocumentProcessingLabel">{label}</div>
        <div className="UploadDocumentProcessingDocumentType" data-id="upload-doc-type-other">
          {value}
        </div>
      </div>
    );
  };

  const additionalFieldByDocumentType = useMemo(() => {
    const additionalFields = {
      [DOCUMENTS_TYPE.Other]: getAdditionalField('Other Document Type', otherType),
    };
    if (documentKindsWithPerson.includes(documentType)) {
      additionalFields[documentType] = getAdditionalField('Applicant', applicant?.fullName || applicant?.fullNameShort);
    }
    return additionalFields;
  }, [documentKindsWithPerson, documentType, otherType, applicant]);

  return (
    <div className="UploadDocumentProcessingTypeContainer">
      <div className="UploadDocumentProcessingLabel">Document Type</div>
      <div className="UploadDocumentProcessingDocumentType" data-id="upload-doc-type">
        {getDocumentTypeDisplayName(documentType)}
      </div>

      {additionalFieldByDocumentType[documentType] || null}
    </div>
  );
};
