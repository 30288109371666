import { SelectionSet, Tooltip } from '@hometap/htco-components';
import cx from 'classnames';
import React from 'react';
import './SelectionYesNoSet.scss';
import PropTypes from 'prop-types';
import infoIcon from 'assets/icons/info.svg';

const InfoIconToolTip = ({ content }) => {
  return (
    <Tooltip className="SelectionYesNoSetInfoTooltip" content={content}>
      <div className="SelectionYesNoSetInfoTooltipImage">
        <img src={infoIcon} className="SelectionYesNoSetInfoIcon" alt="" />
      </div>
    </Tooltip>
  );
};

/**
 * @typedef SelectionYesNoSetParams
 * @type {object}
 * @property {string} label
 * @property {string|JSX.Element} [tooltipContent]
 * @property {boolean} [isWithMarginBottom]
 * @property {boolean} [disabled]
 * @property {object[]} [options]
 */
/**
 * @param {SelectionYesNoSetParams} params
 * @return JSX.Element
 */
const SelectionYesNoSet = ({ label, tooltipContent, isWithMarginBottom = true, disabled, options, ...props }) => {
  return (
    <>
      <div className="SelectionYesNoSetLabel">
        {label} {tooltipContent && <InfoIconToolTip content={tooltipContent} />}
      </div>
      <SelectionSet
        className={cx('SelectionYesNoSetFormItem', { isWithMarginBottom })}
        options={
          options || [
            {
              label: 'Yes',
              value: 'true',
              disabled,
            },
            {
              label: 'No',
              value: 'false',
              disabled,
            },
          ]
        }
        {...props}
      />
    </>
  );
};

SelectionYesNoSet.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isWithMarginBottom: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SelectionYesNoSet;
