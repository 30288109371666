import apiWithAuth from 'utils/api';

type SendMagicLinkProps = {
  email: string;
  trackId: string;
  useCase?: string;
  next?: string;
  isCoapplicant: boolean;
};
const sendMagicLink = async ({
  email,
  trackId,
  useCase = 'sent_by_staff',
  next,
  isCoapplicant,
}: SendMagicLinkProps) => {
  return await apiWithAuth.v1.post(`/auth/magic-link/generate/${next ? `?next=${next}` : ''}`, {
    email,
    track_id: trackId,
    use_case: useCase,
    is_coapplicant: isCoapplicant,
  });
};

export default sendMagicLink;
