import { gql } from '@apollo/client';

export const GET_RISK_BAND_CHECK_DATA = gql`
  query RiskBandCheck($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      applicants {
        isPrimaryApplicant
        fullNameShort
        id

        riskBand {
          isFake
          isNoDataResponse
          errorMessage
          wasExperianNameExactMatch
          value
          expirationDate
          isRiskScorePassing
          identifier
        }
      }
    }
  }
`;
