import { getTrackDetailPageUrls, getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';

export const MENU_RIBBON_LINK_ENUMS = {
  STAGE: 'Stage',
  TASKS: 'Tasks',
  APPLICATION_REVIEW: 'Application Review',
  HOMEOWNER_INFO: 'Homeowner Info',
  RISK_SCORES: 'Risk Scores',
  HOME_VALUATIONS: 'Home Valuations',
  APPRAISAlS: 'Appraisals',
  TITLE_SEARCH: 'Title Search',
  DOCUMENTS: 'Documents',
  CONTRACT: 'Contract Gen.',
};

export const createLinks = (
  { identifier: trackId, hasAcceptedOffer, rolloutFlags, application },
  isOnlyApplicationSpecialist,
  isContractor,
  canReadApplicationReview,
) => {
  const {
    stageUrl,
    homeownerInfoUrl,
    riskScoreSummaryUrl,
    homeValuationsUrl,
    appraisalsUrl,
    titleSearchReportUrl,
    documentsCreateUrl,
    contractGenUrl,
  } = getTrackDetailPageUrls(trackId, rolloutFlags);

  const { applicationReviewUrl } = getApplicationReviewPageUrls(trackId);

  const TASKS_TAB = {
    name: MENU_RIBBON_LINK_ENUMS.TASKS,
    to: `/tracks/${trackId}/tasks`,
    collapseAtBreakpoint: 'xs',
    spa: true,
  };
  const DOCUMENTS_TAB = {
    name: MENU_RIBBON_LINK_ENUMS.DOCUMENTS,
    to: documentsCreateUrl,
    collapseAtBreakpoint: 'md',
  };

  const contractorTabs = [TASKS_TAB, DOCUMENTS_TAB];

  const links = [
    {
      name: MENU_RIBBON_LINK_ENUMS.STAGE,
      to: stageUrl,
      collapseAtBreakpoint: 'xs',
    },
    TASKS_TAB,
    {
      name: MENU_RIBBON_LINK_ENUMS.APPLICATION_REVIEW,
      to: applicationReviewUrl,
      collapseAtBreakpoint: 'xs',
      alsoActiveWhen: pathname => pathname.match(`/tracks/${trackId}/application-review`),
      disabled: (application?.unlockStatus && application.unlockStatus === 'UNLOCKED') || !canReadApplicationReview,
    },
    {
      name: MENU_RIBBON_LINK_ENUMS.HOMEOWNER_INFO,
      to: homeownerInfoUrl,
      collapseAtBreakpoint: 'xs',
    },
    {
      name: MENU_RIBBON_LINK_ENUMS.RISK_SCORES,
      to: riskScoreSummaryUrl,
      collapseAtBreakpoint: 'xs',
    },
    {
      name: MENU_RIBBON_LINK_ENUMS.HOME_VALUATIONS,
      to: homeValuationsUrl,
      collapseAtBreakpoint: 'lg',
    },
    {
      name: MENU_RIBBON_LINK_ENUMS.APPRAISAlS,
      to: appraisalsUrl,
      collapseAtBreakpoint: 'xs',
    },
    {
      name: MENU_RIBBON_LINK_ENUMS.TITLE_SEARCH,
      to: titleSearchReportUrl,
      collapseAtBreakpoint: 'lg',
      disabled: application?.unlockStatus && application.unlockStatus === 'UNLOCKED',
    },
    DOCUMENTS_TAB,
  ];

  let tabs = links;

  const addContractGen = hasAcceptedOffer && isOnlyApplicationSpecialist;

  if (addContractGen) {
    tabs.push({
      name: MENU_RIBBON_LINK_ENUMS.CONTRACT,
      to: contractGenUrl,
      collapseAtBreakpoint: 'lg',
    });
  }

  if (isContractor) {
    tabs = contractorTabs;
  }

  return tabs;
};
