import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Toast } from '@hometap/htco-components';

type ToastType = 'info' | 'success' | 'warning' | 'error';

type ShowNotificationProps = {
  type: ToastType;
  title?: string | (() => React.ReactNode);
  description?: string | (() => React.ReactNode);
  // all react-toastify toast options can be passed from https://fkhadra.github.io/react-toastify/api/toast
  options?: ToastOptions;
  toastContent?: React.ReactNode | (() => React.ReactNode);
};

const toastDefaultOptions: ToastOptions = {
  autoClose: 5000,
  closeButton: false,
  icon: false,
  hideProgressBar: true,
};

export const TOAST_TYPE: Record<ToastType, ToastType> = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export const showNotification = ({ type, title, description, options = {}, toastContent }: ShowNotificationProps) => {
  toast(toastContent || <Toast type={type} title={title} description={description} />, {
    type,
    ...toastDefaultOptions,
    ...options,
  });
};
