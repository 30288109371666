import { Button, Loader } from '@hometap/htco-components';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import listIcon from 'assets/icons/list.svg';
import { CREATE_AND_ASSIGN_TODOS } from 'apps/track-details/todos/data/mutations';
import { TOAST_TYPE, showNotification } from 'utils/toasts';
import useConfigurations from 'hooks/useConfigurations';
import { TODO_NOTE_EXPIRED_DOCUMENT, TODO_NOTE_EXPIRING_DOCUMENT } from '../../data/constants/todoNotes';
import { ApplicationReviewAlert } from './ApplicationReviewAlert';

export const GovernmentIdExpiringAlert = ({ trackId, applicant, expiresIn }) => {
  const { homeowner } = useOutletContext();
  const { taskKinds } = useConfigurations();
  const [createTodos, { called, loading, error }] = useMutation(CREATE_AND_ASSIGN_TODOS, {
    onCompleted: () =>
      showNotification({
        type: TOAST_TYPE.success,
        title: 'Successfully assigned to-do',
        description: 'All is well',
      }),
    onError: () =>
      showNotification({
        type: TOAST_TYPE.error,
        title: 'Failed to assign to-do',
        description: 'Please try adding the to-do again',
      }),
  });
  const createTodosData = {
    kind: taskKinds.UPLOAD_GOV_ID,
    notes: expiresIn < 0 ? TODO_NOTE_EXPIRED_DOCUMENT : TODO_NOTE_EXPIRING_DOCUMENT,
    person: applicant.person.identifier,
  };
  const hasCreatedTodo = called && !error && !loading;
  const applicantName = applicant.governmentId?.nameAsAppearsOnId ?? applicant.fullName;
  const expirationMessage =
    expiresIn < 0
      ? `${applicantName}'s Government ID has expired. Please click “Add this to-do” to request an updated one.`
      : `The expiration date for ${applicantName}'s Government ID falls within the next 30 days. Please click “Add this to-do” to request an updated one.`;

  return (
    <ApplicationReviewAlert
      isAssigned={hasCreatedTodo}
      icon={<img src={listIcon} className="alertListIcon" alt="List icon" />}
      action={
        loading ? (
          <Loader />
        ) : (
          !hasCreatedTodo && (
            <Button
              icon="icon-plus"
              theme="link"
              onClick={() =>
                createTodos({ variables: { trackId, assigneeId: homeowner.identifier, todosInput: [createTodosData] } })
              }
            >
              Add this to-do
            </Button>
          )
        )
      }
    >
      {hasCreatedTodo ? 'Homeowner to-do has been assigned.' : expirationMessage}
    </ApplicationReviewAlert>
  );
};

GovernmentIdExpiringAlert.propTypes = {
  trackId: PropTypes.string.isRequired,
  applicant: PropTypes.object.isRequired,
  expiresIn: PropTypes.number.isRequired,
};
