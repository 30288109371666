import { gql } from '@apollo/client';

export const GET_MORE_TASKS = gql`
  query GetMoreTasks(
    $nextPageLimit: Int
    $prevPageLimit: Int
    $nextPageCursor: String
    $prevPageCursor: String
    $orderBy: String
    $keyword: String
    $taskStatusChoices: [String!]
    $kinds: [String!]
    $assignee: String
  ) {
    unifiedTasks(
      first: $nextPageLimit
      after: $nextPageCursor
      last: $prevPageLimit
      before: $prevPageCursor
      orderBy: $orderBy
      keyword: $keyword
      status: $taskStatusChoices
      excludeStatus: ["track_closed"]
      groups: ["staff"]
      assignee: $assignee
      kinds: $kinds
    ) {
      totalCount

      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }

      edges {
        cursor

        node {
          identifier
          name
          taskDefinitionKey
          kind
          taskStatus

          variables {
            name
            value
          }

          track {
            identifier
            friendlyId
            firstName
            state

            applicants {
              firstName
              person {
                identifier
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TASK_FILTER_OPTIONS = gql`
  query GetTaskFilterOptions {
    taskStatusChoices {
      value
      label
    }
  }
`;
