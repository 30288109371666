import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Checkbox } from '@hometap/htco-components';

import './TaskConditions.scss';
import HtmlRenderer from '../HtmlRenderer/HtmlRenderer';

const TaskConditions = ({ conditions, isReadOnly, onConditionChange, shouldCheckAllConditions, className }) => {
  const [localConditions, setLocalConditions] = useState([...conditions]);

  useEffect(() => {
    setLocalConditions([...conditions]);
  }, [conditions]);

  useEffect(() => {
    if (shouldCheckAllConditions) {
      const result = localConditions.map(item => {
        return { ...item, isCompleted: true };
      });
      setLocalConditions(result);
    }
    // eslint-disable-next-line
  }, [shouldCheckAllConditions]);

  const onChange = (newValue, id) => {
    const conds = JSON.parse(JSON.stringify([...localConditions]));
    const condToUpdate = conds.find(({ identifier }) => id === identifier);
    condToUpdate.isCompleted = newValue;
    setLocalConditions(conds);
    onConditionChange(id, newValue);
  };

  return (
    <div className={cx('TaskConditionsCheckboxList', className)}>
      {localConditions.map(({ identifier, description, isCompleted }) => (
        <Checkbox
          key={identifier}
          name={identifier}
          className="TaskConditionsCheckbox"
          disabled={isReadOnly}
          checked={isCompleted}
          label={<HtmlRenderer className="TaskConditionsHtmlRenderer" description={description} />}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

TaskConditions.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      kind: PropTypes.string,
      description: PropTypes.string,
      isCompleted: PropTypes.bool,
    }),
  ).isRequired,
  isReadOnly: PropTypes.bool,
  shouldCheckAllConditions: PropTypes.bool,
  onConditionChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TaskConditions;
