import React, { useEffect, useMemo } from 'react';
import { Button, FormError, useForm } from '@hometap/htco-components';
import { VisibilityRadioRow } from '../VisibilityRadioRow/VisibilityRadioRow';
import { DOCUMENT_VISIBILITY, OTHER_DOCUMENT_TYPE, DOCUMENTS_TYPE } from '../../data/constants';

import './EditDocumentForm.scss';
import { UploadNewOrEditDocumentFields } from '../UploadNewOrEditDocumentFields/UploadNewOrEditDocumentFields';

const EditDocumentForm = ({
  onSubmit,
  document,
  useDocumentKindsResults,
  handleCancel,
  error,
  getDocumentKindVisibility,
  applicantsOptions,
}) => {
  const { internal, kind_old_code, filename, kind_display, personId } = document;
  const {
    documentKindsDropdownOptionsSorted,
    isDocumentType: getIsDocumentType,
    documentKindsWithPerson,
  } = useDocumentKindsResults;
  const isDocumentType = getIsDocumentType(kind_old_code);
  const visibility = internal ? DOCUMENT_VISIBILITY.internal : DOCUMENT_VISIBILITY.both;
  const { registerField, handleSubmit, updateFormData, formData } = useForm({
    internal: visibility,
    kind: isDocumentType ? kind_old_code : OTHER_DOCUMENT_TYPE,
    kind_other: isDocumentType ? undefined : kind_display,
    person_id: personId,
  });
  const { visibilityLabel, isVisibilityEditable } = getDocumentKindVisibility(formData['kind']);

  // default to applicant when there's only one and gov_id
  useEffect(() => {
    if (documentKindsWithPerson.includes(formData.kind) && applicantsOptions?.length === 1 && !formData.person_id) {
      updateFormData({ person_id: applicantsOptions[0].value });
    }
  }, [formData.kind, updateFormData, formData.person_id, applicantsOptions, documentKindsWithPerson]);

  const handleVisibilityChange = value => {
    updateFormData({ internal: value });
  };

  const additionalFieldsProps = useMemo(() => {
    const additionalFieldsProps = {
      [DOCUMENTS_TYPE.Other]: registerField('kind_other'),
    };
    if (documentKindsWithPerson.includes(formData.kind)) {
      additionalFieldsProps[formData.kind] = registerField('person_id');
    }
    return additionalFieldsProps;
  }, [documentKindsWithPerson, formData.kind, registerField]);

  const getIsDisabledSubmitButton = () => {
    if (formData.kind === DOCUMENTS_TYPE.Other) {
      return !formData?.kind_other;
    }
    if (documentKindsWithPerson.includes(formData.kind)) {
      return !formData?.person_id;
    }
    return false;
  };

  return (
    <form className="EditDocumentForm" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2 className="EditDocumentFormTitle">Edit Document</h2>
        <div>
          <div className="EditDocumentFormLabel">Document Name</div>
          <div className="EditDocumentFormName">{filename}</div>
        </div>
        <UploadNewOrEditDocumentFields
          docType={formData['kind']}
          documentKindsDropdownOptions={documentKindsDropdownOptionsSorted}
          applicantsOptions={applicantsOptions}
          additionalFieldsByDocumentTypeProps={additionalFieldsProps}
          documentKindsWithPerson={documentKindsWithPerson}
          documentTypeFieldProps={{ className: 'Applicant', ...registerField('kind') }}
        />
        <span className="EditDocumentFormLabel">Visibility</span>
        <VisibilityRadioRow
          defaultVisibilityLabel={visibilityLabel}
          isDefaultVisibilityEditable={isVisibilityEditable}
          setVisibility={handleVisibilityChange}
          staticValue={visibility}
          isUpdateVisibilityValue={false}
          {...registerField('internal')}
          docType={formData['kind']}
        />
      </div>
      {error && <FormError className="EditDocumentFormError" standalone error={error} />}
      <div className="EditDocumentFormButtonsContainer">
        <Button theme="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="submit" disabled={getIsDisabledSubmitButton()}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditDocumentForm;
