import { gql } from '@apollo/client';

export const UPDATE_ACTIVE_OPPORTUNITY = gql`
  mutation UpdateActiveOpportunity($trackId: IdentifierType!, $opportunityId: IdentifierType!) {
    updateActiveOpportunity(trackId: $trackId, opportunityId: $opportunityId) {
      opportunityRecord {
        id
      }
    }
  }
`;
