import React from 'react';
import { DropdownSelector, Icon } from '@hometap/htco-components';
import { components } from 'react-select';

const FilterMultiSelect = ({ label, loading, ...props }) => {
  const customStyles = {
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? (isFocused ? '#E2EAFD' : 'transparent') : styles.backgroundColor,
      color: '#0f172a',
      fontSize: 14,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    menu: styles => ({
      ...styles,
      minWidth: '250px',
    }),
    control: styles => ({
      ...styles,
      minHeight: '40px',
    }),
    indicatorSeparator: styles => ({
      display: 'none',
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: '#366ced',
    }),
    multiValueRemove: (styles, { isFocused }) => ({
      ...styles,
      paddingLeft: 2,
      ':hover': {
        color: '#b41a1a',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    }),
    placeholder: (styles, { isFocused }) => ({
      ...styles,
      display: isFocused ? 'none' : styles.display,
      position: 'relative',
      color: '#366ced',
      fontSize: '14px',
      transform: null,
      top: null,
    }),
    valueContainer: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'row',
      ':before': {
        content: `"${label}:"`,
        fontSize: '14px',
        color: '#687183',
        marginRight: '8px',
      },
    }),
  };

  const Option = props => {
    return (
      <span>
        <components.Option {...props}>
          <Icon
            name={props.isSelected ? 'checkbox-checked' : 'checkbox-unchecked'}
            color={props.isSelected ? '#366ced' : '#919aac'}
            size="sm"
            fixed
            style={{
              marginRight: 8,
            }}
          />
          {props.label}
        </components.Option>
      </span>
    );
  };

  return (
    <span data-testid={`filter-${props.name}`}>
      <DropdownSelector
        className="FilterMultiSelect"
        classNamePrefix="FilterMultiSelect"
        placeholder={loading ? '...' : 'All'}
        multiple
        isClearable={false}
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option }}
        {...props}
      />
    </span>
  );
};

export default FilterMultiSelect;
