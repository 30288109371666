import { useSectionFormById, useUpdateSectionForm } from '../../../hooks/useSpecificContentQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { getIsTaskFormChanged } from 'apps/track-details/utils/taskForms';
import { convertNumericStringsToNumbers } from '../../../../utils/taskForms';

export const useLienForm = lien => {
  const lienId = lien?.identifier;

  const { updateSectionFormById } = useUpdateSectionForm();
  const { initialFormData, updateFormData, formData } = useSectionFormById(lienId);

  const isFormChanged = useMemo(() => {
    return getIsTaskFormChanged(
      convertNumericStringsToNumbers(initialFormData),
      convertNumericStringsToNumbers(formData),
    );
  }, [formData, initialFormData]);

  // the function to rest current opened form data to initial state
  const resetData = useCallback(() => {
    if (initialFormData) {
      updateFormData(initialFormData);
    }
  }, [initialFormData, updateFormData]);

  useEffect(() => {
    updateSectionFormById(lienId, {
      isFormChanged,
      resetData,
    });
  }, [lienId, isFormChanged, resetData, updateSectionFormById]);
};
