/**
 * @typedef DisableScrollPDFViewerPluginReturn
 * @type {object}
 * @property {(props: import('@react-pdf-viewer/core').RenderViewer) => import('@react-pdf-viewer/core').Slot} renderViewer Render Viewer Function used by Plugins
 */

/** PDF Viewer Plugin to disable scrolling
 * https://react-pdf-viewer.dev/examples/slide-presentation/ Under the heading "Disable scrolling"
 * @returns {DisableScrollPDFViewerPluginReturn}
 */
export default function disableScrollPDFViewerPlugin() {
  /**
   * @param {import('@react-pdf-viewer/core').RenderViewer} params
   * @returns {import('@react-pdf-viewer/core').Slot}
   */
  function renderViewer({ slot }) {
    if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
      slot.subSlot.attrs.style = {
        ...slot.subSlot.attrs.style, // Disable scrolling in the pages container
        overflow: 'hidden',
      };
    }
    return slot;
  }

  return {
    renderViewer,
  };
}
