import React from 'react';
import ACTION_TOOLTIP_OPTIONS from 'data/constants/actionTooltipOptions';
import { SidebarDetail } from 'apps/track-details/ApplicationReview/components';
import { Tooltip, Icon } from '@hometap/htco-components';
import { currency, currencyWithCents } from 'utils/numbers';
import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import { booleanOrNoneToLabel } from 'utils/boolean';
import { formatMMDDYYYY } from 'utils/dates';
import { getTrackDetailPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { CONFIGURATIONS_RESIDENCE_TYPE } from 'data/constants/configurations';

export const PropertyDetailsSidebarController = ({ track }) => {
  const {
    fullAddress,
    floodZone,
    home,
    relevantHomeValuation,
    appraisalType,
    propertyReportReview,
    rentalAgreementReview,
    identifier,
  } = track || {};
  const { propertyTypeLabel, residenceTypeLabel } = home || {};
  const {
    messages,
    value,
    valueLower,
    valueUpper,
    type,
    expirationDate,
    daysUntilExpires,
    avmEligibility,
    avmEligibilityDetail,
  } = relevantHomeValuation || {};

  const { isTrust } = propertyReportReview || {};
  const { editPropertyAddressUrl, editHomeUrl } = getTrackDetailPageUrls(identifier, []);
  const ShowAvmEligibility = avmEligibility !== 'NOT_APPLICABLE';
  const AvmEligibilityUnknown = 'UNKNOWN';
  const AvmEligibilityIneligible = 'INELIGIBLE';
  const AvmEligibilityEligible = 'ELIGIBLE';

  const homeIsRental = track?.home?.residenceType === CONFIGURATIONS_RESIDENCE_TYPE.Rental;
  const { totalAnnualRentalAmount } = rentalAgreementReview || {};

  const InfoIcon = () => <Icon name="icon-info" size="sm" />;

  const MinMaxCurrencyDisplay = ({ amount, rangeLower, rangeUpper }) => {
    return (
      <div className="MinMaxCurrencyContainer">
        <strong className="SidebarDetailInlineField">
          <ActionsTooltip actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.edit, onEdit: editHomeUrl }]}>
            {currency(amount)}
          </ActionsTooltip>
        </strong>
        {rangeLower && rangeUpper && (
          <h6 className="MinMaxCurrencyRange">{`(${currency(rangeLower)} - ${currency(rangeUpper)})`}</h6>
        )}
      </div>
    );
  };

  const AvmEligibility = () => {
    switch (avmEligibility) {
      case AvmEligibilityUnknown:
        return (
          <>
            <strong>Unknown</strong>
            <Tooltip
              content={
                <span>
                  <b>Result</b>: It was unclear if this valuation passed our automated AVM-eligibility requirements.
                  <br />
                  <br />A physical appraisal may or may not be required.
                  <br />
                  <br />
                  <b>Detail</b>: {avmEligibilityDetail}
                </span>
              }
            >
              <strong>
                Unknown <InfoIcon />
              </strong>
            </Tooltip>
          </>
        );
      case AvmEligibilityIneligible:
        return (
          <>
            <strong>Ineligible </strong>
            <Tooltip
              content={
                <span>
                  <b>Result</b>: This valuation did NOT pass our automated AVM-eligibility requirements.
                  <br />
                  <br />A physical appraisal will be required.
                  <br />
                  <br />
                  <b>Detail</b>: {avmEligibilityDetail}
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          </>
        );
      case AvmEligibilityEligible:
        return (
          <>
            <strong>Eligible</strong>
            <Tooltip
              content={
                <span>
                  <b>Result</b>: This valuation did pass our automated AVM-eligibility requirements.
                  <br />
                  <br />A physical appraisal may not be required.
                  <br />
                  <br />
                  <b>Detail</b>: {avmEligibilityDetail}
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          </>
        );
      default:
        return null;
    }
  };

  const HomeValuationExpiration = () => {
    return (
      <span className="SidebarDetailContentLabel">
        {expirationDate ? (
          <>
            (Expires {formatMMDDYYYY(expirationDate)}, in {daysUntilExpires} days)
          </>
        ) : (
          <>(Does not expire)</>
        )}
      </span>
    );
  };

  return (
    <SidebarDetail sidebarDetailTitle={'Property Details'}>
      <div>
        <span className="SidebarDetailContentLabel">
          Address:{' '}
          <strong className="SidebarDetailInlineField">
            <ActionsTooltip
              value={fullAddress}
              actions={[
                { actionType: ACTION_TOOLTIP_OPTIONS.copy },
                { actionType: ACTION_TOOLTIP_OPTIONS.edit, onEdit: editPropertyAddressUrl },
              ]}
            >
              {fullAddress}
            </ActionsTooltip>
          </strong>
        </span>
      </div>
      <div>
        <span className="SidebarDetailContentLabel">Property Type: </span>
        <strong className="SidebarDetailInlineField">
          <ActionsTooltip actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.edit, onEdit: editHomeUrl }]}>
            {propertyTypeLabel || 'Unknown'}
          </ActionsTooltip>
        </strong>
      </div>
      <div>
        <span className="SidebarDetailContentLabel">Residence Type: </span>
        <strong className="SidebarDetailInlineField">
          <ActionsTooltip actions={[{ actionType: ACTION_TOOLTIP_OPTIONS.edit, onEdit: editHomeUrl }]}>
            {residenceTypeLabel || 'Unknown'}
          </ActionsTooltip>
        </strong>
      </div>
      {homeIsRental && totalAnnualRentalAmount && (
        <div>
          <span className="SidebarDetailContentLabel">Annual Rental Income: </span>
          <strong>{currencyWithCents(totalAnnualRentalAmount)}</strong>
        </div>
      )}
      <div>
        <span className="SidebarDetailContentLabel">Flood Zone: </span>
        <strong>{floodZone}</strong>
      </div>
      <div>
        <span className="SidebarDetailContentLabel">Appraisal Type: </span>
        <strong>{appraisalType?.label}</strong>
      </div>
      <div>
        <span className="SidebarDetailContentLabel">
          Home Value:{' '}
          {value && (
            <strong className="SidebarDetailInlineField">
              <MinMaxCurrencyDisplay amount={value} rangeLower={valueLower} rangeUpper={valueUpper} />
            </strong>
          )}
        </span>
        {messages?.length > 0 && (
          <ul>
            {messages.map((message, messageIndex) => {
              return (
                <li key={`message-${messageIndex}`} className="DataListItemMessage">
                  {message}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div>
        <div className="SidebarDetailContentLabel">
          Home Valuation Type: <strong>{type}</strong>
        </div>
        {type && <HomeValuationExpiration />}
      </div>
      {ShowAvmEligibility && (
        <div>
          <span className="SidebarDetailContentLabel">AVM Eligibility: </span>
          <AvmEligibility />
        </div>
      )}
      <div>
        <span className="SidebarDetailContentLabel">Trust: </span>
        <strong>{booleanOrNoneToLabel(isTrust)}</strong>
      </div>
    </SidebarDetail>
  );
};

export default PropertyDetailsSidebarController;
