import { gql } from '@apollo/client';

export const CLAIM_TRACK = gql`
  mutation ClaimTrack($trackId: IdentifierType!) {
    claimTrack(trackId: $trackId) {
      track {
        id
        friendlyId
        reviewer {
          fullNameShort
        }
      }
    }
  }
`;
