import React from 'react';

const DEFAULT_PREFIX_TAG = 'b';

const ApplicantFullNameAndType = ({ applicant, prefixTag: PrefixTag, fullName }) => {
  return (
    <span>
      <PrefixTag>{applicant.isPrimaryApplicant ? 'Primary Applicant' : 'Co-applicant'}:</PrefixTag>{' '}
      {applicant.fullNameShort || applicant.fullName}
    </span>
  );
};

ApplicantFullNameAndType.defaultProps = {
  prefixTag: DEFAULT_PREFIX_TAG,
};

export default ApplicantFullNameAndType;
