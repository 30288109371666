import { useQuery } from '@apollo/client';
import { Accordion, CurrencyInput, MuiTextInput, Paper } from '@hometap/htco-components';
import { GET_TRACK_MORTGAGES_LIENS } from './getTrackMortgagesLiens';
import './TaskMortgagesLiens.scss';
import { TaskMortgageLien } from '../TaskMortgageLien/TaskMortgageLien';
import { getVariableValue } from '../TaskList/TaskListUtils';
import { SENIOR_LIEN_REVIEW_TASK_DEFINITIONS } from 'data/constants/bpmConstants';
import { showNotification } from 'utils/toasts';
import { useCallback, useState } from 'react';
import { useMemo } from 'react';

const TaskMortgagesLiens = ({ trackId, task }) => {
  const { loading, data, error } = useQuery(GET_TRACK_MORTGAGES_LIENS, {
    variables: { trackId },
  });
  const { track } = data || {};
  const [openAccordianKeys, setOpenAccordianKeys] = useState({});

  const { salesforce_data: salesforceData } = useMemo(() => getVariableValue(task, 'extended_track'), [task]);

  const toggleAccordian = useCallback(key => {
    setOpenAccordianKeys(prev => ({ ...prev, [key]: !prev[key] }));
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    showNotification({
      type: 'error',
      title: `Failed to load mortgages and liens`,
      description: <>error</>,
    });
  }
  return (
    <Paper theme="plain" className="TaskMortgagesLiens">
      {salesforceData && (
        <>
          <MuiTextInput
            value={salesforceData['sfdc_effective_date']}
            label="Effective Date"
            theme="outlined"
            width="100%"
            disabled={true}
            key={'sfdc_effective_date'}
          />
          <h3>Paydowns</h3>
          <CurrencyInput
            key={'total_paydown_amount'}
            value={salesforceData['total_paydown_amount']}
            label="Total Paydown"
            padFractionalZeros={true}
            disabled
          />
          <div className="FormItem">
            <MuiTextInput
              label="Number of Paydowns"
              value={salesforceData['number_of_paydowns']}
              mask={[
                {
                  mask: Number,
                  signed: false,
                  allowDecimal: false,
                },
              ]}
              unmask="typed"
              theme="outlined"
              width="100%"
              disabled
              required={true}
              showRequiredAsterisk={false}
            />
          </div>
          <CurrencyInput
            key={'principal_paydown'}
            value={salesforceData['principal_paydown']}
            label="Principle Paydown"
            padFractionalZeros={true}
            disabled
          />
          <CurrencyInput
            key="mortgage_paydown"
            value={salesforceData['mortgage_paydown']}
            label="Non-Principle Paydown"
            padFractionalZeros={true}
            disabled
          />
          <Accordion
            key="paydown_detail"
            isOpen={!!openAccordianKeys['paydown_detail']}
            onChange={() => toggleAccordian('paydown_detail')}
            theme="with-arrow"
            header="Paydown Details"
          >
            <p className="PayoffDetails">{salesforceData['paydown_detail']}</p>
          </Accordion>
          <Accordion
            key="uw_notes"
            isOpen={!!openAccordianKeys['uw_notes']}
            onChange={() => toggleAccordian('uw_notes')}
            theme="with-arrow"
            header="Underwriter Notes"
          >
            <p className="PayoffDetails">{salesforceData['uw_notes']}</p>
          </Accordion>
        </>
      )}
      {SENIOR_LIEN_REVIEW_TASK_DEFINITIONS.includes(task.taskDefinitionKey) && track?.liens && (
        <>
          <h3>Mortgages and Liens</h3>
          {track.liens.map(lien => (
            <TaskMortgageLien key={lien.identifier} lien={lien} readOnly liensKindOptions={track.liensKindOptions} />
          ))}
        </>
      )}
    </Paper>
  );
};

export default TaskMortgagesLiens;
