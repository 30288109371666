import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { FormError, Icon } from '@hometap/htco-components';
import { Viewer } from '@react-pdf-viewer/core';
import { Loader } from '@hometap/htco-components';
import { downloadThenMaybeConvertDocument } from '../../documentRequests';
import PDFWorker from 'components/PDFWorker/PDFWorker';
import { IMAGE_SCALE_INITIAL } from '../PreviewDocumentBottombar/PreviewDocumentBottombar';
import disableScrollPDFViewerPlugin from '../../plugins/disableScrollPDFViewerPlugin';
import DocumentFileCannotBeViewed from '../DocumentFileCannotBeViewed/DocumentFileCannotBeViewed';
import PanAndZoomImage from '../PanAndZoomImage/PanAndZoomImage';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './PreviewDocumentFile.scss';

/**
 * @typedef UsePreviewDocumentFileReturn
 * @type {object}
 * @property {boolean} downloading Is the Image/PDF downloading?
 * @property {(downloading: boolean) => void} setDownloading Set whether the Image/PDF is downloading
 * @property {string?} downloadError Did the download result in an error?
 * @property {(error: string?) => void} setDownloadError Set whether the download resulted in an error
 * @property {string?} downloadedBlobURL The URL where we store the downloaded blob
 * @property {(url: string?) => void} setDownloadedBlobURL Set the URL where we store the downloaded blob
 * @property {(document: import('../../documentRequests').DocumentOrVersion?) => void} download Download the document/version file
 * @property {() => void} resetDownload Reset the download state
 */

/** Use the hooks for the Document File Viewer
 * @returns {UsePreviewDocumentFileReturn}
 */
export function usePreviewDocumentFile() {
  const [downloading, setDownloading] = useState(true);
  const [downloadError, setDownloadError] = useState();
  const [downloadedBlobURL, setDownloadedBlobURL] = useState();

  /** Download document image file
   * @param {Object} docObject
   */
  const download = useCallback(async documentOrVersion => {
    setDownloading(true);
    try {
      if (documentOrVersion) {
        const blobURL = await downloadThenMaybeConvertDocument(documentOrVersion, true);
        setDownloadedBlobURL(blobURL);
        setDownloadError();
      }
    } catch ({ message }) {
      setDownloadError(message);
    } finally {
      setDownloading(false);
    }
  }, []);

  const resetDownload = useCallback(() => {
    setDownloadedBlobURL();
    setDownloading(false);
    setDownloadError();
  }, []);

  return {
    downloading,
    setDownloading,
    downloadError,
    setDownloadError,
    downloadedBlobURL,
    setDownloadedBlobURL,
    download,
    resetDownload,
  };
}

/**
 * @typedef PreviewDocumentFileParamsWithoutHookReturns
 * @type {object}
 * @property {import('../../documentRequests').DocumentOrVersion?} viewDocumentOrVersion document/version being viewed
 * @property {boolean?} documentLoading Is the document(s) being fetched from the API?
 * @property {import('axios').AxiosError?} documentError Did the document(s) fetch request raise an error?
 * @property {boolean?} isEmbedded Is the Document Viewer embedded inside of another component
 * @property {boolean} [isFullScreenEmbedded] Is this viewer embedded inside another Component and is full screen. Left & Right Sidebar are not visible. Viewer cannot be closed & has slightly different styling. Action bar is moved to the top.
 * @property {import('@react-pdf-viewer/core').Plugin?} thumbnailPluginInstance (Optional) PDF Viewer Thumbnail Plugin
 */

/**
 * @typedef PreviewDocumentFileParams
 * @type {PreviewDocumentFileParamsWithoutHookReturns
 *        & import('../PreviewDocumentBottombar/PreviewDocumentBottombar').UsePreviewDocumentBottombarReturn
 *        & UsePreviewDocumentFileReturn}
 */

/** Document File Viewer without any action buttons nor any side or bottom bar.
 *    -   PDF Viewer requires any plugins being used.
 *    -   Image Viewer requires zoom & scale states.
 *    -   Only viewDocumentOrVersion & UsePreviewDocumentFileReturn are required.
 *
 * @param {PreviewDocumentFileParams} params
 * @return {JSX.Element}
 *    -   Fetching Data or Downloading Image      ->    Loader
 *    -   Fetch or Download Error                 ->    Error
 *    -   File type not supported                 ->    File Description
 *    -   File not yet scanned                    ->    File Description
 *    -   PDF File is Encrypted or Infected       ->    File Description
 *    -   File is Clean or Anti-Virus Disabled    ->    Image/PDF Viewer
 */
export default function PreviewDocumentFile(params) {
  return (
    <PDFWorker>
      <PreviewDocumentFileChild {...params} />
    </PDFWorker>
  );
}
/** Document File Viewer Child
 * @param {PreviewDocumentFileParams} params
 * @return {JSX.Element}
 */
function PreviewDocumentFileChild({
  // PreviewDocumentFileParams
  viewDocumentOrVersion,
  documentLoading = false,
  documentError = null,
  isEmbedded = false,
  isTile = false,
  thumbnailPluginInstance = null,
  // UsePreviewDocumentBottombarReturn
  rotatePluginInstance = null,
  zoomPluginInstance = null,
  pageNavigationPluginInstance = null,
  imageRotation = 0,
  imageScale = IMAGE_SCALE_INITIAL,
  // UsePreviewDocumentFileReturn
  downloading,
  downloadError,
  downloadedBlobURL,
  isFullScreenEmbedded,
}) {
  const RIGHT_DRAWER_WIDTH = '300px';

  // All plugins are optional
  const pdfPlugins = [
    rotatePluginInstance,
    thumbnailPluginInstance,
    zoomPluginInstance,
    pageNavigationPluginInstance,
  ].filter(plugin => !!plugin);
  if (isTile) {
    pdfPlugins.push(disableScrollPDFViewerPlugin());
  }

  // Document(s) being fetched, Image/PDF is downloading, or view document hasn't been selected
  if (documentLoading || downloading || !viewDocumentOrVersion) {
    return (
      <div className={cx('PreviewFile CenterContent', { isEmbedded, isTile })}>
        <Loader type="spinner" size="large" />
      </div>
    );
  }
  // Document(s) fetch error or download error
  else if (documentError || downloadError) {
    return (
      <div className={cx('PreviewFile CenterContent', { isEmbedded })}>
        <FormError standalone error={'Error: This document cannot be viewed at this time'} />
      </div>
    );
  }

  const { filename, isAVCleanOrDisabled, isViewable, isPDF, pinned } = viewDocumentOrVersion;
  const PinIcon = () => <Icon name="icon-pin-fill" className="PinnedIcon" />;

  // Waiting on Anti-virus check, virus check failed, or file type is unsupported
  if (!isAVCleanOrDisabled || !isViewable) {
    return (
      <div className={cx('PreviewFile CenterContent', { isEmbedded, isTile })}>
        <DocumentFileCannotBeViewed {...{ viewDocumentOrVersion, isTile, isEmbedded }} />
      </div>
    );
  }
  // View PDF
  else if (isPDF) {
    return (
      <div className={cx('PreviewFile PreviewPDF', { isEmbedded })}>
        {!isEmbedded && pinned && <PinIcon />}
        {downloadedBlobURL && (
          <Viewer
            fileUrl={downloadedBlobURL}
            plugins={pdfPlugins}
            httpHeaders={{
              'Access-Control-Allow-Origin': '*',
            }}
            initialRotation={imageRotation}
            renderLoader={() => <Loader type="spinner" size="large" />}
          />
        )}
      </div>
    );
  }

  // View Image in static tile
  else if (isTile && !isFullScreenEmbedded) {
    return (
      <>
        {pinned && <PinIcon />}
        <div
          className={cx('PreviewFile CenterContent', {
            isEmbedded,
            isScrollEnabled: !isTile,
            isFullScreenEmbedded,
            PreviewImage: !isFullScreenEmbedded,
          })}
        >
          <img
            src={downloadedBlobURL}
            alt={filename}
            style={{
              transform: `rotate(${imageRotation}deg)`,
              transition: '.5s',
              width: `${imageScale}%`,
            }}
          />
        </div>
      </>
    );
  }

  // View Image in Pan and zoom container
  return (
    <div
      style={{
        width: `calc(100% - ${isFullScreenEmbedded ? '0px' : RIGHT_DRAWER_WIDTH})`,
        height: '100%',
      }}
    >
      <PanAndZoomImage downloadedBlobURL={downloadedBlobURL} filename={filename} imageRotation={imageRotation} />
    </div>
  );
}
