export const getGraphQLError = error => {
  const graphQLErrors = error?.graphQLErrors;

  if (graphQLErrors?.length > 0) {
    return graphQLErrors.reduce(
      (acc, error, currentIndex) => (currentIndex === 0 ? error.message : `${acc}. ${error.message}`),
      '',
    );
  }

  return '';
};
