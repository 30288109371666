import {
  GET_PHYSICAL_APPRAISAL_DATA,
  ORDER_TRADITIONAL_APPRAISAL,
} from 'apps/track-details/ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { showNotification } from 'utils/toasts';
import { getGraphQLError } from 'utils/errors';

const usePhysicalAppraisalData = trackId => {
  const {
    data: physicalAppraisalsData,
    loading: physicalAppraisalsLoading,
    error: physicalAppraisalsError,
    refetch: refetchPhysicalAppraisals,
  } = useQuery(GET_PHYSICAL_APPRAISAL_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      trackId,
    },
  });

  const [requestOrderTraditionalAppraisal, { loading: orderTraditionalAppraisalLoading }] = useMutation(
    ORDER_TRADITIONAL_APPRAISAL,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          title: 'Order Traditional Appraisal',
          description: 'Traditional Appraisal ordered successfully',
        });
      },
    },
  );

  useEffect(() => {
    if (physicalAppraisalsError) {
      propagateError(physicalAppraisalsError);
    }
  }, [physicalAppraisalsError]);

  /**
   *
   * @param {GraphQLError} error
   */
  const propagateError = error => {
    showNotification({
      type: 'error',
      title: 'Error loading physical appraisals',
      description: getGraphQLError(error),
    });
  };

  return {
    physicalAppraisalsData,
    physicalAppraisalsLoading,
    physicalAppraisalsError,
    refetchPhysicalAppraisals,
    requestOrderTraditionalAppraisal,
    orderTraditionalAppraisalLoading,
  };
};

export default usePhysicalAppraisalData;
