import { gql } from '@apollo/client';
import { TASK_DETAILS, UPCOMING_TASK_DETAILS } from 'apps/track-details/tasks/data/fragments';

export const GET_VALIDITY_CONFIRMATION_DATA = gql`
  query ValidityConfirmation($trackId: IdentifierType!) {
    track(identifier: $trackId) {
      id
      internalTasks {
        ...TaskDetails
      }
      upcomingInternalTasks {
        ...UpcomingTaskDetails
      }

      liens {
        asOfDate
        identifier
      }

      applicants {
        isPrimaryApplicant
        fullNameShort
        id
        governmentId {
          nameAsAppearsOnId
          expirationDate
          identifier
        }
      }
    }
  }
  ${TASK_DETAILS}
  ${UPCOMING_TASK_DETAILS}
`;
