import cx from 'classnames';
import { Icon } from '@hometap/htco-components';

/**
 * SliderFormHeader component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.title - The title of the slider form.
 * @param {Function} props.onClose - The function to close the slider form.
 * @param {string} [props.className] - Additional classes to add to the component.
 * @param {boolean} [props.closeDisabled] - Whether the close button should be disabled.
 * @returns {JSX.Element} The rendered SliderFormHeader component.
 */
const SliderFormTitle = ({ title, onClose, className, closeDisabled = false }) => {
  return (
    <div
      className={cx(
        'flex h-[62px] w-full items-center justify-between border-b border-neutral-light-100 p-[24px] text-lg font-bold',
        className,
      )}
    >
      <span>{title}</span>
      <button
        onClick={onClose}
        disabled={closeDisabled}
        className={`${closeDisabled ? 'cursor-default' : 'cursor-pointer'}`}
        aria-label="SliderForm-close-button"
      >
        <Icon name="close" size="lg" className={`${closeDisabled ? 'opacity-50' : 'opacity-100'}`} />
      </button>
    </div>
  );
};

export default SliderFormTitle;
