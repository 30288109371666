import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { MenuDropdown, MenuDropdownItem, Tooltip } from '@hometap/htco-components';

import './ActionsDropdown.scss';

const ActionsDropdown = ({ actions = [], disabledTooltipContent = 'No actions available' }) => {
  const isActionsDropdownDisabled = actions.every(({ shouldActionDisable }) => shouldActionDisable);
  return isActionsDropdownDisabled ? (
    <Tooltip content={disabledTooltipContent} className="ActionsDropdownDisabled">
      <MenuDropdown data-testid="actions-dropdown" />
    </Tooltip>
  ) : (
    <MenuDropdown className="ActionsDropdown" menuWidth="max-content" data-testid="actions-dropdown">
      {actions.map(({ actionFunc, label, shouldActionDisable }) => {
        return (
          <MenuDropdownItem
            className={cx('ActionsDropdownAction', { isDisabled: shouldActionDisable })}
            key={`${label}-i`}
            onClick={!shouldActionDisable && actionFunc}
          >
            {label}
          </MenuDropdownItem>
        );
      })}
    </MenuDropdown>
  );
};

ActionsDropdown.propTypes = {
  disabledTooltipContent: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      shouldActionDisable: PropTypes.bool.isRequired,
      actionFunc: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ActionsDropdown;
