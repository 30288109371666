export const TASK_STATUSES = {
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not Started',
  IN_PROCESS: 'In Progress',
  NOT_APPLICABLE: 'Not Applicable',
  TRACK_CLOSED: 'Track Closed',
  PENDING_TO_DO: 'Pending to-do',
};

export const TASK_RESOLUTION_KEYS = {
  NA: 'na',
  COMPLETED: 'completed',
  ESCALATED: 'escalated',
};
