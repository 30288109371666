import { TaskPropType } from '../../TaskDetail';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MuiTextInput, useAsync } from '@hometap/htco-components';
import dayjs from 'dayjs';
import './SeniorLienDataEntry.scss';
import { createSeniorLienRequest } from 'apps/track-details/tasks/taskRequests';
import {
  useSpecificContent,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { MuiSelect, useForm } from '@hometap/htco-components';
import useYesNoMultiForm from '../../YesNoMultiForm/useYesNoMultiForm';
import { useMultiDocSelector } from '../../TaskDetailMultipleFormsList/hooks/useMultiDocSelector';
import { getVariableValue } from '../../../TaskList/TaskListUtils';
import YesNoMultiForm from '../../YesNoMultiForm/YesNoMultiForm';
import { SeniorLienDataEntryForm } from './SeniorLienDataEntryForm';
import TaskNote from '../../../TaskNote/TaskNote';
import { AdverseEventForm } from './AdverseEventForm';
import { useParams } from 'react-router-dom';

const MULTI_FORMS_FIELDS_META = [
  {
    yesNoField: 'hasAdverseEvents',
    multiFormField: 'adverseEvents',
  },
  {
    yesNoField: 'hasSeniorLiens',
    multiFormField: 'liens',
  },
];

const PROPERTY_CONDITION_OPTIONS = [
  { value: 'C1', label: 'C1' },
  { value: 'C2', label: 'C2' },
  { value: 'C3', label: 'C3' },
  { value: 'C4', label: 'C4' },
  { value: 'C5', label: 'C5' },
  { value: 'C6', label: 'C6' },
  { value: 'Good', label: 'Good' },
  { value: 'Average', label: 'Average' },
  { value: 'Poor', label: 'Poor' },
];

export const SeniorLienDataEntry = ({ task, isLoading, lastDataEntry, isReassign }) => {
  const { trackId } = useParams();
  const taskId = task.identifier;
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent();
  const [notes, setNotes] = useState(lastDataEntry?.notes);
  const { loading: isSaving, execute: saveAsync } = useAsync(createSeniorLienRequest);
  const liens = useMemo(() => lastDataEntry?.data?.liens ?? [], [lastDataEntry?.data?.liens]);

  const useFormHooks = useForm({});
  const { formData, updateFormData, registerField } = useFormHooks;
  const useYesNoMultiFormHooks = useYesNoMultiForm({
    fieldsMeta: MULTI_FORMS_FIELDS_META,
    initialRequestData: lastDataEntry?.data,
    useFormHooks,
  });
  const { getRequestData } = useYesNoMultiFormHooks;
  const { specificTaskData } = useSpecificContent(taskId);
  const { getAvailableDocOptions, changeDocSelections } = useMultiDocSelector({
    documents: specificTaskData.documents,
    itemsWithDocumentIds: liens,
  });
  const escalatedBy = getVariableValue(task, 'escalated_by');

  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;

  const save = useCallback(
    async ({ formData }) => {
      return await saveAsync({
        track_id: trackId,
        process_instance_id: task.processInstanceId,
        input_data: getVariableValue(task, 'extended_track'),
        data: getRequestData(formData),
        notes,
      });
    },
    [trackId, task, saveAsync, getRequestData, notes],
  );

  const isValidForm = useMemo(() => {
    return true;
  }, []);

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      saving: isSaving,
      complete: save,
      formData: formData,
      updateFormData,
      isValidForm,
    });
  }, [updateSpecificTaskById, taskId, updateFormData, save, isSaving, isValidForm, getRequestData, formData]);

  if (isLoading) return null;
  return (
    <div className="TaskDetailContent">
      {escalatedBy && (
        <div className="EscalatedBy">
          <i>Escalated By: {escalatedBy}</i>
        </div>
      )}
      {isReassign && (
        <div className="ReassignBlock">
          <MuiTextInput label="Reassign to (optional)" theme="outlined" width="100%" {...registerField('reassignTo')} />
        </div>
      )}
      <div className="TaskDetailContentTitle">Property Condition</div>
      <div className="PropertyConditionLabel">What's the condition of the property? (C1-C6, Good, Average, Poor)</div>
      <MuiSelect
        label="Property condition"
        options={PROPERTY_CONDITION_OPTIONS}
        disabled={isCompleted}
        theme="outlined"
        width="100%"
        className="PropertyConditionSelector"
        classNamePrefix="PropertyConditionSelector"
        {...registerField('propertyCondition')}
      />
      <div className="SectionDivider" />
      <div className="TaskDetailContentTitle">Adverse Events</div>
      <YesNoMultiForm
        yesNoLabel="Does this property have any adverse events?"
        yesNoField="hasAdverseEvents"
        multiFormField="adverseEvents"
        addAnotherField="hasAnotherAdverseEvent"
        addAnotherLabel="Does this property have another adverse event?"
        isDisabled={isCompleted}
        useFormHooks={useFormHooks}
        useYesNoMultiFormHooks={useYesNoMultiFormHooks}
        renderChild={AdverseEventForm}
      />
      <div className="SectionDivider" />
      <div className="TaskDetailContentTitle">Senior Liens</div>
      <YesNoMultiForm
        yesNoLabel="Does this track have a senior lien?"
        yesNoField="hasSeniorLiens"
        multiFormField="liens"
        addAnotherField="hasAnotherSeniorLien"
        addAnotherLabel="Is there another senior lien?"
        isDisabled={isCompleted}
        useFormHooks={useFormHooks}
        useYesNoMultiFormHooks={useYesNoMultiFormHooks}
        renderChild={({ index, ...rest }) => (
          <SeniorLienDataEntryForm
            index={index}
            {...rest}
            changeDocSelections={documentIds => changeDocSelections(index, documentIds)}
            availableDocOptions={getAvailableDocOptions(index)}
          />
        )}
      />
      <div>
        <TaskNote
          taskId={task.identifier}
          onChange={value => setNotes(value)}
          initialTextValue={lastDataEntry?.notes}
        />
      </div>
    </div>
  );
};

SeniorLienDataEntry.propTypes = {
  task: TaskPropType,
};

export default SeniorLienDataEntry;

export const SENIOR_LIEN_DEMO_DATA = {
  holder: 'BOA',
  asOfDate: dayjs().format('YYYY-MM-DD'),
  mortgageInvestorKind: 'private',
  mortgageAccountNumber: '123456789',
  currentBalance: '123456.78',
  principalAmount: '8857.78',
  interestAmount: '444.44',
  escrowAmount: '222.22',
  isMoreThanOneMonthPastDue: 'false',
  hasForbearance: 'true',
  pastDueAmount: '555.55',
  pastDueFees: '66.66',
  isValid: 'true',
};
