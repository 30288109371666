import React from 'react';
import { showNotification, TOAST_TYPE } from '../../../../../utils/toasts';
import { CompleteTaskError } from '../TaskDetail/TaskSpecificContent/TaskSpecificContentController';
import { getDocKindElseTaskDefKey } from '../../trackTasksUtils';

export const FINAL_APPROVED = 'final_approved';
export const NO_PASS = 'no_pass';
export const MORE_INFO_NEEDED = 'more_info';

// Used to sort tasks that may have subtasks in subsequent order
// {MAIN_TASK_KIND: [SUB_TASK_KIND]}
const TASK_GROUPINGS = {
  ho_insurance: [
    'replacement_cost_estimator_on_condo_master_policy',
    'condo_master_policy',
    'flood_insurance',
    'other_insurance',
    'upload_replacement_cost_estimator_on_hoi',
    'proof_of_rental',
  ],
};

export const getTasksMap = (tasks, reviewStatus) => {
  const currentReviewStatus = reviewStatus?.value;

  const tasksMap = tasks.reduce((acc, task) => {
    const { reviewStatus } = task;

    if (acc.hasOwnProperty(reviewStatus)) {
      acc = { ...acc, [reviewStatus]: [...acc[reviewStatus], task] };
      return acc;
    }

    return { ...acc, [reviewStatus]: [task] };
  }, {});

  groupTasks(tasksMap);

  if (
    currentReviewStatus &&
    !tasks.some(({ reviewStatus: reviewStatusValue }) => reviewStatusValue === currentReviewStatus) &&
    currentReviewStatus !== FINAL_APPROVED
  ) {
    return { ...tasksMap, [currentReviewStatus]: [] };
  }
  return tasksMap;
};

const groupTasks = tasksMap => {
  Object.keys(tasksMap).forEach(reviewStatus => {
    const tasks = tasksMap[reviewStatus];
    if (!tasks) {
      return;
    }
    Object.keys(TASK_GROUPINGS).forEach(mainTaskKind => {
      if (tasks.map(task => getDocKindElseTaskDefKey(task)).includes(mainTaskKind)) {
        const subTaskKinds = TASK_GROUPINGS[mainTaskKind];
        subTaskKinds.forEach(subTaskKind => {
          reorderTasks(tasks, mainTaskKind, subTaskKind);
        });
      }
    });
  });
};

const reorderTasks = (tasksList, mainTaskKind, subTaskKind) => {
  const subTaskKindIndex = tasksList.findIndex(task => getDocKindElseTaskDefKey(task) === subTaskKind);

  if (subTaskKindIndex > -1) {
    const subTask = tasksList[subTaskKindIndex];
    tasksList.splice(subTaskKindIndex, 1);
    const mainTaskKindIndex = tasksList.findIndex(task => getDocKindElseTaskDefKey(task) === mainTaskKind);
    if (mainTaskKindIndex > -1) {
      tasksList.splice(mainTaskKindIndex + 1, 0, subTask);
    }
  }
};

export const getReviewStatusesMap = statuses =>
  statuses
    .filter(({ status }) => status !== FINAL_APPROVED)
    .reduce((acc, { label, value }) => {
      return { ...acc, [value]: label };
    }, {});

export const showCompleteErrorTaskToast = error => {
  const description =
    error && error instanceof CompleteTaskError ? error.message : 'Please try marking the task as complete again';

  showNotification({
    type: TOAST_TYPE.error,
    title: 'Failed to mark task as complete',
    description: <div className="ToastContentDescription">{description}</div>,
  });
};

/** Get the Parsed Task Variable Value
 * @param {Task|HomeownerTodo} task The Task
 * @param {string} variableName The name of the Task Variable
 * @returns {any?} Parsed Task Variable Value if found
 * @throws Programmatic error if the task doesn't follow the required pattern
 */
export const getVariableValue = (task, variableName) => {
  if (task === undefined) {
    return undefined;
  }
  if (task.variables === undefined) {
    throw new Error(`getVariableValue: task=${JSON.stringify(task)} missing BPM variables`);
  }
  const variable = task.variables.find(({ name }) => name === variableName);
  if (variable === undefined) {
    return null;
  }
  return JSON.parse(variable.value);
};

/** Get the applicant linked to the task via a variable
 * @param {Task|HomeownerTodo} [task] Target task with BPM variables
 * @param {Applicant[]} [applicants] List of Applicants. If non-empty, each applicant must have nested person.identifier
 * @returns {Applicant?} Applicant corresponding to the task's linked person if the person exists
 * @throws Programmatic error if the task or applicant don't follow the required pattern
 */
export const getApplicant = (task, applicants) => {
  if (!applicants || !task || applicants.length === 0) {
    return null;
  }
  if (applicants[0].person?.identifier === undefined) {
    throw new Error(`getApplicant: applicants=${JSON.stringify(applicants)} missing person identifiers`);
  }
  const personId = getVariableValue(task, 'person_id');
  return applicants.find(({ person }) => person.identifier === personId) || null;
};

/**
 * Function to search for a row by value in a column and return a value from another column
 * @param {TaskDocumentKinds[]} data - The data array to search through, each item should be an object
 * @param {string} value - The value to search for in the data array
 * @param {'kind'|'documentType'} property - The name of the property to return the value from
 * @returns {string|null|undefined} The value of the specified property in the row that contains the specified value, or null if not found
 */
export const getValueByProperty = (data, value, property) => {
  if (!data || !value || !property) {
    return;
  }
  const row = data.find(item => Object.values(item).includes(value));
  if (row) {
    return row[property];
  }
  return null;
};
