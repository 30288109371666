import React from 'react';
import { useAsync } from '@hometap/htco-components';
import { getDocumentById } from '../../documentRequests';
import { useParams } from 'react-router';
import { PreviewDocument } from '../PreviewDocument/PreviewDocument';
import { usePreviewDocumentPage } from '../StandAlonePreviewTrackDocument/StandAlonePreviewTrackDocument';

/** Standalone Document Viewer with no other documents as context. Full Window & Uncloseable.
 * @returns {JSX.Element} Standalone Document Viewer
 */
export default function StandAlonePreviewDocument() {
  const { documentId, versionId } = useParams();

  const {
    results: document,
    loading: documentLoading,
    error: documentError,
  } = useAsync(getDocumentById, {
    immediate: true,
    params: [documentId],
    defaultLoading: true,
  });
  const documents = document ? [document] : [];
  const { versionLoading, versionError, viewDocumentIndex, setViewDocumentIndex, viewVersion } =
    usePreviewDocumentPage(documents);

  return (
    <PreviewDocument
      documentLoading={documentLoading || versionLoading}
      documentError={documentError || versionError}
      {...{
        documents,
        viewDocumentIndex,
        setViewDocumentIndex,
        versionId,
        viewVersion,
      }}
    />
  );
}
