import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import { CurrencyInput, useForm } from '@hometap/htco-components';
import { ApplicationReviewAlert } from 'apps/track-details/ApplicationReview/components/Alert/ApplicationReviewAlert';
import SelectionYesNoSet from 'apps/track-details/tasks/components/TaskDetail/TaskSpecificContent/SelectionYesNoSet/SelectionYesNoSet';
import { useUpdateSpecificTaskContent } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { getVariableValue } from 'apps/track-details/tasks/components/TaskList/TaskListUtils';
import { PROPERTY_TAX_UPSERT_MUTATION } from 'apps/track-details/tasks/data/mutations';
import './ReviewPropertyTax.scss';

const ReviewPropertyTax = ({ task }) => {
  const { trackId } = useParams();
  const taskId = task.identifier;

  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);
  const { registerField, updateFormData, formData = {}, errors } = useForm();
  const showConditionalCopy = formData.delinquentPropertyTaxes === 'true';
  const uploadedDocIds = getVariableValue(task, 'uploaded_doc_ids');

  const { isLoading, mutate } = usePropertyTaxTaskForm(trackId, uploadedDocIds);

  const isValidForm = useMemo(() => {
    return getIsValidPropertyTaxReviewTaskForm(formData, errors);
  }, [formData, errors]);

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      saving: isLoading,
      complete: mutate,
      formData,
      updateFormData,
      isValidForm,
    });
  }, [formData, isLoading, isValidForm, mutate, taskId, updateFormData, updateSpecificTaskById]);

  return (
    <div className="ReviewPropertyTaxYesNo">
      <div className="ReviewPropertyTaxHeader">Property tax details</div>
      <SelectionYesNoSet
        label="Are there delinquent property taxes?"
        {...registerField('delinquentPropertyTaxes')}
        isWithMarginBottom={showConditionalCopy}
      />
      {showConditionalCopy && (
        <ApplicationReviewAlert description="Taxes will need to be paid off. If delinquent 3+ full years the opportunity must be DQd" />
      )}
      <CurrencyInput
        className="ReviewPropertyTaxAnnualAmount"
        label="Total annual property tax"
        padFractionalZeros={true}
        disabled={false}
        {...registerField('totalAnnualTaxAmount')}
      />
    </div>
  );
};

const usePropertyTaxTaskForm = (trackId, uploadedDocIds) => {
  const [propertyTaxUpsertMutation, { loading: isPropertyTaxUpsertLoading }] =
    useMutation(PROPERTY_TAX_UPSERT_MUTATION);

  const upsertPropertyTax = useCallback(
    async ({ formData = {} }) => {
      const { totalAnnualTaxAmount } = formData;
      const variables = {
        trackId,
        totalAnnualTaxAmount,
      };

      return await propertyTaxUpsertMutation({ variables });
    },
    [trackId, propertyTaxUpsertMutation],
  );

  return {
    isLoading: isPropertyTaxUpsertLoading,
    mutate: upsertPropertyTax,
  };
};

function getIsValidPropertyTaxReviewTaskForm(formData = {}, errors) {
  const { totalAnnualTaxAmount } = formData;
  return !!(totalAnnualTaxAmount && isEmpty(errors));
}

export const PROPERTY_TAX_DEMO_DATA = {
  isValid: 'true',
  totalAnnualTaxAmount: 4582,
};

export default ReviewPropertyTax;
