import cx from 'classnames';

/**
 * SliderFormSection component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} [props.heading] - The heading of the slider form section.
 * @param {string} [props.className] - Additional classes to add to the component.
 * @param {ReactNode} props.children - The content of the slider form section.
 * @returns {JSX.Element} The rendered SliderFormSection component.
 
 */
const SliderFormSection = ({ heading, className, children }) => {
  return (
    <div className={cx('flex w-full flex-col gap-[8px]', className)}>
      {heading && <span className="tracking-[1px] text-neutral-dark-50">{heading}</span>}
      {children}
    </div>
  );
};

export default SliderFormSection;
