import React, { useCallback, useEffect, useMemo } from 'react';
import { DatePicker, MuiTextInput, useForm } from '@hometap/htco-components';
import DetailInformationBlock from 'components/DetailInformationBlock/DetailInformationBlock';
import { TASK_STATUSES } from '../../../../../../../data/constants/taskStatuses';
import { useMutation } from '@apollo/client';
import { CREATE_GOVERNMENT_ID, UPDATE_GOVERNMENT_ID } from 'apps/track-details/tasks/data/mutations';

import './GovernmentIDReview.scss';
import { getHomeownerTodoReason } from 'apps/track-details/todos/todoUtils';
import { EXPIRATION_DAYS_COUNT, getExpirationDateData, getTheDateWithDaysAdded } from 'utils/dates';
import TaskDetailContentSectionReview from '../../TaskDetailContentSectionReview/TaskDetailContentSectionReview';
import { getTodoNotesOptions } from '../../../../../utils/trackDetailsTodo';
import { useParams } from 'react-router-dom';
import useConfigurations from 'hooks/useConfigurations';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useSpecificContentQuery,
  useUpdateSpecificTaskContent,
} from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { GET_GOVERNMENT_ID_REVIEW } from './getGovernmentIDReview';
import { getApplicant } from '../../../TaskList/TaskListUtils';

const TOOLTIP_CONTENT = (
  <div>
    A valid government ID means the:
    <br />
    1. Name matches application
    <br />
    2. Date of birth matches application
    <br />
    3. Expiration date is at least {EXPIRATION_DAYS_COUNT} days in the future
  </div>
);

const INFORMATION_BLOCK = {
  expiringText:
    'Due to expiration rules, a request for an updated government ID will be sent when this task is marked as complete.',
  invalidText:
    'Due to an invalid document, a request for an updated government ID will be sent when this task is marked as complete.',
};

/**
 * @typedef GovernmentIDReviewParams
 * @type {object}
 * @property {Task} task
 */

/** Government ID Review Task Form
 * @param {GovernmentIDReviewParams} params
 * @returns {JSX.Element}
 */
export default function GovernmentIDReview({ task }) {
  const { trackId } = useParams();
  const { registerField, updateFormData, formData = {} } = useForm({});

  const taskId = task.identifier;
  const { data = {} } = useSpecificContentQuery(GET_GOVERNMENT_ID_REVIEW);
  const { user } = useCurrentUser();
  const { createOrUpdateGovtIdTask, isCreatingOrUpdatingGovtIdTask } = useGovtIDReviewTaskForm(trackId);
  const { updateSpecificTaskById } = useUpdateSpecificTaskContent(taskId);

  const applicant = getApplicant(task, data?.track?.applicants);
  const initialFormData = useMemo(() => getGovIDTaskInitialFormData(applicant), [applicant]);
  const { isValid, reason, expirationDate } = formData;
  const { isExpiring } = getExpirationDateData(expirationDate);
  const isCompleted = task.taskStatus === TASK_STATUSES.COMPLETED;
  const { taskKinds } = useConfigurations();

  const REASON_OPTIONS = getTodoNotesOptions({
    kind: taskKinds.GOVT_ID_REVIEW_V1,
    taskKinds: taskKinds,
  });

  const isValidForm = useMemo(() => {
    return getIsValidGovtIDReviewTaskForm(user, formData);
  }, [user, formData]);

  useEffect(() => {
    updateSpecificTaskById(taskId, {
      saving: isCreatingOrUpdatingGovtIdTask,
      complete: createOrUpdateGovtIdTask,
      initialFormData,
      formData,
      updateFormData,
      isValidForm,
    });
  }, [
    isCreatingOrUpdatingGovtIdTask,
    createOrUpdateGovtIdTask,
    updateSpecificTaskById,
    taskId,
    initialFormData,
    formData,
    updateFormData,
    isValidForm,
  ]);

  return (
    <TaskDetailContentSectionReview
      label="Is this a valid Government ID? "
      isValid={isValid}
      isCompleted={isCompleted}
      reason={reason}
      registerField={registerField}
      tooltipContent={TOOLTIP_CONTENT}
      invalidInformationText={INFORMATION_BLOCK.invalidText}
      reasonOptions={REASON_OPTIONS}
    >
      <DatePicker
        className="GovernmentIDFormDatePicker"
        label="Govt. ID expiration date"
        theme="outlined"
        disabled={isCompleted}
        {...registerField('expirationDate')}
      />
      {isExpiring && (
        <DetailInformationBlock
          isInvalid={false}
          isExpiring={isExpiring}
          isCompleted={isCompleted}
          className="GovernmentIDAddHomeownerTodo"
          expiringText={INFORMATION_BLOCK.expiringText}
        />
      )}
      <DatePicker
        className="GovernmentIDFormDatePicker"
        label="Date of birth"
        disabled={isCompleted}
        theme="outlined"
        {...registerField('birthDate')}
      />
      <MuiTextInput
        disabled={isCompleted}
        label="Name as it Appears on ID"
        theme="outlined"
        width="100%"
        {...registerField('nameAsAppearsOnId')}
      />
    </TaskDetailContentSectionReview>
  );
}

/**
 * @typedef GovtIDReviewFormData
 * @type {CreateHomeownerTodoFormData}
 * @property {TrueFalseValue} isValid
 * @property {Date} expirationDate
 * @property {Date} birthDate
 * @property {string} nameAsAppearsOnId
 * @property {string} [reason]
 * @property {string} [reasonNote]
 */
/** Get initial data for the government ID Review
 * @param {Applicant} [applicant] The applicant related to the task
 * @returns {GovtIDReviewFormData} Initial Government ID Task Data
 */
export function getGovIDTaskInitialFormData(applicant) {
  const { governmentId } = applicant || {};
  if (governmentId) {
    return {
      isValid: undefined,
      expirationDate: governmentId?.expirationDate,
      birthDate: applicant.birthDate,
      nameAsAppearsOnId: governmentId?.nameAsAppearsOnId,
    };
  }
  return {
    isValid: undefined,
    expirationDate: undefined,
    birthDate: undefined,
    nameAsAppearsOnId: undefined,
  };
}

/** Get Is Valid Govt ID Review Task Form
 * @param {GovtIDReviewFormData} formData
 * @returns {boolean}
 */
function getIsValidGovtIDReviewTaskForm(user = {}, formData = {}) {
  const { isValid, expirationDate, nameAsAppearsOnId, birthDate } = formData;
  const homeownerTodoReason = getHomeownerTodoReason(formData);
  if (isValid === 'false' && homeownerTodoReason) {
    return true;
  }
  if (isValid === 'false' && user?.rolloutFlags.includes('hide_todo_reasons_dropdown')) {
    return true;
  }
  if (isValid === 'true') {
    return !!(nameAsAppearsOnId && birthDate && expirationDate);
  }
  return false;
}

/**
 * @typedef  {({formData: GovtIdFormData, applicant?: Applicant}) => void} CreateOrUpdateGovtIdTask
 */
/**
 * @typedef UseGovtIDReviewTaskFormReturn
 * @type {object}
 * @property {boolean} isCreatingOrUpdatingGovtIdTask
 * @property {CreateOrUpdateGovtIdTask} createOrUpdateGovtIdTask
 */
/** Use the GovtId Review Task Form
 * @param {string} trackId Track ID
 * @returns {UseGovtIDReviewTaskFormReturn}
 */
export function useGovtIDReviewTaskForm(trackId) {
  const [createGovernmentId, { loading: isCreateGovernmentIdLoading }] = useMutation(CREATE_GOVERNMENT_ID);
  const [updateGovernmentId, { loading: isUpdateGovernmentIdLoading }] = useMutation(UPDATE_GOVERNMENT_ID);
  const isCreatingOrUpdatingGovtIdTask = isCreateGovernmentIdLoading || isUpdateGovernmentIdLoading;

  const createOrUpdateGovtIdTask = useCallback(
    async ({ formData, applicant }) => {
      const homeownerTodoReason = getHomeownerTodoReason(formData);
      if (formData?.isValid !== 'true' || !applicant || homeownerTodoReason) {
        return;
      }
      const { identifier: applicantId, governmentId } = applicant;
      const { nameAsAppearsOnId, birthDate, expirationDate } = formData;
      const variables = {
        applicantId,
        nameAsAppearsOnId,
        birthDate,
        expirationDate,
      };
      // if the government id is not valid or has if the expiration date
      // triggers one of the expired rules we don't create or update governmentID
      if (governmentId?.identifier) {
        await updateGovernmentId({
          variables: { ...variables, governmentIdId: governmentId.identifier },
        });
      } else {
        await createGovernmentId({
          variables: { ...variables, trackId },
        });
      }
    },
    [trackId, createGovernmentId, updateGovernmentId],
  );

  return {
    isCreatingOrUpdatingGovtIdTask,
    createOrUpdateGovtIdTask,
  };
}

export const GOVT_ID_DEMO_DATA = {
  isValid: 'true',
  expirationDate: getTheDateWithDaysAdded(EXPIRATION_DAYS_COUNT + 1),
  birthDate: '1989-07-14',
  nameAsAppearsOnId: 'Bob Whitney Smith',
};
