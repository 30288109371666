import React, { useState } from 'react';
import { useParams } from 'react-router';
import { SliderController } from '../SliderController/SliderController';
import useDocumentKinds from '../../hooks/useDocumentKinds';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTrackApplicants } from '../../hooks/useTrackApplicants';

const StandAloneUploadTrackDocument = props => {
  const { trackId } = useParams();
  // - - - - Document Type Hooks - - - -
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const kind_id = queryParams.get('kind');

  const [openUpload, setOpenUpload] = useState(true);
  const useDocumentKindsResults = useDocumentKinds();
  const { getDocumentKindVisibility } = useDocumentKindsResults;
  const useTrackApplicantsResults = useTrackApplicants(trackId);
  const navigate = useNavigate();
  // TODO: standalone upload new version
  const [versionToBeUpdated, setVersionToBeUpdated] = useState({});

  return (
    <SliderController
      openUpload={openUpload}
      setOpenUpload={setOpenUpload}
      trackId={trackId}
      refresh={() => navigate(`/tracks/${trackId}/documents`)}
      versionToBeUpdated={versionToBeUpdated}
      setVersionToBeUpdated={setVersionToBeUpdated}
      useDocumentKindsResults={useDocumentKindsResults}
      kindId={kind_id}
      closable={false}
      getDocumentKindVisibility={getDocumentKindVisibility}
      useTrackApplicantsResults={useTrackApplicantsResults}
    />
  );
};

export default StandAloneUploadTrackDocument;
