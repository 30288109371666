import React from 'react';
import { Button, Icon, MenuDropdown, MenuDropdownItem, Paper } from '@hometap/htco-components';
import { MAIN_CONTENT_PADDING_X } from 'apps/track-details/TrackDetailsController';

import ActionsTooltip from 'components/Tooltip/ActionsTooltip';
import { QUICK_LINKS } from './quickLinks';
import useCurrentUser from 'hooks/useCurrentUser';

import './TrackDetailsBanner.scss';
import ACTION_TOOLTIP_OPTIONS from '../../data/constants/actionTooltipOptions';
import { HeapWrapper } from 'components/HeapWrapper/HeapWrapper';

const MenuDropdownTrigger = ({ isOpen, disabled }) => {
  return (
    <Button disabled={disabled} theme="secondary" size="small" className="TrackDetailsBannerQuickLinks">
      Quick Links
      <Icon name={isOpen ? 'icon-chevron-up' : 'icon-chevron-down'} />
    </Button>
  );
};

const TrackDetailsBanner = ({ track, applicationUnlockStatus, badge, isTrackDisabled }) => {
  const { isInUnderwriterGroup, isContractor } = useCurrentUser();
  const {
    applicants,
    fullAddress,
    identifier: track_identifier,
    friendlyId,
    product,
    activeOpportunityRecord,
  } = track || {};
  const { name: product_name } = product;
  const { url: opportunityLink } = activeOpportunityRecord || {};

  const applicantDetails = (
    <div className="TrackDetailsBannerMetaSection">
      <Icon size="lg" name="icon-users"></Icon>
      {applicants?.map((applicant, i) => (
        <ActionsTooltip
          value={applicant.fullNameShort}
          className="TrackDetailsBannerApplicantContainer"
          key={`banner-applicant-${i}`}
        >
          <span className="TrackDetailsBannerApplicantName">{applicant.fullNameShort}</span>
          {i !== applicants.length - 1 && <span>,&nbsp;</span>}
        </ActionsTooltip>
      ))}
    </div>
  );

  const propertyDetails = (
    <div className="TrackDetailsBannerMetaSection">
      <Icon size="lg" name="icon-marker-pin"></Icon>
      <ActionsTooltip value={fullAddress}>{fullAddress}</ActionsTooltip>
    </div>
  );

  const noOffsetModifier = {
    name: 'offset',
    options: {
      offset: [-1, -5],
    },
  };

  const links = [...QUICK_LINKS];
  if (isInUnderwriterGroup) {
    links.push({ label: 'UW Calculator', href: `/tracks/${track_identifier}/uw-calculator/` });
  }

  const actions = [{ actionType: ACTION_TOOLTIP_OPTIONS.copy, value: friendlyId }];
  if (opportunityLink) {
    actions.push({ actionType: ACTION_TOOLTIP_OPTIONS.link, toLink: opportunityLink, name: 'Open in SFDC' });
  }

  return (
    <Paper
      theme="plain"
      className="TrackDetailsBanner"
      pad={{
        left: MAIN_CONTENT_PADDING_X,
        right: MAIN_CONTENT_PADDING_X,
      }}
    >
      <div>
        <div className="TrackDetailsBannerInfoHeadingContainer">
          <ActionsTooltip className="TrackDetailsBannerTrackInfoHeading" actions={actions}>
            <HeapWrapper dataId="friendly-id" dataValue={friendlyId}>
              <h2>{friendlyId}</h2>
            </HeapWrapper>
          </ActionsTooltip>
          <span className="TrackDetailsBannerTrackInfoProduct">{product_name}</span>
          {badge && badge}
          {applicationUnlockStatus && (
            <div className="TrackDetailsBannerTrackLockContainer">
              <Icon className="TrackDetailsBannerTrackLockIcon" size="sm" name={applicationUnlockStatus.iconName} />
              <span>{applicationUnlockStatus.displayName}</span>
            </div>
          )}
        </div>
        <div className="TrackDetailsBannerTrackMeta">
          {applicants && applicants.length && applicantDetails}
          {fullAddress && propertyDetails}
        </div>
      </div>

      <div>
        <MenuDropdown
          showCaret={false}
          modifiers={[noOffsetModifier]}
          trigger={MenuDropdownTrigger}
          disabled={isTrackDisabled || isContractor}
        >
          {links.map(({ label, href }, i) => (
            <MenuDropdownItem key={`quickLink-${i}`} target="_blank" rel="noopener noreferrer" href={href}>
              {label}
            </MenuDropdownItem>
          ))}
        </MenuDropdown>
      </div>
    </Paper>
  );
};

export default TrackDetailsBanner;
