import React from 'react';
import { MuiSelect, MuiTextInput, DatePicker, MuiTextarea } from '@hometap/htco-components';
import { useParams } from 'react-router-dom';
import { ApplicationReviewSection } from 'apps/track-details/ApplicationReview/components';
import { isEmpty } from 'lodash';
import './PropertyReportController.scss';
import { getApplicationReviewPageUrls } from 'apps/track-details/utils/trackDetailsLinks';
import { booleanOptionsWithUnknown, booleanOrNoneToValue } from 'utils/boolean';

export const PropertyReportController = ({ track, editDisabled }) => {
  const { trackId } = useParams();
  const { editPropertyReportUrl, historyPropertyReportUrl } = getApplicationReviewPageUrls(trackId);

  // In development of the read-only ARP, disable all inputs
  const readOnly = true;

  const { propertyReportReview, applicants } = track;

  const onClickEdit = function navigateToEditUrl() {
    window.location = editPropertyReportUrl;
  };

  const doNothingOnChange = () => {};

  const getVestingNameForms = () => {
    const applicantsWithTitleMemberReviews = applicants.filter(applicant => !isEmpty(applicant.titleMemberReview));
    return applicantsWithTitleMemberReviews.length === 0 ? (
      <div>There are no title member reviews</div>
    ) : (
      applicantsWithTitleMemberReviews.map((applicant, index) => {
        const { titleMemberReview } = applicant;
        const nameAsAppearsOnId = applicant.governmentId ? applicant.governmentId.nameAsAppearsOnId : 'N/A';

        return (
          <div className="CustomVestingNameInput" key={index}>
            <MuiTextInput
              label={`Vesting name ${index + 1}`}
              unmask="typed"
              disabled={readOnly}
              theme="outlined"
              width="100%"
              value={titleMemberReview.vestingName}
            />
            <p>Applicant name: {applicant.fullName}</p>
            <p>Name on ID: {nameAsAppearsOnId}</p>
          </div>
        );
      })
    );
  };

  return (
    <ApplicationReviewSection
      anchorId="property-report-section"
      sectionTitle="Property report"
      visibleSummary={false}
      historyUrl={historyPropertyReportUrl}
      onEdit={!editDisabled ? onClickEdit : null}
    >
      {isEmpty(propertyReportReview) ? (
        <div>There is no property report</div>
      ) : (
        <>
          <h5>Report Details</h5>
          <MuiSelect
            label="Status"
            name="statusSelector"
            value={propertyReportReview.status.toLowerCase()}
            options={propertyReportReview.statusOptions}
            disabled={readOnly}
            theme="outlined"
            width={'100%'}
            onChange={doNothingOnChange}
          />
          <div className={'TwoItemFormRow'}>
            <MuiSelect
              label="Title Partner"
              name="titlePartnerSelector"
              value={propertyReportReview.titlePartner && propertyReportReview.titlePartner?.toLowerCase()}
              options={propertyReportReview.titlePartnerOptions}
              disabled={readOnly}
              theme="outlined"
              width="100%"
              onChange={doNothingOnChange}
            />
            <MuiTextInput
              label="Title Partner File Number"
              unmask="typed"
              disabled={readOnly}
              theme="outlined"
              width="100%"
              value={propertyReportReview.titlePartnerFileNumber}
            />
          </div>
          <MuiTextInput
            label="Address"
            unmask="typed"
            disabled={readOnly}
            theme="outlined"
            width="100%"
            value={propertyReportReview.fullAddress}
          />
          <MuiTextInput
            label="Grantee"
            unmask="typed"
            disabled={readOnly}
            theme="outlined"
            value={propertyReportReview.grantee}
          />
          <MuiSelect
            label="Is trust"
            name="trustSelector"
            value={booleanOrNoneToValue(propertyReportReview.isTrust)}
            options={booleanOptionsWithUnknown}
            disabled={readOnly}
            theme="outlined"
            width="100%"
            onChange={doNothingOnChange}
          />
          <div className={'TwoItemFormRow'}>
            <DatePicker
              className="FullWidthDatePicker"
              wrapperClassName="FullWidthDatePicker"
              value={propertyReportReview.effectiveDate}
              disabled={readOnly}
              label="Title Search Effective Date"
              theme="outlined"
              onChange={doNothingOnChange}
            />
            <MuiTextInput
              label="APN/PPN"
              disabled={readOnly}
              theme="outlined"
              width="100%"
              value={propertyReportReview.apn}
            />
          </div>
          <MuiTextarea
            label="Legal Description"
            disabled={readOnly}
            theme="outlined"
            value={propertyReportReview.legalDescription}
            onChange={doNothingOnChange}
            className="PropertyReportTextarea"
          />
          <MuiTextarea
            value={propertyReportReview.notes}
            label="Notes"
            theme="outlined"
            width="100%"
            disabled={readOnly}
          />
          <h5 className="CustomVestingNameHeader">Vesting Names</h5>
          {getVestingNameForms()}
        </>
      )}
    </ApplicationReviewSection>
  );
};
