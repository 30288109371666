import React, { useMemo } from 'react';
import cx from 'classnames';
import { gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Button, DataTable, useBoolState } from '@hometap/htco-components';
import useCurrentUser from 'hooks/useCurrentUser';
import useLocalStateForTrack from 'hooks/useLocalStateForTrack';
import TodosConfirmSendReminderModal from './TodosConfirmSendReminderModal';
import { MOBILE_TODO_TABLE_COLUMNS, TodoTableColumns } from '../todoUtils';

import TrackDetailsPadding from 'apps/track-details/TrackDetailsPadding';
import TodosDemoButton from './TodosDemo';

const customStyles = {
  head: {
    style: {
      fontWeight: 'bold',
      fontSize: 16,
    },
  },
  cells: {
    style: {
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: 16,
    },
  },
};

/**
 * @typedef TodosTableParams
 * @type {import('../hooks/useHomeownerTodoSelection').UseHomeownerTodoSelectionReturn}
 * @property {Track} [track]
 * @property {(_: any, overrideTodos: CreateHomeownerTodo) => void} handleCreateTodosSubmit
 */

/** Homeowner Todo Table
 * @param {TodosTableParams} params
 * @returns {JSX.Element}
 */
const TodosTable = ({ track, selectedTodos, setSelectedTodo, handleCreateTodosSubmit }) => {
  const { isInUnderwriterGroup, isInApplicationSpecialistGroup, isInInvestmentManagerGroup, isInServicerGroup } =
    useCurrentUser();
  const { trackId } = useParams();
  const [sortFieldAndDirection, setSortFieldAndDirection] = useLocalStateForTrack({
    trackId,
    key: 'todosTableSort',
    defaultValue: {},
  });
  const showReminderConfirmModal = useBoolState(false);
  const { homeownerTasks = [] } = track || {};
  const openTodos = useMemo(() => homeownerTasks.filter(todo => todo.isOpen), [homeownerTasks]);

  return (
    <TrackDetailsPadding className="TodosTableContainer">
      <div className="TodosTableHeader">
        <h3 className="TodosTableHeading">Homeowner To-dos</h3>
        <div>
          <span className="TodosCountInfo">Number of open to-dos: {openTodos?.length}</span>
          <Button
            size="small"
            disabled={!openTodos.length}
            theme="secondary"
            onClick={showReminderConfirmModal.setTrue}
          >
            Send Reminder
          </Button>
          <TodosConfirmSendReminderModal
            on={showReminderConfirmModal.value}
            onClose={showReminderConfirmModal.setFalse}
            openTodos={openTodos}
            trackId={trackId}
          />
        </div>
      </div>
      <DataTable
        className={cx('TodosTable', { isEmpty: !homeownerTasks.length })}
        data={homeownerTasks}
        respondAt="md"
        columns={TodoTableColumns()}
        customStyles={customStyles}
        mobileColumns={MOBILE_TODO_TABLE_COLUMNS}
        defaultSortAsc={sortFieldAndDirection.direction === 'asc'}
        defaultSortFieldId={sortFieldAndDirection.field}
        onSort={(sortColumn, sortDirection) => {
          setSortFieldAndDirection({ field: sortColumn.id, direction: sortDirection });
        }}
        noDataComponent={<h4 className="TodosTableNoTodos">No to-dos have been assigned to this homeowner</h4>}
      />
      {(isInUnderwriterGroup || isInApplicationSpecialistGroup || isInInvestmentManagerGroup || isInServicerGroup) && (
        <Link className="htco-Button TodosTableCreateLink" to="select-todos">
          Add to-do
        </Link>
      )}
      <TodosDemoButton
        selectedTodos={selectedTodos}
        handleCreateTodosSubmit={handleCreateTodosSubmit}
        track={track}
        setSelectedTodo={setSelectedTodo}
      />
    </TrackDetailsPadding>
  );
};

TodosTable.fragments = {
  HomeownerTodos: gql`
    fragment HomeownerTodos on TrackNode {
      homeownerTasks {
        name
        description
        taskStatus
        createdAt
        identifier
        notes
        isOpen
        id

        variables {
          name
          value
        }
      }
    }
  `,
};

export default TodosTable;
