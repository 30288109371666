import { currencyWithCents } from 'utils/numbers';
import { underscoreToCapitalCase } from './utils';
/** Process & filter Snapshot Event Changes
 * @param {import("./useAppUnlockChangesQuery").SnapshotEventChanges[]} snapshotEventsChanges Snapshot Events Changes
 * @param {ObservedModel[]} observedModels Model's being observed
 * @returns {ProcessedEventChanges[]} Processed & filtered Snapshot Events Changes
 */
export default function processSnapshotEventsChanges(snapshotEventsChanges, observedModels) {
  /** @type {Record<string, ObservedModel>} */
  const observedNameToModel = {};
  observedModels.forEach(observed => {
    observedNameToModel[observed.name] = observed;
  });
  /** @type {ProcessedEventChanges[]} */
  const eventsChanges = [];
  snapshotEventsChanges.forEach(snapshotEventChanges => {
    const changes = new ProcessedEventChanges(snapshotEventChanges, observedNameToModel);
    const { groupByModelChanges } = changes;
    if (groupByModelChanges.length > 0) {
      eventsChanges.push(changes);
    }
  });
  eventsChanges.sort((a, b) => {
    return b.submitDate - a.submitDate;
  });
  return eventsChanges;
}

export class ProcessedEventChanges {
  /** Process the snapshot event changes
   * @param {import("./useAppUnlockChangesQuery").SnapshotEventChanges} snapshotEventChanges
   * @param {Record<string, ObservedModel>} observedNameToModel
   */
  constructor({ rightEvent, snapshotsChanges }, observedNameToModel) {
    /** @type {Record<string, ObservedModel>} */
    this.observedNameToModel = observedNameToModel;
    /** @type {Date} */
    this.submitDate = new Date(rightEvent.createdAt);
    /** @type {string} */
    this.submitDateDisplay = formatDate(rightEvent.createdAt);
    /** @type {Record<string, GroupByModelChanges>} */
    this.modelNameToChanges = {};
    // Process snapshot changes
    snapshotsChanges.forEach(snapshotChanges => {
      this.processSnapshotChanges(snapshotChanges);
    });
  }

  get groupByModelChanges() {
    return Object.values(this.modelNameToChanges);
  }

  /** Process a snapshot changes
   * @param {import("./useAppUnlockChangesQuery").SnapshotChanges} snapshotChanges
   */
  processSnapshotChanges(snapshotChanges) {
    const rightOrLeftSnapshot = snapshotChanges.rightSnapshot || snapshotChanges.leftSnapshot;
    const { modelName } = rightOrLeftSnapshot;
    const observedModel = this.observedNameToModel[modelName];
    if (!observedModel) return;
    let groupByModelChanges = this.modelNameToChanges[modelName];
    if (!groupByModelChanges) {
      groupByModelChanges = new GroupByModelChanges(modelName, observedModel);
      this.modelNameToChanges[modelName] = groupByModelChanges;
    }
    groupByModelChanges.addSnapshotChanges(snapshotChanges, rightOrLeftSnapshot);
  }
}

export class GroupByModelChanges {
  /**
   * @param {string} modelName
   * @param {ObservedModel} observedModel
   */
  constructor(modelName, observedModel) {
    /** @type {string} */
    this.modelName = modelName;
    /** @type {ObservedModel} */
    this.observedModel = observedModel;
    /** @type {string} */
    this.modelDisplayName = observedModel.displayName;
    /** @type {AddedOrRemovedModel[]} */
    this.modelsAdded = [];
    /** @type {AddedOrRemovedModel[]} */
    this.modelsRemoved = [];
    /** @type {ModelChanges[]} */
    this.modelsChanges = [];
  }

  /** Add Snapshot Changes to this GroupByModelChanges
   * @param {import("./useAppUnlockChangesQuery").SnapshotChanges} snapshotChanges
   * @param {import("./useAppUnlockChangesQuery").Snapshot} rightOrLeftSnapshot
   */
  addSnapshotChanges(snapshotChanges, rightOrLeftSnapshot) {
    const snapshotAsObject = snapshotToObject(rightOrLeftSnapshot);
    const instanceName = this.observedModel.getInstanceName(snapshotAsObject);
    switch (snapshotChanges.objectChangeType) {
      case CHANGE_TYPE.added: {
        this.modelsAdded.push({
          modelName: this.modelName,
          instanceName,
        });
        break;
      }

      case CHANGE_TYPE.removed: {
        this.modelsRemoved.push({
          modelName: this.modelName,
          instanceName,
        });
        break;
      }

      case CHANGE_TYPE.changed: {
        const modelChanges = new ModelChanges(this.modelName, instanceName, this.observedModel);
        this.modelsChanges.push(modelChanges);
        snapshotChanges.fieldChanges.forEach(fieldChange => {
          modelChanges.addSnapshotFieldChange(fieldChange);
        });
        break;
      }

      default: {
        break;
      }
    }
  }
}

const boolDisplay = {
  true: 'Yes',
  false: 'No',
};

/**
 * @typedef AddedOrRemovedModel
 * @type {object}
 * @property {string} modelName model type name
 * @property {string} instanceName model instance name
 */

export class ModelChanges {
  /** Constructor
   * @param {string} modelName
   * @param {string} instanceName
   * @param {ObservedModel} observedModel
   */
  constructor(modelName, instanceName, observedModel) {
    /** @type {string} */
    this.modelName = modelName;
    /** @type {string} */
    this.instanceName = instanceName;
    /** @type {ObservedModel} */
    this.observedModel = observedModel;
    /** @type {AddedOrRemovedField[]} */
    this.fieldsAdded = [];
    /** @type {AddedOrRemovedField[]} */
    this.fieldsRemoved = [];
    /** @type {FieldChanges[]} */
    this.fieldsChanges = [];
  }

  /** Deserializer field & change
   * @param {import("./useAppUnlockChangesQuery").SnapshotFieldChange} SnapshotFieldChange
   */
  addSnapshotFieldChange({ changeType, fieldName, leftValue, rightValue }) {
    const { fieldDisplayNamesOverrides, secretValueFields, hiddenFields } = this.observedModel;
    const isHidden = hiddenFields?.includes(fieldName) ?? false;
    if (isHidden) {
      return;
    }

    leftValue = removeDoubleQuotes(leftValue);
    rightValue = removeDoubleQuotes(rightValue);
    const fieldNameDisplayOverride = fieldDisplayNamesOverrides && fieldDisplayNamesOverrides[fieldName];
    const fieldDisplayName = fieldNameDisplayOverride ?? underscoreToCapitalCase(fieldName);
    const rightDisplayValue = rightValue && this.fieldValueToDisplayValue(fieldName, rightValue);
    const leftDisplayValue = leftValue && this.fieldValueToDisplayValue(fieldName, leftValue);
    const isSecret = secretValueFields?.includes(fieldName) ?? false;

    switch (changeType) {
      case CHANGE_TYPE.added: {
        this.fieldsAdded.push({
          fieldName,
          fieldDisplayName,
          value: rightValue,
          displayValue: rightDisplayValue,
          isSecret,
        });
        break;
      }

      case CHANGE_TYPE.removed: {
        this.fieldsRemoved.push({
          fieldName,
          fieldDisplayName,
          value: leftValue,
          displayValue: leftDisplayValue,
          isSecret,
        });
        break;
      }

      case CHANGE_TYPE.changed: {
        this.fieldsChanges.push({
          fieldName,
          fieldDisplayName,
          oldValue: leftValue,
          oldDisplayValue: leftDisplayValue,
          newValue: rightValue,
          newDisplayValue: rightDisplayValue,
          isSecret,
        });
        break;
      }

      default: {
        break;
      }
    }
  }

  fieldValueToDisplayValue(fieldName, value) {
    const { enumFields, enumFieldsOverrides, currencyFields, booleanFields } = this.observedModel;
    // Enum override
    const enumFieldOverride = enumFieldsOverrides && enumFieldsOverrides[fieldName];
    if (enumFieldOverride && enumFieldOverride[value]) {
      return enumFieldOverride[value];
    }

    // Enums defaults to underscore_case -> Capitalize Case
    const isEnum = enumFields?.includes(fieldName);
    if (isEnum) {
      return underscoreToCapitalCase(value);
    }

    // Currency
    const isCurrency = currencyFields?.includes(fieldName) ?? false;
    if (isCurrency) {
      return currencyWithCents(value);
    }

    // Booleans map true -> Yes & false -> No
    const isBoolean = booleanFields?.includes(fieldName) ?? false;
    if (isBoolean && boolDisplay[value]) {
      return boolDisplay[value];
    }
    return value;
  }
}

// * * * * * * * * * Helpers * * * * * * * * *

/** Format the given date
 * @param {string} dateTime date-string
 * @returns formatted date-string
 */
const formatDate = dateTime => {
  return dateTime.slice(0, dateTime.indexOf('T'));
};

const removeDoubleQuotes = value => {
  if (!value) return '';

  return value.replace(/"/g, '');
};

/** Convert Snapshot into object represetation
 * @param {import("./useAppUnlockChangesQuery").Snapshot} snapshot
 * @return {Record<string, string>} Snapshot as an object
 */
export const snapshotToObject = ({ modelName, fields }) => {
  const asObject = { modelName };
  fields.forEach(({ fieldName, fieldValue }) => {
    asObject[fieldName] = removeDoubleQuotes(fieldValue);
  });
  return asObject;
};

// * * * * * * * * * Types * * * * * * * * *

const CHANGE_TYPE = {
  changed: 'CHANGED',
  removed: 'REMOVED',
  added: 'ADDED',
};

/**
 * @typedef ObservedModel
 * @type {object}
 * @property {string} name model name
 * @property {string} displayName model display name
 * @property {(Record<string, string>) => string} getInstanceName Construct a model instance's display name
 * @property {string[]?} hiddenFields [hidden_field_name, ...]   Fields completely hidden from view
 * @property {Record<string, string>?} fieldDisplayNamesOverrides field_name -> field_display_name   Overrides for field diplay names. Otherwise a field name will be converted from underscore_case to Capitalized Case
 * @property {string[]?} secretValueFields [secret_value_field_name, ...]   Fields with secret value fields
 * @property {string[]?} booleanFields [boolean_field_name, ...]  Boolean fields. Maps true -> Yes & false -> No.
 * @property {string[]?} currencyFields [currency_field_name, ...]   Formats current fields.
 * @property {string[]?} enumFields [enum_field_name, ...]   Enum fields where the values are converted from underscore_case -> Capitalized Case
 * @property {Record<string, Record<string, string>?} enumFieldsOverrides field_name -> (field_enum_value -> field_display_value)   Overrides for the enum values. Defaults to underscore_case -> Capitalized Case
 */

/**
 * @typedef AddedOrRemovedField
 * @type {object}
 * @property {string} fieldName field name
 * @property {string} fieldDisplayName field display name
 * @property {string} fieldValue stringified field value
 * @property {string} fieldDisplayValue field display value
 * @property {boolean} isSecret Is the field value a secret?
 */

/**
 * @typedef FieldChanges
 * @type {object}
 * @property {string} fieldName field name
 * @property {string} fieldDisplayName field display name
 * @property {string} oldValue Old stringified field value
 * @property {string} oldDisplayValue Old display value
 * @property {string} newValue New stringified field value
 * @property {string} newDisplayValue New display value
 * @property {boolean} isSecret Is the field value a secret?
 */
