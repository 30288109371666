import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useOutletContext } from 'react-router-dom';
import { Icon } from '@hometap/htco-components';

import './SSNShowHide.scss';

const SSN_VIEWABLE_TIMEOUT_SECONDS = 10;

const ICON_NAMES = {
  show: 'icon-show',
  hide: 'icon-hide',
};

const SSNShowHide = ({ value, disabled = false }) => {
  const [shouldHideValue, setShouldHideValue] = useState(true);
  // Grab the value from context assuming this component is nested within "TrackDetailsController".
  // If not we default to false and would rely on the disabled prop
  const { isTrackDisabled } = useOutletContext() || { isTrackDisabled: false };

  const isButtonDisabled = disabled || isTrackDisabled;
  const renderedValue = shouldHideValue ? '***-**-****' : value;
  const handleValueToggle = event => {
    !isTrackDisabled && setShouldHideValue(!shouldHideValue);
    event.stopPropagation();
  };

  useEffect(() => {
    !shouldHideValue &&
      setTimeout(() => {
        setShouldHideValue(!shouldHideValue);
      }, SSN_VIEWABLE_TIMEOUT_SECONDS * 1000);
  }, [shouldHideValue]);

  return (
    <div className="SSNShowHideContainer">
      <h5 className="SSNShowHideValue">{renderedValue}</h5>
      <div className={cx('SSNShowHideButton', { isDisabled: isButtonDisabled })} onClick={handleValueToggle}>
        <Icon name={shouldHideValue ? ICON_NAMES.show : ICON_NAMES.hide} />
      </div>
    </div>
  );
};

SSNShowHide.propTypes = {
  value: PropTypes.node,
};

export default SSNShowHide;
