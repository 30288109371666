import useLocalStateForTrack from 'hooks/useLocalStateForTrack';

/**
 * @typedef UseHomeownerTodoSelectionReturn
 * @type {object}
 * @property {HomeownerTodoSelected[]} selectedTodos
 * @property {React.Dispatch<HomeownerTodoSelected[]>} setSelectedTodo
 * @property {(todo: HomeownerTodoChoice|HomeownerTodoSelected) => boolean} isSelected
 * @property {(todo: HomeownerTodoChoice) => void} selectTodo
 * @property {(todo: HomeownerTodoChoice|HomeownerTodoSelected) => void} deselectTodo
 * @property {(todo: HomeownerTodoSelected, update: Partial<HomeownerTodoSelected>) => void} updateTodo
 */

/** Use Homeowner Todo Selection which is cached in the browser's local storage
 * @param {string} trackId Unique Track ID
 * @returns {UseHomeownerTodoSelectionReturn}
 */
export default function useHomeownerTodoSelection(trackId) {
  /** @type {[HomeownerTodoSelected[], React.Dispatch<HomeownerTodoSelected[]>]} */
  const [selectedTodos, setSelectedTodo] = useLocalStateForTrack({ trackId, key: 'todos', defaultValue: [] });

  /** Is the given Homeowner Todo Choice already selected?
   * @param {HomeownerTodoChoice} todo The Homeowner Todo Choice being checked for selection
   * @returns {boolean}
   */
  const isSelected = ({ kind, applicant }) => {
    return selectedTodos.some(selectedTodo => {
      return kind === selectedTodo.kind && applicant?.identifier === selectedTodo.applicant?.identifier;
    });
  };

  /** Select a Homeowner Todo Choice
   * @param {HomeownerTodoChoice} todo The Homeowner Todo Choice being selected
   */
  const selectTodo = ({ kind, displayLabel, applicant }) => {
    const addedTodo = { kind, displayLabel, notes: '', applicant };
    setSelectedTodo([...selectedTodos, addedTodo]);
  };

  /** Deselect a Homeowner Todo
   * @param {HomeownerTodoChoice|HomeownerTodoSelected} todo The Homeowner Todo being deselected
   */
  const deselectTodo = ({ kind, applicant }) => {
    const updatedTodos = selectedTodos.filter(selectedTodo => {
      return kind !== selectedTodo.kind || applicant?.identifier !== selectedTodo.applicant?.identifier;
    });
    setSelectedTodo(updatedTodos);
  };

  /** Update the Selected Homeowner Todo
   * @param {HomeownerTodoSelected} todo The Selected Homeowner Todo being update
   * @param {Parital<HomeownerTodoSelected>} update The Update to the Selected Homeowner Todo
   */
  const updateTodo = ({ kind, applicant }, update) => {
    const updatedTodos = selectedTodos.map(selectedTodo => {
      if (kind === selectedTodo.kind && applicant?.person?.identifier === selectedTodo.applicant?.person?.identifier) {
        return { ...selectedTodo, ...update };
      }
      return selectedTodo;
    });
    setSelectedTodo(updatedTodos);
  };

  return {
    selectedTodos,
    setSelectedTodo,
    isSelected,
    selectTodo,
    deselectTodo,
    updateTodo,
  };
}
