import React, { useMemo, useState } from 'react';
import TrackDetailsPadding from 'apps/track-details/TrackDetailsPadding';
import DocumentBatchProgress from './components/DocumentBatchProgress/DocumentBatchProgress';
import { Button, Container, DataTable, NotFoundBlock } from '@hometap/htco-components';
import { showNotification } from 'utils/toasts';
import { useMutation } from '@apollo/client';
import { CANCEL_DOCUMENT_SET_BATCH_REQUEST } from './documentSetBatchRequests';
import { GENERIC_BATCH_ERROR_MESSAGE } from './data/constants';
import { downloadThenMaybeConvertDocument } from 'apps/track-details/Documents/documentRequests';
import { downloadBlobFile } from 'apps/track-details/Documents/DocumentsUtils';
import CreateDocumentSetBatchSlider from './components/CreateDocumentSetBatchSlider/CreateDocumentSetBatchSlider';
import { getDateTimeFormat } from 'utils/dates';
import useDocumentSetBatchPagination from './hooks/useDocumentSetBatchPagination';

import './DocumentSetBatch.scss';

const DocumentSetBatchController = () => {
  const [cancelDocumentSetBatch] = useMutation(CANCEL_DOCUMENT_SET_BATCH_REQUEST);
  const [createDocumentSetSliderOpen, setCreateDocumentSetSliderOpen] = useState(false);
  const {
    paginationData,
    handleRefetch,
    error: documentSetBatchError,
    handleSort,
    handleLoadMore,
    batches,
    loading,
    hasMore,
  } = useDocumentSetBatchPagination({
    defaultSortDirection: 'asc',
    defaultSortField: 'start_time',
    defaultSortId: 'requestedDate',
  });

  const batchRequestSelectors = [
    {
      id: 'batchRequestName',
      sortField: 'business_key',
      name: 'Batch Name',
      sortable: true,
      minWidth: '400px',
      selector: row => row.batchRequestName,
      cell: row => (
        <>
          {row.bpmProcessId ? (
            <a href={`/document-set-batch/batch-details/${row.bpmProcessId}`}>{row.batchRequestName}</a>
          ) : (
            <span>{row.batchRequestName}</span>
          )}
        </>
      ),
    },
    {
      id: 'docSetType',
      name: 'Doc Set Type',
      sortable: false,
      selector: row => row?.documentSetTemplate?.name,
      cell: row => <>{row?.documentSetTemplate?.name}</>,
    },
    {
      id: 'requestedDate',
      name: 'Requested Date',
      sortField: 'start_time',
      sortable: true,
      selector: row => new Date(row.requestedAt),
      cell: ({ requestedAt }) => <>{getDateTimeFormat(requestedAt, 'MMM DD, YYYY')}</>,
    },
    {
      id: 'requestedBy',
      name: 'Requested By',
      sortable: false,
      selector: ({ requestedBy }) => `${requestedBy?.firstName} ${requestedBy?.lastName}`,
      cell: ({ requestedBy }) => (
        <>
          {requestedBy?.firstName} {requestedBy?.lastName}
        </>
      ),
    },
    {
      id: 'documentSets',
      name: <span className="DocumentSetBatchTableIncludedDocumentSets">Included Document Sets</span>,
      sortable: true,
      sortField: 'state',
      selector: row => row.documentSetsCount,
      right: true,
      cell: row => {
        return (
          <DocumentBatchProgress
            document={row}
            failedReason={GENERIC_BATCH_ERROR_MESSAGE}
            onDownload={handleDownloadDocumentSet}
            onCancel={handleCancelDocumentSetBatch}
            batchStatus={row?.batchStatus}
            {...row?.documentSetsCount}
          />
        );
      },
    },
  ];

  // Includes the batch name, batch type, requested date and progress columns
  const batchRequestMobileSelectors = batchRequestSelectors.slice(0, 3).concat(batchRequestSelectors[4]);
  batchRequestMobileSelectors[0].minWidth = '200px';

  /**
   * Given a document set batch object, this function will download the document set batch to your browser
   * @param {Object} documentSetObject
   */
  const handleDownloadDocumentSet = async documentSetObject => {
    const response = await downloadThenMaybeConvertDocument({
      id: documentSetObject?.documentSetBatchId,
      file_extension: 'zip',
    });
    await downloadBlobFile(response, `${documentSetObject?.batchRequestName}.zip`);
  };

  /**
   * Cancel a BPM Process that is currently in progress
   * @param {string} bpmProcessId
   */
  const handleCancelDocumentSetBatch = async bpmProcessId => {
    try {
      await cancelDocumentSetBatch({ variables: { bpmProcessId: bpmProcessId } });
      // Once cancelled - refetch the document set batches
      await handleRefetch();
      showNotification({
        type: 'success',
        title: 'Success',
        description: 'Document set batch successfully cancelled.',
      });
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Error',
        description: 'There was an error cancelling this document set batch.',
      });
    }
  };

  const customStyles = {
    cells: {
      style: {
        paddingTop: 24,
        paddingBottom: 24,
        fontSize: 16,
      },
    },
  };

  const errorDataComponent = useMemo(() => {
    if (documentSetBatchError) {
      return (
        <NotFoundBlock
          error={`${documentSetBatchError?.networkError?.statusCode || 404} Error`}
          title={"We're having trouble fetching the document set batches. Please try refreshing."}
        />
      );
    }
    return null;
  }, [documentSetBatchError]);

  return (
    <>
      <CreateDocumentSetBatchSlider
        sliderStatus={createDocumentSetSliderOpen}
        setSliderStatus={setCreateDocumentSetSliderOpen}
        getDocumentSetBatches={() => handleRefetch('asc', 'start_time', 'requestedDate')}
      />
      <TrackDetailsPadding className="DocumentSetBatchControllerPadding">
        <Container className="DocumentSetBatchContainer">
          <div className="DocumentSetBatchControllerRow">
            <h3>Document Set Batches</h3>
            <Button
              size="small"
              className="DocumentSetBatchControllerCreateButton"
              onClick={() => setCreateDocumentSetSliderOpen(true)}
            >
              Create new batch
            </Button>
          </div>
          <div>
            <DataTable
              data={batches ?? []}
              loading={loading}
              columns={batchRequestSelectors}
              customStyles={customStyles}
              noDataComponent={'There are no batch document sets to display'}
              errorDataComponent={errorDataComponent}
              onSort={(selectedSortColumn, sortDirection) =>
                handleSort(selectedSortColumn.sortField, sortDirection, selectedSortColumn.id)
              }
              sortServer={true}
              defaultSortFieldId={paginationData?.sortId}
              defaultSortAsc={paginationData?.sortDirection === 'asc'}
              // Mobile view setup
              mobileColumns={batchRequestMobileSelectors}
              respondAt="sm"
            />
            {!loading && hasMore && (
              <Button
                size="small"
                className="DocumentSetBatchControllerGetMoreBatchesButton"
                onClick={handleLoadMore}
                theme="secondary"
              >
                Load more
              </Button>
            )}
          </div>
        </Container>
      </TrackDetailsPadding>
    </>
  );
};

export default DocumentSetBatchController;
