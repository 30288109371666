import React from 'react';
import { LoadingPlaceholder } from '@hometap/htco-components';

import ApplicantFullNameAndType from 'components/ApplicantFullNameAndType/ApplicantFullNameAndType';
import RiskBandDisplay from 'components/RiskBandDisplay/RiskBandDisplay';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import { useSpecificContentQuery } from 'apps/track-details/tasks/hooks/useSpecificContentQuery';
import { formatMMDDYYYY, getDaysUntil } from 'utils/dates';
import { isWarningRiskBand } from 'utils/riskBand';

import { GET_RISK_BAND_CHECK_DATA } from './getRiskBandCheckData';
import TaskDetailContentSectionWrapper from '../../TaskDetailContentSectionWrapper/TaskDetailContentSectionWrapper';

const RiskBandMetaData = ({ riskBand }) => {
  const formattedExpirationDate = formatMMDDYYYY(riskBand.expirationDate);
  const expiresIn = getDaysUntil(riskBand.expirationDate);

  return (
    <li>
      Expires {formattedExpirationDate}, in {expiresIn} days
      {isWarningRiskBand(riskBand.value) && (
        <li>
          Applicant's risk band requires the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.getguru.com/card/Tz6j65rc/Additional-Risk-Rubric"
          >
            additional risk rubric process
          </a>
        </li>
      )}
      {!riskBand.isRiskScorePassing && (
        <div className="RiskBandMetaDescription">Applicant's risk band warrants discussion with your IM to DQ.</div>
      )}
    </li>
  );
};

const RiskBandCheck = () => {
  const { data = {}, loading, trackId } = useSpecificContentQuery(GET_RISK_BAND_CHECK_DATA);
  const { track = {} } = data;
  const { applicants = [] } = track;
  const { hasOpsPermissions } = useCurrentUserPermissions();

  return (
    <div className="TaskDetailContent">
      <TaskDetailContentSectionWrapper heading="Applicant Information">
        <LoadingPlaceholder isLoading={loading}>
          {({ loadingClass }) => (
            <div className={loadingClass}>
              {applicants.map(({ fullNameShort, isPrimaryApplicant, id, riskBand }) => {
                return (
                  <ul className="TaskDetailList" key={id}>
                    <ApplicantFullNameAndType applicant={{ fullNameShort, isPrimaryApplicant }} />
                    <li>
                      <span>Risk Band: </span>
                      <RiskBandDisplay trackId={trackId} hasOpsPermissions={hasOpsPermissions} riskBand={riskBand} />
                    </li>
                    {riskBand && <RiskBandMetaData riskBand={riskBand} />}
                  </ul>
                );
              })}
            </div>
          )}
        </LoadingPlaceholder>
      </TaskDetailContentSectionWrapper>
    </div>
  );
};

export default RiskBandCheck;
