import { getRandomId } from '../../../../utils/taskForms';
import { formatMMDDYYYY } from '../../../../../../utils/dates';

export const getNewAdverseEventSection = () => {
  const newAdverseEventIdentifier = `new-${getRandomId()}`;
  return {
    newAdverseEvent: true,
    identifier: newAdverseEventIdentifier,
    kind: '',
    personIds: [],
    resolutionDate: null,
    hasNotBeenResolved: undefined,
    bankruptcyChapter: '',
    bankruptcyOrder: '',
    isForSubjectProperty: undefined,
    notes: '',
    subSectionLabel: 'New adverse event',
    selectorId: newAdverseEventIdentifier,
  };
};

export const getAdverseEventSectionsData = adverseEvents => {
  return {
    sections:
      adverseEvents?.map(adverseEvent => {
        const { resolutionDate, hasNotBeenResolved, identifier } = adverseEvent;
        return {
          ...adverseEvent,
          identifier,
          valueLabel: `Resolution date: `,
          value: hasNotBeenResolved || resolutionDate == null ? 'Unresolved' : formatMMDDYYYY(resolutionDate),
        };
      }) ?? [],
  };
};

const adverseEventToSection = (adverseEvent, adverseEventKindOptions) => {
  const type = adverseEventKindOptions.find(valLabelPair => valLabelPair.value === adverseEvent.kind.toLowerCase());
  return {
    ...adverseEvent,
    ...{
      subSectionLabel: type?.label,
      selectorId: adverseEvent.identifier,
    },
  };
};

export const getInitialSectionsAdverseEvents = (adverseEvents, adverseEventKindOptions) =>
  adverseEvents?.map(adverseEvent => adverseEventToSection(adverseEvent, adverseEventKindOptions));

export const foreclosure = 'foreclosure';
export const bankruptcy = 'bankruptcy';
export const shortSale = 'short_sale';

export const sortAdverseEvents = adverseEvents => {
  const kindPriority = {
    [bankruptcy.toUpperCase()]: 1,
    [foreclosure.toUpperCase()]: 2,
    [shortSale.toUpperCase()]: 3,
  };

  return adverseEvents?.sort((a, b) => {
    // Handle sorting by resolution date, placing unresolved at the end
    const dateA = a.resolutionDate ? new Date(a.resolutionDate) : new Date(9999, 11, 31);
    const dateB = b.resolutionDate ? new Date(b.resolutionDate) : new Date(9999, 11, 31);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return (kindPriority[a.kind] || 99) - (kindPriority[b.kind] || 99);
  });
};
