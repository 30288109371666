import React, { useEffect, useState } from 'react';
import { Checkbox, Icon, DataTable, NotFoundBlock, useAsync, FormError, ModalConfirm } from '@hometap/htco-components';
import {
  deleteMultipleDocuments,
  downloadThenMaybeConvertDocument,
  toggleDocumentPin,
  uploadDemoDocument,
} from './documentRequests';
import { ButtonBar } from './components/ButtonBar/ButtonBar';
import { SliderController } from './components/SliderController/SliderController';
import cx from 'classnames';
import { formatMonthDayYearTime } from 'utils/dates';
import { useParams } from 'react-router';
import useDocumentKinds from './hooks/useDocumentKinds';
import EditDocument from './components/EditDocument/EditDocument';
import MergeDocumentsModal from './components/MergeDocumentsModal/MergeDocumentsModal';
import DocumentActions from './components/DropdownActions/DocumentActions';
import {
  getDeleteDocumentModalConfirmOptions,
  getDeleteDocumentsModalConfirmOptions,
  getDocumentUUID4IdsWithMappedFilesByAvStatus,
  downloadBlobFile,
  downloadAndCompressFiles,
  getAVBatchFileToDownloadAndMaybeShowToasts,
  getDownloadDocumentsModalConfirmOptions,
  getDownloadDocumentModalConfirmOptions,
  getEncryptedDocumentDownloadModalConfirmOptions,
  getMergeDocumentModalConfirmOptions,
} from './DocumentsUtils';
import { getDocumentToastSettings, showPinDocumentErrorToast } from './DocumentToasts';
import './Documents.scss';
import { showNotification } from '../../../utils/toasts';
import { cloneDeep } from 'lodash';
import { AV_STATUS } from './data/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useScrollPosition } from './hooks/useScrollPosition';
import useTimeoutError from 'hooks/useTimeoutError';
import useTrackDocuments from './hooks/useTrackDocuments';
import CreateDocumentSet from './components/CreateDocumentSet/CreateDocumentSet';
import DocumentFilters from './components/DocumentFilters/DocumentFilters';
import DemoButton from 'components/DemoButton/DemoButton';
import TrackDetailsPadding from '../TrackDetailsPadding';
import { useTrackApplicants } from './hooks/useTrackApplicants';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import useCurrentUser from 'hooks/useCurrentUser';
import useQueryParams from 'hooks/useQueryParams';
import { omitBy, isNil } from 'lodash';

const DocumentsController = () => {
  const { trackId } = useParams();
  const { searchParamsObject, setSearchParams } = useQueryParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { isContractor } = useCurrentUser();

  const useTrackApplicantsResults = useTrackApplicants(trackId);
  const { documents, documentsLoading, documentsError, executeGetTrackDocuments, handleFilterDocuments, filters } =
    useTrackDocuments(trackId, useTrackApplicantsResults.applicants, searchParamsObject);

  const {
    loading: deleteDocumentsLoading,
    error: deleteDocumentsError,
    execute: executeDeletingDocuments,
    setError: setDeleteDocumentsError,
  } = useAsync(deleteMultipleDocuments);
  const [selectedDocuments, setSelectedDocuments] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openDocumentSet, setOpenDocumentSet] = useState(false);
  const [documentToEdit, setDocumentToEdit] = useState(null);
  const [openEditDocument, setOpenEditDocument] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  // if there is a new version, Object will be populated
  const [versionToBeUpdated, setVersionToBeUpdated] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [modalConfirmOptions, setModalConfirmOptions] = useState(null);
  const [modalMergeOptions, setModalMergeOptions] = useState({ open: false });

  const useDocumentKindsResults = useDocumentKinds();
  // TODO Please pass scrollPosition in local storage instead passing to the document viewer
  //  https://hometap.atlassian.net/browse/BEAN-1443
  const scrollPosition = useScrollPosition();
  const { getDocumentKindVisibility } = useDocumentKinds();
  const [timeoutError, setTimeoutError] = useTimeoutError();

  const isDataTableLoading = documentsLoading || deleteDocumentsLoading;

  const { postMessage } = useBroadcastChannel({
    channelName: 'DOCS_UPDATED',
  });

  const isDocumentsEditAccess = !isContractor;
  // // Handle checked documents
  useEffect(() => {
    // constructs object: {key:{checked: bool, pinned: bool}, key:...}
    setSelectedDocuments(prevSelectedDocuments => {
      // to avoid adding additional dependency "selectAll" to the useEffect
      // we moved our logic here to compare documents
      const checkedPrevSelectedDocumentIds = prevSelectedDocuments
        ? Object.entries(prevSelectedDocuments)
            .filter(doc => doc[1].checked)
            .map(doc => doc[0])
        : [];
      const checkedPrevSelectedDocumentIdsLength = checkedPrevSelectedDocumentIds.length;
      const isSelectedAll =
        checkedPrevSelectedDocumentIdsLength > 0 &&
        checkedPrevSelectedDocumentIdsLength === documents.length &&
        documents.every(document => checkedPrevSelectedDocumentIds.includes(document.code || document.id));
      if (!isSelectedAll) {
        // if we select all the documents and upload new one
        setSelectAll(false);
      }

      const tempSelectedDocuments = {};
      documents.forEach(doc => {
        tempSelectedDocuments[doc.code] = {
          ...doc,
          checked: prevSelectedDocuments[doc.code]?.checked || isSelectedAll,
        };
      });
      return tempSelectedDocuments;
    });
  }, [documents]);

  /**
   * Handle scrollPosition that we store in the location.store.
   * If the scrollPosition exists we scroll to it and clear our location state.
   * For example, we pass it to keep the same spot after close the preview document
   */
  useEffect(() => {
    if (location?.state?.scrollPosition && !documentsLoading) {
      window.scrollTo(location?.state?.scrollPosition);
      // clear our state after we scroll to position
      navigate(location.pathname, { replace: true, state: undefined });
    }
  }, [documentsLoading, location?.state?.scrollPosition, location.pathname, navigate]);

  /**
   * defines behavior for individual checkbox click vs select all/ deselect all
   * @param {string} code
   */
  const onCheckboxClick = code => {
    const tempSelectedDocs = { ...selectedDocuments };
    if (code === 'selectAll') {
      Object.keys(tempSelectedDocs).forEach(key => {
        tempSelectedDocs[key].checked = !selectAll;
      });
      setSelectAll(!selectAll);
    } else {
      tempSelectedDocs[code].checked = !selectedDocuments[code].checked;
      // check select all checkbox if all boxes are checked
      // uncheck select all checkbox if a document has been unchecked
      const shouldSelectAll = Object.values(tempSelectedDocs).every(doc => doc.checked === true);
      setSelectAll(shouldSelectAll);
    }

    setSelectedDocuments(tempSelectedDocs);
  };

  const handleDownloadDocument = async ({ id, filename, file_extension }) => {
    try {
      handleModalConfirmClose();
      const response = await downloadThenMaybeConvertDocument({ id, file_extension });
      await downloadBlobFile(response, filename);
      showNotification(getDocumentToastSettings(filename, AV_STATUS.Clean));
    } catch (error) {
      setTimeoutError(error);
    }
  };

  const defaultColumns = [
    {
      name: 'Name',
      sortable: true,
      sortKey: 'name',
      maxWidth: '500px',
      minWidth: '350px',
      selector: row => row.filename,
      cell: ({ filename, id, has_other_versions }, index) => {
        return (
          <Link
            className="DocumentsControllerLink"
            to={`/tracks/${trackId}/documents/${id}`}
            state={{
              viewDocumentIndex: index,
              documents: cloneDeep(documents),
              scrollPosition,
            }}
          >
            <span className="DocumentsControllerLinkName">{filename}</span>
            {has_other_versions && (
              <span className="DocumentsControllerLinkIcon">
                <Icon name="icon-history" />
              </span>
            )}
          </Link>
        );
      },
    },
    {
      name: 'Type',
      minWidth: '350px',
      sortable: true,
      sortKey: 'kind',
      cell: row => {
        const fullName = row?.applicant?.fullName || row?.applicant?.fullNameShort;
        return (
          <div className="w-full">
            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">{row.kind_display}</div>
            {!!fullName && <div className="DocumentsControllerApplicant">{fullName}</div>}
          </div>
        );
      },
    },
    {
      name: 'Uploaded',
      sortable: true,
      width: '250px',
      sortKey: 'created_at',
      selector: row => formatMonthDayYearTime(row.created_at),
    },
    {
      name: 'Modified',
      sortable: true,
      width: '250px',
      sortKey: 'modified_at',
      selector: row => formatMonthDayYearTime(row.modified_at),
    },
    {
      name: 'Visibility',
      sortable: false,
      sortKey: 'internal',
      width: '150px',
      selector: row => (row.internal ? 'Internal' : 'Both'),
    },
  ];

  let columns = defaultColumns;

  if (isDocumentsEditAccess) {
    columns = [
      {
        name: (
          <Checkbox
            className={cx('DocumentsControllerCheckBox', { selectAll })}
            onChange={() => onCheckboxClick('selectAll')}
            checked={selectAll}
            key="selectAll"
            name="selectAll"
          />
        ),
        width: '50px',
        left: true,
        selector: row => null,
        cell: ({ code }) => {
          const checked = selectedDocuments ? selectedDocuments[code]?.checked : false;
          return (
            <Checkbox
              key={code}
              name={code}
              checked={checked}
              onChange={() => onCheckboxClick(code)}
              className={`DocumentsControllerCheckBox ${checked ? 'checked' : 'unchecked'}`}
            />
          );
        },
      },
      ...defaultColumns,
      {
        name: 'Actions',
        width: '92px',
        center: true,
        selector: row => null,
        cell: row => (
          <DocumentActions
            row={row}
            onOpenEditDocument={handleOpenEditDocument}
            onDeleteDocumentModal={handleDeleteDocumentModal}
            onDownloadDocument={handleDocumentDownloadModal}
            onTogglePinDocument={handleTogglePinDocument}
            onUploadNewVersion={handleUploadNewVersion}
          />
        ),
      },
    ];
  }

  const mobileColumns = columns.slice(0, 3).concat(columns.slice(6, 7));
  const conditionalRowStyles = [
    {
      when: row => row.pinned === true,
      style: {
        backgroundColor: '#F4F7FF',
      },
    },
  ];

  const customStyles = {
    cells: {
      style: {
        paddingTop: 24,
        paddingBottom: 24,
        fontSize: 16,
      },
    },
  };

  /**
   * Sorts asc or desc data based on sortDirection
   * @param {string} column
   * @param {string} sortDirection
   */
  const onSort = (column, sortDirection) => {
    documents
      .sort((a, b) => {
        if (a[column.sortKey] > b[column.sortKey]) {
          return sortDirection === 'asc' ? 1 : -1;
        }

        return -1;
      })

      // put pins on the top
      .sort((a, b) => b.pinned - a.pinned);
  };

  /**
   * Loop through documents that should be pinned/unpinned
   * @param {*} toggleDocumentsIds list of documentID's
   * @param {bool} action true = unpin, false = pin
   */
  const handleBatchTogglePinDocument = (toggleDocumentsIds, action) => {
    const toggleDocumentPinRequests = toggleDocumentsIds.map(documentId =>
      toggleDocumentPin(documentId, action).catch(e => setTimeoutError(e)),
    );

    Promise.all(toggleDocumentPinRequests).then(() => {
      executeGetTrackDocuments(trackId, filters);
    });
  };

  const handleUploadNewVersion = row => {
    setVersionToBeUpdated(row);
    setOpenUpload(true);
  };

  const handleTogglePinDocument = async row => {
    try {
      await toggleDocumentPin(row.id, !row.pinned);
      await executeGetTrackDocuments(trackId, filters);
    } catch (error) {
      showPinDocumentErrorToast(error);
    }
  };

  /**
   * @param {array} documentIds list of documentID's | documentCodes
   */
  const deleteDocuments = async documentIds => {
    handleModalConfirmClose();
    await executeDeletingDocuments(documentIds);
    await executeGetTrackDocuments(trackId, filters);
    const newMessage = {
      trackId,
      updatedAt: new Date(),
    };
    postMessage(newMessage);
  };

  /**
   * @param {array} documentsToDelete list of documentID's | documentCodes
   */
  const handleDeleteDocumentsModal = documentsToDelete => {
    setOpenModalConfirm(true);

    if (documentsToDelete.length === 1) {
      const documentToDelete = documents.find(
        ({ id, code }) => id === documentsToDelete[0] || code === documentsToDelete[0],
      );
      if (documentToDelete) {
        return setModalConfirmOptions(getDeleteDocumentModalConfirmOptions(documentToDelete, deleteDocuments));
      }
    }
    return setModalConfirmOptions(getDeleteDocumentsModalConfirmOptions(documentsToDelete, deleteDocuments));
  };

  const handleDeleteDocumentModal = document => {
    setOpenModalConfirm(true);
    setModalConfirmOptions(getDeleteDocumentModalConfirmOptions(document, deleteDocuments));
  };

  /**
   *
   * @param {import('./documentRequests').Document} document
   */
  const handleDocumentDownloadModal = document => {
    const { isEncrypted, isAVCleanOrDisabled, isInfected } = document;
    if (isEncrypted) {
      setOpenModalConfirm(true);
      setModalConfirmOptions(getEncryptedDocumentDownloadModalConfirmOptions(document, handleDownloadDocument));
    } else if (isAVCleanOrDisabled) {
      handleDownloadDocument(document);
    } else if (isInfected) {
      showNotification({
        title: 'Unable to download document.',
        type: 'error',
        description: 'Document contains a virus. No download or preview available.',
      });
    } else {
      showNotification({
        title: 'Unable to download document.',
        type: 'error',
        description: 'Document has not yet been scanned for virus protection. No download or preview available.',
      });
    }
  };

  const handleModalConfirmClose = () => {
    setOpenModalConfirm(false);
    setModalConfirmOptions(null);
  };

  if (deleteDocumentsError) {
    if (deleteDocumentsError?.message) {
      setTimeoutError(deleteDocumentsError);
    }
    setDeleteDocumentsError(null);
  }

  // Defining error component if there was an error fetching document information
  let errorDataComponent;
  if (documentsError) {
    errorDataComponent = (
      <NotFoundBlock
        error={`${documentsError.networkError?.statusCode || 404} Error`}
        title={"We're having trouble fetching documents for this track. Please try refreshing."}
      />
    );
  }

  const refreshDocuments = () => executeGetTrackDocuments(trackId, filters);

  const handleCloseOpenEditDocument = () => {
    setDocumentToEdit(null);
    setOpenEditDocument(false);
  };

  const handleOpenEditDocument = document => {
    setDocumentToEdit(document);
    setOpenEditDocument(true);
  };

  /**
   * @param {array} documentIds - list of documentID's and all the documentIds must be UUID4s
   */
  const handleMergeDocumentsModal = documentIds => {
    const { documentIdsToMerge, mappedFilesByAVStatus } = getDocumentUUID4IdsWithMappedFilesByAvStatus(
      documents,
      documentIds,
    );
    if (documentIds && documentIdsToMerge.length > 1) {
      const showMergeModal = documentIds => {
        setModalMergeOptions({ open: true, options: { documentIds } });
      };
      if (documentIds.length === mappedFilesByAVStatus.cleanFiles.length) {
        showMergeModal(documentIdsToMerge);
      } else {
        setOpenModalConfirm(true);
        setModalConfirmOptions(
          getMergeDocumentModalConfirmOptions(mappedFilesByAVStatus, showMergeModal, handleModalConfirmClose),
        );
      }
    }
  };

  const handleMergeDocumentsModalClose = isDocumentsRefresh => {
    if (isDocumentsRefresh) {
      executeGetTrackDocuments(trackId, filters);
    }
    setModalMergeOptions({ open: false });
  };

  /**
   * update document filters
   * @param {object} filters filter key and values
   */
  const handleFilterChange = filters => {
    const cleanFilters = omitBy(filters, isNil);
    handleFilterDocuments(cleanFilters);
    setSearchParams(cleanFilters);
  };

  /**
   * @param {array} documentsToDownload list of documentID's | documentCodes
   */
  const handleDownloadDocuments = async documentsToDownload => {
    try {
      setDownloadLoading(true);
      await downloadAndCompressFiles(documentsToDownload);
      showNotification(getDocumentToastSettings('', AV_STATUS.Clean, 0, documentsToDownload.length));
    } catch (error) {
      setTimeoutError(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  /**
   * @param {array} documentsToDownload list of documents
   */
  const handleDownloadAVDocuments = documentsToDownload => {
    const { passwordProtectedFiles, cleanFiles } = getAVBatchFileToDownloadAndMaybeShowToasts(documentsToDownload);
    if (passwordProtectedFiles?.length) {
      downloadCleanWithPasswordProtectedDocumentOrDocuments({ passwordProtectedFiles, cleanFiles });
    } else if (cleanFiles?.length) {
      downloadCleanDocumentOrDocuments(cleanFiles);
    }
  };

  const downloadCleanDocumentOrDocuments = documents => {
    if (!documents?.length) return;

    if (documents.length === 1) {
      handleDownloadDocument(documents[0]);
    } else {
      handleDownloadDocuments(documents);
    }
  };

  const downloadCleanWithPasswordProtectedDocumentOrDocuments = ({ passwordProtectedFiles, cleanFiles }) => {
    if (!passwordProtectedFiles?.length) {
      return;
    }
    const onConfirm = () => {
      handleModalConfirmClose();
      downloadCleanDocumentOrDocuments([...cleanFiles, ...passwordProtectedFiles]);
    };
    const onCancel = () => {
      handleModalConfirmClose();
      downloadCleanDocumentOrDocuments(cleanFiles);
    };

    if (passwordProtectedFiles.length === 1) {
      setModalConfirmOptions({
        ...getDownloadDocumentModalConfirmOptions(passwordProtectedFiles[0], onConfirm),
        onCancel,
        onClose: onCancel,
      });
    } else {
      setModalConfirmOptions(getDownloadDocumentsModalConfirmOptions(passwordProtectedFiles, onConfirm, onCancel));
    }
    setOpenModalConfirm(true);
  };

  return (
    <TrackDetailsPadding className="DocumentsControllerContainer">
      {/* TODO: temporary error handling */}
      <FormError standalone error={timeoutError} />
      <SliderController
        refresh={refreshDocuments}
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
        trackId={trackId}
        useDocumentKindsResults={useDocumentKindsResults}
        versionToBeUpdated={versionToBeUpdated}
        setVersionToBeUpdated={setVersionToBeUpdated}
        getDocumentKindVisibility={getDocumentKindVisibility}
        useTrackApplicantsResults={useTrackApplicantsResults}
      />
      <CreateDocumentSet open={openDocumentSet} setOpen={setOpenDocumentSet} documents={documents ?? []} />
      {isDocumentsEditAccess ? (
        <ButtonBar
          documents={selectedDocuments}
          uploadClick={() => setOpenUpload(true)}
          togglePin={handleBatchTogglePinDocument}
          deleteClick={handleDeleteDocumentsModal}
          downloadClick={handleDownloadAVDocuments}
          loading={isDataTableLoading}
          mergeClick={handleMergeDocumentsModal}
          downloadLoading={downloadLoading}
          documentSetClick={() => setOpenDocumentSet(true)}
        />
      ) : (
        <div className="DocumentsViewAccess" />
      )}
      <DocumentFilters appliedFilters={filters} onApplyFilter={handleFilterChange} />
      <DataTable
        sortServer
        data={documents ?? []}
        loading={isDataTableLoading}
        columns={columns}
        onSort={onSort}
        mobileColumns={mobileColumns}
        respondAt="md"
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={'There are no Documents to display'}
        errorDataComponent={errorDataComponent}
        className="DocumentsControllerDataTable"
      />
      {openModalConfirm && (
        <ModalConfirm
          open={openModalConfirm}
          onClose={handleModalConfirmClose}
          className="DocumentsConfirmationModal"
          {...modalConfirmOptions}
        />
      )}
      <EditDocument
        document={documentToEdit}
        useDocumentKindsResults={useDocumentKindsResults}
        open={openEditDocument}
        close={handleCloseOpenEditDocument}
        refresh={refreshDocuments}
        getDocumentKindVisibility={getDocumentKindVisibility}
        useTrackApplicantsResults={useTrackApplicantsResults}
      />
      {modalMergeOptions.open && (
        <MergeDocumentsModal
          close={handleMergeDocumentsModalClose}
          refresh={() => executeGetTrackDocuments(trackId, filters)}
          documentKindsDropdownOptions={useDocumentKindsResults.documentKindsDropdownOptionsSorted}
          documentKindsWithPerson={useDocumentKindsResults.documentKindsWithPerson}
          getDocumentKindVisibility={getDocumentKindVisibility}
          useTrackApplicantsResults={useTrackApplicantsResults}
          {...modalMergeOptions}
        />
      )}

      <DemoButton
        onClickAction={() => {
          const { documentKindsDropdownOptionsSorted, documentKindsWithPerson } = useDocumentKindsResults;
          uploadDemoDocument(
            trackId,
            documentKindsDropdownOptionsSorted,
            executeGetTrackDocuments,
            documentKindsWithPerson,
          );
        }}
      />
    </TrackDetailsPadding>
  );
};

export default DocumentsController;
