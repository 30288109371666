import { groupBy } from 'lodash';
import { parseCreatedAtDateParts } from 'utils/dates';
import { TODO_STATUSES } from './data/constants/todoStatuses';
import TodosActionDropdown from 'apps/todos/components/TodosActionDropdown';
import { TODO_NOTES_OTHER } from 'apps/track-details/data/constants/todoForm';
import useCurrentUser from 'hooks/useCurrentUser';

/**
 * @typedef HomeownerTodoChoiceExpandByApplicants
 * @type {object}
 * @property {string} displayGroup Must correspond to an actual homeowner todo choice display group
 * @property {string} displayLabel Require display label override for the homeowner todo choice
 * @property {string} kind Must correspond to an actual To-do variables doc_kind which lives in the target display group
 */

/** @type {HomeownerTodoChoiceExpandByApplicants[]} */
export const TODO_CHOICES_EXPAND_BY_APPLICANTS = [
  { displayGroup: 'Miscellaneous', displayLabel: 'Upload government ID', kind: 'upload_gov_id' },
];

/** Create grouped TodosChoices by displayGroup property
 * @param {Track} [track]
 * @return {Record<string, HomeownerTodoChoice[]>}
 */
export const groupTodosChoices = track => {
  const { assignableHomeownerTaskChoices, applicants } = track || {};
  const groupedTodoChoices = groupBy(assignableHomeownerTaskChoices, 'displayGroup');

  TODO_CHOICES_EXPAND_BY_APPLICANTS.forEach(({ displayGroup, kind }) => {
    if (groupedTodoChoices[displayGroup]) {
      const choice = groupedTodoChoices[displayGroup].find(choice => choice.kind === kind);

      if (choice) {
        // go through the applicants and add an individual todoChoice to each applicant
        // by updating the display label by adding the name of the applicant
        groupedTodoChoices[displayGroup] = [
          ...applicants.map(applicant => {
            return {
              ...choice,
              displayLabel: getHomeownerTodoDisplayLabel(choice, applicant),
              applicant,
            };
          }),
          ...groupedTodoChoices[displayGroup].filter(todoChoice => todoChoice.kind !== kind),
        ];
      }
    }
  });
  return groupedTodoChoices;
};

export const isTodoActive = ({ taskStatus: todoStatus }) => {
  const inactive_statuses = [TODO_STATUSES.COMPLETED, TODO_STATUSES.UPLOADED, TODO_STATUSES.NOT_APPLICABLE];
  return todoStatus && !inactive_statuses.includes(todoStatus);
};

export const formatSingularOrPluralTodoCopy = (todos = [], additionalCopy = '') => {
  return `${todos.length} ${additionalCopy} to-do${todos.length > 1 ? 's' : ''}`;
};

export const generateHomeownerSelectedTodoCount = (selectedTodos = [], homeownerFullName) => {
  return `${formatSingularOrPluralTodoCopy(selectedTodos, 'new')} for ${homeownerFullName}`;
};

export const TodoTableColumns = () => {
  const { user } = useCurrentUser();
  const todo_table_columns = [
    {
      name: 'To-do',
      grow: 1,
      selector: todo => {
        return (
          <p className="TodosTableTodoColumnCopy">
            {todo.name}
            {todo.description && <small className="TodosTableTodoColumnCopyNotes">{todo.description}</small>}
          </p>
        );
      },
    },
    {
      name: 'Status',
      id: 'status',
      grow: 0.5,
      sortable: true,
      selector: row => row.taskStatus,
    },
    {
      name: 'Requested Date',
      id: 'requestedDate',
      sortable: true,
      grow: 0.5,
      selector: row => {
        const [monthDay, year, timeWithTZ] = parseCreatedAtDateParts(row.createdAt);
        return `${monthDay}, ${year} at ${timeWithTZ}`;
      },
    },
    {
      name: 'Actions',
      width: 100,
      right: true,
      grow: 0,
      selector: () => null,
      cell: row => {
        return <TodosActionDropdown todo={row} />;
      },
    },
  ];

  if (user?.rolloutFlags?.includes('hide_todo_reasons_dropdown')) {
    const notes_column = {
      name: 'Notes',
      grow: 1,
      selector: todo => {
        return <p className="TodosTableTodoColumnCopy">{todo.notes}</p>;
      },
    };
    todo_table_columns.splice(1, 0, notes_column);
  }
  return todo_table_columns;
};

export const MOBILE_TODO_TABLE_COLUMNS = [
  {
    name: 'To-do',
    selector: row => row.name,
  },
  {
    name: 'Requested Date',
    id: 'requestedDate',
    sortable: true,
    grow: 0.5,
    selector: row => {
      const [monthDay, year] = parseCreatedAtDateParts(row.createdAt);
      return `${monthDay} ${year}`;
    },
  },
  {
    name: 'Actions',
    grow: 0,
    selector: () => null,
    cell: row => {
      return <TodosActionDropdown todo={row} />;
    },
  },
];

/** Get the display label with the an overriden display name + applicants name if applicable
 * @param {HomeownerTodo|HomeownerTodoChoice} todo the To-do's kind
 * @param {Applicant} [applicant] Subject of the Task/Todo
 * @return {string} The display label for the homeowoner todo
 */
export const getHomeownerTodoDisplayLabel = (todo, applicant) => {
  if (todo.name) {
    return todo.name;
  }
  if (todo.kind === undefined) {
    throw new Error(`getHomeownerTodoDisplayLabel: todo=${JSON.stringify(todo)} missing kind`);
  }
  // HomeownerTodos from the api have displayLabel while choices & selected have a name
  const originalLabel = todo.displayLabel;
  if (originalLabel === undefined) {
    throw new Error(`getHomeownerTodoDisplayLabel: todo=${JSON.stringify(todo)} missing kindLabel or displayLabel`);
  }
  const expandChoice = TODO_CHOICES_EXPAND_BY_APPLICANTS.find(({ kind }) => kind === todo.kind);
  if (expandChoice && applicant) {
    const fullName = applicant.fullName || applicant.fullNameShort;
    if (fullName === undefined) {
      throw new Error(
        `getHomeownerTodoDisplayLabel: applicant=${JSON.stringify(applicant)} missing fullNameShort or fullName`,
      );
    }
    // Choices expanded by applicant override the display label
    return `${expandChoice.displayLabel} for ${fullName}`;
  }
  return originalLabel;
};

/**
 * @typedef CreateHomeownerTodoFormData
 * @property {string} [reason] Reason for creating the Homeowner Todo during completion of internal task
 * @property {string} [reasonNote] Reason Note only ever used for the "other" reason case
 */

/** Get Homeowner TODO reason to create from task form
 * @param {CreateHomeownerTodoFormData} formData
 * @returns {string}
 */
export const getHomeownerTodoReason = formData => {
  const { reason = '', reasonNote = '' } = formData ?? {};
  return reason === TODO_NOTES_OTHER ? reasonNote : reason;
};
