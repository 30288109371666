import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '@hometap/htco-components';
import StatusCodeErrorBlock from 'components/StatusCodeErrorBlock/StatusCodeErrorBlock';

const Context = React.createContext({});

const useTrack = () => useContext(Context);
export default useTrack;

export const TrackQueryProvider = ({
  trackId,
  query,
  children,
  options = {},
  returnPageLoader = true,
  returnPageError = true,
}) => {
  const { data, error, loading, refetch } = useQuery(query, {
    variables: { trackId },
    enabled: false,
    notifyOnNetworkStatusChange: true,
    ...options,
  });
  const track = data?.track;

  if (loading && returnPageLoader) {
    return (
      <div className="FullPageLoader">
        <Loader type="dot-pulse" overlay />
      </div>
    );
  }

  if (error && returnPageError) {
    return <StatusCodeErrorBlock errorCode={error?.statusCode || 500} />;
  }

  const contextData = {
    track,
    trackIsLoading: loading,
    trackError: error,
    refetchTrack: refetch,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
