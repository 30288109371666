import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, Container, NotFoundBlock, useWindowSize } from '@hometap/htco-components';

import HometapError from 'components/HometapError';
import TaskDrawer from './components/TaskDrawer/TaskDrawer';
import TaskList from './components/TaskList/TaskList';
import TaskDetail from './components/TaskDetail/TaskDetail';
import TaskDataController from './components/TaskData/TaskDataController';
import TaskActionBar from './components/TaskActionBar/v2/TaskActionBar';
import { getFirstIncompleteTask, getIsSimpleReviewTask, getSortedInternalTasks } from './trackTasksUtils';
import './TrackTasksController.scss';

import { GET_INTERNAL_TASK_DATA_FOR_TRACK } from './data/queries/getInternalTaskDataForTrack';
import { UPDATE_REVIEW_STATUS } from './data/mutations/updateReviewStatus';
import useCurrentUser from 'hooks/useCurrentUser';
import { sendTaskViewedSegmentEvent } from 'utils/segment/serverEvents';
import usePrevious from 'hooks/usePrevious';
import { TaskSpecificContentProvider } from './hooks/useSpecificContentQuery';
import { APP_UNLOCK_CHANGES_TOOLBAR_TARGET_ID } from 'apps/track-details/utils/applicationUnlockConstants';
import TaskDocuments from './components/TaskDocuments/TaskDocuments';
import cx from 'classnames';
import { OPEN_TASK, SAVE_TASK_NOTES, UPDATE_TASK_CONDITIONS_COMPLETION } from './data/mutations';
import { getGraphQLError } from '../../../utils/errors';
import { showNotification } from '../../../utils/toasts';
import { getApplicant, showCompleteErrorTaskToast } from './components/TaskList/TaskListUtils';
import { TASK_STATUSES } from 'data/constants/taskStatuses';
import { useWidth } from './hooks/useWidth';
import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION, SENIOR_LIEN_TASK_DEFINITIONS } from 'data/constants/bpmConstants';

import TaskDetailContractorForms from './components/TaskDetail/TaskDetailContractorForms/TaskDetailContractorForms';
import TaskMortgagesLiens from './components/TaskMortgagesLiens/TaskMortgagesLiens';
import TaskDetailMultipleForms from './components/TaskDetail/TaskDetailMultipleForms.js/TaskDetailMultipleForms';
import { getIsMultipleFormsTask } from '../utils/taskForms';

// if the screen size does not contain the min-width of the middle content, we enable the overlay
const CONTENT_LEFT_MIN_WIDTH = 480;
const OPEN_TASK_DRAWER_WIDTH = 398;

const TrackTasksController = () => {
  const { isContractor } = useCurrentUser();
  const navigate = useNavigate();
  const contentRightRef = useRef(null);

  const [isOpenTaskDrawer, setIsOpenTaskDrawer] = useState(true);
  const [allConditionChanges, setAllConditionChanges] = useState([]);
  const [allTaskNoteChanges, setAllTaskNoteChanges] = useState([]);
  const [shouldCheckAllConditions, setShouldCheckAllConditions] = useState(false);
  const [isPreviewDocumentOpen, setIsPreviewDocumentOpen] = useState(isContractor);
  const [haveTrackDocumentsChanged, setHaveTrackDocumentsChanged] = useState(false);
  const [shouldDocumentsDrawerOpen, setShouldDocumentsDrawerOpen] = useState(true);

  const isSaveProgressEnabled = allTaskNoteChanges.length + allConditionChanges.length > 0;

  const { user } = useCurrentUser();
  const { trackId, taskId } = useParams();
  const { screenWidth } = useWindowSize({ delay: 250 });
  const client = useApolloClient();
  const [contentRightWidth] = useWidth(contentRightRef, [isPreviewDocumentOpen]);
  const {
    loading: isGetInternalTaskLoading,
    data,
    error,
    refetch,
  } = useQuery(GET_INTERNAL_TASK_DATA_FOR_TRACK, {
    variables: { trackId },
  });

  const [
    updateReviewStatus,
    { loading: isUpdateReviewStatusLoading, error: updateReviewStatusError, reset: updateReviewStatusReset },
  ] = useMutation(UPDATE_REVIEW_STATUS);
  const [updateTaskConditionsCompletion, { loading: updateTaskConditionsCompletionLoading }] = useMutation(
    UPDATE_TASK_CONDITIONS_COMPLETION,
  );
  const [saveTaskNotes, { loading: saveTaskNotesLoading }] = useMutation(SAVE_TASK_NOTES);
  const [openTask, { loading: openTaskLoading }] = useMutation(OPEN_TASK);

  const { track } = data || {};
  const {
    internalTasks: initialInternalTasks = [],
    reviewStatusChoices = [],
    reviewStatus = {},
    canChangeReviewStatus: canChangeReviewStatusObj,
    upcomingInternalTasks = [],
    applicants = [],
    homeownerTasks = [],
  } = track || {};
  // useMemo is used to refrain from unexpected re-renders with filling out the form
  const internalTasks = useMemo(() => {
    const tasks = getSortedInternalTasks(initialInternalTasks);
    if (isContractor) {
      return tasks.filter(task => task.kind === SENIOR_LIEN_DATA_ENTRY_TASK_DEFINITION);
    }
    return tasks;
  }, [initialInternalTasks, isContractor]);

  useEffect(() => {
    setIsPreviewDocumentOpen(isContractor);
  }, [isContractor]);

  const isLoading = isGetInternalTaskLoading || isUpdateReviewStatusLoading;
  const currentTask = internalTasks.find(({ identifier }) => identifier === taskId);
  const applicant = getApplicant(currentTask, applicants);
  const previousTaskId = usePrevious(taskId);
  const previousTask = usePrevious(currentTask);
  const isSimpleReviewTask = getIsSimpleReviewTask(currentTask?.taskDefinitionKey);
  const isOverlayEnabled = CONTENT_LEFT_MIN_WIDTH >= screenWidth - contentRightWidth - OPEN_TASK_DRAWER_WIDTH;
  const isHiddenActionBar = !isSimpleReviewTask && currentTask?.taskStatus === TASK_STATUSES.COMPLETED;
  const isSaving = updateTaskConditionsCompletionLoading || saveTaskNotesLoading || openTaskLoading;
  const isSeniorLienTask = SENIOR_LIEN_TASK_DEFINITIONS.includes(currentTask?.taskDefinitionKey);

  // Enables / Disables overlay effect of left column
  useEffect(() => {
    if (isOverlayEnabled) {
      setIsOpenTaskDrawer(false);
    }
  }, [isOverlayEnabled]);

  const isPrimaryButtonEnabled = () => {
    if (!currentTask || openTaskLoading) {
      return false;
    }
    if (currentTask.isCompleted) {
      return true;
    }
  };

  useBroadcastChannel({
    channelName: 'DOCS_UPDATED',
    onMessage: async message => {
      if (message.trackId === trackId) {
        await refetch();
        setHaveTrackDocumentsChanged(true);
      }
    },
  });

  /**
   * Finds task condition that has been changed and delegates the further processing to {@link applyConditionChange}
   *
   * @param {number} taskConditionId the id of the condition that has changed
   * @param {boolean} newValue the value of the changed condition
   */
  const handleConditionChange = (taskConditionId, newValue) => {
    currentTask.conditions.forEach(condition => {
      if (condition.identifier === taskConditionId) {
        applyConditionChange(condition, taskConditionId, newValue);
      }
    });
  };

  /**
   * If condition's original state matches the changed state - removes that condition from the "condition changes" array
   * Otherwise, adds a new "condition-change" object to the "change" array.
   *
   * @param {object} condition the current state of a condition in backend
   * @param {number} conditionIdToChange {@see handleConditionChange}
   * @param {boolean} newValue conditionIdToChange {@see handleConditionChange}
   */
  const applyConditionChange = (condition, conditionIdToChange, newValue) => {
    if (condition.isCompleted === newValue) {
      setAllConditionChanges([
        ...allConditionChanges.filter(item => {
          return item.id !== conditionIdToChange;
        }),
      ]);
    } else {
      setAllConditionChanges([...allConditionChanges, { id: conditionIdToChange, value: newValue }]);
    }
  };

  /**
   * Find the task that has received a new note.
   * If it matches the original task note - removes it from the "task note changes" array.
   * Otherwise, created a new "task note change" object or edits the value of the existing one.
   *
   * @param taskNote the new note entered by user
   * @param taskId the id of the task where the note belongs
   */
  const handleTaskNoteChange = (taskNote, taskId) => {
    internalTasks.forEach(task => {
      if (task.identifier !== taskId) {
        return;
      }
      if (task.notes === taskNote) {
        setAllTaskNoteChanges([
          ...allTaskNoteChanges.filter(item => {
            return item.id !== taskId;
          }),
        ]);
      } else {
        const existingTaskNoteChange = allTaskNoteChanges.find(taskNoteChange => taskNoteChange.id === taskId);
        if (existingTaskNoteChange) {
          existingTaskNoteChange.value = taskNote;
        } else {
          setAllTaskNoteChanges([...allTaskNoteChanges, { id: taskId, value: taskNote }]);
        }
      }
    });
  };

  const cleanAllTaskChanges = () => {
    setAllConditionChanges([]);
    setAllTaskNoteChanges([]);
  };

  const handleGqlError = (gqlError, errorTitle) => {
    showNotification({
      type: 'error',
      title: errorTitle,
      description: getGraphQLError(gqlError) || 'There was an error',
      options: { position: 'top-right' },
      toastContent: null,
    });
  };

  /**
   * Calls GraphQL mutation for batch saving condition changes.
   */
  const mutateChangedTaskConditions = async () => {
    if (allConditionChanges.length === 0) {
      return;
    }

    const conditionsToOpen = [];
    const conditionsToComplete = [];
    allConditionChanges.forEach(condition => {
      if (condition.value === false) {
        conditionsToOpen.push(condition.id);
      } else {
        conditionsToComplete.push(condition.id);
      }
    });

    try {
      await updateTaskConditionsCompletion({
        variables: { taskId, conditionsToOpen, conditionsToComplete },
      });
    } catch (error) {
      handleGqlError(error, 'Unable to save changed task conditions');
    }
  };

  const mutateChangedTaskNote = async () => {
    try {
      await Promise.all(
        allTaskNoteChanges.map(({ id: taskId, value: notes }) => {
          return saveTaskNotes({ variables: { taskId, notes } });
        }),
      );
    } catch (error) {
      handleGqlError(error, 'Unable to save task note');
    }
  };

  const handleAllChangeMutations = async () => {
    await mutateChangedTaskConditions();
    await mutateChangedTaskNote();
    cleanAllTaskChanges();
  };

  const handleModalConfirm = async () => {
    await handleAllChangeMutations();
    cleanAllTaskChanges();
  };

  const handleModalCancel = () => {
    cleanAllTaskChanges();
  };

  const handleReopenTask = () => {
    openTask({ variables: { taskId } }).catch(error => handleGqlError(error, 'Unable to reopen the task'));
  };

  const handleTaskActionBarPrimaryButtonClick = async (resolution, closeTask, additionalOnClickData) => {
    if (currentTask?.isCompleted) {
      await handleReopenTask();
      await client.refetchQueries({ include: ['GetInternalTaskDataForTrack', 'GetTodos'] });
    } else {
      try {
        await handleAllChangeMutations();
        if (closeTask) {
          await closeTask(resolution, additionalOnClickData);
        }
      } catch (e) {
        showCompleteErrorTaskToast(e);
      } finally {
        refetch();
        await client.refetchQueries({ include: ['GetInternalTaskDataForTrack', 'GetTodos'] });
      }
    }
  };

  /**
   * Checks all incomplete conditions and informs {@link TaskConditions} component by setting a flag.
   */
  const handleDemoButtonClick = () => {
    const newAllConditionChanges = [];
    currentTask.conditions.forEach(condition => {
      if (!condition.isCompleted) {
        newAllConditionChanges.push({ id: condition.identifier, value: true });
      }
    });
    setAllConditionChanges(newAllConditionChanges);
    setShouldCheckAllConditions(true);
    setTimeout(() => {
      setShouldCheckAllConditions(false);
    }, 200);
  };

  useEffect(() => {
    const isRefreshFromDocumentsUpdate = haveTrackDocumentsChanged && !currentTask;
    const shouldRedirectIfHasInitialTasks = !taskId || isRefreshFromDocumentsUpdate || !currentTask;
    const shouldRedirectOnTasksLanding = shouldRedirectIfHasInitialTasks && internalTasks && internalTasks.length > 0;
    if (shouldRedirectOnTasksLanding) {
      const initialTask = getFirstIncompleteTask(internalTasks, reviewStatus?.value);
      if (initialTask) {
        navigate(`/tracks/${trackId}/tasks/${initialTask.identifier}`, { replace: true });
      }
    }
  }, [
    data,
    taskId,
    navigate,
    internalTasks,
    trackId,
    track,
    currentTask,
    user.identifier,
    reviewStatus?.value,
    haveTrackDocumentsChanged,
  ]);

  useEffect(() => {
    if (!taskId) return;

    const isUserViewingNewTask = previousTaskId !== taskId;
    const hasTaskPageLoaded = !previousTask && currentTask;
    if (isUserViewingNewTask || hasTaskPageLoaded) {
      currentTask && sendTaskViewedSegmentEvent({ trackId, reviewerUserId: user.identifier, task: currentTask });
    }
  }, [currentTask, taskId, internalTasks, user.identifier, trackId, previousTaskId, previousTask]);

  if (error) {
    return (
      <HometapError title="We are having trouble fetching the tasks for this track." error="400 error">
        Please try refreshing. If the problem persists please contact engineering.
      </HometapError>
    );
  }
  let taskDetailContent;
  if (!internalTasks?.length && !isLoading) {
    taskDetailContent = (
      <Container className="TrackTaskNoContent">
        <h4>There are no tasks for this track</h4>
        <Button theme="text" href="/tracks">
          Navigate to another Track
        </Button>
      </Container>
    );
  } else if (taskId && !currentTask && !isLoading) {
    taskDetailContent = (
      <NotFoundBlock title="Looks like we're having trouble finding this task.">
        Please try refreshing. If the problem persists please contact engineering.
      </NotFoundBlock>
    );
  } else if (isSeniorLienTask) {
    taskDetailContent = <TaskDetailContractorForms trackId={trackId} task={currentTask} />;
  } else if (getIsMultipleFormsTask(currentTask)) {
    taskDetailContent = <TaskDetailMultipleForms task={currentTask} isPageLoading={isLoading} />;
  } else {
    // A document review task or legacy review, we should put some time into cleaning this up at some point
    taskDetailContent = (
      <TaskDetail
        task={currentTask}
        applicant={applicant}
        isPageLoading={isLoading}
        isSaveTaskNotesLoading={saveTaskNotesLoading}
        reviewStatus={reviewStatus}
        onConditionChange={handleConditionChange}
        onTaskNoteChange={handleTaskNoteChange}
        shouldCheckAllConditions={shouldCheckAllConditions}
        assignee={track?.homeowner?.identifier}
        allConditionChanges={allConditionChanges}
      />
    );
  }

  const handleChangeReviewStatus = reviewStatus => {
    updateReviewStatus({
      variables: {
        trackId,
        reviewStatus,
      },
    }).catch(() => setTimeout(updateReviewStatusReset, 4000));
  };

  return (
    <div className="TrackTasksContainer TrackDetailsPartiallyDisabled" id={APP_UNLOCK_CHANGES_TOOLBAR_TARGET_ID}>
      {!isLoading && !isSeniorLienTask && (
        <TaskDrawer
          isOpen={isOpenTaskDrawer}
          isOverlay={isOverlayEnabled}
          onChange={value => setIsOpenTaskDrawer(value)}
        >
          <TaskList
            tasks={internalTasks}
            upcomingTasks={upcomingInternalTasks}
            homeownerTasks={homeownerTasks}
            reviewStatusChoices={reviewStatusChoices}
            loading={isLoading}
            canChangeReviewStatusObj={canChangeReviewStatusObj}
            onChangeReviewStatus={handleChangeReviewStatus}
            reviewStatus={reviewStatus}
            error={updateReviewStatusError}
          />
        </TaskDrawer>
      )}
      <TaskSpecificContentProvider>
        <div className="TrackTaskContent">
          <div className="TrackTaskContentTop">
            <div className={cx('TrackTaskContentLeft', { isFixed: isSeniorLienTask })}>{taskDetailContent}</div>
            {isSeniorLienTask && (
              <div className={cx('TrackTaskContentMiddle', { isFixed: true })}>
                <TaskMortgagesLiens trackId={trackId} task={currentTask} />
              </div>
            )}
            <div
              ref={contentRightRef}
              className={cx('TrackTaskContentRight', {
                isMedium: isSeniorLienTask,
                isLarge: isPreviewDocumentOpen && !isSimpleReviewTask && !isSeniorLienTask,
                isHidden: isSeniorLienTask && !shouldDocumentsDrawerOpen,
              })}
            >
              {/* TODO https://hometap.atlassian.net/browse/EH-162
                Replace isSimpleReviewTask + isSeniorLienTask checks with setting in the task specific content definition */}
              {!isSimpleReviewTask ? (
                <TaskDocuments
                  trackId={trackId}
                  taskId={currentTask.identifier}
                  isPreviewDocumentOpen={isPreviewDocumentOpen}
                  setIsPreviewDocumentOpen={setIsPreviewDocumentOpen}
                  isTaskCompleted={currentTask.isCompleted}
                  allTrackDocs={isSeniorLienTask}
                  shouldOpen={shouldDocumentsDrawerOpen}
                  setShouldOpen={setShouldDocumentsDrawerOpen}
                  isWithDrawer={isSeniorLienTask}
                  taskDocVariant={isSeniorLienTask ? 'allTrackDocs' : 'allDocsThatMatchTaskKind'}
                />
              ) : (
                <TaskDataController currentTask={currentTask} isCurrentTaskLoading={isGetInternalTaskLoading} />
              )}
            </div>
          </div>

          <div className="TrackTaskContentBottom">
            <TaskActionBar
              task={currentTask}
              isPrimaryButtonEnabled={isPrimaryButtonEnabled()}
              isSecondaryButtonEnabled={isSaveProgressEnabled}
              isDemoButtonEnabled={!currentTask?.isCompleted}
              primaryButtonName={currentTask?.isCompleted ? 'Reopen Task' : 'Mark as complete'}
              onPrimaryButtonClick={handleTaskActionBarPrimaryButtonClick}
              onSecondaryButtonClick={handleAllChangeMutations}
              onDemoButtonClick={handleDemoButtonClick}
              isLoading={isLoading}
              isSaving={isSaving}
              isHiddenTaskActionBar={isHiddenActionBar}
              isSimpleReviewTask={isSimpleReviewTask}
              onModalConfirm={handleModalConfirm}
              onModalCancel={handleModalCancel}
            />
          </div>
        </div>
      </TaskSpecificContentProvider>
    </div>
  );
};

export default TrackTasksController;
