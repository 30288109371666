import { showNotification, TOAST_TYPE } from '../../../utils/toasts';
import { AV_STATUS, MERGE_DOCUMENTS_TOAST, NOT_YET_SCANNED_AV_STATUS } from './data/constants';

export const getDocumentToastSettings = (filename, avStatus, delay = 0, count) => {
  const settingsByAVStatus = {
    [AV_STATUS.Infected]: {
      type: TOAST_TYPE.error,
      title: 'Unable to download document.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{filename}</span> contains a virus. No download or preview available.
        </div>
      ),
      options: { delay },
    },
    [AV_STATUS.Clean]: {
      type: TOAST_TYPE.success,
      title: filename ? 'Document downloaded.' : 'Documents downloaded.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{filename || count}</span>
          <span>
            {filename ? ' has been successfully downloaded.' : ' documents have been successfully downloaded.'}
          </span>
        </div>
      ),
      options: { delay },
    },
    [NOT_YET_SCANNED_AV_STATUS]: {
      type: TOAST_TYPE.error,
      title: 'Unable to download document.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{filename}</span> has not yet been scanned for virus protection. No
          download or preview available.
        </div>
      ),
      options: { delay },
    },
  };
  return settingsByAVStatus[avStatus];
};

/**
 * Used to show the toast when we merge the files
 * @param {string} filename the file name
 * @param {string} toastType the type of toast from the MERGE_DOCUMENTS_TOAST
 * @param {number} count the merged documents' count
 * @param {string} text the additional text to show in the toast
 */
export const showMergeDocumentsToastByType = (filename, toastType, count = 0, text = '') => {
  const settingsByToastType = {
    [MERGE_DOCUMENTS_TOAST.Success]: {
      type: TOAST_TYPE.success,
      title: 'Documents merged.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{count}</span> documents have been successfully merged into{' '}
          <span className="ToastContentText">{filename}</span> .
        </div>
      ),
    },
    [MERGE_DOCUMENTS_TOAST.Error]: {
      type: TOAST_TYPE.error,
      title: 'Unable to merge documents.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{filename}</span> could not be created.
          {text && <div className="ToastContentOverflowText">{text.trim().endsWith('.') ? text : `${text}.`}</div>}
        </div>
      ),
      options: { autoClose: 7000 },
    },
    [MERGE_DOCUMENTS_TOAST.NetworkError]: {
      type: TOAST_TYPE.error,
      title: 'Unable to merge documents.',
      description: (
        <div className="ToastContentDescription">
          <span className="ToastContentText">{filename}</span> could not be created due to a network error.
        </div>
      ),
      options: { autoClose: 7000 },
    },
  };

  const settingsToast = settingsByToastType[toastType] || {
    type: TOAST_TYPE.error,
    title: 'Unable to merge documents.',
  };
  showNotification(settingsToast);
};

/**
 * @typedef ErrorInformation
 * @type {object}
 * @property {boolean} isNetworkError
 * @property {string} errorText
 */

/**
 * Use it to get the error text and check whether it is a network error or not
 * @param {object|string} error
 * @return ErrorInformation
 */
export const getErrorInformation = error => {
  let errorText = '';
  if (typeof error === 'string') {
    errorText = error;
  } else if (error) {
    const { response, message } = error;
    errorText = response?.data?.detail || message || '';
  }
  return { errorText, isNetworkError: errorText === 'Network Error' };
};

export const showUploadNewVersionToast = filename =>
  showNotification({
    type: TOAST_TYPE.success,
    title: 'Document uploaded.',
    description: (
      <div className="ToastContentDescription">
        A new version of <span className="ToastContentText">{filename}</span> has been successfully uploaded.
      </div>
    ),
  });

export const showUploadDocumentToast = filename =>
  showNotification({
    type: TOAST_TYPE.success,
    title: 'Document uploaded.',
    description: (
      <div className="ToastContentDescription">
        <span className="ToastContentText">{filename}</span> has been successfully uploaded.
      </div>
    ),
  });

export const showUploadDocumentsToast = count =>
  showNotification({
    type: TOAST_TYPE.success,
    title: 'Documents uploaded.',
    description: (
      <div className="ToastContentDescription">
        <span className="ToastContentText">{count}</span> documents have been successfully uploaded.
      </div>
    ),
  });

export const showDocumentSetDocumentsError = () =>
  showNotification({
    type: TOAST_TYPE.error,
    title: 'Fetching document set documents failed',
    description: (
      <div className="ToastContentDescription">
        There was an error fetching the document set documents. Please try again.
      </div>
    ),
  });

export const showDocumentSetRequestedToast = () => {
  showNotification({
    type: TOAST_TYPE.success,
    title: 'Document Set Requested.',
    description: (
      <div className="ToastContentDescription">
        This may take some time. You’ll receive an email when the document set is ready.
      </div>
    ),
  });
};

export const showDocumentSetRequestedFailedToast = () => {
  showNotification({
    type: TOAST_TYPE.error,
    title: 'Document Set Failed.',
    description: (
      <div className="ToastContentDescription">There was an error requesting the document set. Please try again.</div>
    ),
  });
};
/**
 * Use it to show the error toast on pinning the document
 * @param {object|string} error
 */
export const showPinDocumentErrorToast = error => {
  const { isNetworkError, errorText } = getErrorInformation(error);
  console.log('errorText', errorText);
  showNotification({
    type: TOAST_TYPE.error,
    title: 'Unable to pin document.',
    description: (
      <div className="ToastContentDescription">
        {isNetworkError ? 'Unable to pin document right now. Please try again later.' : errorText}
      </div>
    ),
  });
};
