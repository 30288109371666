import React, { useEffect, useState } from 'react';
import { useAsync } from '@hometap/htco-components';
import { getDocumentById } from '../../documentRequests';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PreviewDocument } from '../PreviewDocument/PreviewDocument';
import { useTrackApplicants } from 'apps/track-details/Documents/hooks/useTrackApplicants';
import useTrackDocuments from '../../hooks/useTrackDocuments';

/**
 * @typedef UsePreviewDocumentPageReturn
 * @type {object}
 * @property {boolean} versionLoading Is a view version being fetched?
 * @property {string?} versionError Did the version fetch request raise an error
 * @property {number} viewDocumentIndex The index document currently being viewed
 * @property {React.Dispatch<number>} setViewDocumentIndex Set the index of the document being viewed
 * @property {import('../../documentRequests').DocumentVersion?} viewVersion The initial version being viewed
 */

/** Use hooks for a standalone document viewer
 * @param {import('../../documentRequests').Document[]} documents
 * @returns {UsePreviewDocumentPageReturn}
 */
export function usePreviewDocumentPage(documents) {
  const { documentId, versionId } = useParams();
  const { state } = useLocation();

  // View Document
  const [_viewDocumentIndex, setViewDocumentIndex] = useState(-1);
  const viewDocumentIndex = state?.viewDocumentIndex ?? _viewDocumentIndex;

  useEffect(() => {
    if (documents) {
      const index = documents.findIndex(({ id }) => id === documentId);
      if (index !== -1) {
        setViewDocumentIndex(index);
      }
    }
  }, [documentId, documents, setViewDocumentIndex]);

  // View Version
  const {
    results: _viewVersion,
    setResults: setViewVersion,
    loading: versionLoading,
    error: versionError,
    execute: executeGetViewVersion,
  } = useAsync(
    getDocumentById, // All versions can be retrieved as documents
    {
      immediate: false,
      defaultLoading: false,
      defaultResults: null,
    },
  );
  const viewVersion = state?.viewVersion ?? _viewVersion;

  useEffect(() => {
    if (versionId) {
      executeGetViewVersion(versionId);
    } else {
      setViewVersion();
    }
  }, [versionId, executeGetViewVersion, setViewVersion]);

  return {
    versionLoading,
    versionError,
    viewDocumentIndex,
    setViewDocumentIndex,
    viewVersion,
  };
}

/** Standalone Track Document Viewer with other track documents as context. Full Window & Closeable.
 * Close redirects to the Track Documents List View
 * @returns {JSX.Element} Standalone Track Document Viewer
 */
export default function StandAlonePreviewTrackDocument() {
  const { state } = useLocation();
  const { scrollPosition } = state ?? {};
  const navigate = useNavigate();
  const { trackId, documentId, versionId } = useParams();

  // Applicants
  const useTrackApplicantsResults = useTrackApplicants(trackId);

  // Documents
  const useTrackDocumentsReturn = useTrackDocuments(trackId, useTrackApplicantsResults.applicants);
  const { documentsLoading, documentsError } = useTrackDocumentsReturn;
  const documents = state?.documents ?? useTrackDocumentsReturn?.documents;

  const { versionLoading, versionError, viewDocumentIndex, setViewDocumentIndex, viewVersion } =
    usePreviewDocumentPage(documents);

  // onClose
  const onClose = () => {
    if (state?.documentSetBatchId) {
      navigate(`/document-set-batch/batch-details/${state?.documentSetBatchId}`);
    } else {
      navigate(`/tracks/${trackId}/documents`, {
        replace: true,
        state: {
          scrollPosition,
        },
      });
    }
  };

  return (
    <PreviewDocument
      documentLoading={documentsLoading || versionLoading}
      documentError={documentsError || versionError}
      {...{
        documents,
        viewDocumentIndex,
        setViewDocumentIndex,
        documentId,
        versionId,
        viewVersion,
        // nav-related params
        trackId,
        onClose,
        scrollPosition,
      }}
    />
  );
}
