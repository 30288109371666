import numeral from 'numeral';

export const currency = (num = 0, includeCents = false) => {
  const format = includeCents ? '$0,0.00' : '$0,0';
  return numeral(num).format(format);
};

export const currencyWithCents = (num = 0) => {
  return currency(num, true);
};

export const percentage = (num = 0) => {
  return `${parseFloat(num).toFixed(3)}%`;
};

export const formatDecimal = (num = 0, format) => {
  return numeral(num).format(format);
};
