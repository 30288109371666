import { useEffect, useRef, useState, useCallback } from 'react';
import {
  Button,
  CurrencyInput,
  DatePicker,
  Icon,
  Loader,
  MuiSelect,
  MuiTextInput,
  useForm,
  useOutsideClick,
} from '@hometap/htco-components';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import SliderFormContainer from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormContainer';
import SliderFormBody from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormBody';
import SliderFormSection from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormSection';
import SliderFormText from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormText';
import SliderFormTitle from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormTitle';
import SliderFormField from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormField';
import SliderFormFooter from 'apps/track-details/Appraisals/components/SliderForms/common/SliderFormFooter';
import { Divider } from '@mui/material';
import ErrorBlock from 'components/ErrorBlock/ErrorBlock';
import { getGraphQLError } from 'utils/errors';
import { formatDayOfWeekMonthDayYear, formatYYYYMMDDWithDashes } from 'utils/dates';
import usePhysicalAppraisalData from '../../hooks/usePhysicalAppraisalData';
import { validateDate, validateNonEmptyString, validateUsd } from 'utils/validators';
import {
  GET_REGGORA_ORDER_BY_ID,
  GET_TRADITIONAL_APPRAISAL_XML_DATA,
} from 'apps/track-details/ApplicationReview/sections/HomeValuationsController/reggoraRequests';
import { currency } from 'utils/numbers';
import { extractFormNumber } from 'apps/track-details/utils/reggora';

import './styles/OrderPhysicalAppraisalSlider.scss';

/**
 * Slider form for creating a home valuation based on a traditional appraisal, which can be from a Reggora order or from scratch.
 * @param {Object} props - Component properties.
 * @param {?string} props.orderId - The Reggora order ID, if home val is not from scratch.
 * @param {boolean} props.isOpen - Whether or not the slider is open.
 * @param {Function} props.setIsOpen - Function to set whether or not the slider is open.
 * @param {string} props.trackId - The track ID.
 * @param {Function} props.onClose - Functionality to add to when the slider closes.
 * @returns {JSX.Element} The slider form to create home valuations based on traditional appraisals.
 *
 */
const TraditionalAppraisalHomeValSlider = ({ orderId, isOpen, trackId, onClose }) => {
  const [submissionError, setSubmissionError] = useState(null);
  const [appraisalData, setAppraisalData] = useState({});

  const {
    physicalAppraisalsData,
    refetchPhysicalAppraisals,
    physicalAppraisalsLoading,
    requestOrderTraditionalAppraisal,
    orderTraditionalAppraisalLoading,
  } = usePhysicalAppraisalData(trackId);

  const physicalAppraisalOptions = physicalAppraisalsData?.track?.homeValuationReviewProviderOptions;
  const documentOptions = physicalAppraisalsData?.relevantAppraisalDocuments?.map(doc => ({
    label: doc.filename,
    value: doc.identifier,
  }));

  const {
    loading: selectedReggoraOrderLoading,
    error: selectedReggoraOrderError,
    refetch: refetchSelectedReggoraOrder,
  } = useQuery(GET_REGGORA_ORDER_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      orderId: orderId,
      trackId: trackId,
    },
    skip: !orderId,
    notifyOnNetworkStatusChange: true,
    onCompleted(selectedReggoraOrder) {
      const { acceptedVendorId, acceptedVendorName, document, inspectionDatetime, product } =
        selectedReggoraOrder.order;
      setAppraisalData(prev => ({
        ...prev,
        document,
        formNumber: extractFormNumber(product),
        product: product,
        provider: acceptedVendorId,
        scheduledFor: formatYYYYMMDDWithDashes(inspectionDatetime),
      }));
      if (acceptedVendorName in physicalAppraisalOptions && acceptedVendorName !== 'Other') {
        updateFormData({
          ...formData,
          documentFileId: document?.identifier ?? '',
          formNumber: extractFormNumber(product),
          product: product,
          provider: acceptedVendorName,
          scheduledFor: formatYYYYMMDDWithDashes(inspectionDatetime),
        });
      } else {
        updateFormData({
          ...formData,
          documentFileId: document?.identifier ?? '',
          formNumber: extractFormNumber(product),
          product: product,
          provider: 'Other',
          otherProvider: acceptedVendorName,
          scheduledFor: formatYYYYMMDDWithDashes(inspectionDatetime),
        });
      }
    },
    onError(_err) {
      setAppraisalData(prev => ({
        ...prev,
        document: null,
        formNumber: '',
        product: '',
        provider: '',
        otherProvider: '',
        scheduledFor: '',
      }));
    },
  });

  const {
    loading: reggoraOrderXmlDataLoading,
    error: reggoraOrderXmlDataError,
    refetch: refetchReggoraOrderXmlData,
  } = useQuery(GET_TRADITIONAL_APPRAISAL_XML_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      orderId,
    },
    skip: !orderId,
    notifyOnNetworkStatusChange: true,
    onCompleted(reggoraOrderXmlData) {
      const { appraiserName, asOfDate, certificationNumber, value } = reggoraOrderXmlData.traditionalAppraisalXmlData;
      setAppraisalData(prev => ({
        ...prev,
        appraiserName,
        asOfDate,
        certificationNumber,
        homeValue: value,
      }));
      updateFormData({
        ...formData,
        appraiserName,
        asOfDate,
        certificationNumber,
        homeValue: value,
      });
    },
    onError(_err) {
      setAppraisalData(prev => ({
        ...prev,
        appraiserName: '',
        asOfDate: '',
        certificationNumber: '',
        homeValue: '',
      }));
    },
  });

  /**
   * Retry GraphQL queries for appraisal data based on queries errors or manual params.
   *
   * @param {Object} options - Options for retrying data fetch.
   * @param {boolean} options.shouldRefetchSelectedReggoraOrder - Whether or not to refetch the selected Reggora order.
   * @param {boolean} options.shouldRefetchReggoraOrderXmlData - Whether or not to refetch the Reggora order XML data.
   */
  const retryDataFetch = useCallback(
    async ({ shouldRefetchSelectedReggoraOrder = false, shouldRefetchReggoraOrderXmlData = false }) => {
      const refetchRequests = [];
      if (shouldRefetchSelectedReggoraOrder) {
        refetchRequests.push(refetchSelectedReggoraOrder());
      }
      if (shouldRefetchReggoraOrderXmlData) {
        refetchRequests.push(refetchReggoraOrderXmlData());
      }
      await Promise.all(refetchRequests);
    },
    [refetchSelectedReggoraOrder, refetchReggoraOrderXmlData],
  );

  const { registerField, updateFormData, isFormValid, formData, errors: formErrors, setErrors } = useForm();

  useEffect(() => {
    if (!orderId) {
      setAppraisalData({
        product: '',
        provider: '',
        otherProvider: '',
        formNumber: '',
        scheduledFor: '',
        homeValue: '',
        appraiserName: '',
        certificationNumber: '',
        asOfDate: '',
        document: {},
      });
    }
    if (orderId && isOpen) {
      retryDataFetch({ shouldRefetchSelectedReggoraOrder: true, shouldRefetchReggoraOrderXmlData: true });
    }
  }, [updateFormData, isOpen, orderId, retryDataFetch]);

  /**
   * Checks if any values in the data object are an empty string.
   *
   * @param {Object} data - The data object to check.
   * @returns {boolean} - Returns true if any fields are an empty string, false otherwise.
   */
  const checkIfAnyEmptyFields = data => {
    const hasEmptyFields = Object.values(data).some(value => value === '');
    return hasEmptyFields;
  };

  // The custom onBlur and this useEffect are both needed to provide correct behavior
  // as the onBlur function gets the old value, but you need to check on user click out
  // or the form loads with an error state
  useEffect(() => {
    if (formData?.provider && formErrors?.provider?.show) {
      setErrors('provider', null, false);
    }

    if (formData?.documentFileId && formErrors?.documentFileId?.show) {
      setErrors('documentFileId', null, false);
    }
  }, [formData, formErrors, setErrors]);

  const handleClose = () => {
    updateFormData({
      product: '',
      provider: '',
      otherProvider: '',
      formNumber: '',
      scheduledFor: '',
      homeValue: '',
      appraiserName: '',
      certificationNumber: '',
      asOfDate: '',
      documentFileId: '',
    });
    setErrors('provider', null, false);
    setErrors('documentFileId', null, false);
    setSubmissionError(null);
    onClose();
  };

  /**
   * @description Handles the creation of a new physical appraisal using the form data and track id
   * @returns {void}
   */
  const handleCreatePhysicalAppraisal = async () => {
    const variables = {
      appraiserName: formData.appraiserName,
      asOfDate: formatYYYYMMDDWithDashes(formData.asOfDate),
      certificationNumber: formData.certificationNumber,
      documentFileId: formData.documentFileId,
      kindNumber: formData.formNumber,
      provider: formData.provider,
      scheduledDate: formatYYYYMMDDWithDashes(formData.scheduledFor),
      trackId: trackId,
      value: parseInt(formData.homeValue),
    };

    if (formData.provider === 'Other') {
      variables.providerOther = formData.otherProvider;

      // if other provider does not exist or contains only white space
      if (!formData.otherProvider || !formData.otherProvider.trim()) {
        setSubmissionError('"Other provider" is requested when selecting "Other" as the provider');
        return;
      }
    }

    try {
      setSubmissionError(null);
      await requestOrderTraditionalAppraisal({
        variables,
      });
      handleClose();

      await refetchPhysicalAppraisals({ trackId });
    } catch (error) {
      // catch graphql errors to display in the error box
      setSubmissionError(getGraphQLError(error) || error.message || 'an unknown error');
    }
  };

  /**
   * Allows the slider to be closed when clicking outside of the slider.
   * @param {MouseEvent} event - A click event.
   */
  const handleBackdropClick = event => {
    // if loading do not close the slider
    if (loading) return;

    const cssClass = event.target.className;
    const dataId = event.target.getAttribute('data-id');
    if (
      dataId?.includes('create-traditional-appraisal') ||
      cssClass.includes('react-datepicker') ||
      cssClass.includes('SliderFormSelect') ||
      cssClass.includes('htco-MenuDropdownItem') ||
      cssClass.includes('AppraisalsNewAppraisalButton') ||
      cssClass.includes('SliderFormSubmit')
    ) {
      return;
    }

    handleClose();
  };

  const ref = useRef(null);
  useOutsideClick(ref.current, handleBackdropClick);

  const loading =
    physicalAppraisalsLoading ||
    orderTraditionalAppraisalLoading ||
    selectedReggoraOrderLoading ||
    reggoraOrderXmlDataLoading;

  const unableToRetrieveInfoErrorDisplay = (
    <div className="flex h-[24px] items-center gap-[8px] text-red-100">
      <Icon name={'icon-error'} className={'h-[20px] w-[20px]'} />
      <p>{'Unable to retrieve info'}</p>
    </div>
  );

  const getAppraisalTypeContent = () => {
    if (appraisalData.product) {
      return <SliderFormText text={appraisalData.product} />;
    } else if (selectedReggoraOrderError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return <SliderFormText text={'Traditional Appraisal'} />;
  };

  const getScheduledForContent = () => {
    if (appraisalData.scheduledFor) {
      return <SliderFormText text={formatDayOfWeekMonthDayYear(appraisalData.scheduledFor)} />;
    } else if (selectedReggoraOrderError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <DatePicker
        required
        showRequiredAsterisk={false}
        {...registerField('scheduledFor')}
        width={'100%'}
        theme={'outlined'}
        disabled={loading}
        data-id="physical-appraisal-slider-scheduled-for-input"
        aria-label="Scheduled For"
        className="OrderPhysicalAppraisalSliderDatePicker"
        onBlur={() => {
          const validationError = validateDate(formData?.scheduledFor);
          if (validationError) {
            setErrors('scheduledFor', validationError, true);
          }
        }}
        error={formErrors?.scheduledFor?.show ? formErrors?.scheduledFor?.message : null}
      />
    );
  };

  const getHomeValueContent = () => {
    if (appraisalData.homeValue) {
      return <SliderFormText text={currency(appraisalData.homeValue)} />;
    } else if (reggoraOrderXmlDataError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <CurrencyInput
        required
        type="number"
        showRequiredAsterisk={false}
        {...registerField('homeValue')}
        disabled={loading}
        data-id="physical-appraisal-slider-home-value-input"
        aria-label="Home Value"
        validator={validateUsd}
        error={formErrors?.homeValue?.show && formErrors?.homeValue?.message}
      />
    );
  };

  const getAppraiserNameContent = () => {
    if (appraisalData.appraiserName) {
      return <SliderFormText text={appraisalData.appraiserName} />;
    } else if (reggoraOrderXmlDataError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <MuiTextInput
        required
        type="text"
        showRequiredAsterisk={false}
        {...registerField('appraiserName')}
        width={'100%'}
        theme="outlined"
        disabled={loading}
        data-id="physical-appraisal-slider-appraiser-name-input"
        aria-label="Appraiser Name"
        validator={validateNonEmptyString}
        error={formErrors?.appraiserName?.show && formErrors?.appraiserName?.message}
      />
    );
  };

  const getCertificationNumberContent = () => {
    if (appraisalData.certificationNumber) {
      return <SliderFormText text={appraisalData.certificationNumber} />;
    } else if (reggoraOrderXmlDataError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <MuiTextInput
        required
        showRequiredAsterisk={false}
        {...registerField('certificationNumber')}
        width={'100%'}
        theme="outlined"
        disabled={loading}
        data-id="physical-appraisal-slider-certification-number-input"
        aria-label="Certification Number"
        validator={validateNonEmptyString}
        error={formErrors?.certificationNumber?.show && formErrors?.certificationNumber?.message}
      />
    );
  };

  const getAsOfDateContent = () => {
    if (appraisalData.asOfDate) {
      return <SliderFormText text={formatDayOfWeekMonthDayYear(appraisalData.asOfDate)} />;
    } else if (reggoraOrderXmlDataError) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <DatePicker
        required
        showRequiredAsterisk={false}
        {...registerField('asOfDate')}
        width={'100%'}
        theme={'outlined'}
        disabled={loading}
        data-id="physical-appraisal-slider-as-of-date-input"
        aria-label="Effective Date"
        className="OrderPhysicalAppraisalSliderDatePicker"
        onBlur={() => {
          const validationError = validateDate(formData?.asOfDate);
          if (validationError) {
            setErrors('asOfDate', validationError, true);
          }
        }}
        error={formErrors?.asOfDate?.show ? formErrors?.asOfDate?.message : null}
      />
    );
  };

  const getDocumentContent = () => {
    if (appraisalData.document?.filename && appraisalData.document?.identifier) {
      return (
        <a
          href={`/tracks/${trackId}/documents/${appraisalData.document.identifier}`}
          target="_blank"
          rel="noreferrer noopener"
          className="w-fit"
        >
          {appraisalData.document.filename}
        </a>
      );
    } else if (selectedReggoraOrderError && !documentOptions) {
      return unableToRetrieveInfoErrorDisplay;
    }
    return (
      <MuiSelect
        required
        data-id="physical-appraisal-slider-document-select-input"
        showRequiredAsterisk={false}
        options={documentOptions}
        {...registerField('documentFileId')}
        width={'100%'}
        theme="outlined"
        classNamePrefix="SliderFormSelect"
        disabled={loading}
        aria-label="Document"
        onBlur={() => {
          if (!formData?.documentFileId) {
            setErrors('documentFileId', formErrors?.documentFileId?.message, true);
          }
        }}
        error={formErrors?.documentFileId?.show && formErrors?.documentFileId?.message}
      />
    );
  };

  const getErrorBlockContent = () => {
    if ((selectedReggoraOrderError || reggoraOrderXmlDataError) && checkIfAnyEmptyFields(appraisalData)) {
      return (
        <ErrorBlock
          icon={'list'}
          error={
            <>
              Hmm... Looks like we are having a little trouble getting all the information we need. You can click{' '}
              <button
                className="font-sans text-base text-blue-100 underline decoration-1 underline-offset-2 transition-colors hover:text-blue-75"
                onClick={() =>
                  retryDataFetch({
                    shouldRefetchSelectedReggoraOrder: selectedReggoraOrderError,
                    shouldRefetchReggoraOrderXmlData: reggoraOrderXmlDataError,
                  })
                }
              >
                here
              </button>{' '}
              to try again. If things still aren’t working, please{' '}
              <a href="https://hometap.slack.com/archives/CBN5REDQ9">reach out</a> to report the issue.
            </>
          }
        />
      );
    } else if (submissionError) {
      return (
        <ErrorBlock
          icon={'list'}
          error={
            <>
              The home valuation could not be created due to {submissionError}. Please try again or{' '}
              <a href="https://hometap.slack.com/archives/CBN5REDQ9">reach out</a> to report the issue.
            </>
          }
        />
      );
    }
  };

  return (
    <SliderFormContainer ref={ref} isVisible={isOpen}>
      <SliderFormTitle
        // disables closing the slider when submitting the form to avoid premature
        // closing if an error occurs, the user would not be aware
        closeDisabled={orderTraditionalAppraisalLoading}
        title="New traditional appraisal home valuation"
        onClose={handleClose}
      />
      <Divider />
      {loading ? (
        <div className="h-full">
          <Loader
            size="large"
            theme="primary"
            type="spinner"
            className="flex h-full w-full flex-col items-center justify-center"
          />
        </div>
      ) : (
        <>
          <SliderFormBody className="gap-[8px]">
            <SliderFormSection>
              <SliderFormField label={'Appraisal Type'} field={getAppraisalTypeContent()} />

              {!orderId && (
                <SliderFormField
                  label="Provider"
                  className={cx({ 'mb-[32x]': formErrors?.provider?.show })}
                  field={
                    <MuiSelect
                      required
                      showRequiredAsterisk={false}
                      options={physicalAppraisalOptions}
                      {...registerField('provider')}
                      width={'100%'}
                      theme="outlined"
                      classNamePrefix="SliderFormSelect"
                      disabled={loading}
                      data-id="physical-appraisal-slider-provider-input"
                      aria-label="Provider"
                      onBlur={() => {
                        if (!formData?.provider) {
                          setErrors('provider', formErrors?.provider?.message, true);
                        }
                      }}
                      error={formErrors?.provider?.show && formErrors?.provider?.message}
                    />
                  }
                />
              )}

              {formData?.provider === 'Other' && (
                <SliderFormField
                  label="Other provider"
                  className={cx({ 'mb-[32px]': formErrors?.otherProvider?.show })}
                  field={
                    <MuiTextInput
                      required
                      maxLength={255}
                      showRequiredAsterisk={false}
                      {...registerField('otherProvider')}
                      width={'100%'}
                      theme="outlined"
                      disabled={loading}
                      data-id="physical-appraisal-slider-other-provider-input"
                      aria-label="Other provider"
                      validator={validateNonEmptyString}
                      error={formErrors?.otherProvider?.show && formErrors?.otherProvider?.message}
                    />
                  }
                />
              )}

              {!orderId && (
                <SliderFormField
                  label="Form Number"
                  className={cx({ 'mb-[32px]': formErrors?.formNumber?.show })}
                  field={
                    <MuiTextInput
                      required
                      showRequiredAsterisk={false}
                      {...registerField('formNumber')}
                      width={'100%'}
                      theme="outlined"
                      disabled={loading}
                      data-id="physical-appraisal-slider-form-number-input"
                      aria-label="Form Number"
                      validator={validateNonEmptyString}
                      error={formErrors?.formNumber?.show && formErrors?.formNumber?.message}
                    />
                  }
                />
              )}

              <SliderFormField
                label="Scheduled For"
                className={cx({ 'mb-[32px]': formErrors?.scheduledFor?.show })}
                field={getScheduledForContent()}
              />

              <SliderFormField
                label="Home Value"
                className={cx({ 'mb-[32px]': formErrors?.homeValue?.show })}
                field={getHomeValueContent()}
              />

              <SliderFormField
                label="Appraiser Name"
                className={cx({ 'mb-[32px]': formErrors?.appraiserName?.show })}
                field={getAppraiserNameContent()}
              />

              <SliderFormField
                label="Certification Number"
                className={cx({ 'mb-[32px]': formErrors?.certificationNumber?.show })}
                field={getCertificationNumberContent()}
              />

              <SliderFormField
                label="Effective Date"
                className={cx({ 'mb-[32px]': formErrors?.asOfDate?.show })}
                field={getAsOfDateContent()}
              />

              <SliderFormField
                label="Document"
                className={cx({ 'mb-[32px]': formErrors?.documentFileId?.show })}
                field={getDocumentContent()}
              />
            </SliderFormSection>
          </SliderFormBody>
          {getErrorBlockContent()}
          <Divider />
          <SliderFormFooter className="justify-end">
            <div className="flex items-center">
              <Button
                onClick={handleClose}
                disabled={orderTraditionalAppraisalLoading}
                theme="secondary"
                size={'small'}
                data-id="physical-appraisal-slider-cancel-button"
                aria-label="Cancel"
              >
                Cancel
              </Button>

              <Button
                className="SliderFormSubmit"
                data-id="physical-appraisal-slider-create-button"
                size={'small'}
                onClick={() => {
                  handleCreatePhysicalAppraisal();
                }}
                aria-label="Create Valuation"
                disabled={!isFormValid || loading || !formData?.provider || !formData?.documentFileId}
              >
                {orderTraditionalAppraisalLoading ? (
                  <div className="ml-[16px] flex h-[40px] w-[155px] items-center justify-center rounded-lg bg-neutral-dark-30">
                    <Loader type="dot-pulse" theme="inverse" />
                  </div>
                ) : (
                  <>Create Valuation</>
                )}
              </Button>
            </div>
          </SliderFormFooter>
        </>
      )}
    </SliderFormContainer>
  );
};

export default TraditionalAppraisalHomeValSlider;
